import { MB_Button } from '@mightybyte/rnw.components.button';
import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import { COLORS } from '../../../../../constants/colors';
import { textStyles } from '../../../../../constants/textStyles';
import { MB_ToggleSwitch } from '../../../../../mightyByteLibraries/MB_ToggleSwitch';

interface AdminCreateOrEditHeaderProps {
    title: string,
    isEnabled?: boolean,
    onIsEnabledChange?: (isEnabled: boolean) => void,
    disableToggle?: boolean,
    rightButtonTitle: string,
    rightButtonIsLoading?: boolean,
    rightButtonDisabled?: boolean,
    onRightButtonPress?: () => void,
    leftButtonTitle?: string,
    leftButtonDisabled?: boolean,
    onLeftButtonPress?: () => void,
    onBackPress?: () => void,
}

const AdminCreateOrEditHeader = ({
    title,
    isEnabled,
    disableToggle,
    onIsEnabledChange,
    rightButtonTitle,
    rightButtonIsLoading,
    rightButtonDisabled,
    onRightButtonPress,
    leftButtonTitle = 'Cancel',
    leftButtonDisabled,
    onLeftButtonPress,
    onBackPress,
}: AdminCreateOrEditHeaderProps) => {

    return (
        <View style={styles.header}>
            <View style={styles.row}>
                <MB_Button
                    leftElement={<Feather name="arrow-left" color={COLORS.buttonPurple} size={20} />}
                    style={styles.headerBackButton}
                    onPress={onBackPress}
                />
                <Text style={[textStyles.largeText, { color: COLORS.textLightPurple }]}>{title}</Text>
            </View>
            <View style={styles.row}>
                {isEnabled !== undefined &&
                    <View style={[styles.row, styles.live]}>
                        <MB_ToggleSwitch
                            isToggled={isEnabled}
                            onToggle={(newState) => onIsEnabledChange?.(newState)}
                            isDisabled={disableToggle}
                        />
                        <Text style={[styles.liveText, { color: isEnabled ? COLORS.white : COLORS.errorColor }]}>{isEnabled ? 'Live' : 'Offline'}</Text>
                    </View>
                }
                <MB_Button
                    title={leftButtonTitle}
                    textStyle={[textStyles.smallText, { color: COLORS.buttonPurple }]}
                    style={[styles.headerButton, styles.cancel]}
                    onPress={onLeftButtonPress ?? onBackPress}
                    disabled={leftButtonDisabled}
                />
                <MB_Button
                    title={rightButtonTitle}
                    textStyle={textStyles.smallText}
                    style={[styles.headerButton, styles.create]}
                    disabled={rightButtonDisabled}
                    loading={rightButtonIsLoading}
                    onPress={onRightButtonPress}
                />
            </View>
        </View>

    );
};

export { AdminCreateOrEditHeader };

const styles = StyleSheet.create({
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 40,
    },
    row: {
        flexDirection: 'row',
    },
    headerBackButton: {
        width: 47,
        height: 33,
        backgroundColor: COLORS.white,
        marginEnd: 16,
    },
    live: {
        alignItems: 'center',
        marginEnd: 32,
    },
    liveText: StyleSheet.flatten([textStyles.normalText, {
        fontSize: 18,
        marginStart: 8,
        width: 65,
        textAlign: 'left',
    }]),
    headerButton: {
        width: 113,
        height: 38,
    },
    cancel: {
        backgroundColor: COLORS.white,
        marginEnd: 17.5,
    },
    create: {
        backgroundColor: COLORS.buttonPurple,
    },
});
