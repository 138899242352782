import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Linking, Platform } from 'react-native';
import { ServerRedirectProps } from '../../../typesAndInterfaces/componentProps';
import { BarIndicator } from '@mightybyte/rnw.components.activity-indicators';
import { isMobile, isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { COLORS } from '../../../constants/colors';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { envs } from '../../../../env';
import * as WebBrowser from 'expo-web-browser';
import { serverRedirectUtils } from './serverRedirectUtils';

const ServerRedirect = ({ route, navigation }: ServerRedirectProps) => {
  const errorParamBase64 = route.params?.error;
  const successParamsBase64 = route.params?.success;

  const [redirectToMobileInitialized, setRedirectToMobileInitialized] = useState(false);
  const mobileRedirectUrl = `${envs.MOBILE_DEEP_LINK}${route.path?.charAt(0) === '/' ? route.path.slice(1) : route.path}`;

  // Note: Was giving incorrect state warning if this was called outside and when postMessageAndClose was exected.
  // Note sure why but this seems to fix it so we will keep it for now.
  useEffect(() => {
    if (isMobileApp || !isMobile) {
      // We execute processing on mobile and web(computers) right away.
      // For mobile browsers, it`s either handled after button press or not handled at all.

      // iOS needs to dismiss the browser window first otherwise it stays on the top
      if (Platform.OS === 'ios') {
        WebBrowser.dismissBrowser();
      }

      // Giving some time for iOS to be able to dismiss the browser window first.
      setTimeout(() => {
        serverRedirectUtils.handleServerRedirect({ errorParamBase64, successParamsBase64, navigation });
      }, Platform.OS === 'ios' ? 500 : 0);
    }
  }, [errorParamBase64, navigation, successParamsBase64]);



  if (isMobile && !isMobileApp) {
    // We are in mobile browser, we will try to redirect to the app.
    Linking.openURL(mobileRedirectUrl);
  }

  return (
    <View style={styles.container}>
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'transparent' }}>

        {isMobile && !isMobileApp ?
          <MB_Button
            title="Continue to ASL Flurry"
            style={styles.button}
            onPress={() => {
              if (redirectToMobileInitialized) {
                Linking.openURL(envs.MOBILE_DEEP_LINK);
              } else {
                Linking.openURL(mobileRedirectUrl);
                setRedirectToMobileInitialized(true);
              }
            }}
          />
          :
          <BarIndicator color={COLORS.white} count={5} />
        }
      </View>
    </View>
  );
};

export { ServerRedirect };


const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: COLORS.darkBackground,
  },
  button: {
    backgroundColor: COLORS.plum,
  },
});
