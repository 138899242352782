import { MB_Modal } from '@mightybyte/rnw.components.modal';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import React, { useCallback, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { COLORS } from '../../../constants/colors';
import { textStyles } from '../../../constants/textStyles';
import { MB_TextInput } from '@mightybyte/rnw.components.text-input';
import { MB_Button } from '@mightybyte/rnw.components.button';
import Feather from 'react-native-vector-icons/Feather';
import { useCreateFolder } from '../../../hooks/adminHooks';
import { mbShowToast } from '@mightybyte/rnw.components.toast';
import { FOLDER_TYPE } from '../../../typesAndInterfaces/typesAndInterfaces';

const AddFolderModal = ({ type, isVisible, onDismiss }: { type: FOLDER_TYPE, isVisible: boolean, onDismiss?: () => void }) => {

    const [folderName, setFolderName] = useState('');
    const { mutate: createFolder, isLoading: isCreatingFolderLoading } = useCreateFolder();

    const onCreate = useCallback(() => {
        createFolder({ name: folderName, type }, {
            onSuccess: onDismiss,
            onError: (error) => {
                mbShowToast({
                    type: 'error',
                    position: 'top',
                    text2: error.message ?? 'Something went wrong. Please try again.',
                });
            },
        });
    }, [createFolder, folderName, onDismiss, type]);

    return (
        <MB_Modal
            isVisible={isVisible}
            onDismiss={onDismiss}
            title="Add New Folder"
        >
            <View>
                <Text style={styles.folderName}>Folder name*</Text>
                <MB_TextInput
                    value={folderName}
                    onChangeText={setFolderName}
                    maxLength={30}
                />
                <MB_Button
                    title="Create Folder"
                    leftElement={<Feather name="plus" color={COLORS.white} size={14} />}
                    textStyle={textStyles.smallText}
                    style={[styles.button]}
                    disabled={folderName === '' || isCreatingFolderLoading}
                    loading={isCreatingFolderLoading}
                    onPress={onCreate}
                />
            </View>
        </MB_Modal>
    );
};

export { AddFolderModal };

const styles = StyleSheet.create({
    modalStyle: {
        backgroundColor: COLORS.backgroundDarkPurple,
    },
    folderName: mbTextStyles([
        textStyles.normalText, {
            textAlign: 'left',
        },
    ]),
    button: {
        backgroundColor: COLORS.buttonPurple,
        width: 205,
        height: 40,
        borderRadius: 10,
        marginTop: 10,
        alignSelf: 'center',
    },
});
