import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import React, { useMemo } from 'react';
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native';
import { COLORS } from '../../constants/colors';
import { textStyles } from '../../constants/textStyles';

export interface ProgressBarProps {
    progressBarStyle?: StyleProp<ViewStyle>,
    progressBarContainerStyle?: StyleProp<ViewStyle>,
    completedQuestions: number,
    totalQuestions: number,
    showCompletionText?: boolean,
    shouldShowCompletePill?: boolean,
}

const ProgressBar = (props: ProgressBarProps) => {

    const progressBarStyle = useMemo(() => {
        const widthStyle = { width: Math.min((props.completedQuestions / props.totalQuestions) * 100, 100) + '%' };
        return [styles.progressBarStyle, widthStyle, props.progressBarStyle];
    }, [props.completedQuestions, props.progressBarStyle, props.totalQuestions]);

    return (
        <View style={styles.container}>
            {(props.totalQuestions === props.completedQuestions && props.shouldShowCompletePill) ?
                <View style={styles.completePillContainer}>
                    <Text style={styles.completePill}>COMPLETED</Text>
                </View>
                :
                <View style={[styles.progressBarContainer, props.progressBarContainerStyle]}>
                    <View style={progressBarStyle} />
                </View>
            }
            {props.showCompletionText &&
                <Text style={styles.completedText}>{props.completedQuestions}/{props.totalQuestions}</Text>
            }
        </View>
    );
};

export { ProgressBar };


const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginVertical: 12,
    },
    completedText: mbTextStyles([
        textStyles.smallerText, {
            fontWeight: '500',
            minWidth: 40,
            textAlign: 'right',
        },
    ]),
    progressBarContainer: {
        flex: 1,
        padding: 3,
        borderRadius: 30,
        justifyContent: 'center',
        backgroundColor: COLORS.backgroundPurple,
    },
    progressBarStyle: {
        width: '100%',
        height: 12,
        borderRadius: 16,
        backgroundColor: COLORS.buttonPurple,
    },
    completePillContainer: {
        backgroundColor: COLORS.white,
        borderWidth: 2,
        borderColor: COLORS.textPurple,
        borderRadius: 10,
        width: 92,
        height: 22,
        overflow: 'hidden',
        justifyContent: 'center',
    },
    completePill: mbTextStyles([
        textStyles.smallerText, {
            color: COLORS.textPurple,
            fontWeight: '700',
            includeFontPadding: false,
        },
    ]),
});
