import React from 'react';
import { Image, ScrollView, StyleSheet, Text, View } from 'react-native';
import { COLORS } from '../../../constants/colors';
import LoginBackground from '../../../resources/svgComponents/LoginBackground';
import { imageUtils } from '../../../utils/imageUtils';
import LoginInput from './LoginInput';
import { ILoginUI, LOGIN_UI_BUTTON_PRESSED_TYPES } from './LoginUtils';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../constants/textStyles';
import { SocialLoginButtons } from '../../helperComponents/SocialLoginButtons';

const LoginUI = ({ onButtonPressed, socialLoginCallback, email, setEmail, password, setPassword, errorMessage, setErrorMessage, isLoading }: ILoginUI) => {
    return (
        <View style={styles.container}>
            <View style={styles.loginBannerContainer}>
                <LoginBackground />
            </View>

            <ScrollView style={{ flex: 1 }} contentContainerStyle={styles.scrollViewContentContainer}>
                <View style={styles.inputContainer}>
                    <Image style={{ width: 237, height: 163 }} source={imageUtils.images.aslFlurryLogo} resizeMode="contain" />
                    <SocialLoginButtons callback={socialLoginCallback} containerStyle={styles.socialLoginButtonContainer} buttonStyle={styles.socialLoginButton} />
                    <View style={styles.orLabelContainer}>
                        <View style={styles.orLabelLine} />
                        <Text style={styles.orLabel}>OR LOGIN WITH</Text>
                        <View style={styles.orLabelLine} />
                    </View>
                    <LoginInput
                        containerStyle={styles.loginInput}
                        onButtonPressed={onButtonPressed}
                        socialLoginCallback={socialLoginCallback}
                        email={email}
                        setEmail={setEmail}
                        password={password}
                        setPassword={setPassword}
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                        isLoading={isLoading}
                    />
                </View>
                <Text style={styles.noteText}>Don't have an account? <Text style={styles.signUpTxt} onPress={() => onButtonPressed(LOGIN_UI_BUTTON_PRESSED_TYPES.NAVIGATE_SIGN_UP)}>Sign Up</Text></Text>
            </ScrollView>
        </View>
    );
};

export default LoginUI;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: COLORS.backgroundDarkPurple,
        overflow: 'hidden',
        minWidth: 600,
    },
    loginBannerContainer: {
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
    },
    scrollViewContentContainer: {
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
        marginVertical: 32,
    },
    orLabelLine: {
        flex: 1,
        height: 1,
        backgroundColor: COLORS.white,
    },
    orLabel: mbTextStyles([
        textStyles.smallText, {
            color: COLORS.white,
            marginHorizontal: 8,
        },
    ]),
    orLabelContainer: {
        marginTop: 16,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'stretch',
    },
    socialLoginButtonContainer: {
        marginTop: 16,
    },
    socialLoginButton: {
        borderWidth: 0,
        backgroundColor: COLORS.backgroundPurple,
        borderRadius: 8,
    },
    loginInput: {
        marginTop: 25,
        alignSelf: 'stretch',
    },
    inputContainer: {
        backgroundColor: COLORS.darkPurple,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 22,
        paddingHorizontal: 64,
        paddingTop: 52,
        paddingBottom: 46,
        marginTop: 28,
        shadowRadius: 22,
        shadowColor: COLORS.bottombBarBlurBackgroundColor,
    },
    noteText: mbTextStyles([
        textStyles.smallText, {
            color: COLORS.textLightPurple,
            marginTop: 10,
            textAlign: 'left',
        },
    ]),
    signUpTxt: {
        textDecorationLine: 'underline',
    },
});
