import React from 'react';
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native';
import { BODY_PARTS, bodyPartsSvgConfigs } from './bodyPartsConstants';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../../constants/textStyles';
import { COLORS } from '../../../../constants/colors';

const BodyPartsSelectedText = ({ bodyPart, containerStyle }: { bodyPart: BODY_PARTS | undefined, containerStyle?: StyleProp<ViewStyle> }) => {


    return (
        <View style={[styles.container, containerStyle, bodyPart === undefined && {backgroundColor: 'transparent'}]}>
            <View style={[styles.innerContainer, bodyPart === undefined && {backgroundColor: 'transparent'}]}>
                {bodyPart &&
                    <Text style={styles.bodyPartText}>{bodyPartsSvgConfigs[bodyPart].displayText}</Text>
                }
            </View>
        </View>
    );
};

export { BodyPartsSelectedText };

const styles = StyleSheet.create({
    container: {
        borderRadius: 5,
        backgroundColor: COLORS.buttonPurple,
        paddingHorizontal: 3,
        paddingTop: 3,
        paddingBottom: 6,
        alignSelf: 'center',
        marginVertical: 32,
    },
    innerContainer: {
        borderRadius: 5,
        backgroundColor: COLORS.white,
        height: 37,
        justifyContent: 'center',
        paddingHorizontal: 24,
    },
    bodyPartText: mbTextStyles([
        textStyles.largerText, {
            fontSize: 18,
            color: '#412366',
        },
    ]),
});
