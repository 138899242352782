import React from 'react';
import { BarIndicator } from '@mightybyte/rnw.components.activity-indicators';
import { ImageBackground, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { imageUtils } from '../../../../utils/imageUtils';
import { mbShadow, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../../constants/textStyles';
import { COLORS } from '../../../../constants/colors';
import Feather from 'react-native-vector-icons/Feather';
import { MB_Image } from '@mightybyte/rnw.components.image';

const imageBackgrounds = [
    imageUtils.images.purpleCellBackground,
    imageUtils.images.blueCellBackground,
    imageUtils.images.greenCellBackground,
];

export interface RoomsCategoryCardProps<T extends boolean> {
    index: number,
    title: string,
    numberOfQuestions: T extends true ? number : undefined,
    isSelected?: boolean,
    image?: string,
    isLoading?: T
    onPress?: () => void
}

const RoomsCategoryCard = <T extends boolean>({ index, title, numberOfQuestions, isSelected, isLoading, image, onPress }: RoomsCategoryCardProps<T>) => {
    return (
        <TouchableOpacity
            activeOpacity={0.75}
            onPress={onPress}
            disabled={isLoading}
        >
            <ImageBackground
                resizeMode="stretch"
                source={imageBackgrounds[index % imageBackgrounds.length]}
                style={[styles.imageCellContainer]}
            >
                {isLoading ?
                    <BarIndicator color={'white'} count={4} />
                    :
                    <>
                        <View style={styles.check}>
                            {isSelected && <Feather name="check" size={20} color={COLORS.white} />}
                        </View>
                        <Text style={styles.title}>{title}</Text>
                        <Text style={styles.questions}>{numberOfQuestions} {(numberOfQuestions && numberOfQuestions > 1) ? 'QUESTIONS' : 'QUESTIONS'}</Text>
                    </>
                }
            </ImageBackground>
            {image &&
                <MB_Image source={image} style={styles.image} resizeMode="contain" />
            }
        </TouchableOpacity>
    );
};

export { RoomsCategoryCard };

const styles = StyleSheet.create({
    check: {
        width: 35,
        height: 30,
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 1.5,
        borderRadius: 8,
        borderColor: COLORS.textLightPurple,
        ...mbShadow({
            color: COLORS.white,
            opacity: 0.75,
            offsetWidth: 0,
            offsetHeight: 0,
            radius: 8,
            elevation: 8,
        }),
    },
    title: mbTextStyles([textStyles.normalText, {
        fontSize: 18,
        textAlign: 'left',
        marginTop: 11,
    }]),
    questions: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        textAlign: 'left',
        marginTop: 7,
    }]),
    imageCellContainer: {
        borderRadius: 15,
        paddingTop: 13,
        paddingHorizontal: 18,
        marginTop: 20,
        minHeight: 137,
    },
    image: {
        position: 'absolute',
        top: -6,
        right: 0,
        width: 155,
        height: 95,
    },
});
