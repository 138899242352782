import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { ClipPath, Defs, G, Path, Rect } from 'react-native-svg';

const PlayButton = React.memo((props: { size?: number, color?: string, containerStyle?: StyleProp<ViewStyle> }) => {
    return (
        <View style={props.containerStyle}>
            <Svg
                width={props.size ?? 44}
                height={props.size ? (props.size * (33 / 44)) : 33}
                viewBox="8 8 44 33"
                fill="none"
            >
                <G filter="url(#filter0_d_2719_3953)">
                    <G clipPath="url(#clip0_2719_3953)">
                        <Path d="M22 16l9 6-9 6V16z" fill="#fff" />
                    </G>
                    <Rect
                        x={8.75}
                        y={8.75}
                        width={35.5}
                        height={26.5}
                        rx={7.25}
                        stroke="#fff"
                        strokeWidth={1.5}
                    />
                </G>
                <Defs>
                    <ClipPath id="clip0_2719_3953">
                        <Path fill="#fff" transform="translate(22 16)" d="M0 0H9V12H0z" />
                    </ClipPath>
                </Defs>
            </Svg>
        </View>
    );
});

export { PlayButton };
