import { mbPlatformStyle, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { useNavigation } from '@react-navigation/core';
import React, { useCallback, useRef } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { textStyles } from '../../../constants/textStyles';
import { GameSelectorNavigationProp } from '../../../typesAndInterfaces/componentProps';
import { GAME_TYPE, TOOL_TIP_AUTO_SHOW_COLLECTION } from '../../../typesAndInterfaces/typesAndInterfaces';
import { MobileHeaderProps } from '../../helperComponents/Header/MobileHeader';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import { DifficultySelector } from '../../helperComponents/DifficultySelector';
import { GamesView } from '../../helperComponents/GamesView';
import { ToolTipHandler } from '../../helperComponents/ToolTipHandler';
import Feather from 'react-native-vector-icons/Feather';
import { COLORS } from '../../../constants/colors';
import { usePaymentContext } from '../../../context/PaymentContextProvider';
import { GameModeSwitch } from '../../helperComponents/GameModeSwitch';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';

const SelectGame = () => {
    const mobileHeaderOptions = useRef<MobileHeaderProps>({ showHeader: false });
    const navigation = useNavigation<GameSelectorNavigationProp>();

    // rerender when payment changed
    const { } = usePaymentContext();

    const onGameSelected = useCallback((gameType: GAME_TYPE) => {
        navigation.navigate('SelectCategory', { gameType });
    }, [navigation]);

    return (
        <ComponentWrapper
            containerStyle={styles.container}
            mobileHeaderOptions={mobileHeaderOptions.current}
            keyboardShouldPersistTapsForScrollView="always"
            mountPremiumBanner
            headerChildren={<GameModeSwitch />}
        >
            <View style={[isMobileApp && {paddingBottom: 50}]}>
                <Text style={styles.title}>Discover</Text>
                <Text style={styles.body}>Pick a game and let's find a category to get you started!</Text>
                <View style={styles.difficulty}>
                    <DifficultySelector />
                    <ToolTipHandler toolTip={TOOL_TIP_AUTO_SHOW_COLLECTION.Level} placement="bottom">
                        <Feather name="info" color={COLORS.buttonPurple} size={20} />
                    </ToolTipHandler>
                </View>
                <GamesView onGameSelected={onGameSelected} />
            </View>
        </ComponentWrapper>
    );
};

export { GamesView };
export default SelectGame;

const styles = StyleSheet.create({
    container: {
        ...mbPlatformStyle({
            web: {
                minWidth: 1000,
            },
        }),
    },
    title: mbTextStyles([
        textStyles.largeText, {
            fontWeight: 'bold',
            marginBottom: 12,
            marginTop: 24,
        },
    ]),
    body: mbTextStyles([
        textStyles.smallText, {
            marginBottom: 14,
        },
    ]),
    difficulty: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    headerButton: mbTextStyles([textStyles.smallText, {
        color: '#D3D3D3',
        marginRight: 10,
    }]),
});
