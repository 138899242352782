
import { PoolImage } from '../typesAndInterfaces/typesAndInterfaces';
import { axiosCaller } from './customAxios';
import { z } from 'zod';
import { PoolImageObj, PoolImageObjInfinitePagination } from '../utils/zod/zodObjects';
import { MB_InfinitePaginationPage } from '@mightybyte/rnw.utils.util-hooks';

const imagePoolApiCalls = {
    uploadImage: async ({ formData, uploadProgress }: { formData: FormData, uploadProgress?: (percentCompleted: number) => void }): Promise<PoolImage> => {
        return await axiosCaller({
            path: '/api/imagePool/uploadImage',
            method: 'post',
            passToken: true,
            data: formData,
            responseValidatorObj: PoolImageObj,
            responseFormatValidatorMessage: 'Data mismatch for upload pool image response',
            responseDataKey: 'poolImage',
            onUploadProgress: (progressEvent) => {
                const percentCompleted = !progressEvent.total ? 0 : Math.round((progressEvent.loaded * 100) / progressEvent.total);
                uploadProgress?.(percentCompleted);
            },
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },

    getImagePoolImages: async (totalItemsPerPage?: number, offset?: string, searchText?: string, folderId?: string): Promise<MB_InfinitePaginationPage<PoolImage>> => {
        return await axiosCaller({
            path: '/api/imagePool/getImages',
            method: 'get',
            responseValidatorObj: PoolImageObjInfinitePagination,
            responseFormatValidatorMessage: 'Data mismatch for get image pool images response',
            passToken: true,
            params: { totalItemsPerPage, offset, searchText, folderId },
        });
    },

    deleteImage: async (imageId: string): Promise<string> => {
        return await axiosCaller({
            path: '/api/imagePool/deleteImage',
            responseValidatorObj: z.string(),
            responseFormatValidatorMessage: 'Data mismatch for delete image pool images response',
            responseDataKey: 'imageId',
            method: 'post',
            passToken: true,
            params: { imageId },
        });
    },
};

export { imagePoolApiCalls };
