import * as React from 'react';
import Svg, {
    G,
    Path,
    Defs,
    LinearGradient,
    Stop,
    ClipPath,
    RadialGradient,
    Circle,
} from 'react-native-svg';
import { BODY_PARTS, BodyPartsSvgProps } from '../../../components/screens/Game/bodyPartsControls/bodyPartsConstants';
import { BODY_PARTS_SVG_BUTTON_TYPES, BodyPartSvgButton } from '../../../components/helperComponents/BodyPartSvgButton';
/* SVGR has dropped some elements not supported by react-native-svg: filter */


function BodyTypesSVG_nose({ onPress, highlightedParts }: BodyPartsSvgProps) {
    const [_active, setActive] = React.useState<BODY_PARTS | undefined>();

    return (
        <Svg
            style={{ width: '100%', height: '100%' }}
            viewBox="0 0 220 220"
            fill="none"
        >
            <G id="Nostrils">
                <G id="Nose">
                    <G id="Nose_2">
                        <G id="Nose_3" clipPath="url(#clip0_5387_8759)">
                            <G id="Nose_4">
                                <Path
                                    id="Vector"
                                    d="M75.696 81.871c-1.814 1.493-6.29 8.633-5.28 12.168a29.25 29.25 0 01.757 11.778 3.602 3.602 0 011.584 2.296 3.284 3.284 0 01-1.102 3.329c-1.24.688-3.466.206-3.948.642-.482.437-1.01 1.607-2.664 1.607-1.653 0-1.974-.918-2.778-1.446-.803-.528-2.295 0-3.925-.895-1.63-.896-1.493-4.179.826-5.763a29.522 29.522 0 01.39-11.479c.827-2.824-3.076-9.046-5.969-13.04a3.031 3.031 0 01-.528-1.102c-.872-3.33 4.362-7.278 13.247-6.888 6.888.345 9.574 3.628 9.987 6.13a2.708 2.708 0 01-.597 2.663z"
                                    fill="#D98B65"
                                />
                                <Path
                                    id="Vector_2"
                                    style={{
                                        mixBlendMode: 'darken',
                                    }}
                                    d="M75.696 81.871c-1.814 1.492-6.29 8.632-5.28 12.168a29.251 29.251 0 01.757 11.778c-1.423 0-3.42.436-3.696-1.034a46.553 46.553 0 01-.873-8.678c-.07-2.329.41-4.641 1.401-6.75a61.7 61.7 0 016.405-9.183 2.893 2.893 0 011.906-.85 2.709 2.709 0 01-.62 2.55z"
                                    fill="url(#paint0_radial_5387_8759)"
                                />
                                <Path
                                    id="Vector_3"
                                    style={{
                                        mixBlendMode: 'darken',
                                    }}
                                    d="M71.678 111.511c-1.24.689-3.466.207-3.949.643-.482.436-1.01 1.607-2.663 1.607-1.653 0-1.974-.919-2.778-1.447-.803-.528-2.296 0-3.926-.895-1.63-.895-1.492-4.178.827-5.762a29.525 29.525 0 01.39-11.48c.827-2.823-3.076-9.045-5.969-13.04a3.032 3.032 0 01-.528-1.102 7.69 7.69 0 014.293 1.515 34.438 34.438 0 015.28 9.413c.76 2.999.97 6.11.62 9.184-.137 3.742-.55 6.543-.688 8.081a2.5 2.5 0 001.291 2.653c.311.165.653.262 1.004.286a4.823 4.823 0 003.031-1.332 3.652 3.652 0 014.064.321 2.501 2.501 0 00.826-2.043 3.284 3.284 0 01-1.125 3.398z"
                                    fill="url(#paint1_linear_5387_8759)"
                                />
                                <Path
                                    id="Vector_4"
                                    style={{
                                        mixBlendMode: 'darken',
                                    }}
                                    d="M71.678 111.511c-1.24.689-3.466.207-3.949.643-.482.436-1.01 1.607-2.663 1.607-1.653 0-1.974-.919-2.778-1.447-.803-.528-2.296 0-3.926-.895-1.63-.895-1.492-4.178.827-5.762a29.525 29.525 0 01.39-11.48c.827-2.823-3.076-9.045-5.969-13.04a3.032 3.032 0 01-.528-1.102 7.69 7.69 0 014.293 1.515 34.438 34.438 0 015.28 9.413c.76 2.999.97 6.11.62 9.184-.137 3.742-.55 6.543-.688 8.081a2.5 2.5 0 001.291 2.653c.311.165.653.262 1.004.286a4.823 4.823 0 003.031-1.332 3.652 3.652 0 014.064.321 2.501 2.501 0 00.826-2.043 3.284 3.284 0 01-1.125 3.398z"
                                    fill="url(#paint2_radial_5387_8759)"
                                />
                                <Path
                                    id="Vector_5"
                                    d="M59.167 105.658c-1.86 1.171-2.824 4.592-.574 5.878a10.6 10.6 0 003.398.642c.574.184.895.712 1.377 1.057a2.628 2.628 0 001.63.528 2.718 2.718 0 002.296-1.102c.207-.207.345-.483.62-.597a29.029 29.029 0 003.467-.391c2.296-1.056 1.79-4.591-.184-5.854 3.214 0 3.65 5.005.941 6.245-1.469.528-2.938-.299-4.224 0-.528.275-.643.918-1.079 1.4a2.296 2.296 0 01-2.824.597c-.895-.413-1.079-1.607-1.997-1.905-1.561-.207-3.375.665-4.775-.597a3.567 3.567 0 01-.764-3.515 3.557 3.557 0 012.692-2.386z"
                                    fill="#915548"
                                />
                                <Path
                                    id="Vector_6"
                                    d="M59.764 111.051c0-.459.734-.665 1.308-.597a2.302 2.302 0 011.722 1.539 2.043 2.043 0 00-1.653-.574c-.62.046-1.285.092-1.377-.368z"
                                    fill="#1C1E1F"
                                />
                                <Path
                                    id="Vector_7"
                                    d="M70.438 111.051c0-.459-.734-.665-1.331-.597a2.303 2.303 0 00-1.722 1.539 2.083 2.083 0 011.676-.574c.597.046 1.262.092 1.377-.368z"
                                    fill="#1C1E1F"
                                />
                            </G>
                            <G id="pressable_nostrils" clipPath="url(#clip1_5387_8759)">
                                <Path
                                    id="hightlight_nostrils"
                                    d="M61.968 111.835c-6.5 2-7.435-4.772-2.935-6.272 3.74-.343 4.219 3.093 4.219 3.093h3.56s.177-3.599 4.958-3.015c4.5 2.5 1.692 8.333-3.808 6.333-1 3-5.494 2.361-5.994-.139z"
                                    fill="#A430FF"
                                    fillOpacity={0.25}
                                    stroke="#A430FF"
                                    opacity={highlightedParts.includes(BODY_PARTS.nostrils) ? 1 : 0}
                                />
                            </G>
                        </G>
                    </G>
                    <G id="Group 27709">
                        <G id="Group">
                            <G
                                id="Ellipse 83"
                                transform="rotate(150 65.493 110.523)"
                                fill="#fff"
                            >
                                <Circle cx={65.4926} cy={110.523} r={2} />
                                <Circle cx={65.4926} cy={110.523} r={2} />
                            </G>
                            <Path
                                id="Line 78"
                                stroke="#fff"
                                d="M129.451 110.709L64.8331 110.709"
                            />
                        </G>
                        <G id="pressableContainerSelected_nostrils">
                            <G id="pressableButton_nostrils">
                                <BodyPartSvgButton
                                    type={BODY_PARTS_SVG_BUTTON_TYPES.activate}
                                    bodyPart={BODY_PARTS.nostrils}
                                    onPress={onPress}
                                    onActivate={setActive}
                                    activateAsSelect
                                    x={100}
                                    y={94}
                                    width={70}
                                    height={32}
                                />
                            </G>
                        </G>
                    </G>
                </G>
            </G>
            <Defs>
                <RadialGradient
                    id="paint0_radial_5387_8759"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(9.98688 0 0 9.98684 77.211 87.978)"
                >
                    <Stop stopColor="#C97551" />
                    <Stop offset={1} stopColor="#C97551" stopOpacity={0} />
                </RadialGradient>
                <LinearGradient
                    id="paint1_linear_5387_8759"
                    x1={72.4587}
                    y1={115.23}
                    x2={55.1482}
                    y2={93.1214}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#C97551" />
                    <Stop offset={1} stopColor="#C97551" stopOpacity={0} />
                </LinearGradient>
                <RadialGradient
                    id="paint2_radial_5387_8759"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(13.0402 0 0 13.0403 54.368 87.91)"
                >
                    <Stop stopColor="#C97551" />
                    <Stop offset={1} stopColor="#C97551" stopOpacity={0} />
                </RadialGradient>
                <ClipPath id="clip0_5387_8759">
                    <Path
                        fill="#fff"
                        transform="translate(51.965 92.052)"
                        d="M0 0H26V23H0z"
                    />
                </ClipPath>
                <ClipPath id="clip1_5387_8759">
                    <Path
                        fill="#fff"
                        transform="translate(54.62 104.233)"
                        d="M0 0H20V10H0z"
                    />
                </ClipPath>
            </Defs>
        </Svg>
    );
}

export { BodyTypesSVG_nose };
