import { BarIndicator } from '@mightybyte/rnw.components.activity-indicators';
import { mbHidePopUp, mbShowPopUp } from '@mightybyte/rnw.components.pop-up';
import { mbApplyTransparency, mbPlatformStyle, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import React, { memo, useCallback } from 'react';
import { ImageBackground, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { COLORS } from '../../constants/colors';
import { textStyles } from '../../constants/textStyles';
import LockIcon from '../../resources/svgComponents/LockIcon';
import { PlayButton } from '../../resources/svgComponents/PlayButton';
import { RestartButton } from '../../resources/svgComponents/RestartButton';
import { imageUtils } from '../../utils/imageUtils';
import { ProgressBar } from './ProgressBar';
import { MB_Image } from '@mightybyte/rnw.components.image';

export interface CategoryCardProps {
    // categoryId: string,
    index: number; // NOTE: Eventually, this thing won`t be needed anymore.
    title: string;
    totalItems: number;
    completedItems?: number;
    tutorialUrl?: string | undefined;
    // goToDetailScreen: (categoryId: string, tutorialURL: string | undefined, totalQuestions: number, completedQuestions: number, categoryTitle: string) => void;
    showStartHere?: boolean;
    isLoading?: boolean;
    isDisabled?: boolean;
    // navigateToPayment?: () => void,
    isProgressbarDisabled?: boolean,
    onPress?: () => void,
    itemName?: string,
    image?: string,
}

const colorsDataArray = [{
    progressBarStyle: { backgroundColor: '#412366' },
    progressBarContainerStyle: { backgroundColor: '#A430FF' },
},
{
    progressBarStyle: { backgroundColor: mbApplyTransparency(COLORS.black, 0.3) },
    progressBarContainerStyle: { backgroundColor: '#C7DBFF' },
},
{
    progressBarStyle: { backgroundColor: mbApplyTransparency(COLORS.black, 0.3) },
    progressBarContainerStyle: { backgroundColor: '#A2E7F1' },
}];

const disabledColors = {
    progressBarStyle: { backgroundColor: mbApplyTransparency('#81B5D1', 0.11) },
    progressBarContainerStyle: undefined,
};

const imageBackgrounds = [
    imageUtils.images.purpleCellBackground,
    imageUtils.images.blueCellBackground,
    imageUtils.images.greenCellBackground,
];

const CategoryCard = memo((props: CategoryCardProps) => {
    const colorsData = props.isDisabled ? disabledColors : colorsDataArray[props.index % colorsDataArray.length];

    function onCardPress() {
        if (props.isDisabled) {
            mbShowPopUp({
                title: 'Unlock this category',
                message: 'This is a premium category. Join premium to access all categories!',
                buttonText: 'Go Premium!',
                secondaryButtonText: 'Not Now',
                buttonAction: () => {
                    mbHidePopUp();
                    props.onPress?.();
                    // props.navigateToPayment?.();
                },
            });
        } else {
            props.onPress?.();
            // props.goToDetailScreen(props.categoryId, props.tutorialUrl, props.totalQuestions, props.completedQuestions, props.title);
        }
    }

    const handlePluralWord = useCallback(() => {
        const name = props.itemName?.toUpperCase() ?? 'QUESTION';
        if (props.totalItems > 1) {
            return ` ${name}S`;
        } else {
            return ` ${name}`;
        }
    }, [props.itemName, props.totalItems]);

    return (
        <View style={[styles.container, props.showStartHere === true && styles.categoryCardContainer]}>
            {props.showStartHere === true && // TODO: We need to decide at what point we show the start here stuff.
                <View style={styles.startHereContainer}>
                    <Text style={styles.startHereText}>START HERE!</Text>
                </View>
            }

            <TouchableOpacity
                activeOpacity={0.75}
                onPress={onCardPress}
            >
                <ImageBackground
                    resizeMode={'cover'}
                    source={props.isDisabled === true ? undefined : imageBackgrounds[props.index % imageBackgrounds.length]}
                    style={[styles.imageCellContainer, props.isDisabled === true && { backgroundColor: mbApplyTransparency('#5F487C', 0.30) }]}
                >
                    {props.isLoading ?
                        <BarIndicator color={'white'} count={4} />
                        :
                        <React.Fragment>
                            <View style={styles.textContainer}>
                                {props.isDisabled === true ?
                                    <LockIcon containerStyle={{ marginTop: 6 }} />
                                    :
                                    props.totalItems === props.completedItems ?
                                        <RestartButton containerStyle={styles.cellIcon} />
                                        :
                                        <PlayButton containerStyle={styles.cellIcon} />
                                }
                                <Text style={styles.titleText} numberOfLines={1}>{props.title}</Text>
                                <Text style={styles.detailText} numberOfLines={1}>{props.totalItems + handlePluralWord()}</Text>
                            </View>
                            {!props.isProgressbarDisabled &&
                                <ProgressBar
                                    progressBarContainerStyle={[colorsData.progressBarStyle, { height: 10 }]}
                                    progressBarStyle={[colorsData.progressBarContainerStyle, { height: 6 }]}
                                    completedQuestions={props.isDisabled ? 0 : props.completedItems ?? 0}
                                    totalQuestions={props.totalItems}
                                    showCompletionText
                                    shouldShowCompletePill
                                />
                            }
                        </React.Fragment>
                    }
                </ImageBackground>
                {props.image &&
                    <MB_Image source={props.image} style={styles.image} resizeMode="contain" />
                }
            </TouchableOpacity>
        </View>
    );
});

export { CategoryCard };

const styles = StyleSheet.create({
    container: {
        ...mbPlatformStyle({
            web: {
                width: 327,
                marginTop: 12,
                marginHorizontal: 12,
            },
        }),
    },
    categoryCardContainer: {
        marginTop: 12,
    },
    startHereContainer: {
        top: -5,
        left: 20,
        position: 'absolute',
        zIndex: 1,
        backgroundColor: COLORS.textLightPurple,
        borderRadius: 19,
        paddingVertical: 6,
        paddingHorizontal: 10,
    },
    startHereText: mbTextStyles([
        textStyles.smallText, {
            color: COLORS.buttonPurple,
            fontWeight: '600',
        },
    ]),
    titleText: mbTextStyles([
        textStyles.normalText, {
            fontSize: 18,
            fontWeight: '600',
            textAlign: 'left',
            lineHeight: undefined,
            marginTop: 4,
            ...mbPlatformStyle({
                mobile: {
                    maxWidth: 255,
                },
                web: {
                    maxWidth: 232,
                },
            }),
        },
    ]),
    detailText: mbTextStyles([
        textStyles.smallerText, {
            textAlign: 'left',
            fontSize: 11,
        },
    ]),
    imageCellContainer: {
        borderRadius: 15,
        paddingVertical: 8,
        paddingHorizontal: 25,
        // marginVertical: 12,
        marginTop: 20,
        minHeight: 137,
        overflow: 'hidden',
    },
    cellIcon: {
        // marginTop: -6,
        // marginEnd: -8,
    },
    image: {
        position: 'absolute',
        top: -6,
        right: 0,
        width: 155,
        height: 95,
    },
    textContainer: {
        // flexDirection: 'row',
        flex: 1,
        paddingTop: 16,
        // justifyContent: 'space-between',
    },
});
