import { MB_Button } from '@mightybyte/rnw.components.button';
import { mbPlatformStyle, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import React, { useMemo, useRef } from 'react';
import { Linking, StyleSheet, Text, View } from 'react-native';
import { COLORS } from '../../constants/colors';
import { textStyles } from '../../constants/textStyles';
import { ComponentWrapper } from '../helperComponents/componentWrapper/ComponentWrapper';
import { MobileHeaderProps } from '../helperComponents/Header/MobileHeader';
import { getVersionNumber } from '../../utils/getVersionNumber/getVersionNumber';
import { useGetServerVersion } from '../../hooks/serverUtilHooks';
import { envs } from '../../../env';
import { useNavigation } from '@react-navigation/core';
import { UniversalScreenNavigationProp } from '../../typesAndInterfaces/componentProps';
import { isMobile, isMobileApp } from '@mightybyte/rnw.utils.device-info';

const versions = getVersionNumber();

const ContactUs = () => {
    const { data: serverVersion } = useGetServerVersion();
    const mobileHeaderOptions = useRef<MobileHeaderProps>({ showHeader: true, showBackArrow: true, pageTitle: 'Contact Us' });
    const navigation = useNavigation<UniversalScreenNavigationProp>();

    const goToEmailClient = () => {
        Linking.openURL('mailto:support@aslflurry.com');
    };

    const content = useMemo(() => {
        return (
            <View style={styles.container}>
                <Text style={styles.text}>For more information please contact</Text>
                <MB_Button
                    title="Support@aslflurry.com"
                    style={styles.emailButton}
                    textStyle={styles.emailText}
                    onPress={goToEmailClient}
                />

                {isMobile &&
                    <View style={{ marginTop: 'auto', marginBottom: 32 }}>
                        <Text style={styles.versionInfoText}>JS Version: {versions.jsVersion}</Text>
                        <Text style={styles.versionInfoText}>App Version: {`${versions.appVersion}(${versions.buildVersion})`}</Text>
                        <Text style={styles.versionInfoText}>Mode: {process.env.NODE_ENV}, isDev: {__DEV__?.toString()}</Text>
                        <Text style={styles.versionInfoText}>Flavor: {envs.FLAVOR}</Text>
                        <Text style={styles.versionInfoText}>Server Version: {serverVersion?.version}</Text>
                    </View>
                }
            </View>
        );
    }, [serverVersion?.version]);

    if (isMobileApp) {
        return (
            <ComponentWrapper
                wrapInScrollView={false}
                mobileHeaderOptions={mobileHeaderOptions.current}
                onBackPress={() => navigation.navigate('HomeNavigator', { screen: 'GameNavigator', params: { screen: 'SelectGame' } })}
            >
                {content}
            </ComponentWrapper >
        );
    }

    return (
        <View>
            <Text style={styles.title}>Contact Us</Text>
            {content}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        ...mbPlatformStyle({
            web: {
                alignSelf: 'flex-start',
            },
            mobile: {
                marginTop: 64,
                paddingHorizontal: 16,
                flex: 1,
            },
        }),
    },
    title: mbTextStyles([textStyles.largeText, {
        textAlign: 'left',
        marginBottom: 31,
    }]),
    emailButton: {
        backgroundColor: COLORS.backgroundPurple,
        marginTop: isMobile ? 35 : 10,
        borderRadius: 10,
    },
    text: mbTextStyles([
        textStyles.smallerText, {
            fontSize: isMobile ? 12 : 17,
            color: COLORS.textLightPurple,
            fontWeight: '600',
            textAlign: 'left',
        },
    ]),
    emailText: mbTextStyles([
        textStyles.normalText, {
            color: COLORS.textLightPurple,
            fontSize: 18,
            textAlign: 'left',
            flex: 1,
        },
    ]),
    versionInfoText: {
        ...mbTextStyles({
            ...textStyles.tinyText,
            alignSelf: 'flex-start',
        }),
    },
});

export default ContactUs;
