import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { WebView } from 'react-native-webview';
import { GEOGRAPHY_LOCATION, GEOGRAPHY_MAPS } from '../../screens/Game/geographyControls/geographyConstants';
import { geographyUtils } from './geographyUtils';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { MB_EVENT_EMMITER_EVENT_TYPE, MB_EventEmmiter } from '@mightybyte/rnw.utils.event-emmiter';
import SvgLoader from './SvgLoader';
import { imageUtils } from '../../../utils/imageUtils';

interface IGeographyViewProps {
    style?: StyleProp<ViewStyle>,
    map: GEOGRAPHY_MAPS,
    location?: GEOGRAPHY_LOCATION,
    onLocationChange?: (location?: GEOGRAPHY_LOCATION) => void,
    pointerEvents?: 'box-none' | 'none' | 'box-only' | 'auto' | undefined;
    backgroundColor?: string
}

const GeographyView = ({ style, map, location, onLocationChange, pointerEvents, backgroundColor }: IGeographyViewProps) => {

    const wRef = useRef<WebView | null>(null);
    const iRef = useRef<any | null>(null);

    const source = useMemo(() => ({
        html: SvgLoader.getHtmlWrapper(imageUtils.svgs[map].asText, {
            backgroundColor: backgroundColor ?? map === GEOGRAPHY_MAPS.oceans ? '#C1B7FF' : '#1D183B',
            selectedColor: '#A430FF',
            activeAreas: Object.keys(GEOGRAPHY_LOCATION),
        }),
    }), [backgroundColor, map]);

    const postMessage = useCallback((type: string, payload?: Record<string, any>) => {
        if (isMobileApp) {
            wRef.current?.postMessage(JSON.stringify({ name: 'SVGCOMM', type, ...payload }));
        } else {
            iRef.current?.contentWindow.postMessage(JSON.stringify({ name: 'SVGCOMM', type, ...payload }));
        }
    }, []);

    const onGetReady = useCallback(() => {
        if (location) {
            postMessage('select', { area: location });
        }
    }, [location, postMessage]);

    const onIframeLoad = useCallback(() => {
        // NOTE: this is an alternative solution for the srcDoc prop on iframe
        // add html to iframe
        // iRef.current.contentWindow.document.firstChild.innerHTML = source.html;
        // execute script inside html
        // iRef.current.contentWindow.eval(iRef.current.contentWindow.document.querySelector('script').innerHTML);
        // trigger on load event
        // iRef.current.contentWindow.eval("dispatchEvent(new Event('load'))");
        (global as any).addEventListener('keydown', (e: any) => {
            iRef.current?.contentWindow.eval(`document.dispatchEvent(new KeyboardEvent('keydown', {'key': '${e.key}', 'code': '${e.code}'}))`);
        });
        (global as any).addEventListener('keyup', (e: any) => {
            iRef.current?.contentWindow.eval(`document.dispatchEvent(new KeyboardEvent('keyup', {'key': '${e.key}', 'code': '${e.code}'}))`);
        });
    }, []);

    const onMessage = useCallback(
        (event: any) => geographyUtils.onMessage(event, { onLocationChange, onGetReady }),
        [onGetReady, onLocationChange],
    );

    useEffect(() => {
        if (!isMobileApp) {
            const listener = MB_EventEmmiter.addListener(MB_EVENT_EMMITER_EVENT_TYPE.message, onMessage);
            return listener.remove;
        }
    }, [map, onGetReady, onLocationChange, onMessage, postMessage]);

    if (isMobileApp) {
        return (
            <View style={style} pointerEvents={pointerEvents}>
                <WebView
                    style={{ backgroundColor, flex: 1 }}
                    ref={wRef}
                    onMessage={onMessage}
                    source={source}
                    javaScriptEnabled
                    scrollEnabled
                    nestedScrollEnabled
                    //setDisplayZoomControls
                    directionalLockEnabled={false}
                    scalesPageToFit
                />
            </View>
        );
    }

    return (
        <View style={style} pointerEvents={pointerEvents}>
            <iframe key={map} style={styles.iframe} ref={iRef} srcDoc={source.html} onLoad={onIframeLoad} />
        </View>
    );

};

export { GeographyView };

type CssNamedStyles<T> = { [P in keyof T]: React.CSSProperties };
const createCssStyle = <T extends CssNamedStyles<T>>(styles: T | CssNamedStyles<T>) => styles;

const styles = createCssStyle({
    iframe: {
        width: '100%',
        height: '100%',
        border: 'none',
    },
});
