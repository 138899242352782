import { MB_accessTokenUtils } from '@mightybyte/rnw.utils.access-token-utils';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { createNavigationContainerRef, getStateFromPath, LinkingOptions, NavigationContainer, NavigatorScreenParams } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React, { ReactNode, useEffect, useState } from 'react';
import { envs } from '../../env';
import { LOADING_PAGE_GIVE_UP_ACTION_TYPES, LoadingPage } from '../components/helperComponents/LoadingPage';
import { ServerIsDownPage } from '../components/helperComponents/ServerIsDownPage';
import { UpdateApp } from '../components/helperComponents/UpdateApp';
import ContactUs from '../components/screens/ContactUs';
import { ForgotPassword } from '../components/screens/ForgotPassword';
import { GameScreen } from '../components/screens/Game/GameScreen';
import { Login } from '../components/screens/Login/Login';
import { MobileLandingPage } from '../components/screens/MobileLandingPage';
import { MyAccountPassword } from '../components/screens/MyAccount/MyAccountPassword';
import { MyAccountPersonalInformation } from '../components/screens/MyAccount/MyAccountPersonalInformation';
import { NotFound } from '../components/screens/NotFound';
import PaymentPage from '../components/screens/PaymentPage/PaymentPage';
import { ServerRedirect } from '../components/screens/ServerRedirect/ServerRedirect';
import { SignUp } from '../components/screens/Signup/SignUp';
import { SIGNED_IN_STATUS, useSignedInContext } from '../context/SignedInContext';
import { useGetMinimumMobileJSVersion, useGetServerVersion } from '../hooks/serverUtilHooks';
import { useGetCurrentUserData } from '../hooks/userHooks';
import MB_MobilePurchase from '../mightyByteLibraries/MB_MobilePurchase';
import { PasswordRecoveryToken, UserRole } from '../typesAndInterfaces/typesAndInterfaces';
import { getVersionNumber } from '../utils/getVersionNumber/getVersionNumber';
import { utils } from '../utils/utils';
import { AdminNavigator, AdminNavigatorParamList } from './AdminNavigator/AdminNavigator';
import { HomeNavigator, HomeNavigatorParamList } from './HomeNavigator/HomeNavigator';
import { useMB_CodePushContext } from '../context/MB_CodePushContextProvider';
import { RoomParams } from './RoomsNavigator/RoomsNavigator';
import { UserQuestionaire } from '../components/screens/UserQuestionaire';
import { MB_ORIENTATION_LOCK, MB_orientationLocker } from '@mightybyte/rnw.utils.orientation-locker';

export type RootStackParamList = {
    PaymentPage: undefined
    GameScreen: { categoryIds: string[], index: number, categoryParams?: { categoryTitle?: string, tutorialUrl?: string }, roomParams?: RoomParams }
    Login: undefined
    SignUp: undefined
    MobileLandingPage: undefined
    ServerRedirect: { success?: string; error?: string } | undefined
    ForgotPassword: { passwordRecoveryToken?: PasswordRecoveryToken, recoveryFailed?: boolean } | undefined
    HomeNavigator: NavigatorScreenParams<HomeNavigatorParamList>
    AdminNavigator: NavigatorScreenParams<AdminNavigatorParamList>
    InstallApp: undefined
    NotFound: undefined
    ContactUs: undefined
    MyAccountPassword: undefined
    MyAccountPersonalInformation: undefined
    UserQuestionaire: undefined
};

const Stack = createNativeStackNavigator<RootStackParamList>();

const navRef = createNavigationContainerRef<RootStackParamList>();

export const getRootNavRef = () => {
    return navRef.isReady() ? navRef : undefined;
};

const config: LinkingOptions<RootStackParamList>['config'] = {
    screens: {
        PaymentPage: 'paymentPage',
        GameScreen: {
            path: 'gameScreen',
            stringify: isMobileApp ? undefined : {
                categoryIds: utils.encodeParam,
                index: utils.encodeParam,
                categoryParams: utils.encodeParam,
                roomParams: utils.encodeParam,
            },
        },
        Login: 'login',
        SignUp: 'signup',
        MobileLandingPage: 'mobileLandingPage',
        ForgotPassword: 'forgotPassword',
        ServerRedirect: 'serverRedirect',
        HomeNavigator: {
            path: 'homeNavigator',
            screens: {
                MyAccount: 'myAccount',
                GameNavigator: {
                    path: 'gameNavigator',
                    screens: {
                        SelectGame: 'selectGame',
                        SelectCategory: 'SelectCategory',
                    },
                },
                RoomsNavigator: {
                    path: 'roomsNavigator',
                    screens: {
                        RoomCreateOrJoin: 'roomCreateOrJoin',
                        RoomLobby: 'roomLobby',
                        //GameScreen: 'gameScreen',
                        GameScreen: {
                            path: 'gameScreen',
                            stringify: isMobileApp ? undefined : {
                                categoryIds: utils.encodeParam,
                                index: utils.encodeParam,
                                categoryParams: utils.encodeParam,
                                roomParams: utils.encodeParam,
                            },
                        },
                        RoomEndGame: 'roomEndGame',
                    },
                },
                ExtrasNavigator: {
                    path: 'extrasNavigator',
                    screens: {
                        ExtrasHome: 'extrasHome',
                        ExtrasVideos: 'extrasVideos',
                    },
                },
            },
        },
        AdminNavigator: {
            path: 'adminNavigator',
            screens: {
                Users: 'users',
                IntroToASL: {
                    path: 'introToASL',
                    screens: {
                        Categories: 'categories',
                        Reorder: 'reorder',
                        ConstructCategory: 'constructCategory',
                        ConstructQuestion: 'constructQuestion',
                    },
                },
                PriceMatch: {
                    path: 'priceMatch',
                    screens: {
                        Categories: 'categories',
                        Reorder: 'reorder',
                        ConstructCategory: 'constructCategory',
                        ConstructQuestion: 'constructQuestion',
                    },
                },
                BodyParts: {
                    path: 'bodyParts',
                    screens: {
                        Categories: 'categories',
                        Reorder: 'reorder',
                        ConstructCategory: 'constructCategory',
                        ConstructQuestion: 'constructQuestion',
                    },
                },
                Geography: {
                    path: 'geography',
                    screens: {
                        Categories: 'categories',
                        Reorder: 'reorder',
                        ConstructCategory: 'constructCategory',
                        ConstructQuestion: 'constructQuestion',
                    },
                },
                Extras: {
                    path: 'extras',
                    screens: {
                        Categories: 'categories',
                        Reorder: 'reorder',
                        ConstructCategory: 'constructCategory',
                    },
                },
                ImagePool: 'imagePool',
                VideoPool: 'videoPool',
                EducatorCodes: 'educatorCodes',
            },
        },
        InstallApp: 'installApp',
        NotFound: '*',
        ContactUs: 'contactUs',
        MyAccountPassword: 'myAccountPassword',
        MyAccountPersonalInformation: 'myAccountPersonalInformation',
        UserQuestionaire: 'userQuestionaire',
    },
};

const linking = {
    prefixes: [
        envs.WEBSITE_BASE_URL,
        envs.MOBILE_DEEP_LINK,
    ],
    config,
    // TODO: If we do the top one, we end up not needing to deal with base64 stuff on server redirect. I kind of like that better.
    //getStateFromPath: isMobileApp ? undefined : (path: string, options: any) => utils.decodeParams(getStateFromPath(path, options)),
    getStateFromPath: isMobileApp ? undefined : (path: string, options: any) => path.includes('/serverRedirect') ? getStateFromPath(path, options) : utils.decodeParams(getStateFromPath(path, options)),
};

const RootNavigation = ({ onReady }: { onReady: (isReady: boolean) => void }) => {
    const [serverIsDown, setServerIsDown] = useState<boolean | undefined>();
    const { isSignedIn, signedInStatus, setSignedInStatus } = useSignedInContext();
    const { updateStatus } = useMB_CodePushContext();

    const {
        data: serverVersion,
        failureCount: serverVersionRequestFailureCount,
        isLoading: isLoadingServerVersion,
        refetch: refetchServerVersion,
    } = useGetServerVersion({ retry: 2, enabled: !serverIsDown, refetchOnWindowFocus: false });
    const { data: minimumMobileJSVersion } = useGetMinimumMobileJSVersion({ enabled: isMobileApp && !serverIsDown, refetchOnWindowFocus: false });
    const { data: currentUserData, refetch: refetchCurrentUserData } = useGetCurrentUserData({ enabled: isSignedIn && !serverIsDown });
    const { jsVersion } = getVersionNumber();

    // Initiates the mobile purchase connection
    useEffect(() => {
        if (isSignedIn && isMobileApp && currentUserData?.userRole === UserRole.user) {
            async function initiateMobilePurchaseConnection() {
                let isConnected = MB_MobilePurchase.getIsConnected();
                if (!isConnected) {
                    isConnected = await MB_MobilePurchase.connect();
                }
            }

            initiateMobilePurchaseConnection();
        }

        return () => {
            if (isMobileApp) {
                MB_MobilePurchase.disconnect(currentUserData?.userRole !== UserRole.user);
            }
        };
    }, [currentUserData?.userRole, isSignedIn]);

    // Initial check for sign in status based on stored token.
    useEffect(() => {
        if (signedInStatus === SIGNED_IN_STATUS.loading && !serverIsDown) {
            MB_accessTokenUtils.getAccessToken()
                .then((accessToken) => {
                    if (accessToken) {
                        setSignedInStatus(SIGNED_IN_STATUS.signedIn);
                    } else {
                        setSignedInStatus(SIGNED_IN_STATUS.signedOut);
                    }
                });
        }
    }, [onReady, serverIsDown, setSignedInStatus, signedInStatus]);

    // Checks to see if server is down or not by pulling the backend version.
    useEffect(() => {
        if (serverVersion) {
            setServerIsDown(false);
            if (isSignedIn) {
                refetchCurrentUserData();
            }
        } else if (!serverIsDown && serverVersionRequestFailureCount >= 3) {
            setServerIsDown(true);
        }

        onReady(true);
    }, [onReady, serverVersion, signedInStatus, serverVersionRequestFailureCount, serverIsDown, refetchCurrentUserData, isSignedIn]);

    function renderPublicRoutes() {
        if (isSignedIn && currentUserData?.userRole) {
            if (currentUserData?.userRole === UserRole.admin) {
                return (
                    <Stack.Screen name="AdminNavigator" component={AdminNavigator} />
                );
            }

            if (!currentUserData?.userQuestionaire) {
                return (
                    <>
                        <Stack.Screen name="UserQuestionaire" component={UserQuestionaire} />
                    </>
                );
            }

            return (
                <>
                    <Stack.Screen name="HomeNavigator" component={HomeNavigator} />
                    <Stack.Screen name="GameScreen" component={GameScreen} />
                    <Stack.Screen name="PaymentPage" component={PaymentPage} />
                    <Stack.Screen name="ContactUs" component={ContactUs} />
                    <Stack.Screen name="MyAccountPassword" component={MyAccountPassword} />
                    <Stack.Screen name="MyAccountPersonalInformation" component={MyAccountPersonalInformation} />
                </>
            );
        }
        return null;
    }

    function renderGuestRoutes(): ReactNode {
        if (!isSignedIn) {
            return (
                <>
                    {isMobileApp && !isSignedIn &&
                        <Stack.Screen name="MobileLandingPage" component={MobileLandingPage} />
                    }
                    {/* {!isMobileApp && <Stack.Screen name="InstallApp" component={InstallApp} />}  // TODO: We might need to get rid of this or alternatively use this on mobile web. */}
                    <Stack.Screen name="Login" component={Login} />
                    <Stack.Screen name="SignUp" component={SignUp} />
                    <Stack.Screen name="ForgotPassword" component={ForgotPassword} />
                </>
            );
        }

        return null;
    }

    if (serverIsDown) {
        return (
            <ServerIsDownPage onTryAgainPressed={refetchServerVersion} isLoading={isLoadingServerVersion} />
        );
    }

    if ((isMobileApp && minimumMobileJSVersion) && utils.compareVersion(jsVersion, minimumMobileJSVersion) < 0) {
        return (
            <UpdateApp version={minimumMobileJSVersion} />
        );
    }

    // TODO: We might need to get rid of this or alternatively use this on mobile web.
    // if (isSignedIn && ((isMobileApp && currentUserData?.userRole === UserRole.admin) || (!isMobileApp && currentUserData?.userRole === UserRole.user))) {
    //     return (
    //         <UnSupportedAccess type={currentUserData.userRole} />
    //     );
    // }

    if ((isMobileApp && updateStatus === undefined) || signedInStatus === SIGNED_IN_STATUS.loading || isSignedIn && currentUserData === undefined || !serverVersion || (isMobileApp && !minimumMobileJSVersion)) {
        return <LoadingPage giveUpAction={LOADING_PAGE_GIVE_UP_ACTION_TYPES.signOut} hideGiveUpButton={serverIsDown === undefined} />;
    }

    return (
        <NavigationContainer
            linking={linking}
            ref={navRef}
            onStateChange={(state) => {
                if (isMobileApp) {
                    const currentScreen = state?.routes[state.index].name;
                    if (currentScreen === 'GameScreen') {
                        MB_orientationLocker.setOrientation(MB_ORIENTATION_LOCK.ALL);
                    } else {
                        MB_orientationLocker.restoreDefault();
                    }
                }
            }}
        >
            <Stack.Navigator
                screenOptions={{
                    headerShown: false,
                    gestureEnabled: false,
                }}
            >
                {renderPublicRoutes()}
                {renderGuestRoutes()}
                <Stack.Screen name="NotFound" component={NotFound} />
                <Stack.Screen name="ServerRedirect" component={ServerRedirect} />
            </Stack.Navigator>
        </NavigationContainer >
    );
};

export default RootNavigation;
