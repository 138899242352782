import { mbHidePopUp, mbShowPopUp } from '@mightybyte/rnw.components.pop-up';
import { mbPlatformStyle } from '@mightybyte/rnw.utils.style-utils';
import { useNavigation } from '@react-navigation/core';
import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useRef } from 'react';
import { StyleSheet, ViewStyle, useWindowDimensions } from 'react-native';
import { COLORS } from '../../../constants/colors';
import { QUESTION_QUERY_KEYS, STRING_CONSTANTS, USER_QUERY_KEYS } from '../../../constants/constants';
import { usePaymentContext } from '../../../context/PaymentContextProvider';
import { useResetQuestionCompletion } from '../../../hooks/userHooks';
import { SelectCategoryProps, UniversalScreenNavigationProp } from '../../../typesAndInterfaces/componentProps';
import { CategoryForUser, GAME_TYPE, PAYMENT_STATUS, TOOL_TIP_AUTO_SHOW_COLLECTION } from '../../../typesAndInterfaces/typesAndInterfaces';
import { utils } from '../../../utils/utils';
import { MobileHeaderProps } from '../../helperComponents/Header/MobileHeader';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import { HeaderWithDifficultySelector } from './HeaderWithDifficultySelector';
import { CategoryCard } from '../../helperComponents/CategoryCard';
import { envs } from '../../../../env';
import { Analytics } from '../../../utils/Analytics';
import { useSignedInContext } from '../../../context/SignedInContext';
import { CategoriesView } from '../../helperComponents/CategoriesView';
import { utilHooks } from '../../../hooks/utilHooks';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { mbGetMediumImage } from '@mightybyte/rnw.components.image';

const SelectCategory = ({ route }: SelectCategoryProps) => {
  const navigation = useNavigation<UniversalScreenNavigationProp>();
  const { currentUserData } = useSignedInContext();
  const { gameType } = route.params;

  const { width } = useWindowDimensions();

  const pageTitleTooltip = gameType === GAME_TYPE.IntroGame ? TOOL_TIP_AUTO_SHOW_COLLECTION.IntroGame : gameType === GAME_TYPE.MatchingItemsGame ? TOOL_TIP_AUTO_SHOW_COLLECTION.MatchingItemsGame : undefined;

  const mobileHeaderOptions = useRef<MobileHeaderProps>({ showHeader: true, showBackArrow: true, pageTitle: utils.gameTypeToString(gameType), pageTitleTooltip });

  const { paymentStatus } = usePaymentContext();
  const { canAccessGames } = utilHooks.useGetAccess(currentUserData);

  const queryClient = useQueryClient();

  const { mutate: restartQuestionCompletion } = useResetQuestionCompletion();

  const onCategoryPressed = useCallback((categoryId: string, tutorialUrl: string | undefined, numberOfQuestions: number, completedQuestions: number, categoryTitle: string) => {

    function sendAnalyticsEvent() {
      if (!currentUserData) {
        return;
      }

      Analytics.logEvent('gameStarted', {
        gameCategoryName: categoryTitle,
        gameCategoryId: categoryId,
        userId: currentUserData._id,
        gameType: gameType,
      });
    }

    if (completedQuestions < numberOfQuestions) {
      sendAnalyticsEvent();
      navigation.navigate('GameScreen', { categoryIds: [categoryId], index: 0, categoryParams: { tutorialUrl, categoryTitle } });
    } else {
      mbShowPopUp({
        title: 'Restart Game',
        message: 'This category was already completed. Are you sure you want to restart the game?',
        buttonText: 'Restart',
        buttonStyle: {
          container: {
            backgroundColor: COLORS.buttonPurple,
          },
        },
        buttonAction: () => {
          restartQuestionCompletion(categoryId, {
            onError: (error) => {
              mbShowPopUp({
                title: STRING_CONSTANTS.ERROR,
                message: STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN + (envs.FLAVOR === 'prod' ? '' : `\nerror: ${error.message}`), // TODO: DeleteME;
              });
            },
            onSuccess: () => {
              queryClient.invalidateQueries([USER_QUERY_KEYS.getCategoryForHomePage]);
              queryClient.invalidateQueries([QUESTION_QUERY_KEYS.getIncompleteQuestions, categoryId]);
              mbHidePopUp();
              sendAnalyticsEvent();
              setTimeout(() => navigation.navigate('GameScreen', { categoryIds: [categoryId], index: 0, categoryParams: { tutorialUrl, categoryTitle } }), 400);
            },
          });
        },
        secondaryButtonText: 'Cancel',
        secondaryButtonStyle: {
          text: {
            color: COLORS.textPurple,
          },
          container: {
            backgroundColor: COLORS.white,
          },
        },
      });
    }
  }, [currentUserData, gameType, navigation, queryClient, restartQuestionCompletion]);

  const RenderCategoryComponent = useCallback((item: CategoryForUser | string, index: number, isLoading: boolean) => {
    const onPress = () => {
      const isDisabled = typeof item === 'string' ? false : (!item.isFree && paymentStatus !== PAYMENT_STATUS.paid && !canAccessGames);
      if (isDisabled) {
        navigation.navigate('PaymentPage');
      } else {
        const categoryId = typeof item === 'string' ? '' : item._id;
        const tutorialUrl = typeof item === 'string' ? undefined : item.tutorialURL;
        const numberOfQuestions = typeof item === 'string' ? 0 : item.numberOfQuestions;
        const numberOfQuestionsCompleted = typeof item === 'string' ? 0 : item.numberOfQuestionsCompleted;
        const categoryTitle = typeof item === 'string' ? '' : item.name;
        onCategoryPressed(categoryId, tutorialUrl, numberOfQuestions, numberOfQuestionsCompleted, categoryTitle);
      }
    };

    const image = typeof item !== 'string' && typeof item.image !== 'string' ? mbGetMediumImage(item.image) : undefined;

    return (
      <CategoryCard
        index={index}
        title={typeof item === 'string' ? '' : item.name}
        totalItems={typeof item === 'string' ? 0 : item.numberOfQuestions}
        completedItems={typeof item === 'string' ? 0 : item.numberOfQuestionsCompleted}
        tutorialUrl={typeof item === 'string' ? undefined : item.tutorialURL}
        showStartHere={false}
        isLoading={isLoading}
        isDisabled={typeof item === 'string' ? false : (!item.isFree && paymentStatus !== PAYMENT_STATUS.paid && !canAccessGames)}
        image={image}
        onPress={onPress}
      />
    );
  }, [canAccessGames, navigation, onCategoryPressed, paymentStatus]);

  const cardWidth = 327 + 24;
  const numberOfColumns = Math.max(Math.floor((width - 300) / cardWidth), 1);

  return (
    <ComponentWrapper
      containerStyle={styles.container}
      innerContainerStyle={styles.innerContainer}
      mobileHeaderOptions={mobileHeaderOptions.current}
      keyboardShouldPersistTapsForScrollView="always"
      wrapInScrollView={false}
      mountPremiumBanner
      onBackPress={() => navigation.navigate('HomeNavigator', { screen: 'GameNavigator', params: { screen: 'SelectGame' } })}
    >
      <HeaderWithDifficultySelector gameType={gameType} />
      <CategoriesView
        gameType={gameType}
        renderItem={RenderCategoryComponent}
        style={[styles.categoriesView, !isMobileApp && { width: numberOfColumns * cardWidth }]}
        contentContainer={styles.categoriesViewContent}
        numColumns={!isMobileApp ? numberOfColumns : undefined}
        key={!isMobileApp ? numberOfColumns : undefined}
      />
    </ComponentWrapper >
  );
};

export { SelectCategory };

const styles = StyleSheet.create({
  container: {
    ...mbPlatformStyle({
      web: {
        minWidth: 1000,
        flex: 1,
      },
    }),
  },
  innerContainer: {
    ...mbPlatformStyle({
      web: {
        flex: 1,
        maxWidth: 'auto',
        overflowY: 'scroll',
      } as ViewStyle,
    }),
  },
  categoryCardImage: {
    width: 140,
    height: 100,
    position: 'absolute',
    top: 12,
    right: 16,
  },
  categoriesView: {
    ...mbPlatformStyle({
      web: {
        alignSelf: 'center',
      },
      mobile: {
        flex: 1,
      },
    }),
  },
  categoriesViewContent: {
    paddingBottom: 80,
  },
});
