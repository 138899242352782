import { default as React, memo, useCallback, useEffect, useRef, useState } from 'react';
import { Animated, BackHandler, Dimensions, LayoutChangeEvent, StyleSheet, TouchableOpacity, View } from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { COLORS } from '../../../constants/colors';
import { GAME_TYPE, Question } from '../../../typesAndInterfaces/typesAndInterfaces';
import { ShoppingGameControls } from '../ShoppingGame/ShoppingGameControls';
import { IntroGameCards } from './IntroGameCards';
import { BodyPartsControls } from './bodyPartsControls/BodyPartsControls';
import { GeographyControls } from './geographyControls/GeographyControls';

interface GameControlsProps {
    question: Question,
    disableAnswers: boolean,
    showExitModal: () => void,
    onAnswerPressed: (isCorrect: boolean) => void,
    isPortrait: boolean | undefined,
    //bodyPartsControlsBaseRef?: React.RefObject<BodyPartsControlsBaseRef>,
}

interface CardManagerProps {
    question: Question,
    disableAnswers: boolean,
    onAnswerPressed: (isCorrect: boolean) => void,
    isPortrait?: boolean, // TOOD: Can`t we figure this out internally ?
}

const CardManager = memo(({ question, disableAnswers, onAnswerPressed, isPortrait }: CardManagerProps) => {
    if (question.gameType === GAME_TYPE.IntroGame) {
        return (
            <IntroGameCards
                question={question}
                disableAnswers={disableAnswers}
                onAnswerPressed={onAnswerPressed}
                isPortrait={isPortrait ?? true}
            />
        );
    } else if (question.gameType === GAME_TYPE.MatchingItemsGame) {
        return (
            <ShoppingGameControls
                question={question}
                disableAnswers={disableAnswers}
                onAnswerPressed={onAnswerPressed}
                isPortrait={isPortrait ?? true}
            />
        );
    }

    return null;
});

const CardManagerLandscape = ({ question, disableAnswers, onAnswerPressed, isPortrait }: CardManagerProps) => {
    const [landscaleCardsContainerWidth, setLndscaleCardsContainerWidth] = useState(0);
    const [isGameCardModalVisible, setIsGameCardModalVisible] = useState(false);
    const gameCardModalTranslateX = useRef(new Animated.Value(Dimensions.get('window').width)).current;
    const [isInitialized, setIsInitialized] = useState(false);

    const onGameCardContainerLayout = (event: LayoutChangeEvent) => {
        const { width } = event.nativeEvent.layout;
        setLndscaleCardsContainerWidth(width);
    };

    const toggleGameCardModal = useCallback((duration: number) => {
        setIsGameCardModalVisible(old => !old);
        Animated.timing(gameCardModalTranslateX, {
            toValue: isGameCardModalVisible ? 0 : landscaleCardsContainerWidth,
            duration: duration ?? 300,
            useNativeDriver: true,
        }).start();
    }, [gameCardModalTranslateX, isGameCardModalVisible, landscaleCardsContainerWidth]);

    useEffect(() => {
        if (!isInitialized && landscaleCardsContainerWidth !== 0) {
            setIsInitialized(true);
            toggleGameCardModal(0);
        }
    }, [isInitialized, landscaleCardsContainerWidth, toggleGameCardModal]);

    return (
        <Animated.View
            style={[
                styles.portraitControlsContainer,
                { transform: [{ translateX: gameCardModalTranslateX }] },
            ]}
            onLayout={onGameCardContainerLayout}
        >
            <TouchableOpacity
                style={[
                    styles.showHideCardsButtonContainer,
                    styles.showHideCardsButton,
                    { paddingEnd: isGameCardModalVisible ? 40 : 8 },
                ]}
                onPress={() => toggleGameCardModal(300)}
            >
                <MaterialCommunityIcons
                    name={isGameCardModalVisible ? 'arrow-left-drop-circle' : 'arrow-right-drop-circle'}
                    size={20}
                    color={COLORS.white}
                />
            </TouchableOpacity>
            <CardManager
                question={question}
                disableAnswers={disableAnswers}
                onAnswerPressed={onAnswerPressed}
                isPortrait={isPortrait}
            />
        </Animated.View>
    );
};

const GameControls = memo(({ question, disableAnswers, showExitModal, onAnswerPressed, isPortrait }: GameControlsProps) => {
    // Handles back button event
    useEffect(() => {
        const backHandlerListener = BackHandler.addEventListener('hardwareBackPress', () => {
            showExitModal();
            return true;
        });

        return () => {
            backHandlerListener.remove();
        };
    }, [showExitModal]);

    if (question.gameType === GAME_TYPE.BodyParts) {
        return (
            <BodyPartsControls
                question={question}
                onAnswerPressed={onAnswerPressed}
                showCloseButton
                //bodyPartsControlsBaseRef={bodyPartsControlsBaseRef}
            />
        );
    }

    if (question.gameType === GAME_TYPE.Geography) {
        return (
            <GeographyControls
                question={question}
                onAnswerPressed={onAnswerPressed}
                disableAnswers={disableAnswers}
            />
        );
    }

    return (
        <View>
            {isPortrait ?
                <CardManager
                    question={question}
                    disableAnswers={disableAnswers}
                    onAnswerPressed={onAnswerPressed}
                    isPortrait={true}
                />
                :
                <CardManagerLandscape
                    question={question}
                    disableAnswers={disableAnswers}
                    onAnswerPressed={onAnswerPressed}
                    isPortrait={false}
                />
            }
        </View>
    );
});

export { GameControls };

const styles = StyleSheet.create({
    portraitControlsContainer: {
        flexDirection: 'row',
        position: 'absolute',
        right: 0,
        bottom: 0,
        top: 0,
    },
    showHideCardsButtonContainer: {
        alignSelf: 'center',
        zIndex: 1,
    },
    showHideCardsButton: {
        backgroundColor: COLORS.buttonPurple,
        paddingVertical: 13,
        paddingStart: 8,
        borderRadius: 10,
        marginEnd: -8,
    },
});
