import { isMobile } from '@mightybyte/rnw.utils.device-info';
import { mbPlatformStyle } from '@mightybyte/rnw.utils.style-utils';
import React, { useRef } from 'react';
import { StyleSheet } from 'react-native';
import { ChangePasswordPopUp } from '../../helperComponents/ChangePasswordPopUp';
import { MobileHeaderProps } from '../../helperComponents/Header/MobileHeader';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import { COLORS } from '../../../constants/colors';
import { useNavigation } from '@react-navigation/core';
import { UniversalScreenNavigationProp } from '../../../typesAndInterfaces/componentProps';

const MyAccountPassword = () => {
    const mobileHeaderOptions = useRef<MobileHeaderProps>({ showHeader: true, showBackArrow: true, pageTitle: 'Change Password' });
    const navigation = useNavigation<UniversalScreenNavigationProp>();

    return (
        <ComponentWrapper
            wrapInScrollView={false}
            mobileHeaderOptions={mobileHeaderOptions.current}
            onBackPress={() => navigation.navigate('HomeNavigator', { screen: 'GameNavigator', params: { screen: 'SelectGame' } })}
        >
            <ChangePasswordPopUp
                requireOldPassword={true}
                style={styles.modalContainer}
                submitButton={{ style: styles.submitButton, title: 'Change Password' }}
            />
        </ComponentWrapper>
    );
};

export { MyAccountPassword };

const styles = StyleSheet.create({
    submitButton: {
        width: 320,
        height: 54,
        marginTop: 43,
    },
    modalContainer: {
        flex: 1,
        alignItems: 'flex-start',
        marginTop: 22,
        minWidth: isMobile ? '100%' : 412,
        ...mbPlatformStyle({
            mobile: {
                alignItems: undefined,
                justifyContent: undefined,
                width: '100%',
            },
        }),
        ...mbPlatformStyle({
            web: {
                shadowRadius: 22,
                shadowColor: COLORS.bottombBarBlurBackgroundColor,
                backgroundColor: COLORS.darkPurple,
                borderRadius: 22,
                alignSelf: 'center',
                padding: 20,
            },
        }),
    },
});
