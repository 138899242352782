import React from 'react';
import { Linking, ScrollView, StyleSheet, Text, View } from 'react-native';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { Image } from 'react-native';
import { COLORS } from '../../../constants/colors';
import { textStyles } from '../../../constants/textStyles';
import { imageUtils } from '../../../utils/imageUtils';
import { SocialLoginButtons } from '../../helperComponents/SocialLoginButtons';
import SignupInput from './SignupInput';
import { SignupUIProps } from './SignupUtils';
import { MB_TextInputToolTipPortal } from '@mightybyte/rnw.components.text-input';
import LoginBackground from '../../../resources/svgComponents/LoginBackground';
import { envs } from '../../../../env';

const SignupUI = (props: SignupUIProps) => {

    return (
        <View style={styles.container}>
            <View style={styles.loginBannerContainer}>
                <LoginBackground />
            </View>

            <ScrollView style={{ flex: 1 }} contentContainerStyle={styles.scrollViewContentContainer}>
                <View style={styles.inputContainer}>
                    <Image style={{ width: 237, height: 163 }} source={imageUtils.images.aslFlurryLogo} resizeMode="contain" />
                    {!props.isAskingForSignUpCode &&
                        <>
                            <SocialLoginButtons callback={props.socialLoginCallback} containerStyle={styles.socialLoginButtonContainer} buttonStyle={styles.socialLoginButton} />
                            <View style={styles.orLabelContainer}>
                                <View style={styles.orLabelLine} />
                                <Text style={styles.orLabel}>OR REGISTER WITH</Text>
                                <View style={styles.orLabelLine} />
                            </View>
                        </>
                    }
                    <SignupInput {...props} containerStyle={[styles.signUpInput, { marginTop: props.isAskingForSignUpCode ? 0 : 26 }]} />
                    <Text style={styles.note}>By signing up you are accepting our <Text style={styles.noteLink} onPress={() => Linking.openURL(envs.PRIVACY_POLICY_URL)}>Privacy Policy</Text> and <Text style={styles.noteLink} onPress={() => Linking.openURL(envs.TOS_URL)}>Terms of Use.</Text></Text>
                    <MB_TextInputToolTipPortal MB_Ref={props.portalRef} />
                </View>
                <Text style={styles.noteText}>Already have an account? <Text style={styles.signInTxt} onPress={props.navigateToLogin}>Sign In</Text></Text>
            </ScrollView>
        </View>
    );
};

export default SignupUI;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: COLORS.backgroundDarkPurple,
        overflow: 'hidden',
        minWidth: 600,
    },
    loginBannerContainer: {
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
    },
    scrollViewContentContainer: {
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
        marginVertical: 32,
    },
    titleLabel: mbTextStyles([
        textStyles.largestText, {
            fontSize: 24,
            marginTop: 8,
        },
    ]),
    socialLoginButtonContainer: {
        marginTop: 16,
    },
    socialLoginButton: {
        borderWidth: 0,
        backgroundColor: COLORS.backgroundPurple,
        borderRadius: 8,
    },
    orLabelLine: {
        flex: 1,
        height: 1,
        backgroundColor: COLORS.white,
    },
    orLabel: mbTextStyles([
        textStyles.smallText, {
            color: COLORS.white,
            marginHorizontal: 8,
        },
    ]),
    orLabelContainer: {
        marginTop: 16,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'stretch',
    },
    signUpButton: {
        borderRadius: 8,
        marginTop: 10,
        backgroundColor: COLORS.backgroundPurple,
        marginBottom: 30,
    },
    inputContainer: {
        backgroundColor: COLORS.darkPurple,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 22,
        paddingHorizontal: 64,
        paddingTop: 52,
        paddingBottom: 46,
        marginTop: 28,
        shadowRadius: 22,
        shadowColor: COLORS.bottombBarBlurBackgroundColor,
    },
    signUpInput: {
        marginTop: 25,
        alignSelf: 'stretch',
    },
    logoImage: {
        width: 123,
        height: 81,
        alignSelf: 'center',
    },
    verifyTitleLabel: mbTextStyles([
        textStyles.largeText, {
            alignSelf: 'flex-start',
            fontWeight: '700',
        },
    ]),
    noteText: mbTextStyles([
        textStyles.smallText, {
            color: COLORS.textLightPurple,
            marginTop: 10,
            textAlign: 'left',
        },
    ]),
    signInTxt: {
        textDecorationLine: 'underline',
    },
    note: mbTextStyles([
        textStyles.smallerText, {
            fontSize: 13,
            fontWeight: '600',
            marginTop: 34,
            width: 334,
        },
    ]),
    noteLink: {
        textDecorationLine: 'underline',
        color: '#D043DC',
        fontWeight: '400',
    },
});
