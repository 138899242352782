import { StyleProp, ViewStyle } from 'react-native';
import { BodyTypesSVG_eye } from '../../../../resources/svgComponents/bodyParts/BodyTypesSVG_eye';
import { BodyTypesSVG_fullBodyFront } from '../../../../resources/svgComponents/bodyParts/BodyTypesSVG_fullBodyFront';
import { BodyTypesSVG_face } from '../../../../resources/svgComponents/bodyParts/BodyTypesSVG_face';
import { BodyTypesSVG_arm } from '../../../../resources/svgComponents/bodyParts/BodyTypesSVG_arm';
import { BodyTypesSVG_torso } from '../../../../resources/svgComponents/bodyParts/BodyTypesSVG_torso';
import { BodyTypesSVG_leg } from '../../../../resources/svgComponents/bodyParts/BodyTypesSVG_leg';
import { BodyTypesSVG_foot } from '../../../../resources/svgComponents/bodyParts/BodyTypesSVG_foot';
import { BodyTypesSVG_nose } from '../../../../resources/svgComponents/bodyParts/BodyTypesSVG_nose';
import { BodyTypesSVG_ear } from '../../../../resources/svgComponents/bodyParts/BodyTypesSVG_ear';
import { BodyTypesSVG_mouth } from '../../../../resources/svgComponents/bodyParts/BodyTypesSVG_mouth';
import { BodyTypesSVG_hand } from '../../../../resources/svgComponents/bodyParts/BodyTypesSVG_hand';



//////////////////////////////////// TODO: Move these to interface file
interface BodyPartsSvgProps {
    onPress: (tag: BODY_PARTS, selectedOrZoom: 'selected' | 'zoom') => void
    containerStyle?: StyleProp<ViewStyle>
    highlightedParts: BODY_PARTS[]
}

interface BodyPartsSvgConfig {
    select: boolean,
    enlarge: boolean,
    xray: boolean,
    presentationStyle: 'fullScreen' | 'modal',
    element: ((props: BodyPartsSvgProps) => JSX.Element) | undefined,
    tag: string,
    displayText: string,
}
//////////////////////////////////////////////////////////////////////

enum BODY_PARTS {
    // ------------- Full Body ------------- //
    torso = 'torso',
    fullBodyFront = 'fullBodyFront',
    head = 'head',
    arm = 'arm',
    leg = 'leg',
    hips = 'hips',

    // ------------- Arm ------------- //
    hand = 'hand',
    forearm = 'forearm',
    armpit = 'armpit',
    elbow = 'elbow',

    // ------------- Leg ------------- //
    foot = 'foot',
    knee = 'knee',

    // ------------- Foot ------------- //
    arc = 'arc',
    toe = 'toe',
    ankle = 'ankle',
    toenails = 'toenails',
    // ------------- Face ------------- //
    ear = 'ear',
    mouth = 'mouth',
    jaw = 'jaw',
    chin = 'chin',
    eye = 'eye',
    nose = 'nose',
    hair = 'hair',
    neck = 'neck',
    cheek = 'cheek',
    forehead = 'forehead',

    // ------------- Face ------------- //
    chest = 'chest',
    belly = 'belly',
    bellyButton = 'bellyButton',

    // ------------- Nose ------------- //
    nostrils = 'nostrils',

    // ------------- Eye ------------- //
    eyebrow = 'eyebrow',
    eyelash = 'eyelash',
    eyelid = 'eyelid',

    // ------------- Ear ------------- //
    earlobe = 'earlobe',
    eardrum = 'eardrum',

    // ------------- Mouth ------------- //
    lips = 'lips',

    // ------------- Hand ------------- //
    knuckles = 'knuckles',
    fingernails = 'fingernails',
    palm = 'palm',
    fingers = 'fingers',

    // ------------- Fingers ------------- //
    thumb = 'thumb',
    indexFinger = 'indexFinger',
    middleFinger = 'middleFinger',
    ringFinger = 'ringFinger',
    pinkieFinger = 'pinkieFinger',
}

const bodyPartsSvgConfigs: { [key in keyof typeof BODY_PARTS]: BodyPartsSvgConfig } = {
    //--------------------------------------------------------------------------------------//
    //------------------------------------- Full Body  -------------------------------------//
    //--------------------------------------------------------------------------------------//
    fullBodyFront: {
        select: true,
        enlarge: true,
        xray: false,
        presentationStyle: 'fullScreen',
        element: BodyTypesSVG_fullBodyFront,
        tag: 'fullBodyFront',
        displayText: 'Body',
    },
    head: {
        select: true,
        enlarge: true,
        xray: false,
        presentationStyle: 'modal',
        element: BodyTypesSVG_face,
        tag: 'head',
        displayText: 'Face/Head',
    },
    arm: {
        select: true,
        enlarge: true,
        xray: false,
        presentationStyle: 'modal',
        element: BodyTypesSVG_arm,
        tag: 'arm',
        displayText: 'Arm',
    },
    leg: {
        select: true,
        enlarge: true,
        xray: false,
        presentationStyle: 'modal',
        element: BodyTypesSVG_leg,
        tag: 'leg',
        displayText: 'Legs',
    },
    hips: {
        select: true,
        enlarge: false,
        xray: false,
        presentationStyle: 'modal',
        element: undefined,
        tag: 'hips',
        displayText: 'Hips',
    },
    torso: {
        select: true,
        enlarge: true,
        xray: false,
        presentationStyle: 'modal',
        element: BodyTypesSVG_torso,
        tag: 'torso',
        displayText: 'Torso',
    },
    //--------------------------------------------------------------------------------------//
    //---------------------------------------- Arm  ----------------------------------------//
    //--------------------------------------------------------------------------------------//
    hand: {
        select: true,
        enlarge: true,
        xray: false,
        presentationStyle: 'modal',
        element: BodyTypesSVG_hand,
        tag: 'hand',
        displayText: 'Hand',
    },
    forearm: {
        select: true,
        enlarge: false,
        xray: false,
        presentationStyle: 'modal',
        element: undefined,
        tag: 'forearm',
        displayText: 'Forearm',
    },
    armpit: {
        select: true,
        enlarge: false,
        xray: false,
        presentationStyle: 'modal',
        element: undefined,
        tag: 'armpit',
        displayText: 'Armpit',
    },
    elbow: {
        select: true,
        enlarge: false,
        xray: false,
        presentationStyle: 'modal',
        element: undefined,
        tag: 'elbow',
        displayText: 'Elbow',
    },
    //--------------------------------------------------------------------------------------//
    //--------------------------------------- Torso ----------------------------------------//
    //--------------------------------------------------------------------------------------//
    chest: {
        select: true,
        enlarge: false,
        xray: false,
        presentationStyle: 'modal',
        element: undefined,
        tag: 'chest',
        displayText: 'Chest',
    },
    belly: {
        select: true,
        enlarge: false,
        xray: false,
        presentationStyle: 'modal',
        element: undefined,
        tag: 'belly',
        displayText: 'Belly',
    },
    bellyButton: {
        select: true,
        enlarge: false,
        xray: false,
        presentationStyle: 'modal',
        element: undefined,
        tag: 'bellyButton',
        displayText: 'Belly Button',
    },
    //--------------------------------------------------------------------------------------//
    //---------------------------------------- Leg ----------------------------------------//
    //--------------------------------------------------------------------------------------//
    foot: {
        select: true,
        enlarge: true,
        xray: false,
        presentationStyle: 'modal',
        element: BodyTypesSVG_foot,
        tag: 'foot',
        displayText: 'Foot',
    },
    knee: {
        select: true,
        enlarge: false,
        xray: false,
        presentationStyle: 'modal',
        element: undefined,
        tag: 'knee',
        displayText: 'Knee',
    },
    //--------------------------------------------------------------------------------------//
    //---------------------------------------- Foot ----------------------------------------//
    //--------------------------------------------------------------------------------------//
    arc: {
        select: true,
        enlarge: false,
        xray: false,
        presentationStyle: 'modal',
        element: undefined,
        tag: 'arc',
        displayText: 'Arch',
    },
    toe: {
        select: true,
        enlarge: false,
        xray: false,
        presentationStyle: 'modal',
        element: undefined,
        tag: 'toe',
        displayText: 'Toes',
    },
    ankle: {
        select: true,
        enlarge: false,
        xray: false,
        presentationStyle: 'modal',
        element: undefined,
        tag: 'ankle',
        displayText: 'Ankle',
    },
    toenails: {
        select: true,
        enlarge: false,
        xray: false,
        presentationStyle: 'modal',
        element: undefined,
        tag: 'toenails',
        displayText: 'Toenails',
    },
    //--------------------------------------------------------------------------------------//
    //---------------------------------------- Head ----------------------------------------//
    //--------------------------------------------------------------------------------------//
    ear: {
        select: true,
        enlarge: true,
        xray: false,
        presentationStyle: 'modal',
        element: BodyTypesSVG_ear,
        tag: 'ear',
        displayText: 'Ears',
    },
    jaw: {
        select: true,
        enlarge: false,
        xray: false,
        presentationStyle: 'modal',
        element: undefined,
        tag: 'jaw',
        displayText: 'Jaw',
    },
    chin: {
        select: true,
        enlarge: false,
        xray: false,
        presentationStyle: 'modal',
        element: undefined,
        tag: 'chin',
        displayText: 'Chin',
    },
    mouth: {
        select: true,
        enlarge: true,
        xray: false,
        presentationStyle: 'modal',
        element: BodyTypesSVG_mouth,
        tag: 'mouth',
        displayText: 'Mouth',
    },
    eye: {
        select: true,
        enlarge: true,
        xray: false,
        presentationStyle: 'modal',
        element: BodyTypesSVG_eye,
        tag: 'eye',
        displayText: 'Eyes',
    },
    hair: {
        select: true,
        enlarge: false,
        xray: false,
        presentationStyle: 'modal',
        element: undefined,
        tag: 'hair',
        displayText: 'Hair',
    },
    neck: {
        select: true,
        enlarge: false,
        xray: false,
        presentationStyle: 'modal',
        element: undefined,
        tag: 'neck',
        displayText: 'Neck',
    },
    cheek: {
        select: true,
        enlarge: false,
        xray: false,
        presentationStyle: 'modal',
        element: undefined,
        tag: 'cheek',
        displayText: 'Cheek',
    },
    forehead: {
        select: true,
        enlarge: false,
        xray: false,
        presentationStyle: 'modal',
        element: undefined,
        tag: 'forehead',
        displayText: 'Forehead',
    },
    nose: {
        select: true,
        enlarge: true,
        xray: false,
        presentationStyle: 'modal',
        element: BodyTypesSVG_nose,
        tag: 'nose',
        displayText: 'Nose',
    },
    //--------------------------------------------------------------------------------------//
    //---------------------------------------- Eyes ----------------------------------------//
    //--------------------------------------------------------------------------------------//
    eyebrow: {
        select: true,
        enlarge: false,
        xray: false,
        presentationStyle: 'modal',
        element: undefined,
        tag: 'eyebrow',
        displayText: 'Eyebrow',
    },
    eyelash: {
        select: true,
        enlarge: false,
        xray: false,
        presentationStyle: 'modal',
        element: undefined,
        tag: 'eyelash',
        displayText: 'Eyelashes',
    },
    eyelid: {
        select: true,
        enlarge: false,
        xray: false,
        presentationStyle: 'modal',
        element: undefined,
        tag: 'eyelid',
        displayText: 'Eyelid',
    },
    //--------------------------------------------------------------------------------------//
    //---------------------------------------- Nose ----------------------------------------//
    //--------------------------------------------------------------------------------------//
    nostrils: {
        select: true,
        enlarge: false,
        xray: false,
        presentationStyle: 'modal',
        element: undefined,
        tag: 'nostrils',
        displayText: 'Nostrils',
    },
    //--------------------------------------------------------------------------------------//
    //---------------------------------------- Ear ----------------------------------------//
    //--------------------------------------------------------------------------------------//
    earlobe: {
        select: true,
        enlarge: false,
        xray: false,
        presentationStyle: 'modal',
        element: undefined,
        tag: 'earlobe',
        displayText: 'Earlobe',
    },
    eardrum: {
        select: true,
        enlarge: false,
        xray: false,
        presentationStyle: 'modal',
        element: undefined,
        tag: 'eardrum',
        displayText: 'Eardrum',
    },
    //--------------------------------------------------------------------------------------//
    //--------------------------------------- Mouth ----------------------------------------//
    //--------------------------------------------------------------------------------------//
    lips: {
        select: true,
        enlarge: false,
        xray: false,
        presentationStyle: 'modal',
        element: undefined,
        tag: 'lips',
        displayText: 'Lips',
    },
    //--------------------------------------------------------------------------------------//
    //--------------------------------------- Hand ----------------------------------------//
    //--------------------------------------------------------------------------------------//
    knuckles: {
        select: true,
        enlarge: false,
        xray: false,
        presentationStyle: 'modal',
        element: undefined,
        tag: 'knuckles',
        displayText: 'Knuckles',
    },
    fingernails: {
        select: true,
        enlarge: false,
        xray: false,
        presentationStyle: 'modal',
        element: undefined,
        tag: 'fingernails',
        displayText: 'Fingernails',
    },
    palm: {
        select: true,
        enlarge: false,
        xray: false,
        presentationStyle: 'modal',
        element: undefined,
        tag: 'palm',
        displayText: 'Palm',
    },
    fingers: {
        select: true,
        enlarge: true,
        xray: false,
        presentationStyle: 'modal',
        element: undefined,
        tag: 'fingers',
        displayText: 'Fingers',
    },


    //--------------------------------------------------------------------------------------//
    //--------------------------------------- Fingers ----------------------------------------//
    //--------------------------------------------------------------------------------------//
    thumb: {
        select: true,
        enlarge: false,
        xray: false,
        presentationStyle: 'modal',
        element: undefined,
        tag: 'thumb',
        displayText: 'Thumb',
    },
    indexFinger: {
        select: true,
        enlarge: false,
        xray: false,
        presentationStyle: 'modal',
        element: undefined,
        tag: 'indexFinger',
        displayText: 'Index Finger',
    },
    middleFinger: {
        select: true,
        enlarge: false,
        xray: false,
        presentationStyle: 'modal',
        element: undefined,
        tag: 'middleFinger',
        displayText: 'Middle Finger',
    },
    ringFinger: {
        select: true,
        enlarge: false,
        xray: false,
        presentationStyle: 'modal',
        element: undefined,
        tag: 'ringFinger',
        displayText: 'Ring Finger',
    },
    pinkieFinger: {
        select: true,
        enlarge: false,
        xray: false,
        presentationStyle: 'modal',
        element: undefined,
        tag: 'pinkieFinger',
        displayText: 'Pinkie Finger',
    },
};


export { BODY_PARTS, bodyPartsSvgConfigs };
export type { BodyPartsSvgProps, BodyPartsSvgConfig };
