import { useEffect, useState } from 'react';
import { Keyboard, Platform } from 'react-native';
import { initialWindowMetrics } from 'react-native-safe-area-context';
import { EventEmitter } from 'fbemitter';

const MAX_HEIGT_SIZE = 4000;
const MIN_HEIGT_SIZE = 0;

const isNumber = (value: string) => !value || /^\d+$/.test(value);

const onChange = (fun?: (value: string) => void) => (value: string) => isNumber(value) && fun?.(value);

const KeyboardEmitter = new EventEmitter();

function useKeybaord() {
    const [keyboardState, setKeyboardState] = useState({ visible: false, height: 0 });

    useEffect(() => {
        const inputFocus = KeyboardEmitter.addListener(
            'inputFocus',
            () => setKeyboardState(prev => prev.visible ? prev : { visible: true, height: MAX_HEIGT_SIZE })
        );
        const keyboardDidShow = Keyboard.addListener(
            Platform.OS === 'ios' ? 'keyboardWillShow' : 'keyboardDidShow',
            (ev) => {
                const keyboardHeight = ev.endCoordinates.height;
                const bottomEdge = (Platform.OS === 'ios' && initialWindowMetrics?.insets.bottom) || 3;
                const height = keyboardHeight - bottomEdge;
                setKeyboardState({ visible: true, height });
            },
        );
        const keyboardDidHide = Keyboard.addListener(
            Platform.OS === 'ios' ? 'keyboardWillHide' : 'keyboardDidHide',
            () => setKeyboardState({ visible: false, height: MIN_HEIGT_SIZE })
        );
        return () => {
            keyboardDidShow.remove();
            keyboardDidHide.remove();
            inputFocus.remove();
        };
    }, []);

    return {
        visible: keyboardState.visible,
        height: keyboardState.height,
    };
}

const emitFocus = () => KeyboardEmitter.emit('inputFocus', {});

const onlyAndroid = (func: (...args: any[]) => void) => (...args: any[]) => Platform.OS === 'android' && func(...args);

export { isNumber, onChange, useKeybaord, KeyboardEmitter, emitFocus, onlyAndroid };
