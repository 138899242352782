import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import React from 'react';
import { StyleSheet, Text } from 'react-native';
import { COLORS } from '../../constants/colors';
import { textStyles } from '../../constants/textStyles';

const LiveStatusBadge = ({ isEnabled }: { isEnabled: boolean }) => {
    const backgroundColor = isEnabled ? COLORS.statusLiveBg : COLORS.statusOfflineBg;
    const color = isEnabled ? COLORS.statusLiveText : COLORS.statusOfflineText;
    const badgeColor = [{ backgroundColor, color }];

    return (
        <Text style={[styles.badge, badgeColor]}>{isEnabled ? 'LIVE' : 'OFFLINE'}</Text>
    );
};

export { LiveStatusBadge };

const styles = StyleSheet.create({
    badge: mbTextStyles([
        textStyles.smallText, {
            paddingHorizontal: 14,
            paddingVertical: 3,
            borderRadius: 26,
            fontWeight: '600',
        },
    ]),
});
