import React, { useCallback, useMemo } from 'react';
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native';
import { GEOGRAPHY_LOCATION, GEOGRAPHY_MAPS, geographyLocationReadable, geographyMapReadable } from '../../../../Game/geographyControls/geographyConstants';
import { GeographyView } from '../../../../../helperComponents/GeographyView/GeographyView';
import { COLORS } from '../../../../../../constants/colors';
import { MB_DropDownPicker } from '@mightybyte/rnw.components.dropdownpicker';
import { mbApplyTransparency } from '@mightybyte/rnw.utils.style-utils';

type GeographyAnswerProps = {
    map?: GEOGRAPHY_MAPS,
    onMapChange?: (map?: GEOGRAPHY_MAPS) => void,
    location?: GEOGRAPHY_LOCATION,
    onLocationChange?: (location?: GEOGRAPHY_LOCATION) => void
    mapStyle?: StyleProp<ViewStyle>,
}

const GeographyAnswer = ({ map, onMapChange, location, onLocationChange, mapStyle }: GeographyAnswerProps) => {

    const onValueChange = useCallback((value: GEOGRAPHY_MAPS | null) => {
        onMapChange?.(value ?? undefined);
        onLocationChange?.(undefined);
    }, [onLocationChange, onMapChange]);

    const items = useMemo(() => Object.values(GEOGRAPHY_MAPS).map(thisMap => ({ label: geographyMapReadable[thisMap], value: thisMap })), []);

    return (
        <View>
            <View style={styles.topCongtainer}>
                <MB_DropDownPicker
                    key={map}
                    title="Select Geography Map"
                    placeholder="Select Geography Map"
                    items={items}
                    multiple={false}
                    onValueChange={onValueChange}
                    style={styles.dropDown}
                    value={map}
                />
                {location &&
                    <View style={styles.selectedArea}>
                        <Text style={styles.selectedAreaTitle}>Selected Location</Text>
                        <View style={styles.selectedAreaValueContainer}>
                            <Text style={styles.selectedAreaValue}>{geographyLocationReadable[location]}</Text>
                        </View>
                    </View>
                }
            </View>
            {map &&
                <GeographyView
                    style={[styles.map, mapStyle]}
                    map={map}
                    location={location}
                    onLocationChange={onLocationChange}
                />
            }
        </View>
    );
};

export { GeographyAnswer };

const styles = StyleSheet.create({
    topCongtainer: {
        marginTop: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        zIndex: 1,
    },
    dropDown: {
        width: 300,
        backgroundColor: COLORS.backgroundPurple,
    },
    selectedArea: {
        width: 300,
    },
    selectedAreaTitle: {
        fontSize: 14,
        fontWeight: '500',
        lineHeight: 28,
        color: '#FFFFFF',
        textAlign: 'center',
        alignSelf: 'flex-start',
    },
    selectedAreaValueContainer: {
        minHeight: 50,
        borderRadius: 6,
        borderColor: mbApplyTransparency('#BAC0CA', 0.4),
        justifyContent: 'center',
        borderWidth: 1,
        backgroundColor: COLORS.backgroundPurple,
        paddingHorizontal: 10,
    },
    selectedAreaValue: {
        fontSize: 16,
        fontWeight: '500',
        lineHeight: 28,
        color: '#FFFFFF',
    },
    map: {
        marginTop: 20,
        height: 450,
        borderWidth: 1.5,
        borderColor: COLORS.white,
    },
});
