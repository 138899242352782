import { isMobile } from '@mightybyte/rnw.utils.device-info';
import { GEOGRAPHY_LOCATION } from '../../screens/Game/geographyControls/geographyConstants';

enum GEOGRAPHY_PAYLOAD_TYPE {
    click = 'click',
    ready = 'ready'
}

const validateArea = function (area: string): GEOGRAPHY_LOCATION {
    if (!Object.keys(GEOGRAPHY_LOCATION).includes(area)) {
        throw new Error(`location ${area} not found.`);
    }
    return area as GEOGRAPHY_LOCATION;
};

const onMessage = function(event: any, callbacks: { onLocationChange?: (location?: GEOGRAPHY_LOCATION) => void, onGetReady?: () => void }) {
    const stringfiedData = isMobile ? event.nativeEvent.data : event.data;
    if (typeof stringfiedData === 'string') {
        const data = JSON.parse(stringfiedData);
        if (data.name === 'SVGCOMM') {
            if (data.type === GEOGRAPHY_PAYLOAD_TYPE.ready) {
                callbacks.onGetReady?.();
            } else if (data.type === GEOGRAPHY_PAYLOAD_TYPE.click) {
                const location = validateArea(data.area);
                callbacks.onLocationChange?.(location);
            }
        }
    }
};

const geographyUtils = {
    onMessage,
};

export { geographyUtils };
