import React, { useCallback, useRef } from 'react';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import { FlatList, ListRenderItemInfo, StyleSheet, Text, TouchableOpacity, View, ViewStyle, useWindowDimensions } from 'react-native';
import { mbPlatformStyle, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { ExtraNavigatorScreenProps } from '../../../typesAndInterfaces/componentProps';
import { MobileHeaderProps } from '../../helperComponents/Header/MobileHeader';
import { useGetCategory } from '../../../hooks/adminHooks';
import { PoolVideo } from '../../../typesAndInterfaces/typesAndInterfaces';
import { MB_ThumbnailVideoViewerModal, MB_ThumbnailVideoViewerModalRef } from '@mightybyte/rnw.components.video-player';
import { COLORS } from '../../../constants/colors';
import { textStyles } from '../../../constants/textStyles';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';

const ExtrasVideos = ({ navigation, route }: ExtraNavigatorScreenProps<'ExtrasVideos'>) => {
    const { title, categoryId } = route.params;
    const mobileHeaderOptions = useRef<MobileHeaderProps>({ showHeader: true, showBackArrow: true, pageTitle: title });
    const { width: windowWidth } = useWindowDimensions();

    const width = Math.max(windowWidth, 1000);

    const { data: category } = useGetCategory({ categoryId });
    const keyExtractor = React.useCallback((item: PoolVideo | string, index: number) => typeof item === 'string' ? `${item}-${index}` : `${item._id}-${index}`, []);

    const renderItem = useCallback(({ item }: ListRenderItemInfo<PoolVideo | string>) => {
        if (typeof item === 'string') {
            return null;
        }
        const videoRef = React.createRef<MB_ThumbnailVideoViewerModalRef>();
        return (
            <TouchableOpacity
                style={styles.videoContainer}
                onPress={() => videoRef.current?.setModalVisibility(true)}
            >
                <View pointerEvents="none" style={styles.videoPlayer}>
                    <MB_ThumbnailVideoViewerModal
                        video={item}
                        thumbnailContainerStyle={styles.videoPlayer}
                        isFocused={true}
                        MB_ThumbnailVideoViewerModalRef={videoRef}
                        hidePlayIcon
                    />
                </View>
                <Text style={styles.videoTitle}>{item.name}</Text>
            </TouchableOpacity>
        );
    }, []);

    const cardWidth = 153 + 30;
    const numberOfColumns = Math.max(Math.floor((width - 300) / cardWidth), 1);

    return (
        <ComponentWrapper
            containerStyle={styles.container}
            innerContainerStyle={styles.innerContainer}
            mobileHeaderOptions={mobileHeaderOptions.current}
            wrapInScrollView={false}
            mountPremiumBanner
            onBackPress={() => navigation.navigate('ExtrasHome')}
        >
            <FlatList
                keyExtractor={keyExtractor}
                ListHeaderComponent={
                    <Text style={styles.pageDescText}>Choose a video and embark on your learning adventure!</Text>
                }
                data={category?.extras ?? []}
                renderItem={renderItem}
                contentContainerStyle={styles.contentContainer}
                numColumns={!isMobileApp ? numberOfColumns : 2}
                key={!isMobileApp ? numberOfColumns : undefined}
                style={[styles.videosView, !isMobileApp && {
                    // width: numberOfColumns * cardWidth,
                    paddingHorizontal: ((width - 54 * 2) - (numberOfColumns * cardWidth)) / 2,
                }]}
                showsHorizontalScrollIndicator={false}
                showsVerticalScrollIndicator={false}
            />
        </ComponentWrapper>
    );
};

export { ExtrasVideos };

const styles = StyleSheet.create({
    container: {
        ...mbPlatformStyle({
            web: {
                minWidth: 1000,
                flex: 1,
            },
        }),
    },
    innerContainer: {
        ...mbPlatformStyle({
            web: {
                flex: 1,
                maxWidth: 'auto',
                overflowY: 'scroll',
            } as ViewStyle,
        }),
    },
    pageDescText: mbTextStyles([
        textStyles.smallText, {
            marginTop: 12,
            marginBottom: 30,
        },
    ]),
    videoContainer: {
        width: 144,
        height: 170,
        borderRadius: 8,
        backgroundColor: COLORS.backgroundPurple,
        paddingBottom: 10,
        // paddingHorizontal: 15,
        marginVertical: 15,
        marginHorizontal: 15,
        ...mbPlatformStyle({
            mobile: {
            },
            web: {
                width: 153,
                height: 209,
            },
        }),
    },
    videoPlayer: {
        flex: 1,
        backgroundColor: 'transparent',
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
    },
    videoTitle: mbTextStyles([
        textStyles.smallText, {
            marginTop: 12,
        },
    ]),
    contentContainer: {
        paddingBottom: 100,
        ...mbPlatformStyle({
            web: {
                alignItems: 'stretch',
            },
            mobile: {
                alignItems: 'center',
            },
        }),
    },
    videosView: {
        ...mbPlatformStyle({
            web: {
                alignSelf: 'center',
            },
            mobile: {
                flex: 1,
            },
        }),
    },
});
