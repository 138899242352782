import React, { useEffect, useRef, useState } from 'react';
import { MB_Modal } from '@mightybyte/rnw.components.modal';
import { MB_ModalProgressCircleBody } from '@mightybyte/rnw.components.modal-progress-circle';
import { StyleSheet, Text, View } from 'react-native';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../../constants/textStyles';
import { COLORS } from '../../../../constants/colors';
import LinearGradient from 'react-native-linear-gradient';


const ReadyModal = ({ isVisible, onDismiss, onModalHide, initalSeconds = 3 }: { isVisible: boolean, onDismiss: () => void, onModalHide?: () => void, initalSeconds?: number }) => {
  const [progress, setProgress] = useState(100);
  const [currentSeconds, setCurrentSeconds] = useState(initalSeconds);

  useEffect(() => {
    const decSeconds = (seconds: number) => {
      if (seconds >= 0) {
        const newProgress = seconds * 100 / initalSeconds;
        setProgress(newProgress);
        setCurrentSeconds(seconds);
        setTimeout(() => decSeconds(seconds - 1), 1000);
      } else {
        onDismiss();
      }
    };
    if (isVisible) {
      decSeconds(initalSeconds);
    }
  }, [initalSeconds, onDismiss, isVisible]);

  const circularProgressProps = useRef({
    size: 120,
    backgroundWidth: 0,
    backgroundColor: 'transparent',
    width: 4,
    tintColor: COLORS.white,
    rotation: 0,
    prefill: 100,
  });

  const progressToText = () => {
    return currentSeconds.toString();
  };

  return (
    <MB_Modal
      isVisible={isVisible}
      onDismiss={onDismiss}
      hideCloseButton
      childrenWrapperStyle={{ backgroundColor: 'transparent', paddingTop: 0, paddingBottom: 0 }}
      animationIn="fadeIn"
      animationOut="fadeOut"
      onModalHide={onModalHide}
    >
      <Text style={styles.title}>Game will begin in</Text>
      <LinearGradient
        style={styles.linearGradient}
        colors={['#a430ff40', '#01a2a240']}
        useAngle
        angle={180}
      >
        <View style={styles.circle} />
        <MB_ModalProgressCircleBody
          progress={progress}
          circularProgressProps={circularProgressProps.current}
          children={() => <View />}
        />
        <View style={styles.textContainer}>
          <Text style={styles.progressText}>{progressToText()}</Text>
          <Text style={styles.secondsText}>SECONDS</Text>
        </View>
      </LinearGradient>
    </MB_Modal>
  );
};

export { ReadyModal };

const styles = StyleSheet.create({
  title: mbTextStyles([
    textStyles.smallerText,
    { fontWeight: '500', color: COLORS.white },
  ]),
  linearGradient: {
    borderRadius: 360,
    marginTop: 22,
  },
  circle: {
    borderWidth: 1,
    borderColor: COLORS.white,
    borderRadius: 360,
    width: 116,
    height: 116,
    position: 'absolute',
    zIndex: -1,
    top: 2,
    left: 2,
  },
  textContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  progressText: mbTextStyles([
    textStyles.largerText,
    { fontSize: 31, fontWeight: '300', color: COLORS.white },
  ]),
  secondsText: mbTextStyles([
    textStyles.tinyText,
    { fontWeight: '300', color: COLORS.white },
  ]),
});
