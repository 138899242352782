import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { default as React } from 'react';
import { GAME_TYPE } from '../typesAndInterfaces/typesAndInterfaces';
import SelectGame from '../components/screens/SelectGame/SelectGame';
import { SelectCategory } from '../components/screens/SelectCategory/SelectCategory';

export type GameNavigatorParamList = {
  SelectGame: undefined,
  SelectCategory: { gameType: GAME_TYPE }
};

const Stack = createNativeStackNavigator<GameNavigatorParamList>();

const GameNavigator = () => {

  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        gestureEnabled: false,
      }}
    >
      <Stack.Screen name="SelectGame" component={SelectGame} />
      <Stack.Screen name="SelectCategory" component={SelectCategory} />
    </Stack.Navigator>
  );
};

export { GameNavigator };
