import { MB_Button } from '@mightybyte/rnw.components.button';
import { MB_TextInput } from '@mightybyte/rnw.components.text-input';
import { mbShowToast } from '@mightybyte/rnw.components.toast';
import { mbPlatformStyle, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { isMobile, isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { COLORS } from '../../../constants/colors';
import { textStyles } from '../../../constants/textStyles';
import { useGetCurrentUserData, useUpdateUserName } from '../../../hooks/userHooks';
import { MobileHeaderProps } from '../../helperComponents/Header/MobileHeader';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import { MB_Modal } from '@mightybyte/rnw.components.modal';
import { ChangePasswordPopUp } from '../../helperComponents/ChangePasswordPopUp';
import { MB_utilHooks } from '@mightybyte/rnw.utils.util-hooks';


const MyAccountPersonalInformation = () => {
    const updateCurrentUserName = useUpdateUserName();
    const { data: currentUserData } = useGetCurrentUserData();
    const [firstName, setFirstName] = useState(currentUserData?.firstName ?? '');
    const [lastName, setLastName] = useState(currentUserData?.lastName ?? '');
    const [isChangePasswordVisible, showChangePassword, hideChangePassword] = MB_utilHooks.useBool(false);

    const mobileHeaderOptions = useRef<MobileHeaderProps>({ showHeader: true, showBackArrow: true, pageTitle: 'Edit Profile' });

    const isSubmitChangesDisabled = !firstName || !lastName || (firstName === currentUserData?.firstName && lastName === currentUserData?.lastName) || updateCurrentUserName.isLoading ? true : false;

    const onUpdatePressed = useCallback(() => {
        updateCurrentUserName.mutate({ firstName, lastName }, {
            onSuccess: () => {
                mbShowToast({
                    type: 'success',
                    position: 'top',
                    text1: 'Updated!',
                    text2: 'Profile updated successfully',
                });
            },
            onError: () => {
                mbShowToast({
                    type: 'error',
                    position: 'top',
                    text2: 'Something went wrong. Please try again.',
                });
            },
        });
    }, [firstName, lastName, updateCurrentUserName]);

    const content = useMemo(() => {
        return (
            <>
                {!isMobileApp &&
                    <Text style={styles.title}>Edit Profile</Text>
                }
                <View style={styles.fullName}>
                    <MB_TextInput
                        title={'First Name'}
                        value={firstName}
                        style={[styles.textInputStyle, !isMobileApp && { marginRight: 10 }]}
                        textInputStyle={styles.textStyle}
                        titleStyle={styles.defaultTitleStyle}
                        autoCorrect={false}
                        onChangeText={(newText: string) => setFirstName(newText)}
                        maxLength={16}
                    />
                    <MB_TextInput
                        title={'Last Name'}
                        value={lastName}
                        style={styles.textInputStyle}
                        textInputStyle={styles.textStyle}
                        titleStyle={styles.defaultTitleStyle}
                        autoCorrect={false}
                        onChangeText={(newText: string) => setLastName(newText)}
                        maxLength={16}
                    />
                </View>
                {!isMobileApp &&
                    <View>
                        <Text style={styles.emailName}>Email address</Text>
                        <Text style={styles.email}>{currentUserData?.email}</Text>
                        <MB_Button
                            title="Change password"
                            style={styles.changePasswordButton}
                            onPress={showChangePassword}
                        />
                    </View>
                }
                <MB_Button
                    title={'Save'}
                    onPress={onUpdatePressed}
                    disabled={isSubmitChangesDisabled}
                    loading={updateCurrentUserName.isLoading}
                    style={styles.saveButton}
                />
            </>
        );
    }, [currentUserData?.email, firstName, isSubmitChangesDisabled, lastName, onUpdatePressed, showChangePassword, updateCurrentUserName.isLoading]);

    if (isMobileApp) {
        return (
            <ComponentWrapper
                wrapInScrollView={false}
                mobileHeaderOptions={mobileHeaderOptions.current}
                innerContainerStyle={styles.container}
            >
                {content}
            </ComponentWrapper>
        );
    }

    return (
        <View>
            <MB_Modal
                title="Change Password"
                childrenWrapperStyle={styles.modal}
                isVisible={isChangePasswordVisible}
                onDismiss={hideChangePassword}
            >
                <ChangePasswordPopUp
                    requireOldPassword={true}
                    style={styles.modalContainer}
                    submitButton={{ style: styles.submitButton, title: 'Change Password' }}
                />
            </MB_Modal>
            {content}
        </View>
    );
};

export { MyAccountPersonalInformation };

const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginTop: isMobile ? 32 : 114,
        minWidth: isMobile ? '100%' : 412,
        width: '100%',
        justifyContent: 'flex-start',
    },
    defaultTitleStyle: mbTextStyles([
        textStyles.smallText, {
            textAlign: 'left',
            color: COLORS.textLightPurple,
            paddingBottom: 4,
            paddingTop: 22,
        },
    ]),
    title: mbTextStyles([textStyles.largeText, {
        textAlign: 'left',
        marginBottom: 31,
    }]),
    textInputStyle: {
        borderRadius: 10,
        backgroundColor: COLORS.backgroundPurple,
        borderWidth: 0,
        ...mbPlatformStyle({
            web: {
                width: 183,
            },
            mobile: {
                width: '100%',
            },
        }),
    },
    textStyle: mbTextStyles([
        textStyles.smallText, {
            textAlign: 'left',
            paddingStart: 8,
        },
    ]),
    fullName: {
        ...mbPlatformStyle({
            web: {
                flexDirection: 'row',
            },
        }),
    },
    buttonsContainer: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    changePassword: {
        borderColor: COLORS.buttonPurple,
        borderWidth: 1.5,
        borderRadius: 10,
        width: 386,
        height: 62,
        marginTop: 42,
        backgroundColor: 'transparent',
        ...mbPlatformStyle({
            mobile: {
                width: '100%',
                height: 44,
            },
        }),
    },
    emailName: mbTextStyles([textStyles.smallText, {
        marginTop: 32,
        textAlign: 'left',
    }]),
    email: mbTextStyles([textStyles.smallText, {
        fontSize: 13,
        textAlign: 'left',
        color: '#C2C2C2',
        marginTop: 8,
    }]),
    changePasswordButton: {
        backgroundColor: 'transparent',
        borderWidth: 1,
        borderColor: COLORS.white,
        width: 221,
        marginTop: 32,
        marginBottom: 38,
    },
    saveButton: {
        marginBottom: 48,
        width: 386,
        height: 62,
        backgroundColor: COLORS.buttonPurple,
        borderRadius: 10,
        marginTop: 'auto',
        ...mbPlatformStyle({
            mobile: {
                width: '100%',
                height: 44,
            },
        }),
    },
    premiumBannerContainer: {
        width: '100%',
        backgroundColor: 'red',
        borderRadius: 18,
        marginBottom: 20,
        paddingVertical: 8,
        paddingHorizontal: 21,
    },
    premiumBannerTopText: {
        ...mbTextStyles({
            ...textStyles.largeText,
            textAlign: 'left',
        }),
    },
    premiumBannerBottomText: {
        ...mbTextStyles({
            ...textStyles.normalText,
            textAlign: 'left',
        }),
    },
    submitButton: {
        width: 320,
        height: 54,
        marginTop: 43,
    },
    modal: {
        overflow: 'visible',
        backgroundColor: '#321B50',
    },
    modalContainer: {
        alignItems: 'flex-start',
        marginTop: 22,
        minWidth: isMobile ? '100%' : 412,
        ...mbPlatformStyle({
            mobile: {
                alignItems: undefined,
                justifyContent: undefined,
                width: '100%',
            },
        }),
        ...mbPlatformStyle({
            web: {
                alignItems: 'center',
            },
        }),
    },
});
