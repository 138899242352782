import { RouteProp, useRoute } from '@react-navigation/core';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';
import { ConstructQuestion } from '../../../components/screens/Admin/Categories/Question/ConstructQuestion';
import { ConstructCategory } from '../../../components/screens/Admin/Categories/ConstructCategory';
import { Categories } from '../../../components/screens/Admin/Categories/Categories';
import { GAME_TYPE } from '../../../typesAndInterfaces/typesAndInterfaces';
import { AdminNavigatorParamList } from '../AdminNavigator';
import { Reorder } from '../../../components/screens/Admin/Categories/Reorder';


export type AdminCategoriesParamList = {
    Categories: { gameType: GAME_TYPE, page?: number },
    Reorder: { gameType: GAME_TYPE },
    ConstructCategory: { categoryId: string | undefined, gameType: GAME_TYPE },
    ConstructQuestion: { categoryId: string, gameType: GAME_TYPE, questionId?: string | undefined, isCopyingQuestion?: boolean }
};

const Stack = createNativeStackNavigator<AdminCategoriesParamList>();

const GamesCategoriesNavigatorStack = () => {

    const { params } = useRoute<RouteProp<AdminNavigatorParamList, 'IntroToASL' | 'PriceMatch' | 'BodyParts' | 'Geography' | 'Extras'>>();

    return (
        <Stack.Navigator
            screenOptions={{
                headerShown: false,
                gestureEnabled: false,
            }}
        >
            <Stack.Screen name="Categories" component={Categories} initialParams={{ gameType: params.gameType }} />
            <Stack.Screen name="Reorder" component={Reorder} initialParams={{ gameType: params.gameType }} />
            <Stack.Screen name="ConstructCategory" component={ConstructCategory} />
            <Stack.Screen name="ConstructQuestion" component={ConstructQuestion} />
        </Stack.Navigator>
    );
};

export { GamesCategoriesNavigatorStack };
