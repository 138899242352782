import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const FolderIcon = ({ size = 102, color = '#412366' }: { size?: number, color?: string }) => {
  return (
    <Svg
      width={size ?? 120}
      height={size ? (size * (120 / 102)) : 120}
      viewBox="0 0 120 102"
      fill="none"
    >
      <Path
        d="M104.843 101.848H14.978A14.8 14.8 0 010 87.29V14.56A14.798 14.798 0 0114.977 0h27.56a5.991 5.991 0 014.612 2.217l15.757 12.341h41.937A14.8 14.8 0 01120 29.117l-.18 58.173a14.794 14.794 0 01-14.977 14.558z"
        fill={color}
      />
    </Svg>
  );
};

export { FolderIcon };
