import { MB_Button } from '@mightybyte/rnw.components.button';
import { MB_ModalMobileBottom } from '@mightybyte/rnw.components.modal';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useState } from 'react';
import { StyleSheet, Text } from 'react-native';
import { COLORS } from '../../constants/colors';
import { STRING_CONSTANTS } from '../../constants/constants';
import { textStyles } from '../../constants/textStyles';
import { UniversalScreenNavigationProp } from '../../typesAndInterfaces/componentProps';
import { SocialLoginButtons } from '../helperComponents/SocialLoginButtons';

const MobileLandingPagePopUp = ({ isVisible, onHide, screenToNavigateTo }: { isVisible: boolean, onHide: () => void, screenToNavigateTo: 'Login' | 'SignUp' }) => {
    const navigation = useNavigation<UniversalScreenNavigationProp>();
    const [errorMessage, setErrorMessage] = useState('');

    const socialLoginCallback = useCallback((isSuccess: boolean) => {
        if (isSuccess) {
            onHide();
        } else {
            setErrorMessage(STRING_CONSTANTS.FAILED_TO_LOG_IN);
        }
    }, [onHide]);

    return (
        <MB_ModalMobileBottom
            isVisible={isVisible}
            onDismiss={onHide}
            childrenWrapperStyle={styles.container}>
            <Text style={styles.logInWithText}> Log in with </Text>
            <SocialLoginButtons
                containerStyle={styles.socialButtonContainer}
                buttonStyle={styles.socialButtons}
                callback={socialLoginCallback}
            />
            <MB_Button
                style={styles.continueWithEmailButton}
                title={'Continue with email'}
                onPress={() => {
                    onHide();
                    navigation.navigate(screenToNavigateTo);
                }}
            />
            <>
                {errorMessage !== '' &&
                    <Text style={textStyles.popUpErrorMessageText}>{errorMessage}</Text>
                }
            </>
        </MB_ModalMobileBottom >
    );
};

const styles = StyleSheet.create({
    container: {
        backgroundColor: COLORS.backgroundDarkPurple,
        alignItems: 'stretch',
    },
    logInWithText: mbTextStyles([
        textStyles.largeText, {
            fontWeight: 'bold',
            marginBottom: 24,
        },
    ]),
    text: {
        color: 'white',
        textAlign: 'center',
    },
    continueWithEmailButton: {
        backgroundColor: 'transparent',
    },
    socialButtons: {
        backgroundColor: COLORS.backgroundPurple,
        borderWidth: 0,
    },
    socialButtonContainer: {
        marginBottom: 16,
    },
});

export { MobileLandingPagePopUp };

