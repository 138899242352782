import React from 'react';
import { Image, Linking, StyleSheet, Text, View } from 'react-native';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import {
    DrawerContentComponentProps,
    DrawerContentScrollView,
    DrawerItemList,
} from '@react-navigation/drawer';
import { COLORS } from '../../constants/colors';
import { textStyles } from '../../constants/textStyles';
import { useGetServerVersion } from '../../hooks/serverUtilHooks';
import { getVersionNumber } from '../../utils/getVersionNumber/getVersionNumber';
import { imageUtils } from '../../utils/imageUtils';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { utils } from '../../utils/utils';
import { useSignedInContext } from '../../context/SignedInContext';
import { envs } from '../../../env';

const versions = getVersionNumber();

function AdminNavigatorDrawer(props: DrawerContentComponentProps) {
    const { data: serverVersion } = useGetServerVersion();
    const { isSignedIn, signOut, currentUserData } = useSignedInContext();

    return (
        <DrawerContentScrollView {...props} style={styles.drawerContainerStyle} contentContainerStyle={styles.drawerContentContainer}>
            <Image source={imageUtils.images.aslFlurryLogo} style={styles.logoImage} resizeMode="contain" />
            <View style={styles.headerSeparator} />
            <DrawerItemList {...props} />

            <View style={{ marginTop: 'auto', alignItems: 'flex-start', paddingBottom: 16 }}>
                <MB_Button
                    title="View Analytics"
                    style={styles.viewAnalyticsButton}
                    onPress={() => Linking.openURL('https://console.firebase.google.com/u/1/project/aslflurry/analytics/app/android:com.aslflurry.app/overview/~2F%3Ft%3D1688054001965&fpn%3D931253094407&swu%3D1&sgu%3D1&cs%3Dapp.m.dashboard.overview&g%3D1')}
                />

                <Text style={textStyles.tinyText}>JS Version: {versions.jsVersion}</Text>
                {isMobileApp &&
                    <Text style={textStyles.tinyText}>App Version: {`${versions.appVersion}(${versions.buildVersion})`}</Text>
                }
                <Text style={textStyles.tinyText}>Flavor: {envs.FLAVOR}</Text>
                <Text style={textStyles.tinyText}>Mode: {process.env.NODE_ENV}, isDev: {__DEV__?.toString()}</Text>
                <Text style={textStyles.tinyText}>Server Version: {serverVersion?.version}</Text>
            </View>

            <View style={styles.userInfo}>
                {isSignedIn &&
                    <View>
                        <Text style={styles.name}>{currentUserData?.firstName} {currentUserData?.lastName}</Text>
                        <Text style={styles.email}>{currentUserData?.email}</Text>
                        <MB_Button
                            title="Log out"
                            style={styles.logout}
                            textStyle={styles.logoutText}
                            onPress={() => utils.showLogOutPopUp(signOut)}
                        />
                    </View>
                }
            </View>
        </DrawerContentScrollView>
    );
}

export { AdminNavigatorDrawer };

const styles = StyleSheet.create({
    drawerContainerStyle: {
        backgroundColor: COLORS.backgroundPurple,
        paddingTop: 6,
        paddingHorizontal: 20,
    },
    drawerContentContainer: {
        flex: 1,
    },
    headerSeparator: {
        borderRadius: 2,
        borderWidth: 2,
        borderColor: COLORS.textLightPurple,
        marginTop: 16,
        marginBottom: 24,
    },
    logoImage: {
        width: 181,
        height: 124,
        alignSelf: 'center',
    },
    userInfo: {
        borderTopWidth: 2,
        borderTopColor: COLORS.textLightPurple,
        paddingBottom: 40,
        paddingTop: 32,
        alignItems: 'center',
        justifyContent: 'center',
    },
    name: StyleSheet.flatten([textStyles.smallText, {
        textAlign: 'left',
    }]),
    email: StyleSheet.flatten([textStyles.smallText, {
        color: COLORS.textLightPurple,
        textAlign: 'left',
    }]),
    logout: {
        backgroundColor: COLORS.buttonPurple,
        height: 56,
        marginTop: 32,
    },
    logoutText: StyleSheet.flatten([textStyles.normalText, {
        fontSize: 18,
    }]),
    viewAnalyticsButton: {
        backgroundColor: 'transparent',
        borderColor: COLORS.white,
        borderWidth: 2,
        borderRadius: 10,
        height: 56,
        alignSelf: 'stretch',
        marginBottom: 22,
    },
});
