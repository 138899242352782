import React, { useCallback, useRef, useState } from 'react';
import { Animated, FlatList, Keyboard, ListRenderItemInfo, StyleProp, StyleSheet, Text, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import { MB_ModalDropdown } from '@mightybyte/rnw.components.dropdown';
import { mbApplyTransparency, mbShadow, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../constants/textStyles';
import Feather from 'react-native-vector-icons/Feather';
import { useKeybaord } from '../screens/ShoppingGame/utils';

const ModalInfoState = {
    isVisible: false,
    callerX: 0,
    callerY: 0,
    callerWidth: 0,
    callerHeight: 0,
};

type DropdownPickerProps<T extends string> = {
    title?: string,
    titleStyle?: StyleProp<ViewStyle>,
    placeholder?: string,
    items: T[]
    value?: T,
    dropdownTextStyle?: StyleProp<TextStyle>
    onSelectItem?: (item: T) => void,
    style?: StyleProp<ViewStyle>,
    showRequiredAsteriks?: boolean,
    isError?: boolean,
    errorMessage?: string,
}

const capitalize = (s: string) => s[0].toUpperCase() + s.slice(1);

const DropdownPicker = <T extends string>({
    placeholder = 'option',
    items,
    value,
    dropdownTextStyle,
    onSelectItem,
    style,
}: DropdownPickerProps<T>) => {

    const chevronAnimation = useRef(new Animated.Value(0)).current;
    const btnRef = useRef<TouchableOpacity>(null);

    const [modalInfo, setModalInfo] = useState(ModalInfoState);
    const { visible: isKeyboardVisible } = useKeybaord();

    const hideModal = useCallback(() => {
        setModalInfo(prev => ({ ...prev, isVisible: false }));
        Animated.timing(chevronAnimation, {
            toValue: 0,
            duration: 200,
            useNativeDriver: false,
        }).start();
    }, [chevronAnimation]);

    const showModal = useCallback(() => {
        btnRef.current?.measure((_, __, width, height, pageX, pageY) => {
            setModalInfo({
                isVisible: true,
                callerWidth: width,
                callerHeight: height,
                callerY: pageY,
                callerX: pageX,
            });
        });
        Animated.timing(chevronAnimation, {
            toValue: 1,
            duration: 200,
            useNativeDriver: false,
        }).start();
    }, [chevronAnimation]);

    const onShowModal = useCallback(() => {
        if (isKeyboardVisible) {
            Keyboard.dismiss();
            setTimeout(() => showModal(), 500);
        } else {
            showModal();
        }
    }, [isKeyboardVisible, showModal]);

    const rotation = chevronAnimation.interpolate({ inputRange: [0, 1], outputRange: ['0deg', '180deg'] });

    const onItemPress = useCallback((item: T) => {
        onSelectItem?.(item);
        hideModal();
    }, [hideModal, onSelectItem]);

    const renderItem = useCallback(({ item }: ListRenderItemInfo<T>) => (
        <TouchableOpacity onPress={() => onItemPress(item)}>
            <Text style={styles.itemText}>{capitalize(item)}</Text>
        </TouchableOpacity>
    ), [onItemPress]);

    const keyExtractor = useCallback((item: string, index: number) => `${item}-${index}`, []);

    const itemSeparator = useCallback(() => <View style={styles.separator} />, []);

    return (
        <React.Fragment>
            <TouchableOpacity
                ref={btnRef}
                onPress={onShowModal}
                activeOpacity={1}
                style={[styles.container, style]}
            >
                <Text numberOfLines={1} style={[styles.dropdownTextStyle, dropdownTextStyle]}>
                    {capitalize(value ?? placeholder)}
                </Text>
                <Animated.View pointerEvents="none" style={[styles.chevron, { transform: [{ rotate: rotation }] }]} >
                    <Feather
                        name="chevron-up"
                        color="#E9CEFF"
                        size={24}
                    />
                </Animated.View>
            </TouchableOpacity>
            <MB_ModalDropdown
                visible={modalInfo.isVisible}
                callerX={modalInfo.callerX}
                callerY={modalInfo.callerY}
                callerHeight={modalInfo.callerHeight}
                callerWidth={modalInfo.callerWidth}
                onClose={hideModal}
            >
                <View style={[styles.modal, { width: modalInfo.callerWidth }]}>
                    <FlatList
                        data={items}
                        renderItem={renderItem}
                        keyExtractor={keyExtractor}
                        ItemSeparatorComponent={itemSeparator}
                    />
                </View>
            </MB_ModalDropdown>
        </React.Fragment>
    );
};

export default DropdownPicker;

const styles = StyleSheet.create({
    container: {
        borderWidth: 1,
        borderColor: '#00000000',
        height: 50,
        borderRadius: 8,
        justifyContent: 'space-between',
        backgroundColor: '#412366',
        flexDirection: 'row',
        alignItems: 'center',
    },
    dropdownTextStyle: mbTextStyles([textStyles.normalText, {
        textAlign: 'left',
        color: mbApplyTransparency('#FFFFFF', 0.45),
        fontWeight: '400',
    }]),
    chevron: {
    },
    modal: {
        height: 154,
        backgroundColor: '#FFFFFF',
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#D1D1D1',
    },
    itemText: mbTextStyles([textStyles.normalText, {
        color: '#2C2826',
        textAlign: 'center',
        marginVertical: 15,
    }]),
    separator: {
        backgroundColor: mbApplyTransparency('#D1D1D1', 0.50),
        height: 1,
        marginHorizontal: 10,
        ...mbShadow({
            offsetWidth: 0,
            offsetHeight: -1,
            color: '#FFFFFF',
            opacity: 0.5,
            radius: 10,
            elevation: 0,
        }),
    },
    inputTitleAsteriks: {
        color: '#6D3139',
    },
    error: {
        borderColor: mbApplyTransparency('#FF0000', 0.80),
    },
    errorMessageText: mbTextStyles([textStyles.smallText, {
        position: 'absolute',
        zIndex: 1,
        color: mbApplyTransparency('#FF0000', 0.80),
        textAlign: 'center',
        fontWeight: '400',
        fontSize: 11,
        left: 0,
        bottom: -19,
    }]),
});
