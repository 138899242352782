import { getScriptTag } from './map_controls';

type HtmlWrapperProps = {
    selectedColor?: string,
    backgroundColor?: string,
    activeAreas?: string[],
}

const getHtmlWrapper = (svg: string, { selectedColor = '#000000', backgroundColor = '#FFFFFF', activeAreas }: HtmlWrapperProps) => {
    return `<!DOCTYPE html>
<html lang="en">
    <head>
        <meta charset="UTF-8">
        <style>
            html, body { height: 100%; width: 100% }
            body { margin: 0px; padding: 0px; display: flex; align-items: center; background-color: ${backgroundColor} }
            svg * { -webkit-tap-highlight-color:rgba(0,0,0,0) }
            /* Turn off ugly click feedback square */
            path { -webkit-tap-highlight-color:rgba(0,0,0,0); }
            g:hover { cursor: pointer; }
            .selected { fill: ${selectedColor} !important; }
        </style>
    </head>
    <body>
        ${svg}
        ${getScriptTag({ activeAreas })}
    </body>
</html>`;
};

export { getHtmlWrapper };
