import { PacmanIndicator } from '@mightybyte/rnw.components.activity-indicators';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FlatList, ListRenderItemInfo, StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native';
import { textStyles } from '../../constants/textStyles';
import { useGetCategoriesForHomePage, useGetCurrentUserData } from '../../hooks/userHooks';
import { CategoryForUser, DIFFICULTY, GAME_TYPE } from '../../typesAndInterfaces/typesAndInterfaces';
import { useMBInfiniteFetch } from '@mightybyte/rnw.utils.util-hooks';
import { COLORS } from '../../constants/colors';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { useFocusEffect } from '@react-navigation/core';
import { useQueryClient } from '@tanstack/react-query';
import { USER_QUERY_KEYS } from '../../constants/constants';

const TOTAL_ITEMS_PER_PAGE = 4;

const CategoryLoadingIndicator = () => {
    return (
        <View style={styles.categoryLoadingIndicator}>
            <PacmanIndicator color={COLORS.white} />
            <Text style={[textStyles.smallText, { color: COLORS.white }]} >Loading...</Text>
        </View>
    );
};

interface CategoriesViewProps {
    emptyTitle?: string,
    emptyMessage?: string,
    gameType: GAME_TYPE,
    renderItem: (item: CategoryForUser | string, index: number, isLoading: boolean) => React.ReactElement | null,
    difficulty?: DIFFICULTY,
    prefillWhileLoading?: boolean,
    style?: StyleProp<ViewStyle>,
    contentContainer?: StyleProp<ViewStyle>,
    numColumns?: number
    HeaderComponent?: React.ComponentType<any> | React.ReactElement<unknown> | null | undefined
    /**
     * When using React Navigation Tabs
     * the screens are always mounted
     * which prevents React Query from triggering a refetch.
     * This option listens to React Navigation focus events and triggers the refetch manually.
     */
    refetchOnScreenFocus?: boolean,
}

const CategoriesView = ({ gameType, renderItem, difficulty, prefillWhileLoading = false, style, contentContainer, numColumns, HeaderComponent, emptyTitle, emptyMessage, refetchOnScreenFocus }: CategoriesViewProps) => {

    const flatListRef = useRef<FlatList>(null);
    const containerRef = useRef<any | null>(null);
    const queryClient = useQueryClient();

    // TODO: We set the cacheTime to 0 here to avoid flashing previous cards while switching between pages but this is not ideal.
    const getHomeCategoriesHook = useGetCategoriesForHomePage({ gameType, totalItemsPerPage: TOTAL_ITEMS_PER_PAGE, difficulty, queryOptions: { cacheTime: 0 } });
    const { data, loadMore, isLoading, isFetching } = useMBInfiniteFetch(getHomeCategoriesHook, { prefillWhileLoading });
    const { data: currentUserData } = useGetCurrentUserData();
    const [isFetchLoadingDisabled, setIsFetchLoadingDisabled] = useState(false);

    useFocusEffect(useCallback(() => {
        if (refetchOnScreenFocus) {
            setIsFetchLoadingDisabled(true);
            queryClient.invalidateQueries([USER_QUERY_KEYS.getCategoryForHomePage, gameType, difficulty]);
        }
    }, [difficulty, gameType, queryClient, refetchOnScreenFocus]));

    const RenderCategoryComponent = useCallback(({ item, index }: ListRenderItemInfo<CategoryForUser | string>) => {
        return renderItem(item, index, isLoading);
    }, [isLoading, renderItem]);

    const keyExtractor = React.useMemo(() => (item: CategoryForUser | string) => typeof item === 'string' ? item : item._id, []);

    const itemSeparator = useCallback(() => <View style={styles.separator} />, []);

    useEffect(() => {
        flatListRef.current?.scrollToOffset({ offset: 0, animated: true });
    }, [currentUserData?.difficulty, difficulty]);

    useEffect(() => {
        if (!isMobileApp) {
            const innerContainer = containerRef.current?.parentElement;
            const onScroll = () => {
                const maxScrollTop = innerContainer.scrollHeight - innerContainer.clientHeight;
                if (innerContainer.scrollTop > maxScrollTop - 40) {
                    loadMore();
                }
            };
            // in case data get loaded
            onScroll();
            innerContainer?.addEventListener('scroll', onScroll);
            return () => innerContainer?.parentElement.removeEventListener('scroll', onScroll);
        } else {
            setIsFetchLoadingDisabled(false);
        }
    }, [data, loadMore]);

    return (
        <View ref={containerRef} style={[style]}>
            <FlatList
                ListHeaderComponent={HeaderComponent}
                ListEmptyComponent={
                    data.length === 0 && !isFetching && !isLoading ?
                        <View style={styles.noGamesAvailableContainer}>
                            <Text style={styles.noGamesAvailableTitle}>{emptyTitle ?? 'No games available.'}</Text>
                            <Text style={styles.noGamesAvailableText}>{emptyMessage ?? 'Currently there are no games available under the selected level.'}</Text>
                        </View> : undefined
                }
                ref={flatListRef}
                keyExtractor={keyExtractor}
                numColumns={numColumns}
                data={data}
                renderItem={RenderCategoryComponent}
                contentContainerStyle={contentContainer}
                onEndReached={isMobileApp ? loadMore : undefined}
                onEndReachedThreshold={isMobileApp ? 0.3 : undefined}
                ListFooterComponent={(!isFetchLoadingDisabled && isFetching) && !isLoading ? CategoryLoadingIndicator : null}
                ItemSeparatorComponent={itemSeparator}
            />
        </View>
    );
};

export { CategoriesView };

const styles = StyleSheet.create({
    noGamesAvailableContainer: {
        width: '100%',
        flex: 1,
        alignItems: 'center', justifyContent: 'center',
    },
    noGamesAvailableTitle: mbTextStyles([
        textStyles.normalText, {

        },
    ]),
    noGamesAvailableText: mbTextStyles([
        textStyles.smallerText, {
            fontSize: 13,
            marginTop: 6,
            marginHorizontal: 48,
        },
    ]),
    categoryLoadingIndicator: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        minWidth: 100,
    },
    separator: {
        height: 10,
    },
});
