import { MB_Button } from '@mightybyte/rnw.components.button';
import { mbShowToast } from '@mightybyte/rnw.components.toast';
import { mbShadow, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { COLORS } from '../../../constants/colors';
import { textStyles } from '../../../constants/textStyles';
import { ANSWER_BUTTON_STATE, GAME_SUBTYPE, GAME_TYPE, Question } from '../../../typesAndInterfaces/typesAndInterfaces';
import { utils } from '../../../utils/utils';
import { MatchGameCards } from '../Game/MatchGameCards';
import isEqual from 'lodash/isEqual';
import { envs } from '../../../../env';
import MoneyInput from './MoneyInput';
import AmountInput from './AmountInput';
import DateInput from './DateInput';
import { setAdjustResize, setAdjustPan } from 'rn-android-keyboard-adjust';
import { useKeybaord } from './utils';

interface ShoppingGameControlsProps {
    question: Question,
    disableAnswers: boolean,
    onAnswerPressed: (isCorrect: boolean) => void,
    isPortrait: boolean,
}

const ShoppingGameControls = memo(({ question, disableAnswers, onAnswerPressed, isPortrait }: ShoppingGameControlsProps) => {

    if (question.gameType !== GAME_TYPE.MatchingItemsGame) {
        throw new Error('ShoppingGameControls only support MatchingItemsGame Question');
    }

    // Amount
    const [selectedAmount, setSelectedAmount] = useState('');

    // Money
    const [selectedDollarAmount, setSelectedDollarAmount] = useState('');
    const [selectedCentsAmount, setSelectedCentsAmount] = useState('');

    // Date
    const [selectedMonth, setSelectedMonth] = useState<number>();
    const [selectedDay, setSelectedDay] = useState<string>('');
    const [selectedYear, setSelectedYear] = useState<string>('');

    // Correct Answer
    const [isCardCorrect, setIsCardCorrect] = useState<boolean>(false);
    const [isWheelSelectionCorrect, setIsWheelSelectionCorrect] = useState<boolean>();


    const [selectedAnswerName, setSelectedAnswerName] = useState('');
    const [buttonStates, setButtonStates] = useState<{ answerName: string, state: ANSWER_BUTTON_STATE }[]>(question.answers.map(item => ({ answerName: item.name, state: ANSWER_BUTTON_STATE.unselected })));

    // Note: We have a hard to fix bug situation sometimes when we restart the game, old game is used for a split second.
    // This results in button states using the old card values for a moment and then cards cahnge.
    // This routine is run to set the button states according to new values.
    useEffect(() => {
        const buttonStateNames = buttonStates?.map(buttonState => buttonState.answerName);
        const questionAnswerNames = question.answers.map(answer => answer.name);

        if (!buttonStates || !isEqual(buttonStateNames, questionAnswerNames)) {
            setButtonStates(question.answers.map(answer => ({
                answerName: answer.name,
                state: ANSWER_BUTTON_STATE.unselected,
            })));
        }
    }, [buttonStates, question.answers]);

    const updatedButtonStates = useCallback((answerName: string, newButtonState: ANSWER_BUTTON_STATE) => (buttonStates || []).map((bs) => {
        if (bs.answerName === answerName) {
            return { answerName: bs.answerName, state: newButtonState };
        } else {
            if (bs.state !== ANSWER_BUTTON_STATE.wrong && bs.state !== ANSWER_BUTTON_STATE.correct) {
                return { answerName: bs.answerName, state: ANSWER_BUTTON_STATE.unselected };
            }
        }
        return bs;
    }), [buttonStates]);

    const changeAnswerStatus = useCallback((answerName: string) => {
        const buttonState = buttonStates?.find((state) => state.answerName === answerName);

        if (buttonState?.state !== ANSWER_BUTTON_STATE.correct && buttonState?.state !== ANSWER_BUTTON_STATE.wrong) {
            setButtonStates(updatedButtonStates(buttonState?.answerName ?? '', ANSWER_BUTTON_STATE.selected));
        }
        setSelectedAnswerName(buttonState?.answerName ?? '');
    }, [buttonStates, updatedButtonStates]);

    function formatDate() {
        const date = new Date(Number(selectedYear), Number(selectedMonth), Number(selectedDay));

        const month = date.getMonth() + 1; // add 1 since January is 0
        const day = date.getDate();

        const paddedMonth = month.toString().padStart(2, '0');
        const paddedDay = day.toString().padStart(2, '0');

        const formattedDate = String(paddedDay + '/' + paddedMonth + '/' + date.getFullYear());

        return formattedDate;
    }

    function checkAnswerPressed(game: GAME_SUBTYPE) {
        if (!selectedAnswerName || question.gameType !== GAME_TYPE.MatchingItemsGame) {
            return;
        }

        const correctAnswerName = question.answers.find((answr) => answr.isCorrect === true)?.name;
        const isCardCorrectNew = selectedAnswerName === correctAnswerName;

        if (!isCardCorrectNew) {
            setSelectedAnswerName('');
        }

        // Check card answer
        setIsCardCorrect(isCardCorrectNew);
        setButtonStates(updatedButtonStates(selectedAnswerName, isCardCorrectNew ? ANSWER_BUTTON_STATE.correct : ANSWER_BUTTON_STATE.wrong));

        // Check wheel answer
        let wheelAnswer: string = '';
        switch (game) {
            case GAME_SUBTYPE.price:
                wheelAnswer = String(Number(selectedDollarAmount) + '.' + Number(selectedCentsAmount));
                break;

            case GAME_SUBTYPE.date:
                wheelAnswer = formatDate();
                break;

            case GAME_SUBTYPE.number:
                wheelAnswer = String(Number(selectedAmount));
                break;

            case GAME_SUBTYPE.year:
                wheelAnswer = String(selectedYear);
                break;

            default:
                break;
        }

        let gameAnswer = question.matchingItemsGameValue;
        if (question.gameSubType === GAME_SUBTYPE.number) {
            gameAnswer = Number(gameAnswer).toString();
        } else if (question.gameSubType === GAME_SUBTYPE.price) {
            const dollars = Number(gameAnswer.split('.')[0]);
            const cents = Number(gameAnswer.split('.')[1]);
            gameAnswer = dollars + '.' + cents;
        }

        const isWheelCorrect = wheelAnswer === gameAnswer;
        setIsWheelSelectionCorrect(isWheelCorrect);

        mbShowToast({
            ...utils.gameFeedbackToastParams(isCardCorrectNew && isWheelCorrect),
            text2: envs.FLAVOR === 'prod' ? undefined : `e: ${wheelAnswer} r: ${gameAnswer},${selectedAnswerName}-${correctAnswerName}`,
        });

        onAnswerPressed(isCardCorrectNew && isWheelCorrect);
    }

    const { visible, height } = useKeybaord();

    useEffect(() => {
        setAdjustPan();
        return () => setAdjustResize();
    }, []);

    return (
        <View style={[!isPortrait && styles.horizontalContainer]}>
            <MatchGameCards
                question={question}
                disableAnswers={disableAnswers || isCardCorrect}
                buttonStates={buttonStates}
                changeAnswerStyles={changeAnswerStatus}
                isPortrait={isPortrait}
            />
            {visible && <View style={styles.fixedControls} />}
            <View style={[styles.controls, styles.floatingControls, { position: visible ? 'absolute' : 'relative', bottom: height }]}>
                <View style={[styles.wheelControls, isWheelSelectionCorrect === true && styles.correctAnswer, isWheelSelectionCorrect === false && styles.wrongAnswer]} pointerEvents={isWheelSelectionCorrect ? 'none' : undefined}>
                    {question.gameSubType === GAME_SUBTYPE.price &&
                        <MoneyInput
                            dollars={selectedDollarAmount}
                            cents={selectedCentsAmount}
                            onSelectedDollarAmount={setSelectedDollarAmount}
                            onSelectedCentsAmount={setSelectedCentsAmount}
                        />
                    }
                    {question.gameSubType === GAME_SUBTYPE.number &&
                        <AmountInput
                            amount={selectedAmount}
                            onSelectedAmount={setSelectedAmount}
                        />
                    }
                    {question.gameSubType === GAME_SUBTYPE.date &&
                        <DateInput
                            year={selectedYear}
                            month={selectedMonth}
                            day={selectedDay}
                            onSelectedMonth={setSelectedMonth}
                            onSelectedDay={setSelectedDay}
                            onSelectedYear={setSelectedYear}
                        />
                    }
                    {question.gameSubType === GAME_SUBTYPE.year &&
                        <DateInput
                            year={selectedYear}
                            showYearOnly
                            onSelectedYear={setSelectedYear}
                        />
                    }
                </View>
                <MB_Button
                    textStyle={styles.buttonText}
                    style={styles.buttonStyle}
                    title="Check answer"
                    onPress={() => checkAnswerPressed(question.gameSubType)}
                    disabled={!selectedAnswerName}
                />
            </View>
        </View>
    );
});

export { ShoppingGameControls };

const styles = StyleSheet.create({
    horizontalContainer: {
        backgroundColor: COLORS.backgroundDarkPurple,
        paddingHorizontal: 8,
        aspectRatio: 1.34,
    },
    controls: {
        flexDirection: 'row',
    },
    fixedControls: {
        height: 80,
    },
    floatingControls: {
        height: 80,
        padding: 6,
        left: 0,
        right: 0,
        backgroundColor: '#1D183B',
    },
    wheelControls: {
        marginEnd: 8,
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: 1,
        borderColor: COLORS.englishViolet,
        borderRadius: 5,
        padding: 16,
        flex: 1,
    },
    buttonText: mbTextStyles([
        textStyles.normalText,
    ]),
    buttonStyle: {
        backgroundColor: COLORS.buttonPurple,
        alignSelf: 'stretch',
        height: '100%',
        width: '25%',
        paddingHorizontal: 6,
    },
    wrongAnswer: {
        borderColor: COLORS.errorColor,
        ...mbShadow({
            color: COLORS.errorColor,
            offsetWidth: 2,
            offsetHeight: 2,
            opacity: 0.5,
            radius: 8,
            elevation: 3,
        }),
    },
    correctAnswer: {
        borderColor: COLORS.correctAnswerColor,
        ...mbShadow({
            color: COLORS.correctAnswerColor,
            offsetWidth: 2,
            offsetHeight: 2,
            opacity: 0.7,
            radius: 8,
            elevation: 3,
        }),
    },
});
