import { z } from 'zod';
import { DIFFICULTY, GAME_SUBTYPE, GAME_TYPE, PAYMENT_STATUS, ROOM_STATUS, SCORE_TYPE, UserRole } from '../../typesAndInterfaces/typesAndInterfaces';
import { utils } from '../utils';
import { zodUtils } from './zodUtils';
import { BODY_PARTS } from '../../components/screens/Game/bodyPartsControls/bodyPartsConstants';
import { GEOGRAPHY_LOCATION, GEOGRAPHY_MAPS } from '../../components/screens/Game/geographyControls/geographyConstants';

// note: in case we want to use our custom zod we can use
// import z from './zod';

export const VersionObj = z.object({
    version: z.string(),
});/*.strict();*/

export const ImageObj = z.object({
    _id: z.string().optional(),
    small: z.string().optional(),
    medium: z.string().optional(),
    large: z.string().optional(),
});/*.strict();*/

export const VideoObj = z.object({
    _id: z.string().optional(),
    thumbnail: ImageObj.optional(),
    url: z.string(),
    isProcessing: z.boolean(),
});

export const PoolImageObj = ImageObj.extend({
    _id: z.string(),
    name: z.string(),
});

export const PoolVideoObj = VideoObj.extend({
    _id: z.string(),
    name: z.string(),
});

export const AnswerObj = z.object({
    image: PoolImageObj,
    name: z.string(),
    isCorrect: z.boolean(),
});/*.strict();*/

const BaseQuestionObj = z.object({
    _id: z.string(),
    name: z.string(),
    signerName: z.string(),
    video: VideoObj,
    categoryId: z.string(),
    answers: z.array(AnswerObj),
    isEnabled: z.boolean(),
});

const IntroGameQuestionObj = BaseQuestionObj.extend({
    gameType: z.literal(GAME_TYPE.IntroGame),
});/*.strict();*/


const MatchingItemsGameQuestionObj = BaseQuestionObj.extend({
    gameType: z.literal(GAME_TYPE.MatchingItemsGame),
    gameSubType: z.nativeEnum(GAME_SUBTYPE),
    matchingItemsGameValue: z.string(),
});/*.strict();*/

const BodyPartQuestionObj = BaseQuestionObj.extend({
    gameType: z.literal(GAME_TYPE.BodyParts),
    bodyPart: z.nativeEnum(BODY_PARTS),
});/*.strict();*/

const GeographyQuestionObj = BaseQuestionObj.omit({ answers: true }).extend({
    gameType: z.literal(GAME_TYPE.Geography),
    geographyMap: z.nativeEnum(GEOGRAPHY_MAPS),
    geographyLocation: z.nativeEnum(GEOGRAPHY_LOCATION),
}).strict();

export const QuestionObj = z.union([IntroGameQuestionObj, MatchingItemsGameQuestionObj, BodyPartQuestionObj, GeographyQuestionObj]).refine(data => {
    if (data.gameType === GAME_TYPE.MatchingItemsGame && utils.validateSubtypeValue(data.gameSubType, data.matchingItemsGameValue) === undefined) {
        return false;
    }
    return true;
});

const IntroGameQuestionShortObj = IntroGameQuestionObj.omit({ answers: true }).extend({
    numberOfAnswers: z.number().gte(0),
});/*.strict();*/

const MatchingItemsGameQuestionShortObj = MatchingItemsGameQuestionObj.omit({ answers: true }).extend({
    numberOfAnswers: z.number().gte(0),
});/*.strict();*/

const BodyPartGameQuestionShortObj = BodyPartQuestionObj.omit({ answers: true }).extend({
    numberOfAnswers: z.number().gte(0),
});/*.strict();*/

export const QuestionShortObj = z.union([IntroGameQuestionShortObj, MatchingItemsGameQuestionShortObj, BodyPartGameQuestionShortObj, GeographyQuestionObj]).refine(data => {
    if (data.gameType === GAME_TYPE.MatchingItemsGame && !utils.validateSubtypeValue(data.gameSubType, data.matchingItemsGameValue) === undefined) {
        return false;
    }
    return true;
});

export const IncompleteQuestionsDataObj = z.object({
    questionsIds: z.array(z.string()),
    totalNumberOfQuestions: z.number().gte(0),
});/*.strict();*/

export const CategoryObj = z.object({
    _id: z.string(),
    name: z.string(),
    isFree: z.boolean(),
    isEnabled: z.boolean(),
    numberOfQuestions: z.number().gte(0),
    difficulty: z.array(z.nativeEnum(DIFFICULTY)),
    gameType: z.nativeEnum(GAME_TYPE),
    tutorialURL: z.string().optional(),
    image: z.string().or(PoolImageObj).optional(),
    extras: z.array(z.string().or(PoolVideoObj)).optional(),
});/*.strict();*/

export const CategoryForUserObj = CategoryObj.extend({
    numberOfQuestionsCompleted: z.number().gte(0),
});/*.strict();*/

export const userQuestionareObj = z.object({
    isUserDeaf: z.boolean(),
    isUserKnowDeaf: z.boolean(),
    interestedSchool: z.boolean(),
    interestedWork: z.boolean(),
    interestedPersonal: z.boolean(),
});

export const UserObj = z.object({
    _id: z.string(),
    email: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    userRole: z.nativeEnum(UserRole),
    difficulty: z.nativeEnum(DIFFICULTY),
    createdAt: z.string(),
    paymentStatus: z.nativeEnum(PAYMENT_STATUS),
    manualPremium: z.boolean().optional(),
    userQuestionaire: userQuestionareObj.optional(),
    educatorCode: z.object({
        code: z.string(),
        expireDate: z.string(),
    }).optional(),
    settings: z.object({
        canShowBanner: z.boolean().optional(),
    }).optional(),
});/*.strict();*/

export const AccessTokenObj = z.object({
    expiresAt: z.string(),
    expiresAtMs: z.number(),
    expiresIn: z.string(),
    expiresInMs: z.number(),
    token: z.string(),
});/*.strict();*/

export const TokensObj = z.object({
    refreshToken: z.string(),
    accessToken: AccessTokenObj,
});/*.strict();*/

export const PasswordRecoveryTokenObj = z.object({
    token: z.string(),
    expiresAtMs: z.number(),
});/*.strict();*/

export const ChangeDifficultyResponseObj = z.object({
    userId: z.string(),
    difficulty: z.nativeEnum(DIFFICULTY),
});/*.strict();*/

export const ChangeCategoryPaymentObj = z.object({
    categoryId: z.string(),
    isFree: z.boolean(),
});/*.strict();*/

export const FolderObj = z.object({
    _id: z.string(),
    name: z.string(),
});/*.strict();*/

export const GameScoreObj = z.object({
    userId: z.string(),
    name: z.string(),
    score: z.number(),
});

export const LeaderboardObj = z.array(GameScoreObj);

export const RoomObj = z.object({
    _id: z.string(),
    name: z.string(),
    creatorUserId: z.string(),
    code: z.string(),
    status: z.nativeEnum(ROOM_STATUS),
    leaderboard: LeaderboardObj,
    scoringType: z.nativeEnum(SCORE_TYPE),
    gameType: z.nativeEnum(GAME_TYPE).optional(),
    categories: z.array(z.string()),
    isCreatorParticipating: z.boolean(),
});/*.strict();*/

export const PusherAuthorizationObj = z.object({
    channel_data: z.string(),
    auth: z.string(),
});/*.strict();*/

export const PusherUserInfoObj = z.object({
    name: z.string(),
});/*.strict();*/

export const PusherPresenceObj = z.object({
    count: z.number(),
    ids: z.array(z.string()),
    hash: z.record(z.string(), PusherUserInfoObj),
});/*.strict();*/

export const RoomGameObj = z.object({
    gameType: z.nativeEnum(GAME_TYPE),
    categories: z.array(z.string()),
});/*.strict();*/

export const RoomUpdatedObj = z.object({
    scoringType: z.nativeEnum(SCORE_TYPE),
    roomGame: RoomGameObj.optional(),
    isCreatorParticipating: z.boolean(),
}).strict();

export const GameStartedObj = z.object({
    scoringType: z.nativeEnum(SCORE_TYPE),
    roomGame: RoomGameObj,
}).strict();

export const GameFinishedObj = z.object({
    status: z.literal(ROOM_STATUS.ENDED),
}).strict();

export const ExtrasObj = z.object({
    _id: z.string(),
    poolVideo: PoolVideoObj,
});

export const EducatorCodeObj = z.object({
    _id: z.string(),
    code: z.string(),
    name: z.string(),
    numberOfLicenses: z.number(),
    numberOfLicensesUsed: z.number(),
    expireDate: z.string(),
    description: z.string().optional(),
    createdAt: z.string(),
});

export const QuestionObjArray = zodUtils.getArray(QuestionObj);

export const QuestionShortObjArray = zodUtils.getArray(QuestionShortObj);

export const PoolImageObjInfinitePagination = zodUtils.getInfinitePagination(PoolImageObj);

export const PoolVideoObjInfinitePagination = zodUtils.getInfinitePagination(PoolVideoObj);

export const UserObjPagination = zodUtils.getPagination(UserObj);

export const CategoryObjPagination = zodUtils.getPagination(CategoryObj);

export const CategoryForUserObjInfinitePagination = zodUtils.getInfinitePagination(CategoryForUserObj);

export const EducatorCodeObjPagination = zodUtils.getPagination(EducatorCodeObj);
