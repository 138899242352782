import * as React from 'react';
import Svg, { Path, G, Defs, ClipPath } from 'react-native-svg';

const AddFolderIcon = ({ size = 102 }: { size?: number }) => {
    return (
        <Svg
            width={size ?? 120}
            height={size ? (size * (120 / 102)) : 120}
            viewBox="0 0 120 102"
            fill="none"
        >
            <Path
                d="M104.861 100.348H14.959A13.294 13.294 0 011.5 87.278V14.572A13.298 13.298 0 0114.96 1.5H42.53a4.49 4.49 0 013.453 1.662l.107.131.133.105L61.981 15.74l.407.32h42.473l.018-.001A13.3 13.3 0 01118.5 29.127l-.18 58.149a13.298 13.298 0 01-13.459 13.072z"
                stroke="#782ABE"
                strokeWidth={3}
            />
            <G
                clipPath="url(#clip0_1569_4014)"
                stroke="#fff"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <Path d="M60 46.714v20.571M49.714 57h20.572" />
            </G>
            <Defs>
                <ClipPath id="clip0_1569_4014">
                    <Path fill="#fff" transform="translate(48 45)" d="M0 0H24V24H0z" />
                </ClipPath>
            </Defs>
        </Svg>
    );
};

export { AddFolderIcon };
