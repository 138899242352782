import * as React from 'react';
import Svg, { Circle, Defs, G, Path } from 'react-native-svg';

function HomeIcon(props: { size?: number }) {
    return (
        <Svg
            width={props.size ?? 77}
            height={props.size ?? 77}
            viewBox="0 0 77 77"
            fill="none"
        >
            <G filter="url(#filter0_d_917_1557)">
                <Circle cx={38.5} cy={38.5} r={30.5} fill="#fff" />
                <Circle cx={38.5} cy={38.5} r={29.5} stroke="#A430FF" strokeWidth={2} />
            </G>
            <Path
                d="M27 34l12-9.333L51 34v14.667a2.667 2.667 0 01-2.667 2.666H29.667A2.667 2.667 0 0127 48.667V34z"
                stroke="#412366"
                strokeWidth={2.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <Path
                d="M35 51.333V38h8v13.333"
                stroke="#412366"
                strokeWidth={2.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <Defs />
        </Svg>
    );
}

export default HomeIcon;
