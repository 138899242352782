import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { MB_utilHooks } from '@mightybyte/rnw.utils.util-hooks';
import { useNavigation } from '@react-navigation/core';
import { default as React, useCallback, useState } from 'react';
import { Linking, StyleSheet, Text, View } from 'react-native';
import { COLORS } from '../../../constants/colors';
import { textStyles } from '../../../constants/textStyles';
import { useGetCurrentUserData } from '../../../hooks/userHooks';
import { HomeNavigatorScreenProps, UniversalScreenNavigationProp } from '../../../typesAndInterfaces/componentProps';
import { LOADING_PAGE_GIVE_UP_ACTION_TYPES, LoadingPage } from '../../helperComponents/LoadingPage';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import { MB_OnHoverWrapper } from '@mightybyte/rnw.components.on-hover-wrapper';
import Feather from 'react-native-vector-icons/Feather';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { MyAccountPersonalInformation } from './MyAccountPersonalInformation';
import BecomeASponsor from './BecomeASponsor';
import ContactUs from '../ContactUs';
import { envs } from '../../../../env';
import { DeleteAccountModal } from './DeleteAccountModal';
import { getVersionNumber } from '../../../utils/getVersionNumber/getVersionNumber';
import { useGetServerVersion } from '../../../hooks/serverUtilHooks';
import CouponIcon from '../../../resources/svgComponents/CouponIcon';
import { utilHooks } from '../../../hooks/utilHooks';

const versions = getVersionNumber();

enum MENU_ITEMS {
    editProfile,
    becomeASponsor,
    contactUs,
    tos,
    privacy,
    deleteAccount,
}


const MyAccount = ({ route }: HomeNavigatorScreenProps<'MyAccount'>) => {
    const navigation = useNavigation<UniversalScreenNavigationProp>();
    const { data: serverVersion } = useGetServerVersion();

    const [section, setSection] = useState(0);
    const { data: currentUserData } = useGetCurrentUserData();
    const { canAccessGames } = utilHooks.useGetAccess(currentUserData);
    const [isDeleteModalVisible, showDeleteModal, hideDeleteModal] = MB_utilHooks.useBool(route.params?.showDeleteModal);

    const onItemPress = useCallback((menuItem: MENU_ITEMS) => {
        switch (menuItem) {
            case MENU_ITEMS.editProfile:
                setSection(0);
                break;
            case MENU_ITEMS.becomeASponsor:
                setSection(1);
                break;
            case MENU_ITEMS.contactUs:
                setSection(2);
                break;
            case MENU_ITEMS.tos:
                Linking.openURL(envs.TOS_URL);
                break;
            case MENU_ITEMS.privacy:
                Linking.openURL(envs.PRIVACY_POLICY_URL);
                break;
            case MENU_ITEMS.deleteAccount:
                showDeleteModal();
                break;

        }
    }, [showDeleteModal]);

    if (!currentUserData) {
        return <LoadingPage giveUpAction={LOADING_PAGE_GIVE_UP_ACTION_TYPES.backToHome} navigation={navigation} />;
    }

    return (
        <ComponentWrapper
            containerStyle={styles.container}
            onBackPress={() => navigation.navigate('HomeNavigator', { screen: 'GameNavigator', params: { screen: 'SelectGame' } })}
        >
            <DeleteAccountModal
                isVisible={isDeleteModalVisible}
                onDismiss={hideDeleteModal}
            />
            <View style={styles.content}>
                <View style={styles.leftSection}>
                    <Text style={styles.title}>Profile Settings</Text>
                    <View style={styles.menuItems}>
                        <View style={styles.spacer} />
                        <MB_OnHoverWrapper wrapperStyle={[styles.menuItem, section === 0 && styles.menuItemActive]} onHoverWrapperStyleModifier={styles.menuItemsHover} onPress={() => onItemPress(MENU_ITEMS.editProfile)}>
                            <Feather name="user" color={COLORS.white} size={20} />
                            <Text style={styles.menuItemText}>Edit profile</Text>
                        </MB_OnHoverWrapper>
                        <View style={styles.spacer} />
                        {!canAccessGames &&
                            <MB_OnHoverWrapper wrapperStyle={[styles.menuItem]} onHoverWrapperStyleModifier={styles.menuItemsHover} onPress={() => navigation.navigate('PaymentPage')}>
                                <CouponIcon size={20} />
                                <Text style={styles.menuItemText}>Educator Code</Text>
                            </MB_OnHoverWrapper>
                        }
                        <View style={styles.spacer} />
                        <MB_OnHoverWrapper wrapperStyle={[styles.menuItem, section === 1 && styles.menuItemActive]} onHoverWrapperStyleModifier={styles.menuItemsHover} onPress={() => onItemPress(MENU_ITEMS.becomeASponsor)}>
                            <Feather name="users" color={COLORS.white} size={20} />
                            <Text style={styles.menuItemText}>Become a sponsor</Text>
                        </MB_OnHoverWrapper>
                        <View style={styles.spacer} />
                        <MB_OnHoverWrapper wrapperStyle={[styles.menuItem, section === 2 && styles.menuItemActive]} onHoverWrapperStyleModifier={styles.menuItemsHover} onPress={() => onItemPress(MENU_ITEMS.contactUs)}>
                            <Feather name="phone" color={COLORS.white} size={20} />
                            <Text style={styles.menuItemText}>Contact us</Text>
                        </MB_OnHoverWrapper>
                        <View style={styles.spacer} />
                        <MB_OnHoverWrapper wrapperStyle={[styles.menuItem, section === 3 && styles.menuItemActive]} onHoverWrapperStyleModifier={styles.menuItemsHover} onPress={() => onItemPress(MENU_ITEMS.tos)}>
                            <MaterialCommunityIcons name="file-document-outline" color={COLORS.white} size={20} />
                            <Text style={styles.menuItemText}>Terms of service</Text>
                        </MB_OnHoverWrapper>
                        <View style={styles.spacer} />
                        <MB_OnHoverWrapper wrapperStyle={[styles.menuItem, section === 4 && styles.menuItemActive]} onHoverWrapperStyleModifier={styles.menuItemsHover} onPress={() => onItemPress(MENU_ITEMS.privacy)}>
                            <MaterialCommunityIcons name="shield-alert-outline" color={COLORS.white} size={20} />
                            <Text style={styles.menuItemText}>Privacy policy</Text>
                        </MB_OnHoverWrapper>
                        <View style={styles.spacer} />
                        <MB_OnHoverWrapper wrapperStyle={[styles.menuItem, section === 6 && styles.menuItemActive]} onHoverWrapperStyleModifier={styles.menuItemsHover} onPress={() => onItemPress(MENU_ITEMS.deleteAccount)}>
                            <Feather name="trash" color={COLORS.errorColor} size={20} />
                            <Text style={[styles.menuItemText, { color: COLORS.errorColor }]}>Delete account</Text>
                        </MB_OnHoverWrapper>

                        <View style={styles.versionContainer}>
                            <Text style={styles.versionInfoText}>JS Version: {versions.jsVersion}</Text>
                            <Text style={styles.versionInfoText}>Server Version: {serverVersion?.version}</Text>
                            <Text style={styles.versionInfoText}>Flavor: {envs.FLAVOR}</Text>
                        </View>
                    </View>
                </View>
                <View style={styles.rightSection}>
                    {section === 0 && <MyAccountPersonalInformation />}
                    {section === 1 && <BecomeASponsor isVisible onDismiss={() => { }} />}
                    {section === 2 && <ContactUs />}
                </View>
            </View>

        </ComponentWrapper>
    );
};

export { MyAccount };

const styles = StyleSheet.create({
    container: {
        justifyContent: 'flex-start',
    },
    content: {
        flexDirection: 'row',
        paddingHorizontal: 120,
    },
    leftSection: {
        borderRightWidth: 0.5,
        borderRightColor: '#dcceff4d',
    },
    rightSection: {
        marginLeft: 45,
        marginTop: 52,
        flex: 1,
        paddingBottom: 30,
        minWidth: 300,
    },
    title: mbTextStyles([textStyles.largeText, {
        fontWeight: '700',
        fontSize: 26,
        textAlign: 'left',
        marginBottom: 8,
        marginRight: 63,
    }]),
    menuItems: {
        marginLeft: 20,
    },
    menuItemText: mbTextStyles([textStyles.smallText, {
        fontWeight: '500',
        textAlign: 'left',
        marginLeft: 8,
    }]),
    menuItem: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: 15,
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
    },
    menuItemActive: {
        borderRightWidth: 2,
        borderRightColor: '#A430FF',
        backgroundColor: '#412366',
    },
    menuItemsHover: {
        backgroundColor: '#412366',
    },
    spacer: {
        height: 19,
    },
    versionInfoText: {
        ...mbTextStyles({
            ...textStyles.tinyText,
            alignSelf: 'flex-start',
        }),
    },
    versionContainer: {
        marginTop: 'auto',
        marginStart: 16,
        marginBottom: 32,
        paddingTop: 32,
    },
});
