import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import * as React from 'react';
import { Dimensions, StyleSheet, View } from 'react-native';
import Svg, { Path } from 'react-native-svg';

function LoginBackground() {
    return (
        <View style={styles.container}>
            <Svg
                viewBox="0 0 1440 183"
                fill="none"
                width={isMobileApp ? Dimensions.get('window').width : undefined}
                height={isMobileApp ? Dimensions.get('window').width * 183 / 1440 : undefined}
            >
                <Path
                    d="M1423.86 104.684l-25.78-12.846c-25.69-12.767-77.26-38.458-129.6-52.864-52.35-14.405-105.66-17.54-160.36-1.587-54.7 15.953-110.985 51.12-164.593 53.029-53.608 1.909-104.736-29.457-157.712-35.706-52.975-6.248-107.998 12.605-160.715 3.023S521.761 10.26 468.918 2.31c-52.849-7.88-108.124 14.236-162.612 27.494-54.488 13.257-108.389 17.64-161.584 14.23C91.526 40.62 38.836 29.4 12.591 23.797l-26.345-5.61-8.47 109.234 26.62 2.064 132.901 10.305 159.521 12.369 159.521 12.369 159.521 12.37 159.522 12.369 159.521 12.369 159.517 12.37 159.53 12.369 132.9 10.305 26.62 2.064 10.39-134.06z"
                    fill="#5E2B97"
                />
                <Path
                    d="M1440 78.618l-26.7-1.716c-26.6-1.616-80-5.049-133.3 3.433-53.3 8.38-106.7 28.573-160 33.419-53.3 4.948-106.7-5.553-160-22.515S853.3 50.65 800 38.736c-53.3-11.913-106.7-12.317-160-4.341-53.3 7.875-106.7 24.03-160 36.146S373.3 90.734 320 97.6c-53.3 6.967-106.7 12.621-160 11.005-53.3-1.716-106.7-10.803-133.3-15.347L0 88.715V223h1440V78.618z"
                    fill="#7F30CB"
                />
                <Path
                    d="M1441.4 121.06l-26.62-3.858c-26.52-3.855-79.76-11.571-133.15-7.994-53.4 3.447-107.15 18.311-160.48 19.033-53.33.722-106.44-12.704-159.836-9.127-53.396 3.576-107.277 23.891-160.822 33.956-53.546 10.066-106.95 9.617-159.997-1.989-53.047-11.607-105.938-34.376-159.194-36.899-53.256-2.522-107.077 15.197-160.653 26.56-53.575 11.363-107.098 16.105-160.226 8.003-53.128-8.103-106.054-29.315-132.42-39.789L1.537 98.35l-3.162 137.558 26.693.614 133.265 3.063 159.958 3.677 159.957 3.677 159.958 3.677 159.958 3.676 159.958 3.677 159.957 3.677 159.96 3.677 133.26 3.063 26.7.614 3.4-147.94z"
                    fill="#A430FF"
                />
            </Svg>
        </View>
    );
}

export default LoginBackground;

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#190733',
        flex: 1,
        justifyContent: 'flex-end',
    },
});
