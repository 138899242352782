import { StyleSheet } from 'react-native';
import { COLORS } from './colors';

const tableStyles = StyleSheet.create({
    tableStyle: {
        backgroundColor: COLORS.backgroundPurple,
        borderRadius: 8,
        borderWidth: 0,
        marginTop: 24,
    },
    tableHeader: {
        height: 66,
        backgroundColor: 'transparent',
        borderBottomWidth: 2,
        borderBottomColor: COLORS.backgroundDarkPurple,
    },
    rowStyle: {
        borderWidth: 0,
    },
    pageIndicatorStyle: {
        borderTopWidth: 2,
        borderTopColor: COLORS.backgroundDarkPurple,
        paddingVertical: 18,
    },
});

export { tableStyles };
