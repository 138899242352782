import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle, Text, TouchableOpacity } from 'react-native';
import { COLORS } from '../../constants/colors';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../constants/textStyles';
import { ForeignObject, Rect } from 'react-native-svg';
import { BODY_PARTS, bodyPartsSvgConfigs } from '../screens/Game/bodyPartsControls/bodyPartsConstants';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';

enum BODY_PARTS_SVG_BUTTON_TYPES {
    activate = 'activate',
    select = 'select',
    enlarge = 'enlarge',
}

interface BodyPartSvgButtonProps {
    type: BODY_PARTS_SVG_BUTTON_TYPES,
    bodyPart: BODY_PARTS,
    forceTitle?: string,
    activateAsSelect?: boolean,
    onActivate: (value: React.SetStateAction<BODY_PARTS | undefined>) => void
    onPress: (tag: BODY_PARTS, selectedOrZoom: 'selected' | 'zoom') => void,
    width: number,
    height: number,
    x?: number,
    y?: number,
    buttonStyle?: StyleProp<ViewStyle>,
}


const wrapperBackground: { [key in keyof typeof BODY_PARTS_SVG_BUTTON_TYPES]: string } = {
    'activate': 'transparent',
    'select': COLORS.buttonPurple,
    'enlarge': COLORS.shadowGreen,
};


const BodyPartSvgButton = (props: BodyPartSvgButtonProps) => {

    function getTitle(): string {
        if (props.forceTitle) {
            return props.forceTitle;
        }

        if (props.type === BODY_PARTS_SVG_BUTTON_TYPES.enlarge) {
            return 'Zoom';
        } else if (props.type === BODY_PARTS_SVG_BUTTON_TYPES.activate) {
            return bodyPartsSvgConfigs[props.bodyPart].displayText;
        } else {
            return 'Select ' + bodyPartsSvgConfigs[props.bodyPart].displayText;
        }
    }

    function onPress() {
        if (props.type === BODY_PARTS_SVG_BUTTON_TYPES.activate) {
            if (props.activateAsSelect) {
                props.onActivate(undefined);
                props.onPress(props.bodyPart, 'selected');
            } else {
                props.onActivate(props.bodyPart);
            }

        } else {
            props.onActivate(undefined);
            props.onPress(props.bodyPart, props.type === BODY_PARTS_SVG_BUTTON_TYPES.select ? 'selected' : 'zoom');
        }
    }

    if (isMobileApp) {
        return (
            <>
                <ForeignObject
                    x={props.x}
                    y={props.y}
                    width={props.width}
                    height={props.height}
                >
                    <View
                        style={[
                            props.type === BODY_PARTS_SVG_BUTTON_TYPES.activate ? styles.wrapperDefault : styles.wrapperBorder, {
                                borderColor: wrapperBackground[props.type],
                                width: props.width,
                                height: props.height,
                            },
                        ]}
                    >
                        <View style={[
                            {
                                width: '100%',
                                height: '100%',
                                borderRadius: 5,
                                backgroundColor: COLORS.white,
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingHorizontal: 2,
                            },
                            props.buttonStyle,
                        ]}>
                            <Text style={styles.mobileButtonTextStyle}>{getTitle()}</Text>
                        </View>
                    </View>
                </ForeignObject>
                <Rect
                    x={props.x}
                    y={props.y}
                    width={props.width}
                    height={props.height}
                    onPressIn={onPress}
                />
            </>
        );
    }

    return (
        <ForeignObject
            x={props.x}
            y={props.y}
            width={props.width}
            height={props.height}
        >
            <TouchableOpacity
                activeOpacity={1}
                onPress={onPress}
                style={[
                    styles.buttonStyle,
                    { position: 'static' as 'absolute' },
                    props.type !== BODY_PARTS_SVG_BUTTON_TYPES.activate && styles.wrapperBorder,
                    { borderColor: wrapperBackground[props.type] },
                    props.buttonStyle,
                ]}
            >
                <Text style={[styles.buttonTextStyle]}>{getTitle()}</Text>
            </TouchableOpacity>
        </ForeignObject>
    );
};

export { BodyPartSvgButton, BODY_PARTS_SVG_BUTTON_TYPES };

const styles = StyleSheet.create({
    wrapperDefault: {
        width: '100%',
        height: '100%',
    },
    wrapperBorder: {
        borderRadius: 5,
        borderColor: COLORS.buttonPurple,
        borderWidth: 4,
        backgroundColor: COLORS.white,
    },
    buttonStyle: {
        width: '100%',
        height: '100%',
        borderRadius: 5,
        backgroundColor: COLORS.white,
        justifyContent: 'center',
    },
    buttonTextStyle: mbTextStyles([
        textStyles.normalText, {
            fontSize: 16,
            fontWeight: '600',
            color: COLORS.backgroundPurple,
            lineHeight: 18,
        },
    ], { allowLineHeight: true }),
    mobileButtonTextStyle: {
        fontSize: 16,
        color: COLORS.backgroundPurple,
        lineHeight: 16,
        fontWeight: '600',
        textAlign: 'center',
    },
});
