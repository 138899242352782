import { MB_accessTokenUtils } from '@mightybyte/rnw.utils.access-token-utils';
import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useState } from 'react';
import { STRING_CONSTANTS, USER_QUERY_KEYS } from '../../../constants/constants';
import { SIGNED_IN_STATUS, useSignedInContext } from '../../../context/SignedInContext';
import { useSignIn } from '../../../hooks/userHooks';
import { UniversalProps } from '../../../typesAndInterfaces/componentProps';
import LoginUI from './LoginUI';
import { LOGIN_UI_BUTTON_PRESSED_TYPES } from './LoginUtils';

const Login = ({ navigation }: UniversalProps) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { setSignedInStatus } = useSignedInContext();
  const queryClient = useQueryClient();

  const signIn = useSignIn();

  const onButtonPressedCallback = useCallback((buttonType: LOGIN_UI_BUTTON_PRESSED_TYPES) => {
    switch (buttonType) {
      case LOGIN_UI_BUTTON_PRESSED_TYPES.SIGN_IN:
        signIn.mutate(
          { email, password },
          {
            onSuccess: data => {
              setSignedInStatus?.(SIGNED_IN_STATUS.signedIn);
              queryClient.invalidateQueries([USER_QUERY_KEYS.getCurrentUsertData]);
              MB_accessTokenUtils.setAccessToken(data);
            },
            onError: () => {
              setErrorMessage(STRING_CONSTANTS.FAILED_TO_LOG_IN);
            },
          },
        );
        break;
      case LOGIN_UI_BUTTON_PRESSED_TYPES.NAVIGATE_HOME:
        navigation.navigate('HomeNavigator', { screen: 'GameNavigator', params: { screen: 'SelectGame' } });
        break;
      case LOGIN_UI_BUTTON_PRESSED_TYPES.NAVIGATE_SIGN_UP:
        navigation.navigate('SignUp');
        break;
      case LOGIN_UI_BUTTON_PRESSED_TYPES.NAVIGATE_FORGOT_PASSWORD:
        navigation.navigate('ForgotPassword');
        break;
    }
  }, [email, navigation, password, queryClient, setSignedInStatus, signIn]);

  const socialLoginCallback = useCallback((isSuccess: boolean) => {
    if (!isSuccess) {
      setErrorMessage(STRING_CONSTANTS.FAILED_TO_LOG_IN);
    }
  }, []);

  return (
    <LoginUI
      onButtonPressed={onButtonPressedCallback}
      socialLoginCallback={socialLoginCallback}
      email={email}
      setEmail={setEmail}
      password={password}
      setPassword={setPassword}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
      isLoading={signIn.isLoading}
    />
  );
};

export { Login };
