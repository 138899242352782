import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import { MB_Button } from '@mightybyte/rnw.components.button';
import { MB_Modal } from '@mightybyte/rnw.components.modal';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../constants/textStyles';
import CongratulationsBanner from '../../../resources/svgComponents/CongratulationsBanner';
import HomeIcon from '../../../resources/svgComponents/HomeIcon';
import ResetIcon from '../../../resources/svgComponents/ResetIcon';
import { COLORS } from '../../../constants/colors';
import { useNetInfo } from '@react-native-community/netinfo';

const CongratulationsModal = (props: { isVisible: boolean, resetAction: () => void, homeAction: () => void, categoryName: string }) => {
    return (
        <MB_Modal
            simplified={true}
            hideCloseButton={true}
            isVisible={props.isVisible}
        >
            <View style={styles.congratulationsModalContainer}>
                <CongratulationsBanner size={350} containerStyle={styles.congratulationsBanner} />

                <Text style={styles.completeText}>“{props.categoryName}” Category Complete</Text>
                <View style={{ flexDirection: 'row' }}>
                    <TouchableOpacity
                        onPress={props.resetAction}>
                        <ResetIcon />
                        <Text style={mbTextStyles([textStyles.smallText])}>Reset</Text>
                    </TouchableOpacity>

                    <TouchableOpacity
                        style={{ marginStart: 44 }}
                        onPress={props.homeAction}>
                        <HomeIcon />
                        <Text style={mbTextStyles([textStyles.smallText])}>Home</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </MB_Modal>
    );
};

const ExitGameModal = (props: { isVisible: boolean, roomMode?: boolean, hideModal: (props: { shouldExit: boolean, modalType: 'exit' }) => void }) => {
    return (
        <MB_Modal
            isVisible={props.isVisible}
            onDismiss={() => props.hideModal({ shouldExit: false, modalType: 'exit' })}
            childrenWrapperStyle={styles.modalChildrenWrapperStyle}
        >
            <Text style={styles.modalTitleText}>{props.roomMode ? 'Leave game?' : 'Quit game'}</Text>
            <Text style={styles.modalDetailText}>{props.roomMode ? 'Are you sure you want to leave this game? Note that this action cannot be undone.' : 'You can pick up where you left off anytime'}</Text>
            <MB_Button
                title={props.roomMode ? 'Leave game' : 'Exit'}
                style={styles.modalExitButton}
                onPress={() => props.hideModal({ shouldExit: true, modalType: 'exit' })}
            />
            {props.roomMode ? <></> :
                <MB_Button
                    title="Keep playing"
                    style={styles.modalKeepPlayingButton}
                    onPress={() => props.hideModal({ shouldExit: false, modalType: 'exit' })}
                />
            }
        </MB_Modal>
    );
};

const ReConnectModal = (props: { isVisible: boolean, isConnecting: boolean, onGiveUp: () => void, onReconnect: () => void }) => {
    const netInfo = useNetInfo();

    const isInternetReachable = (netInfo.isConnected === null || netInfo.isInternetReachable === null) ? undefined : netInfo.isInternetReachable && netInfo.isConnected;

    return (
        <MB_Modal
            isVisible={props.isVisible}
            onDismiss={() => { }}
            childrenWrapperStyle={styles.modalChildrenWrapperStyle}
            hideCloseButton
        >
            <Text style={styles.modalTitleText}>Internet Error</Text>
            <Text style={styles.modalDetailText}>please check your internet connection to continue</Text>
            <Text style={styles.modalDetailText}>Connection Status: {isInternetReachable ? 'Connected' : 'Disconnected'}</Text>
            <MB_Button
                title="Reconnect"
                style={styles.modalExitButton}
                onPress={() => props.onReconnect()}
                disabled={!isInternetReachable || props.isConnecting}
                loading={isInternetReachable && props.isConnecting}
            />
            <MB_Button
                title="Exit"
                style={styles.modalKeepPlayingButton}
                onPress={() => props.onGiveUp()}
            />
        </MB_Modal>
    );
};

export { CongratulationsModal, ExitGameModal, ReConnectModal };

const styles = StyleSheet.create({
    congratulationsModalContainer: {
        alignItems: 'center',
        backgroundColor: COLORS.buttonPurple,
        borderColor: 'white',
        borderWidth: 1,
        padding: 20,
        borderRadius: 16,
        width: '80%',
        maxWidth: 274,
        height: 280,
    },
    congratulationsBanner: {
        position: 'absolute',
        top: -50,
    },
    completeText: mbTextStyles([
        textStyles.smallText, {
            marginTop: 75,
            marginBottom: 16,
            color: 'white',
            textAlign: 'center',
        }]
    ),
    modalChildrenWrapperStyle: {
        backgroundColor: COLORS.backgroundPurple,
        paddingHorizontal: 32,
    },
    modalTitleText: mbTextStyles([
        textStyles.largeText, {
            textAlign: 'center',
            color: COLORS.white,
            fontWeight: '700',
        },
    ]),
    modalDetailText: mbTextStyles([
        textStyles.smallerText, {
            marginTop: 12,
        },
    ]),
    modalExitButton: {
        marginTop: 26,
        backgroundColor: COLORS.buttonPurple,
        borderRadius: 10,
        alignSelf: 'stretch',
    },
    modalKeepPlayingButton: {
        backgroundColor: 'transparent',
        height: 42,
        marginTop: 28,
    },
});
