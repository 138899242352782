import React from 'react';
import { StyleProp, TextStyle, TouchableOpacity, ViewStyle } from 'react-native';
import { COLORS } from '../../constants/colors';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { mbApplyTransparency } from '@mightybyte/rnw.utils.style-utils';

interface ICheckbox {
  isChecked: boolean,
  onPress?: () => void,
  containerStyle?: StyleProp<ViewStyle>,
  checkboxStyleChecked?: StyleProp<TextStyle>,
  checkboxStyleUnchecked?: StyleProp<TextStyle>,
}

const Checkbox = ({ isChecked, onPress, containerStyle, checkboxStyleChecked, checkboxStyleUnchecked }: ICheckbox) => {
  return (
    <TouchableOpacity disabled={!onPress} style={containerStyle} onPress={onPress} >
      {isChecked ?
        <MaterialCommunityIcons name="checkbox-marked" size={24} color={COLORS.white} style={checkboxStyleChecked} />
        :
        <MaterialCommunityIcons name="checkbox-blank-outline" size={24} color={mbApplyTransparency(COLORS.white, 0.54)} style={checkboxStyleUnchecked} />
      }
    </TouchableOpacity>
  );
};

export { Checkbox };
