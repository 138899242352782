import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import Svg, { Rect } from 'react-native-svg';

const GameScreenScroeCard = (props: { size?: number, color?: string, style?: StyleProp<ViewStyle> }) => {
    return (
        <Svg
            width={props.size ?? 39}
            height={props.size ? (props.size * (29 / 39)) : 29}
            viewBox="0 0 39 29"
            fill="none"
            style={props.style}
        >
            <Rect
                width={20.8941}
                height={25.1998}
                rx={4}
                transform="matrix(.97082 -.2398 .27912 .96026 0 5.01)"
                fill={props.color ?? '#d9c9f1'}
                fillOpacity={0.75}
            />
            <Rect
                x={0.354968}
                y={0.594677}
                width={19.8941}
                height={24.1998}
                rx={3.5}
                transform="matrix(.97437 .22495 -.26443 .9644 18.978 .521)"
                fill={props.color ?? '#fff'}
                stroke={props.color ?? '#fff'}
            />
        </Svg>
    );
};

export default GameScreenScroeCard;
