import * as React from 'react';
import Svg, { G, Path, Defs, ClipPath } from 'react-native-svg';

const CoinIcon = () => {
    return (
        <Svg
            width={13}
            height={14}
            viewBox="0 0 13 14"
            fill="none"
        >
            <G clipPath="url(#clip0_3719_5131)">
                <Path d="M6.5 13.5a6.5 6.5 0 100-13 6.5 6.5 0 000 13z" fill="#FABE2C" />
                <Path
                    d="M13 7C13 3.424 10.076.5 6.5.5v13c3.576 0 6.5-2.924 6.5-6.5z"
                    fill="#FF9100"
                />
                <Path
                    d="M6.5 11.951a4.951 4.951 0 100-9.902 4.951 4.951 0 000 9.902z"
                    fill="#FED843"
                />
                <Path
                    d="M11.451 7A4.957 4.957 0 006.5 2.049v9.902A4.957 4.957 0 0011.451 7z"
                    fill="#FABE2C"
                />
                <Path
                    d="M9.76 5.941a.381.381 0 00-.307-.259L7.649 5.42l-.807-1.635A.372.372 0 006.5 3.59a.372.372 0 00-.341.195L5.352 5.42l-1.804.262a.381.381 0 00-.211.65l1.305 1.272-.308 1.797a.38.38 0 00.552.401L6.5 8.954l1.614.848a.38.38 0 00.552-.401l-.308-1.797 1.306-1.273a.38.38 0 00.096-.39z"
                    fill="#FABE2C"
                />
                <Path
                    d="M8.515 9.773a.38.38 0 00.151-.372l-.308-1.797 1.306-1.273a.38.38 0 00-.212-.65L7.65 5.42 6.84 3.785A.372.372 0 006.5 3.59v5.364l1.614.848a.379.379 0 00.4-.029z"
                    fill="#FF9100"
                />
            </G>
            <Defs>
                <ClipPath id="clip0_3719_5131">
                    <Path fill="#fff" transform="translate(0 .5)" d="M0 0H13V13H0z" />
                </ClipPath>
            </Defs>
        </Svg>
    );
};

export { CoinIcon };
