import { useMutation, useQueryClient, useInfiniteQuery, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { useEffect } from 'react';
import { videoPoolApiCalls } from '../apiCalls/videoPoolApiCalls';
import { IMAGE_VIDEO_POOL_QUERY_KEYS } from '../constants/constants';
import { PoolVideo, ServerError } from '../typesAndInterfaces/typesAndInterfaces';
import { MB_InfinitePaginationPage } from '@mightybyte/rnw.utils.util-hooks';

export const useUploadVideo = () => {
    const queryClient = useQueryClient();

    return useMutation<PoolVideo, ServerError, { formData: FormData, uploadProgress?: (percentCompleted: number) => void }, unknown>(
        async ({ formData, uploadProgress }) => videoPoolApiCalls.uploadVideo({ formData, uploadProgress }), {
        onSuccess: () => {
            queryClient.invalidateQueries([IMAGE_VIDEO_POOL_QUERY_KEYS.getVideoPoolVideos]);
        },
    });
};

export const useGetVideoPoolVideos = ({ totalItemsPerPage, searchText, folderId, queryOptions }: { totalItemsPerPage: number, searchText?: string, folderId?: string, queryOptions?: UseInfiniteQueryOptions<MB_InfinitePaginationPage<PoolVideo>, ServerError> }) => {
    const queryClient = useQueryClient();

    const searchQuery = searchText?.trim() === '' ? undefined : searchText;

    useEffect(() => {
        queryClient.invalidateQueries([IMAGE_VIDEO_POOL_QUERY_KEYS.getVideoPoolVideos, folderId]);
    }, [folderId, queryClient, searchQuery]);

    return useInfiniteQuery<MB_InfinitePaginationPage<PoolVideo>, ServerError>([IMAGE_VIDEO_POOL_QUERY_KEYS.getVideoPoolVideos, folderId, searchQuery],
        async ({ pageParam }: { pageParam?: string }) => videoPoolApiCalls.getVideoPoolVideos(totalItemsPerPage, pageParam, searchQuery, folderId), {
        refetchOnWindowFocus: false,
        getNextPageParam: lastPage => lastPage.nextOffset ?? undefined,
        ...queryOptions,
    });
};

export const useDeleteVideo = () => {
    const queryClient = useQueryClient();

    return useMutation<string, ServerError, string, unknown>(
        async (videoId) => videoPoolApiCalls.deleteVideo(videoId), {
        onSuccess: () => {
            queryClient.invalidateQueries([IMAGE_VIDEO_POOL_QUERY_KEYS.getVideoPoolVideos]);
        },
    });
};
