import { MB_Image } from '@mightybyte/rnw.components.image';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { default as React, memo, useCallback, useMemo, useState } from 'react';
import { LayoutChangeEvent, Platform, StyleProp, StyleSheet, Text, TouchableOpacity, View, ViewStyle } from 'react-native';
import { COLORS } from '../../../constants/colors';
import { textStyles } from '../../../constants/textStyles';
import { ANSWER_BUTTON_STATE } from '../../../typesAndInterfaces/typesAndInterfaces';
import { imageUtils } from '../../../utils/imageUtils';

export interface BaseCardProps {
    text: string;
    imageUrl: string | undefined;
    containerStyle?: StyleProp<ViewStyle>,
    disabled: boolean,
    isCorrect: boolean,
    cardWidth: number,
    buttonState: ANSWER_BUTTON_STATE,
    onPress: () => void,
    isPortrait?: boolean
}

const BaseCard = memo((props: BaseCardProps) => {
    const cardContainerStyle = useMemo(() => {
        const retStyle: ViewStyle = StyleSheet.flatten([
            styles.container,
            props.containerStyle,
            { width: props.cardWidth, paddingTop: props.isPortrait ? 6 : 3 },
            (props.buttonState === ANSWER_BUTTON_STATE.correct && styles.correctAnswer),
            (props.buttonState === ANSWER_BUTTON_STATE.wrong && styles.wrongAnswer),
            (props.buttonState === ANSWER_BUTTON_STATE.selected && styles.selectedAnswer),
        ]);

        return retStyle;
    }, [props.containerStyle, props.cardWidth, props.isPortrait, props.buttonState]);

    const [numberOflines, setNumberOfLines] = useState(1);

    const onTextLayout = useCallback((e: LayoutChangeEvent) => {
        setNumberOfLines(e.nativeEvent.layout.height / 16);
    }, []);

    const imageStyle = useMemo<StyleProp<ViewStyle>>(() => (props.isPortrait ?
        { alignSelf: 'stretch', marginHorizontal: numberOflines > 1.5 ? (16 / 2) : 0, paddingBottom: 8 }
        :
        { flex: 1 }),
        [numberOflines, props.isPortrait]
    );

    if (props.cardWidth === 0) {
        return null;
    }

    return (
        <TouchableOpacity
            activeOpacity={0.75}
            onPress={props.onPress}
            disabled={props.buttonState === ANSWER_BUTTON_STATE.wrong || props.disabled}
            style={cardContainerStyle}
        >
            <MB_Image
                style={[styles.image, imageStyle]}
                source={props.imageUrl ?? imageUtils.images.aslFlurryLogo}
                disableInteraction
            />
            <View onLayout={onTextLayout} style={Platform.OS === 'web' ? {width: '100%'} : undefined}>
                <Text numberOfLines={2} style={styles.text}>{props.text}</Text>
            </View>
        </TouchableOpacity>
    );
});

export { BaseCard };

const styles = StyleSheet.create({
    container: {
        borderColor: 'transparent',
        borderWidth: 1,
        borderRadius: 3,
        alignItems: 'center',
        paddingTop: 6,
        paddingHorizontal: 6,
        flex: 1,
        justifyContent: 'center',
    },
    image: {
        aspectRatio: 1,
        paddingBottom: 6,
    },
    imageLandscape: {
        flex: 1,
    },
    text: mbTextStyles([
        textStyles.smallText, {
            color: 'white',
            textAlign: 'center',
            fontWeight: '600',
            lineHeight: 16,
        },
    ]),
    wrongAnswer: {
        borderColor: COLORS.errorColor,
    },
    correctAnswer: {
        borderColor: COLORS.correctAnswerColor,
    },
    selectedAnswer: {
        borderColor: COLORS.cyan,
    },
});
