import * as React from 'react';
import Svg, {
  G,
  Path,
  Defs,
  LinearGradient,
  Stop,
  Ellipse,
  Circle,
} from 'react-native-svg';
import { BODY_PARTS, BodyPartsSvgProps } from '../../../components/screens/Game/bodyPartsControls/bodyPartsConstants';
import { BODY_PARTS_SVG_BUTTON_TYPES, BodyPartSvgButton } from '../../../components/helperComponents/BodyPartSvgButton';
/* SVGR has dropped some elements not supported by react-native-svg: filter */


function BodyTypesSVG_torso({ onPress, highlightedParts }: BodyPartsSvgProps) {
  const [_active, setActive] = React.useState<BODY_PARTS | undefined>();

  return (
    <Svg
      style={{ width: '100%', height: '100%' }}
      viewBox="0 0 220 220"
      fill="none"
    >
      <G id="Chest">
        <G id="Chest_2">
          <G id="Group 27721">
            <G id="Group 27617">
              <Path
                id="Vector"
                d="M148.233 147.875c-2.292 4.151-29.655 4.071-40.226 3.852-7.405-.083-15.04-1.773-17.159-2.732 0 0-.683-12.515-.767-14.626-.054-1.053-.301-5.451-.423-7.816 0 0-.023-3.625-.422-8.978-.4-5.352-1.19-30.856-2.291-42.902-2.012-19.9-5.863-16.355 17.185-20.26 18.18-4.525 50.613 4.034 50.613 4.034-.208 15.36-6.51 89.428-6.51 89.428z"
                fill="url(#paint0_linear_5385_8546)"
              />
              <Path
                id="Vector 14"
                d="M87.73 84.57c-.127-2.274-.084-3.79-.084-3.79.028 1.29.056 2.552.085 3.79.355 6.293 2.022 18.39 9.541 20.211 3.284.795-4.262 2.556-5.608 6.509-2.78 8.169 1.392 18.052.554 26.64-.517 5.301-1.554 11.884-1.554 11.884-1.493-24.55-2.333-39.624-2.933-65.244z"
                fill="url(#paint1_linear_5385_8546)"
              />
              <Path
                id="Vector 13"
                d="M118.805 96.131c-.677 4.88-2.68 7.44-7.65 9.019-.228.073-.173.458.067.452l16.038-.373c.239-.005.278-.395.048-.458-4.892-1.348-6.99-3.871-7.981-8.657-.049-.236-.489-.221-.522.017z"
                fill="#DD976A"
              />
              <Path
                id="Vector 15"
                d="M147.206 105.483c.478-4.316 3.581-10.527 3.581-10.527l.846 8.149-3.681 42.205 1.494-26.137s-2.837-8.306-2.24-13.69z"
                fill="#DD976A"
              />
              <Ellipse
                id="Ellipse 1"
                cx={100.873}
                cy={96.4423}
                rx={2.42566}
                ry={1.49792}
                fill="#D4885F"
              />
              <Ellipse
                id="Ellipse 3"
                cx={120.348}
                cy={143.625}
                rx={2.42566}
                ry={1.49792}
                fill="#915548"
              />
              <Ellipse
                id="Ellipse 2"
                cx={137.004}
                cy={97.4745}
                rx={2.42566}
                ry={1.49792}
                fill="#D4885F"
              />
              <G id="Group 27560">
                <Path
                  id="Vector_2"
                  d="M112.343 53.133c-34.785 1.462-27.493 6.365-27.177 21.722 1.193 13.182 1.469 19.157 1.99 27.692.102 7.876 2.572 38.613 2.552 47.327 6.485 3.39 59.847 1.73 60.807-.785 0 0 3.071-41.004 3.495-48.425.571-7.713 3.134-30.345 2.804-40.876-.027-.862-.633-1.58-1.467-1.796-3.356-.87-5.765-1.491-8.358-1.977-14.54-2.367-17.39-2.802-34.646-2.882z"
                  fill="#915548"
                />
                <Path
                  id="Vector_3"
                  d="M148.617 149.098c-2.292 4.151-29.655 4.071-40.225 3.852-7.405-.083-15.04-1.773-17.16-2.732 0 0-.683-12.515-.767-14.626-.053-1.053-.3-5.451-.422-7.816 0 0-.023-3.625-.423-8.978-.4-5.352-1.55-29.82-2.653-41.865-2.011-19.9-1.983-19.112 14.382-21.34 20.681-3.244 45.917 2.2 52.272 3.707a1.908 1.908 0 011.471 1.932c-.551 18.238-6.475 87.866-6.475 87.866z"
                  fill="url(#paint2_linear_5385_8546)"
                />
                <Path
                  id="Vector 14_2"
                  d="M88.116 85.793c-.129-2.274-.086-3.79-.086-3.79.028 1.29.057 2.552.086 3.79.355 6.293 2.021 18.39 9.54 20.211 3.285.795-4.261 2.555-5.607 6.509-2.78 8.169 1.391 18.052.554 26.64-.517 5.301-1.554 11.884-1.554 11.884-1.493-24.55-2.334-39.624-2.933-65.245z"
                  fill="url(#paint3_linear_5385_8546)"
                />
                <Path
                  id="Vector 13_2"
                  d="M119.19 97.354c-.677 4.88-2.681 7.44-7.65 9.019-.228.072-.173.458.066.452l16.039-.373c.239-.005.278-.395.047-.458-4.891-1.348-6.989-3.871-7.98-8.657-.049-.236-.489-.221-.522.017z"
                  fill="#DD976A"
                />
                <Ellipse
                  id="Ellipse 1_2"
                  cx={101.258}
                  cy={97.6652}
                  rx={2.42566}
                  ry={1.49792}
                  fill="#D4885F"
                />
                <Ellipse
                  id="Ellipse 3_2"
                  cx={120.732}
                  cy={144.848}
                  rx={2.42566}
                  ry={1.49792}
                  fill="#915548"
                />
                <Ellipse
                  id="Ellipse 2_2"
                  cx={137.389}
                  cy={98.6974}
                  rx={2.42566}
                  ry={1.49792}
                  fill="#D4885F"
                />
              </G>
              <G id="Neck">
                <Path
                  id="Vector_4"
                  d="M136.589 56.015c-.146-.278-.342-.997-.794-1.426l-14.809-.396-14.451-.102s-1.576 5.983-1.727 8.709c-.805 14.442 10.661 18.833 23.107 15.983 4.032-.97 8.209-3.644 9.692-7.822a14.53 14.53 0 001.564-7.614 14.528 14.528 0 00-2.582-7.332z"
                  fill="#915548"
                />
                <Path
                  id="Vector_5"
                  d="M137.01 72.811c-5.028 9.761-23.938 8.626-28.988 2.86-2.49-3.045-3.649-7.959-3.388-11.883.038-2.437-1.667-6.756.834-8.72l.691-1.622s10.095-.7 15.968-.422c5.933.28 10.261.844 10.261.844s.065 1.105.563 1.4c1.038.615 4.948 1.112 4.948 1.112 2.109 3.161 3.153 8.357-.889 16.431z"
                  fill="url(#paint4_linear_5385_8546)"
                />
                <Path
                  id="Vector_6"
                  style={{
                    mixBlendMode: 'darken',
                  }}
                  d="M135.056 54.904s-6.975-.676-10.263-.691c-2.472 0-7.066 1.415-7.299 5.408-.232 3.993 1.057 5.535-.232 4.71-1.289-.823-3.179-3.295-5.176-6.358-1.997-3.063-4.003-1.172-3.528 5.418l-3.984-.034c-.034-2.35-.782-4.97.157-7.044 1.38-2.5 1.411-2.875 1.411-2.875s8.862-.55 13.905-.53c5.913.025 15.009 1.996 15.009 1.996z"
                  fill="url(#paint5_linear_5385_8546)"
                />
                <Path
                  id="Vector_7"
                  d="M112.832 55.365l.872 1.716.853 1.715.795 1.736c.262.581.504 1.172.727 1.783a12.878 12.878 0 01-1.134-1.57 19.282 19.282 0 01-.911-1.706 23.649 23.649 0 01-.698-1.803c-.213-.61-.359-1.24-.504-1.87z"
                  fill="#915548"
                />
                <Path
                  id="Vector_8"
                  d="M102.984 63.497c1.247.456 2.602.723 3.987.785 1.379.078 2.764.078 4.143 0a19.19 19.19 0 014.26.184c1.361.33 2.593.907 3.584 1.677a15.21 15.21 0 00-3.779-1.192 24.97 24.97 0 00-4.091 0 27.257 27.257 0 01-4.26-.184c-1.397-.19-2.715-.626-3.844-1.27z"
                  fill="#915548"
                />
                <Path
                  id="Vector_9"
                  d="M123.746 66.145c.42-.534.96-.988 1.589-1.337a6.072 6.072 0 012.071-.688c.739-.136 1.49-.217 2.245-.243.729 0 1.459-.058 2.177-.077 1.448 0 2.897-.097 4.333-.184l2.167-.165c.73-.058 1.459-.097 2.178-.184-.696.184-1.392.378-2.111.513-.718.136-1.437.262-2.155.369a36.998 36.998 0 01-4.389.33h-2.245c-.713-.012-1.426.02-2.133.096-1.426.103-2.756.663-3.727 1.57z"
                  fill="#915548"
                />
              </G>
              <G id="pressable_chest">
                <Path
                  id="highlight_chest"
                  d="M87.484 112.36l-2.69-40.207 71.246 1.091-3.33 39.417-65.226-.301z"
                  fill="#A430FF"
                  fillOpacity={0.25}
                  stroke="#A430FF"
                  strokeWidth={2}
                  opacity={highlightedParts.includes(BODY_PARTS.chest) ? 1 : 0}
                />
              </G>
              <G id="pressable_belly">
                <Path
                  id="highlight_belly"
                  d="M147.197 136.493c0 3.691-2.737 7.249-7.642 9.943-4.868 2.673-11.661 4.358-19.22 4.358-7.559 0-14.352-1.685-19.22-4.358-4.905-2.694-7.642-6.252-7.642-9.943s2.737-7.249 7.642-9.942c4.868-2.674 11.661-4.358 19.22-4.358 7.559 0 14.352 1.684 19.22 4.358 4.905 2.693 7.642 6.251 7.642 9.942z"
                  fill="#A430FF"
                  fillOpacity={0.25}
                  stroke="#A430FF"
                  strokeWidth={2}
                  opacity={highlightedParts.includes(BODY_PARTS.belly) ? 1 : 0}
                />
              </G>
              <G
                id="pressable_bellyButton"
                fill="#A430FF"
                fillOpacity={0.25}
                stroke="#A430FF"
                strokeWidth={2}
                opacity={highlightedParts.includes(BODY_PARTS.bellyButton) ? 1 : 0}
              >
                <Circle
                  id="bellyButton"
                  cx={120.768}
                  cy={144.122}
                  r={4.76367}
                />
                <Circle
                  id="highlight_bellyButton"
                  cx={120.768}
                  cy={144.122}
                  r={4.76367}
                />
              </G>
            </G>
          </G>
          <G id="Group 27718">
            <G id="Group">
              <G
                id="Ellipse 83"
                transform="rotate(150 127.754 89.039)"
                fill="#fff"
              >
                <Circle cx={127.754} cy={89.0388} r={2} />
                <Circle cx={127.754} cy={89.0388} r={2} />
              </G>
              <Path
                id="Line 78"
                stroke="#fff"
                d="M173.871 42.6563L127.449 89.0788"
              />
            </G>
            <G id="pressableContainerSelected_chest">
              <G id="pressableButton_chest">
                <BodyPartSvgButton
                  type={BODY_PARTS_SVG_BUTTON_TYPES.activate}
                  bodyPart={BODY_PARTS.chest}
                  activateAsSelect
                  onPress={onPress}
                  onActivate={setActive}
                  width={61}
                  height={32}
                  x={146}
                  y={12}
                />
              </G>
            </G>
          </G>
          <G id="Group 27724">
            <G id="Group_2">
              <G
                id="Ellipse 83_2"
                transform="rotate(150 121.065 144.269)"
                fill="#fff"
              >
                <Circle cx={121.065} cy={144.269} r={2} />
                <Circle cx={121.065} cy={144.269} r={2} />
              </G>
              <Path
                id="Line 78_2"
                stroke="#fff"
                d="M120.906 183.535L120.906 143.955"
              />
            </G>
            <G id="pressableContainerSelected_bellyButton">
              <G id="pressableButton_bellyButton">
                <BodyPartSvgButton
                  type={BODY_PARTS_SVG_BUTTON_TYPES.activate}
                  bodyPart={BODY_PARTS.bellyButton}
                  activateAsSelect
                  onPress={onPress}
                  onActivate={setActive}
                  width={126}
                  height={32}
                  x={69.5}
                  y={174}
                />
              </G>
            </G>
          </G>
          <G id="Group 27723">
            <G id="Group_3">
              <G
                id="Ellipse 83_3"
                transform="rotate(-30 104.021 137.374)"
                fill="#fff"
              >
                <Circle cx={104.021} cy={137.374} r={2} />
                <Circle cx={104.021} cy={137.374} r={2} />
              </G>
              <Path
                id="Line 78_3"
                stroke="#fff"
                d="M40.0625 137.187L104.681 137.187"
              />
            </G>
            <G id="pressableContainerSelected_belly">
              <G id="pressableButton_belly">
                <BodyPartSvgButton
                  type={BODY_PARTS_SVG_BUTTON_TYPES.activate}
                  bodyPart={BODY_PARTS.belly}
                  activateAsSelect
                  onPress={onPress}
                  onActivate={setActive}
                  width={61}
                  height={32}
                  x={12.5}
                  y={120}
                />
              </G>
            </G>
          </G>
        </G>
      </G>
      <Defs>
        <LinearGradient
          id="paint0_linear_5385_8546"
          x1={117.643}
          y1={143.51}
          x2={108.057}
          y2={78.0494}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C48054" />
          <Stop offset={0.608984} stopColor="#E8A979" />
          <Stop offset={1} stopColor="#D18B63" />
        </LinearGradient>
        <LinearGradient
          id="paint1_linear_5385_8546"
          x1={92.8655}
          y1={80.7798}
          x2={92.8655}
          y2={151.034}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#DB9469" />
          <Stop offset={0.927083} stopColor="#DE986B" />
        </LinearGradient>
        <LinearGradient
          id="paint2_linear_5385_8546"
          x1={118.027}
          y1={144.733}
          x2={108.441}
          y2={79.2724}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C48054" />
          <Stop offset={0.608984} stopColor="#E8A979" />
          <Stop offset={1} stopColor="#D18B63" />
        </LinearGradient>
        <LinearGradient
          id="paint3_linear_5385_8546"
          x1={93.2502}
          y1={82.0027}
          x2={93.2502}
          y2={152.257}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#DB9469" />
          <Stop offset={0.927083} stopColor="#DE986B" />
        </LinearGradient>
        <LinearGradient
          id="paint4_linear_5385_8546"
          x1={118.261}
          y1={45.1701}
          x2={121.576}
          y2={60.6103}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#E3A475" />
          <Stop offset={1} stopColor="#D28C64" />
        </LinearGradient>
        <LinearGradient
          id="paint5_linear_5385_8546"
          x1={118.687}
          y1={34.8667}
          x2={121.275}
          y2={71.204}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C97551" />
          <Stop offset={1} stopColor="#C97551" stopOpacity={0} />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}

export { BodyTypesSVG_torso };
