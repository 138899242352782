import { mbGetSmallestImage } from '@mightybyte/rnw.components.image';
import { mbShowToast } from '@mightybyte/rnw.components.toast';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Dimensions, LayoutChangeEvent, StyleSheet, View } from 'react-native';
import { FlatList } from 'react-native';
import { COLORS } from '../../../constants/colors';
import { ANSWER_BUTTON_STATE, Answer, GAME_TYPE, Question } from '../../../typesAndInterfaces/typesAndInterfaces';
import { utils } from '../../../utils/utils';
import { BaseCard } from './BaseCard';
import isEqual from 'lodash/isEqual';
import { imageUtils } from '../../../utils/imageUtils';
import { envs } from '../../../../env';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';

interface IntroGameCardsProps {
    question: Question,
    disableAnswers: boolean,
    onAnswerPressed: (isCorrect: boolean) => void,
    isPortrait: boolean,
}

const NUMBER_OF_COLUMNS = 3;

const IntroGameCards = memo(({ question, disableAnswers, onAnswerPressed, isPortrait }: IntroGameCardsProps) => {

    if (question.gameType !== GAME_TYPE.IntroGame) {
        throw new Error('IntroGameCards only support IntroGame Question');
    }

    const [cardWidth, setCardWidth] = useState<number | undefined>();
    const [cardHeight, setCardHeight] = useState<number | undefined>();
    const [buttonStates, setButtonStates] = useState<{ answerName: string, state: ANSWER_BUTTON_STATE }[]>(question.answers.map(item => ({ answerName: item.name, state: ANSWER_BUTTON_STATE.unselected })));

    // Note: We have a hard to fix bug situation sometimes when we restart the game, old game is used for a split second.
    // This results in button states using the old card values for a moment and then cards cahnge.
    // This routine is run to set the button states according to new values.
    useEffect(() => {
        const buttonStateNames = buttonStates?.map(buttonState => buttonState.answerName);
        const questionAnswerNames = question.answers.map(answer => answer.name);

        if (!buttonStates || !isEqual(buttonStateNames, questionAnswerNames)) {
            setButtonStates(question.answers.map(answer => ({
                answerName: answer.name,
                state: ANSWER_BUTTON_STATE.unselected,
            })));
        }
    }, [buttonStates, question.answers]);

    const onLayoutChange = useCallback((e: LayoutChangeEvent) => {
        const width = e.nativeEvent.layout.width;
        const newCardWidth = ((width - 2 * 16) / NUMBER_OF_COLUMNS) - (isPortrait ? 0 : 8);

        setCardHeight(e.nativeEvent.layout.height / 3);

        if (Math.abs(newCardWidth - (cardWidth ?? 0)) >= 1) {
            setCardWidth(newCardWidth);
        }
    }, [cardWidth, isPortrait]);

    const cardHeightStyle = useMemo(() => ({ height: (cardHeight ?? 0) - (isPortrait ? 0 : 8) }), [cardHeight, isPortrait]);

    const GameCardMemoized = useCallback(({ item, index }: { item: Answer, index: number }) => {
        const buttonState = buttonStates?.find((state) => state.answerName === item.name)?.state ?? ANSWER_BUTTON_STATE.unselected;

        if (!cardWidth) {
            return null;
        }

        const changeAnswerState = () => {
            let correctAnswerNameDebug: string | undefined; // TODO: DeleteME;
            setButtonStates(old => old?.map(state => {
                if (state.answerName !== item.name) {
                    return state;
                }

                correctAnswerNameDebug = `e:${item.name} r${state.answerName}`;
                return { answerName: state.answerName, state: item.isCorrect ? ANSWER_BUTTON_STATE.correct : ANSWER_BUTTON_STATE.wrong };
            }));
            mbShowToast({
                ...utils.gameFeedbackToastParams(item.isCorrect),
                text2: envs.FLAVOR === 'prod' ? undefined : correctAnswerNameDebug,
            });
            onAnswerPressed(item.isCorrect);
        };

        return (
            <View style={[styles.cardSpacing, !isPortrait && cardHeightStyle, [1, 4, 7].includes(index) && styles.cardItemSeparator]}>
                <BaseCard
                    text={item.name}
                    imageUrl={mbGetSmallestImage(item.image) ?? imageUtils.images.brokenImage}
                    disabled={disableAnswers}
                    isCorrect={item.isCorrect}
                    cardWidth={cardWidth}
                    onPress={changeAnswerState}
                    buttonState={buttonState}
                    isPortrait={isPortrait}
                />
            </View>
        );
    }, [buttonStates, cardHeightStyle, cardWidth, disableAnswers, isPortrait, onAnswerPressed]);

    const ItemSeparatorComponent = useCallback(() => <View style={styles.itemSeparator} />, []);

    // NOTE: Due to the way the video and game controls are implemented, on less tall devices
    // such as iPad with it`s 4:3 resolution, the game cards end up occupying almost the entire height of the screen
    // because cards span to occupy the entire width of the screen by default. As a result of that, video player ends up being
    // very small. We add a padding to cards to limit their width and consequently height and make the video appear bigger.
    const horizontalPadding = useMemo(() => {
        const aspectRatio = Dimensions.get('window').height / Dimensions.get('window').width;
        return (isMobileApp && isPortrait && aspectRatio < 1.5) ? { paddingHorizontal: '10%' } : undefined;
    }, [isPortrait]);

    return (
        <View style={horizontalPadding}>
            <FlatList
                style={[styles.flatListStyle, isPortrait && styles.flatListStylePortrait]}
                data={question.answers}
                scrollEnabled={false}
                numColumns={NUMBER_OF_COLUMNS}
                renderItem={GameCardMemoized}
                columnWrapperStyle={styles.columnWrapperStyle}
                onLayout={onLayoutChange}
                contentContainerStyle={!isPortrait && styles.flatListHorizontalContainerStyle}
                ItemSeparatorComponent={ItemSeparatorComponent}
            />
        </View>
    );
});

export { IntroGameCards };

const styles = StyleSheet.create({
    flatListStyle: {
        //flexGrow: 1,
    },
    flatListStylePortrait: {
        marginVertical: 7,
        marginHorizontal: 9,
    },
    flatListHorizontalContainerStyle: {
        height: '100%',
        justifyContent: 'center',
        paddingVertical: 8,
        aspectRatio: 1.2,
        backgroundColor: COLORS.backgroundDarkPurple,
        paddingHorizontal: 16,
    },
    cardSpacing: {
        padding: 4,
    },
    columnWrapperStyle: {
        justifyContent: 'center',
        marginHorizontal: 4,
    },
    cardItemSeparator: {
        borderLeftColor: COLORS.white,
        borderLeftWidth: 1,
        borderRightColor: COLORS.white,
        borderRightWidth: 1,
    },
    itemSeparator: {
        height: 1,
        backgroundColor: COLORS.white,
    },
});
