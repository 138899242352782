/* Note: During development to enable typescript for this file, uncomment this block and comment out the const window
///<reference lib="dom"/>

declare global {
    interface Window {
        ReactNativeWebView: any;
        svg: SVGSVGElement;
        origViewBox: { x: number, y: number, width: number, height: number };
        viewBox: { x: number, y: number, width: number, height: number };
        isMapMove: boolean;
        keepMapMovingTimer?: number;
        isMouseDown: boolean;
        keepZooming: boolean;
        keepZoomingTimer?: number;
        activeAreas: string[]
    }
}
*/

const window = (global as any).window;



function send(type: 'click' | 'ready', payload?: any) {
    const data = JSON.stringify(window.Object.assign({ name: 'SVGCOMM', type: type }, payload));
    window.ReactNativeWebView ? window.ReactNativeWebView.postMessage(data) : window.parent.postMessage(data);
}

function initWindow() {
    window.isMouseDown = false;
    window.keepZooming = false;
    window.isMapMove = false;
    const svg = window.document.querySelector('svg');
    if (svg) { window.svg = svg; }
}

function initZoom() {
    const viewBoxAttribute = window.svg.getAttribute('viewBox');
    if (viewBoxAttribute) {
        const coord = viewBoxAttribute.trim().split(' ').map((e: string) => parseInt(e, 10));
        const x = coord[0];
        const y = coord[1];
        const width = coord[2];
        const height = coord[3];
        window.origViewBox = { x, y, width, height };
        window.viewBox = { x, y, width, height };
        window.svg.oncontextmenu = () => false;
    }
}

function resizeMap(orientation?: 'LANDSCAPE' | 'PORTRAIT') {
    // Set size of map to as small as it can be while still covering viewport

    const viewBoxAttribute = window.svg.getAttribute('viewBox');

    if (!viewBoxAttribute) { return; }

    const coord = viewBoxAttribute.trim().split(' ').map((e: string) => parseInt(e, 10));
    const origHeight = coord[3];
    const origWidth = coord[2];

    const origAspect = origWidth / origHeight;

    // Fun with webview differences: Our measured viewport size should care about portratit vs. landscape, but not care about page scaling.
    // On iOS, $(window).width() and $(window).height() does this.
    // On android, the only scalingindependent measure I could find was screen.width / screen.height, but that is only partially orientationindependent.
    // at start, they are correct, but when we tilt, they dont change, so we have to calculate viewport w/h from screen w/h and orientation.

    let wpHeight = window.innerHeight;
    let wpWidth = window.innerWidth;
    if ((window.navigator.userAgent || window.navigator.vendor).match(/Android/i)) {
        switch (orientation) {
            case 'LANDSCAPE':
                wpHeight = Math.min(window.screen.height, window.screen.width);
                wpWidth = Math.max(window.screen.height, window.screen.width);
                break;
            case 'PORTRAIT':
                wpHeight = Math.max(window.screen.height, window.screen.width);
                wpWidth = Math.min(window.screen.height, window.screen.width);
                break;
            default:
                // initial resize
                wpHeight = window.screen.height;
                wpWidth = window.screen.width;
                break;
        }
    }

    let newHeight = wpHeight;
    let newWidth = newHeight * origAspect;
    if (newWidth < wpWidth) {
        newWidth = wpWidth;
        newHeight = newWidth / origAspect;
    }

    if ((window.navigator.userAgent || window.navigator.vendor).match(/Android|Ios/i)) {
        window.svg.setAttribute('height', newHeight + 'px');
        window.svg.setAttribute('width', newWidth + 'px');

        const xOff = Math.max(0, (newWidth - wpWidth) / 2);
        const yOff = Math.max(0, (newHeight - wpHeight) / 2);

        window.scrollTo(xOff, yOff);
    } else {
        window.svg.setAttribute('height', wpHeight + 'px');
        window.svg.setAttribute('width', wpWidth + 'px');
    }
}

function setViewboxAttribute(viewBox: { x: number, y: number, width: number, height: number }) {
    const minMax = (a: number, b: number, c: number) => Math.min(Math.max(a, b), c);
    const n = minMax(viewBox.width, 0.1 * window.origViewBox.width, window.origViewBox.width);
    const i = minMax(viewBox.height, 0.1 * window.origViewBox.height, window.origViewBox.height);
    const o = viewBox.width !== window.viewBox.width && viewBox.width < 0.1 * window.origViewBox.width;
    if (o) { return; }
    const newViewBox = {
        x: minMax(viewBox.x, window.origViewBox.x, window.origViewBox.x + window.origViewBox.width - n),
        y: minMax(viewBox.y, window.origViewBox.y, window.origViewBox.y + window.origViewBox.height - i),
        width: n,
        height: i,
    };
    window.viewBox = newViewBox;
    window.svg.setAttribute('viewBox', `${newViewBox.x} ${newViewBox.y} ${newViewBox.width} ${newViewBox.height}`);
}

function selectArea(area: string) {
    window.svg.querySelectorAll('.selected').forEach((el: any) => el.classList.remove('selected'));
    const areaEl = window.svg.getElementById(area);
    if (areaEl) {
        areaEl.querySelectorAll('*').forEach((el: any) => el.classList.add('selected'));
    }
}

function onKeyDown(e: any) {
    if (e.code === 'ControlLeft' || e.code === 'AltLeft' || e.code === 'MetaLeft') {
        window.svg.style.cursor = 'zoom-in';
        window.svg.querySelectorAll('g').forEach((el: any) => (el.style.cursor = 'zoom-in'));
    }
}

function onKeyUp(e: any) {
    if (e.code === 'ControlLeft' || e.code === 'AltLeft' || e.code === 'MetaLeft') {
        window.svg.style.cursor = 'initial';
        window.svg.querySelectorAll('g').forEach((el: any) => (el.style.cursor = 'pointer'));
    }
}

function onMouseDown() {
    window.isMouseDown = true;
}

function onMouseUp() {
    window.isMouseDown = false;
    window.svg.style.cursor = 'initial';
}

function onMouseMove(e: any) {
    if (!e.buttons || !window.isMouseDown) {
        return;
    }
    if (window.viewBox.width === window.origViewBox.width) {
        return;
    }
    if (window.keepMapMovingTimer) {
        clearTimeout(window.keepMapMovingTimer);
    }
    window.isMapMove = true;
    window.keepMapMovingTimer = window.setTimeout(() => (window.isMapMove = false), 100);
    window.svg.style.cursor = 'grab';
    const i = window.origViewBox.width / window.viewBox.width;
    const a = e.movementX / i;
    const o = e.movementY / i;
    setViewboxAttribute({
        width: window.viewBox.width,
        height: window.viewBox.height,
        x: window.viewBox.x - a,
        y: window.viewBox.y - o,
    });
}

function onWheel(e: any) {
    if (!window.keepZooming && !(e.ctrlKey || e.altKey || e.metaKey)) {
        return;
    }
    if (window.keepZoomingTimer !== undefined) {
        clearTimeout(window.keepZoomingTimer);
    }
    window.keepZooming = true;
    window.keepZoomingTimer = window.setTimeout(() => (window.keepZooming = false), 50);
    e.preventDefault();
    const { width: t, height: r, x: n, y: i } = window.svg.getBoundingClientRect();
    const a = -0.0009 * e.deltaY;
    const o = e.clientX - n;
    const s = e.clientY - i;
    const c = window.viewBox.width * a;
    const u = window.viewBox.height * a;
    setViewboxAttribute({
        x: window.viewBox.x + c * o / t,
        y: window.viewBox.y + u * s / r,
        width: window.viewBox.width - c,
        height: window.viewBox.height - u,
    });
}

function addAreaClickListeners() {
    window.svg.querySelectorAll('g').forEach((el: any) => {
        const area = el.getAttribute('id');
        const onClick = () => {
            if (area && !window.isMapMove) {
                selectArea(area);
                send('click', { area });
            }
        };
        if (area && window.activeAreas.includes(area)) {
            el.addEventListener('click', onClick);
            if (el.nextElementSibling?.nodeName === 'g') {
                el.nextElementSibling.addEventListener('click', onClick);
            }
        }
    });
}

function addListeners() {
    window.svg.addEventListener('wheel', onWheel);
    window.document.addEventListener('mousedown', onMouseDown);
    window.document.addEventListener('mouseup', onMouseUp);
    window.document.addEventListener('mousemove', onMouseMove);
    window.document.addEventListener('keydown', onKeyDown);
    window.document.addEventListener('keyup', onKeyUp);
    addAreaClickListeners();
}

function init() {
    initWindow();
    initZoom();
    resizeMap();
    addListeners();
    send('ready');
}

function onMessage(event: { data: string | any }) {
    const data = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;
    if (data.name === 'SVGCOMM') {
        switch (data.type) {
            case 'select':
                selectArea(data.area);
                break;
        }
    }
}

function nameOf(f: any) {
  return f.toString().replace(/\s|\(|\)|=>|function|return|{|}|;/g,'');
}

const svg_loader_utils = {
    send,
    initWindow,
    initZoom,
    resizeMap,
    setViewboxAttribute,
    selectArea,
    onKeyDown,
    onKeyUp,
    onMouseDown,
    onMouseUp,
    onMouseMove,
    onWheel,
    addAreaClickListeners,
    addListeners,
    init,
    onMessage,
    nameOf,
};

const getScriptTag = ({ activeAreas = [] }: { activeAreas?: string[] }) => {
    return (`
        <script>
            window.${nameOf(() => window)} = window;
            window.activeAreas = [${activeAreas.map(activeArea => `'${activeArea}'`)}];
            ${Object.values(svg_loader_utils).map(func => func.toString()).join('\n')}
            window.addEventListener('load', ${init.name});
            window.addEventListener('message', ${onMessage.name});
        </script>`
    );
};

export { getScriptTag };
