import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

function LockIcon(props: { size?: number, color?: string, containerStyle?: StyleProp<ViewStyle> }) {
    return (
        <View style={props.containerStyle}>
            <Svg
                width={props.size ?? 20}
                height={props.size ? (props.size * (28 / 20)) : 28}
                viewBox="0 0 20 28"
                fill="none"
            >
                <Path
                    d="M10 28c5.38 0 9.758-4.377 9.758-9.758a9.72 9.72 0 00-2.546-6.565V7.212C17.212 3.235 13.977 0 10 0S2.788 3.235 2.788 7.212v4.465a9.72 9.72 0 00-2.546 6.565C.242 23.622 4.62 28 10 28zm1.273-9.334v1.698a1.273 1.273 0 11-2.546 0v-1.698A2.12 2.12 0 0110 14.848c1.17 0 2.121.952 2.121 2.122a2.12 2.12 0 01-.848 1.696zM5.333 7.212A4.672 4.672 0 0110 2.545a4.672 4.672 0 014.667 4.667v2.463A9.697 9.697 0 0010 8.485c-1.69 0-3.28.432-4.667 1.19V7.212z"
                    fill="#fff"
                />
            </Svg>
        </View>
    );
}

export default LockIcon;
