import React, { ReactNode } from 'react';
import { utils } from '../utils/utils';
import { MB_PortalProvider } from './MB_Portal';
import { PaymentContextProvider } from './PaymentContextProvider';
import { QueryContextProvider } from './QueryContextProvider';
import { SignedInStatusContextProvider } from './SignedInContext';
import { UtilContextProvider } from './UtilContextProvider';
import { MB_CodePushContextProvider } from './MB_CodePushContextProvider';
import { ToolTipAutoShowContextProvider } from '@mightybyte/rnw.utils.tooltip-auto-show';
import { TOOL_TIP_AUTO_SHOW_COLLECTION } from '../typesAndInterfaces/typesAndInterfaces';

const ToolTipAutoShowContextProviderWrapper = ({ children }: { children?: ReactNode | ReactNode[]; }) => {
    return (
        <ToolTipAutoShowContextProvider
            children={children}
            tooltipCollection={Object.keys(TOOL_TIP_AUTO_SHOW_COLLECTION)}
        />
    );
};

const providers: React.FC[] = [
    QueryContextProvider,
    MB_CodePushContextProvider,
    MB_PortalProvider,
    ToolTipAutoShowContextProviderWrapper,
    SignedInStatusContextProvider,
    UtilContextProvider,
    PaymentContextProvider,
];

const AppContextProvider = utils.combineComponents(providers);

export { AppContextProvider };
