import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ReactNode } from 'react';
import { MB_EventEmmiter, MB_EventObject, MB_EVENT_EMMITER_EVENT_TYPE } from '@mightybyte/rnw.utils.event-emmiter';
import { envs } from '../../env';
import MB_MobilePurchase, { MB_MOBILE_PURCHASE_EVENT_TYPE } from '../mightyByteLibraries/MB_MobilePurchase';
import { useCheckPaymentStatus } from '../hooks/paymentAndOrdersHooks';
import { useSignedInContext } from './SignedInContext';
import { PAYMENT_STATUS, UserRole } from '../typesAndInterfaces/typesAndInterfaces';
import { TMB_EventSubscription } from '@mightybyte/rnw.utils.event-emmiter/dist/MB_EventEmmiter';


export interface PaymentContext {
    paymentStatus: PAYMENT_STATUS | undefined,
}

const PaymentContext = React.createContext<PaymentContext | undefined>(undefined);

const PaymentContextProvider = ({ children }: { children?: ReactNode }) => {
    const [paymentStatus, setPaymentStatus] = useState<PAYMENT_STATUS>();

    const { mutate: checkPaymentStatus } = useCheckPaymentStatus();
    const { currentUserData } = useSignedInContext();

    const updatePaymentStatus = useCallback(async () => { // TODO: PAYMENT: Where else we might need to call this externally? Possibl in acknowledge and check status hooks
        if (currentUserData?.userRole === UserRole.user) {
            try {
                checkPaymentStatus(undefined, {
                    onSuccess: (newPaymentStatus) => {
                        setPaymentStatus(newPaymentStatus);
                    },
                    onError: () => {
                        // TODO: Do we need to do anything here? Maybe start a countdown and retry?
                    },
                });
            } catch (error) {
                console.error('Error when updating payment status: ', error);
            }
        }
    }, [checkPaymentStatus, currentUserData?.userRole]);


    const purchaseEventListener = useCallback((event: MB_EventObject) => {
        const eventType = event?.data?.type;
        if (event?.origin !== envs.WEBSITE_BASE_URL || !MB_MobilePurchase.eventIsFromMobilePurchase(eventType)) {
            // The incoming event does not belong to this checkout page so we just ignore the event
            return;
        }

        if (event.data.type === MB_MOBILE_PURCHASE_EVENT_TYPE.MOBILE_PURCHASE_UPDATE_SUCCESS || event.data.type === MB_MOBILE_PURCHASE_EVENT_TYPE.MOBILE_PURCHASE_NEEDS_UPDATE || event.data.type === MB_MOBILE_PURCHASE_EVENT_TYPE.MOBILE_PURCHASE_UPDATE_PENDING) {
            updatePaymentStatus();
        }
    }, [updatePaymentStatus]);

    useEffect(() => {
        let eventSub: TMB_EventSubscription | undefined;
        if (currentUserData?.userRole === UserRole.user) {
            updatePaymentStatus();
            eventSub = MB_EventEmmiter.addListener(MB_EVENT_EMMITER_EVENT_TYPE.message, purchaseEventListener);
        }

        return () => {
            eventSub?.remove();
        };
    }, [currentUserData?.userRole, purchaseEventListener, updatePaymentStatus]);

    const value = {
        paymentStatus,
    };

    return (
        <PaymentContext.Provider value={value}>
            {children}
        </PaymentContext.Provider>
    );
};

function usePaymentContext() {
    const context = useContext(PaymentContext);
    if (context === undefined) {
        throw new Error('useSignedInContext must be used within a SignedInStatusContextProvider');
    }

    return context;
}

export { PaymentContextProvider, usePaymentContext };
