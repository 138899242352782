import React from 'react';
import { View, StyleSheet, StyleProp, ViewStyle, TouchableOpacity } from 'react-native';
import { COLORS } from '../constants/colors';

// TODO: Anas: Move to reusables

interface IToggleSwitch {
    style?: StyleProp<ViewStyle>
    trackbarStyleOn?: StyleProp<ViewStyle>
    trackbarStyleOff?: StyleProp<ViewStyle>
    trackbarLeftComponet?: () => JSX.Element
    trackbarRightComponet?: () => JSX.Element
    isToggled: boolean,
    onToggle: (newValue: boolean) => void,
    isDisabled?: boolean,
    thumbButton?: StyleProp<ViewStyle>
}

const MB_ToggleSwitch = ({
    style,
    trackbarStyleOn,
    trackbarStyleOff,
    onToggle,
    isToggled,
    trackbarLeftComponet,
    trackbarRightComponet,
    isDisabled,
    thumbButton,
}: IToggleSwitch) => {
    return (
        <View style={[style, { opacity: isDisabled ? 0.4 : 1 }]}>
            <TouchableOpacity
                activeOpacity={1}
                disabled={isDisabled}
                onPress={() => onToggle(!isToggled)}
                style={[
                    styles.trackbar,
                    { backgroundColor: isToggled ? COLORS.buttonPurple : COLORS.white },
                    isToggled ? trackbarStyleOn ?? trackbarStyleOff : trackbarStyleOff ?? trackbarStyleOn,
                ]}>
                {!isToggled && (trackbarLeftComponet?.() ?? <View style={[styles.thumbButton, styles.thumbLeft, thumbButton]} />)}
                {isToggled && (trackbarRightComponet?.() ?? <View style={[styles.thumbButton, styles.thumbRight, thumbButton]} />)}
            </TouchableOpacity>
        </View>
    );
};

export { MB_ToggleSwitch };

const styles = StyleSheet.create({
    trackbar: {
        width: 35,
        height: 23,
        borderRadius: 13,
        alignItems: 'stretch',
        justifyContent: 'center',
        paddingHorizontal: 3,
    },
    thumbButton: {
        borderRadius: 360,
        aspectRatio: 1,
        height: '80%',
    },
    thumbLeft: {
        marginEnd: 'auto',
        backgroundColor: COLORS.buttonPurple,
    },
    thumbRight: {
        marginStart: 'auto',
        backgroundColor: COLORS.white,
    },
});
