import React, { useCallback } from 'react';
import { mbPlatformStyle, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { StyleSheet, Text, View, ViewStyle, useWindowDimensions } from 'react-native';
import { usePaymentContext } from '../../../context/PaymentContextProvider';
import { CategoryForUser, GAME_TYPE, PAYMENT_STATUS } from '../../../typesAndInterfaces/typesAndInterfaces';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import { CategoryCard } from '../../helperComponents/CategoryCard';
import { useSignedInContext } from '../../../context/SignedInContext';
import { CategoriesView } from '../../helperComponents/CategoriesView';
import { utilHooks } from '../../../hooks/utilHooks';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { textStyles } from '../../../constants/textStyles';
import { ExtraNavigatorScreenProps, UniversalScreenNavigationProp } from '../../../typesAndInterfaces/componentProps';
import { useNavigation } from '@react-navigation/core';
import { GameModeSwitch } from '../../helperComponents/GameModeSwitch';
import { mbGetMediumImage } from '@mightybyte/rnw.components.image';

const ExtrasHome = ({ navigation }: ExtraNavigatorScreenProps<'ExtrasHome'>) => {
    const { currentUserData } = useSignedInContext();
    const rootNavigation = useNavigation<UniversalScreenNavigationProp>();

    const { width } = useWindowDimensions();

    const { paymentStatus } = usePaymentContext();
    const { canAccessGames } = utilHooks.useGetAccess(currentUserData);

    const RenderCategoryComponent = useCallback((item: CategoryForUser | string, index: number, isLoading: boolean) => {
        const title = typeof item === 'string' ? '' : item.name;
        const categoryId = typeof item === 'string' ? '' : item._id;
        const isDisabled = typeof item === 'string' ? false : (!item.isFree && paymentStatus !== PAYMENT_STATUS.paid && !canAccessGames);
        const image = typeof item !== 'string' && typeof item.image !== 'string' ? mbGetMediumImage(item.image) : undefined;
        return (
            <CategoryCard
                index={index}
                title={title}
                totalItems={typeof item === 'string' ? 0 : item.numberOfQuestions}
                showStartHere={false}
                isLoading={isLoading}
                isDisabled={isDisabled}
                isProgressbarDisabled
                itemName="Video"
                image={image}
                onPress={() => isDisabled ? rootNavigation.navigate('PaymentPage') : navigation.navigate('ExtrasVideos', { title, categoryId })}
            />
        );
    }, [canAccessGames, navigation, paymentStatus, rootNavigation]);

    const cardWidth = 327 + 24;
    const numberOfColumns = Math.max(Math.floor((width - 300) / cardWidth), 1);

    return (
        <ComponentWrapper
            containerStyle={styles.container}
            innerContainerStyle={styles.innerContainer}
            wrapInScrollView={false}
            headerChildren={<GameModeSwitch />}
        >
            <CategoriesView
                HeaderComponent={
                    <View style={[isMobileApp && { paddingBottom: 50 }]}>
                        <Text style={styles.title}>Explore</Text>
                        <Text style={styles.body}>Dive into a collection of videos and resources to enhance your learning journey.</Text>
                    </View>
                }
                gameType={GAME_TYPE.Extras}
                renderItem={RenderCategoryComponent}
                style={[styles.categoriesView, !isMobileApp && { width: numberOfColumns * cardWidth }]}
                contentContainer={styles.categoriesViewContent}
                numColumns={!isMobileApp ? numberOfColumns : undefined}
                key={!isMobileApp ? numberOfColumns : undefined}
                emptyTitle="No extras available."
                emptyMessage="Currently there are no extras available"
                refetchOnScreenFocus={isMobileApp}
            />
        </ComponentWrapper >
    );
};

export { ExtrasHome };

const styles = StyleSheet.create({
    container: {
        ...mbPlatformStyle({
            web: {
                minWidth: 1000,
                flex: 1,
            },
        }),
    },
    innerContainer: {
        ...mbPlatformStyle({
            web: {
                flex: 1,
                maxWidth: 'auto',
                overflowY: 'scroll',
            } as ViewStyle,
        }),
    },
    categoryCardImage: {
        width: 140,
        height: 100,
        position: 'absolute',
        top: 12,
        right: 16,
    },
    categoriesView: {
        ...mbPlatformStyle({
            web: {
                alignSelf: 'center',
            },
            mobile: {
                flex: 1,
            },
        }),
    },
    categoriesViewContent: {
        paddingBottom: 80,
    },
    title: mbTextStyles([
        textStyles.largeText, {
            fontWeight: 'bold',
            marginBottom: 12,
            marginTop: 24,
        },
    ]),
    body: mbTextStyles([
        textStyles.smallText, {
            marginBottom: 14,
        },
    ]),
});
