import { MB_Image } from '@mightybyte/rnw.components.image';
import { mbPlatformStyle, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import React, { memo } from 'react';
import { StyleProp, StyleSheet, Text, TouchableOpacity, View, ViewStyle } from 'react-native';
import { COLORS } from '../../constants/colors';
import { textStyles } from '../../constants/textStyles';
import { GAME_TYPE } from '../../typesAndInterfaces/typesAndInterfaces';
import { utils } from '../../utils/utils';
import { imageUtils } from '../../utils/imageUtils';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';


export function gameTypeToImage(gameType: GAME_TYPE): any {
    switch (gameType) {
        case GAME_TYPE.IntroGame:
            return imageUtils.images.introToASLGameCard;
        case GAME_TYPE.MatchingItemsGame:
            return imageUtils.images.matchItemsGameCard;
        case GAME_TYPE.BodyParts:
            return imageUtils.images.bodypartsGameCard;
        case GAME_TYPE.Geography:
            return imageUtils.images.geographyGameCard;
    }
}

export interface GameCardProps {
    gameType: GAME_TYPE;
    onGameSelected: (gameType: GAME_TYPE) => void,
    style?: StyleProp<ViewStyle>,
    renderEmpty?: boolean
}

const GameCard = memo((({ gameType, onGameSelected, style, renderEmpty }: GameCardProps) => {

    return (
        <TouchableOpacity
            style={[styles.categoryCardContainer, style, renderEmpty && { backgroundColor: 'transparent' }]}
            onPress={() => onGameSelected(gameType)}
            disabled={renderEmpty}
        >
            {renderEmpty !== true &&
                <View style={{}}>
                    <MB_Image
                        source={gameTypeToImage(gameType)}
                        style={styles.image}
                        resizeMode="cover"
                        disableInteraction
                    />
                    <Text style={styles.titleText}>{utils.gameTypeToString(gameType)}</Text>
                </View>
            }

        </TouchableOpacity>
    );
}));

export { GameCard };

const styles = StyleSheet.create({
    categoryCardContainer: {
        backgroundColor: COLORS.gameTypeCardBackground,
        borderRadius: 7,
        aspectRatio: isMobileApp ? 142 / 170 : undefined,
        ...mbPlatformStyle({
            web: {
                width: 193,
                paddingVertical: 17,
                paddingHorizontal: 16,
                margin: 10,
            },
            mobile: {
                flex: 1,
                paddingTop: 12,
                paddingHorizontal: 12,
                paddingBottom: 16,
                alignItems: 'center',
                justifyContent: 'center',
                margin: 5,
            },
        }),
    },
    titleText: mbTextStyles([
        textStyles.normalText, {
            fontSize: 12,
            marginTop: 15,
        },
    ]),
    image: {
        width: '100%',
        aspectRatio: 117 / 113,
        borderRadius: 8,
    },
});
