import { MB_Button } from '@mightybyte/rnw.components.button';
import { MB_Image, mbGetMediumImage } from '@mightybyte/rnw.components.image';
import { MB_RESOURCE_PICKER_TYPE } from '@mightybyte/rnw.components.resource-uploader';
import { MB_ThumbnailVideoViewerModal } from '@mightybyte/rnw.components.video-player';
import { mbApplyTransparency } from '@mightybyte/rnw.utils.style-utils';
import { MB_utilHooks } from '@mightybyte/rnw.utils.util-hooks';
import React, { useCallback, useMemo } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { COLORS } from '../../../../../constants/colors';
import { textStyles } from '../../../../../constants/textStyles';
import { RESOURCE_POOL_TYPE, PoolImage, PoolVideo, Image, Video } from '../../../../../typesAndInterfaces/typesAndInterfaces';
import { ImageVideoSelectorModal } from '../../../../helperComponents/ImageVideoSelectorModal';

type MediaTypeVideo = 'video';
type MediaTypeImage = 'image';
type MediaType = MediaTypeImage | MediaTypeVideo;

interface MediaChangerProps<T extends MediaType> {
    type: T,
    source?: T extends MediaTypeImage ? Image : Video,
    onMediaChange?: T extends MediaTypeImage ? (image: PoolImage) => void : (video: PoolVideo) => void,
    disabled?: boolean,
    hideChangeButton?: boolean,
}

const NoMediaView = ({ type }: { type: MediaType }) => {
    return (
        <View style={{ alignItems: 'center', justifyContent: 'center' }}>
            <View style={styles.addImageBackground}>
                <Feather name={type} size={40} color={COLORS.lighterGray} />
            </View>
            <Text style={[textStyles.smallText, { marginTop: 12 }]}>{type === 'image' ? 'Pick Image' : 'Pick Video'}</Text>
        </View>
    );
};

const MediaChanger = <T extends MediaType>({ type, source, onMediaChange, disabled, hideChangeButton }: MediaChangerProps<T>) => {
    const [isResourcePickerVisible, showResourcePicker, hideResourcePicker] = MB_utilHooks.useBool();
    // const [isVideoSelectorVisible, showVideoSelector, hideVideoSelector] = MB_utilHooks.useBool();


    // const mediaUploader = (_uploadProgressListener: (percentCompleted: number) => void) => async ({ formData }: { formData: any }): Promise<void> => {
    //     const file = formData.get(type);
    //     onMediaChange?.(file);
    // };

    const isImage = useCallback((image?: Image | Video): image is Image | undefined => type === 'image', [type]);
    const isVideo = useCallback((video?: Image | Video): video is Video | undefined => type === 'video', [type]);

    const content = useMemo(() => {
        const image = isImage(source) ? mbGetMediumImage(source) : undefined;
        if (image) {
            return (
                <MB_Image style={styles.image} source={image} disableInteraction />
            );
        } else if (isVideo(source) && source) {
            return (
                <MB_ThumbnailVideoViewerModal
                    video={source}
                    thumbnailContainerStyle={styles.thumbnailContainer}
                    isFocused={true}
                    customVideoProcessingView={
                        <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                            <View style={styles.addImageBackground}>
                                <FontAwesome name="play" size={34} color={COLORS.lighterGray} />
                            </View>
                            <Text style={[textStyles.smallText, { marginTop: 12 }]}>We're processing this video.{'\n'}Please check back later.</Text>
                        </View>
                    }
                    customNoThumbnailView={source.thumbnail ? undefined : (
                        <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                            <View style={styles.addImageBackground}>
                                <FontAwesome name="play" size={34} color={COLORS.lighterGray} />
                            </View>
                            <Text style={[textStyles.smallText, { marginTop: 12 }]}>Click to play</Text>
                        </View>
                    )}
                    hidePlayIcon={!source.thumbnail}
                />
            );
        } else {
            return (
                <NoMediaView type={type} />
            );
        }
    }, [isImage, source, isVideo, type]);


    const onDismiss = (item?: PoolImage | PoolVideo) => {
        if (item) {
            onMediaChange?.(item as any);
        }
        hideResourcePicker();
    };

    return (
        <React.Fragment>
            <ImageVideoSelectorModal
                isVisible={isResourcePickerVisible}
                resourcePoolType={type === 'image' ? RESOURCE_POOL_TYPE.Image : RESOURCE_POOL_TYPE.Video}
                onDismiss={onDismiss}
            />
            <TouchableOpacity style={styles.addImageContainer} onPress={showResourcePicker} >
                {content}
            </TouchableOpacity>
            {!hideChangeButton &&
                <MB_Button
                    title={source ? `Change ${type}` : `Pick ${type}`}
                    style={styles.changeImage}
                    leftElement={<Feather name={'image'} size={20} color={COLORS.white} />}
                    onPress={type === MB_RESOURCE_PICKER_TYPE.image ? showResourcePicker : showResourcePicker}
                    disabled={disabled}
                />
            }
        </React.Fragment>
    );
};

export { MediaChanger };

const styles = StyleSheet.create({
    addImageBackground: {
        borderRadius: 300,
        width: 80,
        height: 80,
        backgroundColor: mbApplyTransparency(COLORS.black, 0.3),
        alignItems: 'center',
        justifyContent: 'center',
    },
    addImageContainer: {
        width: 319,
        height: 265,
        borderRadius: 10,
        borderWidth: 1,
        borderColor: COLORS.lighterGray,
        backgroundColor: COLORS.backgroundPurple,
        borderStyle: 'dashed',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 9,
        overflow: 'hidden',
    },
    changeImage: {
        height: 45,
        backgroundColor: COLORS.buttonPurple,
        borderRadius: 10,
        marginTop: 24,
    },
    thumbnailContainer: {
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent',
    },
    image: {
        width: '100%',
        height: '100%',
    },
});
