import * as FileSystem from 'expo-file-system';
import { FFmpegKit, ReturnCode } from 'ffmpeg-kit-react-native';

let cache: Record<string, string> = {};

const m3u8ToMp4 = async (url: string, outputFile: string) => {
    return new Promise((resolve, reject) => {
        FFmpegKit.execute(`-y -i ${url} -c copy -bsf:a aac_adtstoasc ${outputFile}`).then(async (session) => {
            const returnCode = await session.getReturnCode();
            if (ReturnCode.isSuccess(returnCode)) {
                console.log('m3u8ToMp4', url, 'to', outputFile);
                resolve({ url, outputFile });
            } else if (ReturnCode.isCancel(returnCode)) {
                reject({ url, outputFile, isCancel: true });
            } else {
                reject({ url, outputFile, isError: true });
            }
        });
    });
};

const Cache = {
    isCached: (url: string) => cache[url] !== undefined,
    cacheUrl: async (url: string) => {
        try {
            const filename = (url.split('/').pop() ?? url).replace('.m3u8', '.mp4');
            const filePath = `${FileSystem.cacheDirectory}${filename}`;
            const lowQualityUrl = url.substring(0, url.lastIndexOf('/')) + '/' + 'video_1.m3u8';
            await m3u8ToMp4(lowQualityUrl, filePath);
            cache[url] = filePath;
            await Cache.save();
        } catch (error: any) {
            console.log('cache', 'error', error?.message);
        }
    },
    getCachedUrl: (url: string) => cache[url],
    load: async () => {
        const cacheFile = `${FileSystem.cacheDirectory}cache.json`;
        const info = await FileSystem.getInfoAsync(cacheFile);
        if (info.exists) {
            const cacheData = await FileSystem.readAsStringAsync(cacheFile);
            cache = JSON.parse(cacheData);
        }
    },
    save: async () => {
        const cacheFile = `${FileSystem.cacheDirectory}cache.json`;
        await FileSystem.writeAsStringAsync(cacheFile, JSON.stringify(cache));
    },
};

export { Cache };
