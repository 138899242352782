import { MB_Button } from '@mightybyte/rnw.components.button';
import { MB_TextInput, MB_TextInputRef } from '@mightybyte/rnw.components.text-input';
import { isMobile } from '@mightybyte/rnw.utils.device-info';
import { MB_passwordUtils } from '@mightybyte/rnw.utils.password-utils';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import React, { useRef } from 'react';
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native';

import { MB_PasswordToolTip, TOOLTIP_POSITION, TOOLTIP_ARROW_DIRECTION } from '@mightybyte/rnw.components.tool-tip';
import { COLORS } from '../../../constants/colors';
import { STRING_CONSTANTS } from '../../../constants/constants';
import { textStyles } from '../../../constants/textStyles';
import { SignupUIProps } from './SignupUtils';

interface SignupInputProps extends SignupUIProps {
  containerStyle?: StyleProp<ViewStyle>,
}

const SignupInput = ({
  containerStyle,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  errorMessage,
  setErrorMessage,
  signUp,
  isSignUpButtonDisabled,
  isAskingForSignUpCode,
  signUpCode,
  setSignUpCode,
  onSubmitPressed,
  requestSignUpCode,
  attemptRequestRegistrationCode,
  passwordErrorArray,
  setPasswordErrorArray,
  generatePasswordErrorArray,
  portalRef,
}: SignupInputProps) => {

  const lastNameRef = useRef<MB_TextInputRef>(null);
  const emailRef = useRef<MB_TextInputRef>(null);
  const passwordRef = useRef<MB_TextInputRef>(null);
  const confirmPasswordRef = useRef<MB_TextInputRef>(null);

  return (
    <View style={[styles.container, containerStyle]}>
      {!isAskingForSignUpCode ? (
        <>
          <View style={styles.signupCodeContainerMobile}>
            <MB_TextInput
              style={styles.textInputContainerStyle}
              value={firstName}
              onChangeText={(newText: string) => {
                setFirstName(newText);
                setErrorMessage('');
              }}
              title="First Name *"
              placeholder="ex: Alex"
              maxLength={25}
              nextToFocus={lastNameRef}
            />
            <MB_TextInput
              MB_Ref={lastNameRef}
              style={[styles.textInputContainerStyle, styles.textInputWrapperStyle]}
              value={lastName}
              onChangeText={(newText: string) => {
                setLastName(newText);
                setErrorMessage('');
              }}
              title="Last Name *"
              placeholder="ex: Doe"
              maxLength={25}
              nextToFocus={emailRef}
            />
          </View>
          <MB_TextInput
            MB_Ref={emailRef}
            style={[styles.textInputContainerStyle, styles.textInputWrapperStyle]}
            value={email}
            onChangeText={(newText: string) => {
              setEmail(newText);
              setErrorMessage('');
            }}
            title="Email *"
            placeholder="you@example.com"
            textContentType="emailAddress"
            nextToFocus={passwordRef}
          />
          <MB_TextInput
            MB_Ref={passwordRef}
            style={[styles.textInputContainerStyle, styles.textInputWrapperStyle]}
            value={password}
            onChangeText={(newText: string) => {
              setPassword(newText);
              setErrorMessage('');
              setPasswordErrorArray(generatePasswordErrorArray(MB_passwordUtils.validatePassword(newText).errorArray, newText === confirmPassword));
            }}
            onFocus={() => {
              setErrorMessage('');
              setPasswordErrorArray(generatePasswordErrorArray(MB_passwordUtils.validatePassword(password).errorArray, password === confirmPassword));
            }}
            title="Password *"
            secureTextEntry
            wrapInFormElement
            textContentType="password"
            placeholder="Enter password"
            maxLength={256}
            toolTipData={{
              portalRef: portalRef,
              showOnFocus: true,
              toolTipPosition: isMobile ? TOOLTIP_POSITION.top : TOOLTIP_POSITION.left,
              moveBy: {
                x: isMobile ? 0 : -218,
                y: isMobile ? -185 : 0,
              },
              toolTipElement: <MB_PasswordToolTip errorArray={passwordErrorArray} arrowDirection={isMobile ? TOOLTIP_ARROW_DIRECTION.down : TOOLTIP_ARROW_DIRECTION.right} containerStyle={{ marginTop: isMobile ? undefined : '-33%' }} />,
            }}
            nextToFocus={confirmPasswordRef}
          />
          <MB_TextInput
            MB_Ref={confirmPasswordRef}
            style={[styles.textInputContainerStyle, styles.textInputWrapperStyle]}
            value={confirmPassword}
            onChangeText={(newText: string) => {
              setConfirmPassword(newText);
              setErrorMessage('');
              setPasswordErrorArray(generatePasswordErrorArray(MB_passwordUtils.validatePassword(newText).errorArray, newText === password));
            }}
            onFocus={() => {
              setErrorMessage('');
              setPasswordErrorArray(generatePasswordErrorArray(MB_passwordUtils.validatePassword(confirmPassword).errorArray, confirmPassword === password));
            }}
            title="Confirm password *"
            placeholder="Re-enter password"
            secureTextEntry
            wrapInFormElement
            textContentType="password"
            maxLength={256}
            toolTipData={{
              portalRef: portalRef,
              showOnFocus: true,
              toolTipPosition: TOOLTIP_POSITION.left,
              moveBy: {
                x: isMobile ? 0 : -218,
                y: isMobile ? -60 : 0,
              },
              toolTipElement: <MB_PasswordToolTip errorArray={passwordErrorArray} arrowDirection={isMobile ? TOOLTIP_ARROW_DIRECTION.down : TOOLTIP_ARROW_DIRECTION.right} containerStyle={{ marginTop: '-33%' }} />,
            }}
          />
        </>
      ) : (
        <>
          <Text style={styles.registrationCodeText}>{STRING_CONSTANTS.REGISTRATION_CODE_WAS_SENT_TO_YOUR_EMAIL}</Text>
          <MB_TextInput
            maxLength={6}
            titleStyle={styles.textInputTitleStyle}
            style={[styles.textInputContainerStyle, styles.registrationCodeBtn]}
            value={signUpCode}
            onChangeText={(newText: string) => {
              setSignUpCode(newText);
              setErrorMessage('');
            }}
            title="Verification Code"
            placeholder="ex: 885698"
            autoCapitalize="characters"
          />
        </>
      )}
      {errorMessage !== undefined && errorMessage !== '' && <Text style={textStyles.popUpErrorMessageText}>{errorMessage}</Text>}
      <MB_Button
        title={isAskingForSignUpCode ? 'Verify' : 'Next'}
        textStyle={textStyles.normalText}
        onPress={onSubmitPressed}
        style={[styles.submitButton, { marginTop: errorMessage ? 16 : isMobile ? 28 : 38 }]}
        disabled={isSignUpButtonDisabled()}
        loading={signUp.isLoading || requestSignUpCode.isLoading}
      />
      {isAskingForSignUpCode && (
        <MB_Button
          title="Re-Send sign up code"
          onPress={() => attemptRequestRegistrationCode(true)}
          style={styles.resendBtn}
          textStyle={[textStyles.smallText, { color: COLORS.AllportsBlue }]}
        />
      )}
    </View>
  );
};

export default SignupInput;

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  textInputWrapperStyle: {
    marginTop: 24,
  },
  textInputContainerStyle: {
    width: '100%',
    backgroundColor: COLORS.backgroundPurple,
    borderWidth: 0,
    borderRadius: 10,
  },
  greenText: {
    color: '#44ffff',
  },
  signupCodeContainer: {
    marginTop: 28,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 386,
  },
  signupCodeContainerMobile: {
    width: '100%',
  },
  terms: {
    color: COLORS.gray,
    textAlign: 'center',
    marginTop: isMobile ? 24 : 16,
    marginBottom: isMobile ? 51 : 0,
  },
  resendBtn: {
    padding: 0,
    backgroundColor: 'transparent',
    marginTop: 4,
  },
  inputMargin: {
    marginTop: 28,
  },
  lastName: {
    marginTop: isMobile ? 28 : 0,
  },
  submitButton: {
    borderRadius: 10,
    marginTop: 28,
    backgroundColor: COLORS.buttonPurple,
    alignSelf: 'stretch',
  },

  registrationCodeText: mbTextStyles([
    textStyles.normalText, {
      marginTop: 18,
      fontWeight: '400',
      textAlign: 'left',
      width: '100%',
    },
  ]),
  registrationCodeBtn: {
    marginTop: 25,
  },
  textInputTitleStyle: {
    fontWeight: '600',
  },
});
