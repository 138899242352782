import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const WarningIcon = ((props: { size?: number, color?: string }) => {
    return (
        <Svg
            width={props.size ?? 48}
            height={props.size ? (props.size * (51 / 48)) : 51}
            viewBox="0 0 66 51"
            fill="none"
        >
            <Path
                d="M64.15 38.17L41.405 4.242c-3.708-5.607-13.102-5.607-16.81 0L1.85 38.17c-3.708 5.607.906 12.563 8.406 12.563h45.406c7.499 0 12.196-7.027 8.488-12.563zM32.093 15.03c1.731-.355 3.462.355 4.203 1.775.247.497.33.993.33 1.561-.083 1.562-.248 3.123-.33 4.685l-.494 7.24c-.083.78-.083 1.49-.083 2.27-.082 1.278-1.235 2.272-2.719 2.272-1.483 0-2.637-.923-2.72-2.2-.247-3.762-.494-7.452-.74-11.214l-.248-2.98c0-1.634 1.07-2.982 2.802-3.409zM33 43.21c-1.978 0-3.626-1.42-3.626-3.124 0-1.703 1.648-3.123 3.626-3.123s3.626 1.42 3.543 3.194c.083 1.633-1.648 3.052-3.543 3.052z"
                fill="#fff"
            />
        </Svg>
    );
});

export { WarningIcon };
