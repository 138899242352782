import React, { useCallback, useRef, useState } from 'react';
import { MB_LinearGradient } from '@mightybyte/rnw.components.linear-gradient';
import { GEOGRAPHY_LOCATION, geographyLocationReadable } from './geographyConstants';
import { Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { GeographyView } from '../../../helperComponents/GeographyView/GeographyView';
import { COLORS } from '../../../../constants/colors';
import { textStyles } from '../../../../constants/textStyles';
import { mbPlatformStyle, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { MB_utilHooks } from '@mightybyte/rnw.utils.util-hooks';
import { GAME_TYPE, Question } from '../../../../typesAndInterfaces/typesAndInterfaces';
import { mbShowToast } from '@mightybyte/rnw.components.toast';
import { utils } from '../../../../utils/utils';
import { envs } from '../../../../../env';
import { MB_ModalMobileFakeScreen } from '@mightybyte/rnw.components.modal';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { SelectedText } from './SelectedText';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { MB_VideoPlayer, MB_VideoPlayerRef } from '@mightybyte/rnw.components.video-player';
import { initialWindowMetrics } from 'react-native-safe-area-context';
import { isMobile } from '@mightybyte/rnw.utils.device-info';


interface GeographyFullScreenControlsProps {
    question: Question,
    isVisible: boolean,
    onDismiss: () => void,
    onCheckAnswer: (isCorrect: boolean) => void,
}



const GeographyFullScreenControls = ({ question, isVisible, onDismiss, onCheckAnswer }: GeographyFullScreenControlsProps) => {
    if (question.gameType !== GAME_TYPE.Geography) {
        throw new Error('question of type Geography mus be provided!');
    }
    const [geographyLocation, setGeographyLocation] = useState<GEOGRAPHY_LOCATION>();
    const videoRef = useRef<MB_VideoPlayerRef>(null);

    const handleCheckAnswer = useCallback(() => {
        const isCorrect = question.geographyLocation === geographyLocation;
        if (isCorrect) {
            setTimeout(() => onDismiss(), 1000); // navigation to anouther screen take 2000
            onCheckAnswer(isCorrect);
        }
        mbShowToast({
            ...utils.gameFeedbackToastParams(isCorrect),
            text2: envs.FLAVOR === 'prod' ? undefined : question.geographyLocation,
        });
    }, [geographyLocation, onCheckAnswer, onDismiss, question.geographyLocation]);

    return (
        <MB_ModalMobileFakeScreen
            isVisible={isVisible}
            childrenWrapper={styles.modalWrapper}
        >
            <MB_VideoPlayer
                containerStyle={{ width: 1, height: 1 }}
                sourceUrl={question.video.url}
                shouldPlay={true}
                isLooping
                isMuted
                showControls={false}
                MB_Ref={videoRef}
                onFullscreenUpdate={(isFullScreen: boolean) => {
                    // video stop on ios when exit full screen
                    if (!isFullScreen && Platform.OS === 'ios') {
                        setTimeout(() => videoRef.current?.play(), 100);
                    }
                }}
            />
            <>
                {envs.FLAVOR !== 'prod' &&
                    <View style={{ position: 'absolute', backgroundColor: '#f00', padding: 4, zIndex: 1, top: 100 }}>
                        <Text style={[textStyles.smallerText, { textAlign: 'left', fontWeight: '800' }]}>DEBUG</Text>
                        <Text style={[textStyles.smallerText, { textAlign: 'left' }]}>ans: {question.geographyLocation}</Text>
                    </View>
                }
            </>
            <MB_Button
                style={[styles.videoButton, !isMobile && { right: '5%', width: 40, height: 40 }]}
                onPress={() => videoRef.current?.presentFullscreenPlayer()}
                rightElement={
                    <MaterialCommunityIcons name="youtube" size={isMobile ? 20 : 28} color={COLORS.white} />
                }
            />
            <MB_Button
                style={styles.backButton}
                onPress={onDismiss}
                rightElement={
                    <MaterialCommunityIcons name="chevron-left" size={31} color={COLORS.white} />
                }
            />
            <GeographyView
                map={question.geographyMap}
                onLocationChange={setGeographyLocation}
                style={styles.map}
            />
            <SelectedText text={geographyLocation ? geographyLocationReadable[geographyLocation] : ''} />
            <MB_Button
                title="Check answer"
                style={styles.checkAnaswerButton}
                disabled={!geographyLocation}
                onPress={handleCheckAnswer}
            />
        </MB_ModalMobileFakeScreen>
    );
};

interface GeographyControlsProps {
    question: Question,
    onAnswerPressed: (isCorrect: boolean) => void,
    disableAnswers?: boolean
}

const GeographyControls = ({ question, onAnswerPressed, disableAnswers }: GeographyControlsProps) => {

    if (question.gameType !== GAME_TYPE.Geography) {
        throw new Error('question of type Geography mus be provided!');
    }

    const [isShowingControls, showControls, hideControls] = MB_utilHooks.useBool();

    return (
        <MB_LinearGradient
            colors={[COLORS.gradientDarkPurple, COLORS.gradientLightPurple]}
            start={{ x: 0, y: 0 }} end={{ x: 1, y: 1 }}
            style={styles.gradientContainer}
        >
            <GeographyFullScreenControls
                question={question}
                isVisible={isShowingControls}
                onDismiss={hideControls}
                onCheckAnswer={onAnswerPressed}
            />
            <TouchableOpacity onPress={showControls} disabled={disableAnswers} style={{ flex: 1, width: '100%' }}>
                <GeographyView
                    map={question.geographyMap}
                    style={{ flex: 1 }}
                    pointerEvents="none"
                />
            </TouchableOpacity>
            <Text style={styles.showControlsText}>Tap image to expand and choose your answer</Text>
        </MB_LinearGradient>
    );
};

export { GeographyControls };

const styles = StyleSheet.create({
    gradientContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        padding: 16,
        borderRadius: Platform.OS === 'web' ? 16 : undefined,
        marginTop: Platform.OS === 'web' ? -12 : undefined, // Hiding the border bottom from video,
        ...mbPlatformStyle({
            web: {
                height: 350,
                marginBottom: 40,
            },
            mobile: {
                flex: 1,
            },
        }),
    },
    showControlsText: mbTextStyles([
        textStyles.smallText, {
            fontWeight: '500',
            marginTop: 16,
        },
    ]),
    modalWrapper: {
        backgroundColor: COLORS.backgroundDarkPurple,
        paddingBottom: 32,
        justifyContent: 'center',
        paddingTop: 100,
    },
    checkAnaswerButton: {
        backgroundColor: COLORS.buttonPurple,
        alignSelf: 'center',
        paddingHorizontal: 40,
    },
    videoButton: {
        position: 'absolute',
        top: initialWindowMetrics?.insets.top || 40,
        right: 14,
        backgroundColor: COLORS.grayButton,
        borderRadius: 8,
        width: 32,
        height: 32,
        paddingHorizontal: 0,
        paddingVertical: 0,
    },
    backButton: {
        position: 'absolute',
        top: initialWindowMetrics?.insets.top || 40,
        left: 14,
        backgroundColor: 'transparent',
        width: 32,
        height: 32,
        paddingHorizontal: 0,
        paddingVertical: 0,
    },
    map: {
        ...mbPlatformStyle({
            web: {
                borderWidth: 1.5,
                borderColor: COLORS.white,
                alignSelf: 'center',
                width: '90%',
                height: '80%',
            },
            mobile: {
                flex: 1,
            },
        }),
    },
});
