import { MB_Button } from '@mightybyte/rnw.components.button';
import { MB_Image } from '@mightybyte/rnw.components.image';
import { MB_ModalMobileFakeScreen } from '@mightybyte/rnw.components.modal';
import { mbPlatformStyle, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { MB_utilHooks } from '@mightybyte/rnw.utils.util-hooks';
import React, { useCallback, useMemo } from 'react';
import { Linking, Platform, StyleSheet, Text, View } from 'react-native';
import { envs } from '../../../../env';
import { COLORS } from '../../../constants/colors';
import { textStyles } from '../../../constants/textStyles';
import { imageUtils } from '../../../utils/imageUtils';
import ThankYouModal from '../../helperComponents/ThankYouModal';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { initialWindowMetrics } from 'react-native-safe-area-context';

const BecomeASponsor = ({ isVisible, onDismiss }: { isVisible: boolean, onDismiss: () => void }) => {

    const [isThankYouModalVisible, showThankYouModal, hideThankYouModal] = MB_utilHooks.useBool(false);

    const goToDonateLink = useCallback(() => {
        Linking.openURL(envs.DONATE_URL);
        setTimeout(() => {
            showThankYouModal();
        }, 1000);
    }, [showThankYouModal]);

    const content = useMemo(() => {
        return (
            <>
                <ThankYouModal
                    hideModal={hideThankYouModal}
                    isVisible={isThankYouModalVisible}
                />
                <Text style={styles.title}>Become a sponsor</Text>
                <Text style={styles.body}>ASL Flurry is the first to prioritize bilingual language development for hearing AND Deaf learners.</Text>
                <Text style={styles.body}>Your contribution helps us to bring literacy-rich ASL to Deaf and hard of hearing youth in non-signing families and to ASL learners of all ages and skill levels. Support our commitment to bringing the beauty of the Deaf community to devices everywhere!</Text>
                <MB_Image
                    resizeMode="contain"
                    source={imageUtils.images.donateImage}
                    style={styles.image}
                />
                <MB_Button
                    style={styles.donateButton}
                    onPress={goToDonateLink}
                >
                    <View style={styles.donateContainer}>
                        <Text style={styles.donateText}>Donate with</Text>
                        <MB_Image
                            resizeMode="contain"
                            source={imageUtils.images.paypalLogo}
                            style={styles.paypalLogo}
                        />
                    </View>
                </MB_Button>
            </>
        );
    }, [goToDonateLink, hideThankYouModal, isThankYouModalVisible]);

    if (isMobileApp) {
        return (
            <MB_ModalMobileFakeScreen
                isVisible={isVisible}
                container={styles.container}
                showCloseButton={true}
                onDismiss={onDismiss}
                closeButtonProps={{style: {top: initialWindowMetrics?.insets.top || 24}}}
            >
                {content}
            </MB_ModalMobileFakeScreen>
        );
    }
    return (
        <View style={styles.container}>
            {content}
        </View>
    );
};

export default BecomeASponsor;

const styles = StyleSheet.create({
    container: {
        ...mbPlatformStyle({
            web: {
                flex: 1,
            },
            mobile: {
                backgroundColor: COLORS.backgroundDarkPurple,
                height: '100%',
                padding: 24,
                paddingTop: initialWindowMetrics?.insets.top || 24,
            },
        }),
    },
    title: mbTextStyles([
        textStyles.largeText, {
            fontWeight: 'bold',
            marginBottom: 30,
            ...mbPlatformStyle({
                web: {
                    textAlign: 'left',
                },
            }),
        },
    ]),
    body: mbTextStyles([
        textStyles.smallText, {
            textAlign: 'left',
            marginBottom: 30,
        },
    ]),
    image: {
        width: 224,
        height: 166,
        alignSelf: 'center',
        flex: 1,
    },
    donateButton: {
        backgroundColor: COLORS.yellowButton,
        borderRadius: 30,
        width: Platform.OS === 'web' ? 300 : undefined,
        alignSelf: 'center',
    },
    donateContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    donateText: mbTextStyles([
        textStyles.normalText, {
            color: COLORS.black,
            marginEnd: 8,
        }]
    ),
    paypalLogo: {
        width: 100,
        height: 30,
        marginTop: 4,
    },
});
