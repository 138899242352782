import { BarIndicator, PacmanIndicator } from '@mightybyte/rnw.components.activity-indicators';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { MB_Image } from '@mightybyte/rnw.components.image';
import { mbHidePopUp, mbShowPopUp } from '@mightybyte/rnw.components.pop-up';
import { MB_ThumbnailVideoViewerModal } from '@mightybyte/rnw.components.video-player';
import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { MB_TextInput } from '@mightybyte/rnw.components.text-input';
import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import { StyleProp, StyleSheet, Text, TouchableOpacity, View, ViewStyle } from 'react-native';
import { FlatGrid } from 'react-native-super-grid';
import AntDesign from 'react-native-vector-icons/AntDesign';
import Feather from 'react-native-vector-icons/Feather';
import { COLORS } from '../../../../constants/colors';
import { SERVER_ERROR_CODES, STRING_CONSTANTS } from '../../../../constants/constants';
import { textStyles } from '../../../../constants/textStyles';
import { useDeleteImage, useGetImagePoolImages } from '../../../../hooks/imagePoolHooks';
import { useDeleteVideo, useGetVideoPoolVideos } from '../../../../hooks/videoPoolHooks';
import { PoolImage, RESOURCE_POOL_TYPE, PoolVideo, Folder, FOLDER_TYPE } from '../../../../typesAndInterfaces/typesAndInterfaces';
import { MB_UseInfiniteFetchOptions, useMBInfiniteFetch } from '@mightybyte/rnw.utils.util-hooks';
import { EmptyTableIcon } from '../../../../resources/svgComponents/EmptyTableIcon';
import { imageUtils } from '../../../../utils/imageUtils';
import { FoldersView } from '../../../helperComponents/FolderView/FolderView';

interface ImageVideoPoolFlatListProps<T extends RESOURCE_POOL_TYPE> {
    folder?: Folder,
    onFolderPress?: (folder: Folder) => void,
    resourcePoolType: T,
    style?: StyleProp<ViewStyle>,
    disableDelete?: boolean,
    selectedItem?: T extends RESOURCE_POOL_TYPE.Image ? PoolImage : PoolVideo,
    onSelectItem?: (item: T extends RESOURCE_POOL_TYPE.Image ? PoolImage : PoolVideo) => void,
    disableAddFolder?: boolean
}

const DefaultLoadingSpinner = () => {
    return (
        <View style={{ justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', minWidth: 100 }}>
            <PacmanIndicator style={styles.defaultLoader} color={COLORS.white} />
            <Text style={textStyles.smallText}>Loading...</Text>
        </View>
    );
};

interface RenderPoolImageProps {
    item: PoolImage,
    index: number,
    onDelete?: (poolImage: PoolImage) => void,
    isSelected?: boolean,
    onPress?: (image: PoolImage) => void
}

interface RenderPoolVideoProps {
    item: PoolVideo,
    index: number,
    onDelete?: (poolVideo: PoolVideo) => void,
    isSelected?: boolean,
    onPress?: (video: PoolVideo) => void
}

const RenderPoolImageLoading = memo(() => {
    return (
        <View style={[styles.cardContainer, { alignItems: 'center', justifyContent: 'center' }]}>
            <BarIndicator color={'white'} count={4} />
        </View>
    );
});

const RenderPoolVideo = memo(({ item: poolVideo, isSelected, onPress, onDelete }: RenderPoolVideoProps) => {
    return (
        <View style={[styles.cardContainer, { paddingBottom: 12 }, isSelected && styles.cardSelected]}>
            <MB_ThumbnailVideoViewerModal
                video={poolVideo}
                thumbnailContainerStyle={styles.thumbnailContainer}
                isFocused={true}
                customVideoProcessingView={
                    <Text style={[textStyles.smallText]}>We're processing this video.{'\n'}Please check back later.</Text>
                }
                hidePlayIcon={!poolVideo.thumbnail || onPress !== undefined}
                disableModalVideo={onPress !== undefined}
                onThumbnailPressed={() => onPress?.(poolVideo)}
            />
            <TouchableOpacity disabled={!onPress} onPress={() => onPress?.(poolVideo)}>
                <Text style={styles.cardTitle}>{poolVideo.name}</Text>
            </TouchableOpacity>
            {onDelete &&
                <MB_Button
                    style={styles.deleteButton}
                    leftElement={<AntDesign name={'close'} size={16} color={COLORS.lighterGray} />}
                    onPress={() => onDelete(poolVideo)}
                />
            }
        </View>
    );
});

const RenderPoolImage = memo(({ item: poolImage, onDelete, isSelected, onPress }: RenderPoolImageProps) => {

    return (
        <View style={[styles.cardContainer, isSelected && styles.cardSelected]}>
            <TouchableOpacity disabled={!onPress} style={styles.imagePressableWrapper} onPress={() => onPress?.(poolImage)}>
                <MB_Image source={poolImage.small ?? imageUtils.images.brokenImage} style={styles.imageContainerStyle} resizeMode={'contain'} disableInteraction />
                <Text style={styles.cardTitle}>{poolImage.name}</Text>
            </TouchableOpacity>
            {onDelete &&
                <MB_Button
                    style={styles.deleteButton}
                    leftElement={<AntDesign name={'close'} size={16} color={COLORS.lighterGray} />}
                    onPress={() => onDelete(poolImage)}
                />
            }
        </View>
    );
});

const ImageVideoPoolFlatListBeforeMemo = <T extends RESOURCE_POOL_TYPE>({ folder, onFolderPress, disableAddFolder, resourcePoolType, style, disableDelete, selectedItem, onSelectItem }: ImageVideoPoolFlatListProps<T>) => {

    const [searchText, setSearchText] = useState('');
    const debounceId = useRef<NodeJS.Timeout>();
    const { mutate: deleteImage } = useDeleteImage();
    const { mutate: deleteVideo } = useDeleteVideo();

    const deleteHandler = resourcePoolType === RESOURCE_POOL_TYPE.Image ? deleteImage : deleteVideo;

    const getPoolImagesHook = useGetImagePoolImages({ totalItemsPerPage: 20, searchText, folderId: folder?._id, queryOptions: { enabled: resourcePoolType === RESOURCE_POOL_TYPE.Image } });
    const getPoolVideosHook = useGetVideoPoolVideos({ totalItemsPerPage: 20, searchText, folderId: folder?._id, queryOptions: { enabled: resourcePoolType === RESOURCE_POOL_TYPE.Video } });

    const getPoolHandler = resourcePoolType === RESOURCE_POOL_TYPE.Image ? getPoolImagesHook : getPoolVideosHook;

    const { data, loadMore, isLoading, isFetching } = useMBInfiniteFetch<PoolImage | PoolVideo, MB_UseInfiniteFetchOptions>(getPoolHandler, { prefillWhileLoading: true });

    const flatListStyleFinal = useMemo(() => [styles.flatListStyle, style], [style]);

    const keyExtractor = React.useMemo(() => (item: PoolImage | string) => typeof item === 'string' ? item : item._id, []);

    const onDelete = useCallback((poolImageVideo: PoolImage | PoolVideo) => {
        mbShowPopUp({
            title: resourcePoolType === RESOURCE_POOL_TYPE.Image ? 'Delete image' : 'Delete video',
            message: `Are you sure you want to delete “${poolImageVideo.name}”?`,
            buttonText: resourcePoolType === RESOURCE_POOL_TYPE.Image ? 'Delete image' : 'Delete video',
            buttonAction: () => {
                return new Promise((resolve, reject) => {
                    deleteHandler(poolImageVideo._id, {
                        onSuccess: () => {
                            resolve();
                            mbHidePopUp();
                        },
                        onError: (error) => {
                            console.error('Error when deleting image or video: ', error);
                            if (error.errorCode === SERVER_ERROR_CODES.IMAGE_USED || error.errorCode === SERVER_ERROR_CODES.VIDEO_USED_ON_ANSWER) {
                                reject(error.message);
                            } else {
                                reject(STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN);
                            }
                        },
                    });
                });
            },
            buttonStyle: {
                container: { backgroundColor: COLORS.errorColor },
            },
            containerStyle: {
                width: 454,
            },
            secondaryButtonText: 'Cancel',
            secondaryButtonStyle: {
                text: { color: COLORS.buttonPurple },
                container: { backgroundColor: COLORS.white },
            },
        });
    }, [deleteHandler, resourcePoolType]);

    const renderItemModifed = useCallback(({ item, index }: { item: PoolImage | PoolVideo | string; index: number }) => {
        if (isLoading || typeof item === 'string') {
            return <RenderPoolImageLoading />;
        }

        if (resourcePoolType === RESOURCE_POOL_TYPE.Video) {
            return (
                <RenderPoolVideo
                    item={item as PoolVideo}
                    index={index}
                    onDelete={onDelete}
                    isSelected={selectedItem?._id === item._id}
                    onPress={onSelectItem}
                />
            );
        } else if (resourcePoolType === RESOURCE_POOL_TYPE.Image) {
            return (
                <RenderPoolImage
                    item={item}
                    index={index}
                    isSelected={selectedItem?._id === item._id}
                    onPress={onSelectItem as (item: PoolImage) => void}
                    onDelete={disableDelete ? undefined : onDelete}
                />
            );
        }

        return null;
    }, [disableDelete, isLoading, onDelete, onSelectItem, resourcePoolType, selectedItem?._id]);

    const onSearchChange = useCallback((text: string) => {
        clearTimeout(debounceId.current);
        debounceId.current = setTimeout(() => {
            setSearchText(text);
        }, 500);
    }, []);

    const items = useMemo(() => {
        // TODO: Anas: It might be worth actually receiving the videos from backend without processing entries for this.
        if (resourcePoolType === RESOURCE_POOL_TYPE.Video && onSelectItem) {
            return (data as PoolVideo[]).filter(item => !item.isProcessing);
        }
        return data;
    }, [data, onSelectItem, resourcePoolType]);

    const ListHeaderComponent = useMemo(() => {
        return (
            <>
                {!folder && <FoldersView type={resourcePoolType === RESOURCE_POOL_TYPE.Image ? FOLDER_TYPE.image : FOLDER_TYPE.video} disableAddFolder={disableAddFolder} onFolderPress={onFolderPress} />}
                <Text style={styles.title}>{resourcePoolType}s</Text>
                <View style={styles.listHeader}>
                    <View style={styles.searchIcon}>
                        <Feather name="search" size={22} color={COLORS.white} />
                    </View>
                    <MB_TextInput
                        placeholder="Search videos by name or tags"
                        style={styles.searchInput}
                        onChangeText={onSearchChange}
                    />
                </View>

                {items.length < 1 &&
                    <View style={styles.emptyDataContainer}>
                        <EmptyTableIcon />
                        <Text style={[textStyles.normalText, { color: COLORS.lighterGray, marginTop: 4 }]}>No Images/Videos</Text>
                    </View>
                }
            </>

        );
    }, [disableAddFolder, folder, items.length, onFolderPress, onSearchChange, resourcePoolType]);

    return (
        <FlatGrid
            keyExtractor={keyExtractor}
            itemDimension={144 + 6} //  Width of the item + extra space for delete button (144 + 6)
            spacing={18}
            data={items}
            renderItem={renderItemModifed}
            onEndReached={loadMore}
            onEndReachedThreshold={0.3}
            style={flatListStyleFinal}
            ListFooterComponent={isFetching && !isLoading ? DefaultLoadingSpinner : null}
            ListHeaderComponent={ListHeaderComponent}
        />
    );
};

export const ImageVideoPoolFlatList = React.memo(ImageVideoPoolFlatListBeforeMemo) as typeof ImageVideoPoolFlatListBeforeMemo;

const styles = StyleSheet.create({
    title: mbTextStyles([textStyles.smallText, {
        marginBottom: 16,
        textAlign: 'left',
    }]),
    flatListStyle: {
        width: '100%',
        flex: 1,
        paddingTop: 0,
    },
    emptyDataContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '100%',
        flex: 1,
    },
    deleteButton: {
        width: 24,
        height: 24,
        borderRadius: 300,
        position: 'absolute',
        backgroundColor: 'red',
        top: -6,
        right: -6,
        paddingHorizontal: 0,
    },
    cardContainer: {
        width: 144,
        height: 170,
        borderRadius: 8,
        backgroundColor: COLORS.backgroundPurple,
    },
    videoPoolContainer: {
        backgroundColor: mbApplyTransparency(COLORS.black, 0.3),
        alignItems: 'center',
        justifyContent: 'center',
    },
    imagePressableWrapper: {
        flex: 1,
        paddingVertical: 16,
    },
    imageContainerStyle: {
        flex: 1,
        aspectRatio: 1,
    },
    defaultLoader: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        marginTop: 15,
    },
    cardTitle: StyleSheet.flatten([
        textStyles.smallText, {
            marginTop: 12,
        },
    ]),
    cardSelected: {
        borderWidth: 1,
        borderColor: COLORS.white,
    },
    thumbnailContainer: {
        flex: 1,
        backgroundColor: 'transparent',
    },
    listHeader: {
        width: '100%',
        paddingHorizontal: 18,
        marginBottom: 20,
    },
    searchIcon: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        justifyContent: 'center',
        paddingHorizontal: 15,
    },
    searchInput: {
        paddingLeft: 43,
        width: '100%',
    },
});
