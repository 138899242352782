import { mbPlatformStyle, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { BaseToast, ErrorToast, ToastConfigParams } from 'react-native-toast-message';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { COLORS } from '../../constants/colors';
import { textStyles } from '../../constants/textStyles';
import { isMobile } from '@mightybyte/rnw.utils.device-info';

const CorrectAnswerIcon = () => {
    return (
        <View style={styles.answersIconWrapper}>
            <AntDesign name="checkcircleo" size={24} color={COLORS.white} />
        </View>
    );
};

const IncorrectAnswerIcon = () => {
    return (
        <View style={styles.answersIconWrapper}>
            <AntDesign name="closecircleo" size={24} color={COLORS.white} />
        </View>
    );
};

const ErrorCheckbox = () => {
    return (
        <View style={styles.defaultIconWrapper}>
            <AntDesign name="exclamationcircleo" size={16} color={COLORS.incorrectAnswerColor} />
        </View>
    );
};

const CustomToastConfig = {
    correctAnswer: (props: ToastConfigParams<any>) => {
        const { text1, text1Style, text2Style, ...restOfProps } = props;
        return (
            <BaseToast
                style={styles.toastCorrectQuestionAnswerStyle}
                contentContainerStyle={styles.toastContentContainerStyleForGameToasts}
                text1Style={text1Style ?? styles.toastCorrectQuestionAnswerText1Style}
                text2Style={text2Style ?? styles.toastCorrectQuestionAnswerText2Style}
                renderLeadingIcon={CorrectAnswerIcon}
                text1={text1 ?? 'Correct, great job!'}
                {...restOfProps}
            />
        );
    },
    incorrectAnswer: (props: ToastConfigParams<any>) => {
        const { text1, text1Style, text2Style, ...restOfProps } = props;
        return (
            <ErrorToast
                style={styles.toastIncorrectQuestionAnswerStyle}
                contentContainerStyle={styles.toastContentContainerStyleForGameToasts}
                text1Style={text1Style ?? styles.toastIncorrectQuestionAnswerText1Style}
                text2Style={text2Style ?? styles.toastIncorrectQuestionAnswerText2Style}
                renderLeadingIcon={IncorrectAnswerIcon}
                text1={text1 ?? 'Incorrect answer. Try again.'}
                {...restOfProps}
            />
        );
    },
    success: (props: ToastConfigParams<any>) => {
        const { text1, text2, text1Style, text2Style, ...restOfProps } = props;
        return (
            <BaseToast
                style={styles.toastCorrectAnswerStyle}
                contentContainerStyle={styles.toastContentContainerStyle}
                text1Style={text1Style ?? styles.text1Style}
                text2Style={text2Style ?? styles.text2Style}
                renderLeadingIcon={CorrectAnswerIcon}
                text1={text1 ?? 'Success!'}
                text2={text2 ?? 'Updated successfully'}
                {...restOfProps}
            />
        );
    },
    error: (props: ToastConfigParams<any>) => {
        const { text1, text2, text1Style, text2Style, ...restOfProps } = props;
        return (
            <ErrorToast
                style={styles.toastIncorrectAnswerStyle}
                contentContainerStyle={styles.toastContentContainerStyle}
                text1Style={text1Style ?? styles.text1Style}
                text2Style={text2Style ?? styles.text2Style}
                renderLeadingIcon={ErrorCheckbox}
                text1={text1 ?? 'Failed!'}
                text2={text2}
                {...restOfProps}
            />
        );
    },
};

export { CustomToastConfig };

const baseStyles = StyleSheet.create({
    toastStyle: {
        borderStartWidth: 0,
        paddingHorizontal: 10,
        alignItems: 'center',
        height: undefined,
        paddingVertical: 5,
    },
    questionToastStyle: {
        borderStartWidth: 0,
        paddingTop: 8,
        paddingBottom: 10,
        paddingHorizontal: 10,
        alignItems: 'center',
        height: undefined,
        width: undefined,
    },
    toastQuestionAnswerText1Style: mbTextStyles([
        textStyles.smallerText, {
            fontSize: 12,
            lineHeight: undefined,
            textAlign: 'left',
            fontWeight: '700',
            marginStart: 0,
            marginLeft: 0,
            margin: 0,
            marginHorizontal: 0,
            alignSelf: 'flex-start',
            color: COLORS.white,
        },
    ]),
});

const styles = StyleSheet.create({
    answersIconWrapper: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    defaultIconWrapper: {
        width: 17,
        height: 17,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: COLORS.white,
        borderRadius: 300,
    },
    text1Style: mbTextStyles([
        textStyles.smallerText, {
            fontSize: 11,
            lineHeight: undefined,
            textAlign: 'left',
            fontWeight: '700',
            marginStart: 0,
            marginLeft: 0,
            margin: 0,
            marginHorizontal: 0,
            alignSelf: 'flex-start',
            color: COLORS.toastTextColor,
        },
    ]),
    text2Style: mbTextStyles([
        textStyles.tinyText, {
            lineHeight: undefined,
            textAlign: 'left',
            fontWeight: '400',
            marginStart: 0,
            marginLeft: 0,
            margin: 0,
            marginHorizontal: 0,
            alignSelf: 'flex-start',
        },
    ]),
    toastCorrectAnswerStyle: StyleSheet.flatten([
        baseStyles.toastStyle, {
            backgroundColor: COLORS.correctAnswerColor,
        },
    ]),
    toastIncorrectAnswerStyle: StyleSheet.flatten([
        baseStyles.toastStyle, {
            backgroundColor: COLORS.incorrectAnswerColor,
        },
    ]),
    toastCorrectQuestionAnswerStyle: StyleSheet.flatten([
        baseStyles.questionToastStyle, {
            backgroundColor: COLORS.correctAnswerColor,
            borderWidth: isMobile ? 1 : 2,
            borderStartWidth: isMobile ? 1 : 2,
            borderColor: '#7BC852',
            borderStartColor: '#7BC852',
            paddingHorizontal: 16,
            paddingBottom: 10,
            paddingTop: 10,
            minWidth: isMobile ? '90%' : undefined,
            borderRadius: 6,
        },
    ]),
    toastCorrectQuestionAnswerText1Style: mbTextStyles([
        baseStyles.toastQuestionAnswerText1Style,
    ]),
    toastCorrectQuestionAnswerText2Style: mbTextStyles([
        baseStyles.toastQuestionAnswerText1Style, {
            fontWeight: '400',
            fontSize: 11,
            color: '#F4FFF8',
        },
    ]),
    toastIncorrectQuestionAnswerStyle: StyleSheet.flatten([
        baseStyles.questionToastStyle, {
            backgroundColor: COLORS.incorrectAnswerColor,
            borderWidth: isMobile ? 1 : 2,
            borderStartWidth: isMobile ? 1 : 2,
            borderColor: '#FE9F9F',
            borderStartColor: '#FE9F9F',
            paddingHorizontal: 16,
            paddingBottom: 10,
            paddingTop: 10,
            minWidth: isMobile ? '90%' : undefined,
            borderRadius: 6,
        },
    ]),
    toastIncorrectQuestionAnswerText1Style: mbTextStyles([
        baseStyles.toastQuestionAnswerText1Style,
    ]),
    toastIncorrectQuestionAnswerText2Style: mbTextStyles([
        baseStyles.toastQuestionAnswerText1Style, {
            fontWeight: '400',
            fontSize: 11,
            color: '#FFE8E8',
        },
    ]),
    toastContentContainerStyle: {
        paddingStart: 8,
    },
    toastContentContainerStyleForGameToasts: {
        paddingStart: 10,
        paddingEnd: 0,
        ...mbPlatformStyle({
            mobile: {
                flex: 0,
            },
        }),
    },
});
