import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Platform, StyleSheet, Text, View } from 'react-native';
import { MB_VideoPlayerPlayStatus, MB_VideoPlayerProps, MB_VideoPlayer } from '@mightybyte/rnw.components.video-player';
import { textStyles } from '../../constants/textStyles';
import { envs } from '../../../env';

const CachedVideoPLayer = memo((props: MB_VideoPlayerProps) => {

    const { Cache } = useRef(require('../../utils/cache')).current;

    const [isCached, setIsCached] = useState(() => Cache.isCached(props.sourceUrl || ''));
    const [isLoadError, setIsLoadError] = useState(false);
    const playStatus = useRef<MB_VideoPlayerPlayStatus>();
    const startTime = useRef<number>();
    const [loadTime, setLoadTime] = useState<number>();

    const mounted = useRef(false);

    useEffect(() => {
        mounted.current = true;
        if (!isCached && props.sourceUrl) {
            console.warn('CachedVideoPLayer', 'download low quality video to cache');
            Cache.cacheUrl(props.sourceUrl)
                .then(() => mounted.current && setIsCached(true));
            return () => {
                mounted.current = false;
            };
        }
    }, [Cache, isCached, props.sourceUrl]);

    const checkVideoStatus = useCallback(() => {
        if (!startTime.current) {
            startTime.current = Date.now();
        }
        setTimeout(() => {
            if (playStatus.current !== MB_VideoPlayerPlayStatus.playing) {
                console.warn('CachedVideoPLayer', 'video couldn\'t play');
                setIsLoadError(true);
            }
        }, 1000);
    }, []);

    const sourceUrl = (isCached && isLoadError) ?
        Cache.getCachedUrl(props.sourceUrl || '')
        : props.sourceUrl;

    return (
        <>
            {(!isCached && isLoadError) ?
                <View style={props.containerStyle}>
                    <View style={styles.loadingContainer}>
                        {props.loadingComponent}
                    </View>
                </View>
                :
                <MB_VideoPlayer
                    {...props}
                    sourceUrl={sourceUrl}
                    onLoad={checkVideoStatus}
                    onPlayStatusChanged={(newPlayStatus) => {
                        playStatus.current = newPlayStatus;
                        props.onPlayStatusChanged?.(newPlayStatus);
                        if (!loadTime) {
                            setLoadTime(Date.now() - (startTime.current || 0));
                        }
                    }}
                />
            }
            {envs.FLAVOR !== 'prod' &&
                <View style={{ position: 'absolute', top: 0, left: 0 }}>
                    <Text style={[textStyles.smallerText, { textAlign: 'left' }]}>Load Time (ms): {loadTime ?? 'loading ...'}   Freez Detected: {isLoadError ? 'yes' : ' '}</Text>
                    <Text style={[textStyles.smallerText, { textAlign: 'left' }]}>cache: {isCached ? 'cached' : 'caching'}</Text>
                </View>
            }
        </>
    );
});

const VideoPlayer = {
    Cached: Platform.select({
        default: MB_VideoPlayer,
        ios: CachedVideoPLayer,
    }),
};

export { VideoPlayer };

const styles = StyleSheet.create({
    loadingContainer: {
        zIndex: 2,
        position: 'absolute',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
