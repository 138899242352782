import * as React from 'react';
import { View, StyleProp, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

const GoogleLogo = React.memo((props: { size?: number, containerStyle?: StyleProp<ViewStyle> }) => {
    return (
        <View style={props.containerStyle}>
            <Svg
                width={props.size ?? 46}
                height={props.size ? (props.size * (44 / 46)) : 44}
                viewBox="0 0 46 44"
            >
                <Path
                    d="M41.004 18.454h-1.48v-.075H23v7.242h10.377c-1.514 4.215-5.582 7.241-10.377 7.241C16.916 32.862 11.984 28 11.984 22S16.916 11.138 23 11.138c2.808 0 5.363 1.044 7.308 2.75l5.194-5.12A18.417 18.417 0 0023 3.897C12.86 3.897 4.64 12.002 4.64 22c0 9.998 8.22 18.103 18.36 18.103 10.14 0 18.36-8.105 18.36-18.103 0-1.214-.126-2.399-.356-3.546z"
                    fill="#FFC107"
                />
                <Path
                    d="M6.756 13.574l6.033 4.362C14.42 13.95 18.374 11.138 23 11.138c2.808 0 5.363 1.044 7.308 2.75l5.194-5.12A18.417 18.417 0 0023 3.897c-7.052 0-13.168 3.925-16.244 9.677z"
                    fill="#FF3D00"
                />
                <Path
                    d="M23 40.103a18.42 18.42 0 0012.31-4.7l-5.683-4.74a11.035 11.035 0 01-6.627 2.2c-4.776 0-8.83-3.003-10.358-7.193l-5.987 4.548A18.376 18.376 0 0023 40.103z"
                    fill="#4CAF50"
                />
                <Path
                    d="M41.004 18.454h-1.48v-.075H23v7.242h10.377a10.909 10.909 0 01-3.752 5.042l.002-.001 5.683 4.74c-.402.361 6.05-4.35 6.05-13.402 0-1.214-.126-2.399-.356-3.546z"
                    fill="#1976D2"
                />
            </Svg>
        </View>
    );
});

export { GoogleLogo };
