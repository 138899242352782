import { SERVER_ERROR_CODES } from '../constants/constants';
import { IncompleteQuestionsData, Question, QuestionShort, ServerError } from '../typesAndInterfaces/typesAndInterfaces';
import { IncompleteQuestionsDataObj, QuestionObj, QuestionShortObjArray } from '../utils/zod/zodObjects';
import { axiosCaller } from './customAxios';

const questionApiCalls = {
  getQuestion: async (questionId: string | undefined): Promise<Question> => {
    if (!questionId) {
      const error: ServerError = {
        message: 'Question id was undefined',
        errorCode: SERVER_ERROR_CODES.GENERAL_ERROR,
        status: 'error',
      };

      throw error;
    }

    return await axiosCaller({
      path: '/api/questions/getQuestion',
      method: 'get',
      responseValidatorObj: QuestionObj,
      responseFormatValidatorMessage: 'Data mismatch for get question data response',
      passToken: true,
      responseDataKey: 'question',
      params: { questionId },
    });
  },

  getQuestions: async (categoryId: string | undefined): Promise<QuestionShort[]> => {
    if (!categoryId) {
      const error: ServerError = {
        message: 'Category id was undefined',
        errorCode: SERVER_ERROR_CODES.GENERAL_ERROR,
        status: 'error',
      };

      throw error;
    }

    return await axiosCaller({
      path: '/api/questions/getQuestions',
      method: 'get',
      responseValidatorObj: QuestionShortObjArray,
      responseFormatValidatorMessage: 'Data mismatch for get questions data response',
      passToken: true,
      params: { categoryId },
      responseDataKey: 'questions',
    });
  },

  getIncompleteQuestions: async (categoryId: string): Promise<IncompleteQuestionsData> => {
    return await axiosCaller({
      path: '/api/questions/getIncompleteQuestions',
      method: 'get',
      responseValidatorObj: IncompleteQuestionsDataObj,
      responseFormatValidatorMessage: 'Data mismatch for get incomplete questions response',
      passToken: true,
      params: { categoryId },
    });
  },
  getQuestionsIds: async (categoryIds: string[]): Promise<IncompleteQuestionsData> => {
    return await axiosCaller({
      path: '/api/questions/getQuestionsIds',
      method: 'get',
      responseValidatorObj: IncompleteQuestionsDataObj,
      responseFormatValidatorMessage: 'Data mismatch for get incomplete questions response',
      passToken: true,
      params: { categoryIds },
    });
  },
};

export { questionApiCalls };
