import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

const LandingPageBackground = React.memo((props: { size: number, color?: string, containerStyle?: StyleProp<ViewStyle> }) => {
    return (
        <View style={props.containerStyle}>
            <Svg
                width={props.size ?? 320}
                height={props.size ? (props.size * (133 / 320)) : 133}
                viewBox="0 0 320 133"
            >
                <Path
                    d="M255 114.514c43.018 17.486 101.366 32.202 119.5-9 16.151-36.696 9.202-77.335 13.5-117.5 4.375-40.894-59.273-143.244-89.982-170.94-29.196-26.332-72.374-21.7-110.197-30.737-32.23-7.701-62.578-18.52-95.535-18.418-39.759.123-82.631-3.727-114.95 19.069-34.17 24.102-57.116 64.353-62.955 106.414-5.707 41.106 17.057 79.532 31.697 118.738C-39.188 51.6-73.61 103.239-37 123.5c37.116 20.541 79.5-.586 121.5-8.986 45-9 119.046-20.915 170.5 0z"
                    fill={props.color ?? '#412366'}
                />
            </Svg>
        </View>
    );
});

export { LandingPageBackground };
