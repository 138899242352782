import React, { useState } from 'react';
import { Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { DifficultySelector } from '../helperComponents/DifficultySelector';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../constants/textStyles';
import { ComponentWrapper } from '../helperComponents/componentWrapper/ComponentWrapper';
import { mbPlatformStyle } from '@mightybyte/rnw.utils.style-utils';
import Feather from 'react-native-vector-icons/Feather';
import { COLORS } from '../../constants/colors';
import { useSignedInContext } from '../../context/SignedInContext';
import { useAnswerUserQuestionaire } from '../../hooks/userHooks';
import { Checkbox } from '../helperComponents/Checkbox';

const RadioButton = ({ isSelected, onPress }: { isSelected?: boolean, onPress: () => void }) => {
    return (
        <TouchableOpacity style={[{
            height: 24,
            width: 24,
            borderRadius: 12,
            borderWidth: 2,
            borderColor: '#C4C4C4',
            alignItems: 'center',
            justifyContent: 'center',
        }]}
            onPress={onPress}
        >
            {
                isSelected ?
                    <View style={{
                        height: 12,
                        width: 12,
                        borderRadius: 6,
                        backgroundColor: '#C4C4C4',
                    }} />
                    : null
            }
        </TouchableOpacity>
    );
};

const UserQuestionaire = () => {

    const [isUserDeaf, setIsUserDeaf] = useState<boolean>();
    const [isUserKnowDeaf, setIsUserKnowDeaf] = useState<boolean>();
    const [interestedSchool, setInterestedSchool] = useState<boolean>(false);
    const [interestedWork, setInterestedWork] = useState<boolean>(false);
    const [interestedPersonal, setInterestedPersonal] = useState<boolean>(false);

    const { signOut } = useSignedInContext();
    const { mutate: answerUserQuestionaire, isLoading } = useAnswerUserQuestionaire();

    const onAnswerUserQuestionaire = () => {
        if (isUserDeaf !== undefined && isUserKnowDeaf !== undefined) {
            answerUserQuestionaire({ isUserDeaf, isUserKnowDeaf, interestedSchool, interestedWork, interestedPersonal });
        }
    };

    function isButtonDisabled() {
        if (isUserDeaf === undefined || isUserKnowDeaf === undefined) {
            return true;
        }

        if (!interestedSchool && !interestedWork && !interestedPersonal) {
            return true;
        }

        return false;
    }

    return (
        <ComponentWrapper
            containerStyle={styles.container}
            disableLinearGradient
            hideWebHeader
            mobileHeaderOptions={{ showHeader: true, showBackArrow: true, backArrowCustomAction: signOut }}
        >
            {Platform.OS === 'web' && <Feather style={styles.back} name="arrow-left" size={20} color={COLORS.white} onPress={() => signOut()} />}
            <Text style={styles.title}>Almost there,</Text>
            <Text style={styles.subTitle}>Please answer the questions below to complete your sign up process.</Text>
            <Text style={styles.levelText}>Select your level of ASL <Text style={styles.required}>*</Text></Text>
            <DifficultySelector />
            <Text style={styles.deafText}>Are you deaf? <Text style={styles.required}>*</Text></Text>
            <View style={[styles.row, styles.userAnswer]}>
                <View style={[styles.row, styles.answerContainer]}>
                    <RadioButton isSelected={isUserDeaf === true} onPress={() => setIsUserDeaf(true)} />
                    <Text style={styles.answer}>Yes</Text>
                </View>
                <View style={styles.row}>
                    <RadioButton isSelected={isUserDeaf === false} onPress={() => setIsUserDeaf(false)} />
                    <Text style={styles.answer}>No</Text>
                </View>
            </View>
            <Text style={styles.deafText}>Do you know someone deaf? <Text style={styles.required}>*</Text></Text>
            <View style={[styles.row, styles.userAnswer]}>
                <View style={[styles.row, styles.answerContainer]}>
                    <RadioButton isSelected={isUserKnowDeaf === true} onPress={() => setIsUserKnowDeaf(true)} />
                    <Text style={styles.answer}>Yes</Text>
                </View>
                <View style={styles.row}>
                    <RadioButton isSelected={isUserKnowDeaf === false} onPress={() => setIsUserKnowDeaf(false)} />
                    <Text style={styles.answer}>No</Text>
                </View>
            </View>

            <Text style={styles.deafText}>I’m interested in sign language for: <Text style={styles.required}>*</Text></Text>
            <View style={styles.checkBox}>
                <Checkbox isChecked={interestedSchool} onPress={() => setInterestedSchool(old => !old)} checkboxStyleChecked={{ color: COLORS.buttonPurple }} />
                <Text style={[textStyles.normalText, { textAlign: 'left', marginStart: 8 }]}>School</Text>
            </View>
            <View style={styles.checkBox}>
                <Checkbox isChecked={interestedWork} onPress={() => setInterestedWork(old => !old)} checkboxStyleChecked={{ color: COLORS.buttonPurple }} />
                <Text style={[textStyles.normalText, { textAlign: 'left', marginStart: 8 }]}>Work</Text>
            </View>
            <View style={styles.checkBox}>
                <Checkbox isChecked={interestedPersonal} onPress={() => setInterestedPersonal(old => !old)} checkboxStyleChecked={{ color: COLORS.buttonPurple }} />
                <Text style={[textStyles.normalText, { textAlign: 'left', marginStart: 8 }]}>Personal reasons</Text>
            </View>

            <MB_Button
                title="Complete sign up"
                style={styles.completeBtn}
                disabled={isButtonDisabled()}
                loading={isLoading}
                onPress={onAnswerUserQuestionaire}
            />
        </ComponentWrapper>
    );
};

export { UserQuestionaire };

const styles = StyleSheet.create({
    container: {
        ...mbPlatformStyle({
            web: {
                justifyContent: 'center',
            },
        }),
    },
    back: {
        position: 'fixed' as 'absolute',
        top: 50,
        left: 30,
    },
    title: mbTextStyles([textStyles.largeText, {
        fontSize: 26,
        ...mbPlatformStyle({
            mobile: {
                textAlign: 'left',
            },
        }),
    }]),
    subTitle: mbTextStyles([textStyles.smallText, {
        marginTop: 5,
        color: '#CECECE',
        letterSpacing: -0.28,
        fontWeight: '400',
        ...mbPlatformStyle({
            mobile: {
                textAlign: 'left',
            },
        }),
    }]),
    levelText: mbTextStyles([textStyles.smallText, {
        fontSize: 13,
        marginTop: 45,
        color: '#CECECE',
        textAlign: 'left',
        marginBottom: 7,
        ...mbPlatformStyle({
            web: {
                alignSelf: 'center',
                width: 435,
            },
        }),
    }]),
    deafText: mbTextStyles([textStyles.smallText, {
        fontSize: 13,
        marginTop: 35,
        marginBottom: 15,
        color: '#CECECE',
        textAlign: 'left',
        ...mbPlatformStyle({
            web: {
                alignSelf: 'center',
                width: 435,
            },
        }),
    }]),
    required: {
        color: '#DD3636',
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    userAnswer: {
        ...mbPlatformStyle({
            web: {
                alignSelf: 'center',
                width: 435,
            },
        }),
    },
    answer: mbTextStyles([textStyles.smallText, {
        fontSize: 13,
        marginLeft: 10,
        color: '#CECECE',
        textAlign: 'left',
    }]),
    answerContainer: {
        marginRight: 24,
    },
    completeBtn: {
        backgroundColor: '#A430FF',
        marginTop: 45,
        ...mbPlatformStyle({
            mobile: {
                marginTop: 'auto',
            },
            web: {
                alignSelf: 'center',
                width: 435,
            },
        }),
    },
    checkBox: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 16,
        ...mbPlatformStyle({
            web: {
                alignSelf: 'center',
                width: 435,
            },
        }),
    },
});
