import React, { ReactNode } from 'react';

import { PortalHost, PortalProvider } from '@gorhom/portal';
import { PortalProviderProps } from '@gorhom/portal/lib/typescript/components/portalProvider/types';
import { PortalHostProps } from '@gorhom/portal/lib/typescript/components/portalHost/types';


const MB_PortalProvider = (props: PortalProviderProps | { children?: ReactNode | ReactNode[] }) => {
    return <PortalProvider {...props} children={props.children ?? null} />;
};

const MB_PortalHost = (props: PortalHostProps) => {
    return <PortalHost {...props} />;
};

export { MB_PortalProvider, MB_PortalHost };
