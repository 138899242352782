import { MB_Button } from '@mightybyte/rnw.components.button';
import { MB_LinearGradient } from '@mightybyte/rnw.components.linear-gradient';
import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { COLORS } from '../../../constants/colors';
import { textStyles } from '../../../constants/textStyles';
import GameScreenScroeCard from '../../../resources/svgComponents/GameScreenScroeCard';
import { ProgressBar } from '../../helperComponents/ProgressBar';

interface GameHeaderProps {
    showExitModal?: () => void,
    completedQuestions: number | undefined,
    totalQuestions: number,
}

const GameHeader = ({ showExitModal, completedQuestions, totalQuestions }: GameHeaderProps) => {

    const completedQuestionsAdjusted = completedQuestions !== undefined ? completedQuestions + 1 : undefined;

    return (
        <MB_LinearGradient
            colors={[COLORS.backgroundPurple, COLORS.buttonPurple]}
            start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }}
            style={styles.container}
        >
            <View style={styles.gameScreenScroeCard}>
                <GameScreenScroeCard size={48} />
                <Text style={styles.progressText}>{`${completedQuestionsAdjusted ?? '?'}/${totalQuestions}`}</Text>
            </View>

            <View style={styles.progressBarContainer}>
                {completedQuestions !== undefined &&
                    <ProgressBar
                        completedQuestions={completedQuestionsAdjusted ?? 0}
                        totalQuestions={totalQuestions}
                        showCompletionText={false}
                    />
                }
            </View>

            {showExitModal &&
                <MB_Button
                    style={styles.exitButton}
                    onPress={showExitModal}
                    leftElement={<MaterialCommunityIcons name="close-box-outline" color={COLORS.white} size={22} />}
                />
            }
        </MB_LinearGradient>
    );
};

export { GameHeader };

const styles = StyleSheet.create({
    container: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 8,
    },
    exitButton: {
        backgroundColor: mbApplyTransparency(COLORS.white, 0.2),
        borderRadius: 11,
        marginStart: 14,
        width: 44,
        height: 44,
        paddingHorizontal: 0,
    },
    progressText: mbTextStyles([
        textStyles.smallerText, {
            position: 'absolute',
            color: COLORS.buttonPurple,
            fontWeight: '600',
            textAlign: 'center',
        },
    ]),
    progressBarContainer: {
        flexGrow: 1,
        marginStart: 8,
    },
    gameScreenScroeCard: {
        alignItems: 'center',
        justifyContent: 'center',
    },
});
