import * as React from 'react';
import { StateType } from './react-gesture-responder';
import { useMeasure } from './use-measure';
import { GridContext } from './GridContext';
import { GridSettings } from './grid-types';
import { swap } from './swap';
import { getPositionForIndex, getTargetIndex } from './helpers';
import { GridItemContext } from './GridItemContext';
import { GridItem } from './GridItem';

export interface GridDropZoneProps<T>
  extends React.HTMLAttributes<HTMLDivElement> {
  id: string;
  data: T[];
  renderItem: (props: { item: T, isDragging: boolean, index: number }) => React.ReactNode;
  keyExtractor?: (item: T, index: number) => string;
  boxesPerRow: number;
  rowHeight: number;
  disableDrag?: boolean;
  disableDrop?: boolean;
  style?: React.CSSProperties;
  scrollOffset?: number;
  loadMore?: () => void
}

interface PlaceholderType {
  startIndex: number;
  targetIndex: number;
}

export function GridDropZone<T>({
  id,
  data,
  renderItem,
  keyExtractor = (_, index: number) => index.toString(),
  boxesPerRow,
  style,
  disableDrag = false,
  disableDrop = false,
  rowHeight,
  scrollOffset,
  loadMore,
  ...other
}: GridDropZoneProps<T>) {
  const {
    traverse,
    startTraverse,
    endTraverse,
    register,
    measureAll,
    onChange,
    remove,
    getActiveDropId,
  } = React.useContext(GridContext);

  const ref = React.useRef<HTMLDivElement>(null);
  const { bounds, remeasure } = useMeasure(ref);
  const [draggingIndex, setDraggingIndex] = React.useState<number | null>(null);
  const [placeholder, setPlaceholder] = React.useState<PlaceholderType | null>(
    null
  );

  //Check if there is a div with the data-scroll attribute and then store it in the scrollContainer
  const [scrollContainer, setScrollContainer] = React.useState<any | null>(
    ref.current ? ref.current.closest('[data-scroll]') : null
  );

  const traverseIndex =
    traverse && !traverse.execute && traverse.targetId === id
      ? traverse.targetIndex
      : null;

  const grid: GridSettings = {
    columnWidth: bounds.width / boxesPerRow,
    boxesPerRow,
    rowHeight,
    bounds,
  };

  const childCount = data.length;

  const onScroll = React.useCallback(() => {
    if (scrollContainer) {
      const maxScrollTop = scrollContainer.scrollHeight - scrollContainer.clientHeight;
      if (scrollContainer.scrollTop >= maxScrollTop - (scrollOffset || 0)) {
        loadMore?.();
      }
    }
  }, [scrollContainer, loadMore]);

  React.useEffect(() => {
    if (loadMore) {
      onScroll();
    }
  }, [data, onScroll]);

  React.useEffect(() => {
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', onScroll, false);
      return () => scrollContainer.removeEventListener('scroll', onScroll, false);
    }
  }, [scrollContainer, onScroll]);

  React.useEffect(() => {
    setScrollContainer(
      ref.current ? ref.current.closest('[data-scroll]') : null
    );
  }, [])

  /**
   * Register our dropzone with our grid context
   */

  React.useEffect(() => {
    register(id, {
      top: bounds.top,
      bottom: bounds.bottom,
      left: bounds.left,
      right: bounds.right,
      width: bounds.width,
      height: bounds.height,
      count: childCount,
      grid,
      disableDrop,
      remeasure,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childCount, disableDrop, bounds, id, grid]);

  /**
   * Unregister when unmounting
   */

  React.useEffect(() => {
    return () => remove(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // keep an initial list of our item indexes. We use this
  // when animating swap positions on drag events
  const itemsIndexes = data.map((_, i) => i);

  return (
    <div
      id={id}
      ref={ref}
      style={{
        position: 'relative',
        ...style,
      }}
      {...other}
    >
      {grid.columnWidth === 0
        ? null
        : data.map((item, i) => {
          const isTraverseTarget =
            traverse &&
            traverse.targetId === id &&
            traverse.targetIndex === i;

          const order = placeholder
            ? swap(
              itemsIndexes,
              placeholder.startIndex,
              placeholder.targetIndex
            )
            : itemsIndexes;

          const pos = getPositionForIndex(
            order.indexOf(i),
            grid,
            traverseIndex
          );

          /**
           * Handle a child being dragged
           * @param state
           * @param x
           * @param y
           */

          function onMove(state: StateType, x: number, y: number) {
            if (!ref.current) {
              return;
            }

            if (draggingIndex !== i) {
              setDraggingIndex(i);
            }

            const targetDropId = getActiveDropId(
              id,
              x + grid.columnWidth / 2,
              y + grid.rowHeight / 2
            );

            if (targetDropId && targetDropId !== id) {
              startTraverse(id, targetDropId, x, y, i);
            } else {
              endTraverse();
            }

            const targetIndex =
              targetDropId !== id
                ? i
                : getTargetIndex(
                  i,
                  grid,
                  childCount,
                  state.delta[0],
                  state.delta[1],
                );

            if (targetIndex !== i) {
              if (
                (placeholder && placeholder.targetIndex !== targetIndex) ||
                !placeholder
              ) {
                setPlaceholder({
                  targetIndex,
                  startIndex: i,
                });
              }
            } else if (placeholder) {
              setPlaceholder(null);
            }
          }

          /**
           * Handle drag end events
           */

          function onEnd(state: StateType, x: number, y: number) {
            const targetDropId = getActiveDropId(
              id,
              x + grid.columnWidth / 2,
              y + grid.rowHeight / 2
            );

            const targetIndex =
              targetDropId !== id
                ? i
                : getTargetIndex(
                  i,
                  grid,
                  childCount,
                  state.delta[0],
                  state.delta[1],
                );

            // traverse?
            if (traverse) {
              onChange(
                traverse.sourceId,
                traverse.sourceIndex,
                traverse.targetIndex,
                traverse.targetId
              );
            } else {
              onChange(id, i, targetIndex);
            }

            setPlaceholder(null);
            setDraggingIndex(null);
          }

          function onStart() {
            measureAll();
            setScrollContainer(
              ref.current ? ref.current.closest('[data-scroll]') : null
            );
          }

          return (
            <GridItemContext.Provider
              key={keyExtractor(item, i)}
              value={{
                top: pos.xy[1],
                disableDrag,
                endTraverse,
                mountWithTraverseTarget: isTraverseTarget
                  ? [traverse!.tx, traverse!.ty]
                  : undefined,
                left: pos.xy[0],
                i,
                onMove,
                onEnd,
                onStart,
                grid,
                dragging: i === draggingIndex,
                scrollContainer,
              }}
            >
              <GridItem>
                {renderItem({ item, isDragging: i === draggingIndex, index: i })}
              </GridItem>
            </GridItemContext.Provider>
          );
        })}
    </div>
  );
}
