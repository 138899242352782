import React, { useMemo } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { BODY_PARTS, BodyPartsSvgConfig } from './bodyPartsConstants';
import { MB_Modal } from '@mightybyte/rnw.components.modal';
import { MB_Button } from '@mightybyte/rnw.components.button';
import Feather from 'react-native-vector-icons/Feather';
import { COLORS } from '../../../../constants/colors';
import { BodyPartsSelectedText } from './BodyPartsSelectedText';
import { mbPlatformStyle } from '@mightybyte/rnw.utils.style-utils';
import { initialWindowMetrics } from 'react-native-safe-area-context';

interface BodyPartsSvgPresenterProps {
    svgConfig: BodyPartsSvgConfig,
    onPress: (tag: BODY_PARTS, selectedOrZoom: 'selected' | 'zoom') => void,
    bodyPartSelected: BODY_PARTS | undefined,
    onBackPressed: () => void,
    containerStyle?: StyleProp<ViewStyle>
    isForAdminPanel?: boolean
    onSelectPressed: (bodyPartSelected: BODY_PARTS) => void
}

const BodyPartsSvgPresenter = ({ svgConfig, onPress, bodyPartSelected, onBackPressed, containerStyle, isForAdminPanel, onSelectPressed }: BodyPartsSvgPresenterProps) => {


    const SvgElement = useMemo(() => ({ isFullScreen }: { isFullScreen?: boolean }) => {
        const retVal = svgConfig.element?.({
            containerStyle: isFullScreen ? styles.svgContainerStyle : undefined,
            onPress,
            highlightedParts: bodyPartSelected ? [bodyPartSelected] : [],
        });

        return retVal ?? null;
    }, [bodyPartSelected, onPress, svgConfig]);

    if (svgConfig.presentationStyle === 'fullScreen') {
        return (
            <View style={containerStyle}>
                <SvgElement isFullScreen />
            </View>
        );
    }

    function onSelected() {
        if (bodyPartSelected) {
            onSelectPressed(bodyPartSelected);
        }
    }

    return (
        <MB_Modal
            isVisible={true}
            hideCloseButton
            childrenWrapperStyle={styles.modalChildWrapper}
            onBackButtonPress={() => { }}
            withoutScrollView
            animationInTiming={200}
            animationOutTiming={200}
            animationIn={'zoomIn'}
            animationOut={'zoomOut'}
        >
            <View style={styles.container}>
                <MB_Button
                    onPress={onBackPressed}
                    style={styles.backButton}
                    leftElement={<Feather name={'arrow-left'} color={COLORS.buttonPurple} size={24} />}
                />
                <View style={{ flex: 1, marginTop: 16 }}>
                    <SvgElement />
                </View>
            </View>

            <BodyPartsSelectedText bodyPart={bodyPartSelected} />

            <MB_Button
                title={isForAdminPanel ? 'Select' : 'Check answer'}
                style={styles.checkAnaswerButton}
                disabled={bodyPartSelected === undefined}
                onPress={onSelected}
            />

        </MB_Modal>
    );
};

export { BodyPartsSvgPresenter };

const styles = StyleSheet.create({
    modalChildWrapper: {
        paddingTop: initialWindowMetrics?.insets.top || 32,
        paddingBottom: (initialWindowMetrics?.insets.bottom || 16) + 16,
        paddingHorizontal: 8,
        alignSelf: 'stretch',
        height: '100%',
        backgroundColor: 'transparent',
        justifyContent: 'center',
        alignItems: 'center',
    },
    container: {
        backgroundColor: COLORS.bodyPartsBackground,
        borderRadius: 20,
        paddingTop: 9,
        paddingBottom: 9,
        paddingHorizontal: 16,
        flex: 1,


        ...mbPlatformStyle({
            mobile: {
                alignSelf: 'stretch',
                // If we get rid of flex and use following, we end up with a nice looking pop-up on mobile
                //aspectRatio: 1,
                //width: '100%',
            },
            web: {
                width: '100%',
                maxWidth: 750,
                minWidth: 430,
                maxHeight: 750,
                minHeight: 430,
            },
        }),
    },
    backButton: {
        alignSelf: 'flex-start',
        paddingHorizontal: 8,
        backgroundColor: 'transparent',
        position: 'absolute',
        left: 8,
        zIndex: 1,
    },
    svgContainerStyle: {
        flex: 1,
    },
    checkAnaswerButton: {
        backgroundColor: COLORS.buttonPurple,
        alignSelf: 'center',
        paddingHorizontal: 40,
    },
});
