import { MB_Button } from '@mightybyte/rnw.components.button';
import { mbShowPopUp } from '@mightybyte/rnw.components.pop-up';
import { MB_accessTokenUtils } from '@mightybyte/rnw.utils.access-token-utils';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { useIsFocused } from '@react-navigation/core';
import { format as formatDate, parseISO } from 'date-fns';
import { saveAs } from 'file-saver';
import React, { useCallback, useMemo, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import { envs } from '../../../../env';
import { COLORS } from '../../../constants/colors';
import { DEFAULT_TABLE_MAX_ROWS } from '../../../constants/constants';
import { textStyles } from '../../../constants/textStyles';
import { useDeleteUser, useGetUsers, useManualPremiumUser } from '../../../hooks/adminHooks';
import { MB_PageIndicator } from '@mightybyte/rnw.components.page-indicator';
import { DIFFICULTY, PAYMENT_STATUS, User } from '../../../typesAndInterfaces/typesAndInterfaces';
import { utils } from '../../../utils/utils';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import { MB_Table } from '@mightybyte/rnw.components.table';
import { Checkbox } from '../../helperComponents/Checkbox';


const header = [
    { content: 'No.', conatinerStyle: { height: 85, minWidth: 80, maxWidth: 80, paddingLeft: 22 } },
    { content: 'Created', conatinerStyle: { height: 85, minWidth: 100, maxWidth: 100 } },
    { content: 'Name', conatinerStyle: { height: 85, minWidth: 180 } },
    { content: 'Email', conatinerStyle: { height: 85, minWidth: 180 } },
    { content: 'Level', conatinerStyle: { height: 85, minWidth: 150, maxWidth: 150 } },
    { content: 'App', conatinerStyle: { height: 85, minWidth: 186, maxWidth: 186 } },
    { content: 'Action', conatinerStyle: { height: 85, minWidth: 130, maxWidth: 130 } },
    { content: 'Manual Premium', conatinerStyle: { height: 85, minWidth: 140, maxWidth: 140 } },
];

const LevelBadge = ({ level }: { level: DIFFICULTY }) => {
    const colors = {
        [DIFFICULTY.beginner]: {
            color: COLORS.beginnerTextColor,
            backgroundColor: COLORS.beginnerBackgroundColor,
        },
        [DIFFICULTY.beginnerPlus]: {
            color: COLORS.beginnerTextColor,
            backgroundColor: COLORS.beginnerBackgroundColor,
        },
        [DIFFICULTY.intermediate]: {
            color: COLORS.intermediateTextColor,
            backgroundColor: COLORS.intermediateBackgroundColor,
        },
        [DIFFICULTY.intermediatePlus]: {
            color: COLORS.intermediateTextColor,
            backgroundColor: COLORS.intermediateBackgroundColor,
            textOverflow: 'clip',
        },
        [DIFFICULTY.advanced]: {
            color: COLORS.advancedTextColor,
            backgroundColor: COLORS.advancedBackgroundColor,
        },
        [DIFFICULTY.advancedPlus]: {
            color: COLORS.advancedTextColor,
            backgroundColor: COLORS.advancedBackgroundColor,
        },
    };

    return (
        <Text numberOfLines={1} style={[styles.levelBadge, colors[level]]}>{utils.difficultyToText(level)}</Text>
    );
};

const ManualPremium = ({ user }: { user: User }) => {

    const { mutate: manualPremiumUser, isLoading } = useManualPremiumUser();

    const onChange = useCallback(() => {
        if (user.paymentStatus === PAYMENT_STATUS.notPaid) {
            const enabled = !(user.manualPremium ?? false);
            mbShowPopUp({
                title: 'Change User Premium Status',
                message: `are you sure you want to ${enabled ? 'enable' : 'disable'} manual premium?`,
                buttonAction: () => new Promise((resolve) => {
                    manualPremiumUser({ userId: user._id, enabled }, utils.popUpCallback(resolve, 'user now has manual premium'));
                }),
                buttonText: 'Yes',
                buttonStyle: {
                    container: { backgroundColor: COLORS.buttonPurple },
                },
                secondaryButtonText: 'Cancel',
                secondaryButtonStyle: {
                    text: { color: COLORS.buttonPurple },
                    container: { backgroundColor: COLORS.white },
                },
            });
        }
    }, [manualPremiumUser, user._id, user.manualPremium, user.paymentStatus]);

    const isChecked = user.manualPremium ?? false;

    return (
        <Checkbox isChecked={isChecked} onPress={(isLoading || user.paymentStatus === PAYMENT_STATUS.paid) ? undefined : onChange} />
    );
};

const Users = () => {
    const [page, setPage] = useState(1);

    const isFocused = useIsFocused();

    const { data: users, ...getUsersOptions } = useGetUsers({
        totalItemsPerPage: DEFAULT_TABLE_MAX_ROWS,
        pageNum: page,
        queryOptions: {
            enabled: page !== undefined && page > 0 && isFocused,
            refetchOnWindowFocus: false,
            keepPreviousData: true,
        },
    });

    const { mutate: deleteUser } = useDeleteUser();

    const data = useMemo(() => {
        return users?.items?.map((item, index) => {
            return [
                (page - 1) * DEFAULT_TABLE_MAX_ROWS + index + 1,
                formatDate(parseISO(item.createdAt), 'MM/dd/yy'),
                `${item.firstName} ${item.lastName}`,
                item.email,
                <LevelBadge level={item.difficulty} />,
                item.paymentStatus === PAYMENT_STATUS.paid ? 'PAID' : 'FREE',
                <MB_Button
                    leftElement={<Feather name="trash-2" size={21} color={COLORS.errorColor} />}
                    style={styles.trashButton}
                    onPress={() => mbShowPopUp({
                        title: 'Delete User',
                        message: `Are you sure you want to delete "${item.firstName + ' ' + item.lastName}"?\nDeleting this user will erase all their information and progress.`,
                        buttonText: 'Delete user',
                        buttonAction: () => new Promise((resolve) => {
                            deleteUser({ userId: item._id }, utils.popUpCallback(resolve, 'User deleted successfully'));
                        }),
                        buttonStyle: {
                            container: { backgroundColor: COLORS.errorColor },
                        },
                        secondaryButtonText: 'Cancel',
                        secondaryButtonStyle: {
                            text: { color: COLORS.buttonPurple },
                            container: { backgroundColor: COLORS.white },
                        },
                    })}
                />,
                <ManualPremium user={item} />,
            ];
        });
    }, [deleteUser, page, users?.items]);

    const keyExtractor = useCallback((index) => users?.items[index]?._id ?? index.toString(), [users?.items]);

    return (
        <ComponentWrapper
            containerStyle={styles.container}
            disableLinearGradient
            wrapInScrollView
            hideWebHeader
        >
            <View style={styles.pageHeader}>
                <Text style={styles.pageTitle}>Users</Text>
                <MB_Button
                    title="Export to .xls"
                    style={styles.exportButton}
                    onPress={async () => {
                        const accessToken = await MB_accessTokenUtils.getAccessToken();
                        if (accessToken) {
                            let downloadUrl = encodeURI(`${envs.SERVER_URL}/api/admin/exportUsersToCsv?accessToken=${accessToken}`);
                            const fileName = `users_${formatDate(new Date(), 'yyyy-MM-dd_H:mm:ss:SSS')}.csv`;
                            saveAs(downloadUrl, fileName);
                        }
                    }}
                />
            </View>

            <View>
                <MB_Table
                    header={header}
                    headerStyle={styles.tableHeader}
                    data={data}
                    style={styles.tableStyle}
                    rowStyle={styles.rowStyle}
                    keyExtractor={keyExtractor}
                    loading={users ? (getUsersOptions.isFetching && getUsersOptions.isPreviousData) : getUsersOptions.isFetching}
                />

                <MB_PageIndicator
                    current={page}
                    pages={Math.ceil((users?.totalItems ?? 0) / DEFAULT_TABLE_MAX_ROWS)}
                    style={styles.pageIndicatorStyle}
                    onChange={setPage}
                />
            </View>
        </ComponentWrapper>
    );
};

export { Users };

const styles = StyleSheet.create({
    container: {
        minWidth: 1000,
    },
    pageHeader: {
        alignSelf: 'stretch',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 40,
    },
    pageTitle: mbTextStyles([
        textStyles.largestText, {
            fontSize: 36,
            color: COLORS.textLightPurple,
        },
    ]),
    exportButton: {
        height: 41,
        backgroundColor: COLORS.buttonPurple,
    },
    tableStyle: {
        backgroundColor: COLORS.backgroundPurple,
        borderRadius: 8,
        borderWidth: 0,
        marginTop: 24,
    },
    tableHeader: {
        height: 66,
        backgroundColor: 'transparent',
        borderBottomWidth: 2,
        borderBottomColor: COLORS.backgroundDarkPurple,
    },
    rowStyle: {
        borderWidth: 0,
    },
    pageIndicatorStyle: {

        borderTopWidth: 2,
        borderTopColor: COLORS.backgroundDarkPurple,
        paddingVertical: 18,
    },
    trashButton: {
        height: 30,
        backgroundColor: 'transaprent',
    },
    levelBadge: mbTextStyles([
        textStyles.smallText, {
            paddingHorizontal: 14,
            paddingVertical: 3,
            borderRadius: 26,
            fontWeight: '600',
            textTransform: 'capitalize',
        },
    ]),
});
