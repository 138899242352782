import React from 'react';
import { StyleSheet, TextInput, View } from 'react-native';
import { COLORS } from '../../../constants/colors';
import { mbApplyTransparency, mbPlatformStyle, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../constants/textStyles';
import { emitFocus, onChange, onlyAndroid } from './utils';

interface AmountInputProps {
    amount?: string,
    onSelectedAmount?: (value: string) => void;
}

export default function AmountInput({ amount, onSelectedAmount }: AmountInputProps) {

    return (
        <View style={styles.container}>
            <View style={styles.wheelContainer}>
                <TextInput
                    value={amount}
                    placeholder="Enter number"
                    style={styles.input}
                    returnKeyType="done"
                    keyboardType="number-pad"
                    placeholderTextColor={mbApplyTransparency('#FFFFFF', 0.45)}
                    onChangeText={onChange(onSelectedAmount)}
                    maxLength={2}
                    onPressIn={onlyAndroid(emitFocus)}
                />
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    text: {
        fontSize: 20,
        textAlign: 'center',
        fontWeight: 'bold',
        color: COLORS.white,
    },
    border: {
        borderWidth: 2,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderRadius: 0,
        borderColor: COLORS.cardPurple,
        backgroundColor: 'transparent',
    },
    container: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
    },
    wheelContainer: {
        ...mbPlatformStyle({
            web: {
                alignItems: 'center',
            },
            mobile: {
                flexDirection: 'row',
                justifyContent: 'center',
                flex: 1,
            },
        }),
    },
    input: mbTextStyles([textStyles.smallText, {
        backgroundColor: '#412366',
        padding: 8,
        borderRadius: 7,
        color: '#FFFFFF',
        width: 128,
    }]),
});
