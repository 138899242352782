import { z } from 'zod';
import { PAYMENT_PROVIDER } from '../constants/constants';
import { AcknowledgeRequest, PAYMENT_STATUS } from '../typesAndInterfaces/typesAndInterfaces';
import { axiosCaller } from './customAxios';

const paymentAndOrdersApiCalls = {
    acknowledgePurchase: async (requestParams: AcknowledgeRequest<PAYMENT_PROVIDER>): Promise<PAYMENT_STATUS> => {
        return await axiosCaller({
            path: '/api/paymentAndOrders/acknowledgePurchase',
            method: 'post',
            responseValidatorObj: z.nativeEnum(PAYMENT_STATUS),
            responseFormatValidatorMessage: 'Data mismatch for acknowledge purchase',
            responseDataKey: 'paymentStatus',
            data: requestParams,
            passToken: true,
        });
    },
    checkPaymentStatus: async (): Promise<PAYMENT_STATUS> => {
        return await axiosCaller({
            path: '/api/paymentAndOrders/checkPaymentStatus',
            method: 'post',
            responseValidatorObj: z.nativeEnum(PAYMENT_STATUS),
            responseFormatValidatorMessage: 'Data mismatch for check payment status',
            responseDataKey: 'paymentStatus',
            passToken: true,
        });
    },
    debugDeleteOrder: async (): Promise<void> => { // TODO: Delete once done testing.
        return await axiosCaller({
            path: '/api/paymentAndOrders/debugDeleteOrder',
            method: 'post',
            passToken: true,
        });
    },
};

export { paymentAndOrdersApiCalls };
