import {
    useMutation,
} from '@tanstack/react-query';
import { paymentAndOrdersApiCalls } from '../apiCalls/paymentAndOrdersApiCalls';

import { PAYMENT_PROVIDER } from '../constants/constants';
import { AcknowledgeRequest, PAYMENT_STATUS, ServerError } from '../typesAndInterfaces/typesAndInterfaces';

export const useAcknowledgePurchase = () => {
    return useMutation<PAYMENT_STATUS, ServerError, AcknowledgeRequest<PAYMENT_PROVIDER>, unknown>(
        async (data) => paymentAndOrdersApiCalls.acknowledgePurchase(data)
    );
};

export const useCheckPaymentStatus = () => {
    return useMutation<PAYMENT_STATUS, ServerError, void, unknown>(
        async () => paymentAndOrdersApiCalls.checkPaymentStatus()
    );
};
