import React, { ReactNode, useCallback, useRef, useState } from 'react';
import { useNavigation } from '@react-navigation/core';
import { View, StyleSheet, Text, StyleProp, ViewStyle, Pressable } from 'react-native';
import { UniversalScreenNavigationProp } from '../../../typesAndInterfaces/componentProps';
import { utils } from '../../../utils/utils';
import { useSignedInContext } from '../../../context/SignedInContext';
import { COLORS } from '../../../constants/colors';
import { textStyles } from '../../../constants/textStyles';
import { mbApplyTransparency, mbTextStyles, mbPlatformStyle, mbShadow } from '@mightybyte/rnw.utils.style-utils';
import { MB_OnHoverWrapper } from '@mightybyte/rnw.components.on-hover-wrapper';
import { MB_AnimatedChevron, MB_AnimatedChevronRef } from '@mightybyte/rnw.components.animated-chevron';
import { utilHooks } from '../../../hooks/utilHooks';
import { ModalDropdown } from '@mightybyte/rnw.components.modal-dropdown';
import { UserRole } from '../../../typesAndInterfaces/typesAndInterfaces';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import Feather from 'react-native-vector-icons/Feather';

const Header = React.memo(({ style, containerStyle, children }: { style?: StyleProp<ViewStyle>, containerStyle?: StyleProp<ViewStyle>, children?: ReactNode }) => {
    const [isMenuDropdownVisible, setIsMenuDropdownVisible] = useState(false);

    const { isSignedIn, signOut, currentUserData } = useSignedInContext();

    utilHooks.useWidthChange(isMenuDropdownVisible, useCallback(() => modalDropdownRef.current?.hide(), []));

    const navigation = useNavigation<UniversalScreenNavigationProp>();

    const modalDropdownRef = useRef<ModalDropdown>(null);

    let menuItems: string[] = ['Logout'];

    if (currentUserData?.userRole === UserRole.user) {
        menuItems = ['Profile settings', 'Logout'];
    }

    const foundOutSourceChevronRef = useRef<MB_AnimatedChevronRef>(null);

    const onOptionSelected = (index: number) => {
        switch (menuItems[index]) {
            case 'Logout':
                utils.showLogOutPopUp(signOut);
                break;
            case 'Profile settings':
                navigation.navigate('HomeNavigator', { screen: 'MyAccount', params: {} });
                break;
        }
    };

    const onLoginClick = () => {
        navigation.navigate('Login');
    };

    return (
        <View style={style}>
            <View style={[styles.container, containerStyle]}>
                <View style={{ paddingVertical: 4, minWidth: 450, flex: 1, flexDirection: 'row' }}>
                    {children}
                </View>

                {isSignedIn &&
                    <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <ModalDropdown
                            ref={modalDropdownRef}
                            options={menuItems}
                            scrollEnabled={false}
                            onDropdownWillHide={() => {
                                setIsMenuDropdownVisible(false);
                                foundOutSourceChevronRef.current?.setToInitialRotation();
                            }}
                            onDropdownWillShow={() => {
                                setIsMenuDropdownVisible(true);
                                foundOutSourceChevronRef.current?.setToFinalRotation();
                            }}
                            dropdownStyle={styles.profileIconDropdown}
                            renderSeparator={() => null}
                            renderRow={(option: any, index: any) => {
                                return (
                                    <View>
                                        <MB_OnHoverWrapper
                                            wrapperStyle={{
                                                paddingVertical: 9,
                                                paddingHorizontal: 10,
                                                marginTop: Number(index) === 0 ? 15 : 0,
                                                marginBottom: Number(index) === menuItems.length - 1 ? 15 : 0,
                                                borderRadius: 8,
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                            onHoverWrapperStyleModifier={{
                                                backgroundColor: COLORS.englishVioletAlt,
                                            }}
                                            onPress={() => {
                                                onOptionSelected(Number(index));
                                                modalDropdownRef.current?.hide();
                                            }}
                                        >
                                            {option === 'Profile settings' && <Feather name="settings" color={COLORS.white} size={20} />}
                                            {option === 'Logout' && <MaterialCommunityIcons name="exit-to-app" color={COLORS.white} size={20} />}
                                            <Text style={[textStyles.normalText, { textAlign: 'left', marginLeft: 8 }]}>{option}</Text>
                                        </MB_OnHoverWrapper>
                                    </View>
                                );
                            }}
                        >
                            <View style={styles.profileIconContainer}>
                                {/* <ProfileIcon image={undefined} firstName={currentUserData?.firstName} lastName={currentUserData?.lastName} fontStyle={{ fontSize: 16 }} /> */}
                                <Text style={[textStyles.normalText, { marginRight: 13, fontSize: 14 }]}>My Account</Text>
                                <MB_AnimatedChevron ref={foundOutSourceChevronRef} chevronSize={16} />
                            </View>
                        </ModalDropdown>
                    </View>
                }
                {!isSignedIn &&
                    <Pressable style={styles.loginButton} onPress={onLoginClick} >
                        <Text style={styles.loginButtonText}>Login</Text>
                    </Pressable>
                }
            </View>
        </View>
    );
});

export { Header };

const styles = StyleSheet.create({
    container: {
        alignSelf: 'stretch',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingTop: 30,
        marginBottom: 30,
    },
    profileIconContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginStart: 16,
    },
    profileIconDropdown: StyleSheet.flatten([
        mbShadow({ elevation: 3, color: COLORS.black, opacity: 0.45, radius: 15, offsetWidth: 2, offsetHeight: 2 }), {
            width: 277,
            height: 'auto',
            elevation: 5,
            backgroundColor: '#321B50',
            borderRadius: 10,
            borderWidth: 0,
            overflow: 'hidden',
            paddingHorizontal: 16,
        },
    ]),
    loginButton: {
        backgroundColor: mbApplyTransparency(COLORS.white, 0.15),
        width: 99,
        height: 40,
        justifyContent: 'center',
        borderRadius: 6,
    },
    loginButtonText: mbTextStyles([
        textStyles.normalText, {
            fontWeight: '400',
            ...mbPlatformStyle({
                web: {
                    cursor: 'pointer',
                },
            }),
        },
    ]),
    myAccount: {
        flexDirection: 'row',
        height: 40,
        alignItems: 'center',
    },
    myAccountButton: mbTextStyles([textStyles.smallText, {
        color: '#D3D3D3',
        marginRight: 10,
    }]),
});
