import { MB_Button } from '@mightybyte/rnw.components.button';
import { mbPlatformStyle, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { useNavigation } from '@react-navigation/core';
import React, { useCallback } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { COLORS } from '../../constants/colors';
import { textStyles } from '../../constants/textStyles';
import { usePaymentContext } from '../../context/PaymentContextProvider';
import { UniversalScreenNavigationProp } from '../../typesAndInterfaces/componentProps';
import { PAYMENT_STATUS } from '../../typesAndInterfaces/typesAndInterfaces';
import { useSignedInContext } from '../../context/SignedInContext';
import { utilHooks } from '../../hooks/utilHooks';
import { isMobile, isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { useToggleBanner } from '../../hooks/userHooks';


const GoPremiumBanner = () => {
    const { paymentStatus } = usePaymentContext();
    const { currentUserData } = useSignedInContext();
    const { mutate: toggleBanner } = useToggleBanner();
    // const { width } = useWindowDimensions();

    const navigation = useNavigation<UniversalScreenNavigationProp>();

    const { canAccessGames, canAccessRooms } = utilHooks.useGetAccess(currentUserData);
    const canShowBanner = (paymentStatus === undefined || !(paymentStatus === PAYMENT_STATUS.paid)) && (currentUserData?.settings?.canShowBanner || false);
    const hideBanner = useCallback(() => {
        toggleBanner(false);
    }, [toggleBanner]);

    const isSmallWindow = !isMobile && !isMobileApp; //&& width < 1200;

    if (!canShowBanner || (canAccessGames && canAccessRooms)) {
        return null;
    }

    return (
        <View style={[styles.premiumPopup, isSmallWindow && { width: 530 }]}>
            {isSmallWindow ?
                <View style={{ marginEnd: 'auto' }}>
                    <Text style={styles.popupTextTilte}>Expand your ASL vocabulary!</Text>
                    <Text style={styles.popupTextDetail}>Go premium to unlock more categories</Text>
                </View>
                :
                <>
                    <Text style={styles.popupTextTilte}>Expand your ASL vocabulary!</Text>
                    <Text style={styles.popupTextDetail}>Go premium to unlock more categories</Text>
                </>
            }
            <View style={styles.buttonContainer}>
                <MB_Button
                    title="Later"
                    textStyle={[textStyles.smallText, styles.laterButtonText]}
                    style={styles.laterButton}
                    onPress={hideBanner} />
                <MB_Button
                    title="Go Premium!"
                    textStyle={textStyles.smallText}
                    style={styles.premiumButton}
                    onPress={() => navigation.navigate('PaymentPage')}
                />
            </View>
        </View>
    );
};

export default GoPremiumBanner;

const styles = StyleSheet.create({
    premiumPopup: {
        paddingVertical: 16,
        backgroundColor: COLORS.bottombBarBlurBackgroundColor,
        marginHorizontal: -16,
        ...mbPlatformStyle({
            web: {
                position: 'absolute',
                flexDirection: 'row',
                zIndex: 1111,
                alignItems: 'center',
                marginTop: 20,
                borderRadius: 10,
                backgroundColor: '#321B50',
                paddingHorizontal: 18,
                alignSelf: 'center',
            },
            mobile: {
                borderBottomLeftRadius: 27,
                borderBottomRightRadius: 27,
                paddingHorizontal: 4,
            },
        }),
    },
    popupTextTilte: mbTextStyles([
        textStyles.largeText, {
            fontWeight: '500',
            marginBottom: 4,
            ...mbPlatformStyle({
                web: {
                    fontSize: 18,
                    marginEnd: 16,
                },
            }),
        },
    ]),
    popupTextDetail: mbTextStyles([
        textStyles.normalText, {
            marginBottom: 24,
            color: COLORS.textLightPurple,
            ...mbPlatformStyle({
                web: {
                    marginBottom: 0,
                    fontSize: 14,
                },
            }),
        },
    ]),
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    laterButton: {
        backgroundColor: 'transparent',
        borderRadius: 10,
        height: 40,
        ...mbPlatformStyle({
            web: {
                backgroundColor: '#FFFFFF',
                marginStart: 20,
                marginEnd: 8,
            },
            mobile: {
                marginEnd: 34,
            },
        }),
    },
    laterButtonText: {
        color: COLORS.buttonPurple,
    },
    premiumButton: {
        backgroundColor: COLORS.buttonPurple,
        borderRadius: 10,
        height: 40,
    },
});
