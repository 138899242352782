enum GEOGRAPHY_MAPS {
    usa_state = 'usa_state',
    europe_country = 'europe_country',
    continents = 'continents',
    america_north_south = 'america_north_south',
    oceans = 'oceans',
}

enum GEOGRAPHY_LOCATION {
    // usa
    usa_state_alabama = 'usa_state_alabama',
    usa_state_alaska = 'usa_state_alaska',
    usa_state_arizona = 'usa_state_arizona',
    usa_state_arkansas = 'usa_state_arkansas',
    usa_state_california = 'usa_state_california',
    usa_state_colorado = 'usa_state_colorado',
    usa_state_connecticut = 'usa_state_connecticut',
    usa_state_delaware = 'usa_state_delaware',
    usa_state_florida = 'usa_state_florida',
    usa_state_georgia = 'usa_state_georgia',
    usa_state_hawaii = 'usa_state_hawaii',
    usa_state_idaho = 'usa_state_idaho',
    usa_state_illinois = 'usa_state_illinois',
    usa_state_indiana = 'usa_state_indiana',
    usa_state_iowa = 'usa_state_iowa',
    usa_state_kansas = 'usa_state_kansas',
    usa_state_kentucky = 'usa_state_kentucky',
    usa_state_louisiana = 'usa_state_louisiana',
    usa_state_maine = 'usa_state_maine',
    usa_state_maryland = 'usa_state_maryland',
    usa_state_massachusetts = 'usa_state_massachusetts',
    usa_state_michigan = 'usa_state_michigan',
    usa_state_minnesota = 'usa_state_minnesota',
    usa_state_mississippi = 'usa_state_mississippi',
    usa_state_missouri = 'usa_state_missouri',
    usa_state_montana = 'usa_state_montana',
    usa_state_nebraska = 'usa_state_nebraska',
    usa_state_nevada = 'usa_state_nevada',
    usa_state_new_hampshire = 'usa_state_new_hampshire',
    usa_state_new_jersey = 'usa_state_new_jersey',
    usa_state_new_mexico = 'usa_state_new_mexico',
    usa_state_new_york = 'usa_state_new_york',
    usa_state_north_carolina = 'usa_state_north_carolina',
    usa_state_north_dakota = 'usa_state_north_dakota',
    usa_state_ohio = 'usa_state_ohio',
    usa_state_oklahoma = 'usa_state_oklahoma',
    usa_state_oregon = 'usa_state_oregon',
    usa_state_pennsylvania = 'usa_state_pennsylvania',
    usa_state_rhode_island = 'usa_state_rhode_island',
    usa_state_south_carolina = 'usa_state_south_carolina',
    usa_state_south_dakota = 'usa_state_south_dakota',
    usa_state_tennessee = 'usa_state_tennessee',
    usa_state_texas = 'usa_state_texas',
    usa_state_utah = 'usa_state_utah',
    usa_state_vermont = 'usa_state_vermont',
    usa_state_virginia = 'usa_state_virginia',
    usa_state_washington = 'usa_state_washington',
    usa_state_west_virginia = 'usa_state_west_virginia',
    usa_state_wisconsin = 'usa_state_wisconsin',
    usa_state_wyoming = 'usa_state_wyoming',
    //europe
    europe_sweden = 'europe_sweden',
    europe_finland = 'europe_finland',
    europe_norway = 'europe_norway',
    europe_iceland = 'europe_iceland',
    europe_united_kingdom = 'europe_united_kingdom',
    europe_ireland = 'europe_ireland',
    europe_france = 'europe_france',
    europe_spain = 'europe_spain',
    europe_portugal = 'europe_portugal',
    europe_germany = 'europe_germany',
    europe_poland = 'europe_poland',
    europe_slovakia = 'europe_slovakia',
    europe_belarus = 'europe_belarus',
    europe_ukraine = 'europe_ukraine',
    europe_romania = 'europe_romania',
    europe_hungary = 'europe_hungary',
    europe_austria = 'europe_austria',
    europe_prague = 'europe_prague',
    europe_itlay = 'europe_itlay',
    europe_bulgaria = 'europe_bulgaria',
    europe_greece = 'europe_greece',
    europe_turkey = 'europe_turkey',
    europe_latvia = 'europe_latvia',
    europe_estonia = 'europe_estonia',
    europe_slovenia = 'europe_slovenia',
    europe_netherlands = 'europe_netherlands',
    europe_denmark = 'europe_denmark',
    europe_belgium = 'europe_belgium',
    europe_luxemberg = 'europe_luxemberg',
    europe_switzerland = 'europe_switzerland',
    europe_andorra = 'europe_andorra',
    europe_liechtenstein = 'europe_liechtenstein',
    europe_croatia = 'europe_croatia',
    europe_bosnia_and_herzegovina = 'europe_bosnia_and_herzegovina',
    europe_montenegro = 'europe_montenegro',
    europe_lithuania = 'europe_lithuania',
    europe_moldova = 'europe_moldova',
    europe_serbia = 'europe_serbia',
    europe_macedonia = 'europe_macedonia',
    europe_kosovo = 'europe_kosovo',
    europe_albania = 'europe_albania',
    // continents
    continents_north_america = 'continents_north_america',
    continents_antarctica = 'continents_antarctica',
    continents_south_america = 'continents_south_america',
    continents_europe = 'continents_europe',
    continents_africa = 'continents_africa',
    continents_asia = 'continents_asia',
    continents_australia = 'continents_australia',
    //america_north_south
    south_america_venezuela = 'south_america_venezuela',
    south_america_colombia = 'south_america_colombia',
    south_america_brazil = 'south_america_brazil',
    south_america_peru = 'south_america_peru',
    south_america_bolivia = 'south_america_bolivia',
    south_america_argentina = 'south_america_argentina',
    south_america_paraguay = 'south_america_paraguay',
    south_america_chile = 'south_america_chile',
    south_america_ecuador = 'south_america_ecuador',
    south_america_uruguay = 'south_america_uruguay',
    south_america_french_guiana = 'south_america_french_guiana',
    south_america_suriname = 'south_america_suriname',
    south_america_guyana = 'south_america_guyana',
    north_america_canada = 'north_america_canada',
    north_america_alaska = 'north_america_alaska',
    north_america_usa = 'north_america_usa',
    north_america_mexico = 'north_america_mexico',
    north_america_belize = 'north_america_belize',
    north_america_cuba = 'north_america_cuba',
    north_america_bahamas = 'north_america_bahamas',
    north_america_jamaica = 'north_america_jamaica',
    north_america_haiti = 'north_america_haiti',
    north_america_dominican_republic = 'north_america_dominican_republic',
    north_america_puerto_rico = 'north_america_puerto_rico',
    north_america_guatemala = 'north_america_guatemala',
    north_america_honduras = 'north_america_honduras',
    north_america_nicaragua = 'north_america_nicaragua',
    north_america_costa_rica = 'north_america_costa_rica',
    north_america_panama = 'north_america_panama',
    // oceans
    ocean_atlantic = 'ocean_atlantic',
    ocean_arctic = 'ocean_arctic',
    ocean_indian = 'ocean_indian',
    ocean_pacific = 'ocean_pacific',
    ocean_southern = 'ocean_southern',
}

const geographyMapReadable: { [key in keyof typeof GEOGRAPHY_MAPS]: string } = {
    usa_state: 'United States',
    europe_country: 'Europe',
    continents: 'Continents',
    america_north_south: 'America North And South',
    oceans: 'Oceans',
};

const geographyLocationReadable: { [key in keyof typeof GEOGRAPHY_LOCATION]: string } = {
    // usa
    usa_state_alabama: 'Alabama',
    usa_state_alaska: 'Alaska',
    usa_state_arizona: 'Arizona',
    usa_state_arkansas: 'Arkansas',
    usa_state_california: 'California',
    usa_state_colorado: 'Colorado',
    usa_state_connecticut: 'Connecticut',
    usa_state_delaware: 'Delaware',
    usa_state_florida: 'Florida',
    usa_state_georgia: 'Georgia',
    usa_state_hawaii: 'Hawaii',
    usa_state_idaho: 'Idaho',
    usa_state_illinois: 'Illinois',
    usa_state_indiana: 'Indiana',
    usa_state_iowa: 'Iowa',
    usa_state_kansas: 'Kansas',
    usa_state_kentucky: 'Kentucky',
    usa_state_louisiana: 'Louisiana',
    usa_state_maine: 'Maine',
    usa_state_maryland: 'Maryland',
    usa_state_massachusetts: 'Massachusetts',
    usa_state_michigan: 'Michigan',
    usa_state_minnesota: 'Minnesota',
    usa_state_mississippi: 'Mississippi',
    usa_state_missouri: 'Missouri',
    usa_state_montana: 'Montana',
    usa_state_nebraska: 'Nebraska',
    usa_state_nevada: 'Nevada',
    usa_state_new_hampshire: 'New Hampshire',
    usa_state_new_jersey: 'New Jersey',
    usa_state_new_mexico: 'New Mexico',
    usa_state_new_york: 'New York',
    usa_state_north_carolina: 'North Carolina',
    usa_state_north_dakota: 'North Dakota',
    usa_state_ohio: 'Ohio',
    usa_state_oklahoma: 'Oklahoma',
    usa_state_oregon: 'Oregon',
    usa_state_pennsylvania: 'Pennsylvania',
    usa_state_rhode_island: 'Rhode Island',
    usa_state_south_carolina: 'South Carolina',
    usa_state_south_dakota: 'South Dakota',
    usa_state_tennessee: 'Tennessee',
    usa_state_texas: 'Texas',
    usa_state_utah: 'Utah',
    usa_state_vermont: 'Vermont',
    usa_state_virginia: 'Virginia',
    usa_state_washington: 'Washington',
    usa_state_west_virginia: 'West Virginia',
    usa_state_wisconsin: 'Wisconsin',
    usa_state_wyoming: 'Wyoming',
    //europe_country
    europe_sweden: 'Sweden',
    europe_finland: 'Finland',
    europe_norway: 'Norway',
    europe_iceland: 'Iceland',
    europe_united_kingdom: 'United Kingdom',
    europe_ireland: 'Ireland',
    europe_france: 'France',
    europe_spain: 'Spain',
    europe_portugal: 'Portugal',
    europe_germany: 'Germany',
    europe_poland: 'Poland',
    europe_slovakia: 'Slovakia',
    europe_belarus: 'Belarus',
    europe_ukraine: 'Ukraine',
    europe_romania: 'Romania',
    europe_hungary: 'Hungary',
    europe_austria: 'Austria',
    europe_prague: 'Prague',
    europe_itlay: 'Itlay',
    europe_bulgaria: 'Bulgaria',
    europe_greece: 'Greece',
    europe_turkey: 'Turkey',
    europe_latvia: 'Latvia',
    europe_estonia: 'Estonia',
    europe_slovenia: 'Slovenia',
    europe_netherlands: 'Netherlands',
    europe_denmark: 'Denmark',
    europe_belgium: 'Belgium',
    europe_luxemberg: 'Luxembourg',
    europe_switzerland: 'Switzerland',
    europe_andorra: 'Andorra',
    europe_liechtenstein: 'Liechtenstein',
    europe_croatia: 'Croatia',
    europe_bosnia_and_herzegovina: 'Bosnia and Herzegovina',
    europe_montenegro: 'Montenegro',
    europe_lithuania: 'Lithuania',
    europe_moldova: 'Moldova',
    europe_serbia: 'Serbia',
    europe_macedonia: 'Macedonia',
    europe_kosovo: 'Kosovo',
    europe_albania: 'Albania',
    // continents
    continents_north_america: 'North America',
    continents_antarctica: 'Antarctica',
    continents_south_america: 'South America',
    continents_europe: 'Europe',
    continents_africa: 'Africa',
    continents_asia: 'Asia',
    continents_australia: 'Australia',
    // america_north_south
    south_america_venezuela: 'Venezuela',
    south_america_colombia: 'Colombia',
    south_america_brazil: 'Brazil',
    south_america_peru: 'Peru',
    south_america_bolivia: 'Bolivia',
    south_america_argentina: 'Argentina',
    south_america_paraguay: 'Paraguay',
    south_america_chile: 'Chile',
    south_america_ecuador: 'Ecuador',
    south_america_uruguay: 'Uruguay',
    south_america_french_guiana: 'French Guiana',
    south_america_suriname: 'Suriname',
    south_america_guyana: 'Guyana',
    north_america_canada: 'Canada',
    north_america_alaska: 'Alaska',
    north_america_usa: 'USA',
    north_america_mexico: 'Mexico',
    north_america_belize: 'Belize',
    north_america_cuba: 'Cuba',
    north_america_bahamas: 'The Bahamas',
    north_america_jamaica: 'Jamaica',
    north_america_haiti: 'Haiti',
    north_america_dominican_republic: 'Dominican Republic',
    north_america_puerto_rico: 'Puerto Rico',
    north_america_guatemala: 'Guatemala',
    north_america_honduras: 'Honduras',
    north_america_nicaragua: 'Nicaragua',
    north_america_costa_rica: 'Costa Rica',
    north_america_panama: 'Panama',
    // oceans
    // oceans
    ocean_atlantic: 'Atlantic Ocean',
    ocean_arctic: 'Arctic Ocean',
    ocean_indian: 'Indian Ocean',
    ocean_pacific: 'Pacific Ocean',
    ocean_southern: 'Southern Ocean',
};


export { GEOGRAPHY_LOCATION, GEOGRAPHY_MAPS, geographyMapReadable, geographyLocationReadable };
