import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import Svg, { Circle, Defs, G, Path } from 'react-native-svg';

function ResetIcon(props: { size?: number, containerStyle?: StyleProp<ViewStyle> }) {
    return (
        <Svg
            width={props.size ?? 77}
            height={props.size ?? 77}
            viewBox="0 0 77 77"
            fill="none"
        >
            <G filter="url(#filter0_d_917_1558)">
                <Circle cx={38.5} cy={38.5} r={30.5} fill="#fff" />
                <Circle cx={38.5} cy={38.5} r={29.5} stroke="#A430FF" strokeWidth={2} />
            </G>
            <Path
                d="M53.1 37.889v-.115h-.004a14.266 14.266 0 00-4.144-9.73 14.044 14.044 0 00-9.783-4.143 14.037 14.037 0 00-9.72 3.757l-.084-1.7a1.38 1.38 0 00-.743-1.158h0a1.36 1.36 0 00-1.368.069h0c-.398.26-.634.707-.626 1.182v.001l.002.044.266 5.255v.252l.003.012c.009.036.02.072.033.106a.447.447 0 00.003.072l.002.018.008.017c.014.027.029.054.046.08v.033l.011.022a.48.48 0 00.04.064l.03.04h.005l.045.054h0l.07.083h0l.007.008.14.134h-.001l.008.007.088.07.028.021h.1l.153.1.01.006.012.004.095.032.016.005H28.264v0h.008l5.07-.428c.489-.04.918-.341 1.127-.787a1.39 1.39 0 00-.112-1.377h0a1.368 1.368 0 00-1.241-.59h0l-1.928.162a11.314 11.314 0 019.33-3.037h0a11.369 11.369 0 018.347 5.65 11.543 11.543 0 01.639 10.114 11.399 11.399 0 01-7.57 6.67 11.3 11.3 0 01-9.857-1.987 11.495 11.495 0 01-4.442-9.092v0c0-.492-.26-.947-.684-1.193a1.359 1.359 0 00-1.367 0h0a1.38 1.38 0 00-.684 1.193 14.27 14.27 0 004.13 10.048l.07-.069-.07.07A14.046 14.046 0 0039 52.1c3.74 0 7.326-1.498 9.97-4.163l-.07-.07.07.07A14.27 14.27 0 0053.1 37.89z"
                fill="#412366"
                stroke="#fff"
                strokeWidth={0.2}
            />
            <Defs />
        </Svg>
    );
}

export default ResetIcon;
