import { MB_Button } from '@mightybyte/rnw.components.button';
import { MB_Modal, MB_ModalMobileBottom } from '@mightybyte/rnw.components.modal';
import { MB_TextInput } from '@mightybyte/rnw.components.text-input';
import { mbPlatformStyle, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { useNavigation } from '@react-navigation/core';
import React, { useCallback, useMemo, useState } from 'react';
import { Platform, StyleSheet, Text, View } from 'react-native';
import { COLORS } from '../../../../constants/colors';
import { textStyles } from '../../../../constants/textStyles';
import { useCreateRoom, useJoinRoom } from '../../../../hooks/roomHooks';
import { mbShowToast } from '@mightybyte/rnw.components.toast';
import { SERVER_ERROR_CODES } from '../../../../constants/constants';
import { mbHidePopUp, mbShowPopUp } from '@mightybyte/rnw.components.pop-up';
import { RoomsNavigatorParamList } from '../../../../navigations/RoomsNavigator/RoomsNavigator';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';

export enum ROOM_MODAL_TYPE {
    CREATE = 'CREATE',
    JOIN = 'JOIN',
}

const CreateJoinRoomModal = ({ isVisible, onDismiss, roomType }: { isVisible: boolean, onDismiss?: () => void, roomType: ROOM_MODAL_TYPE }) => {
    const navigation = useNavigation<NativeStackNavigationProp<RoomsNavigatorParamList>>();
    const [input, setInput] = useState('');
    const titleText = roomType === ROOM_MODAL_TYPE.CREATE ? 'Create a Room' : 'Join a Room';
    const bodyText = roomType === ROOM_MODAL_TYPE.CREATE ? 'To create a room, name it and send the generated code to all players.' : 'Enter the unique code provided to you below to access a room and start playing.';
    const textFieldTitle = roomType === ROOM_MODAL_TYPE.CREATE ? 'Room name ' : 'Room code ';
    const textFieldPlaceholder = roomType === ROOM_MODAL_TYPE.CREATE ? 'Enter the name of your room' : 'Enter code';
    const buttonText = roomType === ROOM_MODAL_TYPE.CREATE ? 'Create Room' : 'Join Room';
    const { mutate: createRoom, isLoading: isCreateRoomLoading } = useCreateRoom();
    const { mutate: joinRoom, isLoading: isJoinRoomLoading } = useJoinRoom();

    const onCreateRoom = useCallback(() => {
        createRoom({ name: input }, {
            onSuccess: (room) => {
                setInput('');
                onDismiss?.();
                navigation.navigate('RoomLobby', { roomId: room._id });
            },
            onError: () => {
                mbShowToast({
                    type: 'error',
                    text1: 'Error',
                    text2: 'Something went wrong. Please try again.',
                });
            },
        });
    }, [createRoom, input, navigation, onDismiss]);

    const onJoinRoom = useCallback(() => {
        joinRoom({ code: input.toUpperCase() }, {
            onSuccess: (room) => {
                setInput('');
                onDismiss?.();
                navigation.navigate('RoomLobby', { roomId: room._id });
            },
            onError: (error) => {
                mbShowPopUp({
                    title: 'Error',
                    message: error.errorCode === SERVER_ERROR_CODES.NOT_FOUND ? 'couldn\'t found the room, please make sure the code is valid and the game not finished yet' : 'Something went wrong. Please try again.',
                    buttonAction: mbHidePopUp,
                });
            },
        });
    }, [input, joinRoom, navigation, onDismiss]);

    const onModalPress = useCallback(() => {
        if (input.length > 0) {
            if (roomType === ROOM_MODAL_TYPE.CREATE) {
                onCreateRoom();
            } else {
                onJoinRoom();
            }
        }
    }, [input.length, onCreateRoom, onJoinRoom, roomType]);

    const content = useMemo(() => {
        return (
            <View style={styles.container}>
                <Text style={styles.headerText}>{titleText}</Text>
                <Text style={styles.bodyText}>{bodyText}</Text>
                <MB_TextInput
                    showRequiredAsteriks
                    value={input}
                    title={textFieldTitle}
                    placeholder={textFieldPlaceholder}
                    titleStyle={styles.textInputTitle}
                    style={styles.textInput}
                    onChangeText={(newInput) => {
                        if (roomType === ROOM_MODAL_TYPE.CREATE || isMobileApp) {
                            setInput(newInput);
                        } else {
                            // Note: There is a weird bug observed on Android where typing the input and then casting it to upper case behaves weirdly.
                            // For example if you type just 'H' and then type lowercase 'a' and then just press on 'H', it ends up adding extra characters.
                            setInput(newInput.toUpperCase());
                        }
                    }}
                    autoCapitalize="characters"
                    maxLength={roomType === ROOM_MODAL_TYPE.CREATE ? 24 : 6}
                    disable={isCreateRoomLoading || isJoinRoomLoading}
                />
                <MB_Button
                    title={buttonText}
                    style={styles.createRoomButton}
                    onPress={onModalPress}
                    disabled={input.length === 0 || (roomType === ROOM_MODAL_TYPE.JOIN && input.length < 6) || isCreateRoomLoading || isJoinRoomLoading}
                    loading={isCreateRoomLoading || isJoinRoomLoading}
                />
            </View>
        );
    }, [bodyText, buttonText, input, isCreateRoomLoading, isJoinRoomLoading, onModalPress, roomType, textFieldPlaceholder, textFieldTitle, titleText]);


    if (isMobileApp) {
        return (
            <MB_ModalMobileBottom
                isVisible={isVisible}
                onDismiss={onDismiss}
                childrenWrapperStyle={styles.childrenWrapper}
                closeButtonProps={{ style: styles.closeButton }}
                closeButtonXProps={{ size: 14, color: COLORS.buttonPurple }}
                keyboardShouldPersistTaps="handled"
                keyboardAwareProps={Platform.OS === 'ios' ? {extraScrollHeight: 50} : undefined}
            >
                {content}
            </MB_ModalMobileBottom>
        );
    }

    return (
        <MB_Modal
            isVisible={isVisible}
            onDismiss={onDismiss}
            childrenWrapperStyle={styles.childrenWrapper}
            keyboardShouldPersistTaps="handled"
        >
            {content}
        </MB_Modal>
    );
};

export default CreateJoinRoomModal;

const styles = StyleSheet.create({
    childrenWrapper: {
        paddingHorizontal: 16,
        paddingTop: 27,
        ...mbPlatformStyle({
            mobile: {
                borderTopRightRadius: 21,
                borderTopLeftRadius: 21,
                backgroundColor: COLORS.backgroundDarkPurple,
            },
            web: {
                height: 340,
                backgroundColor: '#321B50',
            },
        }),
    },
    container: {
        width: '100%',
        justifyContent: 'flex-start',
        flex: 1,
        paddingHorizontal: 8,
    },
    closeButton: {
        position: 'absolute',
        top: 24,
        right: 10,
        width: 40,
        height: 40,
        backgroundColor: 'transparent',
    },
    headerText: mbTextStyles([
        textStyles.largeText, {
            fontSize: 21,
            ...mbPlatformStyle({
                mobile: {
                    textAlign: 'left',
                },
            }),
        },
    ]),
    bodyText: mbTextStyles([
        textStyles.smallText, {
            marginTop: 8,
            fontSize: 13,
            ...mbPlatformStyle({
                web: {
                    width: 361,
                },
                mobile: {
                    textAlign: 'left',
                },
            }),
        },
    ]),
    textInput: {
        backgroundColor: COLORS.backgroundPurple,
        borderColor: COLORS.backgroundPurple,
        marginTop: 32,
        width: '100%',
    },
    textInputTitle: mbTextStyles([
        textStyles.smallText, {
            color: COLORS.textLightPurple,
        },
    ]),
    createRoomButton: {
        alignSelf: 'stretch',
        backgroundColor: COLORS.buttonPurple,
        marginTop: 40,
        marginBottom: 80,
        borderRadius: 10,
    },
});
