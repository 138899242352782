import { MB_LinearGradient } from '@mightybyte/rnw.components.linear-gradient';
import { mbPlatformStyle, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { MB_utilHooks } from '@mightybyte/rnw.utils.util-hooks';
import React, { useCallback, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { COLORS } from '../../../constants/colors';
import { textStyles } from '../../../constants/textStyles';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import CreateJoinRoomModal, { ROOM_MODAL_TYPE } from './Components/CreateJoinRoomModal';
import LockIcon from '../../../resources/svgComponents/LockIcon';
import { usePaymentContext } from '../../../context/PaymentContextProvider';
import { PAYMENT_STATUS } from '../../../typesAndInterfaces/typesAndInterfaces';
import { mbHidePopUp, mbShowPopUp } from '@mightybyte/rnw.components.pop-up';
import { useNavigation } from '@react-navigation/core';
import { UniversalScreenNavigationProp } from '../../../typesAndInterfaces/componentProps';
import { useSignedInContext } from '../../../context/SignedInContext';
import { utilHooks } from '../../../hooks/utilHooks';
import { GameModeSwitch } from '../../helperComponents/GameModeSwitch';

const RoomCreateOrJoin = () => {

    const navigation = useNavigation<UniversalScreenNavigationProp>();

    const [isCreateRoomModalVisible, showCreateRoomModal, hideCreateRoomModal] = MB_utilHooks.useBool();
    const [roomType, setRoomType] = useState(ROOM_MODAL_TYPE.CREATE);
    const { paymentStatus } = usePaymentContext();
    const { currentUserData } = useSignedInContext();
    const { canAccessRooms } = utilHooks.useGetAccess(currentUserData);

    const isNotPaidUser = paymentStatus !== PAYMENT_STATUS.paid && !canAccessRooms;

    const showModal = useCallback((modalType: ROOM_MODAL_TYPE) => {
        if (isNotPaidUser) {
            mbShowPopUp({
                title: 'Unlock Game Rooms',
                message: 'Premium access is required to\ncreate or join game rooms',
                buttonText: 'Go Premium!',
                secondaryButtonText: 'Not Now',
                buttonAction: () => {
                    mbHidePopUp();
                    navigation.navigate('PaymentPage');
                },
            });
        } else {
            setRoomType(modalType);
            showCreateRoomModal();
        }
    }, [isNotPaidUser, navigation, showCreateRoomModal]);

    return (
        <ComponentWrapper
            containerStyle={styles.container}
            mobileHeaderOptions={{ showHeader: false }}
            keyboardShouldPersistTapsForScrollView="always"
            wrapInScrollView={false}
            headerChildren={<GameModeSwitch />}
        >
            <CreateJoinRoomModal
                key={`${isCreateRoomModalVisible}`}
                isVisible={isCreateRoomModalVisible}
                onDismiss={hideCreateRoomModal}
                roomType={roomType}
            />
            <Text style={styles.header}>Create and join rooms</Text>
            <Text style={styles.body}>Create a room and send the code for all players or join an existing room</Text>
            <View style={styles.mainButtonWrapper}>
                <TouchableOpacity
                    style={styles.startRoomGradient}
                    onPress={() => showModal(ROOM_MODAL_TYPE.CREATE)}>
                    <MB_LinearGradient
                        colors={isNotPaidUser ? ['#31264E', '#31264E'] : [COLORS.backgroundPurple, COLORS.gradientLightPurple]}
                        start={{ x: 0, y: 0 }}
                        end={{ x: 0.8, y: 0 }}
                        style={styles.startRoomButton}>
                        <Text style={styles.buttonHeader}>Start a new room</Text>
                        <Text style={styles.buttonBody}>Create a new room where you can generate a unique code to share with all players.</Text>
                    </MB_LinearGradient>
                    {isNotPaidUser && <LockIcon containerStyle={styles.createRoomLock} />}
                </TouchableOpacity>
                <View style={styles.spacer} />
                <TouchableOpacity
                    style={styles.joinRoomGradient}
                    onPress={() => showModal(ROOM_MODAL_TYPE.JOIN)}>
                    <MB_LinearGradient
                        colors={isNotPaidUser ? ['#31264E', '#31264E'] : [COLORS.backgroundLightGreen, COLORS.backgroundDarkGreen]}
                        start={{ x: 1, y: 0 }}
                        end={{ x: 0, y: 0 }}
                        style={styles.joinRoomButton}>
                        <Text style={styles.buttonHeader}>Join a room</Text>
                        <Text style={styles.buttonBody}>Join a room by entering the unique code shared with you. </Text>
                    </MB_LinearGradient>
                    {isNotPaidUser && <LockIcon containerStyle={styles.joinRoomLock} />}
                </TouchableOpacity>
            </View>
        </ComponentWrapper >
    );
};

export { RoomCreateOrJoin };

const styles = StyleSheet.create({
    container: {
        ...mbPlatformStyle({
            web: {
                minWidth: 1000,
            },
            mobile: {
                paddingTop: 40,
            },
        }),
    },
    header: mbTextStyles([
        textStyles.largerText, {
            fontSize: 22,
            fontWeight: '600',
        },
    ]),
    body: mbTextStyles([
        textStyles.smallText, {
            fontSize: 13,
            paddingHorizontal: 21,
            marginTop: 16,
            fontWeight: '400',
        },
    ]),
    mainButtonWrapper: {
        ...mbPlatformStyle({
            web: {
                flexDirection: 'row',
                justifyContent: 'center',
            },
        }),
    },
    spacer: {
        ...mbPlatformStyle({
            web: {
                width: 20,
            },
        }),
    },
    startRoomButton: {
        paddingVertical: 25,
        paddingRight: 50,
        paddingLeft: 23,
        borderColor: 'red',
        ...mbPlatformStyle({
            web: {
                borderRadius: 10,
                width: 329,
            },
            mobile: {
                borderTopRightRadius: 25,
                borderBottomRightRadius: 25,
            },
        }),
    },
    joinRoomButton: {
        paddingVertical: 25,
        paddingHorizontal: 23,
        ...mbPlatformStyle({
            web: {
                borderRadius: 10,
                width: 329,
            },
            mobile: {
                borderTopLeftRadius: 25,
                borderBottomLeftRadius: 25,
            },
        }),
    },
    gradient: {
        marginTop: 38,
    },
    startRoomGradient: {
        marginTop: 55,
        alignSelf: 'flex-start',
        marginLeft: -16,
    },
    joinRoomGradient: {
        marginTop: 20,
        alignSelf: 'flex-end',
        marginRight: -16,
    },
    buttonHeader: mbTextStyles([
        textStyles.normalText, {
            textAlign: 'left',
            fontSize: 15,
            fontWeight: '600',
        },
    ]),
    buttonBody: mbTextStyles([
        textStyles.smallerText, {
            textAlign: 'left',
            color: COLORS.textLightPurple,
            marginTop: 7,
            fontSize: 11,
            fontWeight: '500',
            width: 250,
        },
    ]),
    createRoomLock: {
        position: 'absolute',
        top: 18,
        right: 27,
    },
    joinRoomLock: {
        position: 'absolute',
        top: 25,
        right: 10,
    },
    headerButton: mbTextStyles([textStyles.smallText, {
        color: '#D3D3D3',
        marginRight: 10,
    }]),
});

