import { DotIndicator } from '@mightybyte/rnw.components.activity-indicators';
import { MB_AnimatedChevron, MB_AnimatedChevronRef } from '@mightybyte/rnw.components.animated-chevron';
import { ModalDropdown } from '@mightybyte/rnw.components.modal-dropdown';
import { mbPlatformStyle, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { COLORS } from '../../constants/colors';
import { textStyles } from '../../constants/textStyles';
import { useChangeUserDifficulty, useGetCurrentUserData } from '../../hooks/userHooks';
import { DIFFICULTY } from '../../typesAndInterfaces/typesAndInterfaces';
import { utils } from '../../utils/utils';

const DifficultyRow = (option: DIFFICULTY, _index: number, isSelected: boolean) => {
    return (
        <Text
            key={option}
            style={[
                styles.modalDropdownTextStyle, {
                    backgroundColor: isSelected ? utils.getDifficultyBackgroundColor(option) : COLORS.backgroundPurple,
                    color: isSelected ? COLORS.white : utils.getDifficultyTextColor(option),
                },
            ]}
        >
            {utils.difficultyToText(option)}
        </Text>
    );
};

const DifficultySelector = ({ difficulty, onDifficultyChange }: { difficulty?: DIFFICULTY, onDifficultyChange?: (difficulty: DIFFICULTY) => void }) => {
    const [selectedDifficulty, setSelectedDifficulty] = useState<DIFFICULTY | undefined>(difficulty);
    const stateAnimatedChevronRef = useRef<MB_AnimatedChevronRef>(null);
    const { data: currentUserData } = useGetCurrentUserData();
    const { mutate: changeDifficulty, isLoading: changeDifficultyIsLoading } = useChangeUserDifficulty();

    useEffect(() => {
        setSelectedDifficulty(currentUserData?.difficulty);
    }, [currentUserData?.difficulty]);

    useEffect(() => {
        if (difficulty) {
            setSelectedDifficulty(difficulty);
        }
    }, [difficulty]);

    const onSelect = (_index: number, option: DIFFICULTY) => {
        if (option !== selectedDifficulty) {
            if (onDifficultyChange) {
                onDifficultyChange?.(option);
            } else {
                changeDifficulty({ difficulty: option });
            }
        }
    };

    return (
        <ModalDropdown
            options={Object.values(DIFFICULTY)}
            renderRowComponent={(props: any) => <TouchableOpacity onPress={props.onPress} children={props.children} />}
            renderSeparator={() => null}
            dropdownStyle={styles.modalDropdownStyle}
            onDropdownWillHide={() => stateAnimatedChevronRef.current?.setToInitialRotation()}
            onDropdownWillShow={() => stateAnimatedChevronRef.current?.setToFinalRotation()}
            onSelect={onSelect}
            disabled={changeDifficultyIsLoading}
            renderRow={DifficultyRow}
            style={styles.modalDropdownView}
            dropdownTextHighlightStyle={{ backgroundColor: 'red' }}
        >
            <View style={[styles.modalDropwdownContainer, { backgroundColor: changeDifficultyIsLoading ? COLORS.gray : utils.getDifficultyBackgroundColor(selectedDifficulty) }]}>
                {changeDifficultyIsLoading ?
                    <DotIndicator
                        color={COLORS.white}
                        size={6}
                        style={[{ paddingVertical: 12, color: 'red' }]}
                        hideLoadingText={true}
                    />
                    :
                    <>
                        <Text style={styles.modalText}>{utils.difficultyToText(selectedDifficulty) ?? 'Select Difficulty'}</Text>
                        <MB_AnimatedChevron
                            ref={stateAnimatedChevronRef}
                            chevronSize={10}
                            containerStyle={styles.chevron}
                            startingRotation={0}
                            finalRotation={90}
                        />
                    </>
                }
            </View>
        </ModalDropdown>
    );
};

export { DifficultySelector };


const styles = StyleSheet.create({
    modalDropwdownContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: 130,
        height: 28,
        borderRadius: 13,
        alignSelf: 'center',
        ...mbPlatformStyle({
            web: {
                width:  136,
            },
        }),
    },
    modalDropdownStyle: {
        borderRadius: 10,
        height: 330,
        width: 150,
        backgroundColor: COLORS.backgroundPurple,
        borderColor: COLORS.backgroundPurple,
        paddingVertical: 4,
        marginTop: 16,
        marginStart: -12,
    },
    modalText: mbTextStyles([
        textStyles.smallerText, {
            fontWeight: '600',
            color: COLORS.lighterGray,
            paddingLeft: 8,
            paddingRight: 4,
            textTransform: 'capitalize',
            flex: 1,
        },
    ]),
    modalDropdownTextStyle: mbTextStyles([
        textStyles.smallerText, {
            fontWeight: '500',
            backgroundColor: COLORS.backgroundPurple,
            paddingVertical: 12,
            marginVertical: 4,
            marginHorizontal: 8,
            borderRadius: 10,
            textTransform: 'capitalize',
        },
    ]),
    modalDropdownView: {
        alignSelf: 'center',
    },
    chevron: {
        marginRight: 8,
    },
});
