// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Modal Dropdown scroll indicator controls
      #HELLO_WORLD::-webkit-scrollbar {
      width: 6px;
    }

    #HELLO_WORLD::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
    }

    #HELLO_WORLD::-webkit-scrollbar-thumb {
      background: #5D3E65;
      border-radius: 10px;
    }

    #HELLO_WORLD::-webkit-scrollbar-thumb:hover {
      background: #5D3E65;
    }
    
    */`, "",{"version":3,"sources":["webpack://./src/css/ScrollBarStyles.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;KAmBK","sourcesContent":["/* Modal Dropdown scroll indicator controls\n      #HELLO_WORLD::-webkit-scrollbar {\n      width: 6px;\n    }\n\n    #HELLO_WORLD::-webkit-scrollbar-track {\n      box-shadow: inset 0 0 5px grey;\n      border-radius: 10px;\n    }\n\n    #HELLO_WORLD::-webkit-scrollbar-thumb {\n      background: #5D3E65;\n      border-radius: 10px;\n    }\n\n    #HELLO_WORLD::-webkit-scrollbar-thumb:hover {\n      background: #5D3E65;\n    }\n    \n    */"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
