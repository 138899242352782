import { MB_Button } from '@mightybyte/rnw.components.button';
import { mbGetMediumImage, MB_Image } from '@mightybyte/rnw.components.image';
import { mbHidePopUp, mbShowPopUp } from '@mightybyte/rnw.components.pop-up';
import { MB_TextInput } from '@mightybyte/rnw.components.text-input';
import { mbShowToast } from '@mightybyte/rnw.components.toast';
import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import isEqual from 'lodash/isEqual';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { COLORS } from '../../../../constants/colors';
import { SERVER_ERROR_CODES, STRING_CONSTANTS } from '../../../../constants/constants';
import { tableStyles } from '../../../../constants/tableStyles';
import { textStyles } from '../../../../constants/textStyles';
import { useCreateCategory, useDeleteQuestion, useEditCategory, useGetCategory, useToggleCategory, useToggleCategoryPaymentState } from '../../../../hooks/adminHooks';
import { useGetQuestions } from '../../../../hooks/questionHooks';
import { MB_ToggleSwitch } from '../../../../mightyByteLibraries/MB_ToggleSwitch';
import { ConstructCategoryProps } from '../../../../typesAndInterfaces/componentProps';
import { DIFFICULTY, GAME_TYPE, PoolImage, PoolVideo, QuestionShort, RESOURCE_POOL_TYPE } from '../../../../typesAndInterfaces/typesAndInterfaces';
import { Checkbox } from '../../../helperComponents/Checkbox';
import { ComponentWrapper } from '../../../helperComponents/componentWrapper/ComponentWrapper';
import { LiveStatusBadge } from '../../../helperComponents/LiveStatusBadge';
import { LoadingPage } from '../../../helperComponents/LoadingPage';
import { AdminCreateOrEditHeader } from './Utils/Header';
import { utils } from '../../../../utils/utils';
import { MB_Table } from '@mightybyte/rnw.components.table';
import { ImageVideoSelectorModal } from '../../../helperComponents/ImageVideoSelectorModal';
import { MediaChanger } from './Utils/MediaChanger';

const isCategoryHasExtra = (extras: (string | PoolVideo)[]): extras is PoolVideo[] => extras.every(extra => typeof extra !== 'string');

const buildVideo = (video: PoolVideo) => (
    <View style={styles.gameQuestion}>
        {video.isProcessing ?
            <View style={[styles.videoPlayer, styles.videoPlayerProccessing]}>
                <View style={styles.playIconWrapper}>
                    <FontAwesome name="play" size={20} color={COLORS.lighterGray} />
                </View>
                <Text style={[textStyles.smallerText, { marginTop: 6 }]}>Processing</Text>
            </View>
            :
            <MB_Image
                source={mbGetMediumImage(video.thumbnail) ?? ''}
                style={styles.videoPlayer}
                disableInteraction
            />
        }
        <Text selectable style={[textStyles.smallText, { color: COLORS.textLightPurple, textAlign: 'left' }]} numberOfLines={3}>{video.name}</Text>
    </View>
);

const ConstructCategory = ({ route, navigation }: ConstructCategoryProps) => {

    const { categoryId, gameType } = route.params;
    const [name, setName] = useState('');
    const [difficulty, setDifficulty] = useState<DIFFICULTY[]>([]);
    const [tutorialURL, setTutorialURL] = useState('');
    const [isVideoSelectorVisible, setIsVideoSelectorVisible] = useState(false);
    const [image, setImage] = useState<PoolImage>();
    const [extras, setExtras] = useState<PoolVideo[]>([]);

    const isEditing = categoryId !== undefined;

    const { mutate: createCategory, isLoading: isCreateCategoryLoading } = useCreateCategory();
    const { data: category, isLoading: isLoadingCategory } = useGetCategory({ categoryId: categoryId, queryOptions: { enabled: isEditing && categoryId !== undefined } });
    const { mutate: editCategory, isLoading: isEditCategoryLoading } = useEditCategory();
    const { data: questions, isLoading: isQuestionsLoading } = useGetQuestions({ categoryId: categoryId, queryOptions: { enabled: isEditing && categoryId !== undefined } });
    const { mutate: deleteQuestion } = useDeleteQuestion();
    const { mutate: toggleCategory, isLoading: isToggleCategoryLoading } = useToggleCategory();
    const { mutate: changeCategoryPaymentType, isLoading: isCategoryPaymentStateLoading } = useToggleCategoryPaymentState();

    // Setting state variables from category data
    useEffect(() => {
        if (category) {
            setName(category.name);
            setTutorialURL(category.tutorialURL ?? '');
            setDifficulty([...category.difficulty]);
            if (typeof category.image !== 'string') {
                setImage(category.image);
            }
            const categoryExtras = category.extras ?? [];
            if (isCategoryHasExtra(categoryExtras)) {
                setExtras(categoryExtras);
            }
        }
    }, [category]);

    const onGoBack = useCallback(() => {
        // TODO: Might be worth fixing, we were having issues determining if we should go back or reset. The issue is that canGoBack always returns true and takes back to users tab
        const navState = navigation.getState();
        if (navigation.canGoBack() && navState.routes[Math.max(navState.index - 1, 0)].name === 'Categories') {
            navigation.goBack();
        } else {
            navigation.reset({ index: 0, routes: [{ name: 'Categories' }] });
        }
    }, [navigation]);

    const onError = useCallback((error) => {
        const isKnownError = error?.errorCode === SERVER_ERROR_CODES.LIMIT_FILE_SIZE ||
            error?.errorCode === SERVER_ERROR_CODES.CATEGORY_WITH_GIVEN_NAME_ALREADY_EXISTS ||
            error?.errorCode === SERVER_ERROR_CODES.CANNOT_ENABLE_CATEGORY;
        mbShowPopUp({
            title: STRING_CONSTANTS.ERROR,
            message: isKnownError ? error.message : STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN,
        });
    }, []);

    const onToggleFreeSwitch = () => {
        if (categoryId) {
            const isFree = !category?.isFree ?? false;
            changeCategoryPaymentType({ categoryId: categoryId, isFree: isFree }, {
                onError: onError,
            });
        }
    };

    const onToggleCategory = useCallback((isEnabled: boolean) => {
        if (isEditing) {
            toggleCategory({ categoryId, isEnabled }, { onError });
        }
    }, [categoryId, isEditing, onError, toggleCategory]);

    const onSave = useCallback(async () => {
        if (isEditing) {
            editCategory({
                categoryId,
                name,
                difficulty,
                tutorialURL,
                extras: extras.map(extra => extra._id),
                image: image?._id,
            }, {
                onSuccess: (updatedCategory) => {
                    setName(updatedCategory.name);
                    setTutorialURL(updatedCategory.tutorialURL ?? '');
                    mbShowToast({
                        text1: 'Success',
                        text2: 'Category Edited successfully',
                    });
                },
                onError,
            });
        } else if (name) {
            createCategory({
                name,
                gameType,
                difficulty: gameType === GAME_TYPE.Extras ? Object.values(DIFFICULTY) : difficulty,
                tutorialURL,
                image: image?._id,
            }, {
                onSuccess: (newCategory) => {
                    mbShowToast({
                        text1: 'Success',
                        text2: 'Category Created successfully',
                    });
                    navigation.navigate('ConstructCategory', { categoryId: newCategory._id, gameType });
                },
                onError,
            });
        }
    }, [isEditing, name, editCategory, categoryId, difficulty, tutorialURL, extras, image?._id, onError, createCategory, gameType, navigation]);

    const onDeleteQuestion = useCallback((question: QuestionShort) => {
        mbShowPopUp({
            title: 'Delete game question',
            message: `Are you sure you want to delete “${question.name}”? Deleting this question will erase all of its images and answers.`,
            buttonText: 'Delete question',
            buttonAction: () => {
                return new Promise((resolve, reject) => {
                    deleteQuestion({ questionId: question._id, categoryId: categoryId as string }, {
                        onSuccess: () => {
                            resolve();
                            mbHidePopUp();
                        },
                        onError: (error) => {
                            console.error('Error when deleting category: ', error);
                            reject(error.errorCode === SERVER_ERROR_CODES.VIDEO_IS_PROCESSING ? error.message : STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN);
                        },
                    });
                });
            },
            buttonStyle: {
                container: { backgroundColor: COLORS.errorColor },
            },
            containerStyle: {
                width: 454,
            },
            secondaryButtonText: 'Cancel',
            secondaryButtonStyle: {
                text: { color: COLORS.buttonPurple },
                container: { backgroundColor: COLORS.white },
            },
        });
    }, [categoryId, deleteQuestion]);

    const onDeleteExtra = useCallback((extrasName: string, index: number) => {
        mbShowPopUp({
            title: 'Delete Extras Video',
            message: `Are you sure you want to delete “${extrasName}”?`,
            buttonText: 'Delete Video',
            buttonAction: () => {
                setExtras(prev => prev.filter((_, filterIndex) => filterIndex !== index));
                mbHidePopUp();
            },
            buttonStyle: {
                container: { backgroundColor: COLORS.errorColor },
            },
            containerStyle: {
                width: 454,
            },
            secondaryButtonText: 'Cancel',
            secondaryButtonStyle: {
                text: { color: COLORS.buttonPurple },
                container: { backgroundColor: COLORS.white },
            },
        });
    }, []);


    const data = useMemo(() => {
        if (gameType === GAME_TYPE.Extras) {
            return extras?.map((extra, index) => [
                buildVideo(extra),
                <View style={styles.actions}>
                    <Feather
                        name="trash"
                        color={COLORS.deleteColor}
                        size={20}
                        onPress={() => onDeleteExtra(extra.name, index)}
                    />
                </View>,
            ]);
        }
        return questions?.map((question) => {
            return [
                buildVideo({ ...question.video, name: question.name, _id: question._id }),
                question.gameType === GAME_TYPE.IntroGame ? 'Intro Game' : question.gameType === GAME_TYPE.BodyParts ? 'Body Part' : question.gameType === GAME_TYPE.Geography ? 'Geography' : question.gameSubType,
                <LiveStatusBadge isEnabled={question.isEnabled} />,
                utils.getAnswer(question),
                <View style={styles.actions}>
                    <Feather
                        name="copy"
                        color={COLORS.white}
                        style={{ marginEnd: 18 }}
                        size={20}
                        onPress={() => categoryId && navigation.navigate('ConstructQuestion', { categoryId: categoryId, gameType, questionId: question._id, isCopyingQuestion: true })}
                    />
                    <Feather
                        name="edit"
                        color={COLORS.buttonPurple}
                        style={{ marginEnd: 18 }}
                        size={20}
                        onPress={() => categoryId && navigation.navigate('ConstructQuestion', { categoryId: categoryId, gameType, questionId: question._id })}
                    />
                    <Feather
                        name="trash"
                        color={COLORS.deleteColor}
                        size={20}
                        onPress={() => onDeleteQuestion(question)}
                    />
                </View>,
            ];
        });
    }, [categoryId, extras, gameType, navigation, onDeleteExtra, onDeleteQuestion, questions]);

    const onAddVideo = useCallback((video?: PoolVideo) => {
        if (video) {
            setExtras(prev => [...prev, video]);
        }
        setIsVideoSelectorVisible(false);
    }, []);

    const onFooterPress = useCallback(() => {
        if (gameType === GAME_TYPE.Extras) {
            setIsVideoSelectorVisible(true);
        } else if (categoryId) {
            navigation.navigate('ConstructQuestion', { categoryId: categoryId, gameType, questionId: undefined });
        }
    }, [categoryId, gameType, navigation]);

    const FooterComponent = useMemo(() => {
        return (
            <View style={styles.tableFooter}>
                <MB_Button
                    title={gameType === GAME_TYPE.Extras ? 'Add Extras' : 'New game question'}
                    style={styles.newGame}
                    leftElement={<Feather name="plus" color={COLORS.white} size={14} />}
                    onPress={onFooterPress}
                    disabled={!isEditing}
                />
            </View>
        );
    }, [gameType, isEditing, onFooterPress]);

    const isSaveButtonDisabled = useMemo(() => {

        const isExtras = gameType === GAME_TYPE.Extras;

        if (isExtras) {
            if (name === '') {
                return true;
            }
        } else if (name === '' || difficulty.length === 0 || (tutorialURL !== '' && !utils.isValidURL(tutorialURL))) {
            return true;
        }

        if (isEditing) {
            // check if categoryName is changed
            if (isExtras) {
                if (name === category?.name && isEqual(category?.extras, extras) && (typeof category?.image !== 'string' && image?._id === category?.image?._id)) {
                    return true;
                }
            } else if (name === category?.name && isEqual(category?.difficulty, difficulty) && tutorialURL === category?.tutorialURL && (typeof category?.image !== 'string' && image?._id === category?.image?._id)) {
                return true;
            }

            if (isEditCategoryLoading) {
                return true;
            }
        } else {
            if (isCreateCategoryLoading) {
                return true;
            }
        }
        return false;
    }, [gameType, name, difficulty, tutorialURL, isEditing, category?.name, category?.difficulty, category?.tutorialURL, category?.image, category?.extras, image?._id, isEditCategoryLoading, extras, isCreateCategoryLoading]);

    const keyExtractor = useCallback((index: number) => gameType === GAME_TYPE.Extras ? extras[index]._id : questions?.[index]._id ?? index.toString(), [extras, gameType, questions]);

    const onPressCheckbox = useCallback((newDifficulty: DIFFICULTY) => {
        setDifficulty((prevDifficulties) => {
            if (prevDifficulties.includes(newDifficulty)) {
                const newDifficulties = prevDifficulties.filter((prevDifficulty) => prevDifficulty !== newDifficulty).sort();
                return (newDifficulties.length > 0 || !isEditing) ? newDifficulties : prevDifficulties;
            } else {
                return [...prevDifficulties, newDifficulty].sort();
            }
        });
    }, [isEditing]);

    const header = useMemo(() => {
        const answerWidth = (gameType === GAME_TYPE.BodyParts || gameType === GAME_TYPE.Geography) ? 180 : 121;
        if (gameType === GAME_TYPE.Extras) {
            return [
                { content: 'Videos', conatinerStyle: { height: 112, minWidth: 450, maxWidth: 450, flex: 1, paddingLeft: 22 } },
                { content: '', conatinerStyle: { height: 112, minWidth: 140, maxWidth: 140 } },
            ];
        }
        return [
            { content: 'Game Question', conatinerStyle: { height: 112, minWidth: 343, maxWidth: 343, flex: 1, paddingLeft: 22 } },
            { content: 'Question Type', conatinerStyle: { height: 112, minWidth: 135, maxWidth: 135 } },
            { content: 'Status', conatinerStyle: { height: 112, minWidth: 127, maxWidth: 127 } },
            { content: (gameType === GAME_TYPE.BodyParts || gameType === GAME_TYPE.Geography) ? 'Answer' : 'Answers', conatinerStyle: { height: 112, minWidth: answerWidth, maxWidth: answerWidth }, numberOfLines: 1 },
            { content: '', conatinerStyle: { height: 112, minWidth: 140, maxWidth: 140 } },
        ];
    }, [gameType]);

    if (isEditing && isLoadingCategory) {
        return <LoadingPage />;
    }

    return (
        <ComponentWrapper
            containerStyle={styles.container}
            disableLinearGradient
            wrapInScrollView
            hideWebHeader
        >
            <AdminCreateOrEditHeader
                title={isEditing ? 'Edit Category' : 'Create New Category'}
                isEnabled={category?.isEnabled}
                disableToggle={isToggleCategoryLoading}
                onIsEnabledChange={onToggleCategory}
                rightButtonTitle={isEditing ? 'Save' : 'Create'}
                rightButtonDisabled={isSaveButtonDisabled}
                rightButtonIsLoading={isCreateCategoryLoading || isEditCategoryLoading}
                onRightButtonPress={onSave}
                onBackPress={onGoBack}
            />
            <View style={styles.body}>
                <View style={styles.leftSide}>
                    <MB_TextInput
                        title="Category name*"
                        placeholder="Enter the category name"
                        style={styles.textInput}
                        value={name}
                        onChangeText={setName}
                        maxLength={20}
                    />
                    {gameType !== GAME_TYPE.Extras &&
                        <React.Fragment>
                            <View style={styles.leftSideSection}>
                                <Text style={[textStyles.smallText, { textAlign: 'left' }]}>Select difficulty level*</Text>
                                {Object.values(DIFFICULTY).map(currentDifficulty => (
                                    <View key={currentDifficulty} style={styles.checkBox}>
                                        <Checkbox isChecked={difficulty.includes(currentDifficulty)} onPress={() => onPressCheckbox(currentDifficulty)} checkboxStyleChecked={{ color: COLORS.buttonPurple }} />
                                        <Text style={[textStyles.normalText, { textAlign: 'left', marginStart: 8, textTransform: 'capitalize' }]}>{utils.difficultyToText(currentDifficulty)}</Text>
                                    </View>
                                ))}
                            </View>
                            <View style={styles.freeSwitchContainer}>
                                <MB_ToggleSwitch
                                    isToggled={category?.isFree ?? false}
                                    onToggle={onToggleFreeSwitch}
                                    isDisabled={isCategoryPaymentStateLoading}
                                />
                                <Text style={styles.freeSwitchText}>{category?.isFree ? 'Free' : 'Not Free'}</Text>
                            </View>
                            <View style={styles.freeSwitchContainer}>
                                <MB_TextInput
                                    title="Tutorial URL"
                                    placeholder="Enter the tutorial url"
                                    style={styles.textInput}
                                    value={tutorialURL}
                                    onChangeText={setTutorialURL}
                                    isError={tutorialURL !== '' && !utils.isValidURL(tutorialURL)}
                                />
                            </View>
                        </React.Fragment>
                    }
                    <View style={styles.mediaChanger}>
                        <Text style={[textStyles.smallText, { textAlign: 'left' }]}>Select Category Image</Text>
                        <MediaChanger
                            type="image"
                            source={image}
                            onMediaChange={setImage}
                            hideChangeButton={image === undefined}
                        />
                    </View>
                </View>
                <ImageVideoSelectorModal
                    isVisible={isVideoSelectorVisible}
                    resourcePoolType={RESOURCE_POOL_TYPE.Video}
                    onDismiss={onAddVideo}
                />
                <View style={styles.rightSide}>
                    <MB_Table
                        keyExtractor={keyExtractor}
                        header={header}
                        headerStyle={tableStyles.tableHeader}
                        data={data}
                        style={tableStyles.tableStyle}
                        rowStyle={tableStyles.rowStyle}
                        doNotRenderEmptyData
                        FooterComponent={FooterComponent}
                        // Note: isQuestionsLoading is set true when useGetQuestions not enabled which results in loading ui being shown when just creating category.
                        loading={isEditing && isQuestionsLoading}
                    />
                </View>
            </View>
        </ComponentWrapper>
    );
};

export { ConstructCategory };

const styles = StyleSheet.create({
    container: {
        minWidth: 1100,
    },
    newGame: {
        width: 230,
        height: 38,
        borderRadius: 10,
        backgroundColor: COLORS.buttonPurple,
    },
    row: {
        flexDirection: 'row',
    },
    body: {
        marginTop: 18,
        flexDirection: 'row',
        marginBottom: 161,
    },
    leftSide: {
        marginEnd: 48,
        width: 319,
    },
    rightSide: {
        flex: 1,
    },
    textInput: {
        width: '100%',
        backgroundColor: COLORS.backgroundPurple,
    },
    gameQuestion: {
        flexDirection: 'row',
        alignItems: 'center',
        width: 321,
    },
    videoPlayer: {
        width: 100,
        height: 84,
        marginEnd: 40,
        backgroundColor: COLORS.black,
        borderRadius: 10,
    },
    videoPlayerProccessing: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    playIconWrapper: {
        borderRadius: 300,
        width: 40,
        height: 40,
        backgroundColor: mbApplyTransparency(COLORS.backgroundPurple, 0.3),
        alignItems: 'center',
        justifyContent: 'center',
    },
    actions: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        width: '100%',
        paddingRight: 35,
    },
    tableFooter: {
        height: 112,
        backgroundColor: mbApplyTransparency(COLORS.cardPurple, 0.3),
        alignItems: 'center',
        justifyContent: 'center',
        // minWidth: 826,
    },
    freeSwitchContainer: {
        flexDirection: 'row',
        marginTop: 30,
    },
    freeSwitchText: mbTextStyles([
        textStyles.smallText, {
            marginStart: 8,
            color: COLORS.white,
        },
    ]),
    mediaChanger: {
        marginTop: 30,
    },
    checkBox: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 16,
    },
    leftSideSection: {
        marginTop: 33,
    },
});
