import React from 'react';
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native';
import { mbPlatformStyle, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../../constants/textStyles';
import { COLORS } from '../../../../constants/colors';

const SelectedText = ({ text, containerStyle }: { text: string, containerStyle?: StyleProp<ViewStyle> }) => {

    return (
        <View style={[styles.container, containerStyle, !text && {backgroundColor: 'transparent'}]}>
            <View style={[styles.innerContainer, !text && {backgroundColor: 'transparent'}]}>
                {text !== '' &&
                    <Text style={styles.bodyPartText}>{text}</Text>
                }
            </View>
        </View>
    );
};

export { SelectedText };

const styles = StyleSheet.create({
    container: {
        borderRadius: 5,
        backgroundColor: COLORS.buttonPurple,
        paddingHorizontal: 3,
        paddingTop: 3,
        paddingBottom: 6,
        alignSelf: 'center',
        ...mbPlatformStyle({
            web: {
                marginVertical: 10,
            },
            mobile: {
                marginVertical: 32,
            },
        }),
    },
    innerContainer: {
        borderRadius: 5,
        backgroundColor: COLORS.white,
        height: 37,
        justifyContent: 'center',
        paddingHorizontal: 24,
    },
    bodyPartText: mbTextStyles([
        textStyles.largerText, {
            fontSize: 18,
            color: '#412366',
        },
    ]),
});
