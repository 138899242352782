import { RouteProp, useNavigation, useRoute } from '@react-navigation/core';
import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { RoomsNavigatorParamList } from '../../navigations/RoomsNavigator/RoomsNavigator';
import { GameNavigatorParamList } from '../../navigations/GameNavigator';
import { textStyles } from '../../constants/textStyles';
import { UniversalScreenNavigationProp } from '../../typesAndInterfaces/componentProps';

const GameModeSwitch = () => {

    const { name } = useRoute<RouteProp<GameNavigatorParamList | RoomsNavigatorParamList>>();
    const navigation = useNavigation<UniversalScreenNavigationProp>();

    return (
        <View style={styles.container}>
            <TouchableOpacity
                style={[styles.mode, name === 'SelectGame' && styles.active]}
                onPress={() => navigation.navigate('HomeNavigator', { screen: 'GameNavigator', params: { screen: 'SelectGame' } })}
            >
                <Text style={styles.text}>Games</Text>
            </TouchableOpacity>
            <View style={styles.spacer} />
            <TouchableOpacity
                style={[styles.mode, name === 'RoomCreateOrJoin' && styles.active]}
                onPress={() => navigation.navigate('HomeNavigator', { screen: 'RoomsNavigator', params: { screen: 'RoomCreateOrJoin' } })}
            >
                <Text style={styles.text}>Rooms</Text>
            </TouchableOpacity>
            <View style={styles.spacer} />
            <TouchableOpacity
                style={[styles.mode, name === 'ExtrasHome' && styles.active]}
                onPress={() => navigation.navigate('HomeNavigator', { screen: 'ExtrasNavigator', params: { screen: 'ExtrasHome' } })}
            >
                <Text style={styles.text}>Extras</Text>
            </TouchableOpacity>
        </View>
    );
};


export { GameModeSwitch };

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        backgroundColor: '#3F285B',
        padding: 3,
        borderRadius: 50,
    },
    spacer: {
        width: 4,
    },
    mode: {
        height: 38,
        width: 86,
        borderRadius: 50,
        alignItems: 'center',
        justifyContent: 'center',
    },
    active: {
        backgroundColor: '#A430FF',
    },
    text: {
        ...textStyles.smallText,
    },
});
