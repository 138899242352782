import React from 'react';
import { mbApplyTransparency, mbPlatformStyle, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { Platform, StyleSheet, Text, TextInput, View } from 'react-native';
import { COLORS } from '../../../constants/colors';
import { textStyles } from '../../../constants/textStyles';
import { emitFocus, onChange, onlyAndroid } from './utils';

interface MoneyInputProps {
    dollars?: string,
    cents?: string,
    onSelectedDollarAmount: React.Dispatch<React.SetStateAction<string>>;
    onSelectedCentsAmount: React.Dispatch<React.SetStateAction<string>>;
}

export default function MoneyInput({ dollars, cents, onSelectedDollarAmount, onSelectedCentsAmount }: MoneyInputProps) {

    return (
        <View style={styles.moneyWheelMainContainer}>
            <Text style={styles.moneySign}>$</Text>
            <View style={styles.wheelContainer}>
                <TextInput
                    value={dollars}
                    placeholder={Platform.OS === 'web' ? 'Enter Dollars' : 'Dollars'}
                    placeholderTextColor={mbApplyTransparency('#FFFFFF', 0.45)}
                    style={styles.input}
                    returnKeyType="done"
                    keyboardType="number-pad"
                    onChangeText={onChange(onSelectedDollarAmount)}
                    maxLength={2}
                    onPressIn={onlyAndroid(emitFocus)}
                />
            </View>
            <Text style={styles.moneySign}>.</Text>
            <View style={styles.wheelContainer}>
                <TextInput
                    value={cents}
                    placeholder={Platform.OS === 'web' ? 'Enter Cents' : 'Cents'}
                    style={styles.input}
                    returnKeyType="done"
                    keyboardType="number-pad"
                    placeholderTextColor={mbApplyTransparency('#FFFFFF', 0.45)}
                    onChangeText={onChange(onSelectedCentsAmount)}
                    maxLength={2}
                    onPressIn={onlyAndroid(emitFocus)}
                />
            </View>
        </View>
    );
}


const styles = StyleSheet.create({
    text: {
        fontSize: 20,
        textAlign: 'center',
        fontWeight: 'bold',
        color: COLORS.white,
    },
    moneySign: mbTextStyles([
        textStyles.largerText, {
            color: COLORS.textLightPurple,
        }, {
            alignSelf: 'center',
        },
    ]),
    moneyWheelContainer: {
        marginHorizontal: 20,
        ...mbPlatformStyle({
            mobile: {
                flex: 1,
            },
        }),
    },
    border: {
        borderWidth: 2,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderRadius: 0,
        borderColor: COLORS.cardPurple,
        backgroundColor: 'transparent',
    },
    moneyWheelMainContainer: {
        flexDirection: 'row',
    },
    wheelContainer: {
        ...mbPlatformStyle({
            web: {
                alignItems: 'center',
            },
            mobile: {
                flexDirection: 'row',
                flex: 1,
            },
        }),
    },
    itemStyle: {
        paddingHorizontal: 16,
    },
    input: mbTextStyles([textStyles.smallText, {
        backgroundColor: '#412366',
        padding: 8,
        borderRadius: 7,
        color: '#FFFFFF',
        marginHorizontal: 10,
        ...mbPlatformStyle({
            mobile: {
                flexGrow: 1,
            },
            web: {
                width: 113,
            },
        }),
    }]),
});

