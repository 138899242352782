import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

const ExtrasIcon = ({ color = '#fff', size = 24, style }: { color?: string, size?: number, style?: StyleProp<ViewStyle> }) => {
    return (
        <Svg
            width={size}
            height={size * (21 / 24)}
            viewBox="0 0 24 21"
            fill="none"
            style={style}
        >
            <Path
                d="M2.469 15.694l.06-10.727H1.32v13.649c1.663-.43 3.333-.703 5.015-.715 1.553-.01 3.105.207 4.662.734a9.955 9.955 0 00-3.195-1.734c-1.45-.473-3.016-.63-4.621-.492a.66.66 0 01-.711-.715zm17.77-11.287a.567.567 0 010-.2V2.036c-1.524-.145-3.095.023-4.466.542-1.28.487-2.386 1.286-3.105 2.43V17.38c1.117-.746 2.26-1.36 3.434-1.777 1.337-.477 2.712-.703 4.136-.59V4.407zm1.318-.758h1.785c.363 0 .658.295.658.658v15.168a.658.658 0 01-.871.623c-1.836-.525-3.66-.875-5.467-.887-1.762-.011-3.521.3-5.289 1.078a.663.663 0 01-.375.11.672.672 0 01-.375-.11c-1.766-.78-3.527-1.09-5.289-1.078-1.807.012-3.629.362-5.467.887A.655.655 0 010 19.477V4.307c0-.363.295-.658.658-.658h1.877l.012-2.2A.657.657 0 013.07.81c1.733-.361 3.838-.272 5.684.436 1.275.488 2.434 1.267 3.28 2.392.853-1.049 1.993-1.803 3.276-2.289 1.754-.666 3.778-.826 5.682-.547.328.047.563.33.563.65V3.65h.002zm-8.25 14.886a14.173 14.173 0 014.361-.636c1.682.01 3.352.285 5.016.715V4.967h-1.127v10.785a.658.658 0 01-.81.64c-1.442-.226-2.839-.038-4.202.448-1.098.393-2.176.979-3.238 1.695zm-1.955-1.283V4.99c-.69-1.217-1.805-2.032-3.065-2.514-1.428-.547-3.031-.67-4.43-.47l-.074 13.048a12.732 12.732 0 014.422.598c1.117.363 2.176.896 3.147 1.601z"
                fill={color}
            />
        </Svg>
    );
};

export { ExtrasIcon };
