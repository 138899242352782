
import { PoolVideo } from '../typesAndInterfaces/typesAndInterfaces';
import { axiosCaller } from './customAxios';
import { z } from 'zod';
import { PoolVideoObj, PoolVideoObjInfinitePagination } from '../utils/zod/zodObjects';
import { MB_InfinitePaginationPage } from '@mightybyte/rnw.utils.util-hooks';

const videoPoolApiCalls = {
    uploadVideo: async ({ formData, uploadProgress }: { formData: FormData, uploadProgress?: (percentCompleted: number) => void }): Promise<PoolVideo> => {
        return await axiosCaller({
            path: '/api/videoPool/uploadVideo',
            method: 'post',
            passToken: true,
            data: formData,
            responseValidatorObj: PoolVideoObj,
            responseFormatValidatorMessage: 'Data mismatch for upload pool video response',
            responseDataKey: 'poolVideo',
            onUploadProgress: (progressEvent) => {
                const percentCompleted = !progressEvent.total ? 0 : Math.round((progressEvent.loaded * 100) / progressEvent.total);
                uploadProgress?.(percentCompleted);
            },
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },

    getVideoPoolVideos: async (totalItemsPerPage?: number, offset?: string, searchText?: string, folderId?: string): Promise<MB_InfinitePaginationPage<PoolVideo>> => {
        return await axiosCaller({
            path: '/api/videoPool/getVideos',
            method: 'get',
            responseValidatorObj: PoolVideoObjInfinitePagination,
            responseFormatValidatorMessage: 'Data mismatch for get video pool videos response',
            passToken: true,
            params: { totalItemsPerPage, offset, searchText, folderId },
        });
    },

    deleteVideo: async (videoId: string): Promise<string> => {
        return await axiosCaller({
            path: '/api/videoPool/deleteVideo',
            responseValidatorObj: z.string(),
            responseFormatValidatorMessage: 'Data mismatch for delete video pool video response',
            responseDataKey: 'videoId',
            method: 'post',
            passToken: true,
            params: { videoId },
        });
    },
};

export { videoPoolApiCalls };
