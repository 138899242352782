import React, { useCallback, useState } from 'react';
import { Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { BodyPartsSvgConfig, bodyPartsSvgConfigs, BODY_PARTS } from './bodyPartsConstants';
import { BodyPartsSvgPresenter } from './BodyPartsSvgPresenter';
import { MB_utilHooks } from '@mightybyte/rnw.utils.util-hooks';
import { MB_LinearGradient } from '@mightybyte/rnw.components.linear-gradient';
import { mbApplyTransparency, mbPlatformStyle, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../../constants/textStyles';
import { COLORS } from '../../../../constants/colors';
import { BodyTypesSVG_fullBodyFront } from '../../../../resources/svgComponents/bodyParts/BodyTypesSVG_fullBodyFront';
import { MB_ModalMobileFakeScreen } from '@mightybyte/rnw.components.modal';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { GAME_TYPE, Question } from '../../../../typesAndInterfaces/typesAndInterfaces';
import { mbShowToast } from '@mightybyte/rnw.components.toast';
import { envs } from '../../../../../env';
import { utils } from '../../../../utils/utils';
import { BodyPartsSelectedText } from './BodyPartsSelectedText';
import { isMobile } from '@mightybyte/rnw.utils.device-info';
import { initialWindowMetrics } from 'react-native-safe-area-context';

// export interface BodyPartsControlsBaseRef {
//     closeControls: () => void,
// }

interface BodyPartsControlsBaseProps {
    initialSelected?: BODY_PARTS,
    onAnswerIsCorrect?: (isCorrect: boolean) => void,
    onBodyPartChange?: (bodyPart: BODY_PARTS) => void
    isForAdminPanel?: boolean,
    question?: Question,
    showCloseButton?: boolean,
    //bodyPartsControlsBaseRef?: React.RefObject<BodyPartsControlsBaseRef>,
}

const BodyPartsControlsBase = ({ initialSelected, onAnswerIsCorrect, onBodyPartChange, isForAdminPanel, question, showCloseButton }: BodyPartsControlsBaseProps) => {
    const [bodyPartSvgConfig, setBodyPartSvgConfig] = useState<BodyPartsSvgConfig[]>([bodyPartsSvgConfigs.fullBodyFront]);
    const [bodyPartSelected, setBodyPartSelected] = useState<BODY_PARTS | undefined>();
    const [isShowingControls, showControls, hideControls] = MB_utilHooks.useBool();

    // useImperativeHandle(bodyPartsControlsBaseRef, () => ({
    //     closeControls: () => {
    //         hideControls();
    //     },
    // }));

    const onCheckAnswer = useCallback(() => {
        if (!bodyPartSelected || question?.gameType !== GAME_TYPE.BodyParts) {
            return;
        }

        const isCorrect = question.gameType === GAME_TYPE.BodyParts && question.bodyPart === bodyPartSelected;

        if (isCorrect) {
            setTimeout(hideControls, 1000); // navigation to anouther screen take 2000
            onAnswerIsCorrect?.(isCorrect);
        }

        mbShowToast({
            ...utils.gameFeedbackToastParams(isCorrect),
            text2: envs.FLAVOR === 'prod' ? undefined : question.bodyPart,
        });

    }, [bodyPartSelected, hideControls, onAnswerIsCorrect, question]);

    const onBodyPartPressed = useCallback((tag: BODY_PARTS, selectedOrZoom: 'selected' | 'zoom') => {
        const config = bodyPartsSvgConfigs[tag];

        if (!config.select) {
            return;
        }

        if (config.enlarge || selectedOrZoom === 'selected') {
            setBodyPartSelected(tag);
        }

        if (selectedOrZoom === 'zoom') {
            setTimeout(() => {
                setBodyPartSvgConfig(old => [...old, config]);
            }, Platform.OS === 'ios' ? 150 : 0);
        }

    }, []);

    const onBodyBackPressed = useCallback(() => {
        setBodyPartSvgConfig(old => {
            if (old.length < 1) {
                return old;
            }
            return old.slice(0, old.length - 1);
        });
    }, []);

    if (!isShowingControls) {
        return (
            <MB_LinearGradient
                colors={[COLORS.gradientDarkPurple, COLORS.gradientLightPurple]}
                start={{ x: 0, y: 0 }} end={{ x: 1, y: 1 }}
                style={styles.gradientContainer}
            >
                <TouchableOpacity onPress={showControls} style={{ flex: 1 }}>
                    <View style={{ flex: 1 }} pointerEvents="none">
                        <BodyTypesSVG_fullBodyFront
                            onPress={showControls}
                            containerStyle={{ backgroundColor: COLORS.backgroundDarkPurple, flex: 1, aspectRatio: 144 / 225, padding: 10, borderRadius: 15 }}
                            highlightedParts={initialSelected ? [initialSelected] : []}
                        />
                    </View>
                </TouchableOpacity>
                <Text style={styles.showControlsText}>Tap image to expand and choose your answer</Text>
            </MB_LinearGradient>
        );
    }

    function onSelectedAction(bodyPart: BODY_PARTS | undefined) {
        if (bodyPart) {
            if (isForAdminPanel) {
                onBodyPartChange?.(bodyPart);
                hideControls();
            } else {
                onCheckAnswer();
            }
        }
    }

    return (
        <MB_ModalMobileFakeScreen
            isVisible={isShowingControls}
            onDismiss={hideControls}
            childrenWrapper={styles.modalWrapper}
            showCloseButton={isForAdminPanel || showCloseButton}
            closeButtonProps={{
                style: {
                    top: initialWindowMetrics?.insets.top || 32,
                },
            }}
        >
            <>
                {envs.FLAVOR !== 'prod' && !isForAdminPanel && question !== undefined &&
                    <View style={{ position: 'absolute', backgroundColor: '#ffffff3a', padding: 4, zIndex: 1, top: 24 }}>
                        <Text style={[textStyles.smallerText, { textAlign: 'left', fontWeight: '800' }]}>DEBUG</Text>
                        <Text style={[textStyles.smallerText, { textAlign: 'left' }]}>ans: {question.gameType === GAME_TYPE.BodyParts ? question.bodyPart : ''}</Text>
                    </View>
                }
            </>
            <BodyPartsSvgPresenter
                svgConfig={bodyPartSvgConfig[bodyPartSvgConfig.length - 1]}
                onPress={onBodyPartPressed}
                bodyPartSelected={bodyPartSelected}
                onBackPressed={onBodyBackPressed}
                containerStyle={styles.bodyPartsPresenterContainer}
                isForAdminPanel={isForAdminPanel}
                onSelectPressed={onSelectedAction}
            />
            <BodyPartsSelectedText bodyPart={bodyPartSelected} />
            <MB_Button
                title={isForAdminPanel ? 'Select' : 'Check answer'}
                style={styles.checkAnaswerButton}
                disabled={bodyPartSelected === undefined}
                onPress={() => onSelectedAction(bodyPartSelected)}
            />
        </MB_ModalMobileFakeScreen>
    );
};

const BodyPartsControls = ({ showCloseButton, question, onAnswerPressed }: { showCloseButton?: boolean, question: Question, onAnswerPressed: (isCorrect: boolean) => void }) => {

    return (
        <BodyPartsControlsBase
            onAnswerIsCorrect={onAnswerPressed}
            question={question}
            showCloseButton={showCloseButton}
        // bodyPartsControlsBaseRef={bodyPartsControlsBaseRef}
        />
    );
};

export { BodyPartsControls, BodyPartsControlsBase };

const styles = StyleSheet.create({
    gradientContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        padding: 16,
        borderRadius: Platform.OS === 'web' ? 16 : undefined,
        marginTop: Platform.OS === 'web' ? -12 : undefined, // Hiding the border bottom from video,
        ...mbPlatformStyle({
            mobile: {
                flex: 1,
            },
            web: {
                height: 400,
            },
        }),
    },
    showControlsText: mbTextStyles([
        textStyles.smallText, {
            fontWeight: '500',
            marginTop: 16,
        },
    ]),
    modalWrapper: {
        backgroundColor: COLORS.backgroundDarkPurple,
        padding: 32,
        justifyContent: 'center',
    },
    checkAnaswerButton: {
        backgroundColor: COLORS.buttonPurple,
        alignSelf: 'center',
        paddingHorizontal: 40,
    },
    bodyPartsPresenterContainer: {
        flex: 1,
        padding: 8,

    },
    closeButton: {
        borderRadius: 6,
        width: 32,
        height: 32,
        paddingHorizontal: 0,
        backgroundColor: mbApplyTransparency(COLORS.white, 0.05),
        alignSelf: 'flex-end',
        marginRight: isMobile ? -10 : -68,
    },
});
