import * as React from 'react';
import Svg, {
  G,
  Path,
  Circle,
  Defs,
  LinearGradient,
  Stop,
  RadialGradient,
  ClipPath,
  Ellipse,
} from 'react-native-svg';
import { BODY_PARTS, BodyPartsSvgProps } from '../../../components/screens/Game/bodyPartsControls/bodyPartsConstants';
import { BODY_PARTS_SVG_BUTTON_TYPES, BodyPartSvgButton } from '../../../components/helperComponents/BodyPartSvgButton';
import { isMobile } from '@mightybyte/rnw.utils.device-info';
/* SVGR has dropped some elements not supported by react-native-svg: filter */


function BodyTypesSVG_face({ onPress, highlightedParts }: BodyPartsSvgProps) {
  const [active, setActive] = React.useState<BODY_PARTS | undefined>();

  return (
    <Svg
      style={{ width: '100%', height: '100%' }}
      viewBox="0 0 289 308"
      fill="none"
    >
      <G id="Group 27713">
        <G id="Face">
          <G id="pressable_neck">
            <G id="Neck">
              <Path
                id="Vector"
                d="M162.425 187.064c-.146-.278-.695-.642-.964-1.191-.888-1.019-.438-9.924-.825-15.342-.63-3.519-8.724-3.305-11.476-3.548-3.625 0-15.508-.378-16.07 4.343-.369 5.011 1.026 12.548-.294 14.122l-1.509 1.734 16.606-.04 7.483-.127 7.049.049z"
                fill="#915548"
              />
              <Path
                id="Vector_2"
                d="M159.391 187.051h-7.329l-12.344.101-8.462-.035c5.381-.897 2.149-8.596 3.094-15.733.174-4.197 24.686-3.877 24.977-.727.194 2.229 0 8.481.582 13.124.634 3.484.825 3.269.825 3.269l-1.343.001z"
                fill="url(#paint0_linear_5325_12254)"
              />
              <Path
                id="Vector_3"
                style={{
                  mixBlendMode: 'darken',
                }}
                d="M160.55 185.98c-2.963 1.425-6.513.437-9.801.421-2.471 0-7.002.406-7.002.406h-5.505l-3.403-.233-2.919.127s3.533 1.397 4.148-15.356c.175-4.197 23.308-3.36 23.598-.209.194 2.229.351 10.201.884 14.844z"
                fill="url(#paint1_linear_5325_12254)"
              />
            </G>
            <Path
              id="highlight_neck"
              d="M159.714 168.641c-7.318-2.989-16.318-2.945-24.571-.121-1.543.528-2.51 2.027-2.458 3.657.196 6.126.641 11.424-.378 14.256h29.47c-.764-5.83-.983-10.471-.892-15.959.014-.795-.435-1.532-1.171-1.833z"
              fill="#A430FF"
              fillOpacity={0.25}
              stroke="#A430FF"
              strokeWidth={1.5}
              opacity={highlightedParts.includes(BODY_PARTS.neck) ? 1 : 0}
            />
          </G>
          <G id="-">
            <G id="pressable_leftEar">
              <G id="Left Ear">
                <Path
                  id="Vector_4"
                  d="M110.144 123.055c-1.837-3.788-4.799-6.497-9.184-5.258-5.349 1.653-4.04 8.518-3.971 12.788a18.366 18.366 0 003.903 11.296 10.42 10.42 0 0011.8 4.89c-.597-7.99-1.171-15.796-2.548-23.716z"
                  fill="#915548"
                />
                <Path
                  id="Vector_5"
                  d="M111.521 145.852c-5.9.987-8.495-3.076-10.768-6.405a20.25 20.25 0 01-2.594-10.859 20.202 20.202 0 01.413-6.888 3.795 3.795 0 012.985-2.778 5.53 5.53 0 014.591.804c.156.102.302.217.437.344a13.154 13.154 0 012.64 3.237c.207 1.263.436 2.824.643 4.592v.551c.505 3.903.987 8.655 1.354 13.247.115 1.469.299 2.847.299 4.155z"
                  fill="url(#paint2_linear_5325_12254)"
                />
                <Path
                  id="Vector_6"
                  style={{
                    mixBlendMode: 'darken',
                  }}
                  d="M111.521 145.853c-5.9.987-8.495-3.077-10.768-6.406a20.25 20.25 0 01-2.594-10.859 20.204 20.204 0 01.413-6.888c-.145 2.24.072 4.488.643 6.658.436 1.745 1.125-.826 1.401-2.709.275-1.882.688-3.214 2.158-3.627a2.295 2.295 0 012.915 1.056c.781.964.896-1.538.505-3.352.156.102.302.217.437.344a65.328 65.328 0 013.168 7.829.653.653 0 010 .207c.012.114.012.23 0 .344.138 1.768 0 5.074 0 5.395 0 .322-1.033-1.102-1.469-1.79-.437-.689-1.653 0-2.296.551a2.935 2.935 0 00-.345 3.329c.712 1.331 1.263 2.433 2.434 2.846 1.171.414 2.571.988 1.814 1.607-.758.62-1.676 1.608-2.296.345-.62-1.263-1.332-1.791-2.021-1.952a5.475 5.475 0 01-2.433-3.742 9.462 9.462 0 012.296-5.625c1.056-.918-.781-.918-1.952 0-1.171.919-1.676-.298-1.607-1.331.069-1.033-.964 1.24-1.102 3.053a12.037 12.037 0 004.73 9.597c-1.607 0-4.317-2.64-5.212-5.074-.895-2.433-.436.85.276 2.296.711 1.446 3.329 5.648 5.418 6.543a3.36 3.36 0 002.965.036 3.346 3.346 0 001.833-2.332c0-.344.23-.459.482-.367.023 1.331.207 2.709.207 4.018z"
                  fill="url(#paint3_linear_5325_12254)"
                />
                <Path
                  id="Vector_7"
                  d="M109.018 130.194l-.229.161a32.813 32.813 0 00-.849-1.814 29.624 29.624 0 00-2.044-3.489 16.736 16.736 0 00-1.171-1.607 2.285 2.285 0 00-1.607-.827 1.562 1.562 0 00-1.377 1.056 6.178 6.178 0 00-.551 1.952 6.252 6.252 0 01.321-2.044c.13-.335.316-.646.551-.918a1.515 1.515 0 011.056-.436 2.498 2.498 0 011.952.872c.48.509.91 1.063 1.285 1.653a21.167 21.167 0 011.975 3.605c.267.605.497 1.226.688 1.859v-.023z"
                  fill="#915548"
                />
                <Path
                  id="Vector_8"
                  d="M109.846 140.894a1.26 1.26 0 01-1.171.551 2.11 2.11 0 01-1.607-1.469.922.922 0 00-.213-.306.92.92 0 00-.315-.199c-.252 0-.574-.161-.918-.299a4.253 4.253 0 01-2.434-3.168 6.455 6.455 0 01.666-3.88 14.12 14.12 0 012.296-3.122 13.36 13.36 0 00-2.043 3.26 6.081 6.081 0 00-.437 3.65 3.84 3.84 0 002.158 2.686c.276.115.597.161.942.299.374.148.683.425.872.78.115.267.27.515.459.735.15.184.363.307.597.344.505 0 .735-.344.505-.803-.229-.459-.826-.62-1.423-.942a5.484 5.484 0 01-2.525-3.122 4.585 4.585 0 01-.207-1.997 2.637 2.637 0 011.01-1.745c.872-.643 1.791-1.171 2.686-1.745l.23-.138-.23.161-1.01.735c-.505.39-1.056.757-1.515 1.171a2.913 2.913 0 00-.528 3.374 5 5 0 002.296 2.709c.689.2 1.293.619 1.721 1.194a1.282 1.282 0 01.138 1.286z"
                  fill="#915548"
                />
                <Path
                  id="Vector_9"
                  d="M103.692 125.466a10.172 10.172 0 00-.483 1.561l-.206.758a2.82 2.82 0 000 .344c.115 0 .298 0 .528-.229l.666-.482c.436-.322.895-.62 1.354-.896-.367.414-.757.781-1.148 1.148l-.597.551c-.108.11-.232.203-.367.276a.671.671 0 01-.551 0 .531.531 0 01-.413-.505.942.942 0 010-.483c.071-.267.163-.528.275-.78.276-.448.591-.871.942-1.263z"
                  fill="#915548"
                />
                <Path
                  id="Vector_10"
                  d="M102.589 125.029a38.823 38.823 0 00-1.4 4.064 10.15 10.15 0 00-.344 4.155 10.733 10.733 0 001.262 3.972 11.09 11.09 0 002.686 3.26 9.694 9.694 0 01-3.053-3.053 10.497 10.497 0 01-1.446-4.11 10.602 10.602 0 01.482-4.339 23.69 23.69 0 011.813-3.949z"
                  fill="#915548"
                />
              </G>
              <Path
                id="highlight_leftEar"
                d="M110.574 123.624l2.553 23.371c-4.927.161-9.021.615-12.269-4.371-2.775-4.213-4.372-6.727-4.372-14 0-3.5-.229-4 .486-6.5 2.429-8.5 12.145-5 13.602 1.5z"
                fill="#A430FF"
                fillOpacity={0.25}
                stroke="#A430FF"
                opacity={highlightedParts.includes(BODY_PARTS.ear) ? 1 : 0}
              />
            </G>
            <G id="pressable_rightEar">
              <G id="right Ear">
                <Path
                  id="Vector_11"
                  d="M183.234 123.121c1.836-3.833 4.797-6.575 9.181-5.32 5.349 1.673 4.04 8.619 3.971 12.941a18.723 18.723 0 01-3.902 11.43 10.458 10.458 0 01-5.062 4.518 10.302 10.302 0 01-6.736.43c.597-8.084 1.171-15.984 2.548-23.999z"
                  fill="#915548"
                />
                <Path
                  id="Vector_12"
                  d="M181.857 146.191c5.899.999 8.493-3.113 10.765-6.482a20.688 20.688 0 002.594-10.989c.258-2.33.118-4.687-.413-6.97a3.845 3.845 0 00-1.087-1.846 3.77 3.77 0 00-1.897-.965 5.497 5.497 0 00-4.591.813c-.155.104-.301.22-.436.349a13.27 13.27 0 00-2.64 3.276 104.156 104.156 0 00-.642 4.646v.558c-.505 3.949-.987 8.758-1.355 13.405-.114 1.487-.298 2.881-.298 4.205z"
                  fill="url(#paint4_linear_5325_12254)"
                />
                <Path
                  id="Vector_13"
                  style={{
                    mixBlendMode: 'darken',
                  }}
                  d="M181.857 146.191c5.899.999 8.493-3.113 10.765-6.481a20.693 20.693 0 002.594-10.989c.258-2.33.118-4.688-.413-6.97a21.356 21.356 0 01-.643 6.737c-.436 1.766-1.125-.836-1.4-2.741-.276-1.905-.689-3.253-2.158-3.671a2.27 2.27 0 00-1.663-.039 2.306 2.306 0 00-1.252 1.108c-.78.976-.895-1.557-.505-3.392-.155.103-.301.22-.436.348a66.306 66.306 0 00-3.167 7.923.666.666 0 000 .209 1.557 1.557 0 000 .348c-.138 1.789 0 5.135 0 5.46 0 .325 1.032-1.115 1.469-1.812.436-.697 1.652 0 2.295.557a3.001 3.001 0 01.344 3.369c-.711 1.347-1.262 2.463-2.433 2.881-1.171.418-2.571.999-1.813 1.626.757.627 1.675 1.626 2.295.349.62-1.278 1.331-1.813 2.02-1.975a5.53 5.53 0 001.614-1.632 5.6 5.6 0 00.819-2.155 9.638 9.638 0 00-2.295-5.692c-1.056-.929.78-.929 1.951 0 1.17.929 1.675-.302 1.607-1.348-.069-1.045.964 1.255 1.101 3.09a12.274 12.274 0 01-1.239 5.417 12.14 12.14 0 01-3.489 4.295c1.607 0 4.315-2.672 5.21-5.135.896-2.462.436.86-.275 2.323-.712 1.464-3.328 5.716-5.417 6.622a3.338 3.338 0 01-1.478.368 3.332 3.332 0 01-2.675-1.296 3.4 3.4 0 01-.644-1.396c0-.348-.23-.464-.482-.371-.023 1.347-.207 2.741-.207 4.065z"
                  fill="url(#paint5_linear_5325_12254)"
                />
                <Path
                  id="Vector_14"
                  d="M184.358 130.346l.23.163c.252-.604.55-1.232.849-1.836a29.848 29.848 0 012.043-3.531c.358-.565.749-1.108 1.17-1.626a2.31 2.31 0 01.719-.585c.276-.144.578-.23.888-.252a1.559 1.559 0 011.377 1.069c.29.623.476 1.29.551 1.975a6.39 6.39 0 00-.321-2.068 3.186 3.186 0 00-.551-.929 1.508 1.508 0 00-1.056-.442 2.496 2.496 0 00-1.951.883 11.11 11.11 0 00-1.285 1.673 21.396 21.396 0 00-1.974 3.647 16.74 16.74 0 00-.689 1.882v-.023z"
                  fill="#915548"
                />
                <Path
                  id="Vector_15"
                  d="M183.532 141.173a1.263 1.263 0 001.171.558 2.103 2.103 0 001.007-.518 2.15 2.15 0 00.6-.969.93.93 0 01.213-.309.925.925 0 01.314-.202c.253 0 .574-.163.919-.302a4.25 4.25 0 001.612-1.295 4.335 4.335 0 00.821-1.911 6.598 6.598 0 00-.666-3.926 14.293 14.293 0 00-2.295-3.16 13.622 13.622 0 012.043 3.299 6.23 6.23 0 01.436 3.694 3.906 3.906 0 01-.76 1.618 3.844 3.844 0 01-1.398 1.1c-.275.116-.597.163-.941.302a1.71 1.71 0 00-.872.79c-.115.27-.27.521-.459.744a.967.967 0 01-.597.348c-.505 0-.735-.348-.505-.813.229-.465.826-.627 1.423-.953a5.538 5.538 0 002.525-3.159 4.71 4.71 0 00.207-2.021 2.68 2.68 0 00-1.01-1.766c-.873-.651-1.791-1.185-2.686-1.766l-.23-.139.23.162 1.01.744c.505.395 1.056.767 1.515 1.185a2.96 2.96 0 01.81 1.621 2.988 2.988 0 01-.282 1.794 5.05 5.05 0 01-2.296 2.741 3.287 3.287 0 00-1.721 1.208 1.309 1.309 0 00-.138 1.301z"
                  fill="#915548"
                />
                <Path
                  id="Vector_16"
                  d="M189.684 125.561c.202.513.363 1.041.482 1.58l.206.767c.007.116.007.232 0 .348-.114 0-.298 0-.528-.232l-.665-.488a17.366 17.366 0 00-1.355-.906c.368.418.758.79 1.148 1.162l.597.557c.108.111.232.205.367.279a.66.66 0 00.551 0 .536.536 0 00.413-.511.96.96 0 000-.488 5.392 5.392 0 00-.275-.79 9.065 9.065 0 00-.941-1.278z"
                  fill="#915548"
                />
                <Path
                  id="Vector_17"
                  d="M190.786 125.119a39.413 39.413 0 011.4 4.112 10.41 10.41 0 01.345 4.205 10.958 10.958 0 01-1.263 4.02 11.207 11.207 0 01-2.686 3.299 9.75 9.75 0 003.053-3.09 10.691 10.691 0 001.446-4.159 10.85 10.85 0 00-.482-4.391 24.12 24.12 0 00-1.813-3.996z"
                  fill="#915548"
                />
              </G>
              <Path
                id="highlight_rightEar"
                d="M182.801 123.624l-2.553 23.371c4.927.161 9.021.615 12.269-4.371 2.775-4.213 4.372-6.727 4.372-14 0-3.5.229-4-.486-6.5-2.429-8.5-12.145-5-13.602 1.5z"
                fill="#A430FF"
                fillOpacity={0.25}
                stroke="#A430FF"
                opacity={highlightedParts.includes(BODY_PARTS.ear) ? 1 : 0}
              />
            </G>
            <Path
              id="Vector_18"
              d="M181.857 83.575c-8.425-16.507-28.766-19.193-45.618-16.208-24.817 4.867-30.488 27.55-28.032 49.268.666 8.954 1.998 18.711 2.985 27.665 1.883 13.04 12.65 22.522 23.899 28.514 9.987 5.372 18.987 3.765 27.941-2.686 7.737-5.671 14.968-12.719 17.93-21.971 2.502-9 2.939-18.482 4.179-27.665 1.813-12.306 2.708-25.484-3.284-36.917z"
              fill="#915548"
            />
            <Path
              id="Vector_19"
              d="M184.406 116.036c-.895 6.566-1.974 17.678-3.558 26.861a33.708 33.708 0 01-9.758 18.367c-.482.482-.964.964-1.469 1.423-4.913 4.592-13.293 11.893-22.752 11.893s-21.029-8.977-26.057-14.211a30.857 30.857 0 01-8.426-17.38c-.597-5.51-2.296-17.701-2.962-26.517-.551-6.887-6.244-49.2 38.18-49.2 22.499 0 31.889 10.837 35.539 22.362 3.651 11.525 1.699 23.142 1.263 26.402z"
              fill="url(#paint6_radial_5325_12254)"
            />
            <Path
              id="Vector_20"
              style={{
                mixBlendMode: 'darken',
              }}
              d="M180.181 99.555c-1.676 1.928-5.074 2.295-7.531 2.548a12.124 12.124 0 005.855.849 17.457 17.457 0 01-4.592 1.837c.735.265 1.52.359 2.296.276a25.003 25.003 0 01-11.066 2.295 25.73 25.73 0 01-16.3-7.14c-5.281-4.936-6.612-9.642-10.446-14.027-3.375-3.88-8.587-6.176-12.719-4.844-3.444.321-6.337 3.49-6.474 9.183-.138 5.694-.437 13.775-2.159 18.573-1.721 4.798-3.902 8.495-4.04 15.566.023 6.917.553 13.823 1.584 20.662.735 4.89 4.178 10.814 9.941 16.852 5.762 6.038 16.071 12.236 22.177 11.823 5.786-.413 13.293-2.686 24.428-12.65-.482.482-.964.964-1.469 1.424-4.913 4.591-13.293 11.892-22.752 11.892s-21.03-8.977-26.058-14.211a30.852 30.852 0 01-8.425-17.38c-.597-5.51-2.296-17.7-2.962-26.516-.551-6.888-6.244-49.2 38.18-49.2 22.499 0 31.889 10.836 35.539 22.361l-4.041 5.855a18.357 18.357 0 01-5.624.413c.046 1.24 6.658 3.559 6.658 3.559z"
              fill="url(#paint7_linear_5325_12254)"
            />
            <Path
              id="Vector_21"
              style={{
                mixBlendMode: 'multiply',
              }}
              opacity={0.7}
              d="M122.946 155.686c5.984 0 10.836-4.851 10.836-10.836s-4.852-10.836-10.836-10.836c-5.985 0-10.837 4.851-10.837 10.836s4.852 10.836 10.837 10.836z"
              fill="url(#paint8_radial_5325_12254)"
            />
            <Path
              id="Vector_22"
              style={{
                mixBlendMode: 'multiply',
              }}
              opacity={0.7}
              d="M169.666 155.686c5.985 0 10.837-4.851 10.837-10.836s-4.852-10.836-10.837-10.836c-5.984 0-10.836 4.851-10.836 10.836s4.852 10.836 10.836 10.836z"
              fill="url(#paint9_radial_5325_12254)"
            />
            <Path
              id="Vector_23"
              style={{
                mixBlendMode: 'darken',
              }}
              d="M135.091 132.798c6.479 0 11.732-5.252 11.732-11.731s-5.253-11.732-11.732-11.732c-6.479 0-11.732 5.253-11.732 11.732 0 6.479 5.253 11.731 11.732 11.731z"
              fill="url(#paint10_radial_5325_12254)"
            />
            <Path
              id="Vector_24"
              style={{
                mixBlendMode: 'darken',
              }}
              d="M144.388 155.158c5.959 0 10.79-4.831 10.79-10.791 0-5.959-4.831-10.79-10.79-10.79-5.959 0-10.79 4.831-10.79 10.79 0 5.96 4.831 10.791 10.79 10.791z"
              fill="url(#paint11_radial_5325_12254)"
            />
            <Path
              id="Vector_25"
              style={{
                mixBlendMode: 'darken',
              }}
              d="M159.128 132.798c6.479 0 11.732-5.252 11.732-11.731s-5.253-11.732-11.732-11.732c-6.479 0-11.732 5.253-11.732 11.732 0 6.479 5.253 11.731 11.732 11.731z"
              fill="url(#paint12_radial_5325_12254)"
            />
            <Path
              id="Vector_26"
              style={{
                mixBlendMode: 'darken',
              }}
              d="M146.869 166.11a14.601 14.601 0 018.541 2.869 10.429 10.429 0 001.524-9.344 10.423 10.423 0 00-20.35 3.146c.009 2.23.725 4.4 2.043 6.198a13.612 13.612 0 018.242-2.869z"
              fill="url(#paint13_radial_5325_12254)"
            />
            <Path
              id="Vector_27"
              style={{
                mixBlendMode: 'darken',
              }}
              opacity={0.4}
              d="M130.499 153.965a3.031 3.031 0 100-6.062 3.031 3.031 0 000 6.062z"
              fill="url(#paint14_radial_5325_12254)"
            />
            <Path
              id="Vector_28"
              style={{
                mixBlendMode: 'darken',
              }}
              opacity={0.4}
              d="M161.884 153.965a3.031 3.031 0 100-6.062 3.031 3.031 0 000 6.062z"
              fill="url(#paint15_radial_5325_12254)"
            />
            <G id="Frame 2221" clipPath="url(#clip0_5325_12254)">
              <G id="Nose">
                <Path
                  id="Vector_29"
                  d="M157.43 114.243c-1.813 1.493-6.29 8.633-5.28 12.168a29.26 29.26 0 01.758 11.778 3.606 3.606 0 011.584 2.296 3.287 3.287 0 01-1.102 3.329c-1.24.688-3.467.206-3.949.642-.482.437-1.01 1.608-2.663 1.608-1.653 0-1.975-.919-2.778-1.447-.804-.528-2.296 0-3.926-.895-1.63-.896-1.492-4.179.826-5.763a29.542 29.542 0 01.391-11.479c.826-2.824-3.077-9.045-5.969-13.04a3.03 3.03 0 01-.528-1.102c-.873-3.329 4.362-7.278 13.247-6.888 6.887.345 9.573 3.628 9.986 6.13a2.708 2.708 0 01-.597 2.663z"
                  fill="#D98B65"
                />
                <Path
                  id="Vector_30"
                  style={{
                    mixBlendMode: 'darken',
                  }}
                  d="M157.43 114.243c-1.813 1.492-6.29 8.632-5.28 12.168a29.26 29.26 0 01.758 11.778c-1.424 0-3.421.436-3.697-1.033a46.58 46.58 0 01-.872-8.679 14.805 14.805 0 011.4-6.749 61.77 61.77 0 016.406-9.184 2.892 2.892 0 011.905-.849 2.7 2.7 0 01-.62 2.548z"
                  fill="url(#paint16_radial_5325_12254)"
                />
                <Path
                  id="Vector_31"
                  style={{
                    mixBlendMode: 'darken',
                  }}
                  d="M153.413 143.883c-1.24.689-3.467.207-3.949.643-.482.436-1.01 1.607-2.663 1.607-1.653 0-1.975-.918-2.778-1.446-.804-.529-2.296 0-3.926-.896-1.63-.895-1.493-4.178.826-5.762a29.54 29.54 0 01.39-11.479c.827-2.824-3.076-9.046-5.969-13.041a3.031 3.031 0 01-.528-1.102 7.697 7.697 0 014.294 1.515 34.464 34.464 0 015.28 9.413 25.64 25.64 0 01.62 9.184c-.138 3.742-.551 6.543-.689 8.081-.062.346-.05.702.034 1.044a2.516 2.516 0 001.258 1.609c.311.165.653.262 1.004.286a4.823 4.823 0 003.03-1.332 3.654 3.654 0 014.064.321c.285-.254.507-.571.65-.925.144-.354.204-.737.176-1.118a3.284 3.284 0 01-1.124 3.398z"
                  fill="url(#paint17_linear_5325_12254)"
                />
                <Path
                  id="Vector_32"
                  style={{
                    mixBlendMode: 'darken',
                  }}
                  d="M153.413 143.883c-1.24.689-3.467.207-3.949.643-.482.436-1.01 1.607-2.663 1.607-1.653 0-1.975-.918-2.778-1.446-.804-.529-2.296 0-3.926-.896-1.63-.895-1.493-4.178.826-5.762a29.54 29.54 0 01.39-11.479c.827-2.824-3.076-9.046-5.969-13.041a3.031 3.031 0 01-.528-1.102 7.697 7.697 0 014.294 1.515 34.464 34.464 0 015.28 9.413 25.64 25.64 0 01.62 9.184c-.138 3.742-.551 6.543-.689 8.081-.062.346-.05.702.034 1.044a2.516 2.516 0 001.258 1.609c.311.165.653.262 1.004.286a4.823 4.823 0 003.03-1.332 3.654 3.654 0 014.064.321c.285-.254.507-.571.65-.925.144-.354.204-.737.176-1.118a3.284 3.284 0 01-1.124 3.398z"
                  fill="url(#paint18_radial_5325_12254)"
                />
              </G>
            </G>
            <G id="Right Brow">
              <Path
                id="Vector_33"
                d="M170.745 110.137a53.181 53.181 0 00-8.494-.137c-1.322.013-2.629.27-3.857.757v-.597a5.5 5.5 0 00-1.355.712 1.54 1.54 0 01-.253-.413 4.186 4.186 0 00-1.493 2.502 4.183 4.183 0 00.506 2.87 20.652 20.652 0 004.959-.918 51.633 51.633 0 019.987-.689 10.582 10.582 0 014.752 2.089c2.296 1.607 3.834 2.87 4.592 3.283a23.064 23.064 0 00-3.237-5.785 9.18 9.18 0 00-6.107-3.674z"
                fill="#1C1E1F"
              />
              <Path
                id="Vector_34"
                d="M178.711 117.852a16.778 16.778 0 00-7.921-4.385 50.238 50.238 0 00-10.216.711c-1.41.42-2.854.712-4.316.873v-.276a3.211 3.211 0 01.367-3.306 1.492 1.492 0 000 1.355 2.62 2.62 0 011.102-1.63 2.304 2.304 0 00-.161 1.217 6.594 6.594 0 014.868-1.653 53.299 53.299 0 018.333.137 7.92 7.92 0 014.202 2.067 24.206 24.206 0 013.742 4.89z"
                fill="url(#paint19_linear_5325_12254)"
              />
              <Path
                id="Vector_35"
                style={{
                  mixBlendMode: 'multiply',
                }}
                d="M178.712 117.851a16.77 16.77 0 00-7.92-4.385 50.243 50.243 0 00-10.217.712c-1.41.419-2.854.711-4.316.872v-.276a3.014 3.014 0 010-1.515c.118.364.296.706.528 1.01a6.12 6.12 0 01.459-1.308 1.158 1.158 0 00.436.895c.345-.826 2.847-1.561 3.559-1.722-.161.345-.482.758-.482 1.01a39.476 39.476 0 018.632-.964 7.421 7.421 0 014.776 1.263 25.667 25.667 0 014.545 4.408z"
                fill="url(#paint20_linear_5325_12254)"
              />
            </G>
            <G id="Left Brow">
              <Path
                id="Vector_36"
                d="M122.67 110.137a53.311 53.311 0 018.495-.138c1.321.014 2.628.271 3.857.758v-.597c.484.168.941.408 1.354.712a1.54 1.54 0 00.253-.413 4.155 4.155 0 01.987 5.372 21.07 21.07 0 01-4.959-.918 51.628 51.628 0 00-9.987-.689 10.69 10.69 0 00-4.752 2.089c-2.296 1.607-3.834 2.87-4.592 3.283a24.584 24.584 0 013.352-5.854 9.183 9.183 0 015.992-3.605z"
                fill="#1C1E1F"
              />
              <Path
                id="Vector_37"
                d="M137.547 113.168a4.205 4.205 0 01-.391 1.883 25.091 25.091 0 01-4.316-.873 50.305 50.305 0 00-10.216-.712 16.883 16.883 0 00-7.921 4.385 23.499 23.499 0 013.88-4.89 7.854 7.854 0 014.178-2.066 53.436 53.436 0 018.334-.138 6.638 6.638 0 014.89 1.653c.064-.415 0-.839-.183-1.216.528.206.941 1.056 1.125 1.63a1.635 1.635 0 000-1.355c.396.478.616 1.078.62 1.699z"
                fill="url(#paint21_linear_5325_12254)"
              />
              <Path
                id="Vector_38"
                style={{
                  mixBlendMode: 'multiply',
                }}
                d="M137.547 113.166a4.202 4.202 0 01-.391 1.883 24.995 24.995 0 01-4.316-.872 50.234 50.234 0 00-10.216-.712 16.875 16.875 0 00-7.921 4.385 22.17 22.17 0 016.337-4.844 22.416 22.416 0 018.196-.781 9.704 9.704 0 013.099.964 6.588 6.588 0 00-.39-1.079 6.897 6.897 0 012.87 1.63c.183 0 0-.987 0-.987a9.192 9.192 0 011.905 1.653c.341-.365.62-.784.827-1.24z"
                fill="url(#paint22_linear_5325_12254)"
              />
            </G>
            <G id="pressable_mouth">
              <G id="Mouth">
                <G id="Teeth">
                  <Path
                    id="Vector_39"
                    d="M162.09 150.296c-2.64 3.857-8.081 11.777-14.923 11.777-6.841 0-10.308-4.591-15.749-11.777a85.821 85.821 0 0015.359 1.744 102.7 102.7 0 0015.313-1.744z"
                    fill="url(#paint23_radial_5325_12254)"
                  />
                  <G id="Teeth_2">
                    <G id="Group">
                      <Path
                        id="Vector_40"
                        d="M152.609 156.633c-2.296-.391-5.464 1.584-7.921.367-3.329-1.607-7.881-.226-7.466 2.492 3.938 1.709 4.252 5.061 9.739 5.061 4.591 0 8.855-2.317 9.154-5.118-.115-1.056-.2-2.343-3.506-2.802z"
                        fill="#823A38"
                      />
                      <Path
                        id="Vector_41"
                        d="M138.091 159.711c-.114-1.906 1.11-2.341 3.176-2.593a7.273 7.273 0 013.145.596 7.316 7.316 0 004.753 0 8.295 8.295 0 013.352-.436c2.502.345 3.306 1.538 3.42 2.64.115 1.102-3.512 3.766-8.976 3.766s-8.801-2.412-8.87-3.973z"
                        fill="url(#paint24_radial_5325_12254)"
                      />
                      <Path
                        id="Vector_42"
                        d="M147.095 150.163c-4.591.114-13.019.071-13.013.318.253 2.25.827 3.581 2.64 3.948a2.871 2.871 0 003.031.529 3.676 3.676 0 003.512.551c.556.331 1.19.505 1.837.505a3.416 3.416 0 001.63-.391 3.584 3.584 0 003.49-.183 3.467 3.467 0 003.053-.322 3.25 3.25 0 002.962-.275 2.139 2.139 0 002.296-1.47c.508-1.247.563-1.338.694-2.678.213-.994-7.54-.441-12.132-.532z"
                        fill="#70707A"
                      />
                      <Path
                        id="Vector_43"
                        d="M158.73 150.269c-.133 1.16-.119 1.433-.519 2.53l-.184.344a1.605 1.605 0 01-1.859.964 2.524 2.524 0 01-2.87.207 2.754 2.754 0 01-3.122.321 2.988 2.988 0 01-3.467.23 2.845 2.845 0 01-3.352-.161 3.007 3.007 0 01-3.49-.551 2.297 2.297 0 01-2.892-.436h-.253c-1.354-.252-1.607-1.492-1.974-3.352-.184-1.079 6.789.269 12.276.223 5.487-.046 11.798-1.122 11.706-.319z"
                        fill="url(#paint25_radial_5325_12254)"
                      />
                      <Path
                        id="Vector_44"
                        style={{
                          mixBlendMode: 'multiply',
                        }}
                        d="M158.837 150.198c-.134 1.16-.226 1.505-.626 2.602l-.184.344c-.688-1.355-2.066-.987-3.214-.895-1.148.091-1.882.206-3.444.321a48.195 48.195 0 01-6.634.275 17.33 17.33 0 00-3.559-.275 9.65 9.65 0 01-2.916-.184c-.78 0-1.308.414-1.538 1.286-1.354-.253-1.607-1.492-1.974-3.352-.184-1.079 6.718.101 12.205.055 5.487-.045 11.976-.98 11.884-.177z"
                        fill="url(#paint26_linear_5325_12254)"
                      />
                      <Path
                        id="Vector_45"
                        d="M146.658 164.49c-6.512-.239-10.808-6.756-10.808-6.756a4.59 4.59 0 013.329 1.171 5.746 5.746 0 013.88.62 4.585 4.585 0 011.997-.206c.592.034 1.17.191 1.699.459a5.097 5.097 0 013.765-.161c1.014-.56 2.338-.728 3.485-.57.895-.735 1.855-1.197 3.012-1.221.428-.366.672-.695 1.23-.773 0 0-2.385 7.774-11.589 7.437z"
                        fill="#70707A"
                      />
                      <Path
                        id="Vector_46"
                        d="M146.633 164.401c-6.884.197-9.067-5.846-10.391-6.131.515-.109.47.045.985.154.67.177 1.27.553 1.722 1.079a5.622 5.622 0 012.548 0 2.934 2.934 0 011.446.688 4.11 4.11 0 013.72.207 4.404 4.404 0 013.879 0 3.766 3.766 0 011.608-.712 4.133 4.133 0 011.928 0c.208-.288.474-.531.781-.711a4.875 4.875 0 012.295-.551c-.978.543-1.659 6.551-10.521 5.977z"
                        fill="url(#paint27_radial_5325_12254)"
                      />
                      <Path
                        id="Vector_47"
                        style={{
                          mixBlendMode: 'multiply',
                        }}
                        d="M156.1 160.145s-1.269 4.386-9.155 4.425c-5.068.025-8.399-3.583-8.468-3.675-.069-.092-1.065-1.094-1.18-2.357.67.177 1.27.553 1.722 1.079a5.622 5.622 0 012.548 0 5.599 5.599 0 003.145 1.607c2.605.51 5.305-.035 7.508-1.515a4.133 4.133 0 011.928 0c.208-.289.474-.531.781-.712.478.282.88.676 1.171 1.148z"
                        fill="url(#paint28_linear_5325_12254)"
                      />
                    </G>
                  </G>
                </G>
                <G id="Lips">
                  <Path
                    id="Vector_48"
                    d="M155.743 149.648a25.766 25.766 0 00-8.541.505c-1.905-1.079-5.464-.849-8.655-.551-2.617.23-4.913.322-7.485.437a57.116 57.116 0 003.054 5.372c4.982 8.15 8.839 9.688 13.339 9.688 4.959 0 8.127-2.548 12.856-10.652.574-1.057 1.86-3.169 2.434-4.592-2.273-.069-4.385-.161-7.002-.207zm-8.702 2.755a100.41 100.41 0 0014.809-1.722c-2.778 3.972-8.633 11.043-14.51 11.043-5.877 0-10.515-4.867-15.29-11.111a92.725 92.725 0 0014.991 1.79z"
                    fill="#915548"
                  />
                  <Path
                    id="Vector_49"
                    d="M162.241 150.292a102.599 102.599 0 01-15.314 1.745 85.741 85.741 0 01-15.359-1.745c3.903-.16 9.184-.16 11.25-.275a8.433 8.433 0 014.339.826 15.037 15.037 0 015.097-.665c1.745.114 7.415-.184 9.987.114z"
                    fill="url(#paint29_radial_5325_12254)"
                  />
                  <Path
                    id="Vector_50"
                    d="M162.241 150.291c-5.993 11.479-10.814 14.234-14.969 14.234-4.156 0-9.712-2.295-15.704-14.234 5.441 7.117 8.931 11.778 15.75 11.778 6.818 0 12.282-7.921 14.923-11.778z"
                    fill="url(#paint30_radial_5325_12254)"
                  />
                  <Path
                    id="Vector_51"
                    d="M161.562 148.31c.38.238.727.523 1.034.85.157.173.303.357.436.551.149.204.251.439.298.688a4.02 4.02 0 010 1.401 4.817 4.817 0 01-.436 1.262v-1.331a7.335 7.335 0 000-1.24 2.95 2.95 0 00-.482-1.079 8.167 8.167 0 00-.85-1.102z"
                    fill="#915548"
                  />
                  <Path
                    id="Vector_52"
                    d="M132.452 148.104c-.256.406-.486.827-.689 1.262a3.647 3.647 0 00-.39 1.263 5.21 5.21 0 000 1.332c0 .459.16.941.206 1.423a6.912 6.912 0 01-.643-1.309 3.75 3.75 0 01-.206-1.515 3.145 3.145 0 01.597-1.423 5.602 5.602 0 011.125-1.033z"
                    fill="#915548"
                  />
                </G>
              </G>
              <Path
                id="highlight_mouth"
                d="M146.637 165.56c-6 0-12.604-7.905-16-16 6.769.097 12.479-1.72 16.479.28 3.652-1.1 10.966-.671 16.466-.171-5.5 10.5-9.737 16.408-16.945 15.891z"
                fill="#A430FF"
                fillOpacity={0.25}
                stroke="#A430FF"
                opacity={highlightedParts.includes(BODY_PARTS.mouth) ? 1 : 0}
              />
            </G>
            <G id="pressable_leftJaw">
              <Path
                id="highlight_leftJaw"
                d="M137.846 168.939c-7.88-4.731-15.789-10.72-21.651-21.869-.529-1.006-1.793-1.391-2.77-.809-.729.434-1.098 1.293-.837 2.1 2.806 8.653 12.467 18.63 23.758 24.081l1.5-3.503z"
                fill="#A430FF"
                fillOpacity={0.25}
                stroke="#A430FF"
                strokeWidth={1.5}
                opacity={highlightedParts.includes(BODY_PARTS.jaw) ? 1 : 0}
              />
            </G>
            <G id="pressable_rightJaw">
              <Path
                id="highlight_rightJaw"
                d="M155.53 168.341c7.899-5.11 15.092-10.022 21.854-22.803.535-1.012 1.84-1.346 2.775-.685.696.492.973 1.394.651 2.181-4.998 12.192-12.891 18.798-23.79 25.756l-1.49-4.449z"
                fill="#A430FF"
                fillOpacity={0.25}
                stroke="#A430FF"
                strokeWidth={1.5}
                opacity={highlightedParts.includes(BODY_PARTS.jaw) ? 1 : 0}
              />
            </G>
            <G id="Hair">
              <Path
                id="Vector_53"
                d="M185.469 70.29a43.898 43.898 0 00-18.091-13.5 53.838 53.838 0 00-17.449-3.236 25.582 25.582 0 00-3.604.137c1.469-.459 2.916-.895 4.201-1.217a19.486 19.486 0 00-5.051-.964 31.91 31.91 0 00-25.047 11.824c-11.732 1.813-16.186 10.56-17.747 21.466a61.16 61.16 0 00-.344 19.721 85.198 85.198 0 002.778 13.385 44.488 44.488 0 013.742 12.994 34.904 34.904 0 001.791 4.775c.115-1.308.597-5.648.574-6.589v-10.813c-.131-2.273.181-4.55.918-6.704 1.423-3.306 4.201-6.107 4.959-9.78 1.355-7.002-2.066-19.469 8.632-19.354a52.328 52.328 0 0112.559.184c4.316.367 8.632.711 13.017.78 6.474 0 12.948-.23 19.423 0 4.476-.298 6.106 4.11 6.152 7.92.138 3.399 0 7.14 0 10.562a26.038 26.038 0 003.651 8.563c2.181 3.467 1.699 7.806 1.744 12.145v10.699c0 1.584.207 4.04.506 6.336a29.171 29.171 0 001.4-4.592c1.286-4.477 1.768-10.285 2.87-14.739 1.079-3.076 2.64-6.451 3.489-9.619 2.227-13.224 2.824-28.836-5.073-40.384z"
                fill="#1C1E1F"
              />
              <Path
                id="Vector_54"
                d="M190.773 96.162a97.105 97.105 0 01-1.08 12.765 18.436 18.436 0 01-.688 2.755c-1.079 3.237-2.594 6.726-3.145 8.448-.551 1.722-2.135 12.65-2.572 15.267.184-4.591 0-13.889 0-16.07a19.767 19.767 0 00-1.24-8.219 51.983 51.983 0 01-4.063-8.61 4.162 4.162 0 010-1.01v-6.887c.011-.115.011-.23 0-.345v-1.538-1.01-.964-.505c-.253-3.054-1.607-7.692-6.245-7.944-2.043-.115-5.303 0-8.931 0h-1.859c-4.041 0-8.288.138-11.686 0h-1.951c-3.513-.184-7.117-.482-9.941-.735-1.998-.183-3.628-.321-4.592-.367h-.597c-1.423 0-3.719 0-5.372.138h-1.24a8.107 8.107 0 00-6.681 2.8c-2.433 3.123-2.295 8.105-2.433 11.48-.138 3.375.39 6.061-2.158 10.17-2.549 4.11-3.697 5.464-3.995 9.367-.298 3.903 0 16.071 0 17.517a48.767 48.767 0 00-3.788-14.578c-.987-1.469-2.732-11.686-3.329-17.839a69.596 69.596 0 012.433-22.315 17.884 17.884 0 0115.566-13.614 30.511 30.511 0 0126.494-11.617 24.311 24.311 0 00-9.688 2.824 60.978 60.978 0 0126.287 1.377c7.415 2.296 16.805 8.495 21.098 15.153 4.294 6.657 5.671 16.667 5.396 24.106z"
                fill="#474A4C"
              />
              <G
                id="Vector_55"
                style={{
                  mixBlendMode: 'screen',
                }}
                opacity={0.21}
              >
                <Path
                  d="M113.288 80.39a10.861 10.861 0 002.296 2.869c.482-.299.964-.597 1.492-.873a5.832 5.832 0 01-.987-2.823c-.958.183-1.897.46-2.801.826zm2.686-5.947c-.643-.712-.413-1.08.367-1.63a13.906 13.906 0 00-3.099.115c-.275.918.597 2.066 1.102 2.87.23.344 0 .78-.138 1.24a23.576 23.576 0 012.572 0c1.469-1.539.114-1.539-.804-2.595zm.85-4.293c.673.2 1.333.446 1.974.734.298-.597 0-1.125-.321-1.928-.322-.804-.299-1.194.367-1.768h-.941c-.965.505-1.722 1.125-1.286 1.974.161.303.232.646.207.988z"
                  fill="#474A4C"
                />
              </G>
              <Path
                id="Vector_56"
                d="M190.773 96.162a97.105 97.105 0 01-1.08 12.765 25.608 25.608 0 00-3.03-10.653c2.916 1.332 3.926 0 3.926-1.699a62.681 62.681 0 00-2.801-17.724 37.65 37.65 0 00-17.058-17.953 37.882 37.882 0 00-27.068-.46 42.993 42.993 0 00-12.236 6.888 25.712 25.712 0 0115.841-10.331 26.195 26.195 0 00-21.328 14.05 24.832 24.832 0 0112.902-14.142 21.329 21.329 0 00-14.601 10.745 16.958 16.958 0 013.834-7.921 9.621 9.621 0 00-5.281 9.55c-1.354-3.053-8.081-2.479-10.997.322 3.145-.115 8.977.78 11.479 5.395a15.105 15.105 0 00-11.915-1.56c-3.582 1.101-3.582 4.132-.551 4.017 3.03-.115 9.183-1.906 12.903 2.296a19.746 19.746 0 00-10.768.895 10.033 10.033 0 00-5.946 7.645c-.574 2.296 2.296 0 5.602-2.709 3.306-2.71 8.425-5.946 13.775-4.592.15.041.29.111.413.207a7.726 7.726 0 00-1.24.207 8.105 8.105 0 00-6.658 2.732c-2.433 3.122-2.295 8.104-2.433 11.479-.138 3.375.39 6.061-2.158 10.17-2.549 4.11-3.697 5.464-3.995 9.367-.298 3.903 0 16.071 0 17.517a48.767 48.767 0 00-3.788-14.578c-.987-1.469-2.732-11.686-3.329-17.839a69.596 69.596 0 012.433-22.315 17.884 17.884 0 0115.566-13.614 30.511 30.511 0 0126.494-11.617 24.311 24.311 0 00-9.688 2.824 60.978 60.978 0 0126.287 1.377c7.415 2.296 16.805 8.495 21.098 15.153 4.294 6.657 5.671 16.667 5.396 24.106z"
                fill="#35373A"
              />
              <Path
                id="Vector_57"
                d="M121.346 64.871c.321.574.597 1.194.872 1.79.276.598.505 1.263.712 1.906a37.116 37.116 0 011.033 3.88c.275 1.309.482 2.617.712 3.949.229 1.332.459 2.617.734 3.926a28.485 28.485 0 01-1.171-3.834c-.321-1.309-.573-2.617-.849-3.926-.275-1.309-.505-2.617-.827-3.903-.183-.643-.344-1.286-.551-1.906-.206-.62-.413-1.262-.665-1.882z"
                fill="#474A4C"
              />
              <Path
                id="Vector_58"
                d="M109.982 71.85c.493-.19 1-.344 1.516-.46.505 0 1.033-.206 1.561-.275.528-.069 1.056 0 1.584 0a12.307 12.307 0 013.168.436c.505.138 1.01.322 1.515.505.49.205.966.443 1.424.712l.666.413.642.483c-.459-.253-.941-.46-1.423-.666-.482-.207-.964-.39-1.446-.551l-1.493-.436-1.515-.276c-.528 0-1.033-.138-1.538-.184h-1.561l-1.561.115c-.505-.023-1.033.07-1.539.184z"
                fill="#474A4C"
              />
              <Path
                id="Vector_59"
                d="M107.273 80.252a5.926 5.926 0 003.766-.39c1.193-.46 2.295-1.125 3.604-1.562a9.986 9.986 0 013.949-.459 9.366 9.366 0 013.788 1.08 12.12 12.12 0 00-3.811-.69 11.014 11.014 0 00-3.765.552c-1.217.39-2.296.987-3.651 1.4a5.51 5.51 0 01-3.88.069z"
                fill="#474A4C"
              />
              <Path
                id="Vector_60"
                d="M105.689 95.931a8.082 8.082 0 002.296-2.158c.643-.872 1.194-1.767 1.768-2.686a33.038 33.038 0 013.972-5.142 18.055 18.055 0 015.303-3.788 13.594 13.594 0 013.122-.942 10.242 10.242 0 013.238 0 13.04 13.04 0 00-6.176 1.4 18.65 18.65 0 00-4.913 3.72 38.675 38.675 0 00-4.041 4.982c-.62.872-1.217 1.768-1.928 2.594a7.699 7.699 0 01-2.641 2.02z"
                fill="#474A4C"
              />
              <Path
                id="Vector_61"
                d="M117.489 88.587a24.532 24.532 0 01-.711 4.248 26.356 26.356 0 01-1.401 4.11 24.41 24.41 0 01-2.089 3.787 10.802 10.802 0 01-1.401 1.676 7.88 7.88 0 01-1.744 1.263 10.887 10.887 0 002.8-3.168 32.727 32.727 0 001.998-3.766 33.377 33.377 0 001.538-3.994 36.377 36.377 0 001.01-4.156z"
                fill="#474A4C"
              />
              <Path
                id="Vector_62"
                d="M124.516 66.937c.25-.627.533-1.24.849-1.837.324-.595.685-1.17 1.079-1.722.39-.55.804-1.079 1.24-1.607.436-.528.941-.987 1.423-1.446a21.092 21.092 0 013.283-2.41c.578-.343 1.176-.65 1.791-.92a18.967 18.967 0 011.906-.71c-.62.275-1.217.573-1.814.894l-1.722 1.01a25.99 25.99 0 00-1.607 1.172c-.528.413-1.033.849-1.538 1.285-.505.436-.942.919-1.401 1.4-.459.483-.872 1.01-1.285 1.539a20.684 20.684 0 00-1.171 1.63 25.814 25.814 0 00-1.033 1.722z"
                fill="#474A4C"
              />
              <Path
                id="Vector_63"
                d="M133.17 69.462l.987-.804 1.056-.78 1.056-.69 1.125-.642 1.148-.597 1.171-.528 1.217-.482 1.217-.413 1.239-.322 1.263-.275 1.286-.23 1.285-.138h3.857l1.286.138h-3.88l-1.285.138-1.24.184-1.263.23-1.24.298-1.217.344-1.216.39-1.194.436-1.171.483-1.148.528-1.148.597-1.102.642-1.079.69-1.01.803z"
                fill="#474A4C"
              />
              <Path
                id="Vector_64"
                d="M171.098 65.305l1.262.551 1.217.597 1.171.689 1.148.712 1.125.803 1.079.827 1.033.895.987.964.918.987.896 1.056.826 1.08.781 1.125.712 1.147.643 1.217.597 1.217.528 1.263-.643-1.217-.666-1.171-.735-1.125-.803-1.125-.827-1.079-.849-1.056-.873-1.01-.941-.964-.941-.942-1.01-.918-1.034-.85-1.056-.826-1.102-.803-1.125-.735-1.17-.689-1.148-.62z"
                fill="#474A4C"
              />
              <Path
                id="Vector_65"
                d="M177.365 78.3c.919.505 1.86.987 2.755 1.538a23.288 23.288 0 012.618 1.837 14.013 14.013 0 012.295 2.296 10.57 10.57 0 011.539 2.824 14.837 14.837 0 00-1.86-2.549 21.881 21.881 0 00-2.296-2.158 40.09 40.09 0 00-2.456-1.974c-.85-.62-1.722-1.217-2.595-1.814z"
                fill="#474A4C"
              />
              <Path
                id="Vector_66"
                d="M176.424 84.018a9.186 9.186 0 013.696 2.043 26.533 26.533 0 012.893 3.122 36.16 36.16 0 002.778 3.146c.97.992 2.135 1.773 3.421 2.295a9.185 9.185 0 01-3.743-1.997 26.525 26.525 0 01-2.892-3.122 36.532 36.532 0 00-2.732-3.191 9.79 9.79 0 00-3.421-2.296z"
                fill="#474A4C"
              />
              <Path
                id="Vector_67"
                d="M187.488 99.125l1.24 1.079 1.194 1.125c.395.424.687.935.849 1.492.137.547.184 1.113.138 1.676-.161-.528-.321-1.056-.505-1.538a4.606 4.606 0 00-.849-1.286l-1.057-1.263-1.01-1.285z"
                fill="#474A4C"
              />
              <Path
                id="Vector_68"
                d="M178.123 91.57c.253.827.528 1.653.804 2.457l.803 2.457c.231.82.515 1.626.85 2.41.326.79.867 1.475 1.561 1.975a4.114 4.114 0 01-1.906-1.791 13.774 13.774 0 01-.987-2.434c-.276-.826-.482-1.676-.689-2.502-.206-.827-.298-1.722-.436-2.572z"
                fill="#474A4C"
              />
              <Path
                id="Vector_69"
                d="M126.467 69.944l.666-1.332.757-1.308.85-1.24.895-1.194.987-1.148 1.056-1.102 1.102-1.01 1.171-.964 1.24-.873 1.286-.78 1.354-.69 1.378-.619 1.423-.482 1.469-.39 1.47-.276 1.492-.16-1.469.275-1.447.367-1.4.482-1.378.551-1.354.62-1.286.735-1.263.78-1.216.873-1.148.918-1.125.964-1.056 1.056-1.01 1.08-.942 1.148-.895 1.193-.85 1.24-.757 1.286z"
                fill="#474A4C"
              />
              <Path
                id="Vector_70"
                d="M133.445 64.94l1.791-1.423 1.883-1.332 1.974-1.17 2.066-1.057 2.135-.895 2.296-.78 2.296-.644 2.296-.482 2.296-.344 2.295-.16h2.296l2.296.114 2.296.298 2.296.437 2.296.62 2.158.78-2.296-.62-2.296-.482-2.296-.367-2.296-.207H154.636l-2.296.207-2.296.321-2.296.436-2.296.574-2.295.712-2.113.85-2.066.987-2.02 1.102-1.929 1.24-1.584 1.285z"
                fill="#474A4C"
              />
              <Path
                id="Vector_71"
                d="M180.327 92.901a18.554 18.554 0 008.334-2.8 39.528 39.528 0 01-8.472-5.442c-2.296-2.112-4.592-4.591-7.071-6.382-12.811-10.95-47.684 3.123-47.684 3.123l.344 1.01c6.268-1.952 12.627 2.295 16.071 7.37 5.395 8.654 11.272 16.07 20.662 17.631 2.364.353 4.769.306 7.117-.138 1.194-.206 4.822-.941 4.822-.941l-3.88-.964a18.658 18.658 0 006.244-1.125 26.593 26.593 0 01-6.405-1.929 23.553 23.553 0 0010.492-2.548l-2.962-.78a14.817 14.817 0 01-6.107-3.513c2.032.347 4.098.447 6.153.298a27.871 27.871 0 005.327-1.148 12.722 12.722 0 01-2.985-1.722z"
                fill="#1C1E1F"
              />
              <Path
                id="Vector_72"
                d="M186.915 89.872h-.183a16.304 16.304 0 01-8.725 1.653 9.543 9.543 0 01-1.492-.299c.169.275.369.53.597.758.278.285.586.539.918.758a8.118 8.118 0 001.975.918l1.263.459h-1.401c-.643 0-1.263 0-1.86.115-.597.115-1.239 0-1.836 0a15.224 15.224 0 01-6.888-1.584c.069.278.169.547.299.803a14.232 14.232 0 007.645 5.923l.734.207-.321.138a29.458 29.458 0 01-10.607.987l.299.298a11.25 11.25 0 005.441 2.595c-.524.087-1.053.141-1.584.16a23.138 23.138 0 01-6.635-.688c.395.407.833.769 1.308 1.079.569.353 1.168.652 1.791.895a20.34 20.34 0 002.181.712h-.551a26.054 26.054 0 01-4.821 0c-9.528-.896-14.785-7.324-20.226-15.566l-.964-1.47c-.23-.344-.483-.71-.735-1.032a18.045 18.045 0 00-9.872-6.612 11.986 11.986 0 00-6.888.16c1.194-.459 2.686-2.295 3.766-3.168a42.125 42.125 0 015.464-3.306 39.182 39.182 0 019.665-3.42 34.878 34.878 0 015.946-.781 33.45 33.45 0 0118.16 4.293c.827.46 1.653.964 2.457 1.515.803.551 1.171.804 1.722 1.263.75.558 1.471 1.156 2.158 1.79a42.976 42.976 0 013.788 4.386 15.758 15.758 0 002.755 2.778c1.653 1.262 3.535 2.135 5.234 3.26l.023.023z"
                fill="#474A4C"
              />
              <G
                id="Vector_73"
                style={{
                  mixBlendMode: 'screen',
                }}
                opacity={0.21}
              >
                <Path
                  d="M159.205 80.782a1.126 1.126 0 01-.345.436 33.447 33.447 0 00-10.675-5.142s.184 2.755 0 5.097l.895 1.078a56.887 56.887 0 013.582 5.19c.413-1.631.757-3.422 1.079-5.075a80.57 80.57 0 018.77 4.293c0-.711.115-1.675.184-2.732a41.85 41.85 0 01-1.929-1.676c-.643-.596-1.033-.964-1.561-1.469zm-11.479 3.398a18.37 18.37 0 00-6.268-2.296h-.688a23.71 23.71 0 011.538 5.051c.229.367.482.735.711 1.125.23.39.873 1.377 1.332 2.09a21.963 21.963 0 000-5.075 23.39 23.39 0 017.438 5.717c.289-.678.527-1.376.712-2.09-1.4-1.882-2.916-3.856-4.385-5.51-.1.36-.272.696-.505.988h.115zm22.315-12.971a2.959 2.959 0 01-1.148 3.65 3.005 3.005 0 01-2.479.344 53.37 53.37 0 00-7.714-1.331c.55 1.558.86 3.192.918 4.844l3.191 3.513a13.878 13.878 0 00-.597-5.005s.942.183 5.419.85c2.295.366 3.168-.529 3.604-1.7a8.161 8.161 0 011.171-2.961c1.538-1.722 5.257-.483 8.196 1.24-4.087-4.5-11.64-6.59-10.676-3.444h.115z"
                  fill="#474A4C"
                />
              </G>
              <Path
                id="Vector_74"
                d="M124.102 73.686a.566.566 0 000 .184c.183 1.24 1.01 4.592 1.653 7.37h.872c1.653-.184 3.949-.138 5.372-.138h.597a18.364 18.364 0 0110.584 8.104c5.808 8.93 11.112 16.071 21.19 17.035a25.115 25.115 0 01-7.553-2.479c-4.293-2.113-10.308-9.872-13.935-15.819a22.502 22.502 0 00-5.671-6.474 12.514 12.514 0 00-6.888-2.571c4.133-3.903 11.296-1.125 14.923 1.515a15.257 15.257 0 011.998 1.79c3.145 3.192 6.543 8.312 8.839 11.159a68.29 68.29 0 00-6.888-11.09 25.93 25.93 0 00-4.592-4.729 12.898 12.898 0 00-9.504-2.755 16.075 16.075 0 00-5.786 1.929 21.38 21.38 0 018.816-4.89c2.137-.713 4.42-.871 6.635-.46 3.49.606 6.732 2.2 9.344 4.592 1.768 1.653 4.316 4.156 6.888 6.383a26.01 26.01 0 006.589 4.844 58.968 58.968 0 01-4.73-4.867A115.874 115.874 0 00152.8 71.964a10.882 10.882 0 00-2.021-1.308c-4.591-2.296-10.882-1.814-18.091 1.607 6.704-7.232 17.632-8.22 21.397-8.426a22.362 22.362 0 00-16.071 1.722 28.095 28.095 0 00-10.56 10.928c-.597 1.102-2.136-2.433-1.24-5.28-.987.184-1.837-2.296-1.699-3.398-.827.321-1.699.16-1.814-.275l1.401 6.152z"
                fill="#333539"
              />
              <G
                id="Vector_75"
                style={{
                  mixBlendMode: 'multiply',
                }}
              >
                <Path
                  d="M167.607 105.461c.713.278 1.442.516 2.181.711h-.55c-.574-.206-1.125-.528-1.631-.711z"
                  fill="#474A4C"
                />
              </G>
              <Path
                id="Vector_76"
                d="M159.893 99.286c2.98 3.001 6.999 4.743 11.226 4.867a23.174 23.174 0 01-6.635-.688c.395.407.834.769 1.309 1.079a14.12 14.12 0 01-1.584-.942 21.327 21.327 0 01-4.316-4.316z"
                fill="#383B3D"
              />
              <Path
                id="Vector_77"
                d="M177.571 100.021a29.457 29.457 0 01-10.606.987l.298.298-.597-.16a10.455 10.455 0 01-3.926-3.743c4.569 3.077 11.388 2.663 14.831 2.618z"
                fill="#383B3D"
              />
              <G
                id="Vector_78"
                style={{
                  mixBlendMode: 'multiply',
                }}
              >
                <Path
                  d="M177.203 99.652l.735.206-.322.138c-.12-.134-.26-.25-.413-.344z"
                  fill="#474A4C"
                />
              </G>
              <Path
                id="Vector_79"
                style={{
                  mixBlendMode: 'multiply',
                }}
                d="M190.06 95.016a14.86 14.86 0 01-4.592-1.286c-.665-.46-1.354 1.883-2.709 2.296-1.354.413-.826 4.431.918 6.015-1.216.321-1.974-1.056-3.627-.964a2.45 2.45 0 00-1.997 1.308 4.162 4.162 0 010-1.01v-6.887h-1.837a15.217 15.217 0 01-6.887-1.585c.069.279.169.548.298.804a4.33 4.33 0 01-.597-.643c-.574-1.033-.941-1.928-1.171-2.296 1.722 1.63 6.727 2.893 10.171 3.421.712 0 1.354.184 1.882.23a7.39 7.39 0 001.355 0l-.275-.161-.942-.505-1.974-1.125-.919-.551-.895-.597a7.483 7.483 0 01-1.791-1.722 19.3 19.3 0 003.536.896c2.921.482 5.915.245 8.724-.69a.228.228 0 00.184 0c0-.481-2.159-1.56-2.159-1.56a40.065 40.065 0 01-5.831-4.477c-.183-.16-.321-.322-.482-.482a50.062 50.062 0 00-5.739-5.855l.275.161a35.535 35.535 0 017.278 5.625 18.382 18.382 0 00-4.385-5.648c2.64.299 8.219 4.592 9.183 5.418.964.827-1.355-6.107-4.224-9.183a41.79 41.79 0 00-12.122-9.183 22.152 22.152 0 0113.935 8.356 33.19 33.19 0 015.579 15.91 6.149 6.149 0 01-.528 3.467c-.229.46 1.722 1.975 2.365 2.503z"
                fill="url(#paint31_linear_5325_12254)"
              />
              <Path
                id="Vector_80"
                d="M127.775 78.876a16.074 16.074 0 018.082-3.33 10.7 10.7 0 014.408.667 17.4 17.4 0 013.903 2.135 27.566 27.566 0 016.313 6.199 61.7 61.7 0 014.592 7.576 71.324 71.324 0 00-4.867-7.324 29.857 29.857 0 00-6.268-5.992 18.036 18.036 0 00-3.765-2.135 10.218 10.218 0 00-4.224-.758 16.209 16.209 0 00-8.174 2.962z"
                fill="#333539"
              />
              <Path
                id="Vector_81"
                d="M129.336 75.088a36.364 36.364 0 014.592-2.893 22.225 22.225 0 015.073-1.929c1.769-.45 3.6-.605 5.419-.459 1.83.177 3.613.69 5.257 1.516a29.144 29.144 0 018.403 6.887 91.779 91.779 0 007.415 7.829 77.138 77.138 0 01-7.714-7.53 30.45 30.45 0 00-8.357-6.613 14.92 14.92 0 00-5.05-1.492 16.993 16.993 0 00-5.281.322 28.13 28.13 0 00-9.757 4.362z"
                fill="#333539"
              />
            </G>
            <G id="pressable_forehead">
              <Path
                id="highlight_forehead"
                d="M116.522 102.957c.169-13.96.514-21.377 9.272-21.258l38.977-.58c5.297 0 11.352 5.991 11.73 14.108.466 7.788 2.862 12.203 6.393 20.378 0 0-5.806-4.174-9.609-5.497-6.309-2.196-17.029 1.159-17.029 1.159l-19.488-.773c-12.355-2.675-18.305-.672-25.164 5.411.467-6.429.959-7.012 4.918-12.948z"
                fill="#A430FF"
                fillOpacity={0.25}
                stroke="#A430FF"
                opacity={highlightedParts.includes(BODY_PARTS.forehead) ? 1 : 0}
              />
            </G>
            <G id="pressable_rightEye">
              <G id="Right Eye">
                <Path
                  id="Vector_82"
                  d="M157.981 129.136a13.773 13.773 0 011.171-6.888c1.699-3.65 6.13-4.936 9.872-4.591a9.78 9.78 0 019.436 10.446c-1.493 6.887-10.079 7.209-15.566 5.739a6.894 6.894 0 01-4.592-2.801 2.723 2.723 0 01-.321-.918 8.126 8.126 0 010-.987z"
                  fill="#1C1E1F"
                />
                <Path
                  id="Vector_83"
                  d="M177.817 127.987c-.298 2.594-3.145 6.107-9.183 6.153-6.038.046-10.194-1.699-10.515-4.087-.321-2.387 0-10.974 8.724-11.479 8.724-.505 11.296 6.704 10.974 9.413z"
                  fill="url(#paint32_radial_5325_12254)"
                />
                <Path
                  id="Vector_84"
                  d="M160.437 126.38a7.118 7.118 0 0013.791 2.845 7.118 7.118 0 00-11.573-7.614 7.117 7.117 0 00-2.218 4.769z"
                  fill="#1C1E1F"
                />
                <Path
                  id="Vector_85"
                  d="M167.531 132.833a6.452 6.452 0 100-12.905 6.452 6.452 0 000 12.905z"
                  fill="url(#paint33_radial_5325_12254)"
                />
                <Path
                  id="Vector_86"
                  d="M167.531 132.833a6.452 6.452 0 100-12.905 6.452 6.452 0 000 12.905z"
                  fill="url(#paint34_radial_5325_12254)"
                />
                <Path
                  id="Vector_87"
                  d="M167.532 130.191a3.81 3.81 0 100-7.621 3.81 3.81 0 000 7.621z"
                  fill="#1C1E1F"
                />
                <Path
                  id="Vector_88"
                  d="M170.516 125.027a.918.918 0 100-1.836.918.918 0 000 1.836z"
                  fill="#fff"
                />
                <Path
                  id="Vector_89"
                  d="M169.071 126.015a.459.459 0 100-.918.459.459 0 000 .918z"
                  fill="#fff"
                />
                <Path
                  id="Vector_90"
                  style={{
                    mixBlendMode: 'multiply',
                  }}
                  d="M166.797 120.528c7.554-.344 10.469 4.592 10.974 7.898.012-.115.012-.23 0-.344.322-2.709-2.158-9.758-11.02-9.344-8.287.413-8.999 7.989-8.793 10.905.299-3.536 1.929-8.862 8.839-9.115z"
                  fill="url(#paint35_linear_5325_12254)"
                />
              </G>
              <Path
                id="highlight_rightEye"
                d="M178.928 128.103c1.5-14.5-24-16.194-21.5 2 0 0 0 5 10.5 5s11-7 11-7z"
                fill="#A430FF"
                fillOpacity={0.25}
                stroke="#A430FF"
                opacity={highlightedParts.includes(BODY_PARTS.eye) ? 1 : 0}
              />
            </G>
            <G id="pressable_leftEye">
              <G id="Left Eye">
                <Path
                  id="Vector_91"
                  d="M136.43 129.136a13.552 13.552 0 00-1.171-6.888c-1.676-3.65-6.13-4.936-9.872-4.591a9.78 9.78 0 00-9.436 10.446c1.492 6.887 10.079 7.209 15.589 5.739a6.892 6.892 0 004.476-2.801c.162-.284.27-.595.322-.918.049-.327.079-.657.092-.987z"
                  fill="#1C1E1F"
                />
                <Path
                  id="Vector_92"
                  d="M116.595 127.987c.321 2.594 3.145 6.107 9.183 6.153 6.038.046 10.193-1.699 10.515-4.087.321-2.387 0-10.974-8.724-11.479-8.725-.505-11.296 6.704-10.974 9.413z"
                  fill="url(#paint36_radial_5325_12254)"
                />
                <Path
                  id="Vector_93"
                  d="M119.877 126.382a7.118 7.118 0 0012.279 5.292 7.132 7.132 0 001.932-5.292 7.107 7.107 0 00-14.211 0z"
                  fill="#1C1E1F"
                />
                <Path
                  id="Vector_94"
                  d="M126.971 132.833a6.452 6.452 0 10-.001-12.903 6.452 6.452 0 00.001 12.903z"
                  fill="url(#paint37_radial_5325_12254)"
                />
                <Path
                  id="Vector_95"
                  d="M126.971 132.833a6.452 6.452 0 10-.001-12.903 6.452 6.452 0 00.001 12.903z"
                  fill="url(#paint38_radial_5325_12254)"
                />
                <Path
                  id="Vector_96"
                  d="M126.971 130.191a3.81 3.81 0 100-7.621 3.81 3.81 0 000 7.621z"
                  fill="#1C1E1F"
                />
                <Path
                  id="Vector_97"
                  d="M129.932 125.027a.918.918 0 100-1.836.918.918 0 000 1.836z"
                  fill="#fff"
                />
                <Path
                  id="Vector_98"
                  d="M128.508 126.015a.458.458 0 10.001-.917.458.458 0 00-.001.917z"
                  fill="#fff"
                />
                <Path
                  id="Vector_99"
                  style={{
                    mixBlendMode: 'multiply',
                  }}
                  d="M116.732 128.332c.505-3.191 3.421-8.242 10.974-7.898a8.867 8.867 0 018.198 5.715 8.849 8.849 0 01.572 3.469c.23-2.916-.482-10.492-8.77-10.906-8.885-.413-11.479 6.635-11.043 9.344l.069.276z"
                  fill="url(#paint39_linear_5325_12254)"
                />
              </G>
              <Path
                id="highlight_leftEye"
                d="M115.426 128.103c-1.999-13 24.001-16.5 21.5 2.5 0 0-.999 4.5-10.999 4.5s-10.501-7-10.501-7z"
                fill="#A430FF"
                fillOpacity={0.25}
                stroke="#A430FF"
                opacity={highlightedParts.includes(BODY_PARTS.eye) ? 1 : 0}
              />
            </G>
            <G id="pressable_nose" clipPath="url(#clip1_5325_12254)">
              <G id="Nose_2">
                <Path
                  id="Vector_100"
                  d="M157.417 114.249c-1.814 1.492-6.291 8.632-5.281 12.168a29.238 29.238 0 01.758 11.777 3.623 3.623 0 011.584 2.296 3.281 3.281 0 01-1.102 3.329c-1.24.689-3.467.207-3.949.643-.482.436-1.01 1.607-2.663 1.607-1.653 0-1.974-.918-2.778-1.446-.803-.528-2.296 0-3.926-.896-1.63-.895-1.492-4.178.827-5.762a29.522 29.522 0 01.39-11.479c.827-2.824-3.076-9.046-5.969-13.041a3.015 3.015 0 01-.528-1.102c-.872-3.328 4.362-7.277 13.247-6.887 6.887.344 9.573 3.627 9.987 6.13a2.708 2.708 0 01-.597 2.663z"
                  fill="#D98B65"
                />
                <Path
                  id="Vector_101"
                  style={{
                    mixBlendMode: 'darken',
                  }}
                  d="M157.417 114.249c-1.814 1.492-6.291 8.632-5.281 12.168a29.238 29.238 0 01.758 11.777c-1.423 0-3.421.436-3.696-1.033a46.562 46.562 0 01-.873-8.678 14.81 14.81 0 011.401-6.75 61.681 61.681 0 016.405-9.183 2.898 2.898 0 011.906-.85c.12.443.127.909.019 1.355a2.727 2.727 0 01-.639 1.194z"
                  fill="url(#paint40_radial_5325_12254)"
                />
                <Path
                  id="Vector_102"
                  style={{
                    mixBlendMode: 'darken',
                  }}
                  d="M153.399 143.889c-1.24.688-3.467.206-3.949.642-.482.437-1.01 1.607-2.663 1.607-1.653 0-1.974-.918-2.778-1.446-.804-.528-2.296 0-3.926-.895-1.63-.896-1.492-4.179.827-5.763a29.522 29.522 0 01.39-11.479c.826-2.824-3.077-9.045-5.969-13.04a3.03 3.03 0 01-.528-1.102 7.686 7.686 0 014.293 1.515 34.424 34.424 0 015.28 9.413c.76 2.999.97 6.11.62 9.183-.138 3.742-.551 6.543-.689 8.082a2.496 2.496 0 00.492 1.982 2.502 2.502 0 001.804.956 4.824 4.824 0 003.031-1.331 3.65 3.65 0 014.063.321 2.511 2.511 0 00.827-2.043 3.281 3.281 0 01-1.125 3.398z"
                  fill="url(#paint41_linear_5325_12254)"
                />
                <Path
                  id="Vector_103"
                  style={{
                    mixBlendMode: 'darken',
                  }}
                  d="M153.399 143.889c-1.24.688-3.467.206-3.949.642-.482.437-1.01 1.607-2.663 1.607-1.653 0-1.974-.918-2.778-1.446-.804-.528-2.296 0-3.926-.895-1.63-.896-1.492-4.179.827-5.763a29.522 29.522 0 01.39-11.479c.826-2.824-3.077-9.045-5.969-13.04a3.03 3.03 0 01-.528-1.102 7.686 7.686 0 014.293 1.515 34.424 34.424 0 015.28 9.413c.76 2.999.97 6.11.62 9.183-.138 3.742-.551 6.543-.689 8.082a2.496 2.496 0 00.492 1.982 2.502 2.502 0 001.804.956 4.824 4.824 0 003.031-1.331 3.65 3.65 0 014.063.321 2.511 2.511 0 00.827-2.043 3.281 3.281 0 01-1.125 3.398z"
                  fill="url(#paint42_radial_5325_12254)"
                />
                <Path
                  id="Vector_104"
                  d="M140.888 138.036c-1.86 1.171-2.824 4.592-.574 5.877 1.09.399 2.238.616 3.398.643.574.184.895.712 1.377 1.056.469.354 1.043.54 1.63.528a2.708 2.708 0 002.296-1.102c.207-.206.344-.482.62-.597 1.162-.06 2.32-.191 3.467-.39 2.295-1.056 1.79-4.592-.184-5.854 3.214 0 3.65 5.005.941 6.244-1.469.528-2.939-.298-4.224 0-.528.276-.643.919-1.079 1.401a2.304 2.304 0 01-1.307.798 2.303 2.303 0 01-1.517-.201c-.895-.414-1.079-1.607-1.997-1.906-1.562-.206-3.375.666-4.776-.597a3.554 3.554 0 01-.763-3.515 3.55 3.55 0 012.692-2.385z"
                  fill="#915548"
                />
                <Path
                  id="Vector_105"
                  d="M141.484 143.429c0-.459.735-.666 1.309-.597a2.3 2.3 0 011.722 1.538 2.055 2.055 0 00-1.653-.574c-.62.046-1.286.092-1.378-.367z"
                  fill="#1C1E1F"
                />
                <Path
                  id="Vector_106"
                  d="M152.159 143.429c0-.459-.735-.666-1.332-.597a2.3 2.3 0 00-1.722 1.538 2.1 2.1 0 011.676-.574c.597.046 1.263.092 1.378-.367z"
                  fill="#1C1E1F"
                />
              </G>
              <Path
                id="Nose_3"
                d="M143.685 144.929c-6.5 2-7.835-5.741-3.335-7.241-.869-5.289-.27-10.504 1.335-15.759h10.5c1.328 5.341 1.717 10.803 1.5 16 4.5 2.5 1.5 9-4 7-1 3-5.5 2.5-6 0z"
                stroke="#A430FF"
                opacity={highlightedParts.includes(BODY_PARTS.nose) ? 1 : 0}
              />
              <Path
                id="hightlight_nose"
                d="M143.685 144.929c-6.5 2-7.835-5.741-3.335-7.241-.869-5.289-.27-10.504 1.335-15.759h10.5c1.328 5.341 1.717 10.803 1.5 16 4.5 2.5 1.5 9-4 7-1 3-5.5 2.5-6 0z"
                fill="#A430FF"
                fillOpacity={0.25}
                stroke="#A430FF"
                opacity={highlightedParts.includes(BODY_PARTS.nose) ? 1 : 0}
              />
            </G>
            <G id="pressable_rightCheeek">
              <G id="highlight_rightCheek" opacity={highlightedParts.includes(BODY_PARTS.cheek) ? 1 : 0}>
                <Path
                  d="M180.582 145.003a9.75 9.75 0 01-9.75 9.75 9.75 9.75 0 01-9.75-9.75c0-5.384 4.365-9.75 9.75-9.75s9.75 4.366 9.75 9.75z"
                  fill="#A430FF"
                  fillOpacity={0.25}
                  style={{
                    mixBlendMode: 'multiply',
                  }}
                />
                <Path
                  d="M180.582 145.003a9.75 9.75 0 01-9.75 9.75 9.75 9.75 0 01-9.75-9.75c0-5.384 4.365-9.75 9.75-9.75s9.75 4.366 9.75 9.75z"
                  stroke="#A430FF"
                  strokeWidth={1.5}
                />
              </G>
            </G>
            <G id="pressable_leftCheeek">
              <G id="highlight_leftCheek" opacity={highlightedParts.includes(BODY_PARTS.cheek) ? 1 : 0}>
                <Path
                  d="M131.537 145.546c0 5.384-4.365 9.75-9.75 9.75s-9.75-4.366-9.75-9.75a9.75 9.75 0 019.75-9.75 9.75 9.75 0 019.75 9.75z"
                  fill="#A430FF"
                  fillOpacity={0.25}
                  style={{
                    mixBlendMode: 'multiply',
                  }}
                />
                <Path
                  d="M131.537 145.546c0 5.384-4.365 9.75-9.75 9.75s-9.75-4.366-9.75-9.75a9.75 9.75 0 019.75-9.75 9.75 9.75 0 019.75 9.75z"
                  stroke="#A430FF"
                  strokeWidth={1.5}
                />
              </G>
            </G>
            <G id="pressable_chin">
              <Ellipse
                id="Vector_107"
                cx={146.889}
                cy={171.566}
                rx={9.51367}
                ry={3.73071}
                fill="#D38D65"
              />
              <Path
                id="highlight _chin"
                d="M155.902 171.638c0 .412-.193.846-.63 1.287-.438.443-1.098.865-1.953 1.23-1.709.729-4.104 1.192-6.775 1.192-2.671 0-5.066-.463-6.775-1.192-.855-.365-1.515-.787-1.954-1.23-.436-.441-.629-.875-.629-1.287 0-.411.193-.845.629-1.286.439-.443 1.099-.865 1.954-1.23 1.709-.73 4.104-1.193 6.775-1.193 2.671 0 5.066.463 6.775 1.193.855.365 1.515.787 1.953 1.23.437.441.63.875.63 1.286z"
                fill="#A430FF"
                fillOpacity={0.25}
                stroke="#A430FF"
                opacity={highlightedParts.includes(BODY_PARTS.chin) ? 1 : 0}
              />
            </G>
            <G id="pressable_hair">
              <G id="Hair_2">
                <Path
                  id="Vector_108"
                  d="M185.469 70.29a43.898 43.898 0 00-18.091-13.5 53.838 53.838 0 00-17.449-3.236 25.582 25.582 0 00-3.604.137c1.469-.459 2.916-.895 4.201-1.217a19.486 19.486 0 00-5.051-.964 31.91 31.91 0 00-25.047 11.824c-11.732 1.813-16.186 10.56-17.747 21.466a61.16 61.16 0 00-.344 19.721 85.198 85.198 0 002.778 13.385 44.488 44.488 0 013.742 12.994 34.904 34.904 0 001.791 4.775c.115-1.308.597-5.648.574-6.589v-10.813c-.131-2.273.181-4.55.918-6.704 1.423-3.306 4.201-6.107 4.959-9.78 1.355-7.002-2.066-19.469 8.632-19.354a52.328 52.328 0 0112.559.184c4.316.367 8.632.711 13.017.78 6.474 0 12.948-.23 19.423 0 4.476-.298 6.106 4.11 6.152 7.92.138 3.399 0 7.14 0 10.562a26.038 26.038 0 003.651 8.563c2.181 3.467 1.699 7.806 1.744 12.145v10.699c0 1.584.207 4.04.506 6.336a29.171 29.171 0 001.4-4.592c1.286-4.477 1.768-10.285 2.87-14.739 1.079-3.076 2.64-6.451 3.489-9.619 2.227-13.224 2.824-28.836-5.073-40.384z"
                  fill="#1C1E1F"
                />
                <Path
                  id="Vector_109"
                  d="M190.773 96.162a97.105 97.105 0 01-1.08 12.765 18.436 18.436 0 01-.688 2.755c-1.079 3.237-2.594 6.726-3.145 8.448-.551 1.722-2.135 12.65-2.572 15.267.184-4.591 0-13.889 0-16.07a19.767 19.767 0 00-1.24-8.219 51.983 51.983 0 01-4.063-8.61 4.162 4.162 0 010-1.01v-6.887c.011-.115.011-.23 0-.345v-1.538-1.01-.964-.505c-.253-3.054-1.607-7.692-6.245-7.944-2.043-.115-5.303 0-8.931 0h-1.859c-4.041 0-8.288.138-11.686 0h-1.951c-3.513-.184-7.117-.482-9.941-.735-1.998-.183-3.628-.321-4.592-.367h-.597c-1.423 0-3.719 0-5.372.138h-1.24a8.107 8.107 0 00-6.681 2.8c-2.433 3.123-2.295 8.105-2.433 11.48-.138 3.375.39 6.061-2.158 10.17-2.549 4.11-3.697 5.464-3.995 9.367-.298 3.903 0 16.071 0 17.517a48.767 48.767 0 00-3.788-14.578c-.987-1.469-2.732-11.686-3.329-17.839a69.596 69.596 0 012.433-22.315 17.884 17.884 0 0115.566-13.614 30.511 30.511 0 0126.494-11.617 24.311 24.311 0 00-9.688 2.824 60.978 60.978 0 0126.287 1.377c7.415 2.296 16.805 8.495 21.098 15.153 4.294 6.657 5.671 16.667 5.396 24.106z"
                  fill="#474A4C"
                />
                <G
                  id="Vector_110"
                  style={{
                    mixBlendMode: 'screen',
                  }}
                  opacity={0.21}
                >
                  <Path
                    d="M113.288 80.39a10.861 10.861 0 002.296 2.869c.482-.299.964-.597 1.492-.873a5.832 5.832 0 01-.987-2.823c-.958.183-1.897.46-2.801.826zm2.686-5.947c-.643-.712-.413-1.08.367-1.63a13.906 13.906 0 00-3.099.115c-.275.918.597 2.066 1.102 2.87.23.344 0 .78-.138 1.24a23.576 23.576 0 012.572 0c1.469-1.539.114-1.539-.804-2.595zm.85-4.293c.673.2 1.333.446 1.974.734.298-.597 0-1.125-.321-1.928-.322-.804-.299-1.194.367-1.768h-.941c-.965.505-1.722 1.125-1.286 1.974.161.303.232.646.207.988z"
                    fill="#474A4C"
                  />
                </G>
                <Path
                  id="Vector_111"
                  d="M190.773 96.162a97.105 97.105 0 01-1.08 12.765 25.608 25.608 0 00-3.03-10.653c2.916 1.332 3.926 0 3.926-1.699a62.681 62.681 0 00-2.801-17.724 37.65 37.65 0 00-17.058-17.953 37.882 37.882 0 00-27.068-.46 42.993 42.993 0 00-12.236 6.888 25.712 25.712 0 0115.841-10.331 26.195 26.195 0 00-21.328 14.05 24.832 24.832 0 0112.902-14.142 21.329 21.329 0 00-14.601 10.745 16.958 16.958 0 013.834-7.921 9.621 9.621 0 00-5.281 9.55c-1.354-3.053-8.081-2.479-10.997.322 3.145-.115 8.977.78 11.479 5.395a15.105 15.105 0 00-11.915-1.56c-3.582 1.101-3.582 4.132-.551 4.017 3.03-.115 9.183-1.906 12.903 2.296a19.746 19.746 0 00-10.768.895 10.033 10.033 0 00-5.946 7.645c-.574 2.296 2.296 0 5.602-2.709 3.306-2.71 8.425-5.946 13.775-4.592.15.041.29.111.413.207a7.726 7.726 0 00-1.24.207 8.105 8.105 0 00-6.658 2.732c-2.433 3.122-2.295 8.104-2.433 11.479-.138 3.375.39 6.061-2.158 10.17-2.549 4.11-3.697 5.464-3.995 9.367-.298 3.903 0 16.071 0 17.517a48.767 48.767 0 00-3.788-14.578c-.987-1.469-2.732-11.686-3.329-17.839a69.596 69.596 0 012.433-22.315 17.884 17.884 0 0115.566-13.614 30.511 30.511 0 0126.494-11.617 24.311 24.311 0 00-9.688 2.824 60.978 60.978 0 0126.287 1.377c7.415 2.296 16.805 8.495 21.098 15.153 4.294 6.657 5.671 16.667 5.396 24.106z"
                  fill="#35373A"
                />
                <Path
                  id="Vector_112"
                  d="M121.346 64.871c.321.574.597 1.194.872 1.79.276.598.505 1.263.712 1.906a37.116 37.116 0 011.033 3.88c.275 1.309.482 2.617.712 3.949.229 1.332.459 2.617.734 3.926a28.485 28.485 0 01-1.171-3.834c-.321-1.309-.573-2.617-.849-3.926-.275-1.309-.505-2.617-.827-3.903-.183-.643-.344-1.286-.551-1.906-.206-.62-.413-1.262-.665-1.882z"
                  fill="#474A4C"
                />
                <Path
                  id="Vector_113"
                  d="M109.982 71.85c.493-.19 1-.344 1.516-.46.505 0 1.033-.206 1.561-.275.528-.069 1.056 0 1.584 0a12.307 12.307 0 013.168.436c.505.138 1.01.322 1.515.505.49.205.966.443 1.424.712l.666.413.642.483c-.459-.253-.941-.46-1.423-.666-.482-.207-.964-.39-1.446-.551l-1.493-.436-1.515-.276c-.528 0-1.033-.138-1.538-.184h-1.561l-1.561.115c-.505-.023-1.033.07-1.539.184z"
                  fill="#474A4C"
                />
                <Path
                  id="Vector_114"
                  d="M107.273 80.252a5.926 5.926 0 003.766-.39c1.193-.46 2.295-1.125 3.604-1.562a9.986 9.986 0 013.949-.459 9.366 9.366 0 013.788 1.08 12.12 12.12 0 00-3.811-.69 11.014 11.014 0 00-3.765.552c-1.217.39-2.296.987-3.651 1.4a5.51 5.51 0 01-3.88.069z"
                  fill="#474A4C"
                />
                <Path
                  id="Vector_115"
                  d="M105.689 95.931a8.082 8.082 0 002.296-2.158c.643-.872 1.194-1.767 1.768-2.686a33.038 33.038 0 013.972-5.142 18.055 18.055 0 015.303-3.788 13.594 13.594 0 013.122-.942 10.242 10.242 0 013.238 0 13.04 13.04 0 00-6.176 1.4 18.65 18.65 0 00-4.913 3.72 38.675 38.675 0 00-4.041 4.982c-.62.872-1.217 1.768-1.928 2.594a7.699 7.699 0 01-2.641 2.02z"
                  fill="#474A4C"
                />
                <Path
                  id="Vector_116"
                  d="M117.489 88.587a24.532 24.532 0 01-.711 4.248 26.356 26.356 0 01-1.401 4.11 24.41 24.41 0 01-2.089 3.787 10.802 10.802 0 01-1.401 1.676 7.88 7.88 0 01-1.744 1.263 10.887 10.887 0 002.8-3.168 32.727 32.727 0 001.998-3.766 33.377 33.377 0 001.538-3.994 36.377 36.377 0 001.01-4.156z"
                  fill="#474A4C"
                />
                <Path
                  id="Vector_117"
                  d="M124.516 66.937c.25-.627.533-1.24.849-1.837.324-.595.685-1.17 1.079-1.722.39-.55.804-1.079 1.24-1.607.436-.528.941-.987 1.423-1.446a21.092 21.092 0 013.283-2.41c.578-.343 1.176-.65 1.791-.92a18.967 18.967 0 011.906-.71c-.62.275-1.217.573-1.814.894l-1.722 1.01a25.99 25.99 0 00-1.607 1.172c-.528.413-1.033.849-1.538 1.285-.505.436-.942.919-1.401 1.4-.459.483-.872 1.01-1.285 1.539a20.684 20.684 0 00-1.171 1.63 25.814 25.814 0 00-1.033 1.722z"
                  fill="#474A4C"
                />
                <Path
                  id="Vector_118"
                  d="M133.17 69.462l.987-.804 1.056-.78 1.056-.69 1.125-.642 1.148-.597 1.171-.528 1.217-.482 1.217-.413 1.239-.322 1.263-.275 1.286-.23 1.285-.138h3.857l1.286.138h-3.88l-1.285.138-1.24.184-1.263.23-1.24.298-1.217.344-1.216.39-1.194.436-1.171.483-1.148.528-1.148.597-1.102.642-1.079.69-1.01.803z"
                  fill="#474A4C"
                />
                <Path
                  id="Vector_119"
                  d="M171.098 65.305l1.262.551 1.217.597 1.171.689 1.148.712 1.125.803 1.079.827 1.033.895.987.964.918.987.896 1.056.826 1.08.781 1.125.712 1.147.643 1.217.597 1.217.528 1.263-.643-1.217-.666-1.171-.735-1.125-.803-1.125-.827-1.079-.849-1.056-.873-1.01-.941-.964-.941-.942-1.01-.918-1.034-.85-1.056-.826-1.102-.803-1.125-.735-1.17-.689-1.148-.62z"
                  fill="#474A4C"
                />
                <Path
                  id="Vector_120"
                  d="M177.365 78.3c.919.505 1.86.987 2.755 1.538a23.288 23.288 0 012.618 1.837 14.013 14.013 0 012.295 2.296 10.57 10.57 0 011.539 2.824 14.837 14.837 0 00-1.86-2.549 21.881 21.881 0 00-2.296-2.158 40.09 40.09 0 00-2.456-1.974c-.85-.62-1.722-1.217-2.595-1.814z"
                  fill="#474A4C"
                />
                <Path
                  id="Vector_121"
                  d="M176.424 84.018a9.186 9.186 0 013.696 2.043 26.533 26.533 0 012.893 3.122 36.16 36.16 0 002.778 3.146c.97.992 2.135 1.773 3.421 2.295a9.185 9.185 0 01-3.743-1.997 26.525 26.525 0 01-2.892-3.122 36.532 36.532 0 00-2.732-3.191 9.79 9.79 0 00-3.421-2.296z"
                  fill="#474A4C"
                />
                <Path
                  id="Vector_122"
                  d="M187.488 99.125l1.24 1.079 1.194 1.125c.395.424.687.935.849 1.492.137.547.184 1.113.138 1.676-.161-.528-.321-1.056-.505-1.538a4.606 4.606 0 00-.849-1.286l-1.057-1.263-1.01-1.285z"
                  fill="#474A4C"
                />
                <Path
                  id="Vector_123"
                  d="M178.123 91.57c.253.827.528 1.653.804 2.457l.803 2.457c.231.82.515 1.626.85 2.41.326.79.867 1.475 1.561 1.975a4.114 4.114 0 01-1.906-1.791 13.774 13.774 0 01-.987-2.434c-.276-.826-.482-1.676-.689-2.502-.206-.827-.298-1.722-.436-2.572z"
                  fill="#474A4C"
                />
                <Path
                  id="Vector_124"
                  d="M126.467 69.944l.666-1.332.757-1.308.85-1.24.895-1.194.987-1.148 1.056-1.102 1.102-1.01 1.171-.964 1.24-.873 1.286-.78 1.354-.69 1.378-.619 1.423-.482 1.469-.39 1.47-.276 1.492-.16-1.469.275-1.447.367-1.4.482-1.378.551-1.354.62-1.286.735-1.263.78-1.216.873-1.148.918-1.125.964-1.056 1.056-1.01 1.08-.942 1.148-.895 1.193-.85 1.24-.757 1.286z"
                  fill="#474A4C"
                />
                <Path
                  id="Vector_125"
                  d="M133.445 64.94l1.791-1.423 1.883-1.332 1.974-1.17 2.066-1.057 2.135-.895 2.296-.78 2.296-.644 2.296-.482 2.296-.344 2.295-.16h2.296l2.296.114 2.296.298 2.296.437 2.296.62 2.158.78-2.296-.62-2.296-.482-2.296-.367-2.296-.207H154.636l-2.296.207-2.296.321-2.296.436-2.296.574-2.295.712-2.113.85-2.066.987-2.02 1.102-1.929 1.24-1.584 1.285z"
                  fill="#474A4C"
                />
                <Path
                  id="Vector_126"
                  d="M180.327 92.901a18.554 18.554 0 008.334-2.8 39.528 39.528 0 01-8.472-5.442c-2.296-2.112-4.592-4.591-7.071-6.382-12.811-10.95-47.684 3.123-47.684 3.123l.344 1.01c6.268-1.952 12.627 2.295 16.071 7.37 5.395 8.654 11.272 16.07 20.662 17.631 2.364.353 4.769.306 7.117-.138 1.194-.206 4.822-.941 4.822-.941l-3.88-.964a18.658 18.658 0 006.244-1.125 26.593 26.593 0 01-6.405-1.929 23.553 23.553 0 0010.492-2.548l-2.962-.78a14.817 14.817 0 01-6.107-3.513c2.032.347 4.098.447 6.153.298a27.871 27.871 0 005.327-1.148 12.722 12.722 0 01-2.985-1.722z"
                  fill="#1C1E1F"
                />
                <Path
                  id="Vector_127"
                  d="M186.915 89.872h-.183a16.304 16.304 0 01-8.725 1.653 9.543 9.543 0 01-1.492-.299c.169.275.369.53.597.758.278.285.586.539.918.758a8.118 8.118 0 001.975.918l1.263.459h-1.401c-.643 0-1.263 0-1.86.115-.597.115-1.239 0-1.836 0a15.224 15.224 0 01-6.888-1.584c.069.278.169.547.299.803a14.232 14.232 0 007.645 5.923l.734.207-.321.138a29.458 29.458 0 01-10.607.987l.299.298a11.25 11.25 0 005.441 2.595c-.524.087-1.053.141-1.584.16a23.138 23.138 0 01-6.635-.688c.395.407.833.769 1.308 1.079.569.353 1.168.652 1.791.895a20.34 20.34 0 002.181.712h-.551a26.054 26.054 0 01-4.821 0c-9.528-.896-14.785-7.324-20.226-15.566l-.964-1.47c-.23-.344-.483-.71-.735-1.032a18.045 18.045 0 00-9.872-6.612 11.986 11.986 0 00-6.888.16c1.194-.459 2.686-2.295 3.766-3.168a42.125 42.125 0 015.464-3.306 39.182 39.182 0 019.665-3.42 34.878 34.878 0 015.946-.781 33.45 33.45 0 0118.16 4.293c.827.46 1.653.964 2.457 1.515.803.551 1.171.804 1.722 1.263.75.558 1.471 1.156 2.158 1.79a42.976 42.976 0 013.788 4.386 15.758 15.758 0 002.755 2.778c1.653 1.262 3.535 2.135 5.234 3.26l.023.023z"
                  fill="#474A4C"
                />
                <G
                  id="Vector_128"
                  style={{
                    mixBlendMode: 'screen',
                  }}
                  opacity={0.21}
                >
                  <Path
                    d="M159.205 80.782a1.126 1.126 0 01-.345.436 33.447 33.447 0 00-10.675-5.142s.184 2.755 0 5.097l.895 1.078a56.887 56.887 0 013.582 5.19c.413-1.631.757-3.422 1.079-5.075a80.57 80.57 0 018.77 4.293c0-.711.115-1.675.184-2.732a41.85 41.85 0 01-1.929-1.676c-.643-.596-1.033-.964-1.561-1.469zm-11.479 3.398a18.37 18.37 0 00-6.268-2.296h-.688a23.71 23.71 0 011.538 5.051c.229.367.482.735.711 1.125.23.39.873 1.377 1.332 2.09a21.963 21.963 0 000-5.075 23.39 23.39 0 017.438 5.717c.289-.678.527-1.376.712-2.09-1.4-1.882-2.916-3.856-4.385-5.51-.1.36-.272.696-.505.988h.115zm22.315-12.971a2.959 2.959 0 01-1.148 3.65 3.005 3.005 0 01-2.479.344 53.37 53.37 0 00-7.714-1.331c.55 1.558.86 3.192.918 4.844l3.191 3.513a13.878 13.878 0 00-.597-5.005s.942.183 5.419.85c2.295.366 3.168-.529 3.604-1.7a8.161 8.161 0 011.171-2.961c1.538-1.722 5.257-.483 8.196 1.24-4.087-4.5-11.64-6.59-10.676-3.444h.115z"
                    fill="#474A4C"
                  />
                </G>
                <Path
                  id="Vector_129"
                  d="M124.102 73.686a.566.566 0 000 .184c.183 1.24 1.01 4.592 1.653 7.37h.872c1.653-.184 3.949-.138 5.372-.138h.597a18.364 18.364 0 0110.584 8.104c5.808 8.93 11.112 16.071 21.19 17.035a25.115 25.115 0 01-7.553-2.479c-4.293-2.113-10.308-9.872-13.935-15.819a22.502 22.502 0 00-5.671-6.474 12.514 12.514 0 00-6.888-2.571c4.133-3.903 11.296-1.125 14.923 1.515a15.257 15.257 0 011.998 1.79c3.145 3.192 6.543 8.312 8.839 11.159a68.29 68.29 0 00-6.888-11.09 25.93 25.93 0 00-4.592-4.729 12.898 12.898 0 00-9.504-2.755 16.075 16.075 0 00-5.786 1.929 21.38 21.38 0 018.816-4.89c2.137-.713 4.42-.871 6.635-.46 3.49.606 6.732 2.2 9.344 4.592 1.768 1.653 4.316 4.156 6.888 6.383a26.01 26.01 0 006.589 4.844 58.968 58.968 0 01-4.73-4.867A115.874 115.874 0 00152.8 71.964a10.882 10.882 0 00-2.021-1.308c-4.591-2.296-10.882-1.814-18.091 1.607 6.704-7.232 17.632-8.22 21.397-8.426a22.362 22.362 0 00-16.071 1.722 28.095 28.095 0 00-10.56 10.928c-.597 1.102-2.136-2.433-1.24-5.28-.987.184-1.837-2.296-1.699-3.398-.827.321-1.699.16-1.814-.275l1.401 6.152z"
                  fill="#333539"
                />
                <G
                  id="Vector_130"
                  style={{
                    mixBlendMode: 'multiply',
                  }}
                >
                  <Path
                    d="M167.607 105.461c.713.278 1.442.516 2.181.711h-.55c-.574-.206-1.125-.528-1.631-.711z"
                    fill="#474A4C"
                  />
                </G>
                <Path
                  id="Vector_131"
                  d="M159.893 99.286c2.98 3.001 6.999 4.743 11.226 4.867a23.174 23.174 0 01-6.635-.688c.395.407.834.769 1.309 1.079a14.12 14.12 0 01-1.584-.942 21.327 21.327 0 01-4.316-4.316z"
                  fill="#383B3D"
                />
                <Path
                  id="Vector_132"
                  d="M177.571 100.021a29.457 29.457 0 01-10.606.987l.298.298-.597-.16a10.455 10.455 0 01-3.926-3.743c4.569 3.077 11.388 2.663 14.831 2.618z"
                  fill="#383B3D"
                />
                <G
                  id="Vector_133"
                  style={{
                    mixBlendMode: 'multiply',
                  }}
                >
                  <Path
                    d="M177.203 99.652l.735.206-.322.138c-.12-.134-.26-.25-.413-.344z"
                    fill="#474A4C"
                  />
                </G>
                <Path
                  id="Vector_134"
                  style={{
                    mixBlendMode: 'multiply',
                  }}
                  d="M190.06 95.016a14.86 14.86 0 01-4.592-1.286c-.665-.46-1.354 1.883-2.709 2.296-1.354.413-.826 4.431.918 6.015-1.216.321-1.974-1.056-3.627-.964a2.45 2.45 0 00-1.997 1.308 4.162 4.162 0 010-1.01v-6.887h-1.837a15.217 15.217 0 01-6.887-1.585c.069.279.169.548.298.804a4.33 4.33 0 01-.597-.643c-.574-1.033-.941-1.928-1.171-2.296 1.722 1.63 6.727 2.893 10.171 3.421.712 0 1.354.184 1.882.23a7.39 7.39 0 001.355 0l-.275-.161-.942-.505-1.974-1.125-.919-.551-.895-.597a7.483 7.483 0 01-1.791-1.722 19.3 19.3 0 003.536.896c2.921.482 5.915.245 8.724-.69a.228.228 0 00.184 0c0-.481-2.159-1.56-2.159-1.56a40.065 40.065 0 01-5.831-4.477c-.183-.16-.321-.322-.482-.482a50.062 50.062 0 00-5.739-5.855l.275.161a35.535 35.535 0 017.278 5.625 18.382 18.382 0 00-4.385-5.648c2.64.299 8.219 4.592 9.183 5.418.964.827-1.355-6.107-4.224-9.183a41.79 41.79 0 00-12.122-9.183 22.152 22.152 0 0113.935 8.356 33.19 33.19 0 015.579 15.91 6.149 6.149 0 01-.528 3.467c-.229.46 1.722 1.975 2.365 2.503z"
                  fill="url(#paint43_linear_5325_12254)"
                />
                <Path
                  id="Vector_135"
                  d="M127.775 78.876a16.074 16.074 0 018.082-3.33 10.7 10.7 0 014.408.667 17.4 17.4 0 013.903 2.135 27.566 27.566 0 016.313 6.199 61.7 61.7 0 014.592 7.576 71.324 71.324 0 00-4.867-7.324 29.857 29.857 0 00-6.268-5.992 18.036 18.036 0 00-3.765-2.135 10.218 10.218 0 00-4.224-.758 16.209 16.209 0 00-8.174 2.962z"
                  fill="#333539"
                />
                <Path
                  id="Vector_136"
                  d="M129.336 75.088a36.364 36.364 0 014.592-2.893 22.225 22.225 0 015.073-1.929c1.769-.45 3.6-.605 5.419-.459 1.83.177 3.613.69 5.257 1.516a29.144 29.144 0 018.403 6.887 91.779 91.779 0 007.415 7.829 77.138 77.138 0 01-7.714-7.53 30.45 30.45 0 00-8.357-6.613 14.92 14.92 0 00-5.05-1.492 16.993 16.993 0 00-5.281.322 28.13 28.13 0 00-9.757 4.362z"
                  fill="#333539"
                />
              </G>
              <G id="hightlight_hair" opacity={highlightedParts.includes(BODY_PARTS.hair) ? 1 : 0}>
                <Path
                  d="M116.617 102.828c-5.807 7.04-6.108 15.876-6.1 32.536l-4.749-17.127c-2.093-8.215-2.839-12.529-3.411-19.705.084-21.003 2.437-30.43 18.569-35.115 9.381-10.613 15.958-12.39 29.052-11.242l-8.716 2.652c23.011-2.44 33.436 1.101 44.968 17.305 8.07 12.698 7.523 30.658.358 47.64l-3.787 19.819-.088-24.135-5.427-15.164-8.314 1.213c2.97 1.757 4.871 1.484 7.935 2.777l-6.982.873c.886.375 1.766.663 3.63 1.232-7.215 1.573-14.422 1.208-19.148-2.542-16.736-17.036-18.088-24.956-28.808-21.86-8.014-.019-8.775 6.727-8.982 20.843z"
                  fill="#A430FF"
                  fillOpacity={0.25}
                />
                <Path
                  d="M177.286 100.292l-8.314 1.213c2.97 1.757 4.871 1.484 7.935 2.777l-6.982.873c.886.375 1.766.663 3.63 1.232-7.215 1.573-14.422 1.208-19.148-2.542-16.736-17.036-18.088-24.956-28.808-21.86-8.014-.019-8.775 6.727-8.982 20.843-5.807 7.04-6.108 15.876-6.1 32.536l-4.749-17.127c-2.093-8.215-2.839-12.529-3.411-19.705.084-21.003 2.437-30.43 18.569-35.115 9.381-10.613 15.958-12.39 29.052-11.242l-8.716 2.652c23.011-2.44 33.436 1.101 44.968 17.305 8.07 12.698 7.523 30.658.358 47.64l-3.787 19.819-.088-24.135-5.427-15.164zm0 0l3.113-.454c-5.14-1.967-7.613-3.765-11.565-7.757 4.763 1.38 7.436 2.02 12.218 2.469l-3.84-2.919c4.148.578 6.444.152 10.499-1.644-3.832-.823-5.783-2.619-9.12-6.786"
                  stroke="#A430FF"
                  strokeWidth={1.5}
                />
              </G>
            </G>
          </G>
        </G>
        <G id="Group 27694">
          <G id="Group_2">
            <G id="Ellipse 83" fill="#fff">
              <Circle cx={144.217} cy={65.4067} r={2} />
              <Circle cx={144.217} cy={65.4067} r={2} />
            </G>
            <Path
              id="Line 78"
              stroke="#fff"
              d="M143.738 23.8838L143.738 65.7704"
            />
          </G>
          <G id="pressableContainerSelected_hair">
            <G id="pressableButton_hair">
              <BodyPartSvgButton
                type={BODY_PARTS_SVG_BUTTON_TYPES.activate}
                bodyPart={BODY_PARTS.hair}
                onPress={onPress}
                onActivate={setActive}
                activateAsSelect
                x={112}
                y={0}
                width={61}
                height={32}
              />
            </G>
          </G>
        </G>
        <G id="Group 27695">
          <G id="Group_3">
            <G
              id="Ellipse 83_2"
              transform="rotate(-35.88 123.498 161.525)"
              fill="#fff"
            >
              <Circle cx={123.498} cy={161.525} r={2} />
              <Circle cx={123.498} cy={161.525} r={2} />
            </G>
            <Path
              id="Line 78_2"
              stroke="#fff"
              d="M55.1587 240.063L124.295 160.956"
            />
          </G>
          <G id="pressableContainerSelected_jaw">
            <G id="pressableButton_jaw">
              <BodyPartSvgButton
                type={BODY_PARTS_SVG_BUTTON_TYPES.activate}
                bodyPart={BODY_PARTS.jaw}
                onPress={onPress}
                onActivate={setActive}
                activateAsSelect
                x={3}
                y={227}
                width={61}
                height={32}
              />
            </G>
          </G>
        </G>
        <G id="Group 27696">
          <G id="Group_4">
            <G
              id="Ellipse 83_3"
              transform="rotate(-180 171.824 146.664)"
              fill="#fff"
            >
              <Circle cx={171.824} cy={146.664} r={2} />
              <Circle cx={171.824} cy={146.664} r={2} />
            </G>
            <Path
              id="Line 78_3"
              stroke="#fff"
              d="M228.067 174.848L170.507 146.618"
            />
          </G>
          <G id="pressableContainerSelected_cheek">
            <G id="pressableButton_cheek">
              <BodyPartSvgButton
                type={BODY_PARTS_SVG_BUTTON_TYPES.activate}
                bodyPart={BODY_PARTS.cheek}
                onPress={onPress}
                onActivate={setActive}
                activateAsSelect
                x={222}
                y={159.5}
                width={61}
                height={32}
              />
            </G>
          </G>
        </G>
        <G id="Group 27697">
          <G id="Group_5">
            <G
              id="Ellipse 83_4"
              transform="rotate(-18.52 146.608 172.711)"
              fill="#fff"
            >
              <Circle cx={146.608} cy={172.711} r={2} />
              <Circle cx={146.608} cy={172.711} r={2} />
            </G>
            <Path
              id="Line 78_4"
              stroke="#fff"
              d="M105.122 238.25L145.921 173.544"
            />
          </G>
          <G id="pressableContainerSelected_chin">
            <G id="pressableButton_chin">
              <BodyPartSvgButton
                type={BODY_PARTS_SVG_BUTTON_TYPES.activate}
                bodyPart={BODY_PARTS.chin}
                onPress={onPress}
                onActivate={setActive}
                activateAsSelect
                x={78}
                y={226}
                width={61}
                height={32}
              />
            </G>
          </G>
        </G>
        <G id="Group 27698">
          <G id="Group_6">
            <G
              id="Ellipse 83_5"
              transform="rotate(150 138.803 97.061)"
              fill="#fff"
            >
              <Circle cx={138.803} cy={97.0615} r={2} />
              <Circle cx={138.803} cy={97.0615} r={2} />
            </G>
            <Path
              id="Line 78_5"
              stroke="#fff"
              d="M199.997 35.4539L138.801 96.911"
            />
          </G>
          <G id="pressableContainerSelected_forehead">
            <G id="pressableButton_forehead">
              <BodyPartSvgButton
                type={BODY_PARTS_SVG_BUTTON_TYPES.activate}
                bodyPart={BODY_PARTS.forehead}
                onPress={onPress}
                onActivate={setActive}
                activateAsSelect
                x={isMobile ? 198 : 190}
                y={20}
                width={isMobile ? 87 : 95}
                height={32}
              />
            </G>
          </G>
        </G>
        <G id="Group 27699">
          <G id="Group_7">
            <G
              id="Ellipse 83_6"
              transform="rotate(143.698 154.149 180.265)"
              fill="#fff"
            >
              <Circle cx={154.149} cy={180.265} r={2} />
              <Circle cx={154.149} cy={180.265} r={2} />
            </G>
            <Path
              id="Line 78_6"
              stroke="#fff"
              d="M182.608 230.61L154.021 180.305"
            />
          </G>
          <G id="pressableContainerSelected_neck">
            <G id="pressableButton_neck">
              <BodyPartSvgButton
                type={BODY_PARTS_SVG_BUTTON_TYPES.activate}
                bodyPart={BODY_PARTS.neck}
                onPress={onPress}
                onActivate={setActive}
                activateAsSelect
                x={149}
                y={226}
                width={61}
                height={32}
              />
            </G>
          </G>
        </G>
        <G id="Group 27708">
          <G id="Group_8">
            <G
              id="Ellipse 83_7"
              transform="rotate(63.267 122.27 122.001)"
              fill="#fff"
            >
              <Circle cx={122.27} cy={122.001} r={2} />
              <Circle cx={122.27} cy={122.001} r={2} />
            </G>
            <Path
              id="Line 78_7"
              stroke="#fff"
              d="M61.9535 51.5674L122.037 122.377"
            />
          </G>
          <G id="pressableArea_eye">

            {active !== BODY_PARTS.eye ?
              <G id="pressableButton_eye">
                <BodyPartSvgButton
                  type={BODY_PARTS_SVG_BUTTON_TYPES.activate}
                  bodyPart={BODY_PARTS.eye}
                  onPress={onPress}
                  onActivate={setActive}
                  x={4}
                  y={42}
                  width={61}
                  height={32}
                />
              </G>
              :
              <G id="pressable_containerSelected_eye">
                <G id="pressableSelect_eye">
                  <BodyPartSvgButton
                    type={BODY_PARTS_SVG_BUTTON_TYPES.select}
                    bodyPart={BODY_PARTS.eye}
                    onPress={onPress}
                    onActivate={setActive}
                    x={0.496094}
                    y={77.5339}
                    width={67.9961}
                    height={50}
                  />
                </G>
                <G id="pressableEnlarge_eye">
                  <BodyPartSvgButton
                    type={BODY_PARTS_SVG_BUTTON_TYPES.enlarge}
                    bodyPart={BODY_PARTS.eye}
                    onPress={onPress}
                    onActivate={setActive}
                    x={0.496094}
                    y={39.5339}
                    width={68}
                    height={38}
                  />
                </G>
              </G>
            }
          </G>
        </G>
        <G id="Group 27705">
          <G id="Group_9">
            <G
              id="Ellipse 83_8"
              transform="rotate(158.675 190.121 127.129)"
              fill="#fff"
            >
              <Circle cx={190.121} cy={127.129} r={2} />
              <Circle cx={190.121} cy={127.129} r={2} />
            </G>
            <Path
              id="Line 78_8"
              stroke="#fff"
              d="M226.234 82.5093L190.24 126.893"
            />
          </G>
          <G id="pressableArea_ear">
            {active !== BODY_PARTS.ear ?
              <G id="pressableButton_ear">
                <BodyPartSvgButton
                  type={BODY_PARTS_SVG_BUTTON_TYPES.activate}
                  bodyPart={BODY_PARTS.ear}
                  onPress={onPress}
                  onActivate={setActive}
                  x={223}
                  y={66}
                  width={61}
                  height={32}
                />
              </G>
              :
              <G id="pressable_containerSelected_ear">
                <G id="pressableSelect_ear">
                  <BodyPartSvgButton
                    type={BODY_PARTS_SVG_BUTTON_TYPES.select}
                    bodyPart={BODY_PARTS.ear}
                    onPress={onPress}
                    onActivate={setActive}
                    x={219.727}
                    y={101.009}
                    width={67.9961}
                    height={46.3878}
                  />
                </G>
                <G id="pressableEnlarge_ear">
                  <BodyPartSvgButton
                    type={BODY_PARTS_SVG_BUTTON_TYPES.enlarge}
                    bodyPart={BODY_PARTS.ear}
                    onPress={onPress}
                    onActivate={setActive}
                    x={219.727}
                    y={63.0089}
                    width={68}
                    height={38}
                  />
                </G>
              </G>
            }
          </G>
        </G>
        <G id="Group 27707">
          <G id="Group_10">
            <G
              id="Ellipse 83_9"
              transform="rotate(-18.52 145.881 140.928)"
              fill="#fff"
            >
              <Circle cx={145.881} cy={140.928} r={2} />
              <Circle cx={145.881} cy={140.928} r={2} />
            </G>
            <Path
              id="Line 78_9"
              stroke="#fff"
              d="M54.6797 140.878L145.434 140.878"
            />
          </G>
          <G id="pressableArea_nose">
            {active !== BODY_PARTS.nose ?
              <G id="pressableButton_nose">
                <BodyPartSvgButton
                  type={BODY_PARTS_SVG_BUTTON_TYPES.activate}
                  bodyPart={BODY_PARTS.nose}
                  onPress={onPress}
                  onActivate={setActive}
                  x={4}
                  y={132.5}
                  width={61}
                  height={32}
                />
              </G>
              :
              <G id="pressable_containerSelected_nose">
                <G id="pressableSelect_nose">
                  <BodyPartSvgButton
                    type={BODY_PARTS_SVG_BUTTON_TYPES.select}
                    bodyPart={BODY_PARTS.nose}
                    onPress={onPress}
                    onActivate={setActive}
                    x={0.664062}
                    y={167.984}
                    width={67.9961}
                    height={46.3878}
                  />
                </G>
                <G id="pressableEnlarge_nose">
                  <BodyPartSvgButton
                    type={BODY_PARTS_SVG_BUTTON_TYPES.enlarge}
                    bodyPart={BODY_PARTS.nose}
                    onPress={onPress}
                    onActivate={setActive}
                    x={0.664062}
                    y={129.984}
                    width={68}
                    height={38}
                  />
                </G>
              </G>

            }
          </G>
        </G>
        <G id="Group 27706">
          <G id="Group_11">
            <G
              id="Ellipse 83_10"
              transform="rotate(-120 151.951 157.539)"
              fill="#fff"
            >
              <Circle cx={151.951} cy={157.539} r={2} />
              <Circle cx={151.951} cy={157.539} r={2} />
            </G>
            <Path
              id="Line 78_10"
              stroke="#fff"
              d="M245.397 231.473L152.214 157.196"
            />
          </G>
          <G id="pressableArea_mouth">
            {active !== BODY_PARTS.mouth ?
              <G id="pressableButton_mouth">
                <G id="pressableButton_ear">
                  <BodyPartSvgButton
                    type={BODY_PARTS_SVG_BUTTON_TYPES.activate}
                    bodyPart={BODY_PARTS.mouth}
                    onPress={onPress}
                    onActivate={setActive}
                    x={223.5}
                    y={226}
                    width={61}
                    height={32}
                  />
                </G>
              </G>
              :
              <G id="pressable_containerSelected_mouth">
                <G id="pressableSelect_mouth">
                  <BodyPartSvgButton
                    type={BODY_PARTS_SVG_BUTTON_TYPES.select}
                    bodyPart={BODY_PARTS.mouth}
                    onPress={onPress}
                    onActivate={setActive}
                    x={220.436}
                    y={261.255}
                    width={67.9961}
                    height={46.3878}
                  />
                </G>
                <G id="pressableEnlarge_mouth">
                  <BodyPartSvgButton
                    type={BODY_PARTS_SVG_BUTTON_TYPES.enlarge}
                    bodyPart={BODY_PARTS.mouth}
                    onPress={onPress}
                    onActivate={setActive}
                    x={220.436}
                    y={223.255}
                    width={68}
                    height={38}
                  />
                </G>
              </G>
            }
          </G>
        </G>
      </G>
      <Defs>
        <LinearGradient
          id="paint0_linear_5325_12254"
          x1={143.877}
          y1={176.88}
          x2={147.192}
          y2={192.32}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#E3A475" />
          <Stop offset={1} stopColor="#D28C64" />
        </LinearGradient>
        <LinearGradient
          id="paint1_linear_5325_12254"
          x1={144.303}
          y1={166.577}
          x2={146.891}
          y2={202.914}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C97551" />
          <Stop offset={1} stopColor="#C97551" stopOpacity={0} />
        </LinearGradient>
        <LinearGradient
          id="paint2_linear_5325_12254"
          x1={97.5851}
          y1={145.439}
          x2={112.554}
          y2={121.654}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#DA8D66" />
          <Stop offset={1} stopColor="#E2A174" />
        </LinearGradient>
        <LinearGradient
          id="paint3_linear_5325_12254"
          x1={112.692}
          y1={129.851}
          x2={89.5038}
          y2={141.674}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C97551" />
          <Stop offset={1} stopColor="#C97551" stopOpacity={0} />
        </LinearGradient>
        <LinearGradient
          id="paint4_linear_5325_12254"
          x1={195.79}
          y1={145.773}
          x2={180.564}
          y2={121.87}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#DA8D66" />
          <Stop offset={1} stopColor="#E2A174" />
        </LinearGradient>
        <LinearGradient
          id="paint5_linear_5325_12254"
          x1={180.686}
          y1={129.998}
          x2={203.984}
          y2={141.735}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C97551" />
          <Stop offset={1} stopColor="#C97551" stopOpacity={0} />
        </LinearGradient>
        <RadialGradient
          id="paint6_radial_5325_12254"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(48.6945 0 0 48.6944 146.777 127.286)"
        >
          <Stop offset={0.76} stopColor="#D38D65" />
          <Stop offset={1} stopColor="#D38D65" />
        </RadialGradient>
        <LinearGradient
          id="paint7_linear_5325_12254"
          x1={44.9797}
          y1={89.1315}
          x2={200.384}
          y2={136.242}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C97551" />
          <Stop offset={1} stopColor="#C97551" stopOpacity={0} />
        </LinearGradient>
        <RadialGradient
          id="paint8_radial_5325_12254"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(122.946 144.85) scale(10.8363)"
        >
          <Stop stopColor="#FFC0BF" />
          <Stop offset={1} stopColor="#FFC0BF" stopOpacity={0} />
        </RadialGradient>
        <RadialGradient
          id="paint9_radial_5325_12254"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(169.666 144.85) scale(10.8363)"
        >
          <Stop stopColor="#FFC0BF" />
          <Stop offset={1} stopColor="#FFC0BF" stopOpacity={0} />
        </RadialGradient>
        <RadialGradient
          id="paint10_radial_5325_12254"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(11.7316 0 0 11.7317 135.091 121.067)"
        >
          <Stop stopColor="#C97551" />
          <Stop offset={1} stopColor="#C97551" stopOpacity={0} />
        </RadialGradient>
        <RadialGradient
          id="paint11_radial_5325_12254"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(144.388 144.367) scale(10.7904)"
        >
          <Stop stopColor="#C97551" />
          <Stop offset={1} stopColor="#C97551" stopOpacity={0} />
        </RadialGradient>
        <RadialGradient
          id="paint12_radial_5325_12254"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(159.128 121.067) scale(11.7317)"
        >
          <Stop stopColor="#C97551" />
          <Stop offset={1} stopColor="#C97551" stopOpacity={0} />
        </RadialGradient>
        <RadialGradient
          id="paint13_radial_5325_12254"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(9.43581 0 0 9.43583 147.03 160.669)"
        >
          <Stop stopColor="#C97551" />
          <Stop offset={1} stopColor="#C97551" stopOpacity={0} />
        </RadialGradient>
        <RadialGradient
          id="paint14_radial_5325_12254"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(3.0305 0 0 3.03048 130.499 150.934)"
        >
          <Stop stopColor="#C97551" />
          <Stop offset={1} stopColor="#C97551" stopOpacity={0} />
        </RadialGradient>
        <RadialGradient
          id="paint15_radial_5325_12254"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(3.0305 0 0 3.03048 161.884 150.934)"
        >
          <Stop stopColor="#C97551" />
          <Stop offset={1} stopColor="#C97551" stopOpacity={0} />
        </RadialGradient>
        <RadialGradient
          id="paint16_radial_5325_12254"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(9.98688 0 0 9.98684 158.946 120.35)"
        >
          <Stop stopColor="#C97551" />
          <Stop offset={1} stopColor="#C97551" stopOpacity={0} />
        </RadialGradient>
        <LinearGradient
          id="paint17_linear_5325_12254"
          x1={154.193}
          y1={147.602}
          x2={136.883}
          y2={125.493}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C97551" />
          <Stop offset={1} stopColor="#C97551" stopOpacity={0} />
        </LinearGradient>
        <RadialGradient
          id="paint18_radial_5325_12254"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(13.0402 0 0 13.0403 136.102 120.282)"
        >
          <Stop stopColor="#C97551" />
          <Stop offset={1} stopColor="#C97551" stopOpacity={0} />
        </RadialGradient>
        <LinearGradient
          id="paint19_linear_5325_12254"
          x1={167.025}
          y1={115.648}
          x2={169.688}
          y2={110.872}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#3C3F41" />
          <Stop offset={1} stopColor="#4B4E50" />
        </LinearGradient>
        <LinearGradient
          id="paint20_linear_5325_12254"
          x1={169.322}
          y1={107.083}
          x2={165.213}
          y2={125.427}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#A1A1AD" />
          <Stop offset={1} stopColor="#A1A1AD" stopOpacity={0} />
        </LinearGradient>
        <LinearGradient
          id="paint21_linear_5325_12254"
          x1={126.274}
          y1={115.096}
          x2={127.284}
          y2={110.206}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#3C3F41" />
          <Stop offset={1} stopColor="#4B4E50" />
        </LinearGradient>
        <LinearGradient
          id="paint22_linear_5325_12254"
          x1={127.537}
          y1={106.968}
          x2={125.218}
          y2={121.385}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#A1A1AD" />
          <Stop offset={1} stopColor="#A1A1AD" stopOpacity={0} />
        </LinearGradient>
        <RadialGradient
          id="paint23_radial_5325_12254"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(146.754 156.196) scale(11.6169)"
        >
          <Stop stopColor="#44211F" />
          <Stop offset={1} stopColor="#21100F" />
        </RadialGradient>
        <RadialGradient
          id="paint24_radial_5325_12254"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(7.64508 0 0 7.6451 148.064 162.375)"
        >
          <Stop stopColor="#D15F5D" />
          <Stop offset={1} stopColor="#B5504E" />
        </RadialGradient>
        <RadialGradient
          id="paint25_radial_5325_12254"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(12.5351 0 0 12.5352 148.316 158.745)"
        >
          <Stop offset={0.62} stopColor="#fff" />
          <Stop offset={1} stopColor="#A1A1AD" />
        </RadialGradient>
        <LinearGradient
          id="paint26_linear_5325_12254"
          x1={146.916}
          y1={144.121}
          x2={146.916}
          y2={156.748}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#A1A1AD" />
          <Stop offset={1} stopColor="#A1A1AD" stopOpacity={0} />
        </LinearGradient>
        <RadialGradient
          id="paint27_radial_5325_12254"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(148.866 154.589) scale(10.5378)"
        >
          <Stop offset={0.62} stopColor="#fff" />
          <Stop offset={1} stopColor="#A1A1AD" />
        </RadialGradient>
        <LinearGradient
          id="paint28_linear_5325_12254"
          x1={146.526}
          y1={164.117}
          x2={146.71}
          y2={158.653}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#A1A1AD" />
          <Stop offset={1} stopColor="#A1A1AD" stopOpacity={0} />
        </LinearGradient>
        <RadialGradient
          id="paint29_radial_5325_12254"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(147.57 141.545) scale(10.8592)"
        >
          <Stop stopColor="#F0A873" />
          <Stop offset={1} stopColor="#B86947" />
        </RadialGradient>
        <RadialGradient
          id="paint30_radial_5325_12254"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(8.70116 0 0 8.70117 146.95 158.671)"
        >
          <Stop offset={0.39} stopColor="#BE734C" />
          <Stop offset={1} stopColor="#C97551" />
        </RadialGradient>
        <LinearGradient
          id="paint31_linear_5325_12254"
          x1={167.791}
          y1={83.5595}
          x2={190.014}
          y2={83.5595}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#A1A1AD" />
          <Stop offset={1} stopColor="#A1A1AD" stopOpacity={0} />
        </LinearGradient>
        <RadialGradient
          id="paint32_radial_5325_12254"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(170.448 126.38) scale(15.6346)"
        >
          <Stop offset={0.74} stopColor="#fff" />
          <Stop offset={1} stopColor="#A1A1AD" />
        </RadialGradient>
        <RadialGradient
          id="paint33_radial_5325_12254"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(6.45126 0 0 6.45127 167.531 126.381)"
        >
          <Stop offset={0.82} stopColor="#698182" />
          <Stop offset={1} stopColor="#2E3536" />
        </RadialGradient>
        <RadialGradient
          id="paint34_radial_5325_12254"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(167.83 129.733) scale(8.33384)"
        >
          <Stop offset={0.81} stopColor="#2E3536" stopOpacity={0} />
          <Stop offset={1} stopColor="#2E3536" />
        </RadialGradient>
        <LinearGradient
          id="paint35_linear_5325_12254"
          x1={167.922}
          y1={118.99}
          x2={167.922}
          y2={128.173}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#A1A1AD" />
          <Stop offset={1} stopColor="#A1A1AD" stopOpacity={0} />
        </LinearGradient>
        <RadialGradient
          id="paint36_radial_5325_12254"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(128.785 126.839) scale(14.4178)"
        >
          <Stop offset={0.71} stopColor="#fff" />
          <Stop offset={1} stopColor="#A1A1AD" />
        </RadialGradient>
        <RadialGradient
          id="paint37_radial_5325_12254"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(6.45126 0 0 6.45127 126.971 126.381)"
        >
          <Stop offset={0.82} stopColor="#698182" />
          <Stop offset={1} stopColor="#2E3536" />
        </RadialGradient>
        <RadialGradient
          id="paint38_radial_5325_12254"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(127.407 130.055) scale(8.33384)"
        >
          <Stop offset={0.81} stopColor="#2E3536" stopOpacity={0} />
          <Stop offset={1} stopColor="#2E3536" />
        </RadialGradient>
        <LinearGradient
          id="paint39_linear_5325_12254"
          x1={126.512}
          y1={118.735}
          x2={126.512}
          y2={128.791}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#A1A1AD" />
          <Stop offset={1} stopColor="#A1A1AD" stopOpacity={0} />
        </LinearGradient>
        <RadialGradient
          id="paint40_radial_5325_12254"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(9.98688 0 0 9.98684 158.932 120.356)"
        >
          <Stop stopColor="#C97551" />
          <Stop offset={1} stopColor="#C97551" stopOpacity={0} />
        </RadialGradient>
        <LinearGradient
          id="paint41_linear_5325_12254"
          x1={154.179}
          y1={147.608}
          x2={136.869}
          y2={125.499}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C97551" />
          <Stop offset={1} stopColor="#C97551" stopOpacity={0} />
        </LinearGradient>
        <RadialGradient
          id="paint42_radial_5325_12254"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(13.0402 0 0 13.0403 136.088 120.288)"
        >
          <Stop stopColor="#C97551" />
          <Stop offset={1} stopColor="#C97551" stopOpacity={0} />
        </RadialGradient>
        <LinearGradient
          id="paint43_linear_5325_12254"
          x1={167.791}
          y1={83.5595}
          x2={190.014}
          y2={83.5595}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#A1A1AD" />
          <Stop offset={1} stopColor="#A1A1AD" stopOpacity={0} />
        </LinearGradient>
        <ClipPath id="clip0_5325_12254">
          <Path
            fill="#fff"
            transform="translate(134.699 105.424)"
            d="M0 0H23V19H0z"
          />
        </ClipPath>
        <ClipPath id="clip1_5325_12254">
          <Path
            fill="#fff"
            transform="translate(133.686 124.429)"
            d="M0 0H26V23H0z"
          />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export { BodyTypesSVG_face };
