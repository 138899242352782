import * as React from 'react';

interface GridScrollContainerProps extends React.HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode;
}

const GridScrollContainer = ({ children, style }: GridScrollContainerProps) => {

    return (
        <div data-scroll style={style}>
            {children}
        </div>
    )
}

export { GridScrollContainer }