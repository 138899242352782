import React, { useCallback } from 'react';
import { NativeStackNavigationProp, createNativeStackNavigator } from '@react-navigation/native-stack';
import { SCORE_TYPE } from '../../typesAndInterfaces/typesAndInterfaces';
import { GameScreen } from '../../components/screens/Game/GameScreen';
import { RoomLobby } from '../../components/screens/Rooms/RoomLobby';
import { RoomEndGame } from '../../components/screens/Rooms/RoomEndGame/RoomEndGame';
import { RoomCreateOrJoin } from '../../components/screens/Rooms/RoomCreateOrJoin';
import { HomeNavigatorOptions } from '../HomeNavigator/homeNavigatorOptions';
import { RTCommContextProvider } from '../../context/RTCommContextProvider';
import { HomeNavigatorParamList } from '../HomeNavigator/HomeNavigator';

export type RoomParams = {
    roomId: string,
    channelName: string,
    score: number,
    scoringType: SCORE_TYPE,
}

export type RoomsNavigatorParamList = {
    RoomCreateOrJoin: undefined,
    RoomLobby: { roomId: string },
    GameScreen: { categoryIds: string[], index: number, roomParams?: RoomParams };
    RoomEndGame: { roomId: string },
}

const Stack = createNativeStackNavigator<RoomsNavigatorParamList>();

const RoomsNavigator = ({ navigation }: { navigation: NativeStackNavigationProp<HomeNavigatorParamList> }) => {

    const onFocus = useCallback(() => {
        const roomNavigation = navigation.getState().routes.find(({ name }) => name === 'RoomsNavigator');
        if (roomNavigation && roomNavigation.state) {
            const routes = roomNavigation.state.routes;
            const screenName = routes[routes.length - 1].name;
            if (screenName === 'RoomCreateOrJoin') {
                HomeNavigatorOptions.showBottomTab(navigation);
            } else {
                HomeNavigatorOptions.hidBottomTab(navigation);
            }
        }
    }, [navigation]);

    return (
        <RTCommContextProvider>
            <Stack.Navigator
                screenOptions={{
                    headerShown: false,
                    gestureEnabled: false,
                }}
                screenListeners={{ focus: onFocus }}
            >
                <Stack.Screen name="RoomCreateOrJoin" component={RoomCreateOrJoin} />
                <Stack.Screen name="RoomLobby" component={RoomLobby} />
                <Stack.Screen name="GameScreen" component={GameScreen} />
                <Stack.Screen name="RoomEndGame" component={RoomEndGame} />
            </Stack.Navigator>
        </RTCommContextProvider>
    );
};

export { RoomsNavigator };
