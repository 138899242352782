import * as React from 'react';
import Svg, {
    G,
    Path,
    Defs,
    LinearGradient,
    Stop,
    Circle,
} from 'react-native-svg';
import { BODY_PARTS, BodyPartsSvgProps } from '../../../components/screens/Game/bodyPartsControls/bodyPartsConstants';
import { BODY_PARTS_SVG_BUTTON_TYPES, BodyPartSvgButton } from '../../../components/helperComponents/BodyPartSvgButton';

const BodyTypesSVG_ear = ({ onPress, highlightedParts }: BodyPartsSvgProps) => {
    const [_active, setActive] = React.useState<BODY_PARTS | undefined>();

    return (
        <Svg
            style={{ width: '100%', height: '100%' }}
            viewBox="0 0 220 220"
            fill="none"
        >
            <G id="Ear">
                <G id="Ear_2">
                    <G id="Group 27717">
                        <G id="pressable_leftEar">
                            <G id="Left Ear">
                                <Path
                                    id="Vector"
                                    d="M78.013 65.594c-1.837-3.788-4.798-6.497-9.183-5.257-5.35 1.653-4.041 8.518-3.972 12.788A18.367 18.367 0 0068.76 84.42a10.423 10.423 0 0011.8 4.89c-.597-7.99-1.17-15.795-2.548-23.716z"
                                    fill="#915548"
                                />
                                <Path
                                    id="Vector_2"
                                    d="M79.39 88.392c-5.9.987-8.495-3.076-10.767-6.405a20.249 20.249 0 01-2.595-10.86 20.205 20.205 0 01.413-6.887 3.788 3.788 0 012.985-2.778 5.533 5.533 0 014.592.803c.155.102.3.218.436.345a13.155 13.155 0 012.64 3.237c.207 1.263.436 2.824.643 4.592v.55c.505 3.903.987 8.656 1.354 13.248.115 1.469.299 2.846.299 4.155z"
                                    fill="url(#paint0_linear_5387_9204)"
                                />
                                <Path
                                    id="Vector_3"
                                    style={{
                                        mixBlendMode: 'darken',
                                    }}
                                    d="M79.39 88.392c-5.9.987-8.495-3.076-10.767-6.405a20.249 20.249 0 01-2.595-10.86 20.205 20.205 0 01.413-6.887 20.87 20.87 0 00.643 6.658c.436 1.745 1.125-.826 1.4-2.709.276-1.883.69-3.214 2.159-3.627a2.296 2.296 0 012.916 1.056c.78.964.895-1.539.505-3.352.155.102.3.217.436.344a65.058 65.058 0 013.168 7.829c.01.068.01.138 0 .207.012.114.012.23 0 .344.138 1.768 0 5.074 0 5.395 0 .322-1.033-1.102-1.47-1.79-.435-.69-1.652 0-2.295.55a2.939 2.939 0 00-.344 3.33c.711 1.33 1.262 2.433 2.433 2.846 1.171.413 2.571.987 1.814 1.607-.758.62-1.676 1.607-2.296.344-.62-1.262-1.332-1.79-2.02-1.95a5.488 5.488 0 01-2.434-3.743 9.458 9.458 0 012.296-5.625c1.056-.918-.78-.918-1.952 0-1.17.919-1.675-.298-1.607-1.331.07-1.034-.964 1.24-1.102 3.053a12.03 12.03 0 004.73 9.596c-1.607 0-4.316-2.64-5.212-5.073-.895-2.434-.436.85.276 2.296.711 1.446 3.329 5.647 5.418 6.543a3.351 3.351 0 004.798-2.296c0-.345.23-.46.482-.368.023 1.332.207 2.71.207 4.018z"
                                    fill="url(#paint1_linear_5387_9204)"
                                />
                                <Path
                                    id="Vector_4"
                                    d="M76.888 72.734l-.23.16a32.17 32.17 0 00-.85-1.813 29.49 29.49 0 00-2.043-3.49 16.966 16.966 0 00-1.17-1.607 2.294 2.294 0 00-1.608-.826 1.562 1.562 0 00-1.377 1.056 6.177 6.177 0 00-.551 1.951 6.244 6.244 0 01.321-2.043c.13-.335.317-.646.551-.918.282-.278.661-.434 1.056-.436a2.502 2.502 0 011.952.872c.48.509.91 1.062 1.286 1.653a21.099 21.099 0 011.974 3.604c.267.606.497 1.227.689 1.86v-.023z"
                                    fill="#915548"
                                />
                                <Path
                                    id="Vector_5"
                                    d="M77.715 83.434a1.262 1.262 0 01-1.17.55 2.112 2.112 0 01-1.608-1.469.942.942 0 00-.528-.505c-.252 0-.574-.16-.918-.298a4.248 4.248 0 01-2.434-3.169 6.45 6.45 0 01.666-3.88 14.142 14.142 0 012.296-3.122 13.41 13.41 0 00-2.043 3.26 6.084 6.084 0 00-.436 3.65 3.834 3.834 0 002.158 2.687c.275.115.597.16.94.298a1.7 1.7 0 01.873.78c.115.268.27.515.46.736.15.184.362.306.596.344.506 0 .735-.345.505-.804-.23-.459-.826-.62-1.423-.94a5.487 5.487 0 01-2.525-3.123 4.59 4.59 0 01-.207-1.998 2.64 2.64 0 011.01-1.745c.873-.642 1.79-1.17 2.686-1.744l.23-.138-.23.16-1.01.735c-.505.39-1.056.758-1.515 1.171a2.915 2.915 0 00-.528 3.375 5.004 5.004 0 002.296 2.71c.689.198 1.293.618 1.722 1.193a1.286 1.286 0 01.137 1.286z"
                                    fill="#915548"
                                />
                                <Path
                                    id="Vector_6"
                                    d="M71.56 68.006a10.147 10.147 0 00-.481 1.561l-.207.758c-.007.114-.007.23 0 .344.115 0 .298 0 .528-.23l.666-.482c.436-.321.895-.62 1.354-.895-.367.413-.757.78-1.148 1.148l-.597.55c-.108.11-.231.203-.367.276a.665.665 0 01-.55 0 .528.528 0 01-.414-.505.94.94 0 010-.482c.071-.267.163-.528.275-.78.276-.449.591-.871.942-1.263z"
                                    fill="#915548"
                                />
                                <Path
                                    id="Vector_7"
                                    d="M70.459 67.569a38.667 38.667 0 00-1.4 4.063 10.17 10.17 0 00-.345 4.156 10.723 10.723 0 001.263 3.972 11.09 11.09 0 002.686 3.26 9.689 9.689 0 01-3.054-3.054 10.492 10.492 0 01-1.446-4.11 10.606 10.606 0 01.482-4.338c.482-1.37 1.09-2.691 1.814-3.95z"
                                    fill="#915548"
                                />
                            </G>
                            <Path
                                id="highlight_leftEar"
                                d="M78.444 66.163l2.552 23.371c-4.926.162-9.02.616-12.269-4.37-2.774-4.213-4.372-6.727-4.372-14 0-3.5-.229-4 .486-6.5 2.429-8.5 12.145-5 13.603 1.5z"
                                fill="#A430FF"
                                fillOpacity={0.25}
                                stroke="#A430FF"
                                opacity={0}
                            />
                        </G>
                        <G id="pressable_earlobe">
                            <Path
                                id="highlight_leftEarlobe"
                                d="M64.454 64.486c2.43-8.5 12.145-5 13.603 1.5 0 0-5.002-4.31-8.026-2.63-3.8 2.112-2.662 10.638-.563 15.907 2.306 5.79 11.141 10.093 11.141 10.093-4.926.162-9.02.616-12.268-4.37-2.775-4.213-4.372-6.727-4.373-14 0-3.5-.228-4 .486-6.5z"
                                fill="#A430FF"
                                fillOpacity={0.25}
                                stroke="#A430FF"
                                opacity={highlightedParts.includes(BODY_PARTS.earlobe) ? 1 : 0}
                            />
                        </G>
                        <G id="pressable_leftEardrum">
                            <Circle
                                id="highlight_leftEardrum"
                                cx={76.3857}
                                cy={75.9113}
                                r={6.95215}
                                fill="#A430FF"
                                fillOpacity={0.25}
                                stroke="#A430FF"
                                opacity={highlightedParts.includes(BODY_PARTS.eardrum) ? 1 : 0}
                            />
                        </G>
                    </G>
                    <G id="Group 27727">
                        <G id="Group">
                            <G
                                id="Ellipse 83"
                                transform="rotate(-172.267 76.688 76.277)"
                                fill="#fff"
                            >
                                <Circle cx={76.6877} cy={76.277} r={2} />
                                <Circle cx={76.6877} cy={76.277} r={2} />
                            </G>
                            <Path
                                id="Line 78"
                                stroke="#fff"
                                d="M137.715 76.1261L76.3581 76.1261"
                            />
                        </G>
                        <G id="pressableContainerSelected_eardrum">
                            <G id="pressableButton_eardrum">
                                <BodyPartSvgButton
                                    type={BODY_PARTS_SVG_BUTTON_TYPES.activate}
                                    bodyPart={BODY_PARTS.eardrum}
                                    activateAsSelect
                                    onPress={onPress}
                                    onActivate={setActive}
                                    width={86}
                                    height={32}
                                    x={111.5}
                                    y={62.5}
                                />
                            </G>
                        </G>
                    </G>
                    <G id="Group 27728">
                        <G id="Group_2">
                            <G
                                id="Ellipse 83_2"
                                transform="rotate(-90 65.54 71.814)"
                                fill="#fff"
                            >
                                <Circle cx={65.5391} cy={71.8136} r={2} />
                                <Circle cx={65.5391} cy={71.8136} r={2} />
                            </G>
                            <Path
                                id="Line 78_2"
                                stroke="#fff"
                                d="M65.6387 134.6L65.6387 71.3985"
                            />
                        </G>
                        <G id="pressableContainerSelected_earlobe">
                            <G id="pressableButton_earlobe">
                                <BodyPartSvgButton
                                    type={BODY_PARTS_SVG_BUTTON_TYPES.activate}
                                    bodyPart={BODY_PARTS.earlobe}
                                    activateAsSelect
                                    onPress={onPress}
                                    onActivate={setActive}
                                    width={86}
                                    height={32}
                                    x={22.5}
                                    y={128.5}
                                />
                            </G>
                        </G>
                    </G>
                </G>
            </G>
            <Defs>
                <LinearGradient
                    id="paint0_linear_5387_9204"
                    x1={65.4542}
                    y1={87.9787}
                    x2={80.423}
                    y2={64.1939}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#DA8D66" />
                    <Stop offset={1} stopColor="#E2A174" />
                </LinearGradient>
                <LinearGradient
                    id="paint1_linear_5387_9204"
                    x1={80.5608}
                    y1={72.3903}
                    x2={57.3729}
                    y2={84.2139}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#C97551" />
                    <Stop offset={1} stopColor="#C97551" stopOpacity={0} />
                </LinearGradient>
            </Defs>
        </Svg>
    );
};

export { BodyTypesSVG_ear };
