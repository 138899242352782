import * as Font from 'expo-font';

import {
  Poppins_100Thin,
  Poppins_100Thin_Italic,
  Poppins_200ExtraLight,
  Poppins_200ExtraLight_Italic,
  Poppins_300Light,
  Poppins_300Light_Italic,
  Poppins_400Regular,
  Poppins_400Regular_Italic,
  Poppins_500Medium,
  Poppins_500Medium_Italic,
  Poppins_600SemiBold,
  Poppins_600SemiBold_Italic,
  Poppins_700Bold,
  Poppins_700Bold_Italic,
  Poppins_800ExtraBold,
  Poppins_800ExtraBold_Italic,
  Poppins_900Black,
  Poppins_900Black_Italic,
} from '@expo-google-fonts/poppins';

export const fontLoader = async (): Promise<any> => {
  const expoFontPromise = Font.loadAsync({
    AntDesign: require('../../node_modules/react-native-vector-icons/Fonts/AntDesign.ttf'),
    Feather: require('../../node_modules/react-native-vector-icons/Fonts/Feather.ttf'),
    FontAwesome: require('../../node_modules/react-native-vector-icons/Fonts/FontAwesome.ttf'),
    MaterialCommunityIcons: require('../../node_modules/react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf'),
    Entypo: require('../../node_modules/react-native-vector-icons/Fonts/Entypo.ttf'),

    Poppins_100: Poppins_100Thin,
    Poppins_100_italic: Poppins_100Thin_Italic,
    Poppins_200: Poppins_200ExtraLight,
    Poppins_200_italic: Poppins_200ExtraLight_Italic,
    Poppins_300: Poppins_300Light,
    Poppins_300_italic: Poppins_300Light_Italic,
    Poppins_400: Poppins_400Regular,
    Poppins_400_italic: Poppins_400Regular_Italic,
    Poppins_500: Poppins_500Medium,
    Poppins_500_italic: Poppins_500Medium_Italic,
    Poppins_600: Poppins_600SemiBold,
    Poppins_600_italic: Poppins_600SemiBold_Italic,
    Poppins_700: Poppins_700Bold,
    Poppins_700_italic: Poppins_700Bold_Italic,
    Poppins_800: Poppins_800ExtraBold,
    Poppins_800_italic: Poppins_800ExtraBold_Italic,
    Poppins_900: Poppins_900Black,
    Poppins_900_italic: Poppins_900Black_Italic,
  });

  return Promise.all([expoFontPromise]);
};
