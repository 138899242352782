import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path, G, Defs, ClipPath } from 'react-native-svg';

const UsersSVG = React.memo((props: { size?: number, color?: string, containerStyle?: StyleProp<ViewStyle> }) => {
    return (
        <View style={props.containerStyle}>
            <Svg
                width={props.size ?? 25}
                height={props.size ? (props.size * (14 / 25)) : 14}
                viewBox="0 0 25 14"
            >
                <G fill={props.color ?? 'white'}>
                    <Path d="M4.897 9.145A3.11 3.11 0 008.004 6.04a3.11 3.11 0 00-3.107-3.106A3.11 3.11 0 001.79 6.04a3.11 3.11 0 003.107 3.106zm0-4.905c.993 0 1.8.807 1.8 1.8 0 .991-.807 1.798-1.8 1.798-.992 0-1.8-.807-1.8-1.799 0-.992.808-1.799 1.8-1.799zM1.332 13.716a2.423 2.423 0 012.421-2.42h2.289c.068 0 .136.002.203.008a.677.677 0 00.515-.173.639.639 0 00.208-.475.654.654 0 00-.581-.65 3.659 3.659 0 00-.346-.017H3.753a3.731 3.731 0 00-3.728 3.727v.268h1.307v-.268zM7.2 13.984h1.308v-1.609a2.74 2.74 0 012.736-2.736h2.524a2.74 2.74 0 012.737 2.736v1.61h1.307v-1.61a4.034 4.034 0 00-1.833-3.381 4.022 4.022 0 00-2.21-.662h-2.525c-1.249 0-2.41.569-3.184 1.56a3.996 3.996 0 00-.86 2.483v1.61zM21.26 9.988h-2.29c-.063 0-.127.002-.19.006a.66.66 0 00-.629.655c0 .18.075.353.205.475a.643.643 0 00.482.175c.044-.002.088-.003.133-.003h2.288a2.423 2.423 0 012.421 2.42v.268h1.308v-.268a3.732 3.732 0 00-3.729-3.728zM20.115 9.145a3.11 3.11 0 003.107-3.106 3.11 3.11 0 00-3.107-3.106 3.11 3.11 0 00-3.107 3.106 3.11 3.11 0 003.107 3.106zm0-4.905c.992 0 1.8.807 1.8 1.8 0 .991-.808 1.798-1.8 1.798s-1.8-.807-1.8-1.799c0-.992.808-1.799 1.8-1.799zM12.506 7.818a3.912 3.912 0 003.909-3.907A3.912 3.912 0 0012.506.003a3.912 3.912 0 00-3.908 3.908 3.912 3.912 0 003.908 3.907zm0-6.508c1.434 0 2.601 1.167 2.601 2.6 0 1.434-1.167 2.6-2.6 2.6a2.604 2.604 0 01-2.602-2.6c0-1.433 1.167-2.6 2.601-2.6z" />
                </G>
                <Defs>
                    <ClipPath>
                        <Path fill={props.color ?? 'white'} d="M0 0H25V14H0z" />
                    </ClipPath>
                </Defs>
            </Svg>
        </View>
    );
});

export { UsersSVG };
