import { Player, ROOM_EVENT_NAME } from '../typesAndInterfaces/typesAndInterfaces';
import { LeaderboardObj, PusherPresenceObj, PusherUserInfoObj, GameStartedObj, RoomUpdatedObj, GameFinishedObj } from './zod/zodObjects';
import { EventParams } from '../context/RTCommContextProvider';

const parseJson = (data: string) => {
    try {
        return JSON.parse(data);
    } catch {
        throw new Error('INVALID_JSON_STRING');
    }
};

const validateEvent = ({ eventName, data }: { eventName: string, data: string }): EventParams<ROOM_EVENT_NAME> => {
    eventName = eventName.replace(/^client-/g, '');
    const dataObj = typeof data === 'object' ? data : parseJson(data);
    if (eventName === ROOM_EVENT_NAME.GAME_STARTED) {
        const validateGameStarted = GameStartedObj.safeParse(dataObj);
        if (validateGameStarted.success) {
            const gameStarted = validateGameStarted.data;
            return { eventName, data: gameStarted };
        }
    } else if (eventName === ROOM_EVENT_NAME.GAME_FINISHED || eventName === ROOM_EVENT_NAME.ROOM_DELETED) {
        const validateGameFinished = GameFinishedObj.safeParse(dataObj);
        if (validateGameFinished.success) {
            const gameFinished = validateGameFinished.data;
            return { eventName, data: gameFinished };
        }
    } else if (eventName === ROOM_EVENT_NAME.ROOM_UPDATED) {
        const validateRoomUpdated = RoomUpdatedObj.safeParse(dataObj);
        if (validateRoomUpdated.success) {
            const roomGame = validateRoomUpdated.data;
            return { eventName, data: roomGame };
        }
    } else if (eventName === ROOM_EVENT_NAME.LEADERBOARD_UPDATED) {
        const validateLeaderboard = LeaderboardObj.safeParse(dataObj);
        if (validateLeaderboard.success) {
            const leaderboard = validateLeaderboard.data;
            return { eventName, data: leaderboard };
        }
    } else if (!eventName.includes('pusher_internal')) {
        throw new Error('INVALID_EVENT_NAME');
    }
    return { eventName } as EventParams<ROOM_EVENT_NAME>;
};

const validatePlayer = (userId: string, userInfo: any): Player | undefined => {
    const validateUserInfo = PusherUserInfoObj.safeParse(userInfo);
    if (validateUserInfo.success) {
        const { name } = validateUserInfo.data;
        return ({ userId, name });
    }
};

const validatePresence = (presenceObj: any): Player[] | undefined => {
    const validatePresenceData = PusherPresenceObj.safeParse(presenceObj);
    if (validatePresenceData.success) {
        const presence = validatePresenceData.data;
        const players = Object.keys(presence.hash).map(userId => ({ userId, name: presence.hash[userId].name }));
        return players;
    }
};

const RTCommValidators = {
    validateEvent,
    validatePlayer,
    validatePresence,
};

export { RTCommValidators };
