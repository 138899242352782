import { z } from 'zod';

const zodPagination = z.object({
    page: z.number().gt(0),
    totalItems: z.number().gte(0),
});

const zodInfinitePagination = z.object({
    nextOffset: z.string().nullable(),
});

const zodUtils = {
    getPagination: (anyData: z.AnyZodObject) => {
        return zodPagination.extend({
            items: anyData.array(),
        });
    },
    getInfinitePagination: (anyData: z.AnyZodObject) => {
        return zodInfinitePagination.extend({
            items: anyData.array(),
        });
    },
    getArray: (anyData: z.ZodTypeAny) => {
        return z.array(anyData);
    },
};

export { zodUtils };
