import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Circle, G, Path, Rect } from 'react-native-svg';

const EmptyTableIcon = React.memo((props: { size?: number, containerStyle?: StyleProp<ViewStyle> }) => {
    return (
        <View style={props.containerStyle}>
            <Svg
                width={props.size ?? 129}
                height={props.size ? (props.size * (147 / 129)) : 147}
                viewBox="0 0 129 147"
                fill="none"
            >
                <G opacity={0.2}>
                    <Circle opacity={0.41} cx={64.5} cy={65.3918} r={64.5} fill="#F1F1F1" />
                    <Rect
                        x={46.5072}
                        y={43.5529}
                        width={62.951}
                        height={88.2098}
                        rx={7}
                        transform="rotate(2.5 46.507 43.553)"
                        fill="#EEE"
                        stroke="#796080"
                        strokeWidth={2}
                    />
                    <Rect
                        x={8.76377}
                        y={38.0783}
                        width={66.951}
                        height={92.2098}
                        rx={9}
                        transform="rotate(-7.5 8.764 38.078)"
                        fill="#FAFAFA"
                        stroke="#796080"
                        strokeWidth={2}
                    />
                    <Path
                        d="M10.93 46.87a8 8 0 016.887-8.975l48.533-6.39a8 8 0 018.975 6.888l1.664 12.64-64.395 8.477-1.664-12.64z"
                        fill="#EEE"
                    />
                    <Rect
                        x={88.2852}
                        y={33.1672}
                        width={2.70629}
                        height={9.02098}
                        rx={1.35315}
                        transform="rotate(82.5 88.285 33.167)"
                        fill="#999"
                    />
                    <Rect
                        x={63.5703}
                        y={18.2234}
                        width={2.70629}
                        height={9.02098}
                        rx={1.35315}
                        transform="rotate(-7.5 63.57 18.223)"
                        fill="#999"
                    />
                    <Rect
                        x={79.4268}
                        y={20.6851}
                        width={2.70629}
                        height={9.02098}
                        rx={1.35315}
                        transform="rotate(37.5 79.427 20.685)"
                        fill="#796080"
                    />
                    <Rect
                        x={19.3252}
                        y={62.2634}
                        width={52.3217}
                        height={3.60839}
                        rx={1.8042}
                        transform="rotate(-7.5 19.325 62.263)"
                        fill="#DCDCDC"
                    />
                    <Rect
                        x={21.6807}
                        y={80.1509}
                        width={52.3217}
                        height={3.60839}
                        rx={1.8042}
                        transform="rotate(-7.5 21.68 80.15)"
                        fill="#DCDCDC"
                    />
                    <Rect
                        x={24.0352}
                        y={98.0386}
                        width={52.3217}
                        height={3.60839}
                        rx={1.8042}
                        transform="rotate(-7.5 24.035 98.039)"
                        fill="#DCDCDC"
                    />
                    <Rect
                        x={20.2676}
                        y={69.4182}
                        width={18.9441}
                        height={3.60839}
                        rx={1.8042}
                        transform="rotate(-7.5 20.268 69.418)"
                        fill="#EEE"
                    />
                    <Rect
                        x={22.6221}
                        y={87.3062}
                        width={18.9441}
                        height={3.60839}
                        rx={1.8042}
                        transform="rotate(-7.5 22.622 87.306)"
                        fill="#EEE"
                    />
                    <Rect
                        x={24.9766}
                        y={105.194}
                        width={18.9441}
                        height={3.60839}
                        rx={1.8042}
                        transform="rotate(-7.5 24.977 105.194)"
                        fill="#EEE"
                    />
                </G>
            </Svg>
        </View>
    );
});

export { EmptyTableIcon };
