import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import React from 'react';

import { StyleSheet, Text, View } from 'react-native';
import { COLORS } from '../../constants/colors';
import { textStyles } from '../../constants/textStyles';
import { UpdateAppButton } from './UpdateAppButton';

const UpdateApp = ({ version }: { version: string }) => {

    return (
        <View style={styles.container}>
            <Text style={mbTextStyles([textStyles.largeText, { color: COLORS.textLightPurple }])}>New Version</Text>
            <Text style={mbTextStyles([textStyles.smallText, { color: COLORS.textLightPurple }])}>V{version}</Text>
            <Text style={mbTextStyles([textStyles.smallText, { marginTop: 30, color: '#B8ABBA' }])}>The current version of this application is no longer supported.</Text>
            <UpdateAppButton title="Update Now" />
        </View>
    );
};

export { UpdateApp };

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: COLORS.backgroundPurple,
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 30,
    },
});
