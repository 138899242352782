import React from 'react';
import { StyleSheet, TextInput, View } from 'react-native';
import { COLORS } from '../../../constants/colors';
import { mbApplyTransparency, mbPlatformStyle, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../constants/textStyles';
import DropdownPicker from '../../helperComponents/DropdownPicker';
import { emitFocus, onChange, onlyAndroid } from './utils';

const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
];

interface DateInputProps {
    showYearOnly?: boolean;
    month?: number,
    day?: string,
    year?: string
    onSelectedMonth?: (value: number) => void;
    onSelectedDay?: (value: string) => void;
    onSelectedYear: (value: string) => void;
}

export default function DateInput({ showYearOnly = false, day, month, year, onSelectedMonth, onSelectedDay, onSelectedYear }: DateInputProps) {

    return (
        <View style={styles.container}>
            {showYearOnly === false &&
                <>
                    <View style={styles.wheelContainer}>
                        <DropdownPicker
                            items={months}
                            style={[styles.input, styles.month]}
                            placeholder="month"
                            value={month !== undefined ? months[month] : undefined}
                            onSelectItem={value => onSelectedMonth?.(months.indexOf(value))}
                        />
                    </View>
                    <View style={styles.wheelContainer}>
                        <TextInput
                            value={day}
                            placeholder="Day"
                            placeholderTextColor={mbApplyTransparency('#FFFFFF', 0.45)}
                            style={[styles.input, styles.day]}
                            returnKeyType="done"
                            keyboardType="number-pad"
                            maxLength={2}
                            onChangeText={onChange(onSelectedDay)}
                            onPressIn={onlyAndroid(emitFocus)}
                        />
                    </View>
                </>
            }
            <View style={styles.wheelContainer}>
                <TextInput
                    value={year}
                    placeholder="Year"
                    placeholderTextColor={mbApplyTransparency('#FFFFFF', 0.45)}
                    style={[styles.input, styles.year]}
                    returnKeyType="done"
                    keyboardType="number-pad"
                    maxLength={4}
                    onChangeText={onChange(onSelectedYear)}
                    onPressIn={onlyAndroid(emitFocus)}
                />
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    text: {
        fontSize: 20,
        textAlign: 'center',
        fontWeight: 'bold',
        color: COLORS.white,
    },
    border: {
        borderWidth: 2,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderRadius: 0,
        borderColor: COLORS.cardPurple,
        backgroundColor: 'transparent',
    },
    dateWheelContainer: {
        marginHorizontal: 8,
    },
    container: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
    },
    wheelContainer: {
        ...mbPlatformStyle({
            web: {
                alignItems: 'center',
            },
            mobile: {
                flexDirection: 'row',
                justifyContent: 'center',
                //flex: 1,
            },
        }),
    },
    input: mbTextStyles([textStyles.smallText, {
        backgroundColor: '#412366',
        padding: 8,
        borderRadius: 10,
        color: '#FFFFFF',
        marginHorizontal: 10,
    }]),
    day: {
        width: 50,
    },
    month: {
        width: 95,
        height: 37,
        ...mbPlatformStyle({
            mobile: {
                paddingLeft: 10,
                paddingRight: 28,
            },
        }),
    },
    year: {
        width: 55,
    },
});
