import { MB_Button } from '@mightybyte/rnw.components.button';
import { MB_ResourceUploader, MB_RESOURCE_PICKER_TYPE } from '@mightybyte/rnw.components.resource-uploader';
import { MB_TextInput } from '@mightybyte/rnw.components.text-input';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { MB_utilHooks } from '@mightybyte/rnw.utils.util-hooks';
import React, { useCallback, useMemo, useState } from 'react';
import { UseMutateFunction } from '@tanstack/react-query';
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import { COLORS } from '../../constants/colors';
import { SERVER_ERROR_CODES } from '../../constants/constants';
import { textStyles } from '../../constants/textStyles';
import { useUploadImage } from '../../hooks/imagePoolHooks';
import { MB_Tags, MB_Tag } from '@mightybyte/rnw.components.tags';
import { useUploadVideo } from '../../hooks/videoPoolHooks';
import { PoolImage, RESOURCE_POOL_TYPE, PoolVideo, ServerError } from '../../typesAndInterfaces/typesAndInterfaces';

const MAX_VIDEO_DURATION = 5 * 60;

type UploadHandler = UseMutateFunction<PoolImage | PoolVideo, ServerError, { formData: FormData; uploadProgress?: ((percentCompleted: number) => void) | undefined; }, unknown>

const UploadImageVideoButton = ({ buttonStyle, resourcePoolType, folderId }: { buttonStyle?: StyleProp<ViewStyle>, resourcePoolType: RESOURCE_POOL_TYPE, folderId?: string }) => {
    const [isResourcePickerVisible, showResourcePicker, hideResourcePicker] = MB_utilHooks.useBool();
    const [imageVideoName, setImageVideoName] = useState('');
    const [tags, setTags] = useState<string[]>([]);
    const [showImageNameError, setShowImageNameError] = useState(false);
    const [showTagsError, setShowTagsError] = useState(false);
    const { mutate: uploadImage } = useUploadImage();
    const { mutate: uploadVideo } = useUploadVideo();

    const uploadHandler: UploadHandler = resourcePoolType === RESOURCE_POOL_TYPE.Image ? uploadImage : uploadVideo;

    const imageVideoUploader = (uploadProgressListener: (percentCompleted: number) => void) => async ({ formData }: { formData: FormData }): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (!imageVideoName) {
                setShowImageNameError(true);
                reject(new Error(`${resourcePoolType === RESOURCE_POOL_TYPE.Image ? 'Image' : 'Video'} name is required`));
                return;
            }
            if (tags.length <= 0) {
                setShowTagsError(true);
                reject(new Error('Tags are required'));
                return;
            }

            setShowImageNameError(false);
            setShowTagsError(false);

            formData.append('name', imageVideoName);
            if (folderId) {
                formData.append('folderId', folderId);
            }
            for (const tag of tags) {
                formData.append('tags[]', tag);
            }
            uploadHandler({ formData, uploadProgress: uploadProgressListener }, {
                onSuccess: () => resolve(),
                onError: (error) => {
                    if (error?.errorCode === SERVER_ERROR_CODES.LIMIT_FILE_SIZE || error?.errorCode === SERVER_ERROR_CODES.IMAGE_WITH_GIVEN_NAME_ALREADY_EXISTS) {
                        reject(new Error(error.message));
                        return;
                    } else {
                        reject({});
                    }
                },

            });
        });
    };

    const onTagsChange = useCallback((newTags: MB_Tag[]) => {
        setTags(newTags.map(tag => tag.title));
    }, []);

    const ImageVideoNameEntry = useMemo(() => {
        return (
            <View style={styles.resourceContent}>
                <Text style={styles.imageNameTitle}>{resourcePoolType === RESOURCE_POOL_TYPE.Image ? 'Image' : 'Video'} name*</Text>
                <MB_TextInput
                    value={imageVideoName}
                    onChangeText={(newText) => {
                        setImageVideoName(newText);
                        setShowImageNameError(false);
                    }}
                    isError={showImageNameError}
                />
                <Text style={[styles.imageNameTitle, { marginTop: 17 }]}>Tags* (minimum 1)</Text>
                <MB_Tags
                    onTagsChange={onTagsChange}
                    containerStyle={{ borderColor: showTagsError ? '#e64545' : undefined, backgroundColor: 'transparent', width: 386 }}
                    title=""
                    maxTagsLength={5}
                    maxTagLength={20}
                />
            </View>
        );
    }, [imageVideoName, onTagsChange, resourcePoolType, showImageNameError, showTagsError]);

    return (
        <>
            <MB_ResourceUploader
                isVisible={isResourcePickerVisible}
                onDismiss={() => {
                    hideResourcePicker();
                    setImageVideoName('');
                }}
                uploaderFunction={imageVideoUploader}
                pageTitle={resourcePoolType === RESOURCE_POOL_TYPE.Image ? 'Upload Image Resource' : 'Upload Video Resource'}
                uploadButtonTitle="Upload"
                resourcePickerType={resourcePoolType === RESOURCE_POOL_TYPE.Image ? MB_RESOURCE_PICKER_TYPE.image : MB_RESOURCE_PICKER_TYPE.video}
                children={ImageVideoNameEntry}
                maxDuration={MAX_VIDEO_DURATION}
            />

            <MB_Button
                title={resourcePoolType === RESOURCE_POOL_TYPE.Image ? 'Add New Image' : 'Add New Video'}
                onPress={showResourcePicker}
                leftElement={<Feather name="plus" color={COLORS.white} size={14} />}
                textStyle={textStyles.smallText}
                style={[styles.button, buttonStyle]}
            />
        </>
    );
};

export { UploadImageVideoButton };

const styles = StyleSheet.create({
    imageNameTitle: mbTextStyles([
        textStyles.normalText, {
            textAlign: 'left',
        },
    ]),
    button: {
        backgroundColor: COLORS.buttonPurple,
        width: 205,
        height: 38,
        borderRadius: 10,
    },
    resourceContent: {
        zIndex: 1,
    },
});
