import React, { useCallback, useMemo, useState } from 'react';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { useIsFocused } from '@react-navigation/core';
import { format as formatDate, parseISO } from 'date-fns';
import { StyleSheet, Text, View } from 'react-native';
import { COLORS } from '../../../constants/colors';
import { DEFAULT_TABLE_MAX_ROWS } from '../../../constants/constants';
import { textStyles } from '../../../constants/textStyles';
import { useDeleteEducatorCode, useGetEducatorCodes } from '../../../hooks/adminHooks';
import { MB_PageIndicator } from '@mightybyte/rnw.components.page-indicator';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import { MB_Table } from '@mightybyte/rnw.components.table';
import { EducatorCode } from '../../../typesAndInterfaces/typesAndInterfaces';
import { EducatorCodeEditor } from '../../helperComponents/Modals/EducatorCodeEditor';
import Feather from 'react-native-vector-icons/Feather';
import { mbShowToast } from '@mightybyte/rnw.components.toast';
import Clipboard from '@react-native-clipboard/clipboard';
import { mbShowPopUp } from '@mightybyte/rnw.components.pop-up';
import { utils } from '../../../utils/utils';

const header = [
    { content: 'No.', conatinerStyle: { height: 85, minWidth: 80, maxWidth: 80, paddingLeft: 22 } },
    { content: 'Created', conatinerStyle: { height: 85, minWidth: 100, maxWidth: 100 } },
    { content: 'Code', conatinerStyle: { height: 85, minWidth: 180 } },
    { content: 'No. of licenses', conatinerStyle: { height: 85, minWidth: 200, maxWidth: 200 } },
    { content: 'No. of licenses available', conatinerStyle: { height: 85, minWidth: 200, maxWidth: 200 } },
    { content: 'No. of licenses used', conatinerStyle: { height: 85, minWidth: 170, maxWidth: 170 } },
    { content: 'Expiration Date', conatinerStyle: { height: 85, minWidth: 130, maxWidth: 130 } },
    { content: '', conatinerStyle: { height: 85, minWidth: 160, maxWidth: 160 } },
];

const EducatorCodes = () => {
    const [page, setPage] = useState(1);
    const [educateCodeModal, changeEducateCodeModal] = useState<{ visible: boolean, educateCode?: EducatorCode }>({ visible: false });

    const isFocused = useIsFocused();

    const { data: educatorCodes, ...getUsersOptions } = useGetEducatorCodes({
        totalItemsPerPage: DEFAULT_TABLE_MAX_ROWS,
        pageNum: page,
        queryOptions: {
            enabled: page !== undefined && page > 0 && isFocused,
            refetchOnWindowFocus: false,
            keepPreviousData: true,
        },
    });

    const { mutate: deleteEducatorCode } = useDeleteEducatorCode();

    const onCopy = useCallback((code: string) => {
        Clipboard.setString(code);
        mbShowToast({
            text1: 'Copied!',
            text2: 'Code Copied successfully.',
            type: 'success',
        });
    }, []);

    const onDelete = useCallback((item: EducatorCode) => {
        mbShowPopUp({
            title: 'Delete Code',
            message: `Are you sure you want to delete "${item.code}"?`,
            buttonText: 'Delete Code',
            buttonAction: () => new Promise((resolve) => {
                deleteEducatorCode({ educatorCodeId: item._id }, utils.popUpCallback(resolve, 'Code deleted successfully'));
            }),
            buttonStyle: {
                container: { backgroundColor: COLORS.errorColor },
            },
            secondaryButtonText: 'Cancel',
            secondaryButtonStyle: {
                text: { color: COLORS.buttonPurple },
                container: { backgroundColor: COLORS.white },
            },
        });
    }, [deleteEducatorCode]);

    const data = useMemo(() => {
        return educatorCodes?.items?.map((item, index) => {
            return [
                (page - 1) * DEFAULT_TABLE_MAX_ROWS + index + 1,
                formatDate(parseISO(item.createdAt), 'MM/dd/yy'),
                item.code,
                item.numberOfLicenses,
                item.numberOfLicenses - item.numberOfLicensesUsed,
                item.numberOfLicensesUsed,
                formatDate(parseISO(item.expireDate), 'MM/dd/yyyy'),
                <View style={styles.actions}>
                    <View style={styles.copy}>
                        <Feather
                            name="copy"
                            color="#E2C8FF"
                            style={{ marginEnd: 10 }}
                            size={20}
                            onPress={() => onCopy(item.code)}
                        />
                        <Text style={styles.copyText} onPress={() => onCopy(item.code)}>Copy</Text>
                    </View>
                    <Feather
                        name="edit"
                        color={COLORS.buttonPurple}
                        style={{ marginHorizontal: 18 }}
                        size={20}
                        onPress={() => changeEducateCodeModal({ visible: true, educateCode: item })}
                    />
                    <Feather
                        name="trash"
                        color={COLORS.deleteColor}
                        size={20}
                        onPress={() => onDelete(item)}
                    />
                </View>,
            ];
        });
    }, [educatorCodes?.items, onCopy, onDelete, page]);

    const keyExtractor = useCallback((index) => educatorCodes?.items[index]?._id ?? index.toString(), [educatorCodes?.items]);

    return (
        <ComponentWrapper
            containerStyle={styles.container}
            disableLinearGradient
            wrapInScrollView
            hideWebHeader
        >
            <View style={styles.pageHeader}>
                <Text style={styles.pageTitle}>Educator Codes</Text>
                <MB_Button
                    title="+ Create code"
                    style={styles.exportButton}
                    onPress={() => changeEducateCodeModal({ visible: true })}
                />
            </View>

            <View>
                <MB_Table
                    header={header}
                    headerStyle={styles.tableHeader}
                    data={data}
                    style={styles.tableStyle}
                    rowStyle={styles.rowStyle}
                    keyExtractor={keyExtractor}
                    loading={educatorCodes ? (getUsersOptions.isFetching && getUsersOptions.isPreviousData) : getUsersOptions.isFetching}
                />

                <MB_PageIndicator
                    current={page}
                    pages={Math.ceil((educatorCodes?.totalItems ?? 0) / DEFAULT_TABLE_MAX_ROWS)}
                    style={styles.pageIndicatorStyle}
                    onChange={setPage}
                />
            </View>
            <EducatorCodeEditor
                isVisible={educateCodeModal.visible}
                educatorCode={educateCodeModal.educateCode}
                onDismiss={() => changeEducateCodeModal({ visible: false })}
            />
        </ComponentWrapper>
    );
};

export { EducatorCodes };

const styles = StyleSheet.create({
    container: {
        minWidth: 1000,
    },
    pageHeader: {
        alignSelf: 'stretch',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 40,
    },
    pageTitle: mbTextStyles([
        textStyles.largestText, {
            fontSize: 36,
            color: COLORS.textLightPurple,
        },
    ]),
    exportButton: {
        height: 41,
        backgroundColor: COLORS.buttonPurple,
    },
    tableStyle: {
        backgroundColor: COLORS.backgroundPurple,
        borderRadius: 8,
        borderWidth: 0,
        marginTop: 24,
    },
    tableHeader: {
        height: 66,
        backgroundColor: 'transparent',
        borderBottomWidth: 2,
        borderBottomColor: COLORS.backgroundDarkPurple,
    },
    rowStyle: {
        borderWidth: 0,
    },
    pageIndicatorStyle: {
        borderTopWidth: 2,
        borderTopColor: COLORS.backgroundDarkPurple,
        paddingVertical: 18,
    },
    actions: {
        flexDirection: 'row',
        // width: '100%',
    },
    copy: {
        flexDirection: 'row',
        alignItems: 'flex-end',
    },
    copyText: mbTextStyles([textStyles.smallText, {
        color: '#E2C8FF',
        textDecorationLine: 'underline',
    }]),
});
