import {
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import shuffle from 'lodash/shuffle';
import { questionApiCalls } from '../apiCalls/questionApiCalls';

import { QUESTION_QUERY_KEYS } from '../constants/constants';
import {
  IncompleteQuestionsData,
  Question,
  QuestionShort,
  ServerError,
} from '../typesAndInterfaces/typesAndInterfaces';

export const useGetQuestion = ({ questionId, queryOptions }: { questionId: string | undefined, queryOptions?: UseQueryOptions<Question, ServerError> }) => {
  return useQuery<Question, ServerError>([QUESTION_QUERY_KEYS.getQuestion, questionId],
    async () => questionApiCalls.getQuestion(questionId),
    { refetchOnWindowFocus: false, ...queryOptions });
};

export const useGetQuestions = ({ categoryId, queryOptions }: { categoryId: string | undefined, queryOptions?: UseQueryOptions<QuestionShort[], ServerError> }) => {
  return useQuery<QuestionShort[], ServerError>([QUESTION_QUERY_KEYS.getQuestions, categoryId],
    async () => questionApiCalls.getQuestions(categoryId),
    { refetchOnWindowFocus: false, ...queryOptions });
};

export const useGetIncompleteQuestions = ({ categoryId, queryOptions }: { categoryId: string, queryOptions?: UseQueryOptions<IncompleteQuestionsData, ServerError> }) => {
  return useQuery<IncompleteQuestionsData, ServerError>(
    [QUESTION_QUERY_KEYS.getIncompleteQuestions, categoryId], // TODO: We should either be very careful with this cache or alternatively set stale time to immediate.
    async () => {
      const data = await questionApiCalls.getIncompleteQuestions(categoryId);
      const returnData: IncompleteQuestionsData = {
        totalNumberOfQuestions: data.totalNumberOfQuestions,
        questionsIds: shuffle(data.questionsIds),
      };
      return returnData;
    },
    { refetchOnWindowFocus: false, ...queryOptions }
  );
};

export const useGetQuestionsIds = ({ categoryIds, queryOptions }: { categoryIds: string[], queryOptions?: UseQueryOptions<IncompleteQuestionsData, ServerError> }) => {
  return useQuery<IncompleteQuestionsData, ServerError>(
    [QUESTION_QUERY_KEYS.getIncompleteQuestions, categoryIds.sort().join('-')],
    async () => {
      const data = await questionApiCalls.getQuestionsIds(categoryIds);
      const returnData: IncompleteQuestionsData = {
        totalNumberOfQuestions: data.totalNumberOfQuestions,
        questionsIds: shuffle(data.questionsIds),
      };
      return returnData;
    },
    { ...queryOptions }
  );
};
