import { MB_Button } from '@mightybyte/rnw.components.button';
import { MB_Image } from '@mightybyte/rnw.components.image';
import { mbHidePopUp, mbShowPopUp } from '@mightybyte/rnw.components.pop-up';
import { MB_TextInput } from '@mightybyte/rnw.components.text-input';
import { mbApplyTransparency } from '@mightybyte/rnw.utils.style-utils';
import { MB_utilHooks } from '@mightybyte/rnw.utils.util-hooks';
import React, { ReactNode, memo } from 'react';
import { View, TouchableOpacity, Text, StyleSheet } from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { COLORS } from '../../../../../../constants/colors';
import { textStyles } from '../../../../../../constants/textStyles';
import { ImageVideoSelectorModal } from '../../../../../helperComponents/ImageVideoSelectorModal';
import { PoolImage, RESOURCE_POOL_TYPE } from '../../../../../../typesAndInterfaces/typesAndInterfaces';
import { AnswerData } from '../ConstructQuestion';

interface AnswerCardProps {
    answer?: AnswerData,
    showPreview?: boolean,
    onAnswerChange?: (answer?: AnswerData) => void,
    disableDelete?: boolean,
    customImage?: ReactNode,
    title?: string
}

const AnswerCard = memo(({ answer, showPreview, onAnswerChange, disableDelete, customImage, title }: AnswerCardProps) => {
    const [isImageSelectorVisible, showResourcePicker, hideResourcePicker] = MB_utilHooks.useBool();


    const onImageSelectorModalDismiss = (image?: PoolImage) => {
        if (image) {
            onAnswerChange?.({ ...answer, image });
        }
        hideResourcePicker();
    };

    const isNotCompleteAnswer = (customImage && title) ? false : answer && (!answer.image || !answer.name || answer.name === '');

    function onDelete() {
        if (answer) {
            mbShowPopUp({
                title: 'Delete Answer',
                message: 'are you sure want to delete the answer?',
                buttonAction: () => {
                    onAnswerChange?.();
                    mbHidePopUp();
                },
                buttonStyle: {
                    container: { backgroundColor: COLORS.errorColor },
                },
                containerStyle: {
                    width: 454,
                },
                secondaryButtonText: 'Cancel',
                secondaryButtonStyle: {
                    text: { color: COLORS.buttonPurple },
                    container: { backgroundColor: COLORS.white },
                },
            });
        }
    }

    if (answer || showPreview) {
        return (
            <View style={[styles.answer, isNotCompleteAnswer && styles.errorBorder]}>
                <ImageVideoSelectorModal
                    isVisible={isImageSelectorVisible}
                    onDismiss={onImageSelectorModalDismiss}
                    resourcePoolType={RESOURCE_POOL_TYPE.Image}
                />
                <TouchableOpacity style={styles.answerLeftSide} onPress={showResourcePicker} disabled={customImage !== undefined || answer?.image !== undefined}>
                    {customImage ?
                        customImage
                        :
                        answer?.image ?
                            <MB_Image
                                source={answer?.image?.medium ?? ''}
                                style={{ width: '100%', height: '100%' }}
                            />
                            :
                            <View style={styles.addImageBackground}>
                                <Feather name={'image'} size={40} color={COLORS.lighterGray} />
                            </View>
                    }
                </TouchableOpacity>
                <View style={styles.answerMiddleSide}>
                    {title && <Text style={[textStyles.smallText, { textAlign: 'left' }]}>{title}</Text>}
                    {!customImage &&
                        <>
                            {answer?.image &&
                                <MB_Button
                                    title="Change image"
                                    style={styles.answerChangeImage}
                                    onPress={showResourcePicker}
                                />
                            }
                            <MB_TextInput
                                title="Answer*"
                                placeholder="Enter the answer"
                                style={styles.answerTextInput}
                                maxLength={30}
                                value={answer?.name ?? ''}
                                onChangeText={(name) => onAnswerChange?.({ ...answer, name })}
                            />
                            <Text style={[textStyles.smallerText, { color: COLORS.textLightPurple, textAlign: 'left' }]}>max. 30 characters</Text>
                        </>
                    }
                </View>
                <View style={styles.answerRightSide}>
                    <FontAwesome
                        name={answer?.isCorrect ? 'star' : 'star-o'}
                        color={COLORS.correctAnswerColor}
                        size={20}
                        onPress={() => onAnswerChange?.({ ...answer, isCorrect: answer?.isCorrect ? false : true })}
                    />
                    {!disableDelete &&
                        <Feather
                            name="trash"
                            color={COLORS.deleteColor}
                            size={20}
                            style={styles.answerTrash}
                            onPress={onDelete}
                        />
                    }
                </View>
            </View>
        );
    }


    return (
        <View style={styles.emptyAnswer}>
            <View style={styles.emptyAnswerLeftSide} />
            <View style={styles.emptyAnswerRightSide} />
        </View>
    );
});

export { AnswerCard };

const styles = StyleSheet.create({
    emptyAnswer: {
        flexDirection: 'row',
        height: 80,
        marginVertical: 16,
        borderRadius: 10,
        borderColor: COLORS.buttonPurple,
        borderWidth: 1,
        borderStyle: 'dashed',
        overflow: 'hidden',
    },
    emptyAnswerLeftSide: {
        width: 164,
        height: '100%',
        backgroundColor: mbApplyTransparency(COLORS.white, 0.2),
    },
    emptyAnswerRightSide: {
        flex: 1,
        backgroundColor: COLORS.backgroundPurple,
    },
    answer: {
        flexDirection: 'row',
        height: 158,
        borderRadius: 10,
        overflow: 'hidden',
        marginVertical: 16,
        borderWidth: 1,
        borderColor: 'transparent',
    },
    answerLeftSide: {
        width: 164,
        height: '100%',
        backgroundColor: mbApplyTransparency(COLORS.white, 0.2),
        alignItems: 'center',
        justifyContent: 'center',
    },
    answerMiddleSide: {
        flex: 1,
        backgroundColor: COLORS.backgroundPurple,
        justifyContent: 'center',
        paddingStart: 24,
    },
    answerChangeImage: {
        width: 152,
        height: 35,
        backgroundColor: COLORS.buttonPurple,
    },
    answerTextInput: {
        width: 261,
        backgroundColor: COLORS.backgroundDarkPurple,
        height: 34,
        marginTop: 16,
    },
    answerRightSide: {
        paddingHorizontal: 8,
        backgroundColor: COLORS.backgroundPurple,
        justifyContent: 'space-between',
        paddingVertical: 22,
        alignItems: 'center',
    },
    answerTrash: {
        backgroundColor: mbApplyTransparency(COLORS.white, 0.25),
        borderRadius: 11,
        paddingVertical: 6,
        paddingHorizontal: 6,
    },
    errorBorder: {
        borderWidth: 1,
        borderColor: mbApplyTransparency(COLORS.errorColor, 0.5),
    },
    addImageContainer: {
        width: 319,
        height: 265,
        borderRadius: 10,
        borderWidth: 1,
        borderColor: COLORS.lighterGray,
        backgroundColor: COLORS.backgroundPurple,
        borderStyle: 'dashed',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 9,
        overflow: 'hidden',
    },
    addImageBackground: {
        borderRadius: 300,
        width: 80,
        height: 80,
        backgroundColor: mbApplyTransparency(COLORS.black, 0.3),
        alignItems: 'center',
        justifyContent: 'center',
    },
});
