import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

type CouponIconProps = {
    size?: number
}

function CouponIcon({ size = 24 }: CouponIconProps) {
    return (
        <Svg
            width={size}
            height={size * (25 / 24)}
            viewBox="0 0 24 25"
            fill="none"
        >
            <Path
                d="M19 4.5a3 3 0 012.995 2.824L22 7.5v1.817a1.55 1.55 0 01-.776 1.33l-.107.058a2 2 0 00-.14 3.515l.14.075c.433.214.82.656.876 1.24l.007.148V17.5a3 3 0 01-2.824 2.995L19 20.5H5a3 3 0 01-2.995-2.824L2 17.5v-1.817c0-.606.352-1.078.776-1.33l.107-.058a2 2 0 00.14-3.515l-.14-.075c-.433-.214-.82-.656-.876-1.24L2 9.318V7.5a3 3 0 012.824-2.995L5 4.5h14zm0 2H5a1 1 0 00-.993.883L4 7.5v1.535a4 4 0 01.185 6.816L4 15.965V17.5a1 1 0 00.883.993L5 18.5h14a1 1 0 00.993-.883L20 17.5v-1.535a4 4 0 01-.185-6.816L20 9.035V7.5a1 1 0 00-.883-.993L19 6.5zm-9 3a1 1 0 01.993.883L11 10.5v4a1 1 0 01-1.993.117L9 14.5v-4a1 1 0 011-1z"
                fill="#fff"
            />
        </Svg>
    );
}

export default CouponIcon;
