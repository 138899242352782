import { z } from 'zod';
import { REDIRECT_REASON } from '../components/screens/ServerRedirect/serverRedirectUtils';
import {
  PAYMENT_PROVIDER,
  SERVER_ERROR_CODES,
} from '../constants/constants';
import {
  AnswerObj,
  CategoryForUserObj,
  CategoryObj,
  ChangeCategoryPaymentObj,
  ChangeDifficultyResponseObj,
  FolderObj,
  ImageObj,
  IncompleteQuestionsDataObj,
  LeaderboardObj,
  PasswordRecoveryTokenObj,
  PoolImageObj,
  PoolVideoObj,
  PusherAuthorizationObj,
  PusherPresenceObj,
  PusherUserInfoObj,
  QuestionObj,
  QuestionShortObj,
  RoomObj,
  RoomGameObj,
  UserObj,
  VideoObj,
  RoomUpdatedObj,
  GameStartedObj,
  GameFinishedObj,
  GameScoreObj,
  ExtrasObj,
  EducatorCodeObj,
} from '../utils/zod/zodObjects';
import { BODY_PARTS } from '../components/screens/Game/bodyPartsControls/bodyPartsConstants';
import { GEOGRAPHY_LOCATION, GEOGRAPHY_MAPS } from '../components/screens/Game/geographyControls/geographyConstants';

export type Category = z.infer<typeof CategoryObj>;

export type CategoryForUser = z.infer<typeof CategoryForUserObj>;

export type PasswordRecoveryToken = z.infer<typeof PasswordRecoveryTokenObj>;

export type Image = z.infer<typeof ImageObj>;

export type PoolImage = z.infer<typeof PoolImageObj>;

export type PoolVideo = z.infer<typeof PoolVideoObj>;

export type Answer = z.infer<typeof AnswerObj>;

export type Folder = z.infer<typeof FolderObj>;

export type EducatorCode = z.infer<typeof EducatorCodeObj>;

export enum GAME_TYPE {
  IntroGame = 'IntroGame',
  MatchingItemsGame = 'MatchingItemsGame',
  BodyParts = 'BodyParts',
  Geography = 'Geography',
  Extras = 'Extras',
}

export enum GAME_SUBTYPE {
  price = 'price',
  number = 'number',
  date = 'date',
  year = 'year'
}

export enum ANSWER_BUTTON_STATE {
  correct = 'correct',
  wrong = 'wrong',
  selected = 'selected',
  unselected = 'unselected'
}

export type Question = z.infer<typeof QuestionObj>;

export type Room = z.infer<typeof RoomObj>;

export type GameScore = z.infer<typeof GameScoreObj>;

export type Leaderboard = z.infer<typeof LeaderboardObj>;

export type PusherAuthorization = z.infer<typeof PusherAuthorizationObj>;

export type PusherUserInfo = z.infer<typeof PusherUserInfoObj>;

export type PusherPresence = z.infer<typeof PusherPresenceObj>;

export type RoomGame = z.infer<typeof RoomGameObj>;

export type RoomUpdated = z.infer<typeof RoomUpdatedObj>;

export type GameStarted = z.infer<typeof GameStartedObj>;

export type GameFinished = z.infer<typeof GameFinishedObj>;

export type QuestionShort = z.infer<typeof QuestionShortObj>;

export type User = z.infer<typeof UserObj>;

export type Video = z.infer<typeof VideoObj>;

export type ChangeDifficultyResponse = z.infer<typeof ChangeDifficultyResponseObj>;

export type ChangeCategoryPaymentResponse = z.infer<typeof ChangeCategoryPaymentObj>;

export type IncompleteQuestionsData = z.infer<typeof IncompleteQuestionsDataObj>;

export type Extras = z.infer<typeof ExtrasObj>;

export enum UserRole {
  user = 'user',
  admin = 'admin',
}

export enum DIFFICULTY {
  beginner = 'beginner',
  beginnerPlus = 'beginnerPlus',
  intermediate = 'intermediate',
  intermediatePlus = 'intermediatePlus',
  advanced = 'advanced',
  advancedPlus = 'advancedPlus'
}

export enum PAYMENT_STATUS {
  paid = 'paid',
  notPaid = 'notPaid',
  pending = 'pending',
}

export const DifficultyNumeric: { [key in DIFFICULTY]: number } = {
  beginner: 0,
  beginnerPlus: 1,
  intermediate: 2,
  intermediatePlus: 3,
  advanced: 4,
  advancedPlus: 5,
};

export interface ServerError {
  message?: string,
  errorCode: SERVER_ERROR_CODES | string,
  status: 'error' | 'success',
  data?: any,
  redirectReason?: REDIRECT_REASON,
}

export interface ServerSuccess {
  status: 'error' | 'success',
  data: null | any,
  redirectReason?: REDIRECT_REASON,
}

export interface PaginationPage<T> {
  items: T[],
  page: number,
  totalItems: number,
}

export type UploadParams = { formData: FormData, uploadProgress?: (percentCompleted: number) => void }

export type AnswerRequest = {
  poolImageId: string,
  name: string,
  isCorrect: boolean,
}

type AcknowledgeRequestApple = {
  /**
       * The receipt data received from the App Store.
       */
  receiptData: string;
  /**
   * The transaction id from App Store's transaction.
   */
  transactionId?: string;
}

type AcknowledgeRequestGoogle = {
  /**
 * The purchase token received from the Play Store.
 */
  purchaseToken: string,
}

export type AcknowledgeRequest<P extends PAYMENT_PROVIDER> = {
  paymentProvider: P,
  /**
   * The purchased product's id.
   */
  productId: string
} & (P extends PAYMENT_PROVIDER.apple ? AcknowledgeRequestApple : AcknowledgeRequestGoogle);

export enum RESOURCE_POOL_TYPE {
  Image = 'Image',
  Video = 'Video'
}


export interface CreateQuestionRquest { // TODO: We should technically have a bit of a dynamic type here based on the game type
  categoryId: string,
  name: string,
  signerName: string,
  poolVideoId: string,
  answers?: AnswerRequest[],
  gameSubType?: GAME_SUBTYPE,
  matchingItemsGameValue?: string,
  bodyPart?: BODY_PARTS,
  geographyLocation?: GEOGRAPHY_LOCATION,
  geographyMap?: GEOGRAPHY_MAPS,
}

export interface EditQuestionRequest { // TODO: We should technically have a bit of a dynamic type here based on the game type
  questionId: string,
  name?: string,
  signerName?: string,
  poolVideoId?: string,
  answers?: AnswerRequest[],
  gameSubType?: GAME_SUBTYPE,
  matchingItemsGameValue?: string,
  bodyPart?: BODY_PARTS,
  geographyLocation?: GEOGRAPHY_LOCATION,
  geographyMap?: GEOGRAPHY_MAPS,
}

export enum FOLDER_TYPE {
  image = 'image',
  video = 'video'
}

export enum TOOL_TIP_AUTO_SHOW_COLLECTION {
  Level = 'Level',
  IntroGame = 'IntroGame',
  MatchingItemsGame = 'MatchingItemsGame'
}

export enum ROOM_STATUS {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  ENDED = 'ENDED'
}

export interface Player {
  userId: string,
  name: string
}

export enum ROOM_EVENT_NAME {
  ROOM_UPDATED = 'ROOM_UPDATED',
  GAME_STARTED = 'GAME_STARTED',
  LEADERBOARD_UPDATED = 'LEADERBOARD_UPDATED',
  GAME_FINISHED = 'GAME_FINISHED',
  ROOM_DELETED = 'ROOM_DELETED'
}

export enum SCORE_TYPE {
  TIME = 'TIME',
  ACCURACY = 'ACCURACY',
  CHALLENGE_MODE = 'CHALLENGE_MODE',
}

export const scoringTypeToString: { [key in SCORE_TYPE]: string } = {
  TIME: 'TIME',
  ACCURACY: 'ACCURACY',
  CHALLENGE_MODE: 'CHALLENGE MODE',
};
