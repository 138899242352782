import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { NativeModules, Platform } from 'react-native';

const appFlavor = (isMobileApp ? NativeModules.RNConfig.flavor : process.env.flavor) as 'dev' | 'staging' | 'prod' | undefined;

if (!appFlavor || (appFlavor !== 'prod' && appFlavor !== 'staging' && appFlavor !== 'dev')) {
    throw 'Error: Invalid flavor specified. Must be "dev", "staging", or "prod"';
}

const serverURLs: { dev: string, staging: string, prod: string } = {
    dev: 'https://localhost:5022',
    staging: 'https://api.staging.aslflurry.com',
    prod: 'https://api.aslflurry.com',
};

const websiteBaseUrls: { dev: string, staging: string, prod: string } = {
    dev: 'http://localhost:8022',
    staging: 'https://app.staging.aslflurry.com',
    prod: 'https://app.aslflurry.com',
};

const pusherKey: { dev: string, staging: string, prod: string } = {
    dev: '1ac598bc45f52dacce42',
    staging: '1bbfd6b34cb71737c26e',
    prod: 'd2557661726abf9f414b',
};

// IMPORTANT: If you want to support app center analytics, please add appcenter-config.json file to the project.
// For android, the location should be android/app/src/main/assets/appcenter-config.json
// For iOS, the location should be next to Info.plist and also make sure to add the file to xcode.
const deploymentKey: { dev: string, staging: string, prod: string } = {
    dev: '', // NOTE: do not add deployment key for dev unless you know what you are doing.
    staging: Platform.OS === 'android' ? 'mfTKRNDhqNs1uGOhchLhRq9WkUo5UFcZFQt3X' : 'OZoWIqQRbNxNYOGoxZ7j3cGuHwsdM7CX8H4dl',
    prod: Platform.OS === 'android' ? 'AZOE8wSxFrkByVr1SqCWfpCWJFmuf1sJzRr2N' : '0JyZxZzV354h8Z5aCCMhO2o54CXGQvuv6bTw4',
};


const envs = {
    FLAVOR: appFlavor,
    SERVER_URL: serverURLs[appFlavor],
    WEBSITE_BASE_URL: websiteBaseUrls[appFlavor],
    MOBILE_DEEP_LINK: 'asl-flurry://',
    MOBILE_DEEP_LINK_FOR_SERVER_REDIRECT: 'asl-flurry-server-redirect://',
    DONATE_URL: 'https://www.paypal.com/donate/?hosted_button_id=RAZZ8AP23WU8U',
    TOS_URL: 'http://legal.app.aslflurry.com/TermsOfService',
    PRIVACY_POLICY_URL: 'http://legal.app.aslflurry.com/PrivacyPolicy',
    PLAY_STORE_DEEP_LINK: 'market://details?id=com.aslflurry.app',
    APP_STORE_DEEP_LINK: 'itms-apps://apps.apple.com/us/app/asl-flurry/id1659464785',
    DEPLOYMENT_KEY: deploymentKey[appFlavor],
    PUSHER_KEY: pusherKey[appFlavor],
    PUSHER_CLUSTER: 'us3',
};

export { envs };
