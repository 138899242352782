import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg';

const Ok = React.memo((props: { size?: number, color?: string, containerStyle?: StyleProp<ViewStyle> }) => {
    return (
        <View style={props.containerStyle}>
            <Svg
                width={props.size ?? 24}
                height={props.size ?? 24}
                viewBox="0 0 24 24"
            >
                <G clipPath="url(#clip0_3109_1290)" fill={props.color ?? '#fff'}>
                    <Path d="M18.147 6.545l-1.172 2.288 1.387-6.903A1.598 1.598 0 0017.057.015a1.648 1.648 0 00-1.837 1.302l-.737 3.241-.78 3.618-.093-6.21a1.6 1.6 0 10-3.201.048v7.002l-.6-1.263a1.595 1.595 0 00-1.9-.854l-2.152.633c-.258.08-.482.243-.637.464l-1.046 1.49a1.509 1.509 0 00-.222 1.31c.002.006.006.01.008.017-.6.073-1.051.58-1.053 1.184v3.099c0 .322.13.63.36.856l3.89 3.676c.128.126.227.278.29.446.07.174.158.34.261.496v2.23a1.2 1.2 0 001.2 1.2h6.402a1.2 1.2 0 001.2-1.2v-2.191c.352-.466.608-.998.75-1.564.228-.912.386-1.839.473-2.774.054-.454.11-.914.181-1.38.13-.796.388-1.566.764-2.28l2.474-4.724a1.6 1.6 0 00-.817-2.173 1.638 1.638 0 00-2.088.831zm-9.843 6.713l-.847 1.26a1.332 1.332 0 01-.32-.171l-.57-.43a.4.4 0 01-.16-.32v-.4c.002-.525-.171-1.035-.49-1.452.172-.075.329-.182.462-.314l.924-.957a.272.272 0 01.44.124l.597 2.378a.375.375 0 01-.035.282zM19.922 6.45a.797.797 0 01.415 1.075l-2.47 4.715a8.287 8.287 0 00-.844 2.528c-.073.475-.13.944-.184 1.407a18.08 18.08 0 01-.45 2.657c-.247.897-.79 1.966-1.979 1.966a.4.4 0 000 .8c.42.002.833-.104 1.2-.307V22.8a.4.4 0 01-.4.4H8.81a.4.4 0 01-.4-.4v-1.484c.37.193.782.29 1.2.283a.4.4 0 000-.8 1.494 1.494 0 01-1.52-1.021 2.026 2.026 0 00-.485-.732l-3.88-3.667a.397.397 0 01-.117-.283v-3.1a.397.397 0 01.389-.4 1.6 1.6 0 011.612 1.601v.4c0 .378.178.733.48.96l.57.429a2.167 2.167 0 00.911.384c.484.086.982.01 1.418-.214a.4.4 0 00-.357-.716c-.07.033-.14.06-.214.084l.56-.832c.169-.272.22-.601.141-.912l-.595-2.374a1.057 1.057 0 00-1.03-.81 1.044 1.044 0 00-.768.326l-.916.948a.714.714 0 01-1.08-.924l1.048-1.492a.41.41 0 01.213-.155l2.147-.63a.8.8 0 01.953.426l1.207 2.551a.48.48 0 00.913-.206V2.007a.8.8 0 111.6-.027l.133 8.843a.499.499 0 00.987.1l1.335-6.19.738-3.24a.84.84 0 01.924-.685.796.796 0 01.652.963l-1.843 9.173a.48.48 0 00.896.32l2.232-4.36a.85.85 0 011.06-.453z" />
                    <Path d="M11.266 13.201a.4.4 0 00-.114.792c.72.104 2.721.624 3.3 1.784a.4.4 0 00.716-.358c-.884-1.768-3.78-2.2-3.902-2.218zM10.958 14.826a.4.4 0 10-.298.743c.018.007 1.75.729 1.75 3.23a.4.4 0 10.8 0c0-3.037-2.161-3.936-2.252-3.973z" />
                </G>
                <Defs>
                    <ClipPath id="clip0_3109_1290">
                        <Path fill={props.color ?? '#fff'} d="M0 0H24V24H0z" />
                    </ClipPath>
                </Defs>
            </Svg>
        </View>
    );
});

export { Ok };
