import { UseQueryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { PusherAuthorization, Room, RoomGame, SCORE_TYPE, ServerError } from '../typesAndInterfaces/typesAndInterfaces';
import { roomApiCalls } from '../apiCalls/roomApiCalls';
import { ROOM_QUERY_KEYS } from '../constants/constants';
import { useRTCommContext } from '../context/RTCommContextProvider';

export const useAuthorizeRoomChannelUser = () => {
    return useMutation<PusherAuthorization, ServerError, { channelName: string, socketId: string }, unknown>(
        async ({ channelName, socketId }) => roomApiCalls.authorizeRoomChannelUser(channelName, socketId)
    );
};


export const useCreateRoom = () => {
    const queryClient = useQueryClient();

    return useMutation<Room, ServerError, { name: string }, unknown>(
        async ({ name }) => roomApiCalls.createRoom(name), {
        onSuccess: (room) => {
            queryClient.setQueryData([ROOM_QUERY_KEYS.getRoom, room._id], room);
        },
    });
};

export const useJoinRoom = () => {
    const queryClient = useQueryClient();

    return useMutation<Room, ServerError, { code: string }, unknown>(
        async ({ code }) => roomApiCalls.joinRoom(code), {
        onSuccess: (room) => {
            queryClient.setQueryData([ROOM_QUERY_KEYS.getRoom, room._id], room);
        },
    });
};

export const useGetRoom = ({ roomId, queryOptions }: { roomId: string, queryOptions?: UseQueryOptions<Room, ServerError> }) => {
    const { setLeaderboard } = useRTCommContext();
    return useQuery<Room, ServerError>([ROOM_QUERY_KEYS.getRoom, roomId],
        async () => {
            const data = await roomApiCalls.getRoom(roomId);
            if (data.leaderboard) {
                setLeaderboard(data.leaderboard);
            }
            return data;
        },
        {
            refetchOnWindowFocus: false,
            cacheTime: Infinity,
            staleTime: Infinity,
            retry: (_failureCount, error) => error.errorCode === 'NOT_FOUND' ? false : true,
            ...queryOptions,
        },
    );
};

export const useStartRoomGame = () => {
    const queryClient = useQueryClient();

    return useMutation<Room, ServerError, { roomId: string, scoringType: SCORE_TYPE, roomGame: RoomGame, isCreatorParticipating: boolean }, unknown>(
        async ({ roomId, scoringType, roomGame, isCreatorParticipating }) => roomApiCalls.startRoomGame(roomId, scoringType, roomGame, isCreatorParticipating), {
        onSuccess: (room) => {
            queryClient.setQueryData([ROOM_QUERY_KEYS.getRoom, room._id], room);
        },
    }
    );
};

export const useUpdateRoom = () => {
    const queryClient = useQueryClient();

    return useMutation<Room, ServerError, { roomId: string, scoringType?: SCORE_TYPE, roomGame?: RoomGame, isCreatorParticipating?: boolean }, unknown>(
        async ({ roomId, scoringType, roomGame, isCreatorParticipating }) => roomApiCalls.updateRoom(roomId, scoringType, roomGame, isCreatorParticipating), {
        onSuccess: (room) => {
            queryClient.setQueryData([ROOM_QUERY_KEYS.getRoom, room._id], room);
        },
    }
    );
};


export const useFinishRoomGame = () => {
    const queryClient = useQueryClient();

    return useMutation<Room, ServerError, { roomId: string }, unknown>(
        async ({ roomId }) => roomApiCalls.finishRoomGame(roomId), {
        onSuccess: (room) => {
            queryClient.setQueryData([ROOM_QUERY_KEYS.getRoom, room._id], room);
        },
    }
    );
};

export const useDeleteRoom = () => {
    const queryClient = useQueryClient();

    return useMutation<void, ServerError, { roomId: string }, unknown>(
        async ({ roomId }) => roomApiCalls.deleteRoom(roomId), {
        onSuccess: (_, { roomId }) => {
            queryClient.setQueryData([ROOM_QUERY_KEYS.getRoom, roomId], undefined);
        },
    }
    );
};


export const useAddPlayerScoreToLeaderboard = () => {
    const queryClient = useQueryClient();

    return useMutation<Room, ServerError, { roomId: string, score: number }, unknown>(
        async ({ roomId, score }) => roomApiCalls.addPlayerScoreToLeaderboard(roomId, score), {
        onSuccess: (room) => {
            queryClient.setQueryData([ROOM_QUERY_KEYS.getRoom, room._id], room);
        },
    }
    );
};
