import React, { useRef } from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { MB_Button } from '@mightybyte/rnw.components.button';
import { MB_TextInput, MB_TextInputRef } from '@mightybyte/rnw.components.text-input';
import { isMobile } from '@mightybyte/rnw.utils.device-info';
import { COLORS } from '../../../constants/colors';
import { textStyles } from '../../../constants/textStyles';
import { ILoginInput } from './LoginUtils';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';

export enum LOGIN_UI_BUTTON_PRESSED_TYPES {
    SIGN_IN,
    NAVIGATE_HOME,
    NAVIGATE_FORGOT_PASSWORD,
    NAVIGATE_SIGN_UP,
}

const LoginInput = ({ containerStyle, onButtonPressed, email, setEmail, password, setPassword, errorMessage, setErrorMessage, isLoading }: ILoginInput) => {
    const emailRef = useRef<MB_TextInputRef>(null);
    const passwordRef = useRef<MB_TextInputRef>(null);

    const onSubmitEditing = (ref?: MB_TextInputRef | null) => {
        if (!email || !password) {
            setTimeout(() => ref?.focus(), 100);
        } else {
            onButtonPressed(LOGIN_UI_BUTTON_PRESSED_TYPES.SIGN_IN);
        }
    };

    return (
        <View style={[styles.container, containerStyle]}>

            <MB_TextInput
                MB_Ref={emailRef}
                style={[styles.textInputStyle, { marginBottom: 8 }]}
                titleStyle={styles.textInputTitleStyle}
                value={email}
                onChangeText={(newText: string) => {
                    setEmail(newText);
                    setErrorMessage('');
                }}
                title="Email address *"
                placeholder="you@example.com"
                textContentType="emailAddress"
                onSubmitEditing={() => onSubmitEditing(emailRef.current)}
            />

            <MB_TextInput
                MB_Ref={passwordRef}
                style={[styles.textInputStyle, { marginTop: isMobile ? 14 : 25 }]}
                titleStyle={styles.textInputTitleStyle}
                value={password}
                onChangeText={(newText: string) => {
                    setPassword(newText);
                    setErrorMessage('');
                }}
                title="Password *"
                placeholder="Enter password"
                secureTextEntry
                textContentType="password"
                onSubmitEditing={() => onSubmitEditing(passwordRef.current)}
            />

            <MB_Button
                title="Forgot Password?"
                onPress={() => onButtonPressed(LOGIN_UI_BUTTON_PRESSED_TYPES.NAVIGATE_FORGOT_PASSWORD)}
                textStyle={styles.forgotPasswordText}
                style={styles.forgotPasswordButton}
            />

            {errorMessage !== '' &&
                <Text style={textStyles.popUpErrorMessageText}>{errorMessage}</Text>
            }

            <MB_Button
                title="Sign In"
                onPress={() => onButtonPressed(LOGIN_UI_BUTTON_PRESSED_TYPES.SIGN_IN)}
                disabled={!email || !password}
                textStyle={textStyles.normalText}
                style={styles.signInButtonStyle}
                loading={isLoading}
            />
        </View>
    );
};

export default LoginInput;

const styles = StyleSheet.create({
    container: {
        alignItems: 'stretch',
    },
    textInputStyle: {
        width: '100%',
        backgroundColor: COLORS.backgroundPurple,
        borderWidth: 0,
        borderRadius: 10,
    },
    forgotPasswordButton: {
        backgroundColor: 'transparent',
        paddingHorizontal: 0,
        paddingVertical: 0,
        marginBottom: 24,
        height: isMobile ? 18 : undefined,
        alignSelf: 'flex-end',
    },
    forgotPasswordText: mbTextStyles([
        textStyles.smallerText, {
            color: isMobile ? COLORS.dimViolet : COLORS.gray,
        },
    ]),
    textInputTitleStyle: {
        color: COLORS.textLightPurple,
    },
    signInButtonStyle: {
        backgroundColor: COLORS.buttonPurple,
        width: isMobile ? undefined : '100%',
        marginTop: 8,
        borderRadius: 10,
    },
});

