import { MB_Button } from '@mightybyte/rnw.components.button';
import { mbHidePopUp, mbShowPopUp } from '@mightybyte/rnw.components.pop-up';
import { MB_TextInput, MB_TextInputToolTipPortal, MB_TextInputToolTipPortalRef } from '@mightybyte/rnw.components.text-input';
import { mbShowToast } from '@mightybyte/rnw.components.toast';
import { MB_PasswordToolTip, TOOLTIP_ARROW_DIRECTION, TOOLTIP_POSITION } from '@mightybyte/rnw.components.tool-tip';
import { isMobile } from '@mightybyte/rnw.utils.device-info';
import { MB_passwordUtils, MB_PASSWORD_UTIL_ERROR_TYPES } from '@mightybyte/rnw.utils.password-utils';
import { mbPlatformStyle, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import React, { useRef, useState } from 'react';
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native';
import { COLORS } from '../../constants/colors';
import { SERVER_ERROR_CODES, STRING_CONSTANTS } from '../../constants/constants';
import { textStyles } from '../../constants/textStyles';
import { useChangePassword, useChangePasswordRecovery } from '../../hooks/userHooks';
import { PasswordRecoveryToken } from '../../typesAndInterfaces/typesAndInterfaces';

enum INPUT_TYPES {
    currentPassword = 'currentPassword',
    password = 'password',
    repeatPassword = 'repeatPassword',
}

interface ChangePasswordPopUpProps {
    TitleComponent?: JSX.Element,
    style?: StyleProp<ViewStyle>,
    submitButton?: { style?: StyleProp<ViewStyle>, title?: string },
    dismissCallback?: (dismissData?: { isSuccess?: boolean }) => void,
    requireOldPassword: boolean,
    passwordRecoveryToken?: PasswordRecoveryToken,
}

const ChangePasswordPopUp = ({ TitleComponent, style, submitButton, dismissCallback, requireOldPassword, passwordRecoveryToken }: ChangePasswordPopUpProps) => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [inputError, setInputError] = useState<INPUT_TYPES[]>([]);

    const changePassword = useChangePassword();
    const changePasswordRecovery = useChangePasswordRecovery();

    const generatePasswordErrorArray = (errorArray: MB_PASSWORD_UTIL_ERROR_TYPES[], doPasswordsMatch: boolean): { message: string, isError: boolean }[] => {
        const errors: { message: string, isError: boolean }[] = [];

        errors.push({ message: MB_PASSWORD_UTIL_ERROR_TYPES.min, isError: errorArray.includes(MB_PASSWORD_UTIL_ERROR_TYPES.min) || errorArray.includes(MB_PASSWORD_UTIL_ERROR_TYPES.max) });
        errors.push({ message: MB_PASSWORD_UTIL_ERROR_TYPES.lowercase, isError: errorArray.includes(MB_PASSWORD_UTIL_ERROR_TYPES.lowercase) });
        errors.push({ message: MB_PASSWORD_UTIL_ERROR_TYPES.uppercase, isError: errorArray.includes(MB_PASSWORD_UTIL_ERROR_TYPES.uppercase) });
        errors.push({ message: MB_PASSWORD_UTIL_ERROR_TYPES.digits, isError: errorArray.includes(MB_PASSWORD_UTIL_ERROR_TYPES.digits) });
        errors.push({ message: MB_PASSWORD_UTIL_ERROR_TYPES.spaces, isError: errorArray.includes(MB_PASSWORD_UTIL_ERROR_TYPES.spaces) });
        if (errorArray.includes(MB_PASSWORD_UTIL_ERROR_TYPES.unknown)) {
            errors.push({ message: MB_PASSWORD_UTIL_ERROR_TYPES.unknown, isError: true });
        }

        errors.push({ message: MB_PASSWORD_UTIL_ERROR_TYPES.passwordMismatch, isError: !doPasswordsMatch });

        return errors;
    };
    const [mainPasswordErrorArray, setMainPasswordErrorArray] = useState<{ message: string, isError: boolean }[]>(generatePasswordErrorArray(MB_passwordUtils.validatePassword('').errorArray, true));
    const [repeatPasswordErrorArray, setRepeatPasswordErrorArray] = useState<{ message: string, isError: boolean }[]>(generatePasswordErrorArray(MB_passwordUtils.validatePassword('').errorArray, true));


    const onPasswordSubmit = () => {
        setErrorMessage('');
        setInputError([]);

        // if (!passwordRecoveryToken) {
        //     const validateCurrentPassword = MB_passwordUtils.validatePassword(currentPassword);
        //     if (validateCurrentPassword.errorMessage) {
        //         setErrorMessage(validateCurrentPassword.errorMessage);
        //         setInputError([INPUT_TYPES.currentPassword]);
        //         return;
        //     }
        // }

        const validatePassword = MB_passwordUtils.validatePassword(password);
        if (validatePassword.errorMessage) {
            setErrorMessage(validatePassword.errorMessage);
            setInputError([INPUT_TYPES.password]);
            return;
        }

        const validateRepeatPassword = MB_passwordUtils.validatePassword(repeatPassword);
        if (validateRepeatPassword.errorMessage) {
            setErrorMessage(validateRepeatPassword.errorMessage);
            setInputError([INPUT_TYPES.repeatPassword]);
            return;
        }

        if (password !== repeatPassword) {
            setErrorMessage(STRING_CONSTANTS.PASSWORDS_DO_NOT_MATCH);
            setInputError([INPUT_TYPES.password, INPUT_TYPES.repeatPassword]);
            return;
        }

        if (!passwordRecoveryToken) {
            if (currentPassword === password) {
                setErrorMessage(STRING_CONSTANTS.NEW_PASSWORD_IS_SAME_AS_OLD);
                setInputError([INPUT_TYPES.password, INPUT_TYPES.repeatPassword]);
                return;
            }
        }

        if (passwordRecoveryToken) {
            changePasswordRecovery.mutate({ newPassword: password, recoveryToken: passwordRecoveryToken.token },
                {
                    onSuccess: () => {
                        mbShowPopUp({
                            title: 'Success',
                            message: 'Password successfully changed',
                            buttonAction: () => {
                                mbHidePopUp();
                                dismissCallback?.({ isSuccess: true });
                            },
                        });
                    },
                    onError: (error) => {
                        if (error.errorCode === SERVER_ERROR_CODES.INVALID_PASSWORD) {
                            setErrorMessage(STRING_CONSTANTS.CURRENT_PASSWORD_WAS_INVALID);
                            setInputError([INPUT_TYPES.currentPassword]);
                        } else {
                            setErrorMessage(STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN);
                        }
                    },
                });
        } else {
            changePassword.mutate({ currentPassword, newPassword: password }, {
                onSuccess: () => {
                    setCurrentPassword('');
                    setPassword('');
                    setRepeatPassword('');
                    mbShowToast({
                        text1: 'Updated!',
                        text2: 'Password updated successfully.',
                        type: 'success',
                    });
                    mbShowPopUp({
                        title: 'Success',
                        message: 'Password successfully changed',
                        buttonAction: () => {
                            mbHidePopUp();
                            dismissCallback?.({ isSuccess: true });
                        },
                    });
                },
                onError: (error) => {
                    if (error.errorCode === SERVER_ERROR_CODES.EXPIRED_PASSWORD_RECOVERY_TOKEN) {
                        setErrorMessage(STRING_CONSTANTS.PASSWRORD_RECOVERY_EXPIRED);
                    } else if (error.errorCode === SERVER_ERROR_CODES.INVALID_PASSWORD) {
                        setErrorMessage(STRING_CONSTANTS.CURRENT_PASSWORD_WAS_INVALID);
                    } else {
                        setErrorMessage(STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN);
                    }
                },
            });
        }

    };

    const portalRef = useRef<MB_TextInputToolTipPortalRef>(null);

    return (

        <View style={[styles.AccessCodeEnterContainer, style]}>
            {TitleComponent}
            {requireOldPassword &&
                <MB_TextInput
                    isError={inputError.includes(INPUT_TYPES.currentPassword)}
                    style={styles.input}
                    value={currentPassword}
                    onChangeText={(newText: string) => {
                        setCurrentPassword(newText);
                        setErrorMessage('');
                        setInputError([]);
                    }}
                    title={'Current password'}
                    placeholder="Current password"
                    titleStyle={styles.title}
                    secureTextEntry
                    textContentType="password"
                    wrapInFormElement
                />
            }

            <MB_TextInput
                isError={inputError.includes(INPUT_TYPES.password)}
                style={[styles.input, { marginTop: 45 }]}
                value={password}
                onChangeText={(newText: string) => {
                    setPassword(newText);
                    setErrorMessage('');
                    setInputError([]);
                    setMainPasswordErrorArray(generatePasswordErrorArray(MB_passwordUtils.validatePassword(newText).errorArray, newText === repeatPassword));
                }}
                onFocus={() => {
                    setMainPasswordErrorArray(generatePasswordErrorArray(MB_passwordUtils.validatePassword(password).errorArray, repeatPassword === password));
                }}
                title={'New password'}
                placeholder="New password"
                titleStyle={styles.title}
                textContentType="password"
                maxLength={256}
                secureTextEntry
                wrapInFormElement
                toolTipData={{
                    showOnFocus: true,
                    toolTipPosition: isMobile ? TOOLTIP_POSITION.top : TOOLTIP_POSITION.left,
                    moveBy: {
                        x: isMobile ? 0 : -218,
                        y: isMobile ? -60 : 80,
                    },
                    toolTipElement: <MB_PasswordToolTip errorArray={mainPasswordErrorArray} arrowDirection={isMobile ? TOOLTIP_ARROW_DIRECTION.down : TOOLTIP_ARROW_DIRECTION.right} containerStyle={{ marginTop: -150 }} />,
                    portalRef: portalRef,
                }}
            />

            <MB_TextInput
                isError={inputError.includes(INPUT_TYPES.repeatPassword)}
                style={[styles.input, styles.repeatPassword]}
                value={repeatPassword}
                onChangeText={(newText: string) => {
                    setRepeatPassword(newText);
                    setErrorMessage('');
                    setInputError([]);
                    setRepeatPasswordErrorArray(generatePasswordErrorArray(MB_passwordUtils.validatePassword(newText).errorArray, newText === password));
                }}
                onFocus={() => {
                    setRepeatPasswordErrorArray(generatePasswordErrorArray(MB_passwordUtils.validatePassword(repeatPassword).errorArray, repeatPassword === password));
                }}
                title={'Re-enter new password'}
                placeholder="Re-enter new password"
                titleStyle={styles.title}
                textContentType="password"
                maxLength={256}
                secureTextEntry
                wrapInFormElement
                toolTipData={{
                    showOnFocus: true,
                    toolTipPosition: isMobile ? TOOLTIP_POSITION.top : TOOLTIP_POSITION.left,
                    moveBy: {
                        x: isMobile ? 0 : -218,
                        y: isMobile ? -60 : 80,
                    },
                    toolTipElement: <MB_PasswordToolTip errorArray={repeatPasswordErrorArray} arrowDirection={isMobile ? TOOLTIP_ARROW_DIRECTION.down : TOOLTIP_ARROW_DIRECTION.right} containerStyle={{ marginTop: -150 }} />,
                    portalRef: portalRef,
                }}
            />

            <MB_Button
                title={submitButton?.title ?? 'Submit'}
                textStyle={textStyles.smallText}
                style={[submitButton?.style, styles.submitBtn]}
                onPress={onPasswordSubmit}
                disabled={changePassword.isLoading || !password || !repeatPassword || (requireOldPassword && !currentPassword)}
                loading={changePassword.isLoading}
            />
            <Text style={textStyles.popUpErrorMessageText}>{errorMessage}</Text>

            <MB_TextInputToolTipPortal MB_Ref={portalRef} />
        </View>
    );
};

export { ChangePasswordPopUp };

const styles = StyleSheet.create({
    AccessCodeEnterContainer: {
        borderRadius: 6,
        alignItems: 'center',
        justifyContent: 'center',
    },
    input: {
        height: 53,
        width: 386,
        borderRadius: 10,
        backgroundColor: COLORS.backgroundPurple,
        borderWidth: 0,
        ...mbPlatformStyle({
            mobile: {
                height: 44,
                width: '100%',
            },
        }),
    },
    title: mbTextStyles([
        textStyles.smallText, {
            textAlign: 'left',
            color: COLORS.textLightPurple,
            paddingBottom: 4,
        },
    ]),
    repeatPassword: {
        marginTop: isMobile ? 14 : 32,
    },
    submitBtn: {
        marginTop: 62,
        marginBottom: 10,
        height: 62,
        paddingHorizontal: 88,
        backgroundColor: COLORS.buttonPurple,
        borderRadius: 10,
        width: '100%',
        ...mbPlatformStyle({
            mobile: {
                height: 44,
                marginTop: 33,
            },
        }),
    },
});
