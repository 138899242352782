import React, { ReactNode, useEffect } from 'react';
import { StyleProp, StyleSheet, Text, ViewStyle } from 'react-native';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { MB_ToolTipHover } from '@mightybyte/rnw.components.tool-tip';
import { useToolTipAutoShow } from '@mightybyte/rnw.utils.tooltip-auto-show';
import { textStyles } from '../../constants/textStyles';
import { COLORS } from '../../constants/colors';
import { TOOL_TIP_AUTO_SHOW_COLLECTION } from '../../typesAndInterfaces/typesAndInterfaces';
import { MB_utilHooks } from '@mightybyte/rnw.utils.util-hooks';

interface ToolTipHandlerProps {
    toolTip: TOOL_TIP_AUTO_SHOW_COLLECTION,
    children?: ReactNode,
    wrapperStyle?: StyleProp<ViewStyle>,
    childWrapperStyle?: StyleProp<ViewStyle>,
    placement?: 'top' | 'bottom' | 'left' | 'right' | 'center',
    rightButtonTitle?: string,
    leftButtonTitle?: string,
    disabled?: boolean,
    delay?: number,
}

const TOOL_TIP_TEXT_COLLECTION = {
    [TOOL_TIP_AUTO_SHOW_COLLECTION.Level]: 'Difficulty levels for games can be changed here.',
    [TOOL_TIP_AUTO_SHOW_COLLECTION.IntroGame]: 'Multiple choice challenges for all levels. For each video, choose from 9 possible answers to improve ASL comprehension.',
    [TOOL_TIP_AUTO_SHOW_COLLECTION.MatchingItemsGame]: 'A twist on ASL Match (3 x 3). Choose your answer from a 4 x 2 response-grid then spin the wheel to set the correct number.',
};

const ToolTipHandler = ({ toolTip, children, wrapperStyle, childWrapperStyle, placement, disabled, delay = 400 }: ToolTipHandlerProps) => {
    const {
        toolTipAutoShowStates,
        markToolTipAsSeen,
        autoShowQueue,
        addToAutoShowQueue,
    } = useToolTipAutoShow<TOOL_TIP_AUTO_SHOW_COLLECTION>();

    const [isDelayEnded, setDelayAsEnded] = MB_utilHooks.useBool(delay > 0 ? false : true);

    useEffect(() => {
        if (delay > 0) {
            const timeOutRef = setTimeout(() => setDelayAsEnded(), delay);
            return () => clearTimeout(timeOutRef);
        }
    }, [delay, setDelayAsEnded]);

    if (!toolTipAutoShowStates[toolTip].hasShown) {
        addToAutoShowQueue(toolTip);
    }

    const onMarkToolTipAutoShowAsSeen = () => {
        markToolTipAsSeen(toolTip);
    };

    const onSkip = () => {
        markToolTipAsSeen(autoShowQueue);
    };

    const hasShown = disabled ? true : (autoShowQueue?.[0] === toolTip && isDelayEnded ? toolTipAutoShowStates[toolTip].hasShown : true);
    const skip = autoShowQueue.length > 1 ? onSkip : undefined;

    return (
        <MB_ToolTipHover
            overrideIsVisible={hasShown ? undefined : true}
            placement={placement}
            backgroundColor={hasShown ? 'transparent' : undefined}
            onRightButtonPressed={hasShown ? undefined : onMarkToolTipAutoShowAsSeen}
            wrapperStyle={[{ marginHorizontal: 8, alignSelf: 'center' }, wrapperStyle]}
            toolTipElement={<Text style={styles.toolTipText}>{TOOL_TIP_TEXT_COLLECTION[toolTip]}</Text>}
            children={children}
            childWrapperStyle={childWrapperStyle}
            rightButtonTitle={autoShowQueue.length > 1 ? 'Next' : undefined}
            leftButtonTitle={skip ? 'skip' : undefined}
            leftButtonStyle={{ paddingVertical: 0 }}
            leftButtonTextStyle={{ color: COLORS.white, lineHeight: undefined }}
            toolTipElementWrapperStyle={styles.toolTipElementWrapperStyle}
            onLeftButtonPressed={skip}
            rightButtonTextStyle={{ lineHeight: undefined }}
            rightButtonStyle={styles.rightButton}
        />
    );
};

export { ToolTipHandler };

const styles = StyleSheet.create({
    toolTipText: mbTextStyles([textStyles.smallerText, {
        textAlign: 'left',
        fontWeight: '400',
    }]),
    toolTipHeader: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 5,
    },
    toolTipElement: {
        alignSelf: 'stretch',
        marginStart: 12,
        marginEnd: 10,
    },
    toolTipHeaderText: mbTextStyles([textStyles.smallerText, {
        fontSize: 13,
        marginEnd: 7,
        fontWeight: '700',
    }]),
    close: {
        position: 'absolute',
        top: 0,
        right: 0,
        height: 30,
        width: 30,
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
    },
    rightButton: {
        backgroundColor: 'transparent',
        paddingVertical: 0,
    },
    toolTipElementWrapperStyle: {
        backgroundColor: COLORS.buttonPurple,
        width: 218,
        marginLeft: -20,
    },
});
