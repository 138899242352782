import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { COLORS } from '../../../constants/colors';
import { textStyles } from '../../../constants/textStyles';
import { DifficultySelector } from '../../helperComponents/DifficultySelector';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { GAME_TYPE, TOOL_TIP_AUTO_SHOW_COLLECTION } from '../../../typesAndInterfaces/typesAndInterfaces';
import { utils } from '../../../utils/utils';
import { ToolTipHandler } from '../../helperComponents/ToolTipHandler';
import Feather from 'react-native-vector-icons/Feather';
import { useGetCurrentUserData } from '../../../hooks/userHooks';

const HeaderWithDifficultySelector = ({ gameType }: { gameType: GAME_TYPE }) => {

    const { data: currentUserData } = useGetCurrentUserData();


    if (isMobileApp) {
        return (
            <View style={styles.container}>
                <Text numberOfLines={1} style={styles.titleText}>Start Playing!</Text>
                <DifficultySelector />
            </View>
        );
    }

    const tooltip = gameType === GAME_TYPE.IntroGame ? TOOL_TIP_AUTO_SHOW_COLLECTION.IntroGame : gameType === GAME_TYPE.MatchingItemsGame ? TOOL_TIP_AUTO_SHOW_COLLECTION.MatchingItemsGame : undefined;

    return (
        <View>
            <View style={styles.titleContainer}>
                <Text style={styles.title}>{utils.gameTypeToString(gameType)}</Text>
                {tooltip &&
                    <ToolTipHandler toolTip={tooltip} placement="bottom">
                        <Feather name="info" color={COLORS.buttonPurple} size={20} />
                    </ToolTipHandler>
                }
            </View>
            <Text style={styles.subTitle}>Select a category to start playing.</Text>
            <Text style={styles.level}>Level: <Text style={{ color: utils.getDifficultyTextColor(currentUserData?.difficulty) }}>{utils.difficultyToText(currentUserData?.difficulty)}</Text></Text>
        </View>
    );
};

export { HeaderWithDifficultySelector };

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginVertical: 24,
    },
    titleText: mbTextStyles([
        textStyles.largestText, {
            fontWeight: '700',
            marginEnd: 10,
            textAlign: 'left',
        },
    ]),
    levelText: mbTextStyles([
        textStyles.smallerText, {
            color: COLORS.textLightBlue,
        },
    ]),
    titleContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    title: mbTextStyles([textStyles.largeText, {
        fontSize: 26,
        fontWeight: '700',
    }]),
    subTitle: mbTextStyles([textStyles.smallText, {
        fontWeight: '500',
        marginTop: 8,
    }]),
    level: mbTextStyles([textStyles.smallText, {
        fontSize: 13,
        fontWeight: '500',
        marginTop: 22,
    }]),
});
