import { MB_Button } from '@mightybyte/rnw.components.button';
import { mbHidePopUp, mbShowPopUp } from '@mightybyte/rnw.components.pop-up';
import { RouteProp, useIsFocused, useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React, { useCallback, useMemo } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import { COLORS } from '../../../../constants/colors';
import { DEFAULT_TABLE_MAX_ROWS, STRING_CONSTANTS } from '../../../../constants/constants';
import { tableStyles } from '../../../../constants/tableStyles';
import { textStyles } from '../../../../constants/textStyles';
import { useDeleteCategory, useGetCategories } from '../../../../hooks/adminHooks';
import { MB_PageIndicator } from '@mightybyte/rnw.components.page-indicator';
import { AdminCategoriesParamList } from '../../../../navigations/AdminNavigator/GamesCategories/GamesCategoriesNavigatorStack';
import { Category, GAME_TYPE } from '../../../../typesAndInterfaces/typesAndInterfaces';
import { ComponentWrapper } from '../../../helperComponents/componentWrapper/ComponentWrapper';
import { LiveStatusBadge } from '../../../helperComponents/LiveStatusBadge';
import { MB_Table } from '@mightybyte/rnw.components.table';
import { CategoriesProps } from '../../../../typesAndInterfaces/componentProps';


const NewCategoryButton = () => {
    const navigation = useNavigation<NativeStackNavigationProp<AdminCategoriesParamList>>();
    const { gameType } = useRoute<RouteProp<AdminCategoriesParamList, 'Categories'>>().params;

    return (
        <MB_Button
            title="New category"
            leftElement={<Feather name="plus" color={COLORS.white} size={14} />}
            textStyle={textStyles.smallText}
            style={[styles.newCategoryButton]}
            onPress={() => navigation.navigate('ConstructCategory', { categoryId: undefined, gameType })}
        />
    );
};

const header = [
    { content: 'No', conatinerStyle: { height: 85, minWidth: 100, maxWidth: 100, paddingLeft: 22 } },
    { content: 'Category', conatinerStyle: { height: 85, minWidth: 280, maxWidth: 280 } },
    { content: 'Status', conatinerStyle: { height: 85, minWidth: 196, maxWidth: 196 } },
    { content: 'Game Questions', conatinerStyle: { height: 85, minWidth: 180 } },
    { content: 'Payment State', conatinerStyle: { height: 85, minWidth: 180 } },
    { content: <NewCategoryButton />, conatinerStyle: { height: 85, minWidth: 218, maxWidth: 218 } },
];

const headerForExtraCategory = [
    { content: 'No', conatinerStyle: { height: 85, minWidth: 100, maxWidth: 100, paddingLeft: 22 } },
    { content: 'Category', conatinerStyle: { height: 85, minWidth: 280, maxWidth: 280 } },
    { content: 'Status', conatinerStyle: { height: 85, minWidth: 196, maxWidth: 196 } },
    { content: 'Game Videos', conatinerStyle: { height: 85, minWidth: 180 } },
    { content: <NewCategoryButton />, conatinerStyle: { height: 85, minWidth: 218, maxWidth: 218 } },
];

const Categories = ({ route, navigation }: CategoriesProps) => {

    const page = Number(route.params?.page || 1);

    const isFocused = useIsFocused();
    const { gameType } = useRoute<RouteProp<AdminCategoriesParamList, 'Categories'>>().params;

    const navigateToPage = useCallback((newPage: number) => {
        navigation.setParams({ gameType: route.params.gameType, page: newPage });
        // navigation.navigate('Categories', { gameType: route.params.gameType, page: newPage });
    }, [navigation, route.params.gameType]);

    const { data: categories, ...getCategoriesOptions } = useGetCategories({
        totalItemsPerPage: DEFAULT_TABLE_MAX_ROWS,
        pageNum: page,
        gameType,
        queryOptions: {
            enabled: page !== undefined && page > 0 && isFocused,
            refetchOnWindowFocus: false,
            keepPreviousData: true,
        },
    });
    const { mutate: deleteCategory } = useDeleteCategory();

    const onDeleteCategory = useCallback((category: Category) => {
        mbShowPopUp({
            title: 'Delete Category',
            message: `Are you sure you want to delete “${category.name}”? Deleting this category will erase all of its questions.`,
            buttonText: 'Delete category',
            buttonAction: () => {
                return new Promise((resolve, reject) => {
                    deleteCategory({ categoryId: category._id }, {
                        onSuccess: () => {
                            if (categories?.items.length === 1) {
                                navigateToPage(Math.max(1, page - 1));
                            }
                            resolve();
                            mbHidePopUp();
                        },
                        onError: (error) => {
                            console.error('Error when deleting category: ', error);
                            reject(STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN);
                        },
                    });
                });
            },
            buttonStyle: {
                container: { backgroundColor: COLORS.errorColor },
            },
            containerStyle: {
                width: 454,
            },
            secondaryButtonText: 'Cancel',
            secondaryButtonStyle: {
                text: { color: COLORS.buttonPurple },
                container: { backgroundColor: COLORS.white },
            },
        });
    }, [categories?.items.length, deleteCategory, page, navigateToPage]);

    const data = useMemo(() => {
        return categories?.items?.map((item, index) => {
            const itemIndex = (page - 1) * DEFAULT_TABLE_MAX_ROWS + index + 1;
            const name = item.name;
            const status = <LiveStatusBadge isEnabled={item.isEnabled} />;
            const numberOfQuestions = item.numberOfQuestions;
            const paymentStatus = item.isFree === true ? 'Free' : 'Not Free';
            const actions = (
                <View style={styles.actions}>
                    <Feather name="edit"
                        color={COLORS.buttonPurple}
                        style={{ marginEnd: 18 }}
                        size={20}
                        onPress={() => navigation.navigate('ConstructCategory', { categoryId: item._id, gameType })}
                    />
                    <Feather
                        name="trash"
                        color={COLORS.deleteColor}
                        size={20}
                        onPress={() => onDeleteCategory(item)}
                    />
                </View>
            );
            if (gameType === GAME_TYPE.Extras) {
                return [itemIndex, name, status, numberOfQuestions, actions];
            } else {
                return [itemIndex, name, status, numberOfQuestions, paymentStatus, actions];
            }
        });
    }, [categories?.items, gameType, navigation, onDeleteCategory, page]);

    const keyExtractor = useCallback((index) => categories?.items[index]?._id ?? index.toString(), [categories?.items]);

    return (
        <ComponentWrapper
            containerStyle={styles.container}
            disableLinearGradient
            wrapInScrollView
            hideWebHeader
        >
            <View style={styles.pageHeader}>
                <Text style={styles.pageTitle}>{gameType === GAME_TYPE.Extras ? 'Extras' : 'Categories'}</Text>
                <MB_Button
                    title="Reorder"
                    textStyle={textStyles.smallText}
                    style={[styles.newCategoryButton]}
                    onPress={() => navigation.navigate('Reorder', { gameType })}
                />
            </View>
            <View>
                <MB_Table
                    header={gameType === GAME_TYPE.Extras ? headerForExtraCategory : header}
                    headerStyle={tableStyles.tableHeader}
                    data={data}
                    style={tableStyles.tableStyle}
                    rowStyle={tableStyles.rowStyle}
                    keyExtractor={keyExtractor}
                    loading={categories ? (getCategoriesOptions.isFetching && getCategoriesOptions.isPreviousData) : getCategoriesOptions.isFetching}
                />
                <MB_PageIndicator
                    current={page}
                    pages={Math.ceil((categories?.totalItems ?? 0) / DEFAULT_TABLE_MAX_ROWS)}
                    style={tableStyles.pageIndicatorStyle}
                    onChange={navigateToPage}
                />
            </View>
        </ComponentWrapper>
    );
};

export { Categories };

const styles = StyleSheet.create({
    container: {
        minWidth: 1000,
    },
    pageHeader: {
        marginTop: 40,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    pageTitle: StyleSheet.flatten([textStyles.largestText, {
        fontSize: 36,
        color: COLORS.textLightPurple,
        textAlign: 'left',
    }]),
    newCategoryButton: {
        backgroundColor: COLORS.buttonPurple,
        width: 186,
        height: 38,
        borderRadius: 10,
    },
    actions: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        width: '100%',
        paddingRight: 32,
    },
});
