import React, { useImperativeHandle, useMemo, useRef } from 'react';
import { View, StyleSheet, ScrollView, Text, TouchableOpacity } from 'react-native';
import { COLORS } from '../../../constants/colors';
import { Header } from '../Header/Header';
import { ComponentWrapperProps } from './ComponentWrapperUtils';
import LoginBackground from '../../../resources/svgComponents/LoginBackground';
import Feather from 'react-native-vector-icons/Feather';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../constants/textStyles';
import GoPremiumBanner from '../GoPremiumBanner';

const ComponentWrapper = (props: ComponentWrapperProps) => {
    const { disableLinearGradient = false } = props;
    const scrollRef = useRef<ScrollView>(null);

    useImperativeHandle(props.MB_Ref, () => ({
        scrollTo: ({ x, y, animated }) => {
            if (!scrollRef.current) {
                console.warn('Warning: ScrollRef was not set. Was wrapInScrollView set to false?');
                return;
            }
            scrollRef.current.scrollTo({ x, y, animated });
        },
    }));

    const content = useMemo(() => {
        return (
            <>
                {!disableLinearGradient &&
                    <View style={styles.loginBannerContainer}>
                        <LoginBackground />
                    </View>
                }

                <View style={[styles.innerContainer, props.innerContainerStyle]} onLayout={props.onInnerComponentLayout}>
                    {props.mountPremiumBanner &&
                        <GoPremiumBanner />
                    }
                    {!props.hideWebHeader &&
                        <Header style={[props.stickyHeader && styles.fixedHeader]}>
                            {props.onBackPress &&
                                <TouchableOpacity style={styles.back} onPress={props.onBackPress}>
                                    <Feather name="arrow-left" size={23} color={COLORS.white} />
                                    <Text style={styles.backText}>{props.backTitle ?? 'Back to lobby'}</Text>
                                </TouchableOpacity>
                            }
                            {props.headerChildren}
                        </Header>
                    }
                    {props.stickyHeader && <Header style={{ opacity: 0 }} />}

                    {props.children}
                </View>
            </>
        );
    }, [disableLinearGradient, props]);

    if (props.wrapInScrollView) {
        return (
            <ScrollView
                ref={scrollRef}
                onLayout={props.onLayout}
                onScroll={props.onScroll}
                scrollEventThrottle={64}
                contentContainerStyle={[styles.container, props.containerStyle]}
                keyboardShouldPersistTaps={props.keyboardShouldPersistTapsForScrollView}
            >
                {content}
            </ScrollView>
        );
    }

    return (
        <View style={[styles.container, props.containerStyle]} onLayout={props.onLayout}>
            {content}
        </View>
    );
};

export { ComponentWrapper };

const styles = StyleSheet.create({
    container: {
        minHeight: '100%',
        backgroundColor: COLORS.backgroundDarkPurple,
        justifyContent: 'space-between',
    },
    innerContainer: {
        maxWidth: 1680,
        paddingHorizontal: 54,
        alignSelf: 'center',
        width: '100%',
    },
    loginBannerContainer: {
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
    },
    back: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    backText: mbTextStyles([textStyles.smallText, {
        fontSize: 13,
        textAlign: 'left',
        marginLeft: 9,
    }]),
    fixedHeader: {
        position: 'fixed' as 'absolute',
        left: 54,
        right: 54,
        zIndex: 111,
    },
});
