
import React, { useCallback, useMemo } from 'react';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { MB_LinearGradient } from '@mightybyte/rnw.components.linear-gradient';
import { Text, View, FlatList, ListRenderItemInfo, StyleSheet, TouchableOpacity, Share } from 'react-native';
import { COLORS } from '../../../../constants/colors';
import { textStyles } from '../../../../constants/textStyles';
import { mbPlatformStyle, mbShadow, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { Leaderboard, Player, ROOM_STATUS, Room } from '../../../../typesAndInterfaces/typesAndInterfaces';
import { useGetCurrentUserData } from '../../../../hooks/userHooks';
import { useFinishRoomGame } from '../../../../hooks/roomHooks';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import Clipboard from '@react-native-clipboard/clipboard';
import { mbShowToast } from '@mightybyte/rnw.components.toast';
import { mbHidePopUp, mbShowPopUp } from '@mightybyte/rnw.components.pop-up';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';

const RoomWaiting = ({ room, leaderboard, players, onGoBack }: { room: Room, leaderboard: Leaderboard, players: Player[], onGoBack: () => void }) => {

    const { data: currentUserData } = useGetCurrentUserData();
    const { mutate: finishRoomGame, isLoading: isFinishRoomGameLoading } = useFinishRoomGame();

    const keyExtractor = useCallback((player: { userId: string }) => player.userId, []);

    const RenderPlayerComponent = useCallback(({ item, index }: ListRenderItemInfo<Player>) => {

        const isFinished = leaderboard.find(player => player.userId === item.userId) !== undefined;

        return (
            <View style={styles.playerTextContainer}>
                <Text style={styles.playerNumberText}>{index + 1}</Text>
                {item.userId === currentUserData?._id &&
                    <View style={styles.creatorContainer}>
                        <Text style={styles.creatorText}>You</Text>
                    </View>
                }
                <Text style={styles.playerNameText} numberOfLines={1}>{item.name}</Text>
                <View style={styles.scoreContainer}>
                    <Text style={styles.scoreText}>{isFinished ? 'Finished' : 'In Progress'}</Text>
                </View>
            </View>
        );
    }, [currentUserData?._id, leaderboard]);

    const members = useMemo(() => {
        const playersNotInLeadboard = players.filter(player => {
            if (player.userId === room.creatorUserId && !room.isCreatorParticipating) {
                return false;
            }
            return !leaderboard.find(leaderboardPlayer => leaderboardPlayer.userId === player.userId);
        });
        return leaderboard
            .map(({ userId, name }) => ({ userId, name }))
            .concat(playersNotInLeadboard);
    }, [leaderboard, players, room.creatorUserId, room.isCreatorParticipating]);

    const finishGame = useCallback(() => {
        if (currentUserData?._id === room.creatorUserId && room.status === ROOM_STATUS.IN_PROGRESS) {
            finishRoomGame({ roomId: room._id });
        } else if (currentUserData?._id !== room.creatorUserId) {
            mbShowPopUp({
                title: 'Quit Room',
                message: 'Are you sure want to quit the room?',
                buttonText: 'Yes',
                secondaryButtonText: 'No',
                buttonAction: () => {
                    mbHidePopUp();
                    onGoBack();
                },
            });
        }
    }, [currentUserData?._id, finishRoomGame, onGoBack, room._id, room.creatorUserId, room.status]);

    const onCopyCode = useCallback(() => {
        if (room) {
            Clipboard.setString(room.code);
            mbShowToast({ type: 'success', text1: 'Copied to clipboard', text2: '' });
        } else {
            mbShowToast({ type: 'error', text1: 'Failed to copy the code' });
        }
    }, [room]);

    const onShareCode = useCallback(() => {
        if (room) {
            Share.share({
                message: `Join my game room with code: ${room.code}`,
            });
        } else {
            mbShowToast({ type: 'error', text1: 'Failed to copy the code' });
        }
    }, [room]);

    return (
        <>
            <Text style={styles.codeNote}>{isMobileApp ? 'Copy or share room code:' : 'Copy room code'}</Text>
            <View style={styles.codeContainer}>
                <Text style={styles.code}>{room?.code}</Text>
                <TouchableOpacity
                    style={styles.codeButtons}
                    onPress={onCopyCode}
                >
                    <MaterialCommunityIcons name="content-copy" size={18} color={COLORS.white} />
                </TouchableOpacity>
                {isMobileApp &&
                    <TouchableOpacity
                        style={[styles.codeButtons, { marginStart: 10 }]}
                        onPress={onShareCode}
                    >
                        <MaterialCommunityIcons name="share-variant-outline" size={18} color={COLORS.white} />
                    </TouchableOpacity>
                }
            </View>
            <Text style={styles.playersState}>{leaderboard.length} / {members.length} Finished</Text>
            <View style={styles.playerContainer}>
                <MB_LinearGradient
                    colors={[COLORS.purpleTrasparent, COLORS.greenTrasparent]}
                    start={{ x: 0, y: 0 }}
                    end={{ x: 1, y: 0 }}
                    style={styles.playerTableHeaderContainer}
                >
                    <Text style={[styles.playerHeaderText, { paddingRight: 16 }]}>#</Text>
                    <Text style={[styles.playerHeaderText, { flex: 1 }]}>Player</Text>
                    <Text style={styles.playerCountHeaderText}>{members.length}</Text>
                </MB_LinearGradient>
                <FlatList
                    keyExtractor={keyExtractor}
                    data={members}
                    renderItem={RenderPlayerComponent}
                    style={styles.playerFlatList}
                />
            </View>
            <View style={styles.footer}>
                <Text style={textStyles.smallText}>
                    {currentUserData?._id === room?.creatorUserId ? 'wait for other players to finish then show the leaderboard' : 'waiting for the host to show the leaderboard'}
                </Text>
                <MB_Button
                    title={currentUserData?._id === room?.creatorUserId ? 'Show leaderboard' : 'Quit the game'}
                    style={styles.startGameButton}
                    textStyle={styles.startGameText}
                    loading={isFinishRoomGameLoading}
                    disabled={isFinishRoomGameLoading}
                    onPress={finishGame}
                />
            </View>
        </>
    );
};

export { RoomWaiting };

const styles = StyleSheet.create({
    codeNote: mbTextStyles([
        textStyles.smallerText,
    ]),
    codeContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 15,
        backgroundColor: '#5f487c61',
        alignSelf: 'center',
        paddingVertical: 10,
        paddingHorizontal: 15,
        borderRadius: 7,
    },
    header: mbTextStyles([
        textStyles.largerText,
    ]),
    code: mbTextStyles([textStyles.smallText, {
        marginRight: 10,
    }]),
    codeButtons: {
        borderRadius: 4,
    },
    gameModeSelectGameContainer: {
        marginTop: 16,
        borderColor: COLORS.backgroundPurple,
        borderWidth: 2,
        borderRadius: 27,
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        height: 128,
    },
    gameModeSelectedContainer: {
        overflow: 'visible',
    },
    gameModeButton: {
        backgroundColor: COLORS.backgroundPurple,
        borderRadius: 10,
    },
    selectGameButtonTextContainer: {
        backgroundColor: COLORS.buttonPurple,
        borderRadius: 10,
        width: 150,
    },
    selectGameButtonText: mbTextStyles([
        textStyles.normalText, {
            paddingVertical: 3,
            paddingHorizontal: 6,
        },
    ]),
    startGameButton: {
        alignSelf: 'stretch',
        backgroundColor: COLORS.buttonPurple,
        marginTop: 10,
    },
    startGameText: mbTextStyles([
        textStyles.smallText, {
        },
    ]),
    playerTableHeaderContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 8,
        borderTopStartRadius: 8,
        borderTopEndRadius: 8,
    },
    playerHeaderText: mbTextStyles([
        textStyles.normalText, {
            textAlign: 'left',
        },
    ]),
    playerCountHeaderText: mbTextStyles([
        textStyles.largestText, {
            fontSize: 16,
            textAlign: 'left',
            color: COLORS.buttonPurple,
        },
    ]),
    playerFlatList: {
        marginVertical: 12,
    },
    playerTextContainer: {
        flexDirection: 'row',
        marginVertical: 8,
    },
    playerNumberText: mbTextStyles([
        textStyles.normalText, {
            width: 20,
            marginStart: 4,
            marginEnd: 8,
        },
    ]),
    playerNameText: mbTextStyles([
        textStyles.normalText, {
            textAlign: 'left',
            flex: 1,
        },
    ]),
    creatorContainer: {
        backgroundColor: COLORS.shadowGreenTrasparent,
        borderRadius: 4,
        marginEnd: 8,
    },
    creatorText: mbTextStyles([
        textStyles.largestText, {
            fontSize: 14,
            color: COLORS.white,
            alignSelf: 'center',
            paddingHorizontal: 8,
            paddingVertical: 2,
        },
    ]),
    image: {
        position: 'absolute',
        top: -30,
        right: 5,
        width: 140,
        height: 90,
        overflow: 'visible',
    },
    gameModeText: mbTextStyles([
        textStyles.largeText, {
            textAlign: 'left',
        },
    ]),
    gameCardContainer: {
        marginTop: 32,
    },
    changeGameModeText: mbTextStyles([
        textStyles.normalText, {
        },
    ]),
    changeGameModeTextContainer: {
        paddingHorizontal: 8,
        paddingVertical: 4,
        width: 100,
        borderWidth: 2,
        borderColor: COLORS.white,
        borderRadius: 8,
        ...mbShadow({
            color: COLORS.white,
            offsetWidth: 0,
            offsetHeight: 0,
            opacity: 1,
            radius: 8,
            elevation: 1,
        }),
    },
    selectedGameText: mbTextStyles([
        textStyles.largeText, {
            textAlign: 'left',
            marginTop: 12,
        },
    ]),
    gradientContainer: {
        padding: 24,
        borderRadius: 27,
        marginTop: 8,
    },
    playersState: mbTextStyles([textStyles.smallText, {
        marginTop: 58,
    }]),
    playerContainer: {
        marginTop: 10,
        ...mbPlatformStyle({
            web: {
                height: 200,
            },
            mobile: {
                flex: 1,
            },
        }),
    },
    loadingText: mbTextStyles([
        textStyles.smallText, {
            color: COLORS.white,
            marginBottom: 32,
        },
    ]),
    loadingIndicator: {
        marginTop: 32,
    },
    footer: {
        paddingVertical: 16,
        marginHorizontal: -16,
        paddingHorizontal: 30,
        ...mbPlatformStyle({
            mobile: {
                backgroundColor: COLORS.backgroundDarkPurple,
                ...mbShadow({
                    color: COLORS.black,
                    offsetWidth: 0,
                    offsetHeight: -16,
                    opacity: 0.25,
                    radius: 4,
                    elevation: 4,
                }),
            },
        }),
    },
    scoreContainer: {
        backgroundColor: COLORS.textLightPurple,
        borderRadius: 4,
        marginLeft: 'auto',
    },
    scoreText: mbTextStyles([
        textStyles.normalText, {
            fontWeight: 'bold',
            paddingVertical: 2,
            paddingHorizontal: 8,
            color: COLORS.buttonPurple,
        },
    ]),
});
