import { MB_Button } from '@mightybyte/rnw.components.button';
import { mbHideToast } from '@mightybyte/rnw.components.toast';
import { MB_ORIENTATION_LOCK, MB_orientationLocker } from '@mightybyte/rnw.utils.orientation-locker';
import { MB_utilHooks } from '@mightybyte/rnw.utils.util-hooks';
import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Linking, Platform, StyleSheet, Text, View } from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { COLORS } from '../../../constants/colors';
import { QUESTION_QUERY_KEYS, USER_QUERY_KEYS } from '../../../constants/constants';
import { useGetIncompleteQuestions, useGetQuestion, useGetQuestionsIds } from '../../../hooks/questionHooks';
import { useGetCurrentUserData, useQuestionCompleted, useResetQuestionCompletion, useToggleBanner } from '../../../hooks/userHooks';
import { GameScreenProps } from '../../../typesAndInterfaces/componentProps';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import { GameControls } from './GameControls';
import { GameHeader } from './GameHeader';
import { CongratulationsModal, ExitGameModal, ReConnectModal } from './GameScreenModals';
import { textStyles } from '../../../constants/textStyles';
import { PacmanIndicator } from '@mightybyte/rnw.components.activity-indicators';
import { GAME_TYPE, ROOM_STATUS, SCORE_TYPE } from '../../../typesAndInterfaces/typesAndInterfaces';
import { envs } from '../../../../env';
import { Analytics } from '../../../utils/Analytics';
import { useAddPlayerScoreToLeaderboard, useGetRoom, useUpdateRoom } from '../../../hooks/roomHooks';
import { CONNECTION_STATE, useRTCommContext } from '../../../context/RTCommContextProvider';
import { LoadingPage } from '../../helperComponents/LoadingPage';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RoomsNavigatorParamList } from '../../../navigations/RoomsNavigator/RoomsNavigator';
import { mbShowPopUp } from '@mightybyte/rnw.components.pop-up';
import { utilHooks } from '../../../hooks/utilHooks';
import { mbPlatformStyle } from '@mightybyte/rnw.utils.style-utils';
import { isMobile, isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { VideoPlayer } from '../../helperComponents/VideoPLayer';
import { MB_VideoPlayerPlayStatus, MB_VideoPlayerRef } from '@mightybyte/rnw.components.video-player';

const useGetAllIncompleteQuestions = (categoryIds: string[], enabled: boolean, isRoom: boolean) => {
    if (categoryIds.length <= 0) {
        throw new Error('categoryIds must not be empty');
    }

    const incompleteQuestionsData = useGetIncompleteQuestions({ categoryId: categoryIds[0], queryOptions: { enabled: enabled && !isRoom, staleTime: 0, cacheTime: 0 } });
    const questionIds = useGetQuestionsIds({ categoryIds, queryOptions: { enabled: enabled && isRoom, staleTime: Infinity, cacheTime: Infinity } });

    return isRoom ? questionIds : incompleteQuestionsData;
};

const GameScreen = ({ route, navigation }: GameScreenProps) => {
    const { categoryIds, index, categoryParams, roomParams } = route.params;
    const { data: currentUserData } = useGetCurrentUserData();
    const [disableAnswers, setDisableAnswers] = useState(false);
    const [isPortrait, setIsPortrait] = useState<boolean>(true);
    const videoRef = useRef<MB_VideoPlayerRef>(null);
    const screenTransitionRef = useRef<NodeJS.Timeout | undefined>();
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const scoreIntervalRef = useRef<NodeJS.Timeout>();

    // const bodyPartsControlsBaseRef = useRef<BodyPartsControlsBaseRef>(null);

    const { isConnected, connectionStatus, isInternetReachable, connect, unsubscribe, isSubscribedToChannel, isGameEnded } = useRTCommContext(roomParams !== undefined);
    const { data: room, refetch } = useGetRoom({ roomId: roomParams?.roomId!, queryOptions: { enabled: roomParams?.roomId !== undefined } });

    const [score, setScore] = useState(100);

    const [isExitModalVisible, showExitModal, hideExitModal] = MB_utilHooks.useBool();
    const [isCongratsModalVisible, showCongratsModal, hideCongratsModal] = MB_utilHooks.useBool();

    const { mutate: markQuestionComplete } = useQuestionCompleted();
    const { mutate: restartQuestionCompletion } = useResetQuestionCompletion();
    const { mutate: addPlayerScoreToLeaderboard } = useAddPlayerScoreToLeaderboard();
    const { mutate: updateRoom, isLoading: isUpdatingGame } = useUpdateRoom();
    const { mutate: toggleBanner } = useToggleBanner();

    utilHooks.useAndroidBackButton(showExitModal);

    // Creates an interval timer that reduces the score every second.
    // TODO: Room: How does this affect stuff like going full screen, rotating screen
    useEffect(() => {
        if (roomParams && isVideoPlaying && isConnected) {
            if (roomParams.scoringType === SCORE_TYPE.TIME || roomParams.scoringType === SCORE_TYPE.CHALLENGE_MODE) {
                scoreIntervalRef.current = setInterval(() => setScore(currentScore => Math.max(currentScore - 1, 0)), 1000);
                return () => clearInterval(scoreIntervalRef.current);
            }
        }
    }, [isConnected, isVideoPlaying, roomParams]);

    /**
     * We are using this boolean to see if we already initiated the delayed transition to next game screen.
     * As of now, this is used to block the exit game modal since exiting the game screen was causing an issue
     * when we exit the game screen but still have the timer running and then the transition occurs.
     * Alternatively, we could disable the timer when doing the transition.
     */
    const [transitioningToNextQuestion, setTransitioningToNextQuestion] = useState(false);

    const queryClient = useQueryClient();

    const { data: incompleteQuestionData } = useGetAllIncompleteQuestions(categoryIds, index === 0, roomParams !== undefined);

    const { data: question, isLoading: isLoadingQuestion } = useGetQuestion({ questionId: incompleteQuestionData?.questionsIds[index], queryOptions: { enabled: incompleteQuestionData?.questionsIds[index] !== undefined } });

    // Note: We use this to load the next question in background so that we can avoid showing loading screen when we move to the next question.
    const { } = useGetQuestion({ questionId: incompleteQuestionData?.questionsIds[index + 1], queryOptions: { enabled: question !== undefined && incompleteQuestionData?.questionsIds[index + 1] !== undefined } });

    const numberOfCompletedQuestions = !incompleteQuestionData ? undefined : incompleteQuestionData.totalNumberOfQuestions - incompleteQuestionData.questionsIds.length + index;

    const channelName = `presence-${roomParams?.roomId}`;

    const exitRoom = useCallback(async (showGameFinishMessage?: boolean) => {
        const roomNavigation = navigation as NativeStackNavigationProp<RoomsNavigatorParamList>;
        hideExitModal();
        clearInterval(scoreIntervalRef.current);
        if (roomParams && currentUserData?._id === room?.creatorUserId && isInternetReachable) {
            updateRoom({ roomId: roomParams.roomId, isCreatorParticipating: false }, {
                onSuccess: () => {
                    roomNavigation.replace('RoomEndGame', { roomId: roomParams.roomId });
                },
            });
        } else {
            roomNavigation.replace('RoomCreateOrJoin');
            unsubscribe(channelName, { disconnect: true });
            if (showGameFinishMessage) {
                mbShowPopUp({
                    title: 'Game Finished',
                    message: 'The creator of the room end the session',
                });
            }
        }
    }, [channelName, currentUserData?._id, hideExitModal, isInternetReachable, navigation, room?.creatorUserId, roomParams, unsubscribe, updateRoom]);

    useEffect(() => {
        if (room?.status === ROOM_STATUS.ENDED || isGameEnded) {
            exitRoom(true);
        }
    }, [exitRoom, isGameEnded, room?.status]);

    useEffect(() => {
        if (roomParams && isConnected) {
            // refetch the room in case the creator finish the game before the player rejoin
            refetch();
        }
    }, [isConnected, refetch, roomParams]);

    const invalidateAndRemoveQueries = useCallback(() => {
        queryClient.invalidateQueries([USER_QUERY_KEYS.getCategoryForHomePage]);
        queryClient.invalidateQueries([QUESTION_QUERY_KEYS.getIncompleteQuestions, categoryIds[0]]);
    }, [categoryIds, queryClient]);

    // Unlocks orientation, sets orientation listeners and then restores orientation when unmounted.
    useEffect(() => {
        async function getInitialOrientation() {
            const initialOrientation = await MB_orientationLocker.getOrientationAync();
            setIsPortrait(Platform.OS === 'web' ? true : initialOrientation.isPortrait);
        }
        getInitialOrientation();

        const orientationListener = MB_orientationLocker.addOrientationChangeListener(({ orientationInfo }) => {
            const isOrientationPortrait = orientationInfo.isPortrait;
            setIsPortrait(isOrientationPortrait);
        });

        return () => {
            if (screenTransitionRef.current) {
                clearTimeout(screenTransitionRef.current);
            }
            MB_orientationLocker.removeOrientationChangeListener(orientationListener);
        };
    }, []);

    const exitGameScreen = useCallback(() => {
        invalidateAndRemoveQueries();
        if (navigation.canGoBack()) {
            navigation.pop();
        } else {
            navigation.reset({ index: 0, routes: [{ name: 'HomeNavigator' }] });
        }
    }, [invalidateAndRemoveQueries, navigation]);

    const restartGame = useCallback(() => {
        if (roomParams) {
            return;
        }

        hideCongratsModal();
        restartQuestionCompletion(categoryIds[0], {
            onError: () => {
                // Failed so just go home
                exitGameScreen();
            },
            onSuccess: () => {
                if (categoryParams?.categoryTitle) {
                    Analytics.logEvent('gameStarted', {
                        gameCategoryName: categoryParams.categoryTitle,
                        gameCategoryId: categoryIds[0],
                        userId: currentUserData?._id,
                        gameType: question?.gameType,
                    });
                }

                invalidateAndRemoveQueries();
                screenTransitionRef.current = setTimeout(() => {
                    // TODO: For some reason, this gives issues on iOS if removeQueries is called before the timeout.
                    queryClient.removeQueries([QUESTION_QUERY_KEYS.getQuestion]);
                    clearInterval(scoreIntervalRef.current);
                    navigation.replace('GameScreen', { categoryIds, index: 0, categoryParams });
                }, 300);
            },
        });
    }, [categoryIds, categoryParams, currentUserData?._id, exitGameScreen, hideCongratsModal, invalidateAndRemoveQueries, navigation, queryClient, question?.gameType, restartQuestionCompletion, roomParams]);

    const onCorrectAnswerPressed = useCallback(() => {
        if (!incompleteQuestionData) {
            return;
        }

        setDisableAnswers(true);
        if (question && !roomParams) {
            markQuestionComplete(question._id);
        }

        if (numberOfCompletedQuestions === incompleteQuestionData.totalNumberOfQuestions - 1) {
            // We are done with all the games so we can go back to home page.

            if (roomParams) {
                const totalScore = roomParams.score + score;
                const roomId = roomParams.roomId;
                clearInterval(scoreIntervalRef.current);
                addPlayerScoreToLeaderboard({ roomId, score: totalScore }, {
                    onSuccess: () => {
                        const roomNavigation = navigation as NativeStackNavigationProp<RoomsNavigatorParamList>;
                        roomNavigation.replace('RoomEndGame', { roomId });
                    },
                });
            } else {
                setTimeout(() => {
                    mbHideToast();
                    toggleBanner(true);
                    setTimeout(() => {
                        showCongratsModal();
                    }, Platform.OS === 'ios' ? 400 : 0);
                }, 2000);
            }
        } else {
            setTransitioningToNextQuestion(true);

            // TODO: Make this transition nicer
            screenTransitionRef.current = setTimeout(() => {
                mbHideToast();
                if (roomParams) {
                    roomParams.score += score;
                }
                clearInterval(scoreIntervalRef.current);
                navigation.replace('GameScreen', {
                    categoryIds,
                    index: index + 1,
                    categoryParams,
                    roomParams,
                });
            }, 2000);
        }
    }, [addPlayerScoreToLeaderboard, categoryIds, categoryParams, incompleteQuestionData, index, markQuestionComplete, navigation, numberOfCompletedQuestions, question, roomParams, score, showCongratsModal, toggleBanner]);

    const onHideModal = useCallback(({ shouldExit, modalType }: { shouldExit: boolean, modalType: 'congrats' | 'exit' }) => {
        if (modalType === 'congrats') {
            hideCongratsModal();
            exitGameScreen();
        } else if (modalType === 'exit') {
            hideExitModal();
            if (shouldExit) {
                if (roomParams) {
                    exitRoom();
                } else {
                    exitGameScreen();
                }
            }
        }
    }, [exitGameScreen, exitRoom, hideCongratsModal, hideExitModal, roomParams]);

    const onAnswerPressed = useCallback((isCorrect: boolean) => {
        if (!isCorrect) {
            if (roomParams?.scoringType === SCORE_TYPE.ACCURACY || roomParams?.scoringType === SCORE_TYPE.CHALLENGE_MODE) {
                setScore(currentScore => Math.max(currentScore - 25, 0));
            }
        } else {
            onCorrectAnswerPressed();
        }
    }, [onCorrectAnswerPressed, roomParams?.scoringType]);

    /* TODO: This was used to restrict the height of the game screens to match the web window but things started getting way too small
     const mainContentWidth = useMemo(() => {
        if (isMobile) {
            return undefined;
        } else {
            // Width of the main content is calculated based on the available height and aspect ratio depending on the game type.

            // available height is approximatley height of scren - 100(headeer) - 100(padding bottom)
            // aspect ratio differs from game type to game type;

            const aspectRatio = question?.gameType === GAME_TYPE.IntroGame ? 0.58 : question?.gameType === GAME_TYPE.MatchingItemsGame ? 0.6 : 0.38;

            return { width: (dims.height - 200) * aspectRatio };
        }
    }, [dims.height, question?.gameType]);
    */
    const mainContentWidth = isMobile ? undefined : { width: 461 };

    if (incompleteQuestionData === undefined || question === undefined || isLoadingQuestion || isPortrait === undefined || isUpdatingGame) {
        return (
            <LoadingPage
                giveUpAction={roomParams ? exitRoom : exitGameScreen}
                debugVariables={{
                    incompleteQuestionData: incompleteQuestionData === undefined,
                    question: question === undefined,
                    isLoadingQuestion,
                    isPortrait,
                    isUpdatingGame,
                }} />
        );
    }

    return (
        <ComponentWrapper
            wrapInScrollView={false}
            containerStyle={styles.container}
            onBackPress={showExitModal}
            backTitle="Exit Game"
            autoDismissKeyboard
        >
            <View style={[styles.content, mainContentWidth]}>
                {categoryParams?.categoryTitle &&
                    <CongratulationsModal
                        isVisible={isCongratsModalVisible}
                        resetAction={restartGame}
                        homeAction={() => onHideModal({ shouldExit: true, modalType: 'congrats' })}
                        categoryName={categoryParams.categoryTitle}
                    />
                }

                {((roomParams && isConnected) || !roomParams) &&
                    <ExitGameModal
                        isVisible={isExitModalVisible && !transitioningToNextQuestion}
                        hideModal={onHideModal}
                        roomMode={roomParams !== undefined}
                    />
                }

                {roomParams &&
                    <ReConnectModal
                        isVisible={isSubscribedToChannel(channelName) && !isConnected && room?.status === ROOM_STATUS.IN_PROGRESS}
                        onGiveUp={exitRoom}
                        onReconnect={connect}
                        isConnecting={connectionStatus === CONNECTION_STATE.CONNECTING || connectionStatus === CONNECTION_STATE.RECONNECTING}
                    />
                }

                <GameHeader showExitModal={isMobileApp ? showExitModal : undefined} completedQuestions={numberOfCompletedQuestions} totalQuestions={incompleteQuestionData.totalNumberOfQuestions} />

                <View style={isPortrait ? styles.screenContent : styles.screenContentLandscape}>

                    {envs.FLAVOR !== 'prod' &&
                        <View style={{ position: 'absolute', backgroundColor: '#ffffff3a', padding: 4, zIndex: 1, right: 0 }}>
                            <Text style={[textStyles.smallerText, { textAlign: 'left', fontWeight: '800' }]}>DEBUG</Text>
                            <Text style={[textStyles.smallerText, { textAlign: 'left' }]}>img: {question.gameType === GAME_TYPE.Geography ? question.geographyLocation : question.answers.findIndex((answr) => answr.isCorrect === true) + 1}</Text>
                            {question.gameType === GAME_TYPE.MatchingItemsGame &&
                                <Text style={[textStyles.smallerText, { textAlign: 'left' }]}>val: {question.gameType === GAME_TYPE.MatchingItemsGame ? question.matchingItemsGameValue : ''}</Text>
                            }
                            {roomParams &&
                                <>
                                    <Text style={[textStyles.smallerText, { textAlign: 'left', fontWeight: '800' }]}>Total Score: {roomParams.score}</Text>
                                    <Text style={[textStyles.smallerText, { textAlign: 'left', fontWeight: '800' }]}>Score: {score}</Text>
                                </>
                            }
                        </View>
                    }

                    <View style={isPortrait ? styles.videoContainer : styles.videoContainerLandscape}>
                        <VideoPlayer.Cached
                            containerStyle={isPortrait ? styles.videoPlayer : styles.videoPlayerLandscape}
                            sourceUrl={question.video.url}
                            shouldPlay={!isCongratsModalVisible && !disableAnswers}
                            isLooping
                            isMuted
                            showControls={false}
                            MB_Ref={videoRef}
                            onPlayStatusChanged={(status) => {
                                if (status === MB_VideoPlayerPlayStatus.playing) {
                                    setIsVideoPlaying(true);
                                }
                            }}
                            onFullscreenUpdate={(isFullScreen: boolean) => {
                                // video stop on ios when exit full screen
                                if (!isFullScreen && Platform.OS === 'ios') {
                                    setTimeout(() => videoRef.current?.play(), 100);
                                }
                            }}
                            mobileOrientationRestore={MB_ORIENTATION_LOCK.ALL}
                            loadingComponent={
                                <View>
                                    <PacmanIndicator color={'white'} style={{ flex: undefined }} size={64} />
                                    <Text style={styles.loadingText}>Loading</Text>
                                </View>
                            }
                        />
                        <MB_Button
                            style={[styles.fullScreenButton, !isPortrait && { left: 14, right: undefined }]}
                            onPress={() => videoRef.current?.presentFullscreenPlayer()}
                            rightElement={
                                <MaterialCommunityIcons name="arrow-expand" size={20} color={COLORS.white} />
                            }
                        />

                        {!roomParams && categoryParams?.tutorialUrl !== undefined && categoryParams?.tutorialUrl !== '' &&
                            <MB_Button
                                style={[styles.questionVideoButton, !isPortrait && { left: 14, right: undefined }]}
                                onPress={() => Linking.openURL(categoryParams.tutorialUrl as string)}
                                rightElement={
                                    <AntDesign name="question" size={20} color={COLORS.white} />
                                }
                            />
                        }
                    </View>

                    <GameControls
                        question={question}
                        disableAnswers={disableAnswers || !isVideoPlaying}
                        showExitModal={showExitModal}
                        onAnswerPressed={onAnswerPressed}
                        isPortrait={isPortrait}
                    //bodyPartsControlsBaseRef={bodyPartsControlsBaseRef}
                    />

                </View>
            </View>
        </ComponentWrapper>
    );
};

export { GameScreen };

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 0,
    },
    content: {
        padding: 4,
        ...mbPlatformStyle({
            web: {
                alignSelf: 'center',
            },
            mobile: {
                flex: 1,
            },
        }),
    },
    screenContent: {
        flexDirection: 'column',
        flex: 1,
    },
    screenContentLandscape: {
        flexDirection: 'row',
        flex: 1,
    },
    fullScreenButton: {
        position: 'absolute',
        bottom: 28,
        right: 14,
        backgroundColor: COLORS.grayButton,
        borderRadius: 11,
        width: 28,
        height: 28,
        paddingHorizontal: 0,
        paddingVertical: 0,
    },
    questionVideoButton: {
        position: 'absolute',
        top: 28,
        left: 14,
        backgroundColor: COLORS.buttonPurple,
        borderRadius: 300,
        width: 28,
        height: 28,
        paddingHorizontal: 0,
        paddingVertical: 0,
    },
    videoPlayer: {
        width: '100%',
        backgroundColor: COLORS.black,
        flex: 1,
        aspectRatio: isMobileApp ? undefined : 16 / 9,
    },
    videoPlayerLandscape: {
        height: '100%',
        width: '100%',
        backgroundColor: COLORS.black,
    },
    videoContainer: {
        borderBottomWidth: 1,
        borderBottomColor: COLORS.backgroundPurple,
        paddingVertical: 24,
        flex: 1,
    },
    videoContainerLandscape: {
        width: '100%',
        alignItems: 'center',
        backgroundColor: COLORS.black,
    },
    loadingText: StyleSheet.flatten([
        textStyles.largeText, {
            marginTop: 10,
        }]),
});
