import React from 'react';
import { MB_TextInput, MB_TextInputInputType } from '@mightybyte/rnw.components.text-input';
import { View, Text, TouchableHighlight, StyleSheet, StyleProp, ViewStyle } from 'react-native';
import { textStyles } from '../../../../../../constants/textStyles';
import { GAME_SUBTYPE } from '../../../../../../typesAndInterfaces/typesAndInterfaces';
import { COLORS } from '../../../../../../constants/colors';
import { MB_DatePicker } from '@mightybyte/rnw.components.date-picker';
import { format as formatDate, parse as parseDate } from 'date-fns';

const PriceMatch = ({ gameSubType, gameSubTypeValue, onChangeGameSubType, onGameSubTypeValueChange, style }: { gameSubType: GAME_SUBTYPE, gameSubTypeValue: string, onChangeGameSubType?: (gameSubType: GAME_SUBTYPE) => void, onGameSubTypeValueChange?: (value: string) => void, style?: StyleProp<ViewStyle> }) => {

    const isSubTypeActive = (newGameSubType: GAME_SUBTYPE) => newGameSubType === gameSubType;

    return (
        <View style={[style]}>
            <Text style={[textStyles.smallText, { textAlign: 'left' }]}>Question Type*</Text>
            <View style={styles.content}>
                <View style={styles.subBtns}>
                    <TouchableHighlight style={[styles.subTypeBtn, isSubTypeActive(GAME_SUBTYPE.price) && styles.subTypeBtnActive]} onPress={() => onChangeGameSubType?.(GAME_SUBTYPE.price)}>
                        <Text style={textStyles.smallerText}>Price</Text>
                    </TouchableHighlight>
                    <View style={styles.line} />
                    <TouchableHighlight style={[styles.subTypeBtn, isSubTypeActive(GAME_SUBTYPE.number) && styles.subTypeBtnActive]} onPress={() => onChangeGameSubType?.(GAME_SUBTYPE.number)}>
                        <Text style={textStyles.smallerText}>Number</Text>
                    </TouchableHighlight>
                    <View style={styles.line} />
                    <TouchableHighlight style={[styles.subTypeBtn, isSubTypeActive(GAME_SUBTYPE.date) && styles.subTypeBtnActive]} onPress={() => onChangeGameSubType?.(GAME_SUBTYPE.date)}>
                        <Text style={textStyles.smallerText}>Date</Text>
                    </TouchableHighlight>
                    <View style={styles.line} />
                    <TouchableHighlight style={[styles.subTypeBtn, isSubTypeActive(GAME_SUBTYPE.year) && styles.subTypeBtnActive]} onPress={() => onChangeGameSubType?.(GAME_SUBTYPE.year)}>
                        <Text style={textStyles.smallerText}>Year</Text>
                    </TouchableHighlight>
                </View>
                <View style={styles.inputs}>
                    {gameSubType === GAME_SUBTYPE.number &&
                        <MB_TextInput
                            placeholder="Enter Number"
                            style={styles.numberInput}
                            textInputStyle={textStyles.smallText}
                            inputType={MB_TextInputInputType.numbers}
                            value={gameSubTypeValue}
                            onChangeText={onGameSubTypeValueChange}
                            maxLength={2}
                        />
                    }
                    {gameSubType === GAME_SUBTYPE.price &&
                        <View style={styles.price}>
                            <Text style={[textStyles.largeText, styles.dot]}>$</Text>
                            <MB_TextInput
                                placeholder="Enter Dollars"
                                style={[styles.priceDollarInput]}
                                textInputStyle={textStyles.smallText}
                                inputType={MB_TextInputInputType.numbers}
                                value={gameSubTypeValue.split('.')[0] ?? ''}
                                maxLength={2}
                                onChangeText={(value) => onGameSubTypeValueChange?.(value + '.' + (gameSubTypeValue.split('.')[1] ?? ''))}
                            />
                            <Text style={[textStyles.largeText, styles.dot]}>.</Text>
                            <MB_TextInput
                                placeholder="Enter Centes"
                                style={[styles.priceCenteInput]}
                                textInputStyle={textStyles.smallText}
                                inputType={MB_TextInputInputType.numbers}
                                value={gameSubTypeValue.split('.')[1] ?? ''}
                                maxLength={2}
                                onChangeText={(value) => onGameSubTypeValueChange?.((gameSubTypeValue.split('.')[0] ?? '') + '.' + value)}
                            />
                        </View>
                    }
                    {gameSubType === GAME_SUBTYPE.date &&
                        <MB_DatePicker
                            style={{ width: 200 }}
                            initialDate={gameSubTypeValue === '' ? undefined : parseDate(gameSubTypeValue, 'dd/MM/yyyy', new Date())}
                            onDateChanged={(date) => onGameSubTypeValueChange?.(date ? formatDate(date, 'dd/MM/yyyy') : '')}
                        />
                    }
                    {gameSubType === GAME_SUBTYPE.year &&
                        <MB_TextInput
                            placeholder="Enter year"
                            style={styles.yearInput}
                            textInputStyle={textStyles.smallText}
                            inputType={MB_TextInputInputType.numbers}
                            value={gameSubTypeValue}
                            onChangeText={onGameSubTypeValueChange}
                            maxLength={4}
                        />
                    }
                </View>
            </View>
        </View>
    );
};

export { PriceMatch };

const styles = StyleSheet.create({
    content: {
        borderRadius: 10,
        borderWidth: 1,
        borderColor: '#412366',
        overflow: 'hidden',
        marginTop: 5,
    },
    line: {
        height: '100%',
        width: 1,
        backgroundColor: '#412366',
    },
    inputs: {
        paddingVertical: 15,
        alignItems: 'center',
    },
    numberInput: {
        width: 110,
        backgroundColor: COLORS.backgroundPurple,
        height: 34,
        borderRadius: 10,
        paddingHorizontal: 0,
        paddingEnd: 0,
    },
    yearInput: {
        width: 87,
        backgroundColor: COLORS.backgroundPurple,
        height: 34,
        borderRadius: 10,
        paddingHorizontal: 0,
        paddingEnd: 0,
    },
    priceDollarInput: {
        width: 110,
        backgroundColor: COLORS.backgroundPurple,
        height: 34,
        borderRadius: 10,
        paddingHorizontal: 0,
        paddingEnd: 0,
    },
    priceCenteInput: {
        width: 100,
        backgroundColor: COLORS.backgroundPurple,
        height: 34,
        borderRadius: 10,
        paddingHorizontal: 0,
        paddingEnd: 0,
    },
    dot: {
        marginHorizontal: 8,
        fontSize: 18,
    },
    subBtns: {
        flexDirection: 'row',
        borderColor: COLORS.borderColor,
        borderBottomColor: '#412366',
        borderBottomWidth: 1,
    },
    subTypeBtn: {
        flex: 1,
        paddingVertical: 14,
    },
    subTypeBtnActive: {
        backgroundColor: COLORS.backgroundPurple,
    },
    price: {
        flexDirection: 'row',
        alignItems: 'center',
    },
});
