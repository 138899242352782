import React from 'react';
import { BottomTabNavigationOptions } from '@react-navigation/bottom-tabs';
import { RouteProp } from '@react-navigation/core';
import { Platform, StyleSheet, Text, View } from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import { COLORS } from '../../constants/colors';
import { MB_Blur, MB_BLUR_TINT } from '@mightybyte/rnw.components.blur';
import { mbPlatformStyle, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../constants/textStyles';
import { HomeNavigatorParamList } from './HomeNavigator';
import { RoomsIcon } from '../../resources/svgComponents/RoomsIcon';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs';
import { ParamListBase } from '@react-navigation/core';
import { ExtrasIcon } from '../../resources/svgComponents/ExtrasIcon';

const getIcon = (routeName: keyof HomeNavigatorParamList, color: string) => {
    if (routeName === 'MyAccount') {
        return <Feather name="user" size={18} color={color} style={styles.icon} />;
    } else if (routeName === 'GameNavigator') {
        return <Feather name="home" size={18} color={color} style={styles.icon} />;
    } else if (routeName === 'ExtrasNavigator') {
        return <ExtrasIcon size={20} style={styles.icon} />;
    } else {
        return <RoomsIcon color={color} containerStyle={styles.icon} />;
    }
};

const tabTitles: { [key in keyof HomeNavigatorParamList]: string } = {
    GameNavigator: 'Home',
    RoomsNavigator: 'Rooms',
    ExtrasNavigator: 'Extras',
    MyAccount: 'My Account',
};

const homeNavigatorOptions = (props: { route: RouteProp<HomeNavigatorParamList, keyof HomeNavigatorParamList>; navigation: any; }): BottomTabNavigationOptions => {
    const navOptions: BottomTabNavigationOptions = {
        tabBarShowLabel: false,
        tabBarLabelStyle: styles.tabBarLabel,
        tabBarActiveTintColor: COLORS.bottomBarIconActive,
        tabBarInactiveTintColor: COLORS.bottomBarIconActive,
        tabBarStyle: styles.tabStyle,
        tabBarBackground: Platform.OS === 'android' ? undefined : () => (
            <MB_Blur tint={MB_BLUR_TINT.dark} style={styles.blurStyle} androidUseRealBlur androidOverlayColor="transparent" />
        ),
        tabBarIcon: ({ focused, color }) => {
            return (
                <View style={[styles.tabIconContainer, { backgroundColor: focused ? COLORS.bottomBarActiveBackground : 'transparent' }]}>
                    {getIcon(props.route.name, color)}
                    <Text style={[styles.tabBarLabel, { color }]}>{tabTitles[props.route.name]}</Text>
                </View>
            );
        },
    };

    return navOptions;
};

const HomeNavigatorOptions = {
    showBottomTab: <T extends ParamListBase>(navigation: NativeStackNavigationProp<T>, parent?: boolean) => {
        const homeNavigation = (parent ? navigation.getParent() : navigation) as BottomTabNavigationProp<HomeNavigatorParamList>;
        homeNavigation?.setOptions({ tabBarStyle: styles.tabStyle });
    },
    hidBottomTab: <T extends ParamListBase>(navigation: NativeStackNavigationProp<T>, parent?: boolean) => {
        const homeNavigation = (parent ? navigation.getParent() : navigation) as BottomTabNavigationProp<HomeNavigatorParamList>;
        homeNavigation?.setOptions({ tabBarStyle: { display: 'none' } });
    },
};

export { homeNavigatorOptions, HomeNavigatorOptions };

const styles = StyleSheet.create({
    tabStyle: {
        position: 'absolute',
        paddingBottom: 0,
        height: 60,
        ...mbPlatformStyle({
            android: {
                backgroundColor: '#412366',
            },
        }),
    },
    tabBarLabel: mbTextStyles([
        textStyles.tinyText, {
            fontWeight: '600',
            fontSize: 12,
        },
    ]),
    tabIconContainer: {
        height: 50,
        width: 85,
        justifyContent: 'center',
        borderRadius: 7,
    },
    blurStyle: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: COLORS.bottombBarBlurBackgroundColor,
    },
    icon: {
        alignSelf: 'center',
    },
});
