import React from 'react';
import { FlatList, ListRenderItemInfo, StyleProp, StyleSheet, ViewStyle } from 'react-native';
import { GAME_TYPE } from '../../typesAndInterfaces/typesAndInterfaces';
import { GameCard, GameCardProps } from './GameCard';
import { mbPlatformStyle } from '@mightybyte/rnw.utils.style-utils';


const RenderSelectedGameCard = ({ item }: { item: GameCardProps }) => {
    return (
        <GameCard
            gameType={item.gameType}
            onGameSelected={item.onGameSelected}
            // renderEmpty={item.gameType === GAME_TYPE.Geography }
        />
    );
};


interface GamesViewProps {
    onGameSelected: (gameType: GAME_TYPE) => void,
    style?: StyleProp<ViewStyle>,
    renderSelectedGameCard?: (params: ListRenderItemInfo<GameCardProps>) => React.ReactElement | null,
}

const GamesView = ({ onGameSelected, renderSelectedGameCard, style }: GamesViewProps) => {

    const gameTypes: GameCardProps[] = [
        {
            gameType: GAME_TYPE.IntroGame,
            onGameSelected: onGameSelected,
        },
        {
            gameType: GAME_TYPE.MatchingItemsGame,
            onGameSelected: onGameSelected,
        },
        {
            gameType: GAME_TYPE.BodyParts,
            onGameSelected: onGameSelected,
        },
        {
            gameType: GAME_TYPE.Geography,
            onGameSelected: onGameSelected,
        },
    ];

    return (
        <FlatList
            scrollEnabled={true}
            numColumns={2}
            data={gameTypes}
            renderItem={renderSelectedGameCard ?? RenderSelectedGameCard}
            style={[styles.container, style]}
            contentContainerStyle={styles.contentContainer}
        />
    );
};

export { GamesView };

const styles = StyleSheet.create({
    container: {
        marginTop: 30,
    },
    contentContainer: {
        ...mbPlatformStyle({
            web: {
                alignItems: 'center',
            },
        }),
    },
});

