import { mbApplyTransparency } from '@mightybyte/rnw.utils.style-utils';

const COLORS = {
    dimViolet: '#B8ABBA',
    plum: '#9A4C95',
    lighterGray: '#BAC0CA',

    darkPurple: '#1D1A31',
    englishViolet: '#4D2D52',
    darkPurpleLight: '#2B203B',
    cyan: '#45FFFF',
    AllportsBlue: '#03769e',
    gray: '#A3A3A3',
    green: '#44D600',
    borderColor: '#6E7A91',
    englishVioletAlt: '#5D3E65',
    gradientLightPurple: '#9726EF',
    gradientDarkPurple: '#6525C3',

    bottomBarIconActive: '#ffffff',
    bottomBarIconInactive: '#C2C2C2',
    bottomBarActiveBackground: '#1D183B',
    bottombBarBlurBackgroundColor: mbApplyTransparency('#412366', 0.75),

    /////// New colors based on designs
    white: '#ffffff',
    black: '#000000',

    errorColor: '#FF563C',

    buttonPurple: '#A430FF',
    buttonWhite: '#FFFFFF',

    textLightPurple: '#E9CEFF',
    textWhite: '#FFFFFF',
    textPurple: '#A430FF',
    textPink: '#FAC0FF',
    textLightGreen: '#A2E7F1',

    cardPurple: '#782ABE',

    darkBackground: '#000000',
    backgroundDarkPurple: '#1D183B',
    backgroundPurple: '#412366',

    correctAnswerColor: '#3E9D0C',
    incorrectAnswerColor: '#E53232',

    grayButton: '#55516C',

    deleteColor: '#E53232',

    yellowButton: '#FEC43A',

    wheelColorDark: '#2D0F53',
    wheelColorLight: '#412366',

    textLightBlue: '#C7DBFF',
    toastTextColor: '#FFDDDD',

    gameTypeCardBackground: '#4C336B',

    // Difficulty colors
    beginnerBackgroundColor: '#D043DC',
    beginnerTextColor: '#FAC0FF',
    intermediateBackgroundColor: '#2762BB',
    intermediateTextColor: '#C7DBFF',
    advancedBackgroundColor: '#158080',
    advancedTextColor: '#A2E7F1',

    // status
    statusLiveBg: '#B3F3BA',
    statusLiveText: '#104E16',
    statusOfflineBg: '#d75656',
    statusOfflineText: '#5f0303',

    // rooms
    backgroundLightGreen: '#00A1A1',
    backgroundDarkGreen: '#066161',
    shadowGreen: '#1DB3B3',
    blueTransparent: mbApplyTransparency('#2762BB', 0.25),
    shadowGreenTrasparent: mbApplyTransparency('#1DB3B3', 0.25),
    purpleTrasparent: mbApplyTransparency('#A430FF', 0.25),
    greenTrasparent: mbApplyTransparency('#01A2A2', 0.25),

    bodyPartsBackground: '#B8B8B8',
};

export { COLORS };
