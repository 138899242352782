import { mbShowPopUp } from '@mightybyte/rnw.components.pop-up';
import { MB_TextInput } from '@mightybyte/rnw.components.text-input';
import { mbShowToast } from '@mightybyte/rnw.components.toast';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { COLORS } from '../../../../../constants/colors';
import { SERVER_ERROR_CODES, STRING_CONSTANTS } from '../../../../../constants/constants';
import { textStyles } from '../../../../../constants/textStyles';
import { useCreateQuestion, useEditQuestion, useToggleQuestion } from '../../../../../hooks/adminHooks';
import { useGetQuestion } from '../../../../../hooks/questionHooks';
import { ConstructQuestionProps } from '../../../../../typesAndInterfaces/componentProps';
import { Answer, AnswerRequest, GAME_SUBTYPE, GAME_TYPE, Video } from '../../../../../typesAndInterfaces/typesAndInterfaces';
import { utils } from '../../../../../utils/utils';
import { ComponentWrapper } from '../../../../helperComponents/componentWrapper/ComponentWrapper';
import { LoadingPage } from '../../../../helperComponents/LoadingPage';
import { AdminCreateOrEditHeader } from '../Utils/Header';
import { MediaChanger } from '../Utils/MediaChanger';
import { PriceMatch } from './Utils/PriceMatch';
import { RegularAnswers } from './Utils/RegularAnswers';
import { BODY_PARTS } from '../../../Game/bodyPartsControls/bodyPartsConstants';
import { BodyPartsControlsBase } from '../../../Game/bodyPartsControls/BodyPartsControls';
import { GEOGRAPHY_LOCATION, GEOGRAPHY_MAPS } from '../../../Game/geographyControls/geographyConstants';
import { GeographyAnswer } from './Utils/GeographyAnswer';

export type AnswerData = Partial<Omit<Answer, '_id'>>;

const buildAnswers = ({ answers, gameType, isCopy }: { answers: Answer[], gameType: GAME_TYPE, isCopy: boolean }) => {
    if (gameType === GAME_TYPE.BodyParts) {
        return [];
    }
    const length = gameType === GAME_TYPE.IntroGame ? 9 : 8;
    if (isCopy) {
        const noAnswers = answers.map((answer) => ({ ...answer, isCorrect: false }));
        return [
            ...noAnswers,
            ...Array(length - answers.length).fill(undefined),
        ];
    } else {
        return [
            ...answers,
            ...Array(length - answers.length).fill(undefined),
        ];
    }
};

const ConstructQuestion = ({ route, navigation }: ConstructQuestionProps) => {
    const { categoryId, questionId, isCopyingQuestion, gameType } = route.params;

    const [questionVideo, setQuestionVideo] = useState<Video>();
    const [questionAnswers, setQuestionAnswers] = useState<(AnswerData | undefined)[]>(buildAnswers({ answers: [], gameType, isCopy: false }));
    const [questionName, setQuestionName] = useState('');
    const [signerName, setSignerName] = useState('');
    const [gameSubType, setGameSubType] = useState<GAME_SUBTYPE>(GAME_SUBTYPE.number);
    const [gameSubTypeValue, setGameSubTypeValue] = useState('');
    const [bodyPart, setBodyPart] = useState<BODY_PARTS>();
    const [geographyMap, setGeographyMap] = useState<GEOGRAPHY_MAPS>();
    const [geographyLocation, setGeographyLocation] = useState<GEOGRAPHY_LOCATION>();


    const isCopy = isCopyingQuestion === true;
    const isEditing = questionId !== undefined && !isCopy;

    const { mutate: createQuestion, isLoading: isCreatingQuestionLoading } = useCreateQuestion();
    const { data: question, isLoading: isLoadingQuestion } = useGetQuestion({ questionId: questionId as string, queryOptions: { enabled: isEditing || isCopy } });
    const { mutate: editQuestion, isLoading: isEditingQuestionLoading } = useEditQuestion();
    const { mutate: toggleQuestion, isLoading: isToggleQuestionLoading } = useToggleQuestion();

    // Setting state variables from category data
    useEffect(() => {
        if (question) {
            setQuestionName(question.name);
            !isCopy && setQuestionVideo(question.video);
            if (utils.hasMultiAnswers(question)) {
                setQuestionAnswers(buildAnswers({ answers: question.answers, gameType, isCopy }));
            }
            setSignerName(question.signerName);
            if (question.gameType === GAME_TYPE.MatchingItemsGame) {
                setGameSubType(question.gameSubType);
                setGameSubTypeValue(question.matchingItemsGameValue);
            } else if (question.gameType === GAME_TYPE.BodyParts) {
                setBodyPart(question.bodyPart);
            } else if (question.gameType === GAME_TYPE.Geography) {
                setGeographyMap(question.geographyMap);
                setGeographyLocation(question.geographyLocation);
            }
        }
    }, [question, isCopy, gameType]);

    const onGoBack = useCallback(() => {
        // TODO: Might be worth fixing, we were having issues determining if we should go back or reset. The issue is that canGoBack always returns true and takes back to users tab
        const navState = navigation.getState();
        if (navigation.canGoBack() && navState.routes[Math.max(navState.index - 1, 0)].name === 'ConstructCategory') {
            navigation.goBack();
        } else {
            navigation.reset({ index: 0, routes: [{ name: 'ConstructCategory', params: { categoryId, gameType } }] });
        }
    }, [categoryId, gameType, navigation]);

    const onError = useCallback((error) => {
        const isKnownError = error?.errorCode === SERVER_ERROR_CODES.LIMIT_FILE_SIZE ||
            error?.errorCode === SERVER_ERROR_CODES.QUESTION_NOT_READY_ENABLE ||
            error?.errorCode === SERVER_ERROR_CODES.DUPLICATE_ANSWER_NAME;
        // in case of error we should wait untill the progress pop-up hide
        setTimeout(() => {
            mbShowPopUp({
                title: STRING_CONSTANTS.ERROR,
                message: isKnownError ? error.message : STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN,
            });
        }, isEditing ? 0 : 500);
    }, [isEditing]);

    const onSave = useCallback(async () => {
        const additionalParams: Record<string, any> = {}; // TODO: This type should not be any ideally.
        if (gameType === GAME_TYPE.MatchingItemsGame) {
            additionalParams.gameSubType = gameSubType;
            additionalParams.matchingItemsGameValue = gameSubTypeValue;
        } else if (gameType === GAME_TYPE.BodyParts) {
            additionalParams.bodyPart = bodyPart;
        } else if (gameType === GAME_TYPE.Geography) {
            additionalParams.geographyMap = geographyMap;
            additionalParams.geographyLocation = geographyLocation;
        }

        const answers = (questionAnswers as Omit<Answer, '_id'>[])
            .filter(Boolean) // Filters out all null or undefined values
            .map<AnswerRequest>(answer => ({
                name: answer.name,
                poolImageId: answer.image._id,
                isCorrect: answer.isCorrect ?? false,
            }));

        if (isEditing && !isCopy) {
            editQuestion({
                questionId,
                name: questionName,
                signerName,
                poolVideoId: questionVideo?._id,
                answers,
                ...additionalParams,
            }, {
                onSuccess: () => {
                    mbShowToast({
                        text1: 'Success',
                        text2: 'Question Edited successfully',
                    });
                },
                onError,
            });
        } else if (questionName && categoryId && signerName && questionVideo?._id) {
            createQuestion({
                categoryId,
                name: questionName,
                signerName,
                poolVideoId: questionVideo._id,
                answers,
                ...additionalParams,
            }, {
                onSuccess: (newQuestion) => {
                    navigation.replace('ConstructQuestion', { categoryId: categoryId, questionId: newQuestion._id, gameType: route.params.gameType });
                },
                onError,
                onSettled: () => {
                    setTimeout(() => {
                        mbShowToast({
                            text1: 'Success',
                            text2: 'Question Created successfully',
                        });
                    }, 500);
                },
            });
        }
    }, [gameType, questionAnswers, isEditing, isCopy, questionName, categoryId, signerName, questionVideo?._id, gameSubType, gameSubTypeValue, bodyPart, geographyMap, geographyLocation, editQuestion, questionId, onError, createQuestion, navigation, route.params.gameType]);

    const onAnswerChange = useCallback((index: number, newAnswer?: AnswerData) => {
        setQuestionAnswers(oldAnswers => {
            if (!newAnswer) {
                oldAnswers.splice(index, 1);
                oldAnswers.push(undefined);
            } else {
                if (newAnswer.isCorrect) {
                    oldAnswers.forEach(oldAnswer => oldAnswer && (oldAnswer.isCorrect = false));
                }
                oldAnswers[index] = newAnswer;
            }
            return [...oldAnswers];
        });
    }, []);

    const isSaveButtonDisabled = useMemo(() => {
        // checks for both case editing and creating
        // check if questionName not set
        if (questionName === '' || signerName === '' || !questionVideo) {
            return true;
        } else if (gameType === GAME_TYPE.MatchingItemsGame && (utils.validateSubtypeValue(gameSubType, gameSubTypeValue) === undefined)) {
            return true;
        } else if (gameType === GAME_TYPE.BodyParts && !bodyPart) {
            return true;
        } else if (gameType === GAME_TYPE.Geography && (!geographyMap || !geographyLocation)) {
            return true;
        }

        // check if the answers are valid to be sent to backend
        const areValidAnswers = questionAnswers.every(answer => !answer || (answer.image && answer.name && answer.name !== ''));
        if (!areValidAnswers) {
            return true;
        }

        if (isEditing && !isCopy) {
            // checks if the question is changed
            const isQuestionNameEqual = questionName === question?.name;
            const isSignerNameEqual = signerName === question?.signerName;
            const isQuestionVideoEqual = questionVideo._id === question?.video._id;
            const isGameSubTypeEqual = question?.gameType === GAME_TYPE.MatchingItemsGame ? question.gameSubType === gameSubType : true;
            const isGameSubTypeValueEqual = question?.gameType === GAME_TYPE.MatchingItemsGame ? question.matchingItemsGameValue === gameSubTypeValue : true;
            const isBodyPartEqual = question?.gameType === GAME_TYPE.BodyParts ? question.bodyPart === bodyPart : true;
            const isGeographyMapEqual = question?.gameType === GAME_TYPE.Geography ? question.geographyMap === geographyMap : true;
            const isGeographyLocationEqual = question?.gameType === GAME_TYPE.Geography ? question.geographyLocation === geographyLocation : true;

            const isQuestionAnswersEqual = utils.hasMultiAnswers(question) ? utils.areAnswersEqual(question?.answers ?? [], questionAnswers.filter(Boolean) as AnswerData[]) : true;
            if (isQuestionNameEqual && isSignerNameEqual && isQuestionAnswersEqual && isGameSubTypeEqual && isGameSubTypeValueEqual && isQuestionVideoEqual && isBodyPartEqual && isGeographyMapEqual && isGeographyLocationEqual) {
                return true;
            }

            // check if the question edit api call is in progress
            if (isEditingQuestionLoading) {
                return true;
            }
        } else {
            if (!questionVideo || isCreatingQuestionLoading) {
                return true;
            }
        }

        return false;
    }, [questionName, signerName, questionVideo, gameType, gameSubType, gameSubTypeValue, bodyPart, geographyMap, geographyLocation, questionAnswers, isEditing, isCopy, question, isEditingQuestionLoading, isCreatingQuestionLoading]);

    const onToggleQuestion = useCallback((isEnabled: boolean) => {
        if (isEditing) {
            toggleQuestion({ questionId, isEnabled }, { onError });
        }
    }, [isEditing, onError, questionId, toggleQuestion]);

    const onChangeGameSubType = useCallback((newGameSubType: GAME_SUBTYPE) => {
        setGameSubType(newGameSubType);
        if (isEditing && question?.gameType === GAME_TYPE.MatchingItemsGame && question.gameSubType === newGameSubType) {
            setGameSubTypeValue(question.matchingItemsGameValue);
        } else {
            setGameSubTypeValue('');
        }
    }, [isEditing, question]);

    if ((isEditing || isCopy) && isLoadingQuestion) {
        return <LoadingPage />;
    }

    return (
        <ComponentWrapper
            containerStyle={styles.container}
            disableLinearGradient
            wrapInScrollView
            hideWebHeader
        >
            <AdminCreateOrEditHeader
                title={isEditing ? 'Edit Game Question' : 'Create New Game Question'}
                isEnabled={question?.isEnabled}
                disableToggle={isToggleQuestionLoading}
                onIsEnabledChange={onToggleQuestion}
                rightButtonTitle={isEditing ? 'Save' : 'Create'}
                rightButtonDisabled={isSaveButtonDisabled}
                rightButtonIsLoading={isCreatingQuestionLoading || isEditingQuestionLoading}
                onRightButtonPress={onSave}
                onBackPress={onGoBack}
            />

            <View style={styles.body}>
                <View style={styles.leftSide}>
                    <MB_TextInput
                        title="Game question name*"
                        placeholder="Enter the game question name"
                        style={[styles.textInput, questionName === '' && styles.errorBorder]}
                        value={questionName}
                        onChangeText={setQuestionName}
                        maxLength={144}
                    />
                    <View style={styles.leftSideSection}>
                        <MB_TextInput
                            title="Game signer name*"
                            placeholder="Enter the game question signer name"
                            style={[styles.textInput, signerName === '' && styles.errorBorder]}
                            value={signerName}
                            onChangeText={setSignerName}
                        />
                    </View>
                    {gameType === GAME_TYPE.MatchingItemsGame &&
                        <PriceMatch
                            gameSubType={gameSubType}
                            gameSubTypeValue={gameSubTypeValue}
                            onChangeGameSubType={onChangeGameSubType}
                            onGameSubTypeValueChange={setGameSubTypeValue}
                            style={styles.leftSideSection}
                        />
                    }
                    <View style={styles.leftSideSection}>
                        <Text style={[textStyles.smallText, { textAlign: 'left' }]}>Video*</Text>
                        <MediaChanger
                            type="video"
                            source={questionVideo}
                            onMediaChange={setQuestionVideo}
                            disabled={isCreatingQuestionLoading}
                        />
                    </View>
                </View>
                <View style={styles.rightSide}>
                    <View style={[styles.row, { alignItems: 'center' }]}>
                        <Text style={[textStyles.largeText, { color: COLORS.textLightPurple, marginEnd: 19 }]}>Answers</Text>
                        <FontAwesome name="star" color={COLORS.textLightPurple} size={20} />
                        <Text style={[textStyles.smallText, { color: COLORS.textLightPurple, marginStart: 10, marginEnd: 'auto' }]}>Select the correct answer</Text>
                        {(gameType === GAME_TYPE.IntroGame || gameType === GAME_TYPE.MatchingItemsGame) ?
                            <Text style={[textStyles.smallText, { color: COLORS.textLightPurple }]}>{questionAnswers.filter(Boolean).length}/{gameType === GAME_TYPE.IntroGame ? '9' : '8'} answers</Text>
                            :
                            <Text style={[textStyles.smallText, { color: COLORS.textLightPurple }]}>{bodyPart}</Text>
                        }
                    </View>
                    {(gameType === GAME_TYPE.IntroGame || gameType === GAME_TYPE.MatchingItemsGame) &&
                        <RegularAnswers
                            answers={questionAnswers}
                            onAnswerChange={onAnswerChange}
                        />
                    }
                    {gameType === GAME_TYPE.BodyParts &&
                        <BodyPartsControlsBase
                            onBodyPartChange={setBodyPart}
                            initialSelected={bodyPart}
                            isForAdminPanel
                        />
                    }
                    {gameType === GAME_TYPE.Geography &&
                        <GeographyAnswer
                            map={geographyMap}
                            location={geographyLocation}
                            onMapChange={setGeographyMap}
                            onLocationChange={setGeographyLocation}
                        />
                    }
                </View>
            </View>
        </ComponentWrapper>
    );
};

export { ConstructQuestion };

const styles = StyleSheet.create({
    container: {
        minWidth: 1100,
    },
    newGame: {
        width: 230,
        height: 38,
        borderRadius: 10,
    },
    row: {
        flexDirection: 'row',
    },
    body: {
        marginTop: 18,
        flexDirection: 'row',
        marginBottom: 60,
    },
    leftSide: {
        marginEnd: 48,
        width: 319,
    },
    rightSide: {
        flex: 1,
    },
    textInput: {
        width: '100%',
        backgroundColor: COLORS.backgroundPurple,
    },
    errorBorder: {
        borderColor: COLORS.errorColor,
    },
    leftSideSection: {
        marginTop: 33,
    },
    emptyAnswer: {
        flexDirection: 'row',
        height: 80,
        marginVertical: 16,
        borderRadius: 10,
        borderColor: COLORS.buttonPurple,
        borderWidth: 1,
        borderStyle: 'dashed',
        overflow: 'hidden',
    },
});
