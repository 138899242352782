import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

interface RoomsIconProps {
    color?: string,
    size?: number,
    containerStyle?: StyleProp<ViewStyle>,
}

const RoomsIcon = ({ color = '#fff', size = 18, containerStyle }: RoomsIconProps) => {
    return (
        <View style={containerStyle}>
            <Svg
                width={size}
                height={size}
                viewBox="0 0 18 18"
                fill="none"
            >
                <Path
                    d="M8.25 2.118v-.205l-.195.062-6.3 2.006-.105.033v9.465l.204-.08 6.3-2.449.096-.037V2.118zm1.695-.14l-.195-.061v8.996l.096.037 1.8.7.204.08V5.31l2.4.76v6.591l.096.037 1.8.7.204.08V4.013l-.105-.033-6.3-2.002zm-.89 10.275L9 12.232l-.054.02-4.63 1.799-.354.137.352.142 4.63 1.868.056.023.056-.023 4.63-1.866.351-.142-.353-.137-4.63-1.8zM.15 2.917L8.844.15h.3l8.706 2.767v11.352L9 17.839l-8.85-3.57V2.917zm3.6 6.773V6.07l2.4-.759v3.434l-2.4.945z"
                    fill={color}
                    strokeWidth={0.3}
                />
            </Svg>
        </View>
    );
};

export { RoomsIcon };
