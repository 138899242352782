import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MB_Modal, MB_ModalMobileFakeScreen } from '@mightybyte/rnw.components.modal';
import { StyleSheet, Text, View } from 'react-native';
import { COLORS } from '../../../../constants/colors';
import Feather from 'react-native-vector-icons/Feather';
import { GamesView } from '../../SelectGame/SelectGame';
import { CategoryForUser, DIFFICULTY, GAME_TYPE, RoomGame } from '../../../../typesAndInterfaces/typesAndInterfaces';
import { utils } from '../../../../utils/utils';
import { mbApplyTransparency, mbPlatformStyle, mbShadow, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../../constants/textStyles';
import { CategoriesView } from '../../../helperComponents/CategoriesView';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { RoomsCategoryCard, RoomsCategoryCardProps } from './RoomsCategoryCard';
import { DifficultySelector } from '../../../helperComponents/DifficultySelector';
import { GameCard, GameCardProps } from '../../../helperComponents/GameCard';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { mbGetMediumImage } from '@mightybyte/rnw.components.image';

interface SelectCategoriesModalProps {
    isVisible: boolean,
    gameType?: GAME_TYPE,
    onDismiss?: () => void,
    onRoomGameChange: (roomGame: RoomGame) => void
}

const SelectCategoriesModal = ({ isVisible, gameType: currentGameType, onDismiss, onRoomGameChange }: SelectCategoriesModalProps) => {

    const [gameType, setGameType] = useState<GAME_TYPE>();
    const [selectedCategories, setSelectedCategories] = useState(new Set<string>());
    const [difficulty, setDifficulty] = useState<DIFFICULTY>(DIFFICULTY.beginner);

    useEffect(() => {
        if (isVisible) {
            setGameType(undefined);
            setSelectedCategories(new Set());
        }
    }, [isVisible]);

    const goBackToFirstScreen = useCallback(() => {
        setGameType(undefined);
        setSelectedCategories(new Set());
    }, [setGameType, setSelectedCategories]);

    const RenderSelectedGameCard = useCallback(({ item }: { item: GameCardProps }) => {
        return (
            <GameCard
                gameType={item.gameType}
                onGameSelected={item.onGameSelected}
                style={[item.gameType === currentGameType && styles.currentGameCard]}
            />
        );
    }, [currentGameType]);

    const onSelectGame = useCallback(() => {
        if (gameType) {
            onRoomGameChange?.({ gameType, categories: [...selectedCategories.values()] });
            onDismiss?.();
        }
    }, [gameType, onDismiss, onRoomGameChange, selectedCategories]);

    const onEditSet = useCallback((categoryId: string) => {
        setSelectedCategories(oldSelectedCategories => {
            if (oldSelectedCategories.has(categoryId)) {
                oldSelectedCategories.delete(categoryId);
            } else {
                oldSelectedCategories.add(categoryId);
            }
            return new Set(oldSelectedCategories);
        });
    }, [setSelectedCategories]);

    const RenderCategoryComponent = useCallback((item: CategoryForUser | string, index: number, isLoading: boolean) => {
        const props: Partial<RoomsCategoryCardProps<boolean>> = {};
        if (!isLoading) {
            props.onPress = () => onEditSet((item as CategoryForUser)._id);
            props.isSelected = selectedCategories.has((item as CategoryForUser)._id);
            props.numberOfQuestions = (item as CategoryForUser).numberOfQuestions;
        }

        const image = typeof item !== 'string' && typeof item.image !== 'string' ? mbGetMediumImage(item.image) : undefined;


        return (
            <RoomsCategoryCard
                index={index}
                title={typeof item === 'string' ? '' : item.name}
                isLoading={isLoading}
                numberOfQuestions={undefined}
                {...props}
                image={image}
            />
        );
    }, [onEditSet, selectedCategories]);

    const content = useMemo(() => {
        return (
            <>
                <View style={styles.header}>
                    {gameType && <Feather name="chevron-left" style={styles.back} color={COLORS.buttonPurple} size={30} onPress={goBackToFirstScreen} />}
                    <View>
                        <Text style={styles.title}>{!gameType ? 'Select a game' : 'Select a category'}</Text>
                        <Text style={styles.subTitle}>{!gameType ? 'Choose a game for your participants to play.' : `Choose a category from ${utils.gameTypeToString(gameType)}`}</Text>
                    </View>
                    {isMobileApp &&
                        <Feather name="x" color={COLORS.buttonPurple} size={25} style={styles.close} onPress={onDismiss} />
                    }
                </View>
                {!gameType &&
                    <GamesView
                        onGameSelected={setGameType}
                        style={styles.gameView}
                        renderSelectedGameCard={RenderSelectedGameCard}
                    />
                }
                {gameType &&
                    <>
                        <View style={styles.difficulty}>
                            <DifficultySelector
                                difficulty={difficulty}
                                onDifficultyChange={setDifficulty}
                            />
                        </View>
                        <CategoriesView
                            gameType={gameType}
                            renderItem={RenderCategoryComponent}
                            style={styles.gameView}
                            difficulty={difficulty}
                            prefillWhileLoading
                        />
                        <View style={styles.footer}>
                            <MB_Button
                                title={`Select category (${selectedCategories.size})`}
                                style={styles.selectCategoryButton}
                                textStyle={styles.selectCategoryText}
                                disabled={selectedCategories.size === 0}
                                onPress={onSelectGame}
                            />
                        </View>
                    </>
                }
            </>
        );
    }, [RenderCategoryComponent, RenderSelectedGameCard, difficulty, gameType, goBackToFirstScreen, onDismiss, onSelectGame, selectedCategories.size]);

    if (isMobileApp) {
        return (
            <MB_ModalMobileFakeScreen
                isVisible={isVisible}
                onDismiss={onDismiss}
                container={styles.container}
            >
                {content}
            </MB_ModalMobileFakeScreen>
        );
    }

    return (
        <MB_Modal
            isVisible={isVisible}
            onDismiss={onDismiss}
            childrenWrapperStyle={[styles.container, !gameType && { height: 600 }]}
        >
            {content}
        </MB_Modal>
    );
};

export { SelectCategoriesModal };

const styles = StyleSheet.create({
    container: {
        backgroundColor: COLORS.backgroundDarkPurple,
        padding: 20,
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        paddingBottom: 0,
        ...mbPlatformStyle({
            web: {
                width: 460,
                height: 600,
                alignItems: 'stretch',
            },
            mobile: {
                height: '95%',
                marginTop: '10%',
            },
        }),
    },
    header: {
        flexDirection: 'row',
        ...mbPlatformStyle({
            web: {
                marginTop: -40,
            },
        }),
    },
    back: {
        marginTop: 4,
        marginRight: 4,
        ...mbPlatformStyle({
            web: {
                borderRadius: 6,
                width: 32,
                height: 32,
                backgroundColor: mbApplyTransparency(COLORS.white, 0.05),
                marginRight: 10,
            },
        }),
    },
    close: {
        position: 'absolute',
        top: 4,
        right: 0,
    },
    title: mbTextStyles([textStyles.largeText, {
        fontSize: 22,
        fontWeight: '700',
        textAlign: 'left',
    }]),
    subTitle: mbTextStyles([textStyles.smallerText, {
        textAlign: 'left',
        marginTop: 6,
    }]),
    gameView: {
        marginTop: 21,
        flex: 1,
    },
    footer: {
        backgroundColor: COLORS.backgroundDarkPurple,
        paddingVertical: 16,
        marginHorizontal: -20,
        paddingHorizontal: 30,
        ...mbShadow({
            color: COLORS.black,
            offsetWidth: 0,
            offsetHeight: -16,
            opacity: 0.25,
            radius: 4,
            elevation: 1,
        }),
    },
    selectCategoryButton: {
        alignSelf: 'stretch',
        backgroundColor: COLORS.buttonPurple,
    },
    selectCategoryText: mbTextStyles([
        textStyles.smallText, {
        },
    ]),
    difficulty: {
        marginTop: 10,
    },
    currentGameCard: {
        borderWidth: 3,
        borderColor: '#A430FF',
    },
});
