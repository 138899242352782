import { MB_Button } from '@mightybyte/rnw.components.button';
import { MB_VideoPlayer, MB_VIDEO_PLAYER_RESIZE_MODE } from '@mightybyte/rnw.components.video-player';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { useIsFocused } from '@react-navigation/native';
import React, { useState } from 'react';
import { Image, StyleSheet, useWindowDimensions, View } from 'react-native';
import { COLORS } from '../../constants/colors';
import { textStyles } from '../../constants/textStyles';
import { LandingPageBackground } from '../../resources/svgComponents/LandingPageBackground';
import { imageUtils } from '../../utils/imageUtils';
import { MobileLandingPagePopUp } from './MobileLandingPagePopUp';

const MobileLandingPage = () => {

  const [modalData, setModalData] = useState<{ isVisible: boolean, navigationPage?: 'Login' | 'SignUp' }>({ isVisible: false, navigationPage: 'Login' });
  const isFocused = useIsFocused();
  const dims = useWindowDimensions();

  return (
    <View style={styles.container} >
      <MobileLandingPagePopUp
        isVisible={modalData.isVisible}
        onHide={() => { setModalData({ isVisible: false }); }}
        screenToNavigateTo={modalData.navigationPage ?? 'Login'}
      />
      <MB_VideoPlayer
        sourceUrl={require('../../resources/videos/signInVideo.mp4')}
        containerStyle={styles.videoContainer}
        showControls={false}
        isLooping
        isMuted
        shouldPlay
        resizeMode={MB_VIDEO_PLAYER_RESIZE_MODE.cover}
        isFocused={isFocused}
      />
      <>
        <LandingPageBackground size={dims.width + 1} />
        <Image source={imageUtils.images.aslFlurryLogo} style={styles.logo} />
      </>
      <View style={styles.buttonContainer}>
        <MB_Button
          title="Log in"
          style={styles.leftBtnStyle}
          textStyle={mbTextStyles(styles.buttonText)}
          onPress={() => { setModalData({ isVisible: true, navigationPage: 'Login' }); }}
        />
        <MB_Button
          title="Sign up"
          style={styles.rightBtnStyle}
          textStyle={mbTextStyles([styles.buttonText, { color: COLORS.textWhite }])}
          onPress={() => { setModalData({ isVisible: true, navigationPage: 'SignUp' }); }}
        />
      </View>
    </View>
  );
};

export { MobileLandingPage };

const baseStyles = StyleSheet.create({
  button: {
    flex: 1,
    height: 44,
    marginHorizontal: 8,
    borderRadius: 10,
  },
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  logo: {
    position: 'absolute',
    alignSelf: 'center',
    width: 123,
    height: 81,
    top: 12,
  },
  buttonContainer: {
    position: 'absolute',
    bottom: 64,
    left: 0,
    right: 0,
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginHorizontal: 8,
  },
  videoContainer: {
    width: '100%',
    height: '90%',
    position: 'absolute',
    bottom: 0,
  },
  buttonText: mbTextStyles([
    textStyles.normalText, {
      color: COLORS.textPurple,
    },
  ]),
  leftBtnStyle: StyleSheet.flatten([
    baseStyles.button, {
      backgroundColor: 'transparent',
      borderColor: COLORS.buttonPurple,
      borderWidth: 2,
    },
  ]),
  rightBtnStyle: StyleSheet.flatten([
    baseStyles.button, {
      backgroundColor: COLORS.buttonPurple,
    },
  ]),
});
