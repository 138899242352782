import { mbGetSmallestImage } from '@mightybyte/rnw.components.image';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { LayoutChangeEvent, StyleSheet, View } from 'react-native';
import { FlatList } from 'react-native';
import { ANSWER_BUTTON_STATE, Answer, GAME_TYPE, Question } from '../../../typesAndInterfaces/typesAndInterfaces';
import { BaseCard } from './BaseCard';
import { imageUtils } from '../../../utils/imageUtils';
import { COLORS } from '../../../constants/colors';

const NUMBER_OF_COLUMNS = 4;

interface MatchGameCardsProps {
    question: Question,
    disableAnswers: boolean,
    buttonStates: { answerName: string, state: ANSWER_BUTTON_STATE }[] | undefined,
    changeAnswerStyles: (name: string) => void
    isPortrait: boolean,
}

const MatchGameCards = memo(({ question, disableAnswers, buttonStates, changeAnswerStyles, isPortrait }: MatchGameCardsProps) => {

    if (question.gameType !== GAME_TYPE.MatchingItemsGame) {
        throw new Error('MatchGameCards only support MatchingItemsGame Question');
    }

    const [cardWidth, setCardWidth] = useState<number | undefined>();
    const [cardHeight, setCardHeight] = useState<number | undefined>();

    const onLayoutChange = useCallback((e: LayoutChangeEvent) => {
        const width = e.nativeEvent.layout.width;
        const newCardWidth = ((width - 2 * 16) / NUMBER_OF_COLUMNS) - (isPortrait ? 0 : 8);

        setCardHeight(e.nativeEvent.layout.height / 2);

        if (Math.abs(newCardWidth - (cardWidth ?? 0)) >= 1) {
            setCardWidth(newCardWidth);
        }
    }, [cardWidth, isPortrait]);

    const cardHeightStyle = useMemo(() => ({ height: (cardHeight ?? 0) - (isPortrait ? 0 : 8) }), [cardHeight, isPortrait]);

    const GameCardMemoized = useCallback(({ item, index }: { item: Answer, index: number }) => {
        const buttonState = buttonStates?.find((state) => state.answerName === item.name);

        if (!cardWidth) {
            return null;
        }

        return (
            <View style={[styles.cardSpacing, !isPortrait && cardHeightStyle, [0, 1, 2, 4, 5, 6].includes(index) && styles.cardItemSeparator]}>
                <BaseCard
                    text={item.name}
                    imageUrl={mbGetSmallestImage(item.image) ?? imageUtils.images.brokenImage}
                    disabled={disableAnswers}
                    isCorrect={item.isCorrect}
                    cardWidth={cardWidth}
                    onPress={() => changeAnswerStyles(item.name)}
                    buttonState={buttonState?.state ?? ANSWER_BUTTON_STATE.unselected}
                    isPortrait={isPortrait}
                />
            </View>
        );
    }, [buttonStates, cardHeightStyle, cardWidth, changeAnswerStyles, disableAnswers, isPortrait]);

    const ItemSeparatorComponent = useCallback(() => <View style={styles.itemSeparator} />, []);


    return (
        <FlatList
            style={[styles.flatListStyle, isPortrait && styles.flatListStylePortrait]}
            data={question.answers}
            scrollEnabled={false}
            numColumns={NUMBER_OF_COLUMNS}
            renderItem={GameCardMemoized}
            columnWrapperStyle={styles.columnWrapperStyle}
            contentContainerStyle={!isPortrait && styles.flatListHorizontalContainerStyle}
            onLayout={onLayoutChange}
            ItemSeparatorComponent={ItemSeparatorComponent}
        />
    );
});

export { MatchGameCards };

const styles = StyleSheet.create({
    flatListStyle: {
        flexGrow: 1,
    },
    flatListStylePortrait: {
        marginVertical: 7,
        marginHorizontal: 9,
        marginBottom: 11,
    },
    cardSpacing: {
        padding: 4,
    },
    columnWrapperStyle: {
        justifyContent: 'center',
        marginHorizontal: 4,
    },
    flatListHorizontalContainerStyle: {
        paddingVertical: 8,
        paddingHorizontal: 8,
        //backgroundColor: '#f0f',
        //flexGrow: 1,
    },
    cardItemSeparator: {
        borderRightColor: COLORS.white,
        borderRightWidth: 1,
    },
    itemSeparator: {
        height: 1,
        backgroundColor: COLORS.white,
    },
});
