import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

const DragIcon = ({ style }: { style?: StyleProp<ViewStyle> }) => {
    return (
        <View style={style}>
            <Svg
                width={12}
                height={19}
                viewBox="0 0 12 19"
                fill="none"

            >
                <Path
                    d="M2.667 7.375a2 2 0 100 4 2 2 0 000-4zm0 7a2 2 0 100 4 2 2 0 000-4zm7-10a2 2 0 100-4 2 2 0 000 4zm-7-4a2 2 0 100 4 2 2 0 000-4zm7 14a2 2 0 100 4 2 2 0 000-4zm0-7a2 2 0 100 4 2 2 0 000-4z"
                    fill="#fff"
                    fillOpacity={0.55}
                />
            </Svg>
        </View>
    );
};

export { DragIcon };
