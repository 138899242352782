import { MB_Button } from '@mightybyte/rnw.components.button';
import { MB_Modal, MB_ModalMobileBottom } from '@mightybyte/rnw.components.modal';
import { MB_PopUpTemplateComponents } from '@mightybyte/rnw.components.pop-up';
import { isMobile } from '@mightybyte/rnw.utils.device-info';
import { mbPlatformStyle, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import React, { useCallback, useMemo, useReducer, useState } from 'react';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { COLORS } from '../../../constants/colors';
import { STRING_CONSTANTS } from '../../../constants/constants';
import { textStyles } from '../../../constants/textStyles';
import { useSignedInContext } from '../../../context/SignedInContext';
import { useDeleteAccount } from '../../../hooks/userHooks';
import { Checkbox } from '../../helperComponents/Checkbox';

const notices = [
    'You are about to permanently delete your ASL Flurry account and delete your data.',
    'Once your account is deleted, all of the purchases and services accessed through your account will no longer be available to you.',
    'If you proceed with this request you will not be able to purchase new orders, cancel existing orders, or apply for refunds.',
    'Please note deleting your account is a permanent action and once your account is closed it will no longer be available to you and cannot be restored. If you decide later that you want to start ordering from us again, or if you would like to use products and services that require an account, you will need to create a new account.',
];

const DeleteAccountModal = ({ isVisible, onDismiss }: { isVisible: boolean, onDismiss?: () => void }) => {

    const { signOut } = useSignedInContext();
    const [errorMessage, setErrorMessage] = useState('');
    const { mutate: deleteAccount, isLoading } = useDeleteAccount();
    const [isDeleteChecked, toggleIsDeletedChecked] = useReducer((prevState) => !prevState, false);

    const handleDeleteAccount = useCallback(() => {
        deleteAccount(undefined, {
            onSuccess: async () => {
                onDismiss?.();
                setTimeout(() => signOut({ skipSignOutRequest: true }), 500);
            },
            onError: () => setErrorMessage(STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN),
        });
    }, [deleteAccount, onDismiss, signOut]);

    const content = useMemo(() => (
        <View style={styles.contentContainerStyle}>
            {MB_PopUpTemplateComponents.components.exclamationCircle}
            <Text style={styles.deleteAccountText}>Delete your account</Text>
            <Text style={styles.subTitle}>Please read this carefully</Text>
            <ScrollView style={{ alignSelf: 'stretch' }}>
                {notices.map((notice, index) => (
                    <View key={index} style={styles.noticeContainer}>
                        <Text style={mbTextStyles([isMobile ? textStyles.smallerText : textStyles.normalText])}>{index + 1}. </Text>
                        <Text style={styles.noticeText}>{notice}</Text>
                    </View>
                ))}
            </ScrollView>
            <View style={styles.deleteConfirmationContainer}>
                <Checkbox isChecked={isDeleteChecked} onPress={toggleIsDeletedChecked} containerStyle={{ marginEnd: 11 }} />
                <Text style={styles.deleteConfirmationText}>Yes, I want to permanently close my ASL Flurry account and delete my data.</Text>
            </View>
            {errorMessage !== '' && <Text style={textStyles.popUpErrorMessageText}>{errorMessage}</Text>}
            <View style={styles.buttons}>
                <MB_Button
                    title="Keep my account"
                    style={styles.keepMyAccountBtn}
                    textStyle={mbTextStyles([textStyles.smallerText])}
                    onPress={onDismiss}
                />
                <MB_Button
                    title="Delete my account"
                    style={styles.deleteAccountBtn}
                    textStyle={mbTextStyles([textStyles.smallerText, { color: '#FC4A71' }])}
                    disabled={!isDeleteChecked || isLoading}
                    onPress={handleDeleteAccount}
                    loading={isLoading}
                />
            </View>
        </View>
    ), [errorMessage, handleDeleteAccount, isDeleteChecked, isLoading, onDismiss]);

    if (isMobile) {
        return (
            <MB_ModalMobileBottom
                isVisible={isVisible}
                onDismiss={onDismiss}
                childrenWrapperStyle={styles.modalStyle}
            >
                {content}
            </MB_ModalMobileBottom>
        );
    }
    return (
        <MB_Modal
            isVisible={isVisible}
            onDismiss={onDismiss}
            childrenWrapperStyle={styles.modalStyle}
        >
            {content}
        </MB_Modal>
    );
};

export { DeleteAccountModal };

const styles = StyleSheet.create({
    modalStyle: {
        backgroundColor: COLORS.backgroundDarkPurple,
        ...mbPlatformStyle({
            web: {
                width: 540,
            },
        }),
    },
    contentContainerStyle: {
        alignItems: 'center',
        paddingHorizontal: 16,
    },
    deleteAccountText: mbTextStyles([
        textStyles.normalText, {
            color: '#FC4A71',
            fontSize: 15,
            paddingHorizontal: 16,
            textAlign: 'left',
            marginTop: 5,
            fontWeight: '700',
        },
    ]),
    subTitle: mbTextStyles([
        textStyles.smallText, {
            color: '#D043DC',
            textAlign: 'left',
            marginTop: 22,
            alignSelf: 'stretch',
        },
    ]),
    noticeContainer: {
        flexDirection: 'row',
        marginVertical: 10,
    },
    noticeText: mbTextStyles([
        isMobile ? textStyles.smallerText : textStyles.normalText, {
            textAlign: 'left',
            flex: 1,
        }]),
    deleteConfirmationContainer: {
        flexDirection: 'row',
        marginTop: 16,
        width: '100%',
    },
    deleteConfirmationText: mbTextStyles([
        isMobile ? textStyles.smallerText : textStyles.smallText, {
            color: '#B8ABBA',
            textAlign: 'left',
            flex: 1,
        },
    ]),
    keepMyAccountBtn: {
        backgroundColor: COLORS.buttonPurple,
        height: 37,
    },
    deleteAccountBtn: {
        backgroundColor: 'transparent',
        marginTop: 12,
        borderColor: '#FC4A71',
        borderWidth: 1,
        height: 37,
    },
    buttons: {
        marginTop: 33,
        marginBottom: 48,
        alignSelf: 'stretch',
    },
});
