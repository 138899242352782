import React from 'react';
import { MB_Modal } from '@mightybyte/rnw.components.modal';
import { useState } from 'react';
import { PoolImage, RESOURCE_POOL_TYPE, PoolVideo, Folder } from '../../typesAndInterfaces/typesAndInterfaces';
import { StyleSheet, Text, useWindowDimensions, View } from 'react-native';
import { textStyles } from '../../constants/textStyles';
import { UploadImageVideoButton } from './UploadImageVideoButton';
import { ImageVideoPoolFlatList } from '../screens/Admin/ImageVideoPool/ImageVideoPoolFlatList';
import { COLORS } from '../../constants/colors';
import { MB_Button } from '@mightybyte/rnw.components.button';
import Feather from 'react-native-vector-icons/Feather';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';

const ImageVideoSelectorModal = <T extends RESOURCE_POOL_TYPE>({ isVisible, resourcePoolType, onDismiss }: { isVisible: boolean, resourcePoolType: T, onDismiss?: (item?: T extends RESOURCE_POOL_TYPE.Image ? PoolImage : PoolVideo) => void }) => {

    const [selectedItem, setSelectedItem] = useState<T extends RESOURCE_POOL_TYPE.Image ? PoolImage : PoolVideo>();
    const [selectedFolder, setSelectedFolder] = useState<Folder>();
    const { height } = useWindowDimensions();

    return (
        <MB_Modal
            isVisible={isVisible}
            onDismiss={() => onDismiss?.()}
            childrenWrapperStyle={[styles.modal, { height: height * 0.7 }]}
            title={resourcePoolType === RESOURCE_POOL_TYPE.Image ? 'Select Image' : 'Select Video'}
        >
            <View style={styles.header}>
                {selectedFolder && <View style={styles.headerButton}>
                    <MB_Button
                        leftElement={<Feather name="arrow-left" color={COLORS.buttonPurple} size={20} />}
                        style={styles.headerBackButton}
                        onPress={() => setSelectedFolder(undefined)}
                    />
                    <Text style={styles.title}>{selectedFolder.name}</Text>
                </View>
                }
                {resourcePoolType === RESOURCE_POOL_TYPE.Image &&
                    <UploadImageVideoButton buttonStyle={styles.uploadButton} resourcePoolType={resourcePoolType} folderId={selectedFolder?._id} />
                }
            </View>
            <ImageVideoPoolFlatList
                style={styles.imagePoolFlatList}
                selectedItem={selectedItem}
                onSelectItem={setSelectedItem}
                disableDelete
                resourcePoolType={resourcePoolType}
                folder={selectedFolder}
                onFolderPress={setSelectedFolder}
                disableAddFolder={true}
            />
            <View style={styles.footer}>
                <MB_Button
                    title="Cancel"
                    textStyle={[textStyles.smallText, { color: COLORS.buttonPurple }]}
                    style={styles.cancelButton}
                    onPress={() => onDismiss?.()}
                />
                <MB_Button
                    title={resourcePoolType === RESOURCE_POOL_TYPE.Image ? 'Select Image' : 'Select Video'}
                    textStyle={[textStyles.smallText]}
                    style={styles.selectButton}
                    onPress={() => onDismiss?.(selectedItem)}
                    disabled={!selectedItem}
                />
            </View>
        </MB_Modal>
    );
};

export { ImageVideoSelectorModal };

const styles = StyleSheet.create({
    modal: {
        width: 983,
        height: 864,
        minHeight: 600,
        backgroundColor: COLORS.backgroundDarkPurple,
        marginVertical: 80,
    },
    imagePoolFlatList: {
        marginTop: 32,
        flex: 1,
    },
    footer: {
        flexDirection: 'row',
        paddingTop: 32,
    },
    cancelButton: {
        backgroundColor: COLORS.white,
        width: 157,
        height: 38,
        borderRadius: 10,
        marginEnd: 17.5,
    },
    selectButton: {
        backgroundColor: COLORS.buttonPurple,
        width: 157,
        height: 38,
        borderRadius: 10,
    },
    uploadButton: {
        marginLeft: 'auto',
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    headerBackButton: {
        width: 47,
        height: 33,
        backgroundColor: COLORS.white,
        marginEnd: 16,
    },
    headerButton: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    title: mbTextStyles([
        textStyles.largestText, {
            fontSize: 24,
            color: COLORS.textLightPurple,
        },
    ]),
});
