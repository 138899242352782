import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { G, Path, Rect } from 'react-native-svg';

const RestartButton = React.memo((props: { size?: number, color?: string, containerStyle?: StyleProp<ViewStyle> }) => {
    return (
        <View style={props.containerStyle}>
            <Svg
                width={props.size ?? 44}
                height={props.size ? (props.size * (33 / 44)) : 33}
                viewBox="8 8 44 33"
                fill="none"
            >
                <G filter="url(#filter0_d_917_1613)" stroke={props.color ?? '#fff'}>
                    <Path
                        d="M38.1 23.936v-.114h-.004a8.195 8.195 0 00-2.379-5.542 8.068 8.068 0 00-5.62-2.38 8.063 8.063 0 00-5.517 2.097l-.043-.88a.832.832 0 00-.448-.698h0a.82.82 0 00-.824.041h0a.833.833 0 00-.378.694v.002l.001.044.152 3.002V20.35l.004.012c.004.02.01.038.017.057 0 .013 0 .026.002.04l.002.017.008.016a.606.606 0 00.022.04v.016l.011.022a.313.313 0 00.027.043l.03.04h.004l.013.015h0l.04.048h0l.007.007.08.077h-.001l.008.007.05.04.028.021H23.45l.077.05.01.007.012.004.055.018.015.005h.245v0h.008l2.897-.245s0 0 0 0a.827.827 0 00.68-.474.838.838 0 00-.068-.83h0a.825.825 0 00-.748-.355h0l-.979.082a6.421 6.421 0 015.207-1.64h0a6.453 6.453 0 014.738 3.208 6.553 6.553 0 01.362 5.742l.087.036-.087-.036a6.471 6.471 0 01-4.296 3.787 6.414 6.414 0 01-5.596-1.129 6.526 6.526 0 01-2.521-5.161v0a.83.83 0 00-.412-.72.82.82 0 00-.825 0h0a.83.83 0 00-.412.72c0 2.164.853 4.24 2.372 5.772h0A8.07 8.07 0 0030 32.1a8.07 8.07 0 005.728-2.391h0a8.197 8.197 0 002.372-5.773z"
                        fill={props.color ?? '#fff'}
                        strokeWidth={0.2}
                    />
                    <Rect
                        x={8.75}
                        y={8.75}
                        width={42.5}
                        height={30.5}
                        rx={7.25}
                        strokeWidth={1.5}
                    />
                </G>
            </Svg>
        </View >
    );
});

export { RestartButton };
