import * as React from 'react';
import Svg, {
    G,
    Path,
    Defs,
    LinearGradient,
    Stop,
    RadialGradient,
    Circle,
} from 'react-native-svg';
import { BODY_PARTS, BodyPartsSvgProps } from '../../../components/screens/Game/bodyPartsControls/bodyPartsConstants';
import { BODY_PARTS_SVG_BUTTON_TYPES, BodyPartSvgButton } from '../../../components/helperComponents/BodyPartSvgButton';

const BodyTypesSVG_eye = ({ onPress, highlightedParts }: BodyPartsSvgProps) => {
    const [_active, setActive] = React.useState<BODY_PARTS | undefined>();

    return (
        <Svg
            style={{ width: '100%', height: '100%' }}
            viewBox="0 0 220 220"
            fill="none"
        >
            <G id="Eyelids">
                <G id="Eye">
                    <G id="Eye_2">
                        <G id="pressable_leftEyebrow">
                            <G id="Left Brow">
                                <Path
                                    id="Vector"
                                    d="M58.045 100.033a53.174 53.174 0 018.495-.138c1.32.014 2.628.271 3.857.758v-.597c.484.168.94.408 1.354.711a1.53 1.53 0 00.253-.413 4.156 4.156 0 01.987 5.372 21.022 21.022 0 01-4.959-.918 51.707 51.707 0 00-9.987-.689 10.704 10.704 0 00-4.752 2.09c-2.296 1.607-3.834 2.869-4.592 3.283a24.63 24.63 0 013.352-5.855 9.185 9.185 0 015.992-3.604z"
                                    fill="#1C1E1F"
                                />
                                <Path
                                    id="Vector_2"
                                    d="M72.922 103.064a4.2 4.2 0 01-.39 1.882 24.991 24.991 0 01-4.317-.872A50.241 50.241 0 0058 103.362a16.874 16.874 0 00-7.92 4.385 23.453 23.453 0 013.88-4.89 7.85 7.85 0 014.177-2.066 53.432 53.432 0 018.334-.138 6.636 6.636 0 014.89 1.653c.064-.415 0-.839-.183-1.217.528.207.941 1.056 1.125 1.63a1.626 1.626 0 000-1.354c.396.477.616 1.078.62 1.699z"
                                    fill="url(#paint0_linear_5387_8855)"
                                />
                                <Path
                                    id="Vector_3"
                                    style={{
                                        mixBlendMode: 'multiply',
                                    }}
                                    d="M72.922 103.062a4.204 4.204 0 01-.39 1.883 25.088 25.088 0 01-4.317-.873A50.24 50.24 0 0058 103.361a16.866 16.866 0 00-7.92 4.385 22.18 22.18 0 016.336-4.845 22.439 22.439 0 018.196-.78 9.704 9.704 0 013.099.964 6.492 6.492 0 00-.39-1.079 6.89 6.89 0 012.87 1.63c.183 0 0-.987 0-.987a9.192 9.192 0 011.905 1.653c.341-.366.62-.784.827-1.24z"
                                    fill="url(#paint1_linear_5387_8855)"
                                />
                            </G>
                            <Path
                                id="highlight_leftEyebrow"
                                d="M70.897 101.389v-.57c.194.103.38.221.558.351l.375.276.272-.32a3.66 3.66 0 01.602 4.079 20.56 20.56 0 01-4.523-.874l-.033-.01-.033-.006a52.147 52.147 0 00-10.084-.695l-.035.001-.035.006a11.19 11.19 0 00-4.965 2.179c-1.028.72-1.905 1.371-2.624 1.905l-.25.185-.244.181a24.11 24.11 0 012.57-4.134h0l.006-.008a8.683 8.683 0 015.653-3.406 52.718 52.718 0 018.4-.135l.014.001h.013c1.26.013 2.507.258 3.678.722l.685.272z"
                                fill="#A430FF"
                                fillOpacity={0.25}
                                stroke="#A430FF"
                                opacity={highlightedParts.includes(BODY_PARTS.eyebrow) ? 1 : 0}
                            />
                        </G>
                        <G id="pressable_eyelash">
                            <G id="Eyelash" fill="#1C1E1F">
                                <Path
                                    id="Vector 44"
                                    d="M50.702 119.544c.8-.23 1.537-1.062 1.537-1.062s.329.864.317.938c-.267.474-1.13.583-1.647.745-.865.269-3.27-.58-3.27-.58s2.423.142 3.063-.041z"
                                />
                                <Path
                                    id="Vector 45"
                                    d="M51.289 121.033c.8-.229 1.583-1.033 1.583-1.033l.469.83s-1.343.667-1.845.824c-.865.269-2.408-.014-2.408-.014s1.561-.424 2.2-.607z"
                                />
                                <Path
                                    id="Vector 46"
                                    d="M52.256 122.511c.722-.345 1.611-1.097 1.611-1.097l.525.715s-1.534.749-1.9.939c-.805.417-2.552.715-2.552.715s1.715-.985 2.316-1.272z"
                                />
                                <Path
                                    id="Vector 47"
                                    d="M53.653 123.733c.676-.526 1.35-1.12 1.35-1.12l.788.466s-1.572 1.039-1.903 1.21c-.804.417-2.09.985-2.09.985s1.33-1.133 1.855-1.541z"
                                />
                                <Path
                                    id="Vector 48"
                                    d="M55.404 124.394c.437-.495 1.03-1.042 1.03-1.042l.582.376s-1.124.851-1.342 1.058c-.301.286-1.489 1.143-1.489 1.143s.756-1.011 1.22-1.535z"
                                />
                                <Path
                                    id="Vector 54"
                                    d="M69.548 123.523c-.174-.443-.303-.48-.303-.48l.502-.2s.134.502.199.714c.084.277.189 1.286.189 1.286s-.402-.85-.587-1.32z"
                                />
                                <Path
                                    id="Vector 48_2"
                                    d="M56.718 125.435c.381-.704.985-1.457.985-1.457l.8.322s-.947 1.137-1.168 1.469c-.279.421-1.637 1.812-1.637 1.812s.616-1.4 1.02-2.146z"
                                />
                                <Path
                                    id="Vector 49"
                                    d="M58.756 125.43c.15-.558.395-1.05.395-1.05l.604.052s-.461.979-.538 1.189c-.124.338-.824 1.495-.824 1.495s.203-1.096.363-1.686z"
                                />
                                <Path
                                    id="Vector 50"
                                    d="M60.392 125.935c.093-.744.266-1.378.266-1.378l.702.027s-.321 1.352-.428 1.675c-.152.46-.51 1.349-.51 1.349s-.13-.885-.03-1.673z"
                                />
                                <Path
                                    id="Vector 51"
                                    d="M62.15 126.241c-.042-.748.014-1.765.014-1.765l.742-.006s-.157 1.619-.165 1.992c-.011.456-.378 1.381-.378 1.381s-.167-.809-.213-1.602z"
                                />
                                <Path
                                    id="Vector 52"
                                    d="M63.99 125.907c-.045-.661-.131-1.54-.131-1.54l.743-.052s-.09 1.484-.094 1.792c-.004.402-.29 1.791-.29 1.791s-.179-1.292-.227-1.991z"
                                />
                                <Path
                                    id="Vector 53"
                                    d="M65.89 125.553c-.188-.553-.445-1.338-.445-1.338l.754-.144s.117 1.232.184 1.5c.086.346.013 1.142.013 1.142s-.308-.573-.507-1.16z"
                                />
                                <Path
                                    id="Vector 54_2"
                                    d="M67.975 124.968c-.173-.487-.337-1.253-.337-1.253l.508-.127s.183 1.034.258 1.317c.081.306.02.821.02.821s-.266-.241-.45-.758z"
                                />
                            </G>
                            <Path
                                id="highlight_eyelash"
                                d="M50.556 119.591c.514-.16 1.33-.697 1.33-.697 1.727 5.976 10.43 6.98 17.915 4.16l.342 1.71c-6.898 5.675-18.863 4.367-22.461-5.173 0 0 1.396.459 2.874 0z"
                                fill="#A430FF"
                                fillOpacity={0.25}
                                stroke="#A430FF"
                                opacity={highlightedParts.includes(BODY_PARTS.eyelash) ? 1 : 0}
                            />
                        </G>
                        <G id="pressable_eyeLid">
                            <G id="Eye Lid">
                                <Path
                                    id="Vector_4"
                                    d="M72.033 119.198a13.545 13.545 0 00-1.17-6.887c-1.677-3.65-6.13-4.936-9.873-4.592a9.779 9.779 0 00-9.436 10.446c1.493 6.888 10.08 7.209 15.589 5.74a6.892 6.892 0 004.477-2.801c.161-.284.27-.596.321-.918a8.92 8.92 0 00.092-.988z"
                                    fill="#1C1E1F"
                                />
                                <Path
                                    id="Vector_5"
                                    d="M52.198 118.05c.322 2.594 3.145 6.106 9.183 6.152 6.038.046 10.194-1.699 10.515-4.086.322-2.388 0-10.974-8.724-11.479-8.724-.505-11.295 6.704-10.974 9.413z"
                                    fill="url(#paint2_radial_5387_8855)"
                                />
                                <Path
                                    id="Vector 55"
                                    d="M68.837 118.967c1.47-.873 2.727-1.855 2.337-4.701.786 2.127 1.02 4.913.54 6.462-2.078 4.638-17.738 5.6-19.436-2.47-.26-1.09.189-2.323.39-3.314 2.63 6.276 12.593 5.812 16.17 4.023z"
                                    fill="url(#paint3_linear_5387_8855)"
                                />
                            </G>
                            <Path
                                id="highlight_eyeLid"
                                d="M51.934 117.923c-.6-11.936 20.446-15.061 20.354 2.579 0 0-.814 4.013-10.814 4.013s-9.54-6.592-9.54-6.592z"
                                fill="#A430FF"
                                fillOpacity={0.25}
                                stroke="#A430FF"
                                opacity={highlightedParts.includes(BODY_PARTS.eyelid) ? 1 : 0}
                            />
                        </G>
                    </G>
                    <G id="Group 27718">
                        <G id="Group">
                            <G
                                id="Ellipse 83"
                                transform="rotate(174.48 55.485 125.266)"
                                fill="#fff"
                            >
                                <Circle cx={55.4849} cy={125.266} r={2} />
                                <Circle cx={55.4849} cy={125.266} r={2} />
                            </G>
                            <Path
                                id="Line 78"
                                stroke="#fff"
                                d="M120.838 156.181L54.8023 125.16"
                            />
                        </G>
                        <G id="pressableContainerSelected_eyelashes">
                            <G id="pressableButton_eyelashes">
                                <BodyPartSvgButton
                                    type={BODY_PARTS_SVG_BUTTON_TYPES.activate}
                                    bodyPart={BODY_PARTS.eyelash}
                                    activateAsSelect
                                    onPress={onPress}
                                    onActivate={setActive}
                                    x={107}
                                    y={139}
                                    width={102}
                                    height={32}
                                />
                            </G>
                        </G>
                    </G>
                    <G id="Group 27719">
                        <G id="Group_2">
                            <G
                                id="Ellipse 83_2"
                                transform="rotate(-172.267 63.166 102.375)"
                                fill="#fff"
                            >
                                <Circle cx={63.1662} cy={102.375} r={2} />
                                <Circle cx={63.1662} cy={102.375} r={2} />
                            </G>
                            <Path
                                id="Line 78_2"
                                stroke="#fff"
                                d="M124.193 102.224L62.8366 102.224"
                            />
                        </G>
                        <G id="pressableContainerSelected_eyebrow">
                            <G id="pressableButton_eyebrow">
                                <BodyPartSvgButton
                                    type={BODY_PARTS_SVG_BUTTON_TYPES.activate}
                                    bodyPart={BODY_PARTS.eyebrow}
                                    activateAsSelect
                                    onPress={onPress}
                                    onActivate={setActive}
                                    x={107}
                                    y={88.5}
                                    width={86}
                                    height={32}
                                />
                            </G>
                        </G>
                    </G>
                    <G id="Group 27720">
                        <G id="Group_3">
                            <G
                                id="Ellipse 83_3"
                                transform="rotate(-30 59.474 114.814)"
                                fill="#fff"
                            >
                                <Circle cx={59.4742} cy={114.814} r={2} />
                                <Circle cx={59.4742} cy={114.814} r={2} />
                            </G>
                            <Path
                                id="Line 78_3"
                                stroke="#fff"
                                d="M45.66 78.4693L60.5945 114.938"
                            />
                        </G>
                        <G id="pressableContainerSelected_eyelid">
                            <G id="pressableButton_eyelid">
                                <BodyPartSvgButton
                                    type={BODY_PARTS_SVG_BUTTON_TYPES.activate}
                                    bodyPart={BODY_PARTS.eyelid}
                                    activateAsSelect
                                    onPress={onPress}
                                    onActivate={setActive}
                                    x={10}
                                    y={48.5}
                                    width={65}
                                    height={32}
                                />
                            </G>
                        </G>
                    </G>
                </G>
            </G>
            <Defs>
                <LinearGradient
                    id="paint0_linear_5387_8855"
                    x1={61.6491}
                    y1={104.992}
                    x2={62.6593}
                    y2={100.102}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#3C3F41" />
                    <Stop offset={1} stopColor="#4B4E50" />
                </LinearGradient>
                <LinearGradient
                    id="paint1_linear_5387_8855"
                    x1={62.9118}
                    y1={96.8633}
                    x2={60.593}
                    y2={111.281}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#A1A1AD" />
                    <Stop offset={1} stopColor="#A1A1AD" stopOpacity={0} />
                </LinearGradient>
                <RadialGradient
                    id="paint2_radial_5387_8855"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="rotate(12.86 -471.73 341.351) scale(15.0426)"
                >
                    <Stop offset={0.65625} stopColor="#D98B65" />
                    <Stop offset={0.958333} stopColor="#7E3000" />
                </RadialGradient>
                <LinearGradient
                    id="paint3_linear_5387_8855"
                    x1={64.4301}
                    y1={106.185}
                    x2={64.6989}
                    y2={122.492}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#AF6136" />
                    <Stop offset={1} stopColor="#AF6136" stopOpacity={0} />
                </LinearGradient>
            </Defs>
        </Svg>
    );
};

export { BodyTypesSVG_eye };
