import * as React from 'react';
import Svg, {
    G,
    Path,
    Defs,
    LinearGradient,
    Stop,
    RadialGradient,
    Circle,
} from 'react-native-svg';
import { BODY_PARTS, BodyPartsSvgProps } from '../../../components/screens/Game/bodyPartsControls/bodyPartsConstants';
import { BODY_PARTS_SVG_BUTTON_TYPES, BodyPartSvgButton } from '../../../components/helperComponents/BodyPartSvgButton';

const BodyTypesSVG_mouth = ({ onPress, highlightedParts }: BodyPartsSvgProps) => {
    const [_active, setActive] = React.useState<BODY_PARTS | undefined>();

    return (
        <Svg
            style={{ width: '100%', height: '100%' }}
            viewBox="0 0 220 220"
            fill="none"
        >
            <G id="Mouth">
                <G id="Group 27726">
                    <G id="Mouth_2">
                        <G id="pressable_lips">
                            <G id="Mouth_3">
                                <G id="Teeth">
                                    <Path
                                        id="Vector"
                                        d="M73.067 100.755c-2.64 3.857-8.082 11.778-14.923 11.778-6.842 0-10.308-4.592-15.75-11.778a85.916 85.916 0 0015.36 1.745c5.14-.197 10.26-.78 15.313-1.745z"
                                        fill="url(#paint0_radial_5387_8960)"
                                    />
                                    <G id="Teeth_2">
                                        <G id="Group">
                                            <Path
                                                id="Vector_2"
                                                d="M63.585 107.092c-2.296-.39-5.464 1.584-7.92.368-3.33-1.608-7.881-.226-7.467 2.491 3.938 1.709 4.252 5.062 9.74 5.062 4.591 0 8.855-2.317 9.153-5.118-.114-1.056-.2-2.344-3.506-2.803z"
                                                fill="#823A38"
                                            />
                                            <Path
                                                id="Vector_3"
                                                d="M49.068 110.17c-.115-1.905 1.11-2.34 3.176-2.593a7.277 7.277 0 013.145.597 7.327 7.327 0 004.752 0 8.28 8.28 0 013.352-.436c2.503.344 3.306 1.538 3.42 2.64.116 1.102-3.512 3.765-8.976 3.765s-8.8-2.412-8.869-3.973z"
                                                fill="url(#paint1_radial_5387_8960)"
                                            />
                                            <Path
                                                id="Vector_4"
                                                d="M58.072 100.622c-4.592.115-13.02.071-13.013.318.252 2.25.826 3.582 2.64 3.949.403.367.902.611 1.438.704a2.862 2.862 0 001.592-.176 3.665 3.665 0 003.513.551c.555.331 1.19.506 1.837.505a3.425 3.425 0 001.63-.39 3.583 3.583 0 003.49-.184 3.456 3.456 0 003.053-.321 3.262 3.262 0 002.961-.276 2.137 2.137 0 002.296-1.469c.509-1.248.564-1.338.695-2.679.212-.993-7.54-.44-12.132-.532z"
                                                fill="#70707A"
                                            />
                                            <Path
                                                id="Vector_5"
                                                d="M69.707 100.729c-.133 1.16-.119 1.432-.52 2.529l-.183.345a1.604 1.604 0 01-1.86.964 2.53 2.53 0 01-2.87.207 2.75 2.75 0 01-1.51.648 2.748 2.748 0 01-1.612-.327 2.985 2.985 0 01-3.467.23 2.85 2.85 0 01-3.352-.161 3.008 3.008 0 01-3.49-.551 2.294 2.294 0 01-2.892-.436H47.7c-1.355-.253-1.607-1.493-1.975-3.352-.183-1.08 6.79.269 12.277.223 5.487-.046 11.798-1.123 11.706-.319z"
                                                fill="url(#paint2_radial_5387_8960)"
                                            />
                                            <Path
                                                id="Vector_6"
                                                style={{
                                                    mixBlendMode: 'multiply',
                                                }}
                                                d="M69.813 100.658c-.133 1.16-.225 1.504-.626 2.601l-.183.345c-.689-1.355-2.066-.988-3.214-.896-1.148.092-1.883.207-3.444.322a48.21 48.21 0 01-6.635.275 17.256 17.256 0 00-3.558-.275 9.649 9.649 0 01-2.916-.184c-.78 0-1.309.413-1.538 1.286-1.355-.253-1.607-1.493-1.975-3.352-.183-1.08 6.719.101 12.206.055 5.487-.046 11.975-.98 11.883-.177z"
                                                fill="url(#paint3_linear_5387_8960)"
                                            />
                                            <Path
                                                id="Vector_7"
                                                d="M57.634 114.949c-6.511-.239-10.808-6.755-10.808-6.755a4.592 4.592 0 013.33 1.171 5.74 5.74 0 013.88.62 4.588 4.588 0 011.996-.207c.592.035 1.17.191 1.7.459a5.097 5.097 0 013.765-.16c1.013-.56 2.337-.729 3.485-.571.895-.734 1.854-1.196 3.012-1.22.427-.366.672-.696 1.23-.773 0 0-2.386 7.773-11.59 7.436z"
                                                fill="#70707A"
                                            />
                                            <Path
                                                id="Vector_8"
                                                d="M57.61 114.86c-6.884.197-9.068-5.845-10.391-6.131.514-.109.47.045.984.154a3.42 3.42 0 011.722 1.079 5.623 5.623 0 012.549 0c.536.091 1.037.33 1.446.689a4.107 4.107 0 013.72.207 4.408 4.408 0 013.879 0 3.76 3.76 0 011.607-.712 4.135 4.135 0 011.929 0c.208-.289.474-.531.78-.712a4.903 4.903 0 012.296-.551c-.978.543-1.66 6.551-10.521 5.977z"
                                                fill="url(#paint4_radial_5387_8960)"
                                            />
                                            <Path
                                                id="Vector_9"
                                                style={{
                                                    mixBlendMode: 'multiply',
                                                }}
                                                d="M67.076 110.605s-1.269 4.386-9.154 4.424c-5.069.025-8.4-3.582-8.469-3.674-.069-.092-1.065-1.095-1.18-2.357a3.42 3.42 0 011.722 1.079 5.623 5.623 0 012.549 0 5.604 5.604 0 003.145 1.607c2.604.51 5.305-.035 7.507-1.515a4.108 4.108 0 011.929 0 2.56 2.56 0 01.78-.712c.478.281.88.675 1.171 1.148z"
                                                fill="url(#paint5_linear_5387_8960)"
                                            />
                                        </G>
                                    </G>
                                </G>
                                <G id="Lips">
                                    <Path
                                        id="Vector_10"
                                        d="M66.72 100.108a25.754 25.754 0 00-8.541.505c-1.906-1.08-5.464-.85-8.656-.551-2.617.23-4.913.321-7.484.436a56.852 56.852 0 003.053 5.372c4.983 8.151 8.84 9.689 13.34 9.689 4.958 0 8.126-2.549 12.856-10.653.574-1.056 1.86-3.168 2.433-4.591-2.273-.069-4.385-.161-7.002-.207zm-8.702 2.755c4.972-.207 9.921-.782 14.808-1.722-2.778 3.972-8.632 11.043-14.51 11.043-5.877 0-10.514-4.867-15.29-11.112a92.844 92.844 0 0014.992 1.791z"
                                        fill="#915548"
                                    />
                                    <Path
                                        id="Vector_11"
                                        d="M73.217 100.752a102.713 102.713 0 01-15.313 1.745 85.916 85.916 0 01-15.36-1.745c3.904-.161 9.184-.161 11.25-.275a8.423 8.423 0 014.34.826 15.039 15.039 0 015.096-.666c1.745.115 7.416-.183 9.987.115z"
                                        fill="url(#paint6_radial_5387_8960)"
                                    />
                                    <Path
                                        id="Vector_12"
                                        d="M73.217 100.751c-5.992 11.479-10.813 14.234-14.969 14.234-4.155 0-9.711-2.296-15.703-14.234 5.441 7.117 8.93 11.777 15.75 11.777 6.818 0 12.282-7.92 14.922-11.777z"
                                        fill="url(#paint7_radial_5387_8960)"
                                    />
                                    <Path
                                        id="Vector_13"
                                        d="M72.54 98.77c.378.237.726.523 1.032.85.158.173.304.357.436.55.15.205.252.44.299.689.081.463.081.937 0 1.4a4.851 4.851 0 01-.436 1.263v-1.332a7.282 7.282 0 000-1.239 2.956 2.956 0 00-.483-1.08 8.17 8.17 0 00-.849-1.101z"
                                        fill="#915548"
                                    />
                                    <Path
                                        id="Vector_14"
                                        d="M43.428 98.563a11.46 11.46 0 00-.688 1.263 3.642 3.642 0 00-.39 1.263 5.229 5.229 0 000 1.331c0 .459.16.941.206 1.424a6.911 6.911 0 01-.643-1.309 3.742 3.742 0 01-.206-1.515 3.147 3.147 0 01.596-1.424 5.626 5.626 0 011.125-1.033z"
                                        fill="#915548"
                                    />
                                </G>
                            </G>
                            <G id="highlight_lips" opacity={highlightedParts.includes(BODY_PARTS.lips) ? 1 : 0}>
                                <Path
                                    d="M58.057 115.511c-6 0-12.604-7.596-16-15.374 6.769.093 12.445-1.817 16.445.104 3.653-1.056 9.58-.388 15.08.092l-2.58 5.358c-3.993 6.224-7.353 10.205-12.945 9.82z"
                                    fill="#A430FF"
                                    fillOpacity={0.25}
                                />
                                <Path
                                    d="M42.057 100.137c3.396 7.778 10 15.374 16 15.374 5.592.385 8.951-3.596 12.944-9.82l2.581-5.358m-31.525-.196c6.769.093 12.445-1.817 16.445.104 3.653-1.056 9.58-.388 15.08.092m-31.525-.196S51.69 102.679 58 102.76c6.711.086 15.581-2.427 15.581-2.427m-31.525-.196s8.181 12.022 16.02 12.092c7.685.068 15.505-11.896 15.505-11.896"
                                    stroke="#A430FF"
                                />
                            </G>
                        </G>
                    </G>
                    <G id="Group 27725">
                        <G id="Group_2">
                            <G
                                id="Ellipse 83"
                                transform="rotate(150 65.946 110.523)"
                                fill="#fff"
                            >
                                <Circle cx={65.9457} cy={110.523} r={2} />
                                <Circle cx={65.9457} cy={110.523} r={2} />
                            </G>
                            <Path
                                id="Line 78"
                                stroke="#fff"
                                d="M129.904 110.709L65.2862 110.709"
                            />
                        </G>
                        <G id="pressableContainerSelected_lips">
                            <G id="pressableButton_lips">
                                <BodyPartSvgButton
                                    type={BODY_PARTS_SVG_BUTTON_TYPES.activate}
                                    bodyPart={BODY_PARTS.lips}
                                    onPress={onPress}
                                    onActivate={setActive}
                                    activateAsSelect
                                    x={117}
                                    y={94.5}
                                    width={61}
                                    height={32}
                                />
                            </G>
                        </G>
                    </G>
                </G>
            </G>
            <Defs>
                <RadialGradient
                    id="paint0_radial_5387_8960"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(57.73 106.656) scale(11.6169)"
                >
                    <Stop stopColor="#44211F" />
                    <Stop offset={1} stopColor="#21100F" />
                </RadialGradient>
                <RadialGradient
                    id="paint1_radial_5387_8960"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(7.64508 0 0 7.6451 59.04 112.835)"
                >
                    <Stop stopColor="#D15F5D" />
                    <Stop offset={1} stopColor="#B5504E" />
                </RadialGradient>
                <RadialGradient
                    id="paint2_radial_5387_8960"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(12.5351 0 0 12.5352 59.293 109.205)"
                >
                    <Stop offset={0.62} stopColor="#fff" />
                    <Stop offset={1} stopColor="#A1A1AD" />
                </RadialGradient>
                <LinearGradient
                    id="paint3_linear_5387_8960"
                    x1={57.8922}
                    y1={94.581}
                    x2={57.8922}
                    y2={107.208}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#A1A1AD" />
                    <Stop offset={1} stopColor="#A1A1AD" stopOpacity={0} />
                </LinearGradient>
                <RadialGradient
                    id="paint4_radial_5387_8960"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(59.843 105.049) scale(10.5378)"
                >
                    <Stop offset={0.62} stopColor="#fff" />
                    <Stop offset={1} stopColor="#A1A1AD" />
                </RadialGradient>
                <LinearGradient
                    id="paint5_linear_5387_8960"
                    x1={57.5027}
                    y1={114.577}
                    x2={57.6863}
                    y2={109.112}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#A1A1AD" />
                    <Stop offset={1} stopColor="#A1A1AD" stopOpacity={0} />
                </LinearGradient>
                <RadialGradient
                    id="paint6_radial_5387_8960"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(58.547 92.005) scale(10.8592)"
                >
                    <Stop stopColor="#F0A873" />
                    <Stop offset={1} stopColor="#B86947" />
                </RadialGradient>
                <RadialGradient
                    id="paint7_radial_5387_8960"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(8.70116 0 0 8.70117 57.927 109.131)"
                >
                    <Stop offset={0.39} stopColor="#BE734C" />
                    <Stop offset={1} stopColor="#C97551" />
                </RadialGradient>
            </Defs>
        </Svg>
    );
};

export { BodyTypesSVG_mouth };
