import Pusher from 'pusher-js';
import { envs } from '../../../env';
import { roomApiCalls } from '../../apiCalls/roomApiCalls';
import { Callbacks } from './MB_Pusher';
import { ROOM_EVENT_NAME } from '../../typesAndInterfaces/typesAndInterfaces';

let pusher: Pusher | undefined;

const MB_Pusher = {
    init: (onConnectionStateChange?: (newConnectionStatus: string) => void) => {
        (this as any).onConnectionStateChange = onConnectionStateChange;
    },
    connect: () => {
        if (!pusher) {
            pusher = new Pusher(envs.PUSHER_KEY, {
                cluster: envs.PUSHER_CLUSTER,
                activityTimeout: 4000,
                pongTimeout: 4000,
                channelAuthorization: {
                    transport: 'ajax',
                    endpoint: 'not used',
                    customHandler: (params, cb) => {
                        roomApiCalls
                            .authorizeRoomChannelUser(params.channelName, params.socketId)
                            .then(authData => cb(null, authData))
                            .catch(err => cb(err, null));
                    },
                },
            });
            pusher.connection
                .bind('state_change', (states: { previous: string, current: string }) => {
                    (this as any).onConnectionStateChange?.(states.current.toUpperCase());
                });
        } else {
            pusher.connect();
        }
    },
    disconnect: () => pusher?.disconnect(),
    getChannelsName: () => [...(pusher?.allChannels() ?? [])].map(channel => channel.name.toString()),
    unsubscribe: (channelName: string) => pusher?.unsubscribe(channelName),
    isSubscribedToChannel: (channelName: string) => pusher?.channel(channelName)?.subscribed ?? false,
    subscribe: (channelName: string, callbacks: Callbacks) => {
        const channel = pusher?.subscribe(channelName);
        channel?.bind('pusher:subscription_succeeded', (members: any) => {
            const hash: Record<string, any> = {};
            const ids: string[] = [];
            members.each((member: any) => {
                hash[member.id] = { name: member.info.name  };
                ids.push(member.id);
            });
            callbacks.onSubscriptionSucceeded?.({
                presence: {
                    count: ids.length,
                    ids,
                    hash,
                },
            });
        });
        channel?.bind('pusher:subscription_error', (err: any) => {
            callbacks.onSubscriptionError?.(channel.name, 'subscription_error', err);
        });
        channel?.bind('pusher:member_added', (member: any) => {
            callbacks.onMemberAdded?.({ userId: member.id, userInfo: member.info });
        });
        channel?.bind('pusher:member_removed', (member: any) => {
            callbacks.onMemberRemoved?.({ userId: member.id, userInfo: member.info });
        });
        Object.keys(ROOM_EVENT_NAME).forEach(eventName => {
            channel?.bind(eventName, (data: any) => {
                callbacks.onEvent?.({ eventName, data });
            });
        });
    },
};

export { MB_Pusher };
