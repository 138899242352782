import { PusherAuthorization, Room, RoomGame, SCORE_TYPE } from '../typesAndInterfaces/typesAndInterfaces';
import { PusherAuthorizationObj, RoomObj } from '../utils/zod/zodObjects';
import { axiosCaller } from './customAxios';


const roomApiCalls = {
    createRoom: async (name: string): Promise<Room> => {
        return await axiosCaller({
            path: '/api/room/createRoom',
            method: 'post',
            responseDataKey: 'room',
            responseValidatorObj: RoomObj,
            responseFormatValidatorMessage: 'Data mismatch for create room response',
            passToken: true,
            data: { name },
        });
    },
    joinRoom: async (code: string): Promise<Room> => {
        return await axiosCaller({
            path: '/api/room/joinRoom',
            method: 'post',
            responseDataKey: 'room',
            responseValidatorObj: RoomObj,
            responseFormatValidatorMessage: 'Data mismatch for join room response',
            passToken: true,
            data: { code },
        });
    },
    getRoom: async (roomId: string): Promise<Room> => {
        return await axiosCaller({
            path: '/api/room/getRoom',
            method: 'get',
            responseDataKey: 'room',
            responseValidatorObj: RoomObj,
            responseFormatValidatorMessage: 'Data mismatch for get room response',
            passToken: true,
            params: { roomId },
        });
    },
    authorizeRoomChannelUser: async (channelName: string, socketId: string): Promise<PusherAuthorization> => {
        return await axiosCaller({
            path: '/api/room/authorizeRoomChannelUser',
            method: 'post',
            responseValidatorObj: PusherAuthorizationObj,
            responseFormatValidatorMessage: 'Data mismatch for get room response',
            passToken: true,
            data: { socketId, channel_name: channelName },
        });
    },
    startRoomGame: async (roomId: string, scoringType: SCORE_TYPE, roomGame: RoomGame, isCreatorParticipating: boolean): Promise<Room> => {
        return await axiosCaller({
            path: '/api/room/startRoomGame',
            method: 'post',
            passToken: true,
            responseDataKey: 'room',
            responseValidatorObj: RoomObj,
            data: { roomId, scoringType, roomGame, isCreatorParticipating },
        });
    },
    updateRoom: async (roomId: string, scoringType?: SCORE_TYPE, roomGame?: RoomGame, isCreatorParticipating?: boolean): Promise<Room> => {
        return await axiosCaller({
            path: '/api/room/updateRoom',
            method: 'post',
            passToken: true,
            responseDataKey: 'room',
            responseValidatorObj: RoomObj,
            data: { roomId, scoringType, roomGame, isCreatorParticipating },
        });
    },
    finishRoomGame: async (roomId: string): Promise<Room> => {
        return await axiosCaller({
            path: '/api/room/finishRoomGame',
            method: 'post',
            passToken: true,
            responseDataKey: 'room',
            responseValidatorObj: RoomObj,
            data: { roomId },
        });
    },
    deleteRoom: async (roomId: string): Promise<void> => {
        return await axiosCaller({
            path: '/api/room/deleteRoom',
            method: 'post',
            passToken: true,
            data: { roomId },
        });
    },
    addPlayerScoreToLeaderboard: async (roomId: string, score: number): Promise<Room> => {
        return await axiosCaller({
            path: '/api/room/addPlayerScoreToLeaderboard',
            method: 'post',
            passToken: true,
            responseDataKey: 'room',
            responseValidatorObj: RoomObj,
            data: { roomId, score },
        });
    },
};

export { roomApiCalls };
