import { MB_Button } from '@mightybyte/rnw.components.button';
import { MB_LinearGradient } from '@mightybyte/rnw.components.linear-gradient';
import { mbShowToast } from '@mightybyte/rnw.components.toast';
import { mbPlatformStyle, mbShadow, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import Clipboard from '@react-native-clipboard/clipboard';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AppState, FlatList, ListRenderItemInfo, NativeEventSubscription, Platform, Share, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { COLORS } from '../../../constants/colors';
import { textStyles } from '../../../constants/textStyles';
import { RoomLobbyProps } from '../../../typesAndInterfaces/componentProps';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import { MobileHeaderProps } from '../../helperComponents/Header/MobileHeader';
import { MB_utilHooks } from '@mightybyte/rnw.utils.util-hooks';
import { SelectCategoriesModal } from './Components/SelectCategoriesModal';
import { Player, ROOM_EVENT_NAME, ROOM_STATUS, RoomGame, SCORE_TYPE, scoringTypeToString } from '../../../typesAndInterfaces/typesAndInterfaces';
import { utils } from '../../../utils/utils';
import { MB_Image } from '@mightybyte/rnw.components.image';
import { gameTypeToImage } from '../../helperComponents/GameCard';
import { useDeleteRoom, useGetRoom, useStartRoomGame, useUpdateRoom } from '../../../hooks/roomHooks';
import { useGetCurrentUserData } from '../../../hooks/userHooks';
import { CONNECTION_STATE, useRTCommContext } from '../../../context/RTCommContextProvider';
import { LoadingPage } from '../../helperComponents/LoadingPage';
import { mbShowPopUp } from '@mightybyte/rnw.components.pop-up';
import { utilHooks } from '../../../hooks/utilHooks';
import { ReadyModal } from './Components/ReadyModal';
import { MB_ToggleSwitch } from '../../../mightyByteLibraries/MB_ToggleSwitch';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';

const RoomLobby = ({ route, navigation }: RoomLobbyProps) => {

    const { roomId } = route.params;

    const { isLoading: isGetRoomLoading, data: room, refetch, error } = useGetRoom({ roomId });
    const { mutate: startRoomGame, isLoading: isGameStarting } = useStartRoomGame();
    const { mutate: updateRoom, isLoading: isUpdatingRoom } = useUpdateRoom();
    const { mutate: deleteRoom } = useDeleteRoom();

    const { data: currentUserData } = useGetCurrentUserData();
    const { connect, isSubscribedToChannel, subscribe, unsubscribe, isConnected, isInternetReachable, connectionStatus, players } = useRTCommContext();

    const channelName = `presence-${roomId}`;
    const isSubscribed = isSubscribedToChannel(channelName);

    const goBack = useCallback(async (errorInfo?: { title: string, message: string }) => {
        if (isInternetReachable && room && currentUserData?._id === room.creatorUserId) {
            deleteRoom({ roomId: room._id });
        }
        unsubscribe(channelName, { disconnect: true });
        navigation.navigate('RoomCreateOrJoin');
        if (errorInfo) {
            mbShowPopUp({
                title: errorInfo.title,
                message: errorInfo.message,
            });
        }
    }, [channelName, currentUserData?._id, deleteRoom, isInternetReachable, navigation, room, unsubscribe]);

    utilHooks.useAndroidBackButton(goBack);

    const mobileHeaderOptions = useMemo<MobileHeaderProps>(() => ({
        showHeader: true,
        showBackArrow: true,
        backArrowCustomAction: goBack,
        pageTitle: room?.name,
    }), [goBack, room?.name]);

    const [isGameSelectorVisible, showGameSelector, hideGameSelector] = MB_utilHooks.useBool(false);
    const [isReadyModalVisible, showIsReadyModal, hideIsReadyModal] = MB_utilHooks.useBool(false);
    const [scoringType, setScoringType] = useState<SCORE_TYPE>(room?.scoringType ?? SCORE_TYPE.TIME);
    const [roomGame, setRoomGame] = useState<RoomGame | undefined>(room?.gameType ? { gameType: room.gameType, categories: room.categories } : undefined);
    const [isCreatorParticipating, setIsCreatorParticipating] = useState(room?.isCreatorParticipating ?? true);
    const [isGameStarted, setGameAsStarted] = MB_utilHooks.useBool(false);

    useEffect(() => {
        if (room) {
            setScoringType(room.scoringType);
            setRoomGame(room?.gameType ? { gameType: room.gameType, categories: room.categories } : undefined);
        }
    }, [room]);

    const keyExtractor = useCallback((player: { userId: string }) => player.userId, []);

    const RenderPlayerComponent = useCallback(({ item, index }: ListRenderItemInfo<Player>) => {
        return (
            <View style={styles.playerTextContainer}>
                <Text style={styles.playerNumberText}>{index + 1}</Text>
                {item.userId === room?.creatorUserId &&
                    <View style={styles.creatorContainer}>
                        <Text style={styles.creatorText}>CREATOR</Text>
                    </View>
                }
                <Text style={styles.playerNameText} numberOfLines={1}>{item.name}</Text>
            </View>
        );
    }, [room?.creatorUserId]);

    const onCopyCode = useCallback(() => {
        if (room) {
            Clipboard.setString(room.code);
            mbShowToast({ type: 'success', text1: 'Copied to clipboard', text2: '' });
        } else {
            mbShowToast({ type: 'error', text1: 'Failed to copy the code' });
        }
    }, [room]);

    const onShareCode = useCallback(() => {
        if (room) {
            Share.share({
                message: `Join my game room with code: ${room.code}`,
            });
        } else {
            mbShowToast({ type: 'error', text1: 'Failed to copy the code' });
        }
    }, [room]);

    const onRoomGameChange = useCallback((newRoomGame: RoomGame) => {
        setRoomGame(newRoomGame);
        updateRoom({ roomId, scoringType, roomGame: newRoomGame, isCreatorParticipating });
    }, [updateRoom, roomId, scoringType, isCreatorParticipating]);

    const onScoringTypeChange = useCallback((newScoringType: SCORE_TYPE) => {
        setScoringType(newScoringType);
        updateRoom({ roomId, scoringType: newScoringType, roomGame, isCreatorParticipating });
    }, [updateRoom, roomId, roomGame, isCreatorParticipating]);

    const onIsCreatorParticipatingChange = useCallback((newIsCreatorParticipating) => {
        setIsCreatorParticipating(newIsCreatorParticipating);
        updateRoom({ roomId, scoringType, roomGame, isCreatorParticipating: newIsCreatorParticipating });
    }, [roomGame, roomId, scoringType, updateRoom]);

    const startGame = useCallback(() => {
        if (roomGame && scoringType) {
            startRoomGame({ roomId, scoringType, roomGame, isCreatorParticipating }, {
                onSuccess: setGameAsStarted,
            });
        }
    }, [isCreatorParticipating, roomGame, roomId, scoringType, setGameAsStarted, startRoomGame]);

    const onGameStart = useCallback((gameIsStarted?: boolean) => {
        if (room?.status === ROOM_STATUS.IN_PROGRESS || gameIsStarted) {
            const playerInLeaderboard = room?.leaderboard.find(player => player.userId === currentUserData?._id);
            const isCreatorParticipatingToGame = room?.status === ROOM_STATUS.IN_PROGRESS ? room.isCreatorParticipating : isCreatorParticipating;
            if (playerInLeaderboard || (!isCreatorParticipatingToGame && currentUserData?._id === room?.creatorUserId)) {
                navigation.replace('RoomEndGame', { roomId });
            } else if (roomGame) {
                navigation.replace('GameScreen', {
                    categoryIds: roomGame.categories,
                    index: 0,
                    roomParams: {
                        roomId,
                        channelName,
                        scoringType,
                        score: 0,
                    },
                });
            }
        }
    }, [channelName, currentUserData?._id, isCreatorParticipating, navigation, room, roomGame, roomId, scoringType]);

    const handleConnection = useCallback(async () => {
        const isDisconnected = connectionStatus === CONNECTION_STATE.DISCONNECTED || connectionStatus === CONNECTION_STATE.DISCONNECTING;
        const appState = AppState.currentState;
        if (error?.errorCode === 'NOT_FOUND') {
            goBack({ title: 'Room Deleted', message: 'the creator of the room close the room!' });
        } else if (isInternetReachable && isDisconnected && (Platform.OS === 'ios' ? appState === 'active' : true)) {
            connect();
        } else if (!isInternetReachable) {
            goBack({ title: 'Internet Error', message: 'Please make sure you have internet connection before joining/creating the room' });
        }
    }, [connect, connectionStatus, error?.errorCode, goBack, isInternetReachable]);

    const handleSubscribtion = useCallback(() => {
        if (isConnected && !isSubscribed) {
            subscribe(channelName, {
                onSubscriptionSucceeded: onGameStart,
                onSubscriptionError: (message) => console.log('SubscriptionError:', message),
                onEvent: ({ eventName, data }) => {
                    if (eventName === ROOM_EVENT_NAME.ROOM_UPDATED) {
                        setRoomGame(data.roomGame);
                        setScoringType(data.scoringType);
                        setIsCreatorParticipating(data.isCreatorParticipating);
                    } else if (eventName === ROOM_EVENT_NAME.GAME_STARTED) {
                        setScoringType(data.scoringType);
                        setRoomGame(data.roomGame);
                        showIsReadyModal();
                    } else if (eventName === ROOM_EVENT_NAME.ROOM_DELETED) {
                        goBack({ title: 'Room Deleted', message: 'the creator of the room close the room!' });
                    }
                },
            });
        }
    }, [channelName, goBack, isConnected, isSubscribed, onGameStart, showIsReadyModal, subscribe]);

    const handleAppStateListener = useCallback(() => {
        return AppState.addEventListener('change', (state) => state === 'active' && refetch());
    }, [refetch]);

    useEffect(() => {
        let appStateListener: NativeEventSubscription | undefined;
        if (Platform.OS !== 'web') {
            appStateListener = handleAppStateListener();
        }
        handleConnection();
        handleSubscribtion();

        return () => {
            if (Platform.OS !== 'web' && appStateListener) {
                appStateListener.remove();
            }
        };
    }, [handleAppStateListener, handleConnection, handleSubscribtion]);

    const scoringString = useMemo(() => {
        if (room?.creatorUserId !== currentUserData?._id) {
            return scoringTypeToString[scoringType];
        }
        return '';
    }, [currentUserData?._id, room?.creatorUserId, scoringType]);

    const members = useMemo(() => {
        const creator = isCreatorParticipating ? players.find(player => player.userId === room?.creatorUserId) : undefined;
        const playersFiltered = players.filter(player => player.userId !== room?.creatorUserId);

        return creator === undefined ? playersFiltered : [creator, ...playersFiltered];
    }, [isCreatorParticipating, players, room?.creatorUserId]);

    if (isGetRoomLoading || !isConnected || !isSubscribed) {
        return (
            <LoadingPage giveUpAction={goBack} debugVariables={{ isGetRoomLoading, isConnected, isSubscribed, connectionStatus }} />
        );
    }

    return (
        <ComponentWrapper
            containerStyle={styles.container}
            mobileHeaderOptions={mobileHeaderOptions}
            keyboardShouldPersistTapsForScrollView="always"
            wrapInScrollView={false}
            onBackPress={() => goBack()}
        >
            <ReadyModal
                isVisible={isReadyModalVisible}
                onDismiss={hideIsReadyModal}
                onModalHide={() => onGameStart(true)}
            />
            <SelectCategoriesModal
                isVisible={isGameSelectorVisible}
                onDismiss={hideGameSelector}
                onRoomGameChange={onRoomGameChange}
                gameType={roomGame?.gameType}
            />
            <View style={styles.content}>
                {Platform.OS === 'web' &&
                    <Text style={styles.roomName}>{room?.name}</Text>
                }
                <Text style={styles.codeNote}>{isMobileApp ? 'Copy or share room code:' : 'Copy room code'}</Text>
                <View style={styles.codeContainer}>
                    <Text style={styles.code}>{room?.code}</Text>
                    <TouchableOpacity
                        style={styles.codeButtons}
                        onPress={onCopyCode}
                    >
                        <MaterialCommunityIcons name="content-copy" size={18} color={COLORS.white} />
                    </TouchableOpacity>
                    {isMobileApp &&
                        <TouchableOpacity
                            style={[styles.codeButtons, { marginStart: 10 }]}
                            onPress={onShareCode}
                        >
                            <MaterialCommunityIcons name="share-variant-outline" size={18} color={COLORS.white} />
                        </TouchableOpacity>
                    }
                </View>
                {room?.creatorUserId === currentUserData?._id &&
                    <View style={styles.participationContainer}>
                        <Text style={styles.participatingText}>Will you be participating in the game?</Text>
                        <MB_ToggleSwitch
                            isDisabled={isUpdatingRoom}
                            isToggled={isCreatorParticipating}
                            onToggle={onIsCreatorParticipatingChange}
                            trackbarStyleOn={[styles.trackbar, { backgroundColor: isCreatorParticipating ? COLORS.buttonPurple : COLORS.white }]}
                            thumbButton={styles.thumbButton}
                        />
                    </View>
                }
                <View style={[styles.scoring, room?.creatorUserId === currentUserData?._id && { justifyContent: 'flex-end' }]}>
                    <Text style={textStyles.smallText}>Scoring Type: {scoringString}</Text>
                    {room?.creatorUserId === currentUserData?._id &&
                        <View style={[styles.scoringSelector, isUpdatingRoom && { opacity: 0.6 }]} pointerEvents={isUpdatingRoom ? 'none' : undefined}>
                            <TouchableOpacity
                                style={[styles.scoringButton, scoringType === SCORE_TYPE.TIME && { backgroundColor: COLORS.backgroundPurple }]}
                                onPress={() => onScoringTypeChange(SCORE_TYPE.TIME)}
                                disabled={scoringType === SCORE_TYPE.TIME}
                            >
                                <Text style={textStyles.smallText}>Time</Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                                style={[styles.scoringButton, scoringType === SCORE_TYPE.ACCURACY && { backgroundColor: COLORS.backgroundPurple }]}
                                onPress={() => onScoringTypeChange(SCORE_TYPE.ACCURACY)}
                                disabled={scoringType === SCORE_TYPE.ACCURACY}
                            >
                                <Text style={textStyles.smallText}>Accuracy</Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                                style={[styles.scoringButton, scoringType === SCORE_TYPE.CHALLENGE_MODE && { backgroundColor: COLORS.backgroundPurple }]}
                                onPress={() => onScoringTypeChange(SCORE_TYPE.CHALLENGE_MODE)}
                                disabled={scoringType === SCORE_TYPE.CHALLENGE_MODE}
                            >
                                <Text style={textStyles.smallText}>Challenge Mode</Text>
                            </TouchableOpacity>
                        </View>
                    }
                </View>
                {!roomGame && (currentUserData?._id === room?.creatorUserId ?
                    <TouchableOpacity
                        style={styles.gameModeSelectGameContainer}
                        onPress={showGameSelector}
                        disabled={currentUserData?._id !== room?.creatorUserId}
                    >
                        <View style={styles.selectGameButtonTextContainer}>
                            <Text style={styles.selectGameButtonText}>Select a game</Text>
                        </View>
                    </TouchableOpacity>
                    :
                    <View
                        style={styles.gameModeSelectGameContainer}
                    >
                        <Text style={styles.selectGameButtonText}>waiting for the host</Text>
                    </View>
                )}
                {roomGame &&
                    <View style={styles.cardContainer}>
                        <View style={styles.card}>
                            <View style={styles.cardLeftSection}>
                                <Text style={styles.cardTitle}>{utils.gameTypeToString(roomGame.gameType)}</Text>
                                <Text style={styles.cardCategories}>{roomGame.categories.length} Categories</Text>
                                {currentUserData?._id === room?.creatorUserId &&
                                    <MB_Button
                                        title="Change"
                                        style={styles.changeBtn}
                                        textStyle={styles.changeText}
                                        onPress={showGameSelector}
                                    />
                                }
                            </View>
                            <View style={styles.cardRightSection}>
                                <MB_Image
                                    source={gameTypeToImage(roomGame.gameType)}
                                    resizeMode="contain"
                                    style={styles.cardCategoryImage}
                                />
                            </View>
                        </View>
                    </View>
                }
                <View style={styles.playerContainer}>
                    <MB_LinearGradient
                        colors={[COLORS.purpleTrasparent, COLORS.greenTrasparent]}
                        start={{ x: 0, y: 0 }}
                        end={{ x: 1, y: 0 }}
                        style={styles.playerTableHeaderContainer}
                    >
                        <Text style={[styles.playerHeaderText, { paddingRight: 16 }]}>#</Text>
                        <Text style={[styles.playerHeaderText, { flex: 1 }]}>Player</Text>
                        <Text style={styles.playerCountHeaderText}>{members.length}</Text>
                    </MB_LinearGradient>
                    <FlatList
                        keyExtractor={keyExtractor}
                        data={members}
                        renderItem={RenderPlayerComponent}
                        style={styles.playerFlatList}
                    />
                </View>
                {currentUserData?._id === room?.creatorUserId &&
                    <View style={styles.footer}>
                        <MB_Button
                            title={currentUserData?._id === room?.creatorUserId ? (members.length <= 1 ? 'waiting for other players' : isGameStarted ? 'Game will start soon' : 'Start game') : 'waiting for the host'}
                            style={styles.startGameButton}
                            textStyle={styles.startGameText}
                            loading={isGameStarting}
                            disabled={!roomGame || isGameStarting || members.length <= 1 || isGameStarted}
                            onPress={startGame}
                        />
                    </View>
                }
            </View>
        </ComponentWrapper>
    );
};

export { RoomLobby };

const styles = StyleSheet.create({
    container: {
        ...mbPlatformStyle({
            web: {
                minWidth: 1000,
            },
        }),
    },
    content: {
        ...mbPlatformStyle({
            web: {
                width: 400,
                alignSelf: 'center',
            },
            mobile: {
                flex: 1,
            },
        }),
    },
    participationContainer: {
        marginTop: 20,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    participatingText: mbTextStyles([textStyles.smallerText, {
        textAlign: 'left',
    }]),
    roomName: mbTextStyles([
        textStyles.largestText, {
            fontSize: 26,
        },
    ]),
    trackbar: {
        height: 30,
        width: 50,
        borderRadius: 30,
    },
    thumbButton: {
        backgroundColor: '#C2C2C2',
    },
    codeNote: mbTextStyles([
        textStyles.smallerText,
    ]),
    codeContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 15,
        backgroundColor: '#5f487c61',
        alignSelf: 'center',
        paddingVertical: 10,
        paddingHorizontal: 15,
        borderRadius: 7,
    },
    header: mbTextStyles([
        textStyles.largerText,
    ]),
    code: mbTextStyles([textStyles.smallText, {
        marginRight: 10,
    }]),
    codeButtons: {
        borderRadius: 4,
    },
    gameModeSelectGameContainer: {
        marginTop: 16,
        borderColor: COLORS.backgroundPurple,
        borderWidth: 2,
        borderRadius: 15,
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        height: 128,
        backgroundColor: '#ffffff05',
    },
    gameModeSelectedContainer: {
        overflow: 'visible',
    },
    gameModeButton: {
        backgroundColor: COLORS.backgroundPurple,
        borderRadius: 10,
    },
    selectGameButtonTextContainer: {
        backgroundColor: COLORS.buttonPurple,
        borderRadius: 10,
        width: 150,
        paddingVertical: 12,
    },
    selectGameButtonText: mbTextStyles([
        textStyles.normalText, {
            paddingHorizontal: 6,
        },
    ]),
    startGameButton: {
        alignSelf: 'stretch',
        backgroundColor: COLORS.buttonPurple,
        ...mbPlatformStyle({
            web: {
                ...mbShadow({
                    color: COLORS.black,
                    offsetWidth: 0,
                    offsetHeight: 0,
                    opacity: 0.25,
                    radius: 15,
                    elevation: 4,
                }),
            },
        }),
    },
    startGameText: mbTextStyles([
        textStyles.smallText, {
        },
    ]),
    playerTableHeaderContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 8,
        borderTopStartRadius: 8,
        borderTopEndRadius: 8,
        marginTop: 24,
    },
    playerHeaderText: mbTextStyles([
        textStyles.normalText, {
            textAlign: 'left',
        },
    ]),
    playerCountHeaderText: mbTextStyles([
        textStyles.largestText, {
            fontSize: 16,
            textAlign: 'left',
            color: COLORS.buttonPurple,
        },
    ]),
    playerFlatList: {
        marginVertical: 12,
    },
    playerTextContainer: {
        flexDirection: 'row',
        marginVertical: 8,
    },
    playerNumberText: mbTextStyles([
        textStyles.normalText, {
            width: 20,
            marginStart: 4,
            marginEnd: 8,
        },
    ]),
    playerNameText: mbTextStyles([
        textStyles.normalText, {
            textAlign: 'left',
            flex: 1,
        },
    ]),
    creatorContainer: {
        backgroundColor: COLORS.shadowGreenTrasparent,
        borderRadius: 4,
        marginEnd: 8,
    },
    creatorText: mbTextStyles([
        textStyles.largestText, {
            fontSize: 14,
            color: COLORS.white,
            alignSelf: 'center',
            paddingHorizontal: 8,
            paddingVertical: 2,
        },
    ]),
    image: {
        position: 'absolute',
        top: -30,
        right: 5,
        width: 140,
        height: 90,
        overflow: 'visible',
    },
    gameModeText: mbTextStyles([
        textStyles.largeText, {
            textAlign: 'left',
        },
    ]),
    gameCardContainer: {
        marginTop: 32,
    },
    changeGameModeText: mbTextStyles([
        textStyles.normalText, {
        },
    ]),
    changeGameModeTextContainer: {
        paddingHorizontal: 8,
        paddingVertical: 4,
        width: 100,
        borderWidth: 2,
        borderColor: COLORS.white,
        borderRadius: 8,
        ...mbShadow({
            color: COLORS.white,
            offsetWidth: 0,
            offsetHeight: 0,
            opacity: 1,
            radius: 8,
            elevation: 1,
        }),
    },
    selectedGameText: mbTextStyles([
        textStyles.largeText, {
            textAlign: 'left',
            marginTop: 12,
        },
    ]),
    gradientContainer: {
        padding: 24,
        borderRadius: 27,
        marginTop: 8,
    },
    playerContainer: {
        ...mbPlatformStyle({
            web: {
                height: 200,
            },
            mobile: {
                flex: 1,
            },
        }),
    },
    loadingText: mbTextStyles([
        textStyles.smallText, {
            color: COLORS.white,
            marginBottom: 32,
        },
    ]),
    loadingIndicator: {
        marginTop: 32,
    },
    footer: {
        paddingVertical: 16,
        marginHorizontal: -16,
        paddingHorizontal: 30,
        ...mbPlatformStyle({
            mobile: {
                backgroundColor: COLORS.backgroundDarkPurple,
                ...mbShadow({
                    color: COLORS.black,
                    offsetWidth: 0,
                    offsetHeight: -16,
                    opacity: 0.25,
                    radius: 4,
                    elevation: 4,
                }),
            },
        }),
    },
    cardContainer: {
        marginTop: 18,
        borderRadius: 10,
        padding: 12,
        backgroundColor: '#4C336B',
    },
    card: {
        backgroundColor: '#9296CF',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderRadius: 10,
    },
    cardLeftSection: {
        paddingLeft: 20,
        paddingTop: 20,
        paddingBottom: 15,
    },
    cardRightSection: {
        paddingTop: 10,
        paddingRight: 5,
    },
    changeText: mbTextStyles([textStyles.smallerText, {
        color: COLORS.black,
    }]),
    cardTitle: mbTextStyles([textStyles.normalText, {
        textAlign: 'left',
        fontSize: 15,
        fontWeight: '600',
        color: COLORS.black,
    }]),
    cardCategories: mbTextStyles([textStyles.smallText, {
        textAlign: 'left',
        color: '#1D183B',
        marginTop: 19,
    }]),
    changeBtn: {
        backgroundColor: 'transparent',
        height: 30,
        borderWidth: 1,
        borderRadius: 8,
        borderColor: COLORS.black,
        paddingVertical: 0,
        marginTop: 19,
        alignSelf: 'flex-start',
    },
    cardCategoryImage: {
        width: 137,
        height: 93,
    },
    scoring: {
        alignItems: 'center',
        marginTop: 10,
    },
    scoringSelector: {
        borderColor: COLORS.backgroundPurple,
        borderWidth: 1,
        borderRadius: 10,
        flexDirection: 'row',
        overflow: 'hidden',
        marginStart: 6,
        marginTop: 10,
    },
    scoringButton: {
        backgroundColor: 'transparent',
        height: 40,
        justifyContent: 'center',
        paddingHorizontal: 10,
    },
});

