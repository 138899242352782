import React from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { GameNavigator, GameNavigatorParamList } from '../GameNavigator';
import { MyAccount } from '../../components/screens/MyAccount/MyAccount';
import { homeNavigatorOptions } from './homeNavigatorOptions';
import { NavigatorScreenParams } from '@react-navigation/core';
import { RoomsNavigator, RoomsNavigatorParamList } from '../RoomsNavigator/RoomsNavigator';
import { isMobile } from '@mightybyte/rnw.utils.device-info';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { ExtrasNavigator, ExtrasNavigatorParamList } from '../../components/screens/Extras/ExtrasNavigator';

export type HomeNavigatorParamList = {
    GameNavigator: NavigatorScreenParams<GameNavigatorParamList>,
    RoomsNavigator: NavigatorScreenParams<RoomsNavigatorParamList>
    ExtrasNavigator: NavigatorScreenParams<ExtrasNavigatorParamList>,
    MyAccount: { showDeleteModal?: boolean },
};

const Tab = isMobile ? createBottomTabNavigator<HomeNavigatorParamList>() : createNativeStackNavigator<HomeNavigatorParamList>();

const HomeNavigator = () => {
    return (
        <Tab.Navigator
            screenOptions={{
                headerShown: false,
            }}
        >
            <Tab.Screen name="GameNavigator" component={GameNavigator} options={homeNavigatorOptions} />
            <Tab.Screen name="RoomsNavigator" component={RoomsNavigator} options={homeNavigatorOptions} />
            <Tab.Screen name="ExtrasNavigator" component={ExtrasNavigator} options={homeNavigatorOptions} />
            <Tab.Screen name="MyAccount" component={MyAccount} options={homeNavigatorOptions} />
        </Tab.Navigator>
    );
};

export { HomeNavigator };
