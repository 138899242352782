import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { StyleSheet, Text, View, useWindowDimensions } from 'react-native';
import { textStyles } from '../../../../constants/textStyles';
import { AdminCategoriesScreenProps } from '../../../../typesAndInterfaces/componentProps';
import { useMBInfiniteFetch } from '@mightybyte/rnw.utils.util-hooks';
import { useInfiniteCategories, useReorderCategories } from '../../../../hooks/adminHooks';
import { Category } from '../../../../typesAndInterfaces/typesAndInterfaces';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { GridContextProvider, GridDropZone, GridScrollContainer, swap } from '../../../../../externalLibraries/react-grid-dnd';
import { MB_OnHoverWrapper } from '@mightybyte/rnw.components.on-hover-wrapper';
import { DragIcon } from '../../../../resources/svgComponents/DragIcon';
import { AdminCreateOrEditHeader } from './Utils/Header';
import isEqual from 'lodash/isEqual';
import differenceWith from 'lodash/differenceWith';
import { COLORS } from '../../../../constants/colors';
import { mbShowPopUp } from '@mightybyte/rnw.components.pop-up';
import { STRING_CONSTANTS } from '../../../../constants/constants';
import { PacmanIndicator } from '@mightybyte/rnw.components.activity-indicators';

type CategoryInfo = {
    categoryId: string;
    order: number;
}

const getCategoryInfoArray = (array: (string | Category)[]): CategoryInfo[] => {
    return array.map((cat, i) => ({ categoryId: typeof cat === 'string' ? cat : cat._id, order: i }));
};

const scrollStyle = {
    scrollBehavior: 'smooth',
    overflowY: 'scroll',
} as const;

const LoadingIndicator = () => {
    return (
        <View style={styles.loadingIndicator}>
            <PacmanIndicator color={COLORS.white} />
            <Text style={[textStyles.smallText, { color: COLORS.white }]} >Loading...</Text>
        </View>
    );
};

const Reorder = ({ route, navigation }: AdminCategoriesScreenProps<'Reorder'>) => {

    const { gameType } = route.params;
    const { height } = useWindowDimensions();

    const getHomeCategoriesHook = useInfiniteCategories({ gameType, totalItemsPerPage: 10 });
    const { data, isLoading, isFetching, loadMore } = useMBInfiniteFetch(getHomeCategoriesHook, { prefillWhileLoading: true });
    const { mutate: reorderCategories, isLoading: isReordering } = useReorderCategories({ gameType });

    const [categories, setCategories] = useState(data);

    const changedCategories = differenceWith(getCategoryInfoArray(categories), getCategoryInfoArray(data), isEqual);

    useEffect(() => {
        setCategories(oldCategories => {
            if (oldCategories.length === 0 || typeof oldCategories[0] === 'string') {
                return [...data];
            }
            return [...oldCategories, ...data.slice(oldCategories.length)];
        });
    }, [data]);

    const onGoBack = useCallback(() => {
        // TODO: Might be worth fixing, we were having issues determining if we should go back or reset. The issue is that canGoBack always returns true and takes back to users tab
        const navState = navigation.getState();
        if (navigation.canGoBack() && navState.routes[Math.max(navState.index - 1, 0)].name === 'Categories') {
            navigation.goBack();
        } else {
            navigation.reset({ index: 0, routes: [{ name: 'Categories', params: { gameType } }] });
        }
    }, [gameType, navigation]);

    const onSave = useCallback(() => {
        reorderCategories({ categoriesOrder: changedCategories }, {
            onError: () => {
                mbShowPopUp({
                    title: STRING_CONSTANTS.ERROR,
                    message: STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN,
                });
            },
        });
    }, [changedCategories, reorderCategories]);

    const keyExtractor = React.useCallback((item: Category | string) => typeof item === 'string' ? item : item._id, []);

    const renderItem = React.useCallback(({ item, isDragging }: { item: Category | string, isDragging: boolean }) => {

        const title = typeof item === 'string' ? 'Loading' : item.name;
        const _id = typeof item === 'string' ? 'Loading' : item._id;
        const index = data.findIndex(elem => typeof elem === 'string' ? false : elem._id === _id);

        return (
            <MB_OnHoverWrapper>
                {({ hovered }) => (
                    <View style={[styles.categoryItem, !isLoading && (isDragging || hovered) && styles.categoryItemDrag]}>
                        {(!isLoading && hovered) && <DragIcon style={styles.dragIcon} />}
                        <Text numberOfLines={1} style={styles.categoryItemText}>{isLoading ? 'Loading' : `${index + 1} - ${title}`}</Text>
                    </View>
                )}
            </MB_OnHoverWrapper>
        );
    }, [data, isLoading]);

    const onChange = useCallback((_sourceId, sourceIndex: number, targetIndex: number) => {
        const nextState = swap(categories, sourceIndex, targetIndex);
        setCategories(nextState);
    }, [categories]);

    const gridStyle = useMemo(() => StyleSheet.flatten([styles.gridStyle, { height: Math.ceil(categories.length / 3) * 64 }]), [categories.length]);
    const containerStyle = useMemo(() => StyleSheet.flatten([styles.container, { height }]), [height]);

    return (
        <GridScrollContainer style={{...containerStyle, ...scrollStyle}}>
            <AdminCreateOrEditHeader
                title="Reorder"
                disableToggle
                rightButtonTitle="Save"
                rightButtonDisabled={changedCategories.length === 0 || isReordering || isFetching}
                rightButtonIsLoading={isReordering}
                onRightButtonPress={onSave}
                leftButtonTitle="Reset"
                leftButtonDisabled={changedCategories.length === 0 || isReordering || isFetching}
                onLeftButtonPress={() => setCategories([...data])}
                onBackPress={onGoBack}
            />
            <View style={styles.body}>
                <GridContextProvider onChange={onChange}>
                    <GridDropZone
                        id="categories"
                        data={categories}
                        renderItem={renderItem}
                        keyExtractor={keyExtractor}
                        boxesPerRow={3}
                        rowHeight={64}
                        style={gridStyle}
                        disableDrag={isLoading}
                        disableDrop={isLoading}
                        scrollOffset={20}
                        loadMore={loadMore}
                    />
                </GridContextProvider>
                {isFetching && !isLoading && <LoadingIndicator />}
            </View>
        </GridScrollContainer>
    );
};

export { Reorder };

const styles = StyleSheet.create({
    container: {
        minWidth: 800,
        paddingBottom: 40,
        backgroundColor: COLORS.backgroundDarkPurple,
        paddingLeft: 40,
        paddingRight: 40,
    },
    body: {
        marginTop: 18,
    },
    gridStyle: {
        borderRadius: 8,
        overflow: 'hidden',
    },
    categoryItem: {
        height: 64,
        width: '100%',
        backgroundColor: '#412366',
        alignItems: 'flex-start',
        justifyContent: 'center',
        paddingLeft: 30,
        paddingRight: 8,
    },
    dragIcon: {
        position: 'absolute',
        left: 8,
        top: 0,
        bottom: 0,
        justifyContent: 'center',
    },
    categoryItemDrag: {
        backgroundColor: '#782ABE',
    },
    categoryItemText: mbTextStyles([textStyles.smallText, {
        color: '#E9CEFF',
        textAlign: 'left',
    }]),
    loadingIndicator: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        minWidth: 100,
    },
});
