import React, { useCallback, useEffect, useMemo } from 'react';
import { useGetRoom } from '../../../../hooks/roomHooks';
import { ROOM_STATUS } from '../../../../typesAndInterfaces/typesAndInterfaces';
import { ComponentWrapper } from '../../../helperComponents/componentWrapper/ComponentWrapper';
import { RoomLeaderboard } from './RoomLeaderboard';
import { CONNECTION_STATE, useRTCommContext } from '../../../../context/RTCommContextProvider';
import { MobileHeaderProps } from '../../../helperComponents/Header/MobileHeader';
import { RoomEndGameProps } from '../../../../typesAndInterfaces/componentProps';
import { RoomWaiting } from './RoomWaiting';
import { LoadingPage } from '../../../helperComponents/LoadingPage';
import { ReConnectModal } from '../../Game/GameScreenModals';
import { AppState, NativeEventSubscription, Platform, StyleSheet, View } from 'react-native';
import { mbPlatformStyle } from '@mightybyte/rnw.utils.style-utils';

const RoomEndGame = ({ route, navigation }: RoomEndGameProps) => {

    const { roomId } = route.params;

    const { data: room, refetch, isFetching: isFetchingRoom } = useGetRoom({ roomId });

    const { isConnected, connectionStatus, players, connect, unsubscribe, isSubscribedToChannel, isGameEnded, leaderboard } = useRTCommContext();

    const channelName = `presence-${roomId}`;

    const goBack = useCallback(async () => {
        await unsubscribe(channelName, { disconnect: true });
        navigation.popToTop();
    }, [channelName, navigation, unsubscribe]);

    const mobileHeaderOptions = useMemo<MobileHeaderProps>(() => ({
        pageTitle: room?.status === ROOM_STATUS.IN_PROGRESS ? room?.name : undefined,
        showHeader: room?.status === ROOM_STATUS.IN_PROGRESS && !isGameEnded,
    }), [isGameEnded, room?.name, room?.status]);

    useEffect(() => {
        if (room?.status === ROOM_STATUS.ENDED) {
            unsubscribe(channelName, { disconnect: true });
        }
    }, [channelName, isGameEnded, room?.status, unsubscribe]);

    useEffect(() => {
        if (isConnected) {
            // refetch the room in case the creator finish the game before the player rejoin
            refetch();
        }
    }, [isConnected, refetch]);

    const handleAppStateListener = useCallback(() => {
        return AppState.addEventListener('change', (state) => state === 'active' && refetch());
    }, [refetch]);

    useEffect(() => {
        let appStateListener: NativeEventSubscription | undefined;

        if (Platform.OS !== 'web') {
            appStateListener = handleAppStateListener();
        }

        return () => {
            if (Platform.OS !== 'web' && appStateListener) {
                appStateListener.remove();
            }
        };
    }, [handleAppStateListener]);

    if (!room || isFetchingRoom) {
        return (
            <LoadingPage giveUpAction={goBack} />
        );
    }

    return (
        <ComponentWrapper
            mobileHeaderOptions={mobileHeaderOptions}
            keyboardShouldPersistTapsForScrollView="always"
            wrapInScrollView={false}
            onBackPress={() => navigation.navigate('RoomCreateOrJoin')}
        >
            <ReConnectModal
                isVisible={isSubscribedToChannel(channelName) && !isConnected && room?.status === ROOM_STATUS.IN_PROGRESS}
                onGiveUp={goBack}
                onReconnect={connect}
                isConnecting={connectionStatus === CONNECTION_STATE.CONNECTING || connectionStatus === CONNECTION_STATE.RECONNECTING || isFetchingRoom}
            />
            <View style={styles.content}>
                {(room.status === ROOM_STATUS.IN_PROGRESS && !isGameEnded) &&
                    <RoomWaiting
                        room={room}
                        leaderboard={leaderboard}
                        players={players}
                        onGoBack={goBack}
                    />
                }
                {(room.status === ROOM_STATUS.ENDED || isGameEnded) &&
                    <RoomLeaderboard
                        room={room}
                        leaderboard={leaderboard}
                        onClose={goBack}
                    />
                }
            </View>
        </ComponentWrapper>
    );
};

export { RoomEndGame };

const styles = StyleSheet.create({
    content: {
        ...mbPlatformStyle({
            web: {
                width: 400,
                alignSelf: 'center',
                paddingBottom: 20,
            },
            mobile: {
                flex: 1,
            },
        }),
    },
});

