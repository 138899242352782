import { VersionObj } from '../utils/zod/zodObjects';
import { axiosCaller } from './customAxios';
import { z } from 'zod';

const utilApiCalls = {
    getVersion: async (): Promise<{ version: string }> => {
        return await axiosCaller({
            path: '/api/util/getVersion',
            method: 'get',
            responseFormatValidatorMessage: 'Data mismatch for version data response',
            responseValidatorObj: VersionObj,
        });
    },

    getMinimumMobileJSVersion: async (): Promise<string> => {
        return await axiosCaller({
            path: '/api/util/getMinimumMobileJSVersion',
            method: 'get',
            responseDataKey: 'version',
            responseFormatValidatorMessage: 'Data mismatch for version data response',
            responseValidatorObj: z.string(),
        });
    },
};

export { utilApiCalls };
