import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ROOM_EVENT_NAME, Leaderboard, Player, RoomUpdated, GameStarted, GameFinished } from '../typesAndInterfaces/typesAndInterfaces';
import { envs } from '../../env';
import { useSignedInContext } from './SignedInContext';
import { RTCommValidators } from '../utils/RTCommValidators';
import { AppState, Platform, StyleProp, Text, View, ViewStyle } from 'react-native';
import netInfo, { NetInfoState } from '@react-native-community/netinfo';
import { textStyles } from '../constants/textStyles';
import { MB_Pusher } from '../mightyByteLibraries/MB_Pusher/MB_Pusher';

export type EventData<T extends ROOM_EVENT_NAME> =
    T extends ROOM_EVENT_NAME.GAME_STARTED ? GameStarted :
    T extends ROOM_EVENT_NAME.GAME_FINISHED ? GameFinished :
    T extends ROOM_EVENT_NAME.ROOM_UPDATED ? RoomUpdated :
    T extends ROOM_EVENT_NAME.ROOM_DELETED ? GameFinished :
    T extends ROOM_EVENT_NAME.LEADERBOARD_UPDATED ? Leaderboard :
    any;

export type EventParams<T extends ROOM_EVENT_NAME> =
    T extends ROOM_EVENT_NAME.GAME_STARTED ? { eventName: ROOM_EVENT_NAME.GAME_STARTED, data: EventData<ROOM_EVENT_NAME.GAME_STARTED> } :
    T extends ROOM_EVENT_NAME.GAME_FINISHED ? { eventName: ROOM_EVENT_NAME.GAME_FINISHED, data: EventData<ROOM_EVENT_NAME.GAME_FINISHED> } :
    T extends ROOM_EVENT_NAME.ROOM_DELETED ? { eventName: ROOM_EVENT_NAME.ROOM_DELETED, data: EventData<ROOM_EVENT_NAME.GAME_FINISHED> } :
    T extends ROOM_EVENT_NAME.ROOM_UPDATED ? { eventName: ROOM_EVENT_NAME.ROOM_UPDATED, data: EventData<ROOM_EVENT_NAME.ROOM_UPDATED> } :
    T extends ROOM_EVENT_NAME.LEADERBOARD_UPDATED ? { eventName: ROOM_EVENT_NAME.LEADERBOARD_UPDATED, data: EventData<ROOM_EVENT_NAME.LEADERBOARD_UPDATED> } :
    any;

export interface Callbacks {
    onSubscriptionSucceeded?: () => void;
    onSubscriptionError?: (message: string) => void;
    onEvent?: (params: EventParams<ROOM_EVENT_NAME.GAME_STARTED | ROOM_EVENT_NAME.ROOM_UPDATED | ROOM_EVENT_NAME.ROOM_DELETED>) => void;
}

export enum CONNECTION_STATE {
    INITIALIZED = 'INITIALIZED',
    CONNECTING = 'CONNECTING',
    CONNECTED = 'CONNECTED',
    UNAVAILABLE = 'UNAVAILABLE',
    FAILED = 'FAILED',
    DISCONNECTED = 'DISCONNECTED',
    DISCONNECTING = 'DISCONNECTING',
    RECONNECTING = 'RECONNECTING'
}

type RTCommContext = {
    connect(): Promise<void>,
    disconnect(): Promise<void>,
    subscribe(channelName: string, callbacks?: Callbacks): Promise<void>,
    unsubscribe(channelName: string, params?: { disconnect?: boolean }): Promise<void>,
    isSubscribedToChannel(channelName: string): boolean,
    //sendEvent<T extends ROOM_EVENT_NAME>(channelName: string, eventName: T, data: EventData<T>): Promise<void>,
    connectionStatus: CONNECTION_STATE,
    isConnected: boolean,
    isInternetReachable: boolean | undefined;
    players: Player[],
    isGameEnded: boolean,
    leaderboard: Leaderboard,
    setLeaderboard: (leaderboard: Leaderboard) => void
};

const RTCommContext = React.createContext<RTCommContext | undefined>(undefined);

const isConnectionState = (connectionStatus: string): connectionStatus is CONNECTION_STATE => Object.keys(CONNECTION_STATE).includes(connectionStatus);

const RTCommContextProvider = ({ children }: { children?: ReactNode }) => {

    const { isSignedIn } = useSignedInContext();
    const [connectionStatus, setConnectionStatus] = useState<CONNECTION_STATE>(CONNECTION_STATE.DISCONNECTED);
    const [players, setPlayers] = useState<Player[]>([]);
    const [leaderboard, setLeaderboard] = useState<Leaderboard>([]);
    const [isGameEnded, setIsGameEnded] = useState(true);
    const [isInternetReachable, setIsInternetReachable] = useState(false);
    const [isAppInBackground, setIsAppInBackground] = useState(false);

    const isConnected = connectionStatus === CONNECTION_STATE.CONNECTED;

    const onConnectionStateChange = useCallback((newConnectionStatus: string) => {
        if (isConnectionState(newConnectionStatus)) {
            setConnectionStatus(newConnectionStatus);
        }
    }, []);

    useEffect(() => {
        if (isSignedIn) {
            MB_Pusher.init(onConnectionStateChange);
        }
    }, [isSignedIn, onConnectionStateChange]);

    const onNetInfoStateChange = useCallback((netInfoState: NetInfoState) => {
        if (netInfoState.isConnected && netInfoState.isInternetReachable) {
            setIsInternetReachable(true);
        } else if (netInfoState.isConnected && netInfoState.isInternetReachable === null) {
            setIsInternetReachable(true);
        } else if (netInfoState.isConnected === false && netInfoState.isInternetReachable === false) {
            setIsInternetReachable(false);
        }
    }, []);

    const internetListener = useCallback(() => {
        netInfo.fetch().then(onNetInfoStateChange);
        return netInfo.addEventListener(onNetInfoStateChange);
    }, [onNetInfoStateChange]);

    useEffect(() => {
        let removeInternetListener = internetListener();
        if (Platform.OS === 'ios') {
            const appStateListener = AppState.addEventListener('change', state => {
                if (state === 'background') {
                    removeInternetListener();
                    setIsAppInBackground(true);
                } else if (state === 'active') {
                    setIsAppInBackground(false);
                    removeInternetListener = internetListener();
                }
            });
            return () => {
                removeInternetListener();
                appStateListener.remove();
            };
        }
        return removeInternetListener;
    }, [internetListener, onNetInfoStateChange]);

    useEffect(() => {
        if (Platform.OS === 'ios') {
            const channelsName = MB_Pusher.getChannelsName();
            if (!isGameEnded) {
                const isDisconnected =
                    connectionStatus === CONNECTION_STATE.DISCONNECTED ||
                    connectionStatus === CONNECTION_STATE.DISCONNECTING ||
                    connectionStatus === CONNECTION_STATE.RECONNECTING;
                if (isInternetReachable && isDisconnected && !isAppInBackground) {
                    MB_Pusher.connect();
                } else if (!isInternetReachable || isAppInBackground) {
                    MB_Pusher.disconnect();
                }
            } else if (channelsName.length > 0 && connectionStatus === CONNECTION_STATE.CONNECTED) {
                for (const channelName of channelsName) {
                    MB_Pusher.unsubscribe(channelName);
                }
                MB_Pusher.disconnect();
            }
        }
    }, [connectionStatus, isAppInBackground, isGameEnded, isInternetReachable]);

    const onPlayerAdded = useCallback((userId: string, userInfo: any) => {
        const player = RTCommValidators.validatePlayer(userId, userInfo);
        if (player) {
            setPlayers(currentPlayers => {
                const isPlayerIn = currentPlayers.find(thisPlayer => thisPlayer.userId === player.userId);
                if (!isPlayerIn) {
                    return [...currentPlayers, player];
                }
                return currentPlayers;
            });
        }
    }, []);

    const onPlayerRemoved = useCallback((userId: string, userInfo: any) => {
        const player = RTCommValidators.validatePlayer(userId, userInfo);
        if (player) {
            setPlayers(currentPlayers => currentPlayers.filter(thisPlayer => thisPlayer.userId !== player.userId));
        }
    }, []);

    const onLeaderboardChange = useCallback((newLeaderboard: Leaderboard) => {
        setLeaderboard([...newLeaderboard].sort((a, b) => b.score - a.score));
    }, []);

    const onEvent = useCallback(async (eventData: { eventName: string, data: any }, callback: Callbacks['onEvent']) => {
        const { eventName, data } = RTCommValidators.validateEvent(eventData);
        if (eventName === ROOM_EVENT_NAME.LEADERBOARD_UPDATED) {
            onLeaderboardChange(data);
        } else if (eventName === ROOM_EVENT_NAME.GAME_FINISHED) {
            setIsGameEnded(true);
            const channelsName = MB_Pusher.getChannelsName();
            if (Platform.OS !== 'ios') {
                for (const channelName of channelsName) {
                    await MB_Pusher.unsubscribe(channelName);
                }
                MB_Pusher.disconnect();
            }
        } else if (eventName === ROOM_EVENT_NAME.GAME_STARTED) {
            callback?.({ eventName, data });
        } else if (eventName === ROOM_EVENT_NAME.ROOM_UPDATED) {
            callback?.({ eventName, data });
        } else if (eventName === ROOM_EVENT_NAME.ROOM_DELETED) {
            callback?.({ eventName, data });
        }
    }, [onLeaderboardChange]);

    const value = useMemo<RTCommContext>(() => ({
        connectionStatus,
        isConnected,
        isInternetReachable,
        players,
        isGameEnded,
        leaderboard,
        setLeaderboard: onLeaderboardChange,
        connect: () => {
            console.log('RTComm: Connect Requested!');
            setConnectionStatus(CONNECTION_STATE.CONNECTING);
            return MB_Pusher.connect();
        },
        disconnect: MB_Pusher.disconnect,
        subscribe: async (channelName: string, callbacks?: Callbacks) => {
            console.log('RTComm: Suscribe Requested!');
            await MB_Pusher.subscribe(channelName, {
                onSubscriptionSucceeded: (data) => {
                    setIsGameEnded(false);
                    const channelPlayers = RTCommValidators.validatePresence(data.presence);
                    if (channelPlayers) {
                        setPlayers([...channelPlayers]);
                    }
                    callbacks?.onSubscriptionSucceeded?.();
                },
                onMemberAdded: ({ userId, userInfo }) => onPlayerAdded(userId, userInfo),
                onMemberRemoved: ({ userId, userInfo }) => onPlayerRemoved(userId, userInfo),
                onEvent: (eventData) => onEvent(eventData, callbacks?.onEvent),
                onSubscriptionError: (_, message) => callbacks?.onSubscriptionError?.(message),
            });
        },
        unsubscribe: async (channelName: string, params?: { disconnect?: boolean }) => {
            setIsGameEnded(true);
            if (Platform.OS !== 'ios') {
                await MB_Pusher.unsubscribe(channelName);
                if (params?.disconnect) {
                    await MB_Pusher.disconnect();
                }
            }
        },
        isSubscribedToChannel: (channelName) => MB_Pusher.isSubscribedToChannel(channelName), // TODO: We might need to internalize this as a boolean state variable?
        // sendEvent: async <T extends ROOM_EVENT_NAME>(channelName: string, eventName: T, data: EventData<T>) => {
        //     await pusher.trigger({ channelName, eventName: `client-${eventName}`, data: JSON.stringify(data) });
        // },
    }), [connectionStatus, isConnected, isGameEnded, isInternetReachable, leaderboard, onEvent, onLeaderboardChange, onPlayerAdded, onPlayerRemoved, players]);

    const getStyle = useCallback((test: boolean | undefined, left: number): StyleProp<ViewStyle> => ({
        position: 'absolute',
        left,
        width: 15,
        height: 15,
        top: 5,
        borderRadius: 360,
        backgroundColor: test ? '#3E9D0C' : '#E53232',
        alignItems: 'center',
        justifyContent: 'center',
    }), []);

    return (
        <RTCommContext.Provider value={value}>
            {children}
            {envs.FLAVOR !== 'prod' &&
                <>
                    <View style={getStyle(isInternetReachable, 5)}>
                        <Text style={textStyles.tinyText}>I</Text>
                    </View>
                    <View style={getStyle(isConnected, 25)}>
                        <Text style={textStyles.tinyText}>C</Text>
                    </View>
                </>
            }
        </RTCommContext.Provider>
    );
};

function useRTCommContext(enable?: boolean) {
    const context = useContext(RTCommContext);
    if (context === undefined && enable) {
        throw new Error('useRTCommContext must be used within a RTCommContextProvider');
    }

    return context ?? ({} as RTCommContext);
}

export { RTCommContextProvider, useRTCommContext };
