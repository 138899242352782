import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg';

const ImagePoolIcon = React.memo((props: { size?: number, color?: string, containerStyle?: StyleProp<ViewStyle> }) => {
    return (
        <View style={props.containerStyle}>
            <Svg
                width={props.size ?? 22}
                height={props.size ? (props.size * (20 / 22)) : 20}
                viewBox="0 0 22 20"
            >
                <G clipPath="url(#clip0_3109_1302)">
                    <Path
                        d="M13.183 6.2l.979 1.485h1.417c.318 0 .575.26.575.581v6.396a.578.578 0 01-.575.58h-9.2a.578.578 0 01-.576-.58V8.265c0-.32.258-.58.575-.58h1.418l.98-1.486a.574.574 0 01.478-.259h3.45c.192 0 .372.097.478.26zm1.821 2.648h-1.15a.573.573 0 01-.478-.259l-.98-1.485H9.562l-.98 1.485a.573.573 0 01-.478.259h-1.15v5.232h8.05V8.848zm-1.725 2.325c0 1.284-1.03 2.325-2.3 2.325-1.27 0-2.3-1.041-2.3-2.325 0-1.284 1.03-2.325 2.3-2.325 1.27 0 2.3 1.041 2.3 2.325zm-1.15 0c0-.642-.515-1.162-1.15-1.162-.635 0-1.15.52-1.15 1.162 0 .642.515 1.163 1.15 1.163.635 0 1.15-.521 1.15-1.163zM7.393.128a2.65 2.65 0 012.375 1.484c.255.516.777.841 1.346.841h9.066c.952 0 1.725.781 1.725 1.744V18.15c0 .963-.773 1.744-1.725 1.744H1.777A1.735 1.735 0 01.052 18.15V1.872C.052.909.825.128 1.777.128h5.616zm0 1.162H1.778a.579.579 0 00-.575.582v16.277c0 .32.258.581.575.581H20.18c.317 0 .575-.26.575-.58V4.196a.579.579 0 00-.575-.581h-9.065A2.654 2.654 0 018.74 2.132a1.502 1.502 0 00-1.347-.842z"
                        fill={props.color ?? '#fff'}
                    />
                </G>
                <Defs>
                    <ClipPath id="clip0_3109_1302">
                        <Path fill={props.color ?? '#fff'} d="M0 0H22V20H0z" />
                    </ClipPath>
                </Defs>
            </Svg>
        </View>
    );
});

export { ImagePoolIcon };
