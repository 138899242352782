import { useInfiniteQuery, UseInfiniteQueryOptions, useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { adminApiCalls } from '../apiCalls/adminApiCalls';
import { ADMIN_QUERY_KEYS, QUESTION_QUERY_KEYS } from '../constants/constants';
import { Category, ChangeCategoryPaymentResponse, CreateQuestionRquest, DIFFICULTY, EditQuestionRequest, EducatorCode, Folder, FOLDER_TYPE, GAME_TYPE, PaginationPage, Question, ServerError, User } from '../typesAndInterfaces/typesAndInterfaces';

export const useGetUsers = ({ totalItemsPerPage, pageNum, queryOptions }: { totalItemsPerPage: number, pageNum: number, queryOptions?: UseQueryOptions<PaginationPage<User>, ServerError> }) => {
    return useQuery<PaginationPage<User>, ServerError>([ADMIN_QUERY_KEYS.getUsers, pageNum],
        async () => adminApiCalls.getUsers(pageNum, totalItemsPerPage),
        { refetchOnWindowFocus: false, ...queryOptions });
};

export const useGetCategories = ({ totalItemsPerPage, pageNum, gameType, queryOptions }: { totalItemsPerPage: number, pageNum: number, gameType: GAME_TYPE, queryOptions?: UseQueryOptions<PaginationPage<Category>, ServerError> }) => {
    return useQuery<PaginationPage<Category>, ServerError>([ADMIN_QUERY_KEYS.getCategories, gameType, pageNum],
        async () => adminApiCalls.getCategories(pageNum, totalItemsPerPage, gameType),
        { refetchOnWindowFocus: false, ...queryOptions });
};


export const useInfiniteCategories = ({ gameType, totalItemsPerPage, queryOptions }: { gameType: GAME_TYPE, totalItemsPerPage: number, queryOptions?: UseInfiniteQueryOptions<PaginationPage<Category>, any> }) => {
    return useInfiniteQuery<PaginationPage<Category>, any>([ADMIN_QUERY_KEYS.getCategories, 'infinite', gameType],
        async ({ pageParam }: { pageParam?: number }) => adminApiCalls.getCategories(pageParam || 1, totalItemsPerPage, gameType), {
        refetchOnWindowFocus: false,
        getNextPageParam: lastPage => lastPage.page < Math.ceil(lastPage.totalItems / totalItemsPerPage) ? lastPage.page + 1 : undefined,
        ...queryOptions,
    }
    );
};

export const useGetCategory = ({ categoryId, queryOptions }: { categoryId: string | undefined, queryOptions?: UseQueryOptions<Category, ServerError> }) => {
    return useQuery<Category, ServerError>([ADMIN_QUERY_KEYS.getCategory, categoryId],
        async () => adminApiCalls.getCategory(categoryId),
        { refetchOnWindowFocus: false, ...queryOptions });
};

export const useDeleteUser = () => {
    const queryClient = useQueryClient();

    return useMutation<void, ServerError, { userId: string }, unknown>(
        async ({ userId }) => adminApiCalls.deleteUser(userId), {
        onSuccess: () => {
            queryClient.invalidateQueries([ADMIN_QUERY_KEYS.getUsers]);
        },
    });
};

export const useCreateCategory = () => {
    const queryClient = useQueryClient();

    return useMutation<Category, ServerError, { name: string, gameType: GAME_TYPE, difficulty: DIFFICULTY[], tutorialURL?: string, image?: string }, unknown>(
        async ({ name, gameType, difficulty, tutorialURL, image }) => adminApiCalls.createCategory(name, gameType, difficulty, tutorialURL, image), {
        onSuccess: (category) => {
            queryClient.setQueryData<Category>([ADMIN_QUERY_KEYS.getCategory, category._id], category);
            queryClient.invalidateQueries([ADMIN_QUERY_KEYS.getCategories]);
            queryClient.invalidateQueries([ADMIN_QUERY_KEYS.getCategory, category._id]);
        },
    });
};

export const useToggleCategory = () => {
    const queryClient = useQueryClient();

    return useMutation<Category, ServerError, { categoryId: string, isEnabled: boolean }, unknown>(
        async ({ categoryId, isEnabled }) => adminApiCalls.toggleCategory(categoryId, isEnabled), {
        onSuccess: (category, { categoryId }) => {
            queryClient.setQueryData<Category>([ADMIN_QUERY_KEYS.getCategory, category._id], category);
            queryClient.invalidateQueries([ADMIN_QUERY_KEYS.getCategories]);
            queryClient.invalidateQueries([ADMIN_QUERY_KEYS.getCategory, categoryId]);
        },
    });
};

export const useEditCategory = () => {
    const queryClient = useQueryClient();

    return useMutation<Category, ServerError, { categoryId?: string, name?: string, difficulty?: DIFFICULTY[], tutorialURL?: string, extras?: string[], image?: string }, unknown>(
        async ({ categoryId, name, difficulty, tutorialURL, extras, image }) => adminApiCalls.editCategory(categoryId, name, difficulty, tutorialURL, image, extras), {
        onSuccess: (category, { categoryId }) => {
            queryClient.setQueryData<Category>([ADMIN_QUERY_KEYS.getCategory, category._id], category);
            queryClient.invalidateQueries([ADMIN_QUERY_KEYS.getCategories]);
            queryClient.invalidateQueries([ADMIN_QUERY_KEYS.getCategory, categoryId]);
        },
    });
};

export const useDeleteCategory = () => {
    const queryClient = useQueryClient();

    return useMutation<string, ServerError, { categoryId: string }, unknown>(
        async ({ categoryId }) => adminApiCalls.deleteCategory(categoryId), {
        onSuccess: (categoryId) => {
            queryClient.setQueryData<Category>([ADMIN_QUERY_KEYS.getCategory, categoryId], undefined);
            queryClient.invalidateQueries([ADMIN_QUERY_KEYS.getCategories]);
        },
    });
};


export const useReorderCategories = ({ gameType }: { gameType: GAME_TYPE }) => {
    const queryClient = useQueryClient();

    return useMutation<void, ServerError, { categoriesOrder: { categoryId: string, order: number }[] }, unknown>(
        async ({ categoriesOrder }) => adminApiCalls.reorderCategories(categoriesOrder), {
        onSuccess: () => {
            queryClient.invalidateQueries([ADMIN_QUERY_KEYS.getCategories, 'infinite', gameType]);
        },
    });
};
export const useCreateQuestion = () => {
    const queryClient = useQueryClient();

    return useMutation<Question, ServerError, CreateQuestionRquest, unknown>(
        async (requestData) => adminApiCalls.createQuestion(requestData), {
        onSuccess: (question) => {
            queryClient.setQueryData<Question>([QUESTION_QUERY_KEYS.getQuestion, question._id], question);
            queryClient.invalidateQueries([QUESTION_QUERY_KEYS.getQuestions, question.categoryId]);
        },
    });
};

export const useToggleQuestion = () => {
    const queryClient = useQueryClient();

    return useMutation<Question, ServerError, { questionId: string, isEnabled: boolean }, unknown>(
        async ({ questionId, isEnabled }) => adminApiCalls.toggleQuestion(questionId, isEnabled), {
        onSuccess: (question) => {
            queryClient.setQueryData<Question>([QUESTION_QUERY_KEYS.getQuestion, question._id], question);
            queryClient.invalidateQueries([QUESTION_QUERY_KEYS.getQuestion, question._id]);
            queryClient.invalidateQueries([QUESTION_QUERY_KEYS.getQuestions, question.categoryId]);
        },
    });
};

export const useEditQuestion = () => {
    const queryClient = useQueryClient();
    return useMutation<Question, ServerError, EditQuestionRequest, unknown>(
        async (requestData) => adminApiCalls.editQuestion(requestData), {
        onSuccess: (question) => {
            queryClient.setQueryData<Question>([QUESTION_QUERY_KEYS.getQuestion, question._id], question);
            queryClient.invalidateQueries([QUESTION_QUERY_KEYS.getQuestion, question._id]);
            queryClient.invalidateQueries([QUESTION_QUERY_KEYS.getQuestions, question.categoryId]);
        },
    });
};

export const useDeleteQuestion = () => {
    const queryClient = useQueryClient();

    return useMutation<string, ServerError, { questionId: string, categoryId: string }, unknown>(
        async ({ questionId }) => adminApiCalls.deleteQuestion(questionId), {
        onSuccess: (deleteQuestionId, { categoryId }) => {
            queryClient.setQueryData<Question>([QUESTION_QUERY_KEYS.getQuestion, deleteQuestionId], undefined);
            queryClient.invalidateQueries([QUESTION_QUERY_KEYS.getQuestions, categoryId]);
        },
    });
};

export const useToggleCategoryPaymentState = () => {
    const queryClient = useQueryClient();

    return useMutation<ChangeCategoryPaymentResponse, ServerError, { categoryId: string, isFree: boolean }, unknown>(
        async ({ categoryId, isFree }) => adminApiCalls.toggleCategoryPaymentState(categoryId, isFree), {
        onSuccess: (response) => {
            queryClient.setQueryData<Category | undefined>([ADMIN_QUERY_KEYS.getCategory, response.categoryId], (categoryOld) => {
                if (!categoryOld) { return categoryOld; }

                return { ...categoryOld, isFree: response.isFree };
            });
            // TODO: Anas: Maybe use setQueryData for ADMIN_QUERY_KEYS.getCategories as well?
            // TODO: Anas: Maybe invalidate a specific page for ADMIN_QUERY_KEYS.getCategories instead?
            queryClient.invalidateQueries([ADMIN_QUERY_KEYS.getCategories]);
            queryClient.invalidateQueries([ADMIN_QUERY_KEYS.getCategory, response.categoryId]);
        },
    });
};

export const useGetFolders = ({ type, queryOptions }: { type: FOLDER_TYPE, queryOptions?: UseQueryOptions<Folder[], ServerError> }) => {
    return useQuery<Folder[], ServerError>([ADMIN_QUERY_KEYS.getFolders, type],
        async () => adminApiCalls.getFolders(type),
        { refetchOnWindowFocus: false, ...queryOptions });
};

export const useCreateFolder = () => {
    const queryClient = useQueryClient();

    return useMutation<Folder, ServerError, { name: string, type: FOLDER_TYPE }, unknown>(
        async ({ name, type }) => adminApiCalls.createFolder(name, type), {
        onSuccess: (newFolder) => {
            queryClient.setQueriesData<Folder[]>([ADMIN_QUERY_KEYS.getFolders], (data) => {
                if (!data) {
                    return [newFolder];
                }
                return [...data, newFolder];
            });
            queryClient.invalidateQueries([ADMIN_QUERY_KEYS.getFolders]);
        },
    });
};

export const useManualPremiumUser = () => {
    const queryClient = useQueryClient();

    return useMutation<void, ServerError, { userId: string; enabled: boolean }, unknown>(
        async ({ userId, enabled }) => adminApiCalls.manualPremiumUser(userId, enabled), {
        onSuccess: () => {
            queryClient.invalidateQueries([ADMIN_QUERY_KEYS.getUsers]);
        },
    });
};

export const useGetEducatorCodes = ({ totalItemsPerPage, pageNum, queryOptions }: { totalItemsPerPage: number, pageNum: number, queryOptions?: UseQueryOptions<PaginationPage<EducatorCode>, ServerError> }) => {
    return useQuery<PaginationPage<EducatorCode>, ServerError>([ADMIN_QUERY_KEYS.getEducatorCodes, pageNum],
        async () => adminApiCalls.getEducatorCodes(pageNum, totalItemsPerPage),
        { refetchOnWindowFocus: false, ...queryOptions });
};

export const useCreateEducatorCode = () => {
    const queryClient = useQueryClient();

    return useMutation<EducatorCode, ServerError, { code: string, name: string, numberOfLicenses: number, expireDate: string, description: string }, unknown>(
        async ({ code, name, numberOfLicenses, expireDate, description }) => adminApiCalls.createEducatorCode(code, name, numberOfLicenses, expireDate, description), {
        onSuccess: () => {
            queryClient.invalidateQueries([ADMIN_QUERY_KEYS.getEducatorCodes]);
        },
    });
};

export const useEditEducatorCode = () => {
    const queryClient = useQueryClient();

    return useMutation<EducatorCode, ServerError, { educatorCodeId: string, name: string, numberOfLicenses: number, expireDate: string, description: string }, unknown>(
        async ({ educatorCodeId, name, numberOfLicenses, expireDate, description }) => adminApiCalls.editEducatorCode(educatorCodeId, name, numberOfLicenses, expireDate, description), {
        onSuccess: () => {
            queryClient.invalidateQueries([ADMIN_QUERY_KEYS.getEducatorCodes]);
        },
    });
};

export const useDeleteEducatorCode = () => {
    const queryClient = useQueryClient();

    return useMutation<void, ServerError, { educatorCodeId: string }, unknown>(
        async ({ educatorCodeId }) => adminApiCalls.deleteEducatorCode(educatorCodeId), {
        onSuccess: () => {
            queryClient.invalidateQueries([ADMIN_QUERY_KEYS.getEducatorCodes]);
        },
    });
};
