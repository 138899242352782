import { MB_Button } from '@mightybyte/rnw.components.button';
import { MB_Image } from '@mightybyte/rnw.components.image';
import { MB_Modal } from '@mightybyte/rnw.components.modal';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import React from 'react';
import { StyleSheet, Text } from 'react-native';
import { COLORS } from '../../constants/colors';
import { textStyles } from '../../constants/textStyles';
import { imageUtils } from '../../utils/imageUtils';

const ThankYouModal = ({ isVisible, hideModal }: { isVisible: boolean, hideModal: () => void }) => {
    return (
        <MB_Modal
            isVisible={isVisible}
            childrenWrapperStyle={styles.modalChildrenWrapperStyle}
            hideCloseButton={true}
        >
            <MB_Image
                resizeMode="contain"
                source={imageUtils.images.thankYouImage}
                style={styles.image}
            />
            <Text style={styles.modalDetailText}>We appreciate your support!</Text>
            <MB_Button
                title="Done"
                style={styles.modalExitButton}
                onPress={hideModal}
            />
        </MB_Modal>
    );
};

export default ThankYouModal;

const styles = StyleSheet.create({
    image: {
        width: 213,
        height: 222,
        position: 'absolute',
        top: -126,
        zIndex: 1,
    },
    modalChildrenWrapperStyle: {
        backgroundColor: COLORS.backgroundPurple,
        paddingHorizontal: 32,
        overflow: 'visible',
    },
    modalDetailText: mbTextStyles([
        textStyles.smallText, {
            paddingTop: 50,
            paddingHorizontal: 16,
        },
    ]),
    modalExitButton: {
        marginTop: 16,
        backgroundColor: COLORS.buttonPurple,
        borderRadius: 10,
        paddingHorizontal: 40,
        marginBottom: 16,
        height: 40,
    },
});
