import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { FolderIcon } from '../../../resources/svgComponents/FolderIcon';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../constants/textStyles';
import { AddFolderIcon } from '../../../resources/svgComponents/AddFolderIcon';
import { MB_utilHooks } from '@mightybyte/rnw.utils.util-hooks';
import { AddFolderModal } from './AddFolderModal';
import { useGetFolders } from '../../../hooks/adminHooks';
import { FOLDER_TYPE, Folder } from '../../../typesAndInterfaces/typesAndInterfaces';


const FoldersView = ({ type, onFolderPress, disableAddFolder }: { type: FOLDER_TYPE, onFolderPress?: (folder: Folder) => void, disableAddFolder?: boolean }) => {

    const [isAddFolderVisible, showAddFolderModal, hideAddFolderModal] = MB_utilHooks.useBool();

    const { data: folders } = useGetFolders({ type });

    return (
        <View style={styles.container}>
            <AddFolderModal
                key={`${isAddFolderVisible}`}
                isVisible={isAddFolderVisible}
                onDismiss={hideAddFolderModal}
                type={type}
            />
            <Text style={styles.title}>Folders</Text>
            <View style={styles.folders}>
                {folders?.map(folder => (
                    <View style={styles.folderWrapper}>
                        <TouchableOpacity key={folder._id} style={styles.folder} onPress={() => onFolderPress?.(folder)}>
                            <FolderIcon />
                            <Text style={styles.folderNameText} numberOfLines={2}>{folder.name}</Text>
                        </TouchableOpacity>
                    </View>
                ))}
                {!disableAddFolder &&
                    <View style={styles.folderWrapper}>
                        <TouchableOpacity style={styles.folder} onPress={showAddFolderModal}>
                            <AddFolderIcon />
                            <Text style={styles.folderNameText}>Create new folder</Text>
                        </TouchableOpacity>
                    </View>
                }
                {new Array(20).fill(1).map(() => <View style={styles.fakeFolder} />)}
            </View>
        </View>
    );
};

export { FoldersView };

const styles = StyleSheet.create({
    container: {
        width: '100%',
        marginBottom: 48,
    },
    title: mbTextStyles([textStyles.smallText, {
        textAlign: 'left',
    }]),
    folders: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },
    fakeFolder: {
        width: 156,
    },
    folderWrapper: {
        width: 156,
    },
    folder: {
        marginTop: 20,
        width: 120,
        alignItems: 'center',
    },
    folderNameText: mbTextStyles([textStyles.smallText, {
        marginTop: 12,
    }]),
});

