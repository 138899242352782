import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { ComponentWrapper } from '../../../helperComponents/componentWrapper/ComponentWrapper';
import { ImageVideoPoolFlatList } from './ImageVideoPoolFlatList';
import { UploadImageVideoButton } from '../../../helperComponents/UploadImageVideoButton';
import { textStyles } from '../../../../constants/textStyles';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { COLORS } from '../../../../constants/colors';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/core';
import { AdminNavigatorParamList } from '../../../../navigations/AdminNavigator/AdminNavigator';
import { Folder, RESOURCE_POOL_TYPE } from '../../../../typesAndInterfaces/typesAndInterfaces';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { MB_Button } from '@mightybyte/rnw.components.button';
import Feather from 'react-native-vector-icons/Feather';

const ImageVideoPool = () => {

    const { resourcePoolType, folder } = useRoute<RouteProp<AdminNavigatorParamList, 'ImagePool' | 'VideoPool'>>().params;
    const navigation = useNavigation<NativeStackNavigationProp<AdminNavigatorParamList>>();

    const onFolderChange = (newFolder?: Folder) => {
        if (resourcePoolType === RESOURCE_POOL_TYPE.Image) {
            navigation.navigate('ImagePool', { resourcePoolType, folder: newFolder });
        } else if (resourcePoolType === RESOURCE_POOL_TYPE.Video) {
            navigation.navigate('VideoPool', { resourcePoolType, folder: newFolder });
        }
    };

    return (
        <ComponentWrapper
            containerStyle={styles.container}
            innerContainerStyle={styles.innerContainer}
            disableLinearGradient
            hideWebHeader
        >
            <View style={styles.header}>
                <View style={styles.headerButton}>
                    {folder && <MB_Button
                        leftElement={<Feather name="arrow-left" color={COLORS.buttonPurple} size={20} />}
                        style={styles.headerBackButton}
                        onPress={() => onFolderChange()}
                    />}
                    <Text style={styles.title}>{folder ? folder.name : resourcePoolType === RESOURCE_POOL_TYPE.Image ? 'Image Pool' : 'Video Pool'}</Text>
                </View>
                <UploadImageVideoButton resourcePoolType={resourcePoolType} folderId={folder?._id} />
            </View>
            <ImageVideoPoolFlatList
                style={styles.imageVideoFlatList}
                resourcePoolType={resourcePoolType}
                folder={folder}
                onFolderPress={onFolderChange}
            />
        </ComponentWrapper>
    );
};

export { ImageVideoPool };

const styles = StyleSheet.create({
    container: {
        minWidth: 1000,
        height: '100%',
    },
    innerContainer: {
        flex: 1,
        paddingBottom: 32,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 40,
    },
    headerBackButton: {
        width: 47,
        height: 33,
        backgroundColor: COLORS.white,
        marginEnd: 16,
    },
    headerButton: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    title: mbTextStyles([
        textStyles.largestText, {
            fontSize: 36,
            color: COLORS.textLightPurple,
        },
    ]),
    imageVideoFlatList: {
        marginTop: 32,
    },
});
