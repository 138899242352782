import React, { createContext, ReactNode, useCallback, useContext, useEffect } from 'react';
interface MB_CodePushContextAvailableUpdateData {
  version: string | undefined,
  isMandatory: boolean,
  isPending: boolean,
  label: string,
}

interface CodePushContextProps {
  updateStatus: undefined,
  progress: number | undefined,
  availableUpdateData: MB_CodePushContextAvailableUpdateData | undefined,
  checkForAvailableUpdates: () => Promise<MB_CodePushContextAvailableUpdateData | undefined>,
  update: (options?: any) => void,
}


const MB_CodePushContext = createContext<CodePushContextProps | undefined>(undefined);


const MB_CodePushContextProvider = ({ children }: { children?: ReactNode }) => {

  const update = useCallback(async () => {

  }, []);

  const checkForAvailableUpdates = useCallback(async (): Promise<MB_CodePushContextAvailableUpdateData | undefined> => {
    return undefined;
  }, []);

  useEffect(() => {
    checkForAvailableUpdates();
  }, [checkForAvailableUpdates]);

  const value = {
    updateStatus: undefined,
    progress: undefined,
    availableUpdateData: undefined,
    checkForAvailableUpdates,
    update,
  };

  return (
    <MB_CodePushContext.Provider value={value}>
      {children}
    </MB_CodePushContext.Provider>
  );
};

function useMB_CodePushContext() {
  const context = useContext(MB_CodePushContext);

  if (context === undefined) {
    throw new Error('useCodePushContext must be used within a CodePushContextProvider');
  }

  return context;
}

export { MB_CodePushContext, useMB_CodePushContext, MB_CodePushContextProvider };
export type { MB_CodePushContextAvailableUpdateData };
