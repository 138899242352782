import { useMutation, useQueryClient, useInfiniteQuery, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { useEffect } from 'react';
import { imagePoolApiCalls } from '../apiCalls/imagePoolApiCalls';
import { IMAGE_VIDEO_POOL_QUERY_KEYS } from '../constants/constants';
import { PoolImage, ServerError } from '../typesAndInterfaces/typesAndInterfaces';
import { MB_InfinitePaginationPage } from '@mightybyte/rnw.utils.util-hooks';

export const useUploadImage = () => {
    const queryClient = useQueryClient();

    return useMutation<PoolImage, ServerError, { formData: FormData, uploadProgress?: (percentCompleted: number) => void }, unknown>(
        async ({ formData, uploadProgress }) => imagePoolApiCalls.uploadImage({ formData, uploadProgress }), {
        onSuccess: () => {
            queryClient.invalidateQueries([IMAGE_VIDEO_POOL_QUERY_KEYS.getImagePoolImages]);
        },
    });
};

export const useGetImagePoolImages = ({ totalItemsPerPage, searchText, folderId, queryOptions }: { totalItemsPerPage: number, searchText?: string, folderId?: string, queryOptions?: UseInfiniteQueryOptions<MB_InfinitePaginationPage<PoolImage>, ServerError> }) => {

    const queryClient = useQueryClient();

    const searchQuery = searchText?.trim() === '' ? undefined : searchText;

    useEffect(() => {
        queryClient.invalidateQueries([IMAGE_VIDEO_POOL_QUERY_KEYS.getImagePoolImages, folderId]);
    }, [folderId, queryClient, searchQuery]);


    return useInfiniteQuery<MB_InfinitePaginationPage<PoolImage>, ServerError>([IMAGE_VIDEO_POOL_QUERY_KEYS.getImagePoolImages, folderId, searchQuery],
        async ({ pageParam }: { pageParam?: string }) => imagePoolApiCalls.getImagePoolImages(totalItemsPerPage, pageParam, searchQuery, folderId), {
        refetchOnWindowFocus: false,
        getNextPageParam: lastPage => lastPage.nextOffset ?? undefined,
        ...queryOptions,
    });
};

export const useDeleteImage = () => {
    const queryClient = useQueryClient();

    return useMutation<string, ServerError, string, unknown>(
        async (imageId) => imagePoolApiCalls.deleteImage(imageId), {
        onSuccess: () => {
            queryClient.invalidateQueries([IMAGE_VIDEO_POOL_QUERY_KEYS.getImagePoolImages]);
        },
    });
};
