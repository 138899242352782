import { MB_Image } from '@mightybyte/rnw.components.image';
import { MB_LinearGradient } from '@mightybyte/rnw.components.linear-gradient';
import { mbApplyTransparency, mbPlatformStyle, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import React, { useCallback } from 'react';
import { FlatList, ListRenderItemInfo, StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native';
import { COLORS } from '../../../../constants/colors';
import { imageUtils } from '../../../../utils/imageUtils';
import { textStyles } from '../../../../constants/textStyles';
import { GameScore, Leaderboard, Room } from '../../../../typesAndInterfaces/typesAndInterfaces';
import { CoinIcon } from '../../../../resources/svgComponents/CoinIcon';
import Feather from 'react-native-vector-icons/Feather';
import { useGetCurrentUserData } from '../../../../hooks/userHooks';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';

interface LeaderboardModalProps {
    room: Room,
    leaderboard: Leaderboard,
    onClose: () => void
}

const RoomLeaderboard = ({ room, leaderboard, onClose }: LeaderboardModalProps) => {

    const { data: currentUserData } = useGetCurrentUserData();

    const firstPlayer: GameScore | undefined = leaderboard[0];
    const secondPlayer: GameScore | undefined = leaderboard[1];
    const thirdPlayer: GameScore | undefined = leaderboard[2];
    const currentPlayer: GameScore | undefined = leaderboard.find(player => player.userId === currentUserData?._id);
    const currentPlayerPosition: number = leaderboard.findIndex(player => player.userId === currentUserData?._id);

    const keyExtractor = useCallback((player: { userId: string }) => player.userId, []);

    const RenderPlayerComponent = useCallback(({ item: player, index }: ListRenderItemInfo<GameScore>) => {
        return (
            <PlayerRank
                playerName={player.name}
                rank={(index + 3)}
                score={player.score}
                style={{ backgroundColor: (index + 3) % 2 !== 0 ? mbApplyTransparency('#FFFFFF', 0.07) : undefined }}
            />
        );
    }, []);

    const formatPosition = (position: number) => {
        if (position === 1) {
            return '1st Position';
        }
        else if (position === 2) {
            return '2nd Position';
        } else if (position === 3) {
            return '3rd Position';
        }
        return `${position}th Position`;
    };

    const isRoomCreator = currentUserData?._id === room.creatorUserId;
    const isParticipating = currentUserData?._id !== room.creatorUserId || room.isCreatorParticipating;

    return (
        <>
            <View style={styles.header}>
                <Text style={styles.headerText}>Leaderboard</Text>
                {isMobileApp && <Feather style={styles.closeButton} name="x" size={25} color={COLORS.white} onPress={onClose} />}
            </View>
            <View style={styles.podium}>
                {/* Second Place */}
                <MB_LinearGradient
                    style={[styles.secondThirdPlace, { height: 250, paddingTop: 25 }]}
                    colors={[COLORS.wheelColorLight, COLORS.black]}
                    start={{ x: 0, y: 0.7 }}
                    end={{ x: 0, y: 1.3 }}
                >
                    <MB_Image
                        source={imageUtils.images.rankingSecond}
                        style={styles.podiumImage}
                        disableInteraction
                    />
                    <Text style={styles.podiumName}>{secondPlayer?.name ?? ''}</Text>
                    {secondPlayer && <Text style={styles.podiumScore}>{secondPlayer.score}</Text>}
                </MB_LinearGradient>
                {/* First Place */}
                <MB_Image
                    source={imageUtils.images.crown}
                    style={styles.crown}
                    disableInteraction
                />
                <MB_LinearGradient
                    style={[styles.firstPlace, { height: 300 }]}
                    colors={[COLORS.cardPurple, COLORS.black]}
                    start={{ x: 0, y: 0.7 }}
                    end={{ x: 0, y: 1.15 }}
                >
                    <MB_Image
                        source={imageUtils.images.rankingFirst}
                        style={styles.podiumImage}
                        disableInteraction
                    />
                    <Text style={styles.podiumName}>{firstPlayer?.name ?? ''}</Text>
                    {firstPlayer && <Text style={styles.podiumScore}>{firstPlayer.score}</Text>}
                </MB_LinearGradient>
                {/* Third Place */}
                <MB_LinearGradient
                    style={[styles.secondThirdPlace, { height: 200 }]}
                    colors={[COLORS.wheelColorLight, COLORS.black]}
                    start={{ x: 0, y: 0.7 }}
                    end={{ x: 0, y: 1.3 }}
                >
                    <MB_Image
                        source={imageUtils.images.rankingThird}
                        style={styles.podiumImage}
                        disableInteraction
                    />
                    <Text style={styles.podiumName}>{thirdPlayer?.name ?? ''}</Text>
                    {thirdPlayer && <Text style={styles.podiumScore}>{thirdPlayer.score}</Text>}
                </MB_LinearGradient>
            </View>
            { isParticipating &&
                <View style={[styles.playerStatusContainer, { paddingVertical: isRoomCreator ? 15 : 25 }]}>
                    <View style={isRoomCreator ? styles.creatorView : { alignItems: 'center' }}>
                        <Text style={[styles.totalScoreText, !isRoomCreator && { marginBottom: 11 }]}>YOUR TOTAL SCORE:</Text>
                        <View style={styles.scoreContainer}>
                            <CoinIcon />
                            <Text style={[styles.scoreText, { marginLeft: 7, fontSize: 13 }]}>{currentPlayer?.score}</Text>
                        </View>
                    </View>
                    <View style={isRoomCreator ? [styles.creatorView, { marginTop: 10 }] : { alignItems: 'center', marginTop: 20 }}>
                        <Text style={styles.positionText}>YOUR POSITION:</Text>
                        <Text style={[styles.positionPlaceText, !isRoomCreator && { marginTop: 9 }]}>{formatPosition(currentPlayerPosition + 1)}</Text>
                    </View>
                </View>
            }
            {currentUserData?._id === room.creatorUserId &&
                <View style={styles.playerContainer}>
                    <FlatList
                        keyExtractor={keyExtractor}
                        data={leaderboard.slice(3)}
                        renderItem={RenderPlayerComponent}
                    />
                </View>
            }
        </>
    );
};

interface PlayerRankProps {
    rank: number
    playerName: string,
    score: number,
    style?: StyleProp<ViewStyle>
}

const PlayerRank = ({ playerName, rank, score, style }: PlayerRankProps) => {
    return (
        <View style={[styles.playerTextContainer, style]}>
            <Text style={styles.playerNumberText}>{rank + 1}</Text>
            <Text style={[styles.rankingName]} numberOfLines={1}>{playerName}</Text>
            <View style={styles.scoreContainer}>
                <CoinIcon />
                <Text style={styles.scoreText}>{score}</Text>
            </View>
        </View>
    );
};

export { RoomLeaderboard };

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#1D183B',
    },
    header: {
        ...mbPlatformStyle({
            mobile: {
                marginTop: 30,
            },
        }),
    },
    headerText: mbTextStyles([
        textStyles.largerText, {
            fontSize: 18,
        },
    ]),
    closeButton: {
        position: 'absolute',
        right: 0,
    },
    podium: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'center',
        paddingHorizontal: 30,
        marginTop: 52,
    },
    firstPlace: {
        paddingTop: 50,
        alignItems: 'center',
        borderTopStartRadius: 8,
        borderTopEndRadius: 8,
        flex: 1,
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
    },
    secondThirdPlace: {
        backgroundColor: COLORS.wheelColorLight,
        paddingTop: 10,
        alignItems: 'center',
        borderTopStartRadius: 8,
        borderTopEndRadius: 8,
        flex: 1,
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
    },
    podiumImage: {
        width: '91%',
        aspectRatio: 1.875,
        marginBottom: 10,
    },
    podiumName: mbTextStyles([
        textStyles.smallerText, {
            marginBottom: 8,
            fontWeight: '600',
        },
    ]),
    podiumScore: mbTextStyles([
        textStyles.smallerText, {
            fontWeight: '300',
        },
    ]),
    crown: {
        width: 100,
        height: 60,
        position: 'absolute',
        top: -30,
        zIndex: 1,
    },
    playerContainer: {
        alignSelf: 'stretch',
        marginTop: 16,
        marginHorizontal: -16,
        ...mbPlatformStyle({
            web: {
                height: 200,
            },
            mobile: {
                flex: 1,
            },
        }),
    },
    playerTextContainer: {
        flexDirection: 'row',
        paddingVertical: 12,
        paddingHorizontal: 15,
    },
    playerNumberText: mbTextStyles([
        textStyles.smallerText, {
            marginEnd: 10,
        },
    ]),
    rankingName: mbTextStyles([
        textStyles.smallerText, {
            flex: 1,
            textAlign: 'left',
        },
    ]),
    rankingImage: {
        width: 37,
        height: 20,
        marginEnd: 16,
        alignSelf: 'center',
    },
    scoreContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    scoreText: mbTextStyles([
        textStyles.smallerText, {
            textAlign: 'left',
            fontWeight: '600',
            paddingVertical: 2,
            marginLeft: 11,
            width: 30,
        },
    ]),
    playerStatusContainer: {
        marginTop: 28,
        marginHorizontal: 30,
        backgroundColor: mbApplyTransparency('#FFFFFF', 0.07),
        borderRadius: 7,
        alignItems: 'center',
        //paddingVertical: 25,
    },
    creatorView: {
        flexDirection: 'row',
        width: '100%',
        paddingHorizontal: 15,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    totalScoreText: mbTextStyles([
        textStyles.smallerText, {
            fontSize: 11,
        },
    ]),
    positionText: mbTextStyles([
        textStyles.smallerText, {
            fontSize: 11,
        },
    ]),
    positionPlaceText: mbTextStyles([
        textStyles.smallerText, {
            fontSize: 13,
            fontWeight: '700',
        },
    ]),
});
