import { MB_EventEmmiter, MB_EVENT_EMMITER_EVENT_TYPE } from '@mightybyte/rnw.utils.event-emmiter';
import { EmitterSubscription, Platform } from 'react-native';
import {
    Product,
    initConnection,
    endConnection,
    getProducts,
    RequestPurchase,
    requestPurchase,
    flushFailedPurchasesCachedAsPendingAndroid,
    purchaseUpdatedListener,
    finishTransaction,
    PurchaseStateAndroid,
    purchaseErrorListener,
    getAvailablePurchases,
    Purchase,
} from 'react-native-iap';
import { envs } from '../../env';
import { paymentAndOrdersApiCalls } from '../apiCalls/paymentAndOrdersApiCalls';
import { PAYMENT_PROVIDER } from '../constants/constants';
import { AcknowledgeRequest, PAYMENT_STATUS } from '../typesAndInterfaces/typesAndInterfaces';
import { mbShowToast } from '@mightybyte/rnw.components.toast';

export enum MB_MOBILE_PURCHASE_EVENT_TYPE {
    'MOBILE_PURCHASE_UPDATE_SUCCESS' = 'MOBILE_PURCHASE_UPDATE_SUCCESS',
    'MOBILE_PURCHASE_UPDATE_PENDING' = 'MOBILE_PURCHASE_UPDATE_PENDING',
    'MOBILE_PURCHASE_UPDATE_ERROR' = 'MOBILE_PURCHASE_UPDATE_ERROR',
    'MOBILE_PURCHASE_NEEDS_UPDATE' = 'MOBILE_PURCHASE_NEEDS_UPDATE',
    'MOBILE_PURCHASE_CANCELLED' = 'MOBILE_PURCHASE_CANCELLED',
}

const emitEvent = (type: MB_MOBILE_PURCHASE_EVENT_TYPE, error?: any) => {
    MB_EventEmmiter.emit(MB_EVENT_EMMITER_EVENT_TYPE.message, { origin: envs.WEBSITE_BASE_URL, data: { type, error } });
};

async function sendAcknowledgeRequest(requestParams: AcknowledgeRequest<PAYMENT_PROVIDER>, purchase: Purchase) {
    paymentAndOrdersApiCalls.acknowledgePurchase(requestParams)
        .then(async newPaymentStatus => {
            console.log('Success when sending acknowledge payment request', newPaymentStatus);

            if (newPaymentStatus === PAYMENT_STATUS.pending) {
                emitEvent(MB_MOBILE_PURCHASE_EVENT_TYPE.MOBILE_PURCHASE_UPDATE_PENDING);
            } else {
                emitEvent(MB_MOBILE_PURCHASE_EVENT_TYPE.MOBILE_PURCHASE_UPDATE_SUCCESS);
                finishTransaction({ purchase, isConsumable: false });
                // TODO: Why do we need to call finishTransaction? Maybe backend should do this?
            }
        })
        .catch(error => {
            console.error('Error when sending acknowledge payment request', error);
            emitEvent(MB_MOBILE_PURCHASE_EVENT_TYPE.MOBILE_PURCHASE_UPDATE_ERROR, error);
        });
}

// Note: You can read more about how to use react-native-iap at https://react-native-iap.dooboolab.com/docs/get-started
class MB_MobilePurchase {
    #purchaseUpdateSubscription: EmitterSubscription | undefined;
    #purchaseErrorSubscription: EmitterSubscription | undefined;
    #isConnected: boolean = false;

    constructor() { }

    getIsConnected() {
        return this.#isConnected;
    }

    eventIsFromMobilePurchase(event: any): boolean {
        return Object.keys(MB_MOBILE_PURCHASE_EVENT_TYPE).indexOf(event) !== -1;
    }

    /**
     * Attemps to remove "ghost" pending payments for android.
     * (ghost = failed pending payment that are still marked as pending in Google's native Vending module cache)
     */
    async flushFailedPurchasesCachedAsPendingAndroid() {
        try {
            const flashResult = await flushFailedPurchasesCachedAsPendingAndroid();
            console.info('Result for flushing cached failed purchases on Android', flashResult);
        } catch (error) {
            // exception can happen here if: there are pending purchases that are still pending (we can't consume a pending purchase)
            // in any case, you might not want to do anything special with the error
            console.error('Error flushing ghost pending payments', error);
        }
    }

    async connect(): Promise<boolean> {
        // Cleanup, close listeners and connections just in case if they were somehow active.
        this.disconnect();

        try {
            this.#isConnected = await initConnection();
            console.log('Mobile payment connection established status: ' + this.#isConnected);

            await this.flushFailedPurchasesCachedAsPendingAndroid();

            this.#purchaseUpdateSubscription = purchaseUpdatedListener(async (purchase) => {
                console.log('Received purchase update', purchase);
                const { purchaseStateAndroid, purchaseToken, transactionId, transactionReceipt, productId } = purchase;

                if (!productId) {
                    console.error('Error: Invalid purchase state. No product ID', purchase);
                    return;
                }

                if (Platform.OS === 'ios') {
                    if (!transactionReceipt) {
                        console.error('Error: Invalid purchase state. No transactionReceipt', purchase);
                        return;
                    }

                    const requestParams: AcknowledgeRequest<PAYMENT_PROVIDER.apple> = {
                        paymentProvider: PAYMENT_PROVIDER.apple,
                        productId,
                        transactionId,
                        receiptData: transactionReceipt,
                    };

                    sendAcknowledgeRequest(requestParams, purchase);

                } else if (Platform.OS === 'android') {
                    if (!purchaseToken) {
                        console.error('Error: Invalid purchase state. No purchase token', purchase);
                        return;
                    }

                    if (purchaseStateAndroid === PurchaseStateAndroid.PENDING || purchaseStateAndroid === PurchaseStateAndroid.PURCHASED) {
                        const requestParams: AcknowledgeRequest<PAYMENT_PROVIDER.google> = {
                            paymentProvider: PAYMENT_PROVIDER.google,
                            productId,
                            purchaseToken,
                        };

                        sendAcknowledgeRequest(requestParams, purchase);
                    }
                }
            });

            this.#purchaseErrorSubscription = purchaseErrorListener((error) => {
                if (error.code === 'E_USER_CANCELLED') {
                    emitEvent(MB_MOBILE_PURCHASE_EVENT_TYPE.MOBILE_PURCHASE_CANCELLED);
                } else {
                    console.error('Mobile payment purchase error', error);
                    emitEvent(MB_MOBILE_PURCHASE_EVENT_TYPE.MOBILE_PURCHASE_UPDATE_ERROR, error);
                }
            });
        } catch (error) {
            console.error('Error when initializing mobile purchase', error);
        }

        return this.#isConnected;
    }

    async disconnect(withoutError?: boolean) {
        try {
            if (this.#purchaseUpdateSubscription) {
                this.#purchaseUpdateSubscription.remove();
                this.#purchaseUpdateSubscription = undefined;
            }

            if (this.#purchaseErrorSubscription) {
                this.#purchaseErrorSubscription.remove();
                this.#purchaseErrorSubscription = undefined;
            }

            await endConnection();
            this.#isConnected = false;
        } catch (error) {
            if (!withoutError) {
                console.error('SaikLog: Error when ending mobile purchase connection', error);
            }
        }
    }

    async requestPurchase({ sku }: { sku: string }) {
        try {
            if (!this.#isConnected) {
                await this.connect();
            }

            const requestPurchaseProps: RequestPurchase = Platform.OS === 'android' ? { skus: [sku] } : { sku };

            const purchaseRequest = await requestPurchase({
                ...requestPurchaseProps,
                andDangerouslyFinishTransactionAutomaticallyIOS: false,
            });
            return purchaseRequest;
        } catch (error) {
            console.error('Error when requesting a purchase', { error });
        }
    }

    async getProducts(productIds: string[]): Promise<Product[]> {
        if (!this.#isConnected) {
            await this.connect();
        }

        return await getProducts({ skus: productIds });
    }

    // This allows us to consume purchases and basically reset purchase state for testing.
    async consumeProductsForDebug(): Promise<boolean> {
        // TODO: PAYMENT: Disable this once done testing
        return new Promise((resolve) => {
            let purchaseResultText = 'Test';
            // Try to delete the order first
            paymentAndOrdersApiCalls.debugDeleteOrder()
                .then(async () => {
                    const availablePurchases = await getAvailablePurchases();

                    if (availablePurchases.length === 0) {
                        purchaseResultText = 'No purchases to clear';
                    }

                    await Promise.all(availablePurchases.map(async purchase => {
                        const finishTransactionResult = await finishTransaction({ purchase, isConsumable: true });
                        if (typeof finishTransactionResult === 'boolean') {
                            purchaseResultText = String(finishTransactionResult);
                        } else {
                            purchaseResultText = `C:${finishTransactionResult.code ?? '?'},R:${finishTransactionResult.responseCode ?? '?'},M:${finishTransactionResult.message ?? '?'},D:${finishTransactionResult.debugMessage ?? '?'}`;
                        }
                    }));

                    emitEvent(MB_MOBILE_PURCHASE_EVENT_TYPE.MOBILE_PURCHASE_NEEDS_UPDATE);
                    mbShowToast({ text1: 'Purchase cleared', text2: purchaseResultText, type: 'success' });
                    return resolve(true);
                })
                .catch(() => {
                    mbShowToast({ text1: 'Purchase clear failed!', type: 'error' });
                    return resolve(false);
                });
        });
    }
}

const instance = new MB_MobilePurchase();
export default instance;
