import React, { useEffect, useState } from 'react';

import { StyleSheet, Text, View } from 'react-native';
import { COLORS } from '../../constants/colors';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { textStyles } from '../../constants/textStyles';
import { UniversalScreenNavigationProp } from '../../typesAndInterfaces/componentProps';
import { BarIndicator } from '@mightybyte/rnw.components.activity-indicators';
import { useSignedInContext } from '../../context/SignedInContext';
import LoginBackground from '../../resources/svgComponents/LoginBackground';
import { envs } from '../../../env';

export enum LOADING_PAGE_GIVE_UP_ACTION_TYPES {
    signOut = 'signOut',
    backToHome = 'backToHome'
}

type LoadingParams = {
    giveUpAction?: LOADING_PAGE_GIVE_UP_ACTION_TYPES | (() => void),
    navigation?: UniversalScreenNavigationProp,
    hideGiveUpButton?: boolean,
    debugVariables?: { [key: string]: any } | undefined,
}

const LoadingPage = ({ giveUpAction, navigation, hideGiveUpButton, debugVariables }: LoadingParams) => {
    const [isLoadingVisibleInternal, setIsLoadingVisibleInternal] = useState(false);
    const [isGiveUpButtonVisible, setIsGiveUpButtonVisible] = useState(false);
    const { signOut } = useSignedInContext();
    useEffect(() => {
        const buttonTimeout = setTimeout(() => { setIsGiveUpButtonVisible(true); }, 5000);
        const viewTimeout = setTimeout(() => { setIsLoadingVisibleInternal(true); }, 500);

        return () => {
            clearTimeout(buttonTimeout);
            clearTimeout(viewTimeout);
        };
    }, []);

    if (!isLoadingVisibleInternal) {
        return <View style={styles.container} />;
    }

    const giveUp = () => {
        if (giveUpAction === LOADING_PAGE_GIVE_UP_ACTION_TYPES.signOut) {
            signOut({ skipSignOutRequest: true });
        } else if (typeof giveUpAction === 'function') {
            giveUpAction();
        } else {
            navigation?.reset({
                index: 0,
                routes: [{ name: navigation.getState().routeNames[0] }],
            });
        }
    };

    return (
        <View style={styles.container}>
            <View style={styles.loginBannerContainer}>
                <LoginBackground />
            </View>

            <View>
                <BarIndicator color={'white'} count={5} style={{ alignSelf: 'center', marginBottom: 32, flex: undefined }} />

                <Text style={textStyles.largerText}>Loading...</Text>
                {isGiveUpButtonVisible && !hideGiveUpButton &&
                    <MB_Button
                        title={giveUpAction === LOADING_PAGE_GIVE_UP_ACTION_TYPES.signOut ? 'Sign Out' : 'Go Back'}
                        onPress={giveUp}
                        style={{ marginTop: 16 }}
                    />
                }

                {(envs.FLAVOR !== 'prod') && (debugVariables !== undefined || Object.keys(debugVariables ?? {}).length !== 0) &&
                    <View>
                        <Text style={textStyles.normalText}>DEBUG VALUES</Text>
                        <Text style={textStyles.normalText}>{JSON.stringify(debugVariables)}</Text>
                    </View>
                }
            </View>
        </View>
    );
};

export { LoadingPage };

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: COLORS.backgroundDarkPurple,
        alignItems: 'center',
        justifyContent: 'center',
    },
    loginBannerContainer: {
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
    },
});
