import { StyleSheet } from 'react-native';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { COLORS } from './colors';

enum FONT_STYLES {
    PoppinsBase = 'Poppins',
    Poppins_100 = 'Poppins_100',
    Poppins_100_italic = 'Poppins_100_italic',
    Poppins_200 = 'Poppins_200',
    Poppins_200_italic = 'Poppins_200_italic',
    Poppins_300 = 'Poppins_300',
    Poppins_300_italic = 'Poppins_300_italic',
    Poppins_400 = 'Poppins_400',
    Poppins_400_italic = 'Poppins_400_italic',
    Poppins_500 = 'Poppins_500',
    Poppins_500_italic = 'Poppins_500_italic',
    Poppins_600 = 'Poppins_600',
    Poppins_600_italic = 'Poppins_600_italic',
    Poppins_700 = 'Poppins_700',
    Poppins_700_italic = 'Poppins_700_italic',
    Poppins_800 = 'Poppins_800',
    Poppins_800_italic = 'Poppins_800_italic',
    Poppins_900 = 'Poppins_900',
    Poppins_900_italic = 'Poppins_900_italic',
}

const textStyles = StyleSheet.create({
    superLargeText: mbTextStyles({
        fontFamily: FONT_STYLES.PoppinsBase,
        fontSize: 44,
        fontWeight: '700',
        color: COLORS.white,
        textAlign: 'center',
    }),
    largestText: mbTextStyles({
        fontFamily: FONT_STYLES.PoppinsBase,
        fontSize: 32,
        fontWeight: '700',
        color: COLORS.white,
        textAlign: 'center',
    }),
    largerText: mbTextStyles({
        fontFamily: FONT_STYLES.PoppinsBase,
        fontSize: 28,
        fontWeight: '600',
        color: COLORS.white,
        textAlign: 'center',
    }),
    largeText: mbTextStyles({
        fontFamily: FONT_STYLES.PoppinsBase,
        fontSize: 24,
        fontWeight: '500',
        color: COLORS.white,
        textAlign: 'center',
    }),
    normalText: mbTextStyles({
        fontFamily: FONT_STYLES.PoppinsBase,
        fontSize: 16,
        fontWeight: '500',
        color: COLORS.white,
        textAlign: 'center',
    }),
    smallText: mbTextStyles({
        fontFamily: FONT_STYLES.PoppinsBase,
        fontSize: 14,
        fontWeight: '500',
        color: COLORS.white,
        textAlign: 'center',
    }),
    smallerText: mbTextStyles({
        fontFamily: FONT_STYLES.PoppinsBase,
        fontWeight: 'normal',
        fontSize: 12,
        color: COLORS.white,
        textAlign: 'center',
    }),
    tinyText: mbTextStyles({
        fontFamily: FONT_STYLES.PoppinsBase,
        fontWeight: 'normal',
        fontSize: 10,
        color: COLORS.white,
        textAlign: 'center',
    }),
    popUpErrorMessageText: mbTextStyles({
        fontFamily: FONT_STYLES.PoppinsBase,
        fontWeight: 'normal',
        fontSize: 12,
        textAlign: 'center',
        color: COLORS.errorColor,
    }),
});


export { textStyles, FONT_STYLES };
