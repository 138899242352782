import { default as React } from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { ExtrasHome } from './ExtrasHome';
import { ExtrasVideos } from './ExtrasVideos';

export type ExtrasNavigatorParamList = {
  ExtrasHome: undefined,
  ExtrasVideos: { categoryId: string, title: string }
};

const Stack = createNativeStackNavigator<ExtrasNavigatorParamList>();

const ExtrasNavigator = () => {

  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        gestureEnabled: false,
      }}
    >
      <Stack.Screen name="ExtrasHome" component={ExtrasHome} />
      <Stack.Screen name="ExtrasVideos" component={ExtrasVideos} />
    </Stack.Navigator>
  );
};

export { ExtrasNavigator };
