import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useEffect } from 'react';
import { Platform, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { MB_Button } from '@mightybyte/rnw.components.button';
import { MB_accessTokenUtils, Token } from '@mightybyte/rnw.utils.access-token-utils';
import { MB_EventEmmiter, MB_EventObject, MB_EVENT_EMMITER_EVENT_TYPE } from '@mightybyte/rnw.utils.event-emmiter';
import { mbApplyTransparency } from '@mightybyte/rnw.utils.style-utils';
import { useQueryClient } from '@tanstack/react-query';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { envs } from '../../../env';
import { COLORS } from '../../constants/colors';
import { USER_QUERY_KEYS } from '../../constants/constants';
import { textStyles } from '../../constants/textStyles';
import { SIGNED_IN_STATUS, useSignedInContext } from '../../context/SignedInContext';
import { ServerRedirectNavigationProp } from '../../typesAndInterfaces/componentProps';
import { utils } from '../../utils/utils';
import { WEB_POST_MESSAGE_OBJECT_TYPES } from '../screens/ServerRedirect/serverRedirectUtils';
import { GoogleLogo } from '../../resources/svgComponents/GoogleLogo';

function SocialLoginButton({ buttonStyle, onPress, socialLoginType }: { buttonStyle?: StyleProp<ViewStyle>, onPress: () => void; socialLoginType: 'google' | 'apple' }) {
  return (
    <MB_Button
      title={socialLoginType === 'google' ? 'Google' : 'Apple'}
      leftElement={socialLoginType === 'google' ? <GoogleLogo size={40} /> : <AntDesign name="apple1" color={COLORS.white} size={35} />}
      onPress={() => {
        onPress();
      }}
      textStyle={textStyles.normalText}
      style={[
        styles.socialLoginButton,
        {
          marginEnd: socialLoginType === 'google' ? 17 : 0,
        },
        buttonStyle,
      ]}
    />
  );
}

const SocialLoginButtons = ({ callback, buttonStyle, containerStyle }: { callback: (isSuccess: boolean) => void, buttonStyle?: StyleProp<ViewStyle>, containerStyle?: StyleProp<ViewStyle> }) => {
  const queryClient = useQueryClient();
  const navigation = useNavigation<ServerRedirectNavigationProp>();
  const { setSignedInStatus } = useSignedInContext();

  const loginRedirectCallback = useCallback((event: MB_EventObject) => {
    try {
      if (event.origin !== envs.WEBSITE_BASE_URL || (event.data.type !== WEB_POST_MESSAGE_OBJECT_TYPES.LOGIN_COMPLETE && event.data.type !== WEB_POST_MESSAGE_OBJECT_TYPES.GENERAL_ERROR)) {
        return;
      }

      if (event.data.success && event.data.type !== WEB_POST_MESSAGE_OBJECT_TYPES.GENERAL_ERROR && event.data.data) {
        callback(true);
        MB_accessTokenUtils.setAccessToken(event.data.data as Token);
        queryClient.invalidateQueries([USER_QUERY_KEYS.getCurrentUsertData]);
        setSignedInStatus(SIGNED_IN_STATUS.signedIn);
      } else {
        callback(false);
      }
    } catch (error) {
      console.error('Error in redirect event listener', error);
      callback(false);
    }
  }, [callback, queryClient, setSignedInStatus]);


  useEffect(() => {
    const eventSub = MB_EventEmmiter.addListener(MB_EVENT_EMMITER_EVENT_TYPE.message, loginRedirectCallback, { useBroadcastChannel: true });
    return () => eventSub.remove();
  }, [loginRedirectCallback]);

  const googleOrAppleLoginPressed = async (provider: 'google' | 'apple') => {
    if (Platform.OS === 'web') {
      utils.openExternalWindow(`${envs.SERVER_URL}/api/users/login/${provider}`);
    } else {
      utils.openExternalAuthWindowForMobile(`${envs.SERVER_URL}/api/users/login/${provider}`, navigation);
    }
  };

  return (
    <View style={[styles.container, containerStyle]}>
      <SocialLoginButton buttonStyle={buttonStyle} onPress={() => googleOrAppleLoginPressed('google')} socialLoginType={'google'} />
      <SocialLoginButton buttonStyle={buttonStyle} onPress={() => googleOrAppleLoginPressed('apple')} socialLoginType={'apple'} />
    </View>
  );
};

export { SocialLoginButtons };

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
  },
  socialLoginButton: {
    backgroundColor: mbApplyTransparency(COLORS.darkPurple, 0.28),
    borderColor: COLORS.white,
    borderWidth: 1,
    width: 184,
    borderRadius: 6,
    flex: 1,
  },
});
