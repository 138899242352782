import { MB_Button } from '@mightybyte/rnw.components.button';
import { MB_Modal } from '@mightybyte/rnw.components.modal';
import { MB_TextInput } from '@mightybyte/rnw.components.text-input';
import { isMobile, isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { mbPlatformStyle, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { COLORS } from '../../constants/colors';
import { STRING_CONSTANTS } from '../../constants/constants';
import { textStyles } from '../../constants/textStyles';
import { useResetPasswordRequest } from '../../hooks/userHooks';
import { ForgotPasswordProps } from '../../typesAndInterfaces/componentProps';
import { ChangePasswordPopUp } from '../helperComponents/ChangePasswordPopUp';
import { ComponentWrapper } from '../helperComponents/componentWrapper/ComponentWrapper';
import { MobileHeaderProps } from '../helperComponents/Header/MobileHeader';

const ForgotPassword = ({ route, navigation }: ForgotPasswordProps) => {
    const passwordRecoveryToken = route.params?.passwordRecoveryToken;
    const recoveryFailed = route.params?.recoveryFailed;
    const [email, setEmail] = useState('');
    const [infoText, setInfoText] = useState('');
    const [errorMessage, setErrorMessage] = useState(recoveryFailed ? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN : '');
    const [isChangePasswordModalVisible, setIsChangePasswordModalVisible] = useState(false);
    const mobileHeaderOptions = useRef<MobileHeaderProps>({ showHeader: true, showBackArrow: true });

    useEffect(() => {
        if (passwordRecoveryToken && passwordRecoveryToken.token) {
            if (passwordRecoveryToken.expiresAtMs - Date.now() < 30000) {
                setErrorMessage('Password recovery link expired');
                setIsChangePasswordModalVisible(false);
                return;
            }
            setIsChangePasswordModalVisible(true);
        } else {
            setIsChangePasswordModalVisible(false);
        }
    }, [passwordRecoveryToken]);

    const resetPasswordRequest = useResetPasswordRequest();

    const sendResetEmail = () => {
        setInfoText('');
        setErrorMessage('');
        resetPasswordRequest.mutate(email, {
            onSuccess: () => {
                setInfoText(STRING_CONSTANTS.PASSWORD_RECOVERY_LINK_SEND_SUCCESS);
            },
            onError: () => {
                setErrorMessage(STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN);
            },
        });
    };

    const changePasswordModalCallback = (dismissData?: { isSuccess?: boolean }) => {
        setIsChangePasswordModalVisible(false);
        if (dismissData?.isSuccess) {
            navigation.reset({
                index: 0,
                routes: isMobileApp ? [{ name: 'MobileLandingPage' }, { name: 'Login' }] : [{ name: 'Login' }],
            });
        }
    };

    const resetPasswordContent = (
        <View style={styles.resetPasswordContainer}>
            <View style={styles.subContainer}>

                <Text style={styles.forgotPwdText}>Forgot your password?</Text>
                <Text style={styles.helperText}>Enter your email address and we’ll send you an email with instructions to reset your password.</Text>
                <MB_TextInput
                    value={email}
                    titleStyle={styles.textInputTitleStyle}
                    style={[styles.textInputMobileStyle, styles.textInputWrapper]}
                    onChangeText={(newText: string) => {
                        setEmail(newText);
                        setErrorMessage('');
                    }}
                    title="Email Address"
                    placeholder="john@example.com"
                />


                <MB_Button
                    title="Send instructions"
                    textStyle={textStyles.normalText}
                    onPress={sendResetEmail}
                    disabled={!email || resetPasswordRequest.isLoading}
                    loading={resetPasswordRequest.isLoading}
                    style={styles.sendInstructionsBtn}
                />

                <Text style={[textStyles.popUpErrorMessageText, { color: errorMessage ? COLORS.errorColor : COLORS.cyan }]}>{errorMessage ? errorMessage : infoText}</Text>
            </View>
        </View>
    );

    const confirmPasswordContent = (
        <ChangePasswordPopUp
            TitleComponent={(
                <>
                    {isMobile &&
                        <>
                            <Text style={[textStyles.largeText, isMobile && { alignSelf: 'flex-start' }]}>Change Password</Text>
                            <Text style={styles.helperText}>Please choose new password</Text>
                        </>
                    }
                </>
            )}
            style={styles.changePasswordPopUp}
            dismissCallback={changePasswordModalCallback}
            requireOldPassword={false}
            passwordRecoveryToken={passwordRecoveryToken}
            submitButton={{ title: 'Reset Password' }}
        />
    );

    if (isMobileApp) {
        return (
            <ComponentWrapper mobileHeaderOptions={mobileHeaderOptions.current}>
                {isChangePasswordModalVisible ? confirmPasswordContent : resetPasswordContent}
            </ComponentWrapper>
        );
    }

    return (
        <ComponentWrapper>
            <MB_Modal
                isVisible={isChangePasswordModalVisible}
                onDismiss={setIsChangePasswordModalVisible}
                title="Change password"
                childrenWrapperStyle={{ overflow: 'visible' }}
            >
                {confirmPasswordContent}
            </MB_Modal>
            <View style={styles.container}>
                {resetPasswordContent}
            </View>
        </ComponentWrapper>
    );
};

export { ForgotPassword };

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 22,
    },
    resetPasswordContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        ...mbPlatformStyle({
            mobile: {
                justifyContent: 'flex-start',
                marginTop: 30,
            },
        }),
    },
    subContainer: {
        alignItems: 'center',
        ...mbPlatformStyle({
            web: {
                justifyContent: 'center',
                backgroundColor: COLORS.darkPurple,
                borderRadius: 6,
                paddingTop: 35,
                paddingBottom: 40,
                paddingHorizontal: 63,
                marginTop: 22,
            },
            mobile: {
                width: '100%',
            },
        }),
    },
    changePasswordPopUp: {
        ...mbPlatformStyle({
            web: {
                backgroundColor: COLORS.darkPurple,
                paddingHorizontal: 61,
                paddingVertical: 50,
            },
            mobile: {
                marginTop: 30,
            },
        }),
    },
    textInputWrapper: {
        width: '100%',
        backgroundColor: COLORS.backgroundPurple,
        borderWidth: 0,
        borderRadius: 10,
        marginTop: 30,
    },
    textInputMobileStyle: {
        ...mbPlatformStyle({
            mobile: {
                height: 44,
            },
        }),
    },
    forgotPwdText: mbTextStyles([
        textStyles.largestText, {
            ...mbPlatformStyle({
                mobile: {
                    alignSelf: 'flex-start',
                },
            }),
            fontSize: 24,
        },
    ]),
    helperText: mbTextStyles([
        textStyles.smallText, {
            textAlign: 'left',
            alignSelf: isMobile ? 'flex-start' : 'center',
            marginTop: 18,
            fontWeight: '500',
            fontSize: 14,
        },
    ]),
    sendInstructionsBtn: {
        borderRadius: 10,
        backgroundColor: COLORS.buttonPurple,
        width: '100%',
        marginTop: 28,
    },
    textInputTitleStyle: {
        color: COLORS.textLightPurple,
    },
});
