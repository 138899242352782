import React from 'react';
import { AnswerCard } from './AnswerCard';
import { AnswerData } from '../ConstructQuestion';
import { View } from 'react-native';


const RegularAnswers = ({ answers, onAnswerChange }: { answers: (AnswerData | undefined)[], onAnswerChange: (index: number, newAnswer?: AnswerData) =>  void  }) => {
    return (
        <View>
            {answers.map((answer, index) => {
                const prevQuestion = answers[index - 1];
                const isPreviewExist = !answer && prevQuestion && (prevQuestion?.image !== undefined || prevQuestion?.name !== '');
                const isFirstPreview = !answer && index === 0;
                const showPreview = isPreviewExist || isFirstPreview;
                return (
                    <AnswerCard
                        key={index}
                        answer={answer}
                        showPreview={showPreview}
                        onAnswerChange={(newAnswer) => onAnswerChange(index, newAnswer)}
                    />
                );
            })}
        </View>
    );
};

export { RegularAnswers };
