const svgs: { [key: string]: { resource: any, asText: string } } = {
  usa_state: { resource: require('../resources/svg/united_state.svg'), asText: '' },
  europe_country: { resource: require('../resources/svg/europe.svg'), asText: '' },
  continents: { resource: require('../resources/svg/continents.svg'), asText: '' },
  america_north_south: { resource: require('../resources/svg/america_north_south.svg'), asText: '' },
  oceans: { resource: require('../resources/svg/oceans.svg'), asText: '' },
};


export const imageUtils = {
  images: {
    aslFlurryLogo: require('../resources/images/aslFlurryLogo.png'),
    brokenImage: require('../resources/images/broken-image.png'),
    donateImage: require('../resources/images/donate.png'),
    paypalLogo: require('../resources/images/paypalLogo.png'),
    thankYouImage: require('../resources/images/thankYou.png'),
    premiumBackground: require('../resources/images/premiumBackground.png'),
    purpleCellBackground: require('../resources/images/purpleCellBackground.png'),
    blueCellBackground: require('../resources/images/blueCellBackground.png'),
    greenCellBackground: require('../resources/images/greenCellBackground.png'),
    introToASLGameCard: require('../resources/images/introToASLGameCard.jpg'),
    matchItemsGameCard: require('../resources/images/matchItemsGameCard.jpg'),
    bodypartsGameCard: require('../resources/images/bodypartsGameCard.jpg'),
    geographyGameCard: require('../resources/images/geographyGameCard.png'),
    crown: require('../resources/images/crown.png'),
    rankingFirst: require('../resources/images/rankingFirst.png'),
    rankingSecond: require('../resources/images/rankingSecond.png'),
    rankingThird: require('../resources/images/rankingThird.png'),
  },
  svgs,
};
